import React from 'react';
import subFormState from 'globalState/subForm';
import FormsState from 'globalState/forms';
import Button from 'components/button';
import Paperclip from 'assets/img/icons/paperclip-attach.svg';
import styles from './styles.module.scss';
import ModalWindow from 'components/modalWindow';
import SubFormComponent from 'components/subForm/index';
import { SaveRecordAnswerType, SubFormModalProps } from 'types/components/subForm';
import { observer } from 'mobx-react';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';
import IconX from 'assets/img/icons/close-x.svg';
import { observable } from 'mobx';
import _ from 'lodash';

@observer
export default class SubFormModal extends React.Component<SubFormModalProps> {
    @observable disabledSave = false;

    handleShowModalAttachPopup = () => {
        subFormState.setShowAttachPopup(true);
    }

    handleClickSave = () => {
        this.disabledSave = true;
        const { isList } = this.props;
        const form = FormsState.getSubForm();
        if (!form) {
            return;
        }
        form.save('Default', true).then((record: SaveRecordAnswerType) => {
            subFormState.setIsShow(false);
            const value = {
                database_value: record.recordId,
                display_value: record.displayValue,
                reference_state: record.referenceState || null,
            };
            if (isList) {
                const editCell = subFormState.getEditCell();
                editCell.onChange({
                    value: _.isArray(editCell.value) ? [ ...editCell.value, value ] : value,
                    isValid: true,
                });
            } else if (window.opener) {
                if (window.opener.listEditReferenceCell) {
                    const editCell = window.opener.listEditReferenceCell;
                    editCell.onChange({
                        value: _.isArray(editCell.value) ? [ ...editCell.value, value ] : value,
                        isValid: true,
                    });
                    window.opener.listEditReferenceCell = null;
                } else if (window.opener.editReferenceField) {
                    window.opener.editReferenceField.updateData(value);
                    window.opener.editReferenceField = null;
                }
                window.close();
            } else {
                if (subFormState.getEditField()) {
                    subFormState.getEditField().updateData(value);
                }
            }
        }).finally(() => {
            this.disabledSave = false;
        });
    };

    handleClickCancel = () => {
        (window as any).isShowModal = false;
        subFormState.setIsShow(false);
    };

    render() {
        const isShow = subFormState.getIsShow();
        if (!isShow) {
            return null;
        }
        const recordActions = langStore.getTranslateKey('record_actions');
        const attachBtn = (
            <Button
                buttonType='attach'
                attachmentsLength={ subFormState.getAttachmentsLength() }
                svg={ Paperclip }
                onClick={ this.handleShowModalAttachPopup }
                className={ styles.ModalHeaderAttach }
                data-test={ ATTRIBUTES.subFormAttachIcon }
            />
        );
        const title = (
            <div data-test={ ATTRIBUTES.subFormTitle }>
                { subFormState.getTitle() }
            </div>
        );
        const buttons = (
            <>
                <Button
                    onClick={ this.handleClickSave }
                    buttonType='primary'
                    className={ styles.ModalHeaderBtn }
                    data-test={ ATTRIBUTES.subFormSaveButton }
                    disabled={ this.disabledSave }
                >
                    { recordActions?.save }
                </Button>
                <Button
                    onClick={ this.handleClickCancel }
                    className={ `${ styles.ModalHeaderBtn } ${ styles.CancelBtn }` }
                    data-test={ ATTRIBUTES.subFormCancelButton }
                >
                    { recordActions?.cancel }
                </Button>
            </>
        );
        const header = (
            <div>
                <div className={ styles.ModalHeader }>
                    { title }
                    <div>
                        { buttons }
                        { attachBtn }
                    </div>
                </div>
                <div className={ styles.ModalHeaderH2 } data-test={ ATTRIBUTES.subFormHeader2 }>
                    { recordActions?.new }
                </div>
                <div className={ styles.ModalHeaderMobile }>
                    <div className={ styles.ModalHeaderMobileTitle }>
                        { title }
                        <Button
                            buttonType='icon'
                            onClick={ this.handleClickCancel }
                            className={ styles.CloseIco }
                            svg={ IconX }
                            data-test={ ATTRIBUTES.modalCloseButton }
                        />
                    </div>
                </div>
            </div>
        );
        const mobileH2 = (
            <div className={ styles.ModalHeaderMobileH2 } data-test={ ATTRIBUTES.subFormMobileHeader2 }>
                <div>
                    { recordActions?.new }
                </div>
                { attachBtn }
            </div>
        );
        return (
            <ModalWindow
                isShow={ isShow }
                doClose={ this.handleClickCancel }
                header={ header }
                modalClassName={ styles.ModalWindow }
                modalHeaderClassName={ styles.ModalWindowHeader }
            >
                <div data-test={ ATTRIBUTES.subForm }>
                    { mobileH2 }
                    <SubFormComponent
                        tableName={ subFormState.getTableName() }
                        sysId=''
                        view='Default'
                        uiActions
                        userScripts
                    />
                    <div className={ styles.ModalFooterMobile }>
                        { buttons }
                    </div>
                </div>
            </ModalWindow>
        );
    }
}
