import * as React from 'react';
import { observer } from 'mobx-react';

import PageData from 'components/portalDesigner/state/pageData';

import styles from 'components/portalDesigner/styles.module.scss';
import DragAndDrop from 'components/portalDesigner/components/dragAndDrop';

/**
 * Базовый элемент для всех сущностей дизайнера порталов
 *
 * @props id {string}                     sys_id сущности
 * @props className {string}              классы
 * @props breadcrumbsIds [string]         хлебные крошки
 * @props handleDrop {function}         |
 * @props handleDragEnter {function}    |
 * @props handleDragOver {function}     |
 * @props handleDragLeave {function}    | методы drag'n'drop
 * @props model {BasePortalDesignerModel}  модель
 */
@observer
export default class BaseElement extends React.Component {

    constructor(props) {
        super(props);
    }

    onMouseDownHandler = (model) => (e) => {
        e.stopPropagation();
        PageData.setActiveElement(model, this.props.breadcrumbsIds);
    };

    render() {
        let className = [ this.props.className ];

        let style = {};
        if (this.props.model.backgroundImage) {
            style.backgroundImage = `url(${ this.props.model.backgroundImage }`;
            style.backgroundSize = 'cover';
        }
        else if (this.props.model.backgroundColor) {
            style.backgroundColor = this.props.model.backgroundColor;
        }

        if (PageData.activeElement && PageData.activeElement.id === this.props.id) {
            className.push(styles.ActiveElement);
        }

        const element =
            <div
                onDrop={ this.props.handleDrop }
                onMouseUp={ this.props.handleDrop }
                onDragEnter={ this.props.handleDragEnter }
                onDragOver={ this.props.handleDragOver }
                onDragLeave={ this.props.handleDragLeave }
                onMouseDown={ this.onMouseDownHandler(this.props.model) }
                className={ className.join(' ') }
                style={ style }>
                { this.props.children }
            </div>;

        if (this.props.type === 'widget') {
            return <DragAndDrop isNew={false} type={ PageData.TYPE_WIDGET_INSTANCE } sysId={ this.props.id }>
                { element }
            </DragAndDrop>;
        }
        else if (this.props.type === 'container') {
            return <DragAndDrop isNew={false} type={ PageData.TYPE_CONTAINER } sysId={ this.props.id }>
                { element }
            </DragAndDrop>;
        }
        else {
            return element;
        }
    }
}
