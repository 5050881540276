import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';

/**
 * Описание: Визуализация загрузки страницы
 * backgroundColor - string - цвет фона
 */


@observer
export default class PageLoader extends React.Component {
    refLoader = React.createRef();
    refContent = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setLoaderHeight();
        window.addEventListener('resize', this.setLoaderHeight);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setLoaderHeight);
    }

    setLoaderHeight = () => {
        const { current: loaderEl } = this.refLoader;
        const { current: contentEl } = this.refContent;
        if (!loaderEl || !contentEl) return;

        const contentHeight = contentEl.getBoundingClientRect().height;
        const bodyHeight = document.documentElement.clientHeight;
        const loaderTop = loaderEl.getBoundingClientRect().top;
        const loaderHeight = bodyHeight - loaderTop < contentHeight ? contentHeight : bodyHeight - loaderTop;
        loaderEl.style.height = `${ loaderHeight }px`;
    };

    render() {
        const { backgroundColor } = this.props;
        const style = backgroundColor ? { backgroundColor: backgroundColor } : {};

        return (
            <div className={ styles.Loader } style={ style } ref={ this.refLoader }>
                <div className={ styles.Content } ref={ this.refContent }>
                    <div className={ styles.LoaderIcon } />
                </div>
            </div>
        );
    }
}
