import * as React from 'react';
import { observer } from 'mobx-react';
import { helperRedirect } from 'helpers/history';
import styles from './styles.module.scss';
import CustomSelect from 'components/customselect';
import Button from 'components/button';
import IconLayout from 'assets/img/icons/layout.svg';
import IconLayers from 'assets/img/icons/layers.svg';
import listboxState from 'globalState/listboxState';
import { ATTRIBUTES } from 'constants/attributesForTests';
import langStore from 'globalState/lang';

/**
 * Описание: Поле для листбокса
 * Параметры:
 * type: {required: true, type: string} - тип поля
 * name: {required: true, type: string} - имя поля(заголовок)
 * databaseValue: {required: true, type: string} - начальное значение
 * options: {required: true, type: array} - опции для селекта
 * button: {required: false, type: string} - тип иконки для кнопки(ссылки) рядом с селектом
 * onChange: {required: true, type: function} - метод вызываемый при изменении значения
 * tableName: {required: true, type: string} - имя таблицы для формирования ссылки кнопки
 * preferenceName: {required: true, type: string} - имя настройки которую сохраняем
 *
 * */

@observer
export default class FieldComponent extends React.Component {
    redirectUrl = '';

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        if (this.props.button) {
            this.redirectUrl = `/record/${this.props.tableName}/${this.props.databaseValue}`;
        }
    }

    onChange = (value) => {
        if (this.props.button) {
            this.redirectUrl = `/record/${this.props.tableName}/${value.database_value}`;
        }
        if (this.props.onChange) {
            this.props.onChange(value.database_value, this.props.preferenceName);
        }
    };

    onButtonClick = () => {
        helperRedirect(this.redirectUrl);
        listboxState.setOpenedState(false);
    };

    getButtonIcon() {
        let icon;
        switch (this.props.button) {
            case 'layers':
                icon = IconLayers;
                break;

            case 'layout':
                icon = IconLayout;
                break;

            default:
                icon = null;
                break;
        }
        return icon;
    }

    render() {
        const { disabled, preferenceName } = this.props;
        const dataTestName = this.props['data-test'] ? this.props['data-test'] : `${preferenceName}-${ATTRIBUTES.listBoxField}`;
        const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');

        if (this.props.type === 'select') {
            if (this.props.button) {
                return (
                    <div className={ styles.Field } data-test={ dataTestName }>
                        <div className={ styles.FieldTitle }>{ this.props.name }</div>
                        <div className={ styles.FieldFlex }>
                            <CustomSelect
                                options={ this.props.options }
                                databaseValue={ this.props.databaseValue }
                                onChange={ this.onChange }
                                disabled={ disabled }
                            />
                            <Button
                                hint={ this.props.button === 'layout' ? system_buttons_hints?.open_the_record : null }
                                buttonType={ 'icon-border' }
                                svg={ this.getButtonIcon() }
                                onClick={ this.onButtonClick }
                                disabled={ disabled }
                            />
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div
                        className={ styles.Field }
                        data-test={ dataTestName }
                    >
                        <div className={ styles.FieldTitle }>{ this.props.name }</div>
                        <CustomSelect
                            options={ this.props.options }
                            databaseValue={ this.props.databaseValue }
                            onChange={ this.onChange }
                            disabled={ disabled }
                        />
                    </div>
                );
            }
        }
        else {
            return 'Unknown field type';
        }
    }
}
