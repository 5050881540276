import { observer } from 'mobx-react';
import * as React from 'react';
import Workflow from 'components/workflow';
import LeftPanel from 'components/workflow/leftPanel';
import PropertiesPanel from 'components/workflow/propertiesPanel';
import HeaderPanel from 'components/workflow/headerPanel';
import ZoomPanel from 'components/workflow/zoomPanel';
import { action, observable, reaction } from 'mobx';

import styles from 'pages/workflow/styles.module.scss';
import apiRequest from 'lib/apiRequest';
import userState from 'globalState/user';
import InfoMessagesState from 'globalState/infoMessages';
import langStore from 'globalState/lang';
import WorkflowTitle from 'components/workflow/title';
import WorkflowRecent from 'components/workflow/recentList';
import WorkflowUserRecent from 'components/workflow/recentUserList';
import NewWorkflowPanel from 'components/workflow/newWorkflowPanel';
import { getLocalTime } from 'components/workflow/helpers/datetime';
import StatePanel from 'components/workflow/statePanel';
import ListBox from 'layouts/main/listbox';
import _ from 'lodash';
import PageLoader from 'components/pageLoader';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import listboxState from 'globalState/listboxState';
import FormMessages from 'components/formMessages';
import { workflowRemove } from 'actions/workflow';

/**
 *
 * Параметры для ListBox:
 * isBlockWorkflow: режим внешнего вида панели для workflow
 *
 **/

const refreshPeriod = 10000;
const activityWidth = 200;
const activityHeight = 62;
const endActivityTypeName = 'End';


@observer
class WorkflowPage extends React.Component {
    @observable model = {
        wf_activity_type: [],
        wf_activity: [],
        wf_activity_exit: [],
        wf_activity_type_exit: [],
        wf_transition: [],
        wf_workflow: [],
        wf_workflow_version: [],
        wf_activity_type_category: [],
        wf_executing_activity_history: [], // история переходов между активностями
        typeNames: {},
    };
    @observable opened_workflows = [];
    @observable currentWorkflow;
    @observable isPropertiesPanelOpened = false;
    @observable isLeftPanelOpened = true;
    @observable readOnly = true;
    @observable isViewer = true;
    @observable translate = {};
    @observable selected = {
        type: null,
        sys_id: null,
    };
    @observable viewerRecord = {
        essence: null,
        recordId: null,
    };
    @observable zoom = 1;
    zoomStep = 0.25;
    minZoom = 0.25;
    maxZoom = 2;
    leftPanelWidth = 274;
    topPanelHeight = 42;
    @observable stagePos = {
        x: 0,
        y: 0,
    };
    @observable mode = 'index'; // 'new', 'open', 'index', 'workspace'
    @observable disabledButtonCreate = true;
    intervalId;
    isUserAction = false;
    @observable loading = false;

    constructor(props) {
        super(props);
        this.currentWorkflow = this.props.sys_id || this.props.match.params.sys_id || null;
        this.mode = this.currentWorkflow ? 'workspace' : 'index'; // если указан id - переходим сразу в редактор
        this.isViewer = !!this.props.viewer;
        this.viewerRecord = {
            essence: this.props.essence || this.props.match.params.essence || null,
            recordId: this.props.record_id || this.props.match.params.record_id || null,
        };

        if (this.isViewer) {
            this.fetchRunningWorkflows().then(() => {
                this.loadWorkflow();
            });
        }
        else {
            this.fetchWorkflowList().then(() => {
                this.loadWorkflow();
            });
        }
        this.fetchTranslate().catch(this.errorFetchData);
        reaction(
            () => listboxState.isOpened,
            () => {
                if (this.isPropertiesPanelOpened)
                    this.togglePropertiesPanel(false);
            },
        );
    }

    componentDidMount = () => {
        if (this.isViewer) {
            this.intervalId = setInterval(this.timer, refreshPeriod);
        }
    };

    componentWillUnmount = () => {
        if (this.isViewer) {
            clearInterval(this.intervalId);
        }
    };

    timer = () => {
        if (this.isViewer && this.mode === 'workspace' && document.visibilityState === 'visible') {
            this.fetchRunningWorkflows().then(() => {
                this.loadWorkflow(undefined,false);
            });
        }
    };

    getSysId = () => {
        return this.currentWorkflow;
    };

    fetchTranslate = async () => {
        await new apiRequest('GET /workflow/dictionary').caching().send();

        const { get_types } = langStore.getTranslate();

        this.model = {
            ...this.model,
            typeNames: {
                wf_activity_type: get_types && get_types.activity_type,
                wf_activity: get_types && get_types.activity,
                wf_activity_exit: get_types && get_types.activity_exit,
                wf_activity_type_exit: get_types && get_types.activity_type_exit,
                wf_transition: get_types && get_types.transition,
                wf_workflow: get_types && get_types.workflow,
                wf_workflow_version: get_types && get_types.workflow_version,
                wf_activity_type_category: get_types && get_types.activity_type_category,
                wf_context: get_types && get_types.context,
                wf_executing_activity: get_types && get_types.executing_activity,
            },
        };
    };

    fetchRunningWorkflows = async () => {
        this.loading = true;
        let response;
        try {
            response = await new apiRequest(`GET /workflow/get-contexts`)
                .qs({
                    essence: this.viewerRecord.essence,
                    record_id: this.viewerRecord.recordId,
                }).send();
        }
        finally {
            this.loading = false;
        }
        if (response.status === 'OK') {
            const data = response.getData();
            this.model = {
                ...this.model,
                wf_workflow: data,
            };
            this.opened_workflows = data; // в viewer переключение между работающими workflow в верхней панели
            if (this.opened_workflows && !this.currentWorkflow && this.opened_workflows[0]) {
                this.currentWorkflow = this.opened_workflows[0].sys_id;
            }
        }
    };

    fetchWorkflowList = async () => {
        this.loading = true;
        let response;
        try {
            response = await new apiRequest(`GET /workflow/get-workflows`).send();
        }
        finally {
            this.loading = false;
        }
        if (response.status === 'OK') {
            let data = response.getData();
            data = data.map((elem) => {
                const edited_at = elem.edited_at ? new Date(getLocalTime(elem.edited_at)) : null;
                return {
                    ...elem,
                    edited_at,
                };
            });
            this.model = {
                ...this.model,
                wf_workflow: data,
            };
        }
    };

    loadWorkflow = async (sys_id, resetViewport = true) => {
        const isNew = (sys_id && sys_id !== this.currentWorkflow);
        if (!sys_id) {
            sys_id = this.currentWorkflow;
        }
        if (sys_id) {
            await this.fetchWorkflow(sys_id)
                      .catch((e) => {
                          this.errorFetchData(e);
                          return Promise.reject('Unable to fetch workflow!');
                      })
                      .then(() => {
                          this.switchMode('workspace');
                          this.disabledButtonCreate = false;
                          this.switchCurrentWorkflow(sys_id, resetViewport);
                          if (isNew) {
                              this.togglePropertiesPanel(false);
                          }
                      });
        }
    };

    @action
    switchCurrentWorkflow = (sys_id, resetViewport = true) => {
        const workflow = this.model.wf_workflow.find((elem) => (elem.sys_id === sys_id));
        const exist_workflow = this.opened_workflows.find((elem) => (elem.sys_id === sys_id));
        const { user } = userState;
        if (!exist_workflow) {
            this.opened_workflows.push(workflow);
        }
        //Обновляем за одно действие в рамках одного render workflow, чтобы корректно отработал componentDidUpdate в компоненте Workflow
        this.currentWorkflow = sys_id;
        if (resetViewport){
            this.zoom = 1;
            this.stagePos = {
                x: 0,
                y: 0,
            };
        }
        this.readOnly = this.isViewer || !workflow.checked_out_by || !!workflow.checked_out_by && workflow.checked_out_by !== user.sys_id;
    };

    fetchWorkflow = async (sys_id) => {
        this.loading = true;
        let response;
        try {
            if (this.isViewer) {
                response = await new apiRequest(`GET /workflow/get-context-elements/${ sys_id }`).send()
                                                                                                 .catch((e) => {
                                                                                                     this.errorFetchData(e);
                                                                                                     return Promise.reject('Bad response from server!');
                                                                                                 });
            }
            else {
                response = await new apiRequest(`GET /workflow/get-workflow-elements/${ sys_id }`).send()
                                                                                                  .catch((e) => {
                                                                                                      this.errorFetchData(e);
                                                                                                      return Promise.reject('Bad response from server!');
                                                                                                  });
            }
        }
        finally {
            this.loading = false;
        }

        if (response.status === 'OK') {
            const data = response.getData();
            const new_data = {
                wf_activity_type: data.wf_activity_type,
                wf_activity: data.wf_activity,
                wf_activity_exit: data.wf_activity_exit,
                wf_activity_type_exit: data.wf_activity_type_exit,
                wf_transition: data.wf_transition,
                wf_workflow_version: data.wf_workflow_version,
                wf_activity_type_category: data.wf_activity_type_category,
                wf_context: data.wf_context,
                wf_executing_activity: data.wf_executing_activity,
                wf_executing_activity_history: data.wf_executing_activity_history,
            };
            this.model = {
                ...this.model,
                ...new_data,
            };
        }
        else {
            return Promise.reject('Server response is not OK!');
        }
    };

    errorFetchData = (message) => {
        InfoMessagesState.pushError(message);
    };

    refreshData = () => {
        this.fetchWorkflow(this.currentWorkflow).catch((e) => this.errorFetchData(e));

    };

    closeWorkflow = (sys_id) => {
        this.opened_workflows = this.opened_workflows.filter((elem) => (elem.sys_id !== sys_id));
        if (this.opened_workflows.length > 0) {
            const new_current_workflow = this.opened_workflows[0].sys_id;
            this.loadWorkflow(new_current_workflow);
        }
        else {
            this.currentWorkflow = null;
            this.switchMode('index');
        }
    };

    @action
    createData = async (entity, data, table_name) => {
        if (!entity || !data) {
            return Promise.reject('Insufficient data to create record!');
        }
        //Формируем запрос на сервер
        const requestData = {
            record: {
                ...data,
                sys_id: null,
            },
        };
        const response = await new apiRequest(`PUT /record/${ table_name || entity }`)
            .qs({ clear_messages: 'success' })
            .sendJSON(requestData);
        if (response.status === 'OK') {
            const responseData = response.getData();
            if (responseData && responseData.record_id) {
                // Обновляем model
                const index = this.model[entity].findIndex((elem) => (elem.sys_id === data.sys_id));
                let record = this.model[entity][index];
                record = {
                    ...record,
                    sys_id: responseData.record_id,
                };
                if (entity === 'wf_activity'){
                    record.active = await this.checkActive(responseData.record_id);
                }
                delete record.isNew; // чтобы избежать в дальнейшем передачи лишнего атрибута на сервер, убираем isNew.
                this.model[entity][index] = record;
                return Promise.resolve(responseData);
            }
            else {
                return Promise.reject('No response with sys_id from server!');
            }
        }
        else {
            return Promise.reject('Bad response from server!');
        }
    };

    @action
    updateData = async (entity, data, table_name, shouldSaveToDB = true) => {
        if (!entity || !data || !data.sys_id) {
            return Promise.reject('Insufficient data to update record!');
        }
        // Обновляем model
        const index = this.model[entity].findIndex((elem) => (elem.sys_id === data.sys_id));
        let record = this.model[entity][index];
        record = { ...record, ...data };
        this.model[entity][index] = record;
        // Обновляем данные на backend
        const requestData = {
            record: {
                ...data,
                //workflow_version_id: this.model.wf_workflow_version.sys_id,
            },
        };
        if (shouldSaveToDB) {
            if (record.isNew) {
                return Promise.reject('Unable to edit object until response from server');
            }
            await new apiRequest(`POST /record/${ table_name || entity }/${ data.sys_id }`)
                .qs({ clear_messages: 'success', open_first_rel_list : 0 })
                .sendJSON(requestData)
                .then((response) => {
                    record.active = response.data.active;
                });
        }
    };

    @action
    deleteData = async (entity, sys_id) => {
        if (!entity || !sys_id) {
            return Promise.reject('Insufficient data to delete record!');
        }
        const record = this.model[entity].find((elem) => (elem.sys_id === sys_id));
        if (record.isNew) {
            return Promise.reject('Unable to delete object until response from server');
        }
        this.model[entity] = this.model[entity].filter((elem) => (elem.sys_id !== sys_id));

        const data = {
            sys_ids: { 0: sys_id },
        };
        await new apiRequest(`POST /delete/${ entity }`)
            .qs({ clear_messages: 'success' })
            .sendJSON(data); // для любого activity удаление идёт из родительской таблицы wf_activity
    };

    // ACTIVITY METHODS

    createActivity = (coor, type_id, type_name, table_name, transition_id) => {
        const tmp_sys_id = 'ACT' + Math.round(Math.random() * 1000000); // временный идентификатор до получения sys_id с backend TODO заменить на более надёжное решение
        const data = {
            name: type_name,
            description: '',
            activity_type_id: type_id,
            type_name: type_name,
            sys_id: tmp_sys_id,
            workflow_version_id: this.model.wf_workflow_version.sys_id,
            ...coor,
        };

        const model_record = {
            ...data,
            isNew: true, // для новых записей запрещаем редктирование до получения постоянного идентификатора
            active: false, // требует ли активность действий пользователя должен в дальнейшем сообщить сервер
        };

        //Добавляем выходы по умолчанию
        const template_exits = this.model.wf_activity_type_exit.filter((elem) => (elem.activity_type_id === type_id));
        const activity_exits = template_exits.map((elem) => ({
            sys_id: 'EXT' + Math.round(Math.random() * 1000000), // временный идентификатор до получения sys_id с backend TODO заменить на более надёжное решение
            name: elem.name,
            order: elem.order,
            activity_id: tmp_sys_id,
            description: elem.description,
            isNew: true, // для предотвращения создания связей к выходам, котороые не имеют ИД с сервера
        }));

        this.model = {
            ...this.model,
            wf_activity: this.model.wf_activity.concat(model_record),
            wf_activity_exit: this.model.wf_activity_exit.concat(activity_exits),
        };

        //Сохранение на backend
        delete data.type_name; // удаляем свойство используемое только на клиенте
        this.createData('wf_activity', data, table_name)
            .then((data) => {
                // обновляем привязку выходов к активности сразу (чтобы избежать визуального исчезновения выходов во время получения идентификаторов выходов)
                const newExits = [];
                this.model.wf_activity_exit.forEach((elem) => {
                    if (elem.activity_id === tmp_sys_id) {
                        newExits.push({
                            ...elem,
                            activity_id: data.record_id,
                        });
                    }
                });
                const otherExits = this.model.wf_activity_exit.filter((elem) => (elem.activity_id !== tmp_sys_id));
                this.model.wf_activity_exit = otherExits.concat(newExits);
                //Обновление идентификаторов выходов
                this.updateExitsIDs(data.record_id).then(() => {
                    this.breakTransition(data.record_id, transition_id);
                });
                this.setSelected('wf_activity', data.record_id);
            })
            .catch((e) => this.errorFetchData(e));
    };

    async checkActive(id){
        let res = false;
        let response = await new apiRequest(`GET /workflow/get-activity/${ id }`).send();
        if (response.status === 'OK') {
            res = response.getData().active;
        }
        return res;
    }

    breakTransition = (activity_id, transition_id) => { // Позиционируем новый блок на связь
        //Удаляем старую связь и создаём вместо неё две новые и подключаем их к новому блоку
        if (activity_id && transition_id) {
            const transition = this.model.wf_transition.find((elem) => (elem.sys_id === transition_id));
            const inputTransition = {
                activity_to_id: activity_id,
                activity_exit_id: transition.activity_exit_id,
            };
            const exit = this.model.wf_activity_exit.find((elem) => (elem.activity_id === activity_id));
            const outputTransition = {
                activity_to_id: transition.activity_to_id,
                activity_exit_id: exit.sys_id,
            };
            this.createTransition(inputTransition);
            this.createTransition(outputTransition);
            this.removeTransition(transition_id);
        }
    };

    updateExitsIDs = async (activity_id) => {
        // Обновление идентификаторов выходов активности полученными с сервера
        const response = await new apiRequest(`GET /workflow/get-activity-exits/${ activity_id }`).send();
        if (response.status === 'OK') {
            const responseData = response.getData();
            if (responseData) {
                // Обновляем model
                const activityExits = responseData.map((elem) => (
                    {
                        sys_id: elem.sys_id,
                        activity_type_exit_id: elem.activity_type_exit_id,
                    }
                ));
                // Предполагается, что порядок выходов в wf_activity_type_exit из запроса workflow совпадает с порядком выходов из get-activity-exits
                // Иначе на клиенте не определить какой ИД ставить какому выходу (как альтернатива вводить ограничение на уникальность имени выхода в рамках активности и искать нужный выход по имени)
                const exits = this.model.wf_activity_exit.filter((elem) => (elem.activity_id === activity_id));
                const newExits = [];
                exits.forEach((elem, index) => {
                    delete elem.isNew; // Снимаем флаг запрещающий редактирование
                    newExits.push({
                        ...elem,
                        sys_id: activityExits[index].sys_id,
                        activity_type_exit_id: activityExits[index].activity_type_exit_id,
                    });
                });
                const otherExits = this.model.wf_activity_exit.filter((elem) => (elem.activity_id !== activity_id));
                this.model.wf_activity_exit = otherExits.concat(newExits);
            }
            else {
                return Promise.reject('No response with sys_id from server!');
            }
        }
        else {
            return Promise.reject('Bad response from server!');
        }
    };

    updateActivity = (sys_id, data, shouldSaveToDB = true) => {
        let activity = this.model.wf_activity.find((item) => item.sys_id === sys_id);
        activity = { ...activity, ...data };
        delete activity.type_name; // удаляем свойство используемое только на клиенте
        delete activity.active; // удаляем свойство используемое только на клиенте
        const activityType = this.model.wf_activity_type.find((elem) => (elem.sys_id === activity.activity_type_id));
        const tableName = activityType ? activityType.activity_table_name : null;
        this.updateData('wf_activity', activity, tableName, shouldSaveToDB)
            .catch((e) => {
                this.errorFetchData(e);
            });
    };

    removeActivity = (sys_id) => {
        const activity = this.model.wf_activity.find((activity) => (activity.sys_id === sys_id));

        if (!activity.isNew) { //Activity can't be deleted until response from server!
            const activityTypeFind = this.model.wf_activity_type.find((elem) =>
                ((elem.sys_id === activity.activity_type_id) && (elem.name === 'Begin' || elem.name === 'End')),
            );
            const checkRemove = _.isEmpty(activityTypeFind);
            if (!checkRemove) {
                const { workflow_titles } = langStore.getTranslate();
                this.errorFetchData(workflow_titles && workflow_titles.check_remove);
                return;
            }

            let transitions = this.model.wf_transition.filter((elem) => (elem.activity_to_id !== sys_id)); // удаляем входящие в активность связи
            let exits = this.model.wf_activity_exit.filter((elem) => (elem.activity_id === sys_id)); // выходы удаляемой активити
            exits = exits.map((exit) => (exit.sys_id)); // массив идентификаторов выходов
            transitions = transitions.filter((transition) => (!exits.includes(transition.activity_exit_id))); // удаляем исходящие из выходов transitions
            this.model = {
                ...this.model,
                wf_transition: transitions,
                wf_activity_exit: this.model.wf_activity_exit.filter((exit) => (!exits.includes(exit.sys_id))),
            };
            const activityType = this.model.wf_activity_type.find((elem) => (elem.sys_id === activity.activity_type_id));
            const tableName = activityType ? activityType.activity_table_name : null;
            this.deleteData('wf_activity', sys_id, tableName)
                .catch((e) => this.errorFetchData(e));
        }
    };

    // TRANSITION METHODS

    createTransition = (data) => {
        const activity = this.model.wf_activity.find((elem) => (elem.sys_id === data.activity_to_id));
        const exit = this.model.wf_activity_exit.find((elem) => (elem.sys_id === data.activity_exit_id));
        if (activity && exit && !activity.isNew && !exit.isNew) { //Unable to create record until response from server!
            const activityType = this.model.wf_activity_type.filter((elem) =>
                (elem.sys_id === activity.activity_type_id && elem.name === 'Begin'),
            );
            const checkTransition = _.isEmpty(activityType);
            if (!checkTransition) {
                const { workflow_titles } = langStore.getTranslate();
                this.errorFetchData(workflow_titles && workflow_titles.create_transition);
                return;
            }
            const tmp_sys_id = 'TRN' + Math.round(Math.random() * 1000000); // временный идентификатор до получения sys_id с backend
            const recordData = {
                sys_id: tmp_sys_id,
                ...data,
            };
            const modelRecord = {
                ...recordData,
                isNew: true, // для новых записей запрещаем редктирование до получения постоянного идентификатора
            };
            this.model.wf_transition = this.model.wf_transition.concat(modelRecord);
            this.createData('wf_transition', recordData)
                .catch((e) => this.errorFetchData(e));
        }
        else {
            this.errorFetchData('Unable to edit object until response from server');
        }
    };

    updateTransition = (sys_id, data) => {
        let transition = this.model.wf_transition.find((item) => item.sys_id === sys_id);
        transition = { ...transition, ...data };
        this.updateData('wf_transition', transition)
            .catch((e) => {
                this.errorFetchData(e);
            });
    };

    removeTransition = (sys_id) => {
        this.deleteData('wf_transition', sys_id)
            .catch((e) => this.errorFetchData(e));
    };

    // EXITS METHODS

    createExit = (data) => {
        const tmp_sys_id = 'EXT' + Math.round(Math.random() * 1000000); // временный идентификатор до получения sys_id с backend
        const recordData = {
            sys_id: tmp_sys_id,
            ...data,
        };
        const modelRecord = {
            ...recordData,
            isNew: true, // для новых записей запрещаем редктирование до получения постоянного идентификатора
        };
        this.model.wf_activity_exit = this.model.wf_activity_exit.concat(modelRecord);
        this.createData('wf_activity_exit', recordData)
            .catch((e) => this.errorFetchData(e));
    };

    removeExit = (sys_id) => {
        const exit = this.model.wf_activity_exit.find((elem) => (elem.sys_id === sys_id));
        const { workflow_titles } = langStore.getTranslate();
        if (exit.activity_type_exit_id) { // предустановленный выход
            this.errorFetchData(workflow_titles && workflow_titles.delete_default_exit_error);
            return;
        }
        const otherActivityExit = this.model.wf_activity_exit.find((elem) => (elem.activity_id === exit.activity_id && elem.sys_id !== exit.sys_id));
        if (!otherActivityExit) {
            this.errorFetchData(workflow_titles && workflow_titles.delete_last_exit_error);
            return;
        }

        this.model.wf_transition = this.model.wf_transition.filter((elem) => (elem.activity_exit_id !== sys_id)); // удаляем cвязи из данного выхода
        this.deleteData('wf_activity_exit', sys_id)
            .catch((e) => this.errorFetchData(e));
    };

    updateExit = (sys_id, data) => {
        let exit = this.model.wf_activity_exit.find((item) => item.sys_id === sys_id);
        exit = { ...exit, ...data };
        delete exit.height;
        delete exit.linked;
        delete exit.y;
        this.updateData('wf_activity_exit', exit)
            .catch((e) => {
                this.errorFetchData(e);
            });
    };

    togglePropertiesPanel = (state) => {
        if (this.isPropertiesPanelOpened !== state) {
            if (state && listboxState.isOpened){
                listboxState.setOpenedState(false);
            }
            this.isPropertiesPanelOpened = state;
        }
    };

    toggleLeftPanel = (state) => {
        this.isLeftPanelOpened = state;
    };

    setSelected = (type, sys_id) => {
        const selected = {
            type,
            sys_id,
        };
        this.selected = selected;
        if (!type) {
            this.isPropertiesPanelOpened = false;
        }
    };

    newWorkflow = () => {
        this.showWorkflowProperties();
    };

    copyWorkflow = () => {

    };

    updateOpenedWorkflows = () => {
        const openedWorkflowIDs = this.opened_workflows.map((elem) => (elem.sys_id));
        this.opened_workflows = this.model.wf_workflow.filter((elem) => (openedWorkflowIDs.includes(elem.sys_id)));
    };

    deleteWorkflow = () => {
        if (this.currentWorkflow) {
            const { workflow_titles } = langStore.getTranslate();
            let confirm = workflow_titles?.confirm_delete;
            const currentWorkflow = this.model.wf_workflow.find((workflow) => workflow.sys_id === this.currentWorkflow);
            if (typeof(confirm) === 'string'){
                confirm = confirm.replace('{workflow_name}', currentWorkflow?.name);
            }
            if (window.confirm(confirm)){
                this.removeWorkflow();
            }
        }
    };

    removeWorkflow = async () => {
        if (this.currentWorkflow) {
            const { isOkStatus } = await workflowRemove(this.currentWorkflow);
            if (isOkStatus) {
                this.closeWorkflow(this.currentWorkflow);
                this.fetchWorkflowList();
                this.updateOpenedWorkflows();
            }
        }
    };

    checkOutWorkflow = async () => {
        const requestData = {
            record: {
                checked_out: true,
            },
        };

        //Временное решение для получения form_id пока бэк не сделал передачу form_id через get-workflow-elements
        let formId;
        let response = await new apiRequest(`GET /record/wf_workflow_version`).send();
        if (response.status === 'OK') {
            formId = response.getData().form_id;
        }

        response = await new apiRequest(`POST /record/wf_workflow_version/${ this.model.wf_workflow_version.sys_id }`)
            .qs({
                form_id: formId,
            })
            .sendJSON(requestData);
        if (response.status === 'OK') {
            this.fetchWorkflowList().then(() => {
                this.fetchWorkflow(this.currentWorkflow).then(() => {
                    this.updateOpenedWorkflows();
                    this.readOnly = false;
                }); //обновляем workflow version
            });
        }
    };

    isEveryActivityHasExit = () => {
        const endType = this.model.wf_activity_type.find((elem) => (elem.name === endActivityTypeName));
        return this.model.wf_activity.every((act) => {
            if (act.activity_type_id !== endType.sys_id) {
                const exitExists = this.model.wf_activity_exit.find((elem) => (elem.activity_id === act.sys_id));
                if (!exitExists) {
                    return false;
                }
            }
            return true;
        });
    };

    publishWorkflow = async () => {
        if (!this.isEveryActivityHasExit()) {
            const { workflow_titles } = langStore.getTranslate();
            this.errorFetchData(workflow_titles && workflow_titles.activity_without_exit_found_error);
            return Promise.reject('Activity without at least one exit found!');
        }

        //Временное решение для получения form_id пока бэк не сделал передачу form_id через get-workflow-elements
        let formId;
        let response = await new apiRequest(`GET /record/wf_workflow_version`).send();
        if (response.status === 'OK') {
            formId = response.getData().form_id;
        }

        const requestData = {
            record: {
                published: true,
            },
        };

        response = await new apiRequest(`POST /record/wf_workflow_version/${ this.model.wf_workflow_version.sys_id }`)
            .qs({
                form_id: formId,
            })
            .sendJSON(requestData);
        if (response.status === 'OK') {
            this.fetchWorkflowList().then(() => {
                this.fetchWorkflow(this.currentWorkflow).then(() => {
                    this.updateOpenedWorkflows();
                    this.readOnly = true;
                }); //обновляем workflow version
            });
        }
    };

    validateWorkflow = () => {

    };

    showWorkflowProperties = (workflowId) => {
        this.setSelected('wf_workflow', workflowId);
        this.togglePropertiesPanel(true);
    };

    decreaseZoom = (isUserAction = false) => {
        if (this.zoom - this.zoomStep > 0) {
            this.isUserAction = isUserAction;
            this.zoom -= this.zoomStep;
        }
    };

    increaseZoom = (isUserAction = false) => {
        if (this.zoom + this.zoomStep <= this.maxZoom) {
            this.isUserAction = isUserAction;
            this.zoom += this.zoomStep;
        }
    };

    setStagePosition = (x, y, isUserAction = false) => {
        this.stagePos = {
            x: x,
            y: y,
        };
        this.isUserAction = isUserAction;
    };

    @action
    resetZoom = () => {
        this.isUserAction = false;
        this.zoom = 1;
        this.stagePos = {
            x: 0,
            y: 0,
        };
    };

    @action
    adjustZoom = () => {
        if (this.model.wf_activity.length > 0) {
            this.isUserAction = false;
            let minX = window.innerWidth,
                minY = window.innerHeight,
                maxX = 0,
                maxY = 0;
            this.model.wf_activity.forEach((act) => {
                minX = act.x < minX ? act.x : minX;
                minY = act.y < minY ? act.y : minY;
                maxX = (act.x > maxX) ? act.x : maxX;
                maxY = (act.y > maxY) ? act.y : maxY;
            });
            maxX += activityWidth;
            maxY += activityHeight;

            const leftMargin = this.isLeftPanelOpened ? this.leftPanelWidth : 0;
            const scaleX = window.innerWidth / maxX;
            const scaleY = window.innerHeight / maxY;
            let newZoom = Math.floor(Math.min(scaleX, scaleY) / this.zoomStep) * this.zoomStep;
            newZoom = newZoom < this.minZoom ? this.minZoom : newZoom;
            newZoom = newZoom > this.maxZoom ? this.maxZoom : newZoom;
            this.zoom = newZoom;
            this.stagePos = {
                x: -minX * newZoom + leftMargin,
                y: -minY * newZoom + this.topPanelHeight,
            };
        }
    };

    switchMode = (mode) => {
        this.mode = mode;
    };

    toggleCreateButton = (mode) => {
        this.disabledButtonCreate = mode;
    };

    saveNewWorkflow = async (table, name, description) => {
        this.loading = true;
        if (!table || !name) {
            return Promise.reject('Insufficient data to create record!');
        }
        //Формируем запрос на сервер
        const requestData = {
            record: {
                name: name,
                active: true,
                description: description,
                table_id: table.database_value,
            },
        };
        let response;
        try {
            response = await new apiRequest(`PUT /record/wf_workflow`).sendJSON(requestData).catch();
        }
        finally {
            this.loading = false;
        }
        if (response.status === 'OK') {
            const responseData = response.getData();
            if (responseData && responseData.record_id) {
                this.fetchWorkflowList().then(() => {
                    this.loadWorkflow(responseData.record_id);
                });
                return Promise.resolve(responseData);
            }
            else {
                this.disabledButtonCreate = false;
                return Promise.reject('No response with sys_id from server!');
            }
        }
        else {
            this.disabledButtonCreate = false;
            return Promise.reject('Bad response from server!');
        }
    };

    loadWorkflowFromPanel = (sys_id) => {
        this.loadWorkflow(sys_id).catch(() => {
            this.fetchWorkflowList();
        });
    };

    render() {
        let output;
        if (this.mode === 'workspace') {
            //Рабочая область
            let selectedActivity;
            if (this.selected.type === 'wf_activity' && this.selected.sys_id) {
                //уточняем таблицу активности
                const activity = this.model.wf_activity.find((elem) => (elem.sys_id === this.selected.sys_id));
                if (activity) {
                    const activityType = this.model.wf_activity_type.find((elem) => (elem.sys_id === activity.activity_type_id));
                    selectedActivity = {
                        tableName: activityType && activityType.activity_table_name,
                        name: activityType && activityType.name,
                    };
                }
            }

            let isLocked,
                isPublished,
                isCheckedOut;
            if (this.currentWorkflow) {
                const { user } = userState;
                const workflow = this.model.wf_workflow.find((elem) => (elem.sys_id === this.currentWorkflow));
                if (workflow) {
                    isLocked = workflow.checked_out_by && workflow.checked_out_by !== user.sys_id;
                    isPublished = this.model.wf_workflow_version.published;
                    isCheckedOut = workflow.checked_out_by === user.sys_id;
                }
            }
            let activityStatus;
            if (this.isViewer && this.selected.type === 'wf_activity') {
                activityStatus = this.model.wf_executing_activity.find((elem) => (elem.activity_id === this.selected.sys_id));
            }

            output =
                <div className={ styles.Workflow }>
                    { !this.isViewer &&
                    <LeftPanel
                        workflows={ this.model.wf_workflow }
                        activityTypes={ this.model.wf_activity_type }
                        activityTypeCategories={ this.model.wf_activity_type_category }
                        currentWorkflow={ this.currentWorkflow }
                        isOpened={ this.isLeftPanelOpened }
                        toggleLeftPanel={ this.toggleLeftPanel }
                        loadWorkflow={ this.loadWorkflow }
                        newWorkflow={ this.newWorkflow }
                        copyWorkflow={ this.copyWorkflow }
                        deleteWorkflow={ this.deleteWorkflow }
                        checkOutWorkflow={ this.checkOutWorkflow }
                        publishWorkflow={ this.publishWorkflow }
                        validateWorkflow={ this.validateWorkflow }
                        showWorkflowContext={ this.showWorkflowContext }
                        showWorkflowProperties={ this.showWorkflowProperties }
                        isLocked={ isLocked }
                        isPublished={ isPublished }
                        isCheckedOut={ isCheckedOut }
                        setSelected={ this.setSelected }
                    /> }
                    { !!this.currentWorkflow &&
                    <Workflow
                        model={ this.model }
                        method={ {
                            wf_activity: {
                                create: this.createActivity,
                                remove: this.removeActivity,
                                update: this.updateActivity,
                            },
                            wf_transition: {
                                create: this.createTransition,
                                remove: this.removeTransition,
                                update: this.updateTransition,
                            },
                            wf_activity_exit: {
                                create: this.createExit,
                                remove: this.removeExit,
                                update: this.updateExit,
                            },
                        } }
                        togglePropertiesPanel={ this.togglePropertiesPanel }
                        selected={ this.selected }
                        setSelected={ this.setSelected }
                        readOnly={ this.readOnly }
                        currentWorkflow={ this.currentWorkflow }
                        isViewer={ this.isViewer }
                        zoom={ this.zoom }
                        decreaseZoom={ this.decreaseZoom }
                        increaseZoom={ this.increaseZoom }
                        resetZoom={ this.resetZoom }
                        adjustZoom={ this.adjustZoom }
                        stageX={ this.stagePos.x }
                        stageY={ this.stagePos.y }
                        setStagePosition={ this.setStagePosition }
                        activityWidth={ activityWidth }
                        activityHeight={ activityHeight }
                        isUserAction={ this.isUserAction }
                    />
                    }
                    { this.isPropertiesPanelOpened && !!this.selected.type &&
                    <PropertiesPanel
                        sys_id={ this.selected.sys_id }
                        type={ this.selected.type }
                        tableName={ selectedActivity && selectedActivity.tableName }
                        header={ selectedActivity ? selectedActivity.name : this.model.typeNames[this.selected.type] }
                        togglePropertiesPanel={ this.togglePropertiesPanel }
                        refreshData={ this.refreshData }
                        loadWorkflow={ this.loadWorkflow }
                        fetchWorkflowList={ this.fetchWorkflowList }
                        readOnly={ this.readOnly }
                        status={ activityStatus }
                    />
                    }
                    <HeaderPanel
                        workflows={ this.opened_workflows }
                        currentWorkflow={ this.currentWorkflow }
                        closeWorkflow={ this.closeWorkflow }
                        loadWorkflow={ this.loadWorkflow }
                        isViewer={ this.isViewer }
                    />
                    <ListBox
                        isBlockWorkflow={ true }
                    />
                    { !!this.currentWorkflow && this.isViewer && <StatePanel
                        state={ this.model.wf_context.state }
                        started_at={ this.model.wf_context.started_at }
                        ended_at={ this.model.wf_context.ended_at }
                    /> }
                    { !!this.currentWorkflow && <ZoomPanel
                        zoom={ this.zoom }
                        increaseZoom={ this.increaseZoom }
                        decreaseZoom={ this.decreaseZoom }
                        isToolsOpened={ this.isLeftPanelOpened && !this.isViewer }
                        resetZoom={ this.resetZoom }
                        adjustZoom={ this.adjustZoom }
                    /> }
                    <FormMessages />
                </div>;
        }
        else {
            //Стартовая страница
            let description;
            const { workflow_titles } = langStore.getTranslate();
            switch (this.mode) {
                case 'index':
                    description = workflow_titles && workflow_titles.description;
                    break;
                case 'new':
                    description = workflow_titles && workflow_titles.create;
                    break;
                case 'open':
                    description = workflow_titles && workflow_titles.open;
                    break;
                default:
                    description = workflow_titles && workflow_titles.description;
            }
            output =
                <>
                    { !this.loading &&
                    <div className={ styles.Welcome }>
                        <WorkflowTitle
                            mode={ this.mode }
                            description={ description }
                            goBack={ () => this.switchMode('index') }
                            goNew={ () => this.switchMode('new') }
                            goOpen={ () => this.switchMode('open') }
                            workflows={ this.model.wf_workflow }
                        />
                        <ListBox
                            isBlockWorkflow={ true }
                        />
                        { this.mode === 'index' && this.model.wf_workflow && (this.model.wf_workflow.length > 0) &&
                        <WorkflowUserRecent
                            workflows={ this.model.wf_workflow }
                            openWorkflow={ this.loadWorkflowFromPanel }
                        />
                        }
                        { this.mode === 'open' &&
                        <WorkflowRecent
                            workflows={ this.model.wf_workflow }
                            openWorkflow={ this.loadWorkflowFromPanel }
                        />
                        }
                        { this.mode === 'new' &&
                        <NewWorkflowPanel
                            isDisabled={ this.disabledButtonCreate }
                            saveNewWorkflow={ this.saveNewWorkflow }
                            toggleCreateButton={ this.toggleCreateButton }
                        />
                        }
                        <HeaderPanel isViewer={ this.isViewer } />
                    </div>
                    }
                    { this.loading && <PageLoader /> }
                </>;
        }

        return output;
    }
}


export default ErrorWrapperHoc(WorkflowPage);
