import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { isEqual } from 'helpers/data';

import styles from 'components/dynamicForms/view/field/template/templateField/styles.module.scss';
import { fetchDotWalkList } from 'actions/conditions';

@observer
export default class TemplateField extends React.Component {
    @observable childrenData = [];
    @observable stringValue = '';
    @observable filteringData = [];
    @observable isActive;
    tableId = '';
    refTemplate = React.createRef();
    refStringCondition = React.createRef();

    constructor(props) {
        super(props);
        if (props.data && props.data.length > 0) {
            this.stringValue = '';
            this.filteringData = props.data;
            this.setValue(props.data, props.value);
        }
        this.isActive = this.props.isActive;
    }

    componentDidUpdate(prevProps) {
        const { data, value } = this.props;
        if (data && data.length > 0 && !isEqual(data, prevProps.data)) {
            this.stringValue = '';
            this.filteringData = data;
            this.setValue(data, value);
        }
        if (prevProps.isActive !== this.props.isActive) {
            this.isActive = this.props.isActive;
        }
        this.setFocus();
    }

    setFocus = () => {
        this.refStringCondition.current && this.refStringCondition.current.focus();
    };

    searchValue = async (data, value) => {
        const findLi = data.find(li => li.dot_walking_attribute === value.dot_walking_attribute && (!value.column_id || li.column_id === value.column_id));
        if (findLi) {
            this.props.onChange(findLi, this.tableId);
            return;
        }
        const values = value.dot_walking_attribute.split('.');
        if (values.length > 1) {
            this.searchSelectedValue(values, data);
        }
    };

    searchSelectedValue = async (values, data) => {
        let compValue = '';
        for (const index in values) {
            if (values.hasOwnProperty(index)) {
                compValue = compValue ? `${ compValue }.${ values[index] }` : values[index];
                if (Number(index) === 0 && values.length > 1) {
                    const findLi = data.find(li => li.dot_walking_attribute === values[index]);
                    await this.fetchData(findLi, index);
                }
                if (index > 0 && values.length - 1 > index && this.childrenData.length > 0 && this.childrenData[index - 1].length > 0) {
                    const findLi = this.childrenData[index - 1].find(li => li.dot_walking_attribute === compValue);
                    await this.fetchData(findLi, index);
                }
            }
        }
    };

    fetchData = async (li, index) => {
        const { value } = this.props;
        const params = {
            referenced_table_id: li.referenced_table_id,
            dot_walking_attribute: li.dot_walking_attribute,
        };
        const response = await fetchDotWalkList(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        this.tableId = data.table_id;
        const items = data.items || data;
        const findLi = items.find(li => li.dot_walking_attribute === value.dot_walking_attribute);
        if (findLi) {
            this.props.onChange(findLi, data.table_id);
        }
        const tempData = this.childrenData.filter((data, i) => index > i);
        this.childrenData = [
            ...tempData,
            items,
        ];
    };

    setValue = (data, value) => {
        if (value && value.dot_walking_attribute) {
            this.searchValue(data, value);
        }
    };

    handleChangeStringValue = (e) => {
        this.stringValue = e.target.value;
        this.filteringData = this.props.data.filter(li => li.column_title.toLowerCase().includes(this.stringValue.toLowerCase()));
    };

    handleClickLi = (li) => () => {
        this.props.onChange(li, this.tableId);
        this.handleToggleShow();
    };

    handleToggleShow = () => {
        const { value } = this.props;
        if (!this.isActive) {
            this.props.setActive(true);
            const values = value.dot_walking_attribute.split('.');
            if (values.length > 1) {
                this.searchSelectedValue(values, data);
            }
            document.addEventListener('mousedown', this.onOuterClick);
        }
        else {
            this.close();
        }
    };

    isDotWalkEquals = (value, row) => {
        return value.dot_walking_attribute === row.dot_walking_attribute;
    };

    isDotWalkIncludes = (value, row) => {
        return value.dot_walking_attribute.includes(`${ row.dot_walking_attribute }.`);
    };

    onOuterClick = (e) => {
        const conditionEl = this.refTemplate.current;

        if (!conditionEl) return false;

        if (!conditionEl.contains(e.target)) {
            this.close();
            e.preventDefault();
            e.stopPropagation();
        }
    };

    close = () => {
        this.childrenData = [];
        this.stringValue = '';
        this.filteringData = this.props.data;
        this.props.setActive(false);
        document.removeEventListener('mousedown', this.onOuterClick);
    };

    renderModalField = () => {
        const { data, value } = this.props;
        if (!data || !this.isActive || value === null) {
            return null;
        }
        this.setFocus();
        const li = this.filteringData.map((jsonLi, liIndex) => {
            const className = (this.isDotWalkEquals(value, jsonLi) || this.isDotWalkIncludes(value, jsonLi)) ? styles.Selected : '';
            return (
                <li
                    key={ `${ jsonLi.dot_walking_attribute }${ liIndex }` }
                    className={ className }
                    onClick={ this.handleClickLi(jsonLi) }
                >
                    { jsonLi.column_title }
                </li>
            );
        });

        return (
            <div className={ styles.CondSelectBlock }>
                <div className={ styles.CondSelectBlockHead }>
                    <div className={ styles.CondSelectBlockFilter }>
                        <input
                            type="text"
                            className={ styles.input }
                            value={ this.stringValue }
                            onChange={ this.handleChangeStringValue }
                            ref={ this.refStringCondition }
                        />
                    </div>
                </div>
                <div className={ styles.CondSelectBlockBody }>
                    <ul className={ styles.CondSelectBlockList }>
                        { li }
                    </ul>
                </div>
            </div>
        );
    };

    render() {
        const { value } = this.props;

        return (
            <div ref={ this.refTemplate } className={ styles.ConditionField }>
                <button
                    className={ styles.CondSelectButton }
                    type="button"
                    onClick={ this.handleToggleShow }
                    disabled={ this.props.readOnly }
                >
                    { value !== null ? value.column_title : '' }
                </button>
                { this.renderModalField() }
            </div>
        );
    }
}
