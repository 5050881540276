import { action, observable } from 'mobx';
import * as _ from 'lodash';
import { fetchChoices, getConditionValue } from 'helpers/condition';
import { templateTypes } from 'constants/template';
import { fetchReferenceDisplayName } from 'actions/conditions';
import langStore from 'globalState/lang';

/**
 * Хранилище для строки с полями поля шаблон
 */
class TemplateRow {
    @observable _id = '';
    @observable _field = {};
    @observable _value = '';
    @observable _choiceOptions = [];

    constructor(data, tableId) {
        if (data) {
            this._id = data.id;
            this._field = data.field;
            this._value = data.value;
            if (data.special && data.special.values) {
                this._choiceOptions = data.special.values;
            } else if (tableId) {
                this._choiceOptions = this.updateChoiceOptions(tableId);
            }
        }
    }

    getId() {
        return this._id;
    }

    @action
    setId(value) {
        this._id = value;
    }

    getField() {
        return this._field;
    }

    @action
    setField(value) {
        this._field = value;
    }

    getValue() {
        return this._value;
    }

    @action
    setValue(value) {
        this._value = value;
    }

    @action
    changeTemplateField = async (fieldValue, tableId, templateState) => {
        const needToClear = fieldValue && this._field && !_.isEqual(fieldValue, this._field); // если новый тип поля не совпадает с существующим - очищаем поля Оператор и Значение
        this._field = typeof fieldValue === 'object' ? { ...fieldValue } : {};
        if (needToClear) {
            if ([
                'list',
                'multiselect',
            ].includes(this._field.condition_type)) {
                this._value = [];
            }
            else {
                this._value = '';
            }
        }
        this._choiceOptions = await this.updateChoiceOptions(tableId); // в конец метода, чтобы не тормозило работу
        const options = this.getTypeOptions();
        if (options.length > 0) {
            this._value = options[0];
        }
        templateState.changeTemplateResult(this._id, this._value);
    };

    @action
    changeTemplateValue = async (value, templateState) => {
        if (this._field && this._field.column_type) {
            const showElement = templateTypes.find(item => item.type === this._field.column_type);
            if (value && typeof value === 'string' && showElement.type === 'reference' && this._field.referenced_table_name) {
                await this.fetchReferenceValue(value);
            } else if (value && Array.isArray(value) && typeof value[0] === 'string' && this._field.referenced_table_name) {
                await this.fetchListValue(value);
            }
            else {
                this._value = getConditionValue(showElement.type, value);
            }
        }
        else {
            this._value = value;
        }
        templateState.changeTemplateResult(this._id, this._value);
    };

    updateChoiceOptions = async (tableId) => {
        let choiceOptions = [];
        if (this._field.column_id && this._field.column_type === 'choice') {
            choiceOptions = await fetchChoices(this._field.column_id, tableId || this._field.referenced_table_id);
        }
        return choiceOptions;
    };

    @action
    fetchReferenceValue = async (refValue) => {
        if (!refValue) {
            return;
        }
        const params = {
            table_name: this._field.referenced_table_name,
            sys_id: refValue,
        };
        const response = await fetchReferenceDisplayName(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        this._value = {
            display_value: data.display_name || '',
            database_value: refValue,
        };
    };

    fetchListValue = async (values) => {
        const result = [];
        for (const value of values) {
            if (typeof value === 'string') {
                const params = {
                    table_name: this._field.referenced_table_name,
                    sys_id: value,
                };
                const response = await fetchReferenceDisplayName(params);
                const data = response.isOkStatus && response.data ? response.data : {};
                result.push({
                    display_value: data.display_name || '',
                    database_value: value,
                    link: '',
                });
            }
        }
        if (result.length > 0) {
            this._value = result;
        }
    };

    getTypeOptions = () => {
        if (!this._field.column_type) {
            return [];
        }

        switch (this._field.column_type) {
            case 'boolean':
                return langStore.getBooleanValue();
            case 'choice':
                return this._choiceOptions;
            default:
                return [];
        }
    };
}

export const TemplateRowState = TemplateRow;

export default new TemplateRow();
