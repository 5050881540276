import * as React from 'react';
import { observer } from 'mobx-react';
import IconX from 'assets/img/icons/close-x.svg';
import styles from './styles.module.scss';
import ModalWrapper from 'components/modalWrapper';

/***
 * Описание: Попап для поля кондишн
 * Параметры:
 * onClose: {required, function} - метод для закрытия попапа *
 */

@observer
export default class Popup extends React.Component {
    constructor(props) {
        super(props);
    }

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    };

    render() {
        return (
            <ModalWrapper>
                <div className={ styles.Popup }>
                    <div className={ styles.PopupContainer }>
                        <div className={ styles.PopupContent }>
                            <div
                                className={ styles.PopupOverlay }
                                onClick={ this.onClose }
                            />
                            <div
                                className={ styles.PopupClose }
                                dangerouslySetInnerHTML={ { __html: IconX } }
                                onClick={ this.onClose }
                            />
                            <div className={styles.PopupChild}>
                                { this.props.children }
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        );
    }
}
