import * as React from 'react';
import { observer } from 'mobx-react';
import { templateStateClass } from 'globalState/template';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';

import styles from 'components/dynamicForms/view/field/template/styles.module.scss';
import TemplateRow from 'components/dynamicForms/view/field/template/templateRow';
import { getTestNameField, isEqual } from 'helpers/data';
import TemplateModel from 'components/dynamicForms/model/field/TemplateModel';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { observable } from "mobx";
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент Template
 * Параметры:
 * onChange: {type: function} - метод для изменения
 * tableId: {type: string} - id таблицы
 * value: {type: string}
 * dependentOnColumn: {type: string} - связь с полем с таблицей
 */
@observer
export default class Template extends React.Component {
    @observable model;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new TemplateModel(props);
        }
        this.initialize();
    }

    initialize = () => {
        this.model.templateState = new templateStateClass();
        this.model.fetchData(this.model.tableId);
        this.model.events();
    };

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
                this.initialize();
            } else {
                this.model.mergeData(this.props);
            }
        }

        const { tableId, value } = this.model;

        if (tableId !== prevProps.model.tableId) {
            this.model.value = {};
            this.model.changed = isChanged(this.model.defaultValue, this.model.value);
            this.model.data = [];
            this.model.templateState.clearData();
            this.model.templateState.addTemplateRow();
            this.model.changed = isChanged(this.model.defaultValue, this.model.value);
            this.model.fetchData(tableId);
        }

        if (value && !isEqual(value, prevProps.model.value)) {
            this.model.value = this.model.templateState.parseValue(value, this.model.data, this.model.tableId);
            this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        }
    }

    renderTemplate = () => {
        const templateFields = this.model.templateState.getTemplateFields();
        const fields = templateFields.map((row, index) => {
            const isActive = this.model.activeTemplate === row.getId();
            return (
                <React.Fragment key={ row.getId() }>
                    <TemplateRow
                        key={ row.getId() }
                        row={ row }
                        tableId={ this.model.tableId }
                        onRemove={ this.model.removeTemplateField }
                        lastIndex={ templateFields.length - 1 }
                        index={ index }
                        data={ this.model.filteringItems() }
                        setActive={ this.model.setActiveTemplate }
                        isActive={ isActive }
                        readOnly={ this.model.readonly || this.model.data.length === 0 }
                        templateState={ this.model.templateState }
                        onChange={ this.model.changeValue }
                    />
                </React.Fragment>
            );
        });

        return (
            <div className={ styles.TemplateConditions }>
                { fields }
            </div>
        );
    };

    renderField = () => {
        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldTemplate }` : `${ ATTRIBUTES.fieldTemplate }`;
        return (
            <div
                className={ styles.Template }
                data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                data-test-visible={ this.model.isVisible }
                data-test-mandatory={ this.model.isMandatory }
                data-test-readonly={ this.model.readonly || this.model.data.length === 0 }
                data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                data-test-field-name={ this.model.sys_column_name }
            >
                { this.renderTemplate() }
            </div>
        );
    };

    render() {
        return (
            <FieldWrapper model={ this.model }>
                { this.renderField() }
            </FieldWrapper>
        );
    }
}
