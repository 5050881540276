import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import * as _ from 'lodash';

import styles from 'components/dynamicForms/view/field/image/styles.module.scss';
import Paperclip from 'assets/img/icons/paperclip-attach.svg';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import Button from 'components/button';
import DragAndDrop from 'components/dragAndDrop';
import Preview from 'components/dynamicForms/view/field/image/preview';
import ImageModel from 'components/dynamicForms/model/field/ImageModel';
import { uploadFile } from 'actions/components/image';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент Image
 * загрузка и удаление одного изображения и превью
 *
 * Параметры:
 * value: {required, type: object} - данные
 'sys_id' => <image_id>,
 'delete_url' => '/v1/image/delete/<image_id>',
 'url' => '/path/to/some_name.jpg',
 'file_size' => 34.53,
 'file_name': 'some_name.jpg',

 * readOnly: {type: boolean} - флаг редактируемости поля
 * isVisible: {type: boolean} - флаг показа поля
 * is_mandatory: {type: boolean} - флаг обязательности
 * fieldMessage: {type: { message: string, type: string }} - сообщение
 * className: {type: array} - кастомный класс стилей

 * column_id: {type: string} - id поля
 */

@observer
export default class Image extends React.Component {
    @observable model;
    @observable showAttachPopup = false;
    @observable showPreviewPopup = false;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new ImageModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    handleCloseAttachPopup = () => {
        this.showAttachPopup = false;
    };

    handleShowAttachPopup = () => {
        this.showAttachPopup = true;
    };

    uploadFileAction = async (fileData) => {
        const requestParams = {
            column_id: this.model.column_id,
            essence: this.model.tableName,
        };

        if (this.model.sysId) {
            requestParams.record_id = this.model.sysId;
        }

        const result = await uploadFile(fileData, requestParams);
        if (result.data) {
            this.model.value = result.data;
            this.model.changed = isChanged(this.model.defaultValue, this.model.value);
            if (this.props.onChange) {
                this.props.onChange(this.model);
            }
            this.showAttachPopup = false;
        }
        return result.response;
    };


    removeFileAction = async () => {
        this.model.value = null;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
        return true;
    };


    render() {
        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldImage }` : `${ ATTRIBUTES.fieldImage }`;
        return (
            <FieldWrapper model={ this.model }>

                <div
                    className={ styles.Wrap }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <Button buttonType='icon-border'
                            svg={ Paperclip }
                            onClick={ this.handleShowAttachPopup }
                            disabled={ this.model.readOnly } />
                    {
                        this.model.value && this.model.value.url && (
                            <div
                                className={ styles.ImageThumbnail }
                                style={ { backgroundImage: `url("${ this.model.value.url }")` } }
                                onClick={ () => {
                                    this.showPreviewPopup = true;
                                } }
                            />
                        )
                    }
                </div>

                <DragAndDrop
                    downloadUrl={ 'image' }
                    key={ this.model.column_id }
                    onClose={ this.handleCloseAttachPopup }
                    show={ this.showAttachPopup }
                    attachments={ this.model.value && [this.model.value] }
                    multiple={ false }
                    uploadFileAction={ this.uploadFileAction }
                    removeFileAction={ this.removeFileAction }
                    columnName={ this.model.name }
                />

                { this.showPreviewPopup && (
                    <Preview
                        downloadUrl={ 'image' }
                        fileId={ this.model.value.sys_id }
                        src={ this.model.value.original_url }
                        name={this.model.value.file_name }
                        onClose={ () => {
                            this.showPreviewPopup = false;
                        } }
                    />
                ) }
            </FieldWrapper>
        );
    }
}
