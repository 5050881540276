import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';

import IconClose from 'assets/img/icons/close-x.svg';
import IconInfo from 'assets/img/icons/info.svg';
import IconSuccess from 'assets/img/icons/check-circle-2.svg';
import IconWarning from 'assets/img/icons/alert-triangle.svg';
import IconError from 'assets/img/icons/alert-octagon.svg';
import InfoMessagesState from 'globalState/infoMessages';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import { observable } from 'mobx';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Button from 'components/button';

/***
 * Описание: Плашка сообщения
 * Параметры:
 * id: {required, type: string} - айдишник сообщения. используется для удаления
 * type: {required, type: string} - тип сообщения(info, warn, success, error)
 * message: {required, type: html} - содержимое сообщения
 * duration: {required, type: number} - длительность показа сообщения
 * className: {type: string} - класс
 */


@observer
export default class MessageComponent extends React.Component {
    @observable isTextExpanded = false;
    @observable showExpandBtn = false;
    @observable oneLineText = false;
    refText = React.createRef();
    refMessage = React.createRef();
    timeout = null;
    timeoutDuration = 4000;
    messageEl;

    constructor(props) {
        super(props);

        if(this.props.duration) {
            this.timeoutDuration = this.props.duration;
        }
    }

    componentDidMount() {
        // используем таймаут для скрытия, если это не сообщение об ошибке, и переданное время таймаута не равно 0
        if (this.props.type !== 'error' || this.props.duration !== 0) {
            this.messageEl = this.refMessage.current;
            if (!this.messageEl) return;
            this.startTimeout();
            this.messageEl.addEventListener('mouseenter', this.cancelTimeout);
            this.messageEl.addEventListener('mouseleave', this.startTimeout);
        }
        this.checkTextHeight();
    }

    startTimeout = () => {
        if (!this.messageEl) return;
        this.timeout = setTimeout(() => {
            this.messageEl.classList.remove(styles.slideInRight);
            this.messageEl.classList.add(styles.bounceOutRight);
            this.messageEl.addEventListener('animationend', this.onAnimationend);
        }, this.timeoutDuration);
    };

    cancelTimeout = () => {
        if (!this.messageEl) return;
        clearTimeout(this.timeout);
        this.messageEl.classList.remove(styles.bounceOutRight);
        this.messageEl.removeEventListener('animationend', this.onAnimationend);
    };

    onAnimationend = () => {
        InfoMessagesState.remove(this.props.id);
    };

    onCloseClick = () => {
        this.cancelTimeout();
        InfoMessagesState.remove(this.props.id);
    };

    checkTextHeight = () => {
        const { current: textEl } = this.refText;
        if (!textEl) return;
        const innerText = textEl.children[0];
        const innerTextHeight = innerText.offsetHeight;
        const textElHeight = textEl.offsetHeight;
        this.oneLineText = innerTextHeight === textElHeight && innerTextHeight === 20;
        this.showExpandBtn = innerTextHeight > textElHeight;
    };

    render() {
        const { className } = this.props;
        let icon = null;
        switch (this.props.type) {
            case 'info':
                icon = IconInfo;
                break;
            case 'error':
                icon = IconError;
                break;
            case 'success':
                icon = IconSuccess;
                break;
            case 'warning':
                icon = IconWarning;
                break;
            default:
                break;
        }

        const message = this.props.message && Array.isArray(this.props.message) ? this.props.message.join('<br/>') : this.props.message;

        return (
            <div
                className={ `${ styles.messageContainer } ${className}` }
                data-test={ this.props['data-test'] ? this.props['data-test'] : '' }
            >
                <div className={ `${ styles.message } ${ styles.slideInRight }` } ref={ this.refMessage } data-test={ ATTRIBUTES.message }>
                    <div className={ styles.messageContent }>
                        { icon && <div className={ `${ styles.messageIcon } ${ styles[this.props.type] }` } dangerouslySetInnerHTML={ { __html: icon } } data-test={ `${ATTRIBUTES.messageType}-${this.props.type}` }/> }
                        <div className={ `${ styles.messageText } ${ this.isTextExpanded ? styles.expanded : '' }` } ref={ this.refText }>
                            <div dangerouslySetInnerHTML={ { __html: message } } data-test={ ATTRIBUTES.messageContentText } />
                        </div>
                        <div className={ `${ styles.messageControls } ${ this.oneLineText ? styles.offsetTop : '' }` }>
                            <Button buttonType={'icon-mini'} onClick={ this.onCloseClick } svg={ IconClose } />
                            { this.showExpandBtn && <Button buttonType={'icon-mini'} onClick={ () => {
                                this.isTextExpanded = !this.isTextExpanded;
                            } } svg={ this.isTextExpanded ? IconChevronUp : IconChevronDown } /> }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
