import { action, observable } from 'mobx/lib/mobx';
import { ActivityStateProps, IActivityFeeds } from 'types/components/activityFeed';
import _ from 'lodash';
import formsState from 'globalState/forms';

class ActivityFeeds implements IActivityFeeds {
    @observable items: ActivityStateProps[] = [];

    @action
    addItem = (item: ActivityStateProps) => {
        const form = formsState.getDynamicFormBySysId(item.getTableName(), item.getSysId());
        if (form) {
            form.isActivityFeedWidget = true;
        }
        if (this.getItem(item.getTableName(), item.getSysId())) {
            return;
        }
        this.items.push(item);
    }

    @action
    removeItem = (removeItem: ActivityStateProps) => {
        this.items = _.filter(this.items, item => item.getTableName() !== removeItem.getTableName()
            && item.getSysId() !== removeItem.getSysId());
    }

    getItem = (tableName: string | null, sysId: string | null) => {
        if (!tableName || !sysId) {
            return null;
        }
        return _.find(this.items, item => item.getTableName() === tableName && item.getSysId() === sysId);
    };

    hasItem = (tableName: string, sysId: string) => {
        return !!this.getItem(tableName, sysId);
    };

    @action
    clear = () => {
        this.items = [];
    }
}

export default new ActivityFeeds();