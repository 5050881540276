import moment from 'moment';
import langStore from 'globalState/lang/index';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/dateTime';

const dateTimeFormat = 'L LTS';
const shortDateTimeFormat = 'L LT';
const dateFormat = 'L';
const timeFormat = 'LTS';
const shortTimeFormat = 'LT';
export const dayMonthYearFormat = 'DD-MM-YYYY';

const maxDateValue = {
    yearFormat: 9999,
    monthFormat: 12,
    dayFormat: 31,
    yearDaysFormat: 99999,
    hoursFormat: 23,
    minutesFormat: 59,
    secondsFormat: 59,
};

const minDateValue = {
    yearFormat: 0,
    monthFormat: 1,
    dayFormat: 1,
    yearDaysFormat: 0,
    hoursFormat: 0,
    minutesFormat: 0,
    secondsFormat: 0,
};

export const dateTimeToString = (date: Date): string => {
    return moment(date).format(dateTimeFormat);
};

export const shortDateTimeToString = (date: Date): string => {
    return moment(date).format(shortDateTimeFormat);
};

export const dateToString = (date: Date): string => {
    return moment(date).format(dateFormat);
};

export const timeToString = (time: Date): string => {
    return moment(time).format(timeFormat);
};

export const shortTimeToString = (time: Date): string => {
    return moment(time).format(shortTimeFormat);
};

export const getFormatFromPHP = (format: string): string => {
    if(!format) return DEFAULT_DATE_TIME_FORMAT;

    return moment.formatPHP(format);
};

export const getDaysOfWeek = (value: number): Array<number> => {
    const values: Array<number> = [];
    const WEEKDAYS = moment.weekdays(true);

    for (let i = 0; i < WEEKDAYS.length; i++) {
        const checkValue = WEEKDAYS.length - i - 1;
        const bitValue = value >>> checkValue;
        if (bitValue & 1) {
            values.push(checkValue);
        }
    }
    return values;
};

export const parseDate = (num: string, maskFormat: string): string => {
    const {date_time = {}} = langStore.getTranslate();

    const yearFormat = date_time && date_time.YYYY ? date_time.YYYY : '';
    const maskLength = maskFormat.trim().length;

    if (yearFormat.includes(maskFormat) && maskLength > num.length) {
        const diff = maskLength - num.length - 1;
        return "2" + "0".repeat(diff) + num;
    }

    if (maskLength > 1 && num.length <= 1) {
        const diff = maskLength - num.length;
        return "0".repeat(diff) + num;
    }
    return num.toString();
};


export const normalizeValue = (value: string, maskFormat: string, reverse = false): string => {
    const {date_time = {}} = langStore.getTranslate();

    const yearFormat = date_time.YYYY;
    const monthFormat = [date_time.M, date_time.MM];
    const dayFormat = [date_time.D, date_time.DD];
    const yearDaysFormat = [date_time.d];
    const hoursFormat = [date_time.hh, date_time.HH, date_time.h];
    const minutesFormat = [date_time.mm, date_time.m];
    const secondsFormat = [date_time.ss, date_time.s];


    if (yearFormat === maskFormat) {
        if (parseInt(value) <= minDateValue.yearFormat) return new Date().getFullYear().toString();
        if (parseInt(value) > maxDateValue.yearFormat) return maxDateValue.yearFormat.toString();
    }

    if (monthFormat.includes(maskFormat)) {
        return checkValue(value, minDateValue.monthFormat, maxDateValue.monthFormat, reverse, maskFormat);
    }

    if (dayFormat.includes(maskFormat)) {
        return checkValue(value, minDateValue.dayFormat, maxDateValue.dayFormat, reverse, maskFormat);
    }

    if (yearDaysFormat.includes(maskFormat)) {
        return checkValue(value, minDateValue.yearFormat, maxDateValue.yearDaysFormat, reverse, maskFormat);
    }

    if (hoursFormat.includes(maskFormat)) {
        return checkValue(value, minDateValue.hoursFormat, maxDateValue.hoursFormat, reverse, maskFormat);
    }

    if (minutesFormat.includes(maskFormat) || secondsFormat.includes(maskFormat)) {
        return checkValue(value, minDateValue.minutesFormat, maxDateValue.secondsFormat, reverse, maskFormat);
    }
    return parseDate(value, maskFormat);
};


const checkValue = (value: string, min: number, max: number, reverse: boolean, maskFormat: string): string => {
    if (parseInt(value) < min) {
        return reverse ? parseDate(max.toString(), maskFormat) : parseDate(min.toString(), maskFormat);
    }
    if (parseInt(value) > max) {
        return reverse ? parseDate(min.toString(), maskFormat) : parseDate(max.toString(), maskFormat);
    }

    return parseDate(value, maskFormat);
};

export const getNow = () => Math.round(new Date().getTime()/1000);
