import * as React from 'react';
import Container from 'components/container';

import LayoutEditor from 'components/layoutEditor';
import ViewSelect from 'components/layoutSelect/viewSelect';
import Button from 'components/button';
import apiRequest from 'lib/apiRequest';

import styles from './styles.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { helperRedirect } from 'helpers/history';
import { getUrlParams, paramsToString } from 'helpers/data';
import SmartTitle from 'components/smartTitle';
import langStore from 'globalState/lang';
import PageLoader from 'components/pageLoader';
import { EnhancedComponent } from 'components/EnhancedComponent';
import { globalEventEmitter } from 'lib/EventEmitter';
import Heading from 'components/heading';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import { ATTRIBUTES } from 'constants/attributesForTests';


@observer
class ListLayoutEdit extends EnhancedComponent {
    @observable data;
    @observable errorMessage = '';
    @observable isSavingData = false;
    selected = [];
    pathNamePersonalLayout = '';
    pathNameCommonLayout = '';
    initialView = '';
    formId = '';

    constructor(props) {
        super(props);
        this.pathNamePersonalLayout = 'list-layout-personal';
        this.pathNameCommonLayout = 'list-layout';
        const parsedSearchParams = getUrlParams(props.location.search);
        this.formId = parsedSearchParams.form_id || '';
        this.initialView = parsedSearchParams.view || '';

        this.useEffect(this.onMount);
        this.useEffect(this.onUpdate, true);
        this.useEffect(this.useImpersonateHandler);
    }

    // Update page data when we do impersonate or deImpersonate
    useImpersonateHandler = () => {
        const handleImpersonate = () => {
            this.fetchData().catch(this.errorFetchData);
        };

        return globalEventEmitter.subscribe(['impersonate', 'deImpersonate'], handleImpersonate);
    };

    onMount = (mount) => {
        if (!mount) return;
        this.fetchData().catch(this.errorFetchData);
    };

    onUpdate = (mount, update, prevProps) => {
        if (!update) return; // ignore onMount
        const routeParams = this.props.location.pathname + this.props.location.search;
        const prevRouteParams = prevProps.location.pathname + prevProps.location.search;
        if (routeParams !== prevRouteParams) {
            this.fetchData().catch(this.errorFetchData);
        }
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    fetchData = async () => {
        const parsedSearchParams = getUrlParams(this.props.location.search);
        parsedSearchParams.personal = this.isPersonalLayout() ? 'true' : 'false';
        if (parsedSearchParams.view || parsedSearchParams.current_view) {
            parsedSearchParams.view = parsedSearchParams.current_view ? parsedSearchParams.current_view : parsedSearchParams.view;
        }
        delete parsedSearchParams.current_view;
        const requestPathName = this.props.location.pathname.replace(this.pathNamePersonalLayout, this.pathNameCommonLayout);
        const response = await new apiRequest('GET ' + requestPathName).qs(parsedSearchParams).send();
        this.data = response.getData();
    };

    errorFetchData = (e) => {
        this.isSavingData = false;
        this.errorMessage = e.message;
    };

    updateSelected = (selected) => {
        this.selected = selected;
    };

    /**
     * Описание: получение признака песонализиварованного layout
     * return { type: boolean }
     */
    isPersonalLayout = () => {
        return this.props.location.pathname.search(this.pathNamePersonalLayout) !== -1 ? true : false;
    };

    /**
     * Описание: получения url для возврата на страницу record`а или list
     * Параметры:
     * useInitialView: { type: boolean } - использовать view полученный из this.props.location.search при загрузке страницы
     * return { type: string } - url для редиректа
     */
    getRedirectPathForBack = (useInitialView = false) => {
        let redirectUrl = '';
        const parsedSearchParams = getUrlParams(this.props.location.search);
        if (parsedSearchParams.table_name && parsedSearchParams.sys_id) {
            redirectUrl = `/record/${ parsedSearchParams.table_name }/${ parsedSearchParams.sys_id }`;
        }
        else {
            redirectUrl = `/list/${ this.data.essence }?` +
                `view=${ useInitialView ? this.initialView : this.data.current_view }`;
        }

        if (parsedSearchParams.condition) {
            redirectUrl += `${!redirectUrl.includes('?') ? '?' : '&'}condition=${encodeURIComponent(parsedSearchParams.condition)}`;
        }

        return redirectUrl;
    };

    /**
     * Описание: получение url для редиректа при смене view
     * Параметры:
     * view: { type: string } - выбранный view
     * return { type: string } - url для редиректа
     */
    getRedirectPath = (view) => {
        const parsedSearchParams = getUrlParams(this.props.location.search);
        parsedSearchParams.current_view = view;
        return `/${ this.isPersonalLayout() ? this.pathNamePersonalLayout : this.pathNameCommonLayout }/` +
            `${ this.data.essence }${ paramsToString(parsedSearchParams) }`;
    };

    prepareData = (view = '') => {
        const data = {
            view: view ? view : this.data.current_view,
        };
        if (this.formId) {
            data.form_id = this.formId;
        }
        data.personal = this.isPersonalLayout();
        return data;
    };

    /**
     * Описание: сброс песонализированных настроек layout к настройкам по умолчанию
     */
    resetToDefault = async () => {
        const data = this.prepareData();
        const result = await new apiRequest(`POST /${ this.pathNameCommonLayout }/reset/${ this.data.essence }`).sendJSON(data).catch(console.error);
        if (result) {
            helperRedirect(this.getRedirectPathForBack());
        }
    };

    saveSelected = async () => {
        this.isSavingData = true;
        const data = this.prepareData();
        data.selected_ids = this.selected;

        await new apiRequest(`POST /${ this.pathNameCommonLayout }/${ this.data.essence }`).sendJSON(data).catch(this.errorFetchData).catch(console.error);
        helperRedirect(this.getRedirectPathForBack());
    };

    handleClose = () => {
        helperRedirect(this.getRedirectPathForBack(true));
    };

    addView = async (view) => {
        const data = this.prepareData(view);
        const result = await new apiRequest(`PUT /${ this.pathNameCommonLayout }/${ this.data.essence }`).sendJSON(data).catch(console.error);
        if (result) {
            helperRedirect(this.getRedirectPath(view));
        }
    };

    switchView = async (view) => {
        helperRedirect(this.getRedirectPath(view));
    };

    render() {
        const { list_layout_titles = {} } = langStore.getTranslate();
        if (this.errorMessage) return <div>{ this.errorMessage }</div>;
        if (!this.data) return <PageLoader />;

        return (
            <Container>
                <div className={ styles.all }>
                    <div className={ styles.actionsTop }>
                        <div className={ styles.title }>
                            <Heading size={'h3'} isRegular noMargin><SmartTitle>{ list_layout_titles.list_layout_configure }</SmartTitle></Heading>
                        </div>
                        <Button
                            className={ styles.button }
                            data-loader={ this.isSavingData }
                            onClick={ this.saveSelected }
                            buttonType='primary'
                            data-test={ ATTRIBUTES.listlayoutSaveButton }
                        >
                            { list_layout_titles.save_button }
                        </Button>
                        <Button
                            onClick={ this.handleClose }
                            data-test={ ATTRIBUTES.listlayoutCancelButton }
                        >
                            { list_layout_titles.cancel_button }
                        </Button>
                    </div>
                    <LayoutEditor onChange={ this.updateSelected }
                                  lang={ list_layout_titles }
                                  selectedItems={ this.data.selected_items }
                                  availableItems={ this.data.available_items }
                                  showSplitButtons={ this.data.show_split_buttons }
                                  location={ this.props.location }
                    />
                    <Heading size={'h4'}>{ list_layout_titles.list_view }</Heading>
                    <div className={ styles.bottom }>
                        <ViewSelect
                            current={ this.data.current_view }
                            onAdd={ this.addView }
                            onSelect={ this.switchView }
                            items={ this.data.available_views }
                            lang={ list_layout_titles }
                            doNotShowAddButton={ this.isPersonalLayout() }
                        />
                    </div>
                    { this.isPersonalLayout() && <Button
                        onClick={ this.resetToDefault }
                        data-test={ ATTRIBUTES.listlayoutResetToDefaultButton }
                    >
                        { list_layout_titles.reset_default }
                    </Button> }
                </div>
            </Container>
        );
    }

}


export default ErrorWrapperHoc(ListLayoutEdit);
