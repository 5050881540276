import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';

export const fetchConditionParse = async (params) => {
    const response = await new apiRequest(`GET /condition/parse`)
        .qs(params)
        .caching()
        .send();
    return handleResponse(response);
};

// получение списка значений для choice полей
export const fetchTableChoices = async (params) => {
    const response = await new apiRequest(`GET /choice/list-set`)
        .qs(params)
        .caching()
        .send();
    return handleResponse(response);
};

// получение списка значений для динамических опций
export const fetchDynamicFilterOptions = async (params) => {
    const response = await new apiRequest('GET /filter/dynamic-filter-options')
        .qs(params)
        .caching()
        .send();
    return handleResponse(response);
};

//Получение списка динамических значений для поля значений FT0000135
export const fetchDynamicOpts = async (params) => {
    // params - объект с полями 'operator', 'table_id', 'column_id'
    const response = await new apiRequest('GET /condition/opts')
        .qs(params)
        .caching()
        .send();
    return handleResponse(response);
};

// получение display_value для reference поля
export const fetchReferenceDisplayName = async (params) => {
    const response = await new apiRequest('GET /field/display-name').qs(params).send();
    return handleResponse(response);
};

export const fetchDotWalkList = async (params) => {
    const response = await new apiRequest('GET /condition/dot-walk')
        .qs(params)
        .caching()
        .send();
    return handleResponse(response);
};


export default {
    fetchConditionParse,
    fetchTableChoices,
    fetchDynamicFilterOptions,
    fetchReferenceDisplayName,
    fetchDynamicOpts,
};
