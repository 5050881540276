import * as React from 'react';
import { observable } from 'mobx';
import _ from 'lodash';
import BaseFormFieldModel from './BaseFormFieldModel';

export default class TextAreaModel extends BaseFormFieldModel {

    /**
     *
     * cell
     *
     * @type {Object}
     */
    cell;

    /**
     * максимальная высота для расширения
     * maxHeight
     *
     * @type {number}
     */
    maxHeight;

    /**
     * class для компонента
     * className
     *
     * @type {string}
     */
    className;


    /**
     * placeholder
     * placeholder
     *
     * @type {string}
     */
    @observable
    placeholder;

    /**
     * @type {*}
     */
    options;

    columnType = 'text';

    /**
     *
     * @type {{current: null}}
     */
    refCont = React.createRef();

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        super.mergeData(data);
    }

    setValue(value) {
        return !_.isNil(value) ? value : '';
    }

    setAreaHeight() {
        const maxHeight = this.maxHeight ? this.maxHeight : 500;
        if (!this.refCont || !this.ref || !this.refCont.current || !this.ref.current) {
            return;
        }
        const textAreaEl = this.ref.current;
        const containerEl = this.refCont.current;

        if (!containerEl) {
            console.warn(`Field ${ this.name } doesn't exists`);
            return;
        }

        // задаём контейнеру необходимые для расчёта высоты стили, и наполняем его содержимым textArea
        const textAreaStyle = window.getComputedStyle(textAreaEl);
        containerEl.style.borderTopStyle = textAreaStyle.getPropertyValue('border-top-style');
        containerEl.style.borderTopWidth = textAreaStyle.getPropertyValue('border-top-width');
        containerEl.style.borderTopColor = textAreaStyle.getPropertyValue('border-top-color');
        containerEl.style.borderRightStyle = textAreaStyle.getPropertyValue('border-right-style');
        containerEl.style.borderRightWidth = textAreaStyle.getPropertyValue('border-right-width');
        containerEl.style.borderRightColor = textAreaStyle.getPropertyValue('border-right-color');
        containerEl.style.borderBottomStyle = textAreaStyle.getPropertyValue('border-bottom-style');
        containerEl.style.borderBottomWidth = textAreaStyle.getPropertyValue('border-bottom-width');
        containerEl.style.borderBottomColor = textAreaStyle.getPropertyValue('border-bottom-color');
        containerEl.style.borderLeftStyle = textAreaStyle.getPropertyValue('border-left-style');
        containerEl.style.borderLeftWidth = textAreaStyle.getPropertyValue('border-left-width');
        containerEl.style.borderLeftColor = textAreaStyle.getPropertyValue('border-left-color');
        containerEl.style.paddingTop = textAreaStyle.getPropertyValue('padding-top');
        containerEl.style.paddingRight = textAreaStyle.getPropertyValue('padding-right');
        containerEl.style.paddingBottom = textAreaStyle.getPropertyValue('padding-bottom');
        containerEl.style.paddingLeft = textAreaStyle.getPropertyValue('padding-left');
        containerEl.style.lineHeight = textAreaStyle.getPropertyValue('line-height');
        containerEl.style.fontSize = textAreaStyle.getPropertyValue('font-size');
        containerEl.style.fontFamily = textAreaStyle.getPropertyValue('font-family');
        containerEl.style.minHeight = textAreaStyle.getPropertyValue('min-height');
        containerEl.style.width = textAreaEl.offsetWidth + 'px';
        containerEl.innerHTML = this.value
            ? this.value.toString()
                  .replace(/</g, '&lt;')
                  .replace(/>/g, '&gt;')
                  .replace(/\n/g, '<br/>')
            + ' &nbsp;'
            : '';

        // расчитываем высоту для textArea
        const containerHeight = Math.ceil(containerEl.getBoundingClientRect().height);
        const textAreaHeight = containerHeight > maxHeight ? maxHeight : containerHeight;
        textAreaEl.style.height = textAreaHeight + 'px';
        if (textAreaHeight === maxHeight) {
            textAreaEl.style.overflowY = 'scroll';
        }
        else {
            textAreaEl.style.overflowY = 'hidden';
        }
    }

    uiSetValue(value) {
        super.uiSetValue(value);
        this.setAreaHeight();
    }
}
