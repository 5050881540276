import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { observable } from "mobx";
import { observer } from 'mobx-react';
import _ from 'lodash';
import styles from './styles.module.scss';
import Reference from 'components/dynamicForms/view/field/reference';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import Badges from 'components/dynamicForms/view/field/list/badges';
import ListModel from 'components/dynamicForms/model/field/ListModel';
import ReferenceModel from 'components/dynamicForms/model/field/ReferenceModel';
import reportState from 'globalState/report';
import subFormState from 'globalState/subForm';
import { isMedia } from 'helpers/html';
import { getTestNameField } from 'helpers/data';
import { isChanged } from 'helpers/form';
import IconClose from 'assets/img/icons/close-circle.svg';
import IconSearch from 'assets/img/icons/search.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { ListProps } from 'types/components/dynamicForms/view/field/list/list';
import {BadgeValue} from 'types/components/dynamicForms/view/field/list/badges';

const TABLE_NAME_CMBD_CI = 'sys_cmdb_ci';


/**
 * Описание: компонент List
 * Параметры:
 * onChange: {required, type: function} - метод для изменения значения
 * className: {type: string} - class css
 * special: {type: object} - параметры
 * value: {type: array}
 * term: {type: string} - значение ввода
 * cellEditMode: {type: boolean} - компонент используется в редакторе ячеек листа
 * isWindow - открыто в словаре
 */
@observer
export class List extends React.Component<ListProps> {
    @observable model: ListModel;
    @observable hideFocusBtn = false;
    refContainer = React.createRef<HTMLDivElement>();
    refContent = React.createRef<HTMLDivElement>();
    refOverlay = React.createRef<HTMLDivElement>();
    refBadges = React.createRef<HTMLDivElement>();
    refField = React.createRef<HTMLDivElement>();
    refReferenceInput = React.createRef<HTMLInputElement>();
    referenceModel: ReferenceModel;
    dataTest;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        }
        else {
            this.model = new ListModel(props);
        }

        this.referenceModel = new ReferenceModel({
            value: '',
            usedByList: true,
            isWindow: this.model.isWindow,
            is_mandatory: this.model.isMandatory,
            term: this.model.term,
            special: this.model.special,
            readonly: this.model.readonly,
            sysColumnName: this.model.sysColumnName,
            extraAttributes: this.model.extraAttributes,
            sysColumnId: this.model.sysColumnId,
            sysTableName: this.model.sysTableName,
            forReference: this.model.forReference,
            form: this.model.form,
            save: this.model.save,
            cellSysId: this.model.tableId,
            cellEditMode: this.model.cellEditMode,
            listLabel: this.model.label,
            columnId: this.model.columnId,
            tableName: this.model.tableName,
            parentFormSectionModel: this.model.parentFormSectionModel,
            fieldId: this.model.fieldId,
            allowRunScripts: false,
        });
    }

    componentDidMount() {
        this.model.mergeData(this.props);
        if (this.model.cellEditMode) {
            if (this.refReferenceInput.current) {
                this.refReferenceInput.current.style.display = 'block';
                this.refReferenceInput.current.focus();
                this.model.isFocused = true;
            }
        }
        this.setContainerHeight();
        window.addEventListener('resize', this.setContainerHeight);
        document.addEventListener('click', this.onDocumentClick);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setContainerHeight);
        document.removeEventListener('click', this.onDocumentClick);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            }
            else {
                this.model.mergeData(this.props);
            }

            this.referenceModel.mergeData({
                term: this.model.term,
                special: this.model.special,
                readonly: this.model.readonly,
                sysColumnName: this.model.sysColumnName,
                sysTableName: this.model.sysTableName,
                forReference: this.model.forReference,
                form: this.model.form,
                save: this.model.save,
                cellEditMode: this.model.cellEditMode,
                listLabel: this.model.label,
                columnId: this.model.columnId,
                tableName: this.model.tableName,
                parentFormSectionModel: this.model.parentFormSectionModel,
            });
        }
        this.setContainerHeight();
    }

    setContainerHeight = () => {
        const container = this.refContainer.current;
        const content = this.refContent.current;
        if (container && content) {
            container.style.height = content.offsetHeight + 'px';
        }
    };

    removeItem = (selected) => {
        this.model.mergeData({
            value: this.model.value.filter((item) => item.database_value !== selected.database_value),
        });
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    removeAll = () => {
        this.model.mergeData({
            value: [],
        });
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }

        // в режиме инлайн-редактирования ставим фокус на поле
        if (this.model.cellEditMode) {
            setTimeout(() => {
                const input = this.refReferenceInput.current;
                if (input) {
                    document.removeEventListener('click', this.onDocumentClick);
                    input.style.display = 'block';
                    input.focus();
                    this.model.isFocused = true;
                    document.addEventListener('click', this.onDocumentClick);
                }
            }, 100);
        }
    };

    getLink(sys_id) {
        const referenceTableName = this.model.special.table_name;
        if (!referenceTableName) return '';
        if (referenceTableName === TABLE_NAME_CMBD_CI) {
            return `/visual/dependency?sys_id=${ sys_id }&essence=${ referenceTableName }&script=CI`;
        }
        else {
            return `/record/${ referenceTableName }/${ sys_id }`;
        }
    }

    add = ({ value, term }) => {
        if (Array.isArray(value)) {
            const data = (this.props.value || []) as BadgeValue[];
            _.forEach(value, (item) => {
                const { database_value, display_value, reference_state, has_delegate } = item;
                data.push({
                    database_value,
                    display_value,
                    has_delegate,
                    reference_state: reference_state || null,
                });
            });
            this.model.mergeData({
                value: _.uniqBy(data as any, 'database_value'),
            });
        }
        else {
            if (value && value.database_value) {
                this.model.isUpdatedFromDropdown = true;
                let data = this.model.value ? [...this.model.value] : [];
                data.push({
                    database_value: value.database_value,
                    display_value: value.display_value,
                    has_delegate: value.has_delegate,
                    reference_state: value.reference_state || null,
                });
                this.model.mergeData({
                    value: _.uniqBy(data, 'database_value'),
                });
            }
            else if (term !== undefined) {
                this.model.mergeData({
                    term: term,
                    value: this.model.value ? [...this.model.value] : [],
                });
            }
        }
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    onDocumentClick = (e) => {
        const { current: overlayEl } = this.refOverlay;
        const { current: referenceInputEl } = this.refReferenceInput;
        const selection = window.getSelection();

        if (!overlayEl || !referenceInputEl || !selection) return;

        const selText = selection.toString();
        const referenceValue = this.referenceModel.displayValue as string;
        if (referenceValue.trim() !== '' && selText !== '' && referenceValue.includes(selText) && selection.anchorNode === referenceInputEl.parentNode) {
            // случай когда текст в инпуте был выделен мышью
            return;
        }

        this.referenceModel.displayValue = '';
        if (overlayEl.contains(e.target) || referenceInputEl.contains(e.target)) {
            referenceInputEl.style.display = 'block';
            referenceInputEl.focus();
            this.model.isFocused = true;
        }
        else {
            this.referenceModel.term = '';
            if (this.model.isUpdatedFromDropdown && referenceInputEl.style.display === 'block') {
                this.model.isUpdatedFromDropdown = false;
                referenceInputEl.focus();
                this.model.isFocused = true;
            }
            else {
                referenceInputEl.style.display = 'none';
                this.model.isFocused = false;
            }
        }
        this.setContainerHeight();
    };

    onBadgesClick = () => {
        const input = this.refReferenceInput.current;
        if (isMedia('sm') && !!input) {
            document.removeEventListener('click', this.onDocumentClick);

            input.style.display = 'block';
            input.focus();
            this.model.isFocused = true;

            document.addEventListener('click', this.onDocumentClick);
        }
    };

    onOverlayFocus = () => {
        const { value } = this.model;
        const input = this.refReferenceInput.current;
        if (value.length === 0 && input) {
            input.style.display = 'block';
            input.focus();
            this.model.isFocused = true;
        }
    };

    onOverlayKeyDown = (e) => {
        const input = this.refReferenceInput.current;
        if (input && e.key === 'Enter') {
            input.style.display = 'block';
            input.focus();
            this.model.isFocused = true;
        }
    };

    onInputTabBlur = (e) => {
        const input = this.refReferenceInput.current;
        if (input) {
            if (e.shiftKey) {
                this.hideFocusBtn = true;
                setTimeout(() => { this.hideFocusBtn = false },200);
            }
            input.style.display = 'none';
            this.model.isFocused = false;
            this.model.isHovered = false;
        }
    };

    getEditableView = () => {
        const { value, isWindow, special, columnId, sysColumnId } = this.model;
        const { formName, location } = this.props;

        const isWorkflow = location?.pathname === '/workflow';
        const canCreate = !this.model.parentFormSectionModel?.parentFormModel?.isSubForm && special.can_create && _.isEmpty(reportState.getTypes())
            && !subFormState.getIsShow() && !isWorkflow;
        return (
            <React.Fragment>
                <div className={ `${ styles.Field } ${ canCreate ? styles.CanCreate : '' } ${ isWindow ? styles.isWindow : '' }  ${ this.model.isHovered ? styles.hovered : '' } ${ this.model.isFocused ? styles.focused : '' }` } ref={ this.refField }>
                    {!this.hideFocusBtn && <button
                        type={'button'}
                        onFocus={ this.onOverlayFocus }
                        onKeyDown={ this.onOverlayKeyDown }
                        className={ styles.FocusOverlay }
                    /> }
                    <div
                        ref={ this.refOverlay }
                        onMouseEnter={ () => {
                            this.model.isHovered = true;
                        } }
                        onMouseLeave={ () => {
                            this.model.isHovered = false;
                        } }
                        className={ styles.FieldOverlay }
                    >
                        { isWindow && value.length === 0 && !this.model.isFocused && <div className={ styles.FieldSearch } dangerouslySetInnerHTML={ { __html: IconSearch } } data-test={ ATTRIBUTES.fieldListSearchButton } /> }
                    </div>
                    {
                        value && value.length !== 0 && (
                            <div ref={ this.refBadges } onMouseEnter={ () => {
                                this.model.isHovered = true;
                            } } onMouseLeave={ () => {
                                this.model.isHovered = false;
                            } }>
                                <Badges
                                    isWindow={isWindow}
                                    onClick={ this.onBadgesClick }
                                    values={ _.uniqBy(value as BadgeValue[], 'database_value') }
                                    tableName={ this.model.special.table_name }
                                    columnId={columnId || sysColumnId}
                                    onRemoveItem={ this.removeItem }
                                />
                                <div
                                    onClick={ this.removeAll }
                                     className={ styles.FieldClear }
                                     dangerouslySetInnerHTML={ { __html: IconClose } }
                                     data-test={ ATTRIBUTES.fieldListClearButton }
                                />
                            </div>
                        )
                    }
                    <Reference
                        model={ this.referenceModel }
                        refField={ this.refField }
                        key="reference"
                        onChange={ this.add }
                        onTabBlur={ this.onInputTabBlur }
                        ref={ this.refReferenceInput }
                        formName={ formName }
                    />
                </div>
            </React.Fragment>
        );
    };

    getReadOnlyView = () => {
        const { value, isWindow } = this.model;
        const items = value && Array.isArray(value) ?
            <Badges isWindow={isWindow} values={ _.uniqBy(value, 'database_value') } tableName={ this.model.special.table_name } readOnly />
            : null;
        return <div className={ styles.StaticList }>{ items }</div>;
    };

    render() {
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ styles.ListWrap }
                    ref={ this.refContainer }
                    data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldList }` }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ (this.model as any).sys_column_name ? (this.model as any).column_type : undefined }
                    data-test-field-name={ (this.model as any).sys_column_name }
                >
                    <div className={ styles.Content } ref={ this.refContent }>
                        { !this.model.readOnly ? this.getEditableView() : this.getReadOnlyView() }
                    </div>
                </div>
            </FieldWrapper>
        );
    }
}

export default withRouter(List);
