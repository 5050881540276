import * as React from 'react';
import ModalWindow from 'components/modalWindow';
import SimpleModal from 'ui-actions-scripts/simple-modal';
import modalDataState from 'globalState/modal';
import { observer } from 'mobx-react';
import modalsDataState from 'globalState/modals';

@observer
export default class uiModal extends React.Component {
    state;

    constructor(props) {
        super(props);

        this.state = props.modalState ? props.modalState : modalDataState;
        if (!window.s_modal && !props.modalState) {
            window.s_modal = new SimpleModal();
        }
    }

    componentWillUnmount() {
        const id = this.state.getId();
        if (id) {
            modalsDataState.removeModal(id);
        }
    }

    getReactModal = () => {
        const modalStyles = {
            width: this.state.getWidth() + 'px',
        };

        return (
            <ModalWindow
                isShow={ this.state.getShow() }
                modalStyles={ modalStyles }
                doClose={ this.state.setShow }
                title={ this.state.getTitle() }
                isLoading={ this.state.getIsLoading() }
                isFullScreenMobile
            >
               { this.state.getElement()}
            </ModalWindow>
        );
    };

    render() {
        return (
            <div>{ this.getReactModal() }</div>
        );
    }
}
