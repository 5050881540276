import * as React from 'react';
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import Editor from 'ckeditor4-react';

import styles from 'components/dynamicForms/view/field/htmlInput/styles.module.scss';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import HtmlInputModel from 'components/dynamicForms/model/field/HtmlInputModel';
import langStore from 'globalState/lang';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { observable } from 'mobx';
import { isChanged } from 'helpers/form';

require('components/dynamicForms/view/field/htmlInput/custom.css');

@observer
export default class HtmlInput extends React.Component {
    @observable model;

    saveTimerId;

    @observable ckeditorValue;

    preparedValue;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        }
        else {
            this.model = new HtmlInputModel(props);
        }
        this.ckeditorValue = this.model.value;
        Editor.editorUrl = '/assets/lib/ckeditor/ckeditor.js';
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            }
            else {
                this.model.mergeData(this.props);
            }
            if (!_.isEqual(this.preparedValue, this.model.value)) {
                this.ckeditorValue = this.model.value;
            }
        }
    }

    componentWillUnmount() {
        // Глобальный метод проверяет, отрыто ли диалоговое окно
        if (window.CKEDITOR && CKEDITOR.dialog.getCurrent()) {
            CKEDITOR.dialog.getCurrent().hide();
        }
    }

    onChange = (evt) => {
        this.throttle(() => this.updateValue(evt), 500);
    };

    updateValue = async (evt) => {
        this.preparedValue = this.model.value = await this.cleanStyles(evt.editor.getData());
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
    };

    throttle = (func, delay) => {
        if (this.saveTimerId) {
            return;
        }
        this.saveTimerId = setTimeout(() => {
            func(),
                this.saveTimerId = undefined;
        }, delay);
    };

    handlePaste = ({ data, editor, cancel }) => {
        if (data.dataTransfer.getFilesCount() > 0) {
            // находим изображение
            for (let i = 0; i < data.dataTransfer.getFilesCount(); i++) {
                // представляем изображение в виде файла
                const file = data.dataTransfer.getFile(i);
                if (file.type.indexOf('image') !== -1) {
                    // аналог preventDefault
                    cancel();
                    const reader = new FileReader();
                    reader.onload = (evt) => {
                        const element = editor.document.createElement('img', {
                            attributes: {
                                src: evt.target.result,
                            },
                        });

                        setTimeout(() => {
                            editor.insertElement(element);
                        }, 0);
                    };

                    reader.readAsDataURL(file);
                }
            }
        }
    };


    onBeforeLoad = (editor) => {
        editor.disableAutoInline = true;
    };

    cleanStyles = (editorValue) => {
        return editorValue.replace('white-space: nowrap;', '');
    };

    renderBodyField() {
        if (!this.model) {
            return null;
        }
        if (this.model.readOnly) {
            return (
                <div
                    className={ styles.frame + ' ' + styles.readOnly }
                    dangerouslySetInnerHTML={ { __html: this.ckeditorValue } }
                />
            );
        }

        const config = {
            language: langStore.getLang(),
            toolbar: [
                ['Maximize'],
                [
                    'Undo',
                    'Redo',
                ],
                ['Find'],
                [
                    'Link',
                    'Unlink',
                ],
                [
                    'Image',
                    'Table',
                    'HorizontalRule',
                    'SpecialChar',
                ],
                [
                    'Bold',
                    'Italic',
                    'Underline',
                    'Strike',
                    'Subscript',
                    'Superscript',
                ],
                [
                    'CopyFormatting',
                    'RemoveFormat',
                ],
                [
                    'NumberedList',
                    'BulletedList',
                ],
                [
                    'Outdent',
                    'Indent',
                ],
                [
                    'Blockquote',
                    'CreateDiv',
                ],
                [
                    'JustifyLeft',
                    'JustifyCenter',
                    'JustifyRight',
                    'JustifyBlock',
                ],
                ['Styles'],
                ['Format'],
                ['Font'],
                ['FontSize'],
                [
                    'TextColor',
                    'BGColor',
                ],
                ['Source'],
            ],
            extraPlugins: ['tableresize, tabletools'],
            removePlugins: 'about, flash, iframe',
            pasteFilter: null,
            fullPage: true,
            allowedContent: true,
        };

        return <Editor
            ref={ this.model.ref }
            onBeforeLoad={ this.onBeforeLoad }
            onChange={ this.onChange }
            onPaste={ this.handlePaste }
            data={ this.ckeditorValue }
            config={ config }
        />;
    }


    render() {
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ styles.HTMLInput }
                    data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldHtml }` }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    { this.renderBodyField() }
                </div>
            </FieldWrapper>
        );
    }
}
