import { action, observable } from 'mobx';
import Logo from 'assets/img/logo.svg';
import LogoSmall from 'assets/img/logo-small.svg';
import Background from 'assets/img/servicePortal/bgMain.png';
import {fetchBranding} from "actions/branding";
import {BrandingData} from "types/globalState/branding";

/**
 * Описание: класс для работы с данными о брендировании
 * */
class Branding {
    @observable branding: BrandingData = {};

    @action
    async getBrandingData() {
        if (Object.keys(this.branding).length !== 0) {
            return;
        }

        const { isOkStatus, data } = await fetchBranding();

        if (isOkStatus) {
            this.setBrandingData(data['ui.branding.logo'], 'logo');
            this.setBrandingData(data['ui.branding.logoSmall'], 'logoSmall');
            this.setBrandingData(data['ui.branding.favicon'], 'favicon');
            this.setBrandingData(data['ui.branding.pageTitle'], 'pageTitle');

            this.setBrandingData(data['sp.branding.logo'], 'logoServicePortal');
            this.setBrandingData(data['sp.branding.logoSmall'], 'logoSmallServicePortal');
            this.setBrandingData(data['sp.branding.favicon'], 'faviconServicePortal');
            this.setBrandingData(data['sp.branding.bgMain'], 'backgroundServicePortal');
            this.setBrandingData(data['sp.branding.pageTitle'], 'pageTitleServicePortal');
        }
    }

    setBrandingData = (param, prop) => {
        if (param && param !== 'Default') {
            this.branding[prop] = param;
            return;
        }
        this.branding[prop] = param || '';
    };

    getPlatformLogo = (): string => {
        if (this.branding.logo) {
            return this.branding.logo === 'Default' ? `data:image/svg+xml;base64,${ btoa(Logo) }` : `${ this.branding.logo }`;
        }
        return '';
    };

    getPlatformSmallLogo = (): string => {
        if (this.branding.logoSmall) {
            return this.branding.logoSmall === 'Default' ? `url(data:image/svg+xml;base64,${ btoa(LogoSmall) })` : `url("${ this.branding.logoSmall }")`;
        }
        return '';
    };

    setPlatformFavicon = () => {
        if (this.branding.favicon && this.branding.favicon !== 'Default') {
            this.setFavicon(this.branding.favicon);
        } else {
            this.setFavicon('/favicon.png');
        }
    };

    getPlatformPageTitle = (): string => {
        if (this.branding.pageTitle) {
            return this.branding.pageTitle;
        }
        return 'Simple';
    };

    getServicePortalLogo = (): string => {
        if (this.branding.logoServicePortal) {
            return this.branding.logoServicePortal === 'Default' ? `url(data:image/svg+xml;base64,${ btoa(Logo) })` : `url("${ this.branding.logoServicePortal }")`;
        }
        return '';
    };

    setServicePortalFavicon = () => {
        if (this.branding.faviconServicePortal && this.branding.faviconServicePortal !== 'Default') {
            this.setFavicon(this.branding.faviconServicePortal);
        } else {
            this.setFavicon('/favicon.png');
        }
    };

    getServicePortalBackground = (): string => {
        if (this.branding.backgroundServicePortal) {
            return this.branding.backgroundServicePortal === 'Default' ? `url("${ Background }")` : `url("${ this.branding.backgroundServicePortal }")`;
        }
        return '';
    };

    getServicePortalPageTitle = (): string => {
        if (this.branding.pageTitleServicePortal) {
            return this.branding.pageTitleServicePortal;
        }
        return 'Simple';
    };

    setFavicon = (favicon: string) => {
        const elemLink = document.querySelector('link[rel="shortcut icon"]') as HTMLLinkElement;
        if (elemLink) {
            elemLink.href = favicon;
        } else {
            let link = document.createElement('link');
            link.rel = 'shortcut icon';
            link.href = favicon;
            document.head.appendChild(link);
        }
    };
}

export default new Branding();
