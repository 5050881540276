import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import React, {useCallback, useEffect, useState, useRef} from 'react';
import {
    SELECTION_CHANGE_COMMAND,
    $getSelection,
    $isRangeSelection,
    createCommand, $isRootNode, COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_LOW,
} from 'lexical';
import {$isLinkNode, $createLinkNode} from '@lexical/link';
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils';
import {
    $isListNode,
    ListNode,
} from '@lexical/list';
import {
    $isHeadingNode,
} from '@lexical/rich-text';
import styles from './styles.module.scss';
import {Divider, ToolbarItems} from "./ToolbarItems";
import {ToolbarItem} from "./ToolbarItem";
import {getSelectedNode} from "./helpers";
import { ATTRIBUTES } from 'constants/attributesForTests';
import IconFormat from "assets/img/icons/text-format-size.svg";
import langStore from "globalState/lang";

export default function ToolbarPlugin({isShowToolbar, setIsShowToolbar}) {
    const [editor] = useLexicalComposerContext();
    const isReadOnly = !editor.isEditable();

    const [containItems, setContainItems] = useState(0);
    const refToolbar = useRef<HTMLDivElement>(null);

    const [blockType, setBlockType] = useState('paragraph');
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isLink, setIsLink] = useState(false);

    const INSERT_LINK_COMMAND = createCommand();

    const { rich_text } = langStore.getTranslate();

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                }
            }
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                INSERT_LINK_COMMAND,
                (payload: string) => {
                    const selection = $getSelection();
                    if ($isRangeSelection(selection)) {
                        if ($isRootNode(selection.anchor.getNode())) {
                            selection.insertParagraph();
                        }

                        const linkNode = $createLinkNode(payload);
                        selection.insertNodes([linkNode]);
                    }
                    return true;
                },
                COMMAND_PRIORITY_EDITOR,
            ),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [
        editor,
        updateToolbar,
    ]);

    useEffect(() => {
        const widthToolbar = refToolbar.current?.offsetWidth as number;
        const toolbarElementWidth = 48;
        const containItems = Math.floor(widthToolbar / toolbarElementWidth) - 1;
        setContainItems(containItems);
    }, []);

    return (
        <div ref={refToolbar}>
            {!isReadOnly && <div className={styles.Toolbar}>
                <ToolbarItem
                    onClick={() => {
                        setIsShowToolbar(!isShowToolbar);
                    }}
                    isActive={isShowToolbar}
                    icon={IconFormat}
                    dataTest={ATTRIBUTES.editorToggleIcon}
                >
                    {rich_text?.text_formatting}
                </ToolbarItem>

                <Divider/>

                <ToolbarItems
                    isShowMainItems={isShowToolbar}
                    containItems={containItems}
                    blockType={blockType}
                    isBold={isBold}
                    isItalic={isItalic}
                    isUnderline={isUnderline}
                    isStrikethrough={isStrikethrough}
                    isLink={isLink}
                    isNeedListen
                    isShowLinkItem
                    isShowImageItem
                />
            </div>}
        </div>
    );
}
