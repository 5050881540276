import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';


/**
 * Описание: компонент ErrorBoundary - обертка для отлова ошибок
 * Параметры:
 * children - дочерние элементы
 */
@observer
class ErrorBoundary extends React.Component {
    @observable hasError = false;

    constructor(props) {
        super(props);
        this.hasError = false;
    }

    componentDidCatch() {
        this.hasError = true;
    }

    render() {
        if (this.hasError) {
            // Здесь можно рендерить запасной интерфейс
            return (
                <h3 style={{ padding: '15px' }}>Something went wrong.</h3>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
