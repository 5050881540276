import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { jsPlumb } from 'jsplumb';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import ContextMenu from 'components/contextMenu';
import { parseUrlParams } from 'helpers/data';
import IconServer from 'assets/img/icons/server.svg';
import IconBook from 'assets/img/icons/book.svg';
import IconBox from 'assets/img/icons/box.svg';
import IconDatabase from 'assets/img/icons/database.svg';
import IconFolder from 'assets/img/icons/folder.svg';
import IconTree from 'assets/img/icons/tree.svg';

@observer
export default class CMDBComponent extends React.Component {
    @observable mapHeight = 200;
    @observable isShowContextMenu;
    @observable menuCoordinates = {
        x: 0,
        y: 0,
    };
    @observable currentSysId;
    contRef = React.createRef();
    jsPlumb;

    componentDidMount() {
        if (!this.props.data) return;
        this.initPlumb();
    }

    componentDidUpdate() {
        if (!this.props.data) return;
        this.initPlumb();
    }

    handleToggleContextMenu = () => {
        this.isShowContextMenu = !this.isShowContextMenu;
    };

    handleContextMenu = (item) => (e) => {
        e.preventDefault();
        this.menuCoordinates.x = e.pageX;
        this.menuCoordinates.y = e.pageY;
        this.isShowContextMenu = true;
        this.currentSysId = item.sysId;
    };

    getNodeBySysId(sysId) {
        return this.props.data && this.props.data.nodes.find((node) => node.sysId === sysId);
    }

    renderContextMenu() {
        if (!this.isShowContextMenu || !this.props.contextMenu) {
            return null;
        }

        const node = this.getNodeBySysId(this.currentSysId);
        return (
            <ContextMenu
                items={ this.props.contextMenu.dependency_map_context_menu }
                x={ this.menuCoordinates.x }
                y={ this.menuCoordinates.y }
                table={ this }
                rowId={ this.currentSysId }
                addParams={
                    {
                        row_id: this.currentSysId,
                        table_name: node.table || parseUrlParams(new URLSearchParams(window.location.search)).essence,
                    }
                }
                isShowContextMenu={ this.isShowContextMenu }
                onToggleContextMenu={ this.handleToggleContextMenu }
            />
        );
    }

    renderNodes() {
        return this.props.data.nodes.map((data) => {
            const classNames = [
                styles.node,
                styles[data.color],
            ];

            let icon = null;
            switch (data.icon) {
                case 'folder':
                    icon = IconFolder;
                    break;
                case 'sitemap':
                    icon = IconTree;
                    break;
                case 'server':
                    icon = IconServer;
                    break;
                case 'box-open':
                    icon = IconBox;
                    break;
                case 'database':
                    icon = IconDatabase;
                    break;
                case 'book':
                    icon = IconBook;
                    break;
                default:
                    break;
            }

            return (
                <div
                    id={ data.id }
                    key={ data.id }
                    className={ classNames.join(' ') }
                    onContextMenu={ this.handleContextMenu(data) }
                    style={ {
                        top: data.position.top + 'px',
                        left: data.position.left + 'px',
                    } }
                >
                    <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: icon } } />
                    <div className={ styles.description }>
                        <div className={ styles.category }>{ data.category }</div>
                        <div className={ styles.title }>
                            <Link to={ data.href }>{ data.title }</Link>
                        </div>
                    </div>
                </div>
            );
        });
    }

    setMapHeight() {
        let highestNode = this.props.data.nodes[0];
        let lowestNode = this.props.data.nodes[0];
        if (!highestNode || !lowestNode) return;
        this.props.data.nodes.forEach((currentNode) => {
            highestNode = highestNode.position.top > currentNode.position.top ? highestNode : currentNode;
            lowestNode = lowestNode.position.top < currentNode.position.top ? lowestNode : currentNode;
        });
        this.mapHeight = document.getElementById(highestNode.id).offsetHeight + highestNode.position.top + lowestNode.position.top;
    }

    drawConnections() {
        this.props.data.connections.forEach((conn) => {
            const connection = this.jsPlumb.connect({
                source: conn.source,
                target: conn.target,
            });
            connection.addType(conn.type);
        });
    }

    initPlumb() {
        const container = this.contRef.current;
        this.jsPlumb = jsPlumb.getInstance({
            Anchors: [
                [
                    'Continuous',
                    {
                        faces: [
                            'bottom',
                            'top',
                        ],
                    },
                ],
                [
                    'Continuous',
                    {
                        faces: [
                            'top',
                            'bottom',
                        ],
                    },
                ],
            ],
            Endpoints: [
                'Blank',
                'Blank',
            ],
            Connector: [
                'Bezier',
                { curviness: 50 },
            ],
            ConnectionOverlays: [
                [
                    'Arrow',
                    {
                        location: 1,
                        visible: true,
                        width: 5,
                        length: 5,
                        id: 'ARROW',
                    },
                ],
            ],
            PaintStyle: { strokeWidth: 1 },
            Container: container,
        });
        this.jsPlumb.registerConnectionTypes({
            'red': {
                paintStyle: { stroke: '#FF0700' },
            },
            'orange': {
                paintStyle: { stroke: '#FFD500' },
            },
            'blue': {
                paintStyle: { stroke: '#278efc' },
            },
            'green': {
                paintStyle: { stroke: '#30BF39' },
            },
            'gray': {
                paintStyle: { stroke: '#b3b3b3' },
            },
        });
        this.drawConnections();
        this.setMapHeight();
    }

    render() {
        if (!this.props.data) return <div className={ styles.preloader } />;

        return (
            <div className={ styles.map } style={ { height: this.mapHeight } }>
                <div className={ styles.container } ref={ this.contRef }>
                    { this.renderNodes() }
                    { this.renderContextMenu() }
                </div>
            </div>
        );
    }
}
