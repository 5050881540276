import { observable } from 'mobx';

class DragAndDropState {
    @observable draggedType = ''; // 'lane'|'card'
    @observable dragged = false;
    @observable draggedSysId = null;
    @observable draggedCategoryId = null;
    @observable draggedOrder = -1;
    @observable enterId = '';
    @observable firstLaneId = null;

    setSysId(sysId) {
        this.draggedSysId = sysId;
    }

    getSysId() {
        return this.draggedSysId;
    }

    setCategoryId(sysId) {
        this.draggedCategoryId = sysId;
    }

    getCategoryId() {
        return this.draggedCategoryId;
    }

    setType(type) {
        this.draggedType = type;
    }

    getType() {
        return this.draggedType;
    }

    setDragged(isDragged) {
        this.dragged = isDragged;
    }

    getDragged() {
        return this.dragged;
    }

    setEnterId(enterId) {
        this.enterId = enterId;
    }

    getEnterId() {
        return this.enterId;
    }

    setOrder = (order) => {
        this.draggedOrder = order;
    };

    getOrder = () => {
        return this.draggedOrder;
    };

    setFirstLaneId = (laneId) => {
        this.firstLaneId = laneId;
    };

    getFirstLaneId = () => {
        return this.firstLaneId;
    };

    clean() {
        this.draggedSysId = null;
        this.draggedType = '';
        this.draggedCategoryId = null;
        this.dragged = false;
        this.enterId = '';
        this.draggedOrder = -1;
        this.firstLaneId = null;
    }
}

export default new DragAndDropState();
