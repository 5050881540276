import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styles from 'components/portalWidgetsComponents/DurationInput/styles.module.scss';
import { observable, reaction } from 'mobx/lib/mobx';
import langStore from 'globalState/lang';
import DurationMsConverter from 'helpers/DurationMsConverter';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Custom Duration Input
 *
 * Props:
 * exclude{array} - какие единицы не отображать
 * value{int} - время в ms
 * onChange{function} - вызывается при смене значения
 * alignRight{boolean} - текст по правому краю
 * maxValue{int} - максимальное значение в ms
 */
@observer
export default class DurationInput extends React.Component {
    @observable activeKey = null;
    isFirstPress = true;
    max = {
        days: 99999,
        hours: 23,
        minutes: 59,
        seconds: 59,
    };

    constructor(props) {
        super(props);

        reaction(
            () => this.activeKey,
            () => {
                this.isFirstPress = true;
            }
        );
    }

    getDurations = (getAll) => {
        const {
            exclude,
            value,
        } = this.props;
        const duration = DurationMsConverter.toDuration(value);
        if (!getAll) {
            delete duration['ms'];
            if (exclude && exclude.length > 0) {
                for (let i = 0; i < exclude.length; i++) {
                    delete duration[exclude[i]];
                }
            }
        }
        return duration;
    };

    onKeydown = (e) => {
        const value = this.getDurations();
        if (this.activeKey) {
            const regex = /[0-9]/;
            if (regex.test(e.key)) {
                if (this.isFirstPress) {
                    this.updateValue(e.key);
                    this.isFirstPress = false;
                }
                else {
                    const oldVal = value[this.activeKey].toString();
                    const newVal = oldVal.length < this.max[this.activeKey].toString().length ? oldVal + e.key : e.key;
                    this.updateValue(newVal);
                }
            }
            if ([
                'Backspace',
                'Delete',
            ].includes(e.key)) {
                const oldVal = value[this.activeKey].toString();
                const newVal = oldVal.length > 1 ? oldVal.slice(0, -1) : 0;
                this.updateValue(newVal);
            }
            if ('ArrowUp' === e.key) {
                e.preventDefault();
                const newVal = parseInt(value[this.activeKey] || 0) + 1;
                this.updateValue(newVal);
            }
            if ('ArrowDown' === e.key) {
                e.preventDefault();
                const newVal = parseInt(value[this.activeKey] || 0) - 1;
                this.updateValue(newVal < 0 ? 0 : newVal);
            }
            if ([
                'ArrowLeft',
                'ArrowRight',
            ].includes(e.key)) {
                e.preventDefault();
                const keys = _.keys(value);
                for (let i = 0, keysLength = keys.length; i < keysLength; i++) {
                    if (keys[i] === this.activeKey) {
                        let newIndex = 'ArrowRight' === e.key ? i + 1 : i - 1;
                        if (newIndex > keysLength - 1) {
                            newIndex = 0;
                        }
                        else if (newIndex < 0) {
                            newIndex = keysLength - 1;
                        }
                        this.activeKey = keys[newIndex];
                        break;
                    }
                }
            }

        }
    };

    updateValue = (value) => {
        const { onChange, maxValue } = this.props;
        if (!onChange) return;

        let duration = this.getDurations(true);
        duration[this.activeKey] = parseInt(value);
        duration = DurationMsConverter.toMs(duration);

        if (maxValue) {
            duration = duration > maxValue ? maxValue : duration;
        }

        onChange(duration);
    };

    renderItems = () => {
        const value = this.getDurations();
        return _.map(value, (value, key) => {
            let valueString = !value ? '0' : value.toString();
            if (this.max[key].toString().length > 1) {
                if (!value) {
                    valueString = '00';
                }
                else if (valueString.length === 1) {
                    valueString = '0' + value;
                }
            }
            return (
                <span key={ key } className={ `${ styles.Item } ${ value ? styles.active : '' }` } onClick={ this.onItemClick(key) } data-test={ `${ ATTRIBUTES.durationInputItem }-${key}` }>
                    <span className={ `${ styles.Count } ${ this.activeKey === key ? styles.active : '' }` }>{ valueString }</span>
                    <span>{ this.getTitle(key) }</span>
                </span>
            );
        });
    };

    getTitle = (key) => {
        const { duration_titles } = langStore.getTranslate();
        if (!duration_titles) return;
        return duration_titles[key].slice(0, 1);
    };

    onItemClick = (key) => () => {
        this.activeKey = key;
    };

    onFieldBlur = () => {
        this.activeKey = null;
    };

    onFieldClick = () => {
        if (!this.activeKey) {
            const value = this.getDurations();
            this.activeKey = _.keys(value)[0];
        }
    };

    render() {
        const { alignRight, value } = this.props;
        return (
            <label className={ `${ styles.Field } ${ alignRight ? styles.TextRight : styles.TextLeft } ${!value ? styles.empty : ''} ${ this.activeKey ? styles.focus : ''}` } data-test={ ATTRIBUTES.durationInput }>
                <input onKeyDown={ this.onKeydown } className={ styles.HiddenInput } inputMode="numeric" type="number" onFocus={ this.onFieldClick } onBlur={ this.onFieldBlur } />
                <span className={ styles.Content }>
                    { this.renderItems() }
                </span>
            </label>
        );
    }
}