import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { observable } from 'mobx';

/***
 * Обёртка для текста в пунктах навигатора.
 * Когда весь текст не вмещается, показывает title с полным текстом
 */

@observer
export default class Label extends React.Component {
    refLabel = React.createRef();
    refText = React.createRef();
    @observable showHint = false;

    checkOverflow = () => {
        const labelEl = this.refLabel ? this.refLabel.current : null;
        const textEl = this.refText ? this.refText.current : null;
        if (!labelEl || !textEl) return;
        this.showHint = textEl.offsetWidth > labelEl.offsetWidth;
    };

    render() {
        const { children, hasIcon } = this.props;
        return (
            <>
                <div title={ this.showHint && children ? children : undefined } onMouseEnter={ this.checkOverflow } className={ `${ styles.Label } ${ hasIcon ? styles.LabelIcon : '' }` } ref={ this.refLabel }>
                    <span ref={ this.refText }>{ children }</span>
                </div>
            </>
        );
    }
}
