import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styles from './styles.module.scss';
import Dropdown from 'components/dropdown';
import langStore from 'globalState/lang';
import {fetchAutoSuggestData, fetchAutoSuggestDelegationData} from 'actions/suggestDropDown';
import { updateMenuScrollTop } from 'helpers/html';
import UsersIcon from "assets/img/icons/user-many.svg";
import { ATTRIBUTES } from 'constants/attributesForTests';
import { AutoSuggestParams, SuggestDropDownProps, AutoSuggestSelectOption as SelectOption } from 'types/components/suggestDropDown';
import { KeyboardKeys } from 'types/components/dynamicForms/maskInput';

@observer
export class SuggestDropDown extends React.Component<SuggestDropDownProps> {
    @observable data: SelectOption[] = [];
    @observable current: SelectOption | null = null;
    @observable isLoading = true;
    @observable isHidden = false;
    isKeyboardOn = false;
    refMenu = React.createRef<HTMLDivElement>();
    refActiveItem = React.createRef<HTMLDivElement>();
    timeout: NodeJS.Timeout;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.onDocumentClick);
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('keydown', this.onDocumentKeyDown);
        this.updateSuggested();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.term !== this.props.term) {
            this.updateSuggested();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onDocumentClick);
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    }

    getData = async () => {
        const { reference_id, reference_column_id, term, reference_qualifier_condition, extraAttributes, fetchCondition } = this.props;
        this.isHidden = false;
        this.isLoading = true;
        if (!reference_id || !term) {
            return;
        }
        let condition = reference_qualifier_condition;
        const isDelegation = extraAttributes?.includes('show_delegates=true');
        if (fetchCondition) {
            condition = await fetchCondition();
        }
        const params: AutoSuggestParams = {
            reference_id,
            reference_column_id,
            term,
        };
        if (condition) {
            params.reference_qualifier_condition = condition;
        }
        const request = isDelegation ? fetchAutoSuggestDelegationData : fetchAutoSuggestData;
        let { data } = await request(params);
        if (_.isArray(data)){
            data = data.map((elem) => ({...elem, reference_state: null })); // пока autosuggest не возвращает reference_state
        }
        this.data = data;

        this.isLoading = false;
    };

    onItemMouseEnter = (item: SelectOption) => () => {
        if (this.isKeyboardOn) return;
        this.current = item;
    };

    onItemMouseLeave = () => () => {
        if (this.isKeyboardOn) return;
        this.current = null;
    };

    onItemClick = (item: SelectOption) => () => {
        if (this.props.updateData) {
            this.props.updateData(item);
        }
    };

    onMouseMove = () => {
        this.isKeyboardOn = false;
    };

    onDocumentClick = (e) => {
        const dropEl = this.props.refDropdown ? this.props.refDropdown.current : null;
        if (!dropEl) return;

        if (!dropEl.contains(e.target)) {
            this.isHidden = true;
        }
    };

    onDocumentKeyDown = (e) => {
        if (this.data.length === 0) return;
        const { key } = e;
        switch (key) {
            case KeyboardKeys.Enter:
                e.preventDefault();
                if (this.props.updateData && this.current) {
                    this.props.updateData(this.current);
                }
                break;
            case KeyboardKeys.Escape:
                this.isHidden = true;
                break;
            case KeyboardKeys.ArrowUp:
            case KeyboardKeys.ArrowDown:
                e.preventDefault();
                const data = this.data;
                const dataLength = data.length;
                if (!this.current) {
                    this.current = key === 'ArrowUp' ? data[dataLength - 1] : data[0];
                }
                else {
                    for (let i = 0; i < dataLength; i++) {
                        if (data[i].database_value === this.current.database_value) {
                            let newIndex = key === 'ArrowUp' ? i - 1 : i + 1;
                            if (newIndex < 0) {
                                newIndex = dataLength - 1;
                            }
                            else if (newIndex >= dataLength) {
                                newIndex = 0;
                            }
                            this.current = data[newIndex];
                            break;
                        }
                    }
                }
                this.isKeyboardOn = true;
                updateMenuScrollTop(this.refMenu.current, this.refActiveItem.current);
        }
    };

    updateSuggested = () => {
        this.current = null;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.getData, 300);
    };

    renderList = () => {
        const items = this.data.map((item, index) => {
            let className = [ styles.ListItem ];
            if (this.current && item.database_value === this.current.database_value) {
                className.push(styles.current);
            }
            const hasIcon = item.has_delegate;

            return (
                <div
                    ref={ this.current && item.database_value === this.current.database_value ? this.refActiveItem : null }
                    className={ className.join(' ') }
                    key={ index }
                    onMouseEnter={ this.onItemMouseEnter(item) }
                    onMouseLeave={ this.onItemMouseLeave() }
                    onClick={ this.onItemClick(item) }
                    data-test={ ATTRIBUTES.suggestDropdownItem }
                >
                    <span className={styles.TextBlock}>
                         { item.display_value }
                    </span>

                    { hasIcon && <div className={styles.DelegationIcon} dangerouslySetInnerHTML={{__html: UsersIcon}}/> }
                </div>
            );
        });

        return <div className={ styles.List } ref={ this.refMenu }>{ items }</div>;
    };

    renderPreloader = () => {
        return <div className={ styles.Preloader } />;
    };

    renderNoResults = () => {
        const { multi_select_titles: titles } = langStore.getTranslate();

        return (
            <div className={ styles.NoResultsText }>
                <div>{ titles && titles.no_results }</div>
            </div>
        );
    };

    render() {
        if (this.isHidden) return null;

        let result;
        if (this.isLoading) {
            result = this.renderPreloader();
        }
        else {
            if (this.data.length === 0) {
                result = this.renderNoResults();
            }
            else {
                result = this.renderList();
            }
        }

        let list = <div className={ styles.DropDown }>{ result }</div>;

        return (
            <Dropdown refParent={ this.props.refField } ref={ this.props.refDropdown } data-test={ this.props['data-test'] } >
                { list }
            </Dropdown>
        );
    }
}

export default React.forwardRef((props: SuggestDropDownProps, ref: React.RefObject<HTMLDivElement>) =>
    <SuggestDropDown {...props} refDropdown={ref}/>);
