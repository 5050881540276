import { HandleResponse } from 'types/actions/main';
import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';

function getFileData (apiAttachmentData = {}, file) {
    const fileData = {
        originalFileData: file,
        progress: 0,
        errorText: '',
        error: false,
        uploaded: true,
        uploadInProgress: false,
        index: 0,
        apiResponse: {
            sys_id: 0,
            file_name: '',
            delete_url: '',
            download_url: '',
            file_size: '',
        },
    };

    if (file) fileData.originalFileData = file;
    fileData.apiResponse = {
        ...fileData.apiResponse,
        ...apiAttachmentData,
    };

    return fileData;
}

export async function uploadImage(entity: string, sysId: string, files): Promise<HandleResponse> {
    const firstFile = files[0];
    if (!firstFile?.type?.includes('image')) {
        return Promise.reject();
    }
    const file = getFileData({}, firstFile);
    const formData = new FormData();
    formData.append('files[]', file.originalFileData);
    let url = `/attachments/upload/${ entity }`;
    if (sysId){
        url += `/${ sysId }`;
    }
    const response = await new apiRequest(`POST ${url}`).send(formData).catch(console.error);
    return handleResponse(response);
}


