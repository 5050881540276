import * as React from 'react';
import { observer } from 'mobx-react';
import styles from 'components/dateTimePicker/styles.module.scss';
import moment from 'moment';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Месяцы датапикера
 * Параметры:
 * visibleDay - выбранный день
 * onItemClick - метод при выборе
 * onHeaderClick - метод при клике на шапку
 * isHidden - флаг скрыт ли блок
 */
@observer
export default class DatePickerMonths extends React.Component {
    constructor(props) {
        super(props);
    }

    getMonthsList = () => {
        return (
            <div className={ styles.HeaderMonths }>
                { moment.months().map((name, index) => {
                    const isActive = index === this.props.visibleDay.month();
                    return (
                        <div key={ index }>
                            <button
                                type={ 'button' }
                                className={ `${ styles.HeaderButton } ${ isActive ? styles.active : '' }` }
                                onClick={ this.onItemClick(index) }
                                data-test={ ATTRIBUTES.fieldDatePickerHeaderMonthItem }
                            >
                                { name }
                            </button>
                        </div>
                    );
                }) }
            </div>
        );
    };

    onItemClick = (index) => () => {
        const { onItemClick } = this.props;
        if (onItemClick) {
            onItemClick(index);
        }
    };

    onHeaderClick = (index) => () => {
        const { onHeaderClick } = this.props;
        if (onHeaderClick) {
            onHeaderClick(index);
        }
    };

    render() {
        const { data_picker_titles } = langStore.getTranslate();
        return (
            <div
                className={ `${ styles.HeaderPopover } ${ this.props.isHidden ? styles.hidden : '' }` }
                data-test={ ATTRIBUTES.fieldDatePickerHeaderMonthsPopover }
            >
                <div className={ styles.HeaderPopoverTop }>
                    <span onClick={ this.onHeaderClick(false) } className={ styles.HeaderLink }>{ data_picker_titles && data_picker_titles.month }</span>
                </div>
                <div className={ styles.HeaderPopoverCont }>
                    { this.getMonthsList() }
                </div>
            </div>
        );
    }
}
