import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import styles from './styles.module.scss';
import IconImage from 'assets/img/icons/image.svg';

import IconDownload from 'assets/img/icons/download.svg';
import IconClose from 'assets/img/icons/close-x.svg';
import IconPrev from 'assets/img/icons/chevron-left.svg';
import IconNext from 'assets/img/icons/chevron-right.svg';
import User from 'globalState/user';
import { API_BASE_URL } from 'lib/apiRequest';
import MobileModal from 'components/modalWrapper';

/***
 * Описание: Превью картинок
 * Параметры:
 * src: {required, type: string} - путь до картинки
 * name: {required, type: string} - имя картинки
 * onClose: {required, type: function} - метод, вызываемый при закрытии
 * onArrowClick: {required, type: function} - метод, вызываемый при нажатии на стрелки
 * showArrows: {type: boolean} - показывать стрелки
 */

@observer
export default class PreviewComponent extends React.Component {
    @observable showLoader = false;
    timeout = null;
    refImage = React.createRef();
    refHeader = React.createRef();
    refContent = React.createRef();
    refArrows = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        document.addEventListener('keydown', this.onDocumentKeyDown);
        this.initImagePreLoader();
    }

    componentDidUpdate(prevProps) {
        if (this.props.src !== prevProps.src) {
            this.initImagePreLoader();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    }

    initImagePreLoader = () => {
        this.timeout = setTimeout(() => {
            this.showLoader = true;
        }, 500);

        const imageEl = this.refImage.current;
        const contentEl = this.refContent.current;
        if (imageEl && contentEl) {
            contentEl.classList.remove(styles.loaded);
            imageEl.addEventListener('load', () => {
                contentEl.classList.add(styles.loaded);
                clearTimeout(this.timeout);
                this.showLoader = false;
            });
        }
    };

    onDocumentClick = (e) => {
        const imageEl = this.refImage.current;
        const headerEl = this.refHeader.current;
        const arrowsEl = this.refArrows.current;
        if (!imageEl || !headerEl) return;

        if (imageEl.contains(e.target) || headerEl.contains(e.target) || (!!arrowsEl && arrowsEl.contains(e.target))) {
            return;
        }
        else {
            this.onClose();
        }
    };

    onDocumentKeyDown = ({ key }) => {
        if (key === 'Escape') {
            this.onClose();
        }
        if (key === 'ArrowRight') {
            this.onArrowClick('next')();
        }
        if (key === 'ArrowLeft') {
            this.onArrowClick('prev')();
        }
    };

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    onArrowClick = (direction) => () => {
        if (this.props.onArrowClick) {
            this.props.onArrowClick(direction);
        }
    };

    onDownloadFileClick = (e) => {
        e.stopPropagation();
        const { downloadUrl, fileId } = this.props;
        window.location = API_BASE_URL + `/${ downloadUrl }/download/${ fileId }?access-token=${ User.accessToken }`;
    };

    render() {
        const { src, name, showArrows } = this.props;

        return (
            <MobileModal>
                <div className={ styles.Preview }>
                    <div className={ styles.Content } ref={ this.refContent }>
                        { this.showLoader && <div className={ styles.Loader } /> }
                        <img src={ src } ref={ this.refImage } />
                    </div>

                    { showArrows && (
                        <div ref={ this.refArrows }>
                            <div className={ `${ styles.Arrow } ${ styles.left }` } onClick={ this.onArrowClick('prev') } dangerouslySetInnerHTML={ { __html: IconPrev } } />
                            <div className={ `${ styles.Arrow } ${ styles.right }` } onClick={ this.onArrowClick('next') } dangerouslySetInnerHTML={ { __html: IconNext } } />
                        </div>
                    )
                    }

                    <div className={ styles.Header } ref={ this.refHeader }>
                        <div className={ styles.Title }>
                            <div className={ `${ styles.Icon } ${ styles.noHover }` } dangerouslySetInnerHTML={ { __html: IconImage } } />
                            { name }
                        </div>
                        <div className={ styles.Buttons }>
                            {/*<a href={ this.props.src } target="_blank" className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconExternal } } />*/ }
                            <div onClick={ (e) => this.onDownloadFileClick(e) } className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconDownload } } />
                            <div
                                className={ styles.Icon }
                                dangerouslySetInnerHTML={ { __html: IconClose } }
                                onClick={ this.onClose }
                            />
                        </div>
                    </div>
                </div>
            </MobileModal>
        );
    }
}
