import { observer } from 'mobx-react';
import * as React from 'react';
import stylesTabs from 'components/tabs/styles.module.scss';
import FormsState from 'globalState/forms';
import { ATTRIBUTES } from 'constants/attributesForTests';

@observer
class RelatedListTab extends React.Component {
    constructor(props) {
        super(props);

        const { name, tableName } = props;
        if (tableName && name) {
            //if (typeof window.dynamicFormsGlobals[tableName + '.relatedList'] === 'undefined') {
            //    window.dynamicFormsGlobals[tableName + '.relatedList'] = {};
            //}
            //window.dynamicFormsGlobals[tableName + '.relatedList'][name] = this;
            FormsState.setRelatedList(name, this);
        }
    }

    uiHideRelatedList = () => {
        const { onHideRelatedList, name } = this.props;
        if (onHideRelatedList && name) {
            onHideRelatedList(name);
        }
    };

    uiShowRelatedList = () => {
        const { onShowRelatedList, name } = this.props;
        if (onShowRelatedList && name) {
            onShowRelatedList(name);
        }
    };

    render() {
        const { active, list, onChangeActiveLists, isShow, refDiv } = this.props;
        if (!isShow) {
            return null;
        }
        const dataTest = `${ list.name }_${ ATTRIBUTES.relatedListTab }`;
        return (
            <div
                ref={ refDiv }
                onClick={ onChangeActiveLists(list.sysId) }
                data-test={ dataTest }
                className={ `${ stylesTabs.TabsHeadItem } ${ active === list.sysId ? stylesTabs.TabsHeadItemActive : '' }` }
            >
                { list.name }
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <RelatedListTab {...props} refDiv={ ref }/>);