import type {
    EditorConfig,
    GridSelection,
    LexicalNode,
    NodeKey,
    NodeSelection,
    RangeSelection,
    SerializedLexicalNode,
    Spread,
} from 'lexical';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import {
    $getNodeByKey,
    $getSelection,
    $isNodeSelection,
    CLICK_COMMAND,
    COMMAND_PRIORITY_LOW,
    DecoratorNode,
    KEY_BACKSPACE_COMMAND,
    KEY_DELETE_COMMAND,
} from 'lexical';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';

import ImageResizer from '../plugins/ImagePlugin/ImageResizer';
import styles from '../plugins/ImagePlugin/styles.module.scss';

export interface ImagePayload {
    altText: string;
    height?: number;
    key?: NodeKey;
    maxWidth?: number;
    src: string;
    width?: number;
}

const imageCache = new Set();

function useSuspenseImage(src: string) {
    if (!imageCache.has(src)) {
        throw new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                imageCache.add(src);
                resolve(null);
            };
        });
    }
}

function LazyImage({
                       altText,
                       className,
                       imageRef,
                       src,
                       width,
                       height,
                       maxWidth,
                   }: {
    altText: string;
    className: string | null;
    height: 'inherit' | number;
    imageRef: { current: null | HTMLImageElement };
    maxWidth: number;
    src: string;
    width: 'inherit' | number;
}): JSX.Element {
    useSuspenseImage(src);
    return (
        <img
            className={ className || undefined }
            src={ src }
            alt={ altText }
            ref={ imageRef }
            style={ {
                height,
                maxWidth,
                width,
            } }
            draggable="false"
        />
    );
}

function ImageComponent({
                            src,
                            altText,
                            nodeKey,
                            width,
                            height,
                            maxWidth,
                            resizable,
                        }: {
    altText: string;
    height: 'inherit' | number;
    maxWidth: number;
    nodeKey: NodeKey;
    resizable: boolean;
    src: string;
    width: 'inherit' | number;
}): JSX.Element {
    const ref = useRef(null);
    const [ isSelected, setSelected, clearSelection ] =
        useLexicalNodeSelection(nodeKey);
    const [ isResizing, setIsResizing ] = useState<boolean>(false);
    const [ editor ] = useLexicalComposerContext();
    const [ selection, setSelection ] = useState<RangeSelection | NodeSelection | GridSelection | null>(null);

    const onDelete = useCallback(
        (payload: KeyboardEvent) => {
            if (isSelected && $isNodeSelection($getSelection())) {
                const event: KeyboardEvent = payload;
                event.preventDefault();
                const node = $getNodeByKey(nodeKey);
                if ($isImageNode(node)) {
                    node.remove();
                }
                setSelected(false);
            }
            return false;
        },
        [ isSelected, nodeKey, setSelected ],
    );

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                setSelection(editorState.read(() => $getSelection()));
            }),
            editor.registerCommand<MouseEvent>(
                CLICK_COMMAND,
                (payload) => {
                    const event = payload;

                    if (isResizing) {
                        return true;
                    }
                    if (event.target === ref.current) {
                        if (!event.shiftKey) {
                            clearSelection();
                        }
                        setSelected(!isSelected);
                        return true;
                    }

                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
            editor.registerCommand(
                KEY_DELETE_COMMAND,
                onDelete,
                COMMAND_PRIORITY_LOW,
            ),
            editor.registerCommand(
                KEY_BACKSPACE_COMMAND,
                onDelete,
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [
        clearSelection,
        editor,
        isResizing,
        isSelected,
        nodeKey,
        onDelete,
        setSelected,
    ]);

    const onResizeEnd = (
        nextWidth: 'inherit' | number,
        nextHeight: 'inherit' | number,
    ) => {
        // Delay hiding the resize bars for click case
        setTimeout(() => {
            setIsResizing(false);
        }, 200);

        editor.update(() => {
            const node = $getNodeByKey(nodeKey);
            if ($isImageNode(node)) {
                node.setWidthAndHeight(nextWidth, nextHeight);
            }
        });
    };

    const onResizeStart = () => {
        setIsResizing(true);
    };

    const draggable = isSelected && $isNodeSelection(selection);
    const isFocused = $isNodeSelection(selection) && (isSelected || isResizing);
    return (
        <Suspense fallback={ null }>
            <>
                <div draggable={ draggable }>
                    <LazyImage
                        className={ isFocused ? styles.FocusedImage : null }
                        src={ src }
                        altText={ altText }
                        imageRef={ ref }
                        width={ width }
                        height={ height }
                        maxWidth={ maxWidth }
                    />
                </div>
                { resizable && isFocused && (
                    <ImageResizer
                        editor={ editor }
                        imageRef={ ref }
                        maxWidth={ maxWidth }
                        onResizeStart={ onResizeStart }
                        onResizeEnd={ onResizeEnd }
                    />
                ) }
            </>
        </Suspense>
    );
}

export type SerializedImageNode = Spread<{
    altText: string;
    height?: number;
    maxWidth: number;
    src: string;
    width?: number;
    type: 'image';
    version: 1;
},
    SerializedLexicalNode>;

export class ImageNode extends DecoratorNode<JSX.Element> {
    __src: string;
    __altText: string;
    __width: 'inherit' | number;
    __height: 'inherit' | number;
    __maxWidth: number;

    static getType(): string {
        return 'image';
    }

    static clone(node: ImageNode): ImageNode {
        return new ImageNode(
            node.__src,
            node.__altText,
            node.__maxWidth,
            node.__width,
            node.__height,
            node.__key,
        );
    }

    static importJSON(serializedNode: SerializedImageNode): ImageNode {
        const { altText, height, width, maxWidth, src } =
            serializedNode;
        return $createImageNode({
            altText,
            height,
            maxWidth,
            src,
            width,
        });
    }

    constructor(
        src: string,
        altText: string,
        maxWidth: number,
        width?: 'inherit' | number,
        height?: 'inherit' | number,
        key?: NodeKey,
    ) {
        super(key);
        this.__src = src;
        this.__altText = altText;
        this.__maxWidth = maxWidth;
        this.__width = width || 'inherit';
        this.__height = height || 'inherit';
    }

    exportJSON(): SerializedImageNode {
        return {
            altText: this.getAltText(),
            height: this.__height === 'inherit' ? 0 : this.__height,
            maxWidth: this.__maxWidth,
            src: this.getSrc(),
            type: 'image',
            version: 1,
            width: this.__width === 'inherit' ? 0 : this.__width,
        };
    }

    setWidthAndHeight(
        width: 'inherit' | number,
        height: 'inherit' | number,
    ): void {
        const writable = this.getWritable();
        writable.__width = width;
        writable.__height = height;
    }

    // View

    createDOM(config: EditorConfig): HTMLElement {
        const span = document.createElement('span');
        const theme = config.theme;
        const className = theme.image;
        if (className !== undefined) {
            span.className = className;
        }
        return span;
    }

    updateDOM(): false {
        return false;
    }

    getSrc(): string {
        return this.__src;
    }

    getAltText(): string {
        return this.__altText;
    }

    decorate(): JSX.Element {
        return (
            <ImageComponent
                src={ this.__src }
                altText={ this.__altText }
                width={ this.__width }
                height={ this.__height }
                maxWidth={ this.__maxWidth }
                nodeKey={ this.getKey() }
                resizable={ true }
            />
        );
    }
}

export function $createImageNode({
                                     altText,
                                     height,
                                     maxWidth = 440,
                                     src,
                                     width,
                                     key,
                                 }: ImagePayload): ImageNode {
    return new ImageNode(
        src,
        altText,
        maxWidth,
        width,
        height,
        key,
    );
}

export function $isImageNode(
    node: LexicalNode | null | undefined,
): node is ImageNode {
    return node instanceof ImageNode;
}
