import * as React from 'react';
import { observer } from 'mobx-react';


@observer
export default class ColorInput extends React.Component {
    constructor(props) {
        super(props);

        if (window.PORTAL_STATE === undefined) {
            throw new Error('window.PORTAL_STATE must be defined for ColorInput widget');
        }

        PORTAL_STATE.color = '#eeeeee';
    }

    onChange = (e) => {
        PORTAL_STATE.color = e.target.value;
    };

    onClick = () => {
        PORTAL_STATE.updateColor();
    }

    render() {
        return (
            <div>
                <div><b>Color:</b></div>
                <div>
                    <input value={PORTAL_STATE.color} onChange={this.onChange} />
                </div>
                <div>
                    <button>Apply color</button>
                </div>
            </div>
        );
    }
}
