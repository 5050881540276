import { observable, action } from 'mobx';
import { TableStateType } from 'types/components/table/index';
import { TableGroupType } from 'types/components/table/groups/index';

class TableState implements TableStateType {
    @observable private _name = '';
    @observable private _columns: any[] = [];
    @observable private _rows: any[] = [];
    @observable private _pagination = {};
    @observable private _groups: TableGroupType[] = []
    @observable private _isShowListGroups = false;
    @observable private _isShowGroups = false;
    @observable private _scrollLeft = 0;
    private _tableId = '';
    private _recordId = '';
    private _recordColumnName = '';

    @action
    setScrollLeft = (value) => {
        this._scrollLeft = value;
    };

    getScrollLeft = () => {
        return this._scrollLeft;
    };

    @action
    setName = (name) => {
        this._name = name;
    };

    getName = () => {
        return this._name;
    }

    getRows = () => {
        return this._rows;
    };

    getRowsLength = () => {
        return this._rows.length;
    };

    @action
    setRows = (rows) => {
        this._rows = rows;
    };

    getPagination = () => {
        return this._pagination;
    };

    @action
    setPagination = (pagination = {}) => {
        this._pagination = pagination;
    };

    getIsShowListGroups(): boolean {
        return this._isShowListGroups;
    }

    @action
    setIsShowListGroups(value: boolean) {
        this._isShowListGroups = value;
    }

    getIsShowGroups(): boolean {
        return this._isShowGroups;
    }

    setIsShowGroups(value: boolean) {
        this._isShowGroups = value;
    }

    getCellValue = (rowIndex, field) => {
        if (this._rows[rowIndex] && this._rows[rowIndex][field]) {
            return this._rows[rowIndex][field].value; // todo
        }
        return null;
    };

    @action
    setCellValue = (rowIndex, field, value) => {
        if (this._rows[rowIndex] && this._rows[rowIndex][field]) {
            this._rows[rowIndex][field].value = value; // todo
        }
    };

    @action
    updateCell = (rowIndex, field, value) => {
        if (this._rows[rowIndex] && this._rows[rowIndex][field]) {
            this._rows[rowIndex][field] = value;
        }
    };

    @action
    changePaginationTotal = (total) => {
        this._pagination = { ...this._pagination, total };
    };

    @action
    changePaginationPerPage = (perPage) => {
        this._pagination = { ...this._pagination, per_page: perPage };
    };

    @action
    clear = () => {
        this._rows = [];
        this._name = '';
        this._tableId = '';
        this._recordId = '';
        this._recordColumnName = '';
        this._columns = [];
        this._groups = [];
    };

    getTableId = () => {
        return this._tableId;
    };

    setTableId = (value) => {
        this._tableId = value;
    };

    getRecordId = () => {
        return this._recordId;
    };

    setRecordId = (recordId) => {
        this._recordId = recordId;
    };

    getRecordColumnName = () => {
        return this._recordColumnName;
    };

    setRecordColumnName = (recordColumnName) => {
        this._recordColumnName = recordColumnName;
    };

    getGroups(): TableGroupType[] {
        return this._groups;
    }

    setGroups(value: TableGroupType[]) {
        this._groups = value;
    }

    addGroup(value: TableGroupType) {
        this._groups = [ ...this._groups, value ];
    }

    getColumns(): any[] {
        return this._columns;
    }

    setColumns(value: any[]) {
        this._columns = value;
    }

    deleteRow(id) {
        this._rows = this._rows.filter((row: any) => row.sys_id !== id);
    }

    setRowByID = (id, row) => {
        let index = this._rows.findIndex((row: any) => (row.sys_id === id));
        if (index >= 0){
            this._rows[index] = row;
        }
    };
}

export const tableStateClass = TableState;

export default new TableState();
