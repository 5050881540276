import * as React from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/dynamicForms/view/field/checkbox';
import RecordPopupButton from 'components/recordPopup/infoButton';
import styles from 'components/groupedTable/styles.module.scss';
import Info from 'assets/img/icons/info.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { MetaCellProps } from 'types/components/table/metaCell/index';
import langStore from "globalState/lang";

@observer
export default class MetaCell extends React.Component<MetaCellProps> {
    onCheck() {
        if (this.props.usedByList) {
            const display_value = this.props.label.value && typeof this.props.label.value === 'object' && 'display_value' in this.props.label.value ?
                this.props.label.value.display_value || this.props.id : this.props.label.value;
            this.props.onCheckRow(this.props.id, !this.props.value, display_value, this.props.label?.reference_state);
        } else {
            this.props.onCheckRow(this.props.id, !this.props.value, undefined, this.props.label?.reference_state);
        }
    }

    render() {
        const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');
        const { className } = this.props;
        const classes = [ styles.tdInfo ];
        if (className) {
            classes.push(className);
        }
        return <>
            {
                this.props.isBlankMode || (this.props.isWindow && !this.props.usedByList) ? null :
                    <td key="checked" className={ styles.tdCheckbox }>
                        <Checkbox onChange={ this.onCheck.bind(this) } value={ this.props.value } />
                    </td>
            }
            <td
                key="info"
                className={ classes.join(' ') }
                data-test={ this.props['data-test'] ? this.props['data-test'] : ATTRIBUTES.tableMetaCell }
            >
                <RecordPopupButton
                    className={ styles.BtnIcon }
                    essence={ this.props.tableName }
                    sys_id={ this.props.id }
                    svg={ Info }
                    isServicePortal={ this.props.isServicePortal }
                    isWindow={ this.props.isWindow }
                    hint={ system_buttons_hints?.record_preview }
                />
            </td>
        </>;
    }
}
