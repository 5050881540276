import { observable, action } from 'mobx';
import * as React from 'react';
import _ from 'lodash';
import { helperRedirectReplace } from 'helpers/history';
import { distributeData, getUiActions, isChangeStep } from 'helpers/report/reportMaster';
import { fetchRecords, isDurationAverage } from 'helpers/report/report';
import { getReportType } from 'helpers/report/reportStylesHelper';
import SimpleForm from 'ui-actions-scripts/simple-form';
import { paramsToString } from 'helpers/data';
import { fetchDotWalkList } from 'actions/conditions';
import { calculateCondtionString } from 'helpers/condition';
import { AdditionalDataType, ReportParamsType, ReportStateType } from 'types/components/report/index';
import { generateRandomString } from 'helpers/data';

//isLoadData - флаг, который показывает загружены ли данные отчета
//isUpdateChart - флаг, который отвечает за необходимость обновления отчета (чарта)
//isLoadingRecord - флаг, который показывает загружается ли форма
//isOpenFilter - флаг, обознаючающий открытие filterConditions
//isUpdateMenu - флаг, отвечающий за обновление меню
export const IS_LOAD_DATA = 1;
export const IS_LOADING_RECORD = 2;
export const IS_UPDATE_CHART = 4;
export const IS_OPEN_FILTER = 8;
export const IS_UPDATE_MENU = 16;


/**
 * Компонент отчетов, отвечает за глобальное хранение
 * @param name - название отчета
 * @param record - рекорд отчета, только для МО (мастер отчета)
 * @param table - таблица для создания репорта
 * @param chart - данные об отчете
 * @param configForm - форма с конфигурацией отчета в МО (3й шаг)
 * @param step - шаг выбора в меню в МО
 * @param report - данные для отображения готового отчета
 * @param stateFlags - стейт с состояниями флагов
 * @param reportData - данные отчета, такие как данные самого чарта, данные формы, название отчета, тип, таблица
 * @param styleOptions - конечный массив стилей, который используется в самом компоненте отчета
 * @param additionalGroup - дополнительные параметры группировки, например, когда в параметре group by несколько значений
 * @param additionalGroupValue - выбранное значение group value из массива additionalGroup
 * @param prevType - предыдущий тип репорта
 * @param params - параметры в юрле для репорта
 * @param report_id - параметр используется для получения данных по существующему айди отчета
 * @param types - массив всех возможных типов отчета, приходит в /report/get-types
 * @param reportStyles - текущии отображаемые стили у отчета
 * @param additionalData - дополнительная информация для постройки репорта (массив цветов и пр)
 * @param conditionState - state поля conditions
 * @param savedRecord - данные сохранённого отчёта в формате [{column_id: value}]
 *                      Нужны при смене отчёта для заполнения общих полей. Если отчёт ещё не сохранён, не заполнен
 * @param addCondition - строка для доп фильтра
 */
class ReportState implements ReportStateType {
    @observable name = '';
    @observable record = null;
    @observable table: any = null;
    @observable chart: any = null;
    @observable styles = {};
    @observable type: any = null;
    @observable indexReportError = false;
    @observable stateFlags = 0;
    @observable step = 0;
    @observable report = null;
    @observable reportData = null;
    @observable reportStyles = {};
    @observable sFormSysId = null;
    @observable menuItems = [];
    @observable fixedCondition = '';
    @observable isDurationAverage = false;
    typeTable;
    styleOptions = null;
    configForm: React.RefObject<any> = React.createRef();
    dataForm: React.RefObject<any> = React.createRef();
    additionalGroup = [];
    additionalGroupValue = null;
    stylesForm = {};
    prevType = null;
    prevTable = null;
    report_id = null;
    types = null;
    additionalData = null;
    conditionState;
    savedRecord = null;
    aggregationRecordId = null;
    parentFormId = ''; //Идентификатор для привязки всех секций форм отчётов к одной записи в state
    @observable aggregationColumn = '';

    /**
     * URL search params
     * @type {{
          report_id: {string},
          table_id: {string},
          type: {string},
          condition: {string},
     }| null}
     */
    params: any = {};

    /**
     * Очистка рекорда
     */
    @action
    clearRecord = () => {
        this.record = null;
    };

    /**
     * Обновление текущих стилей у чарта
     */
    @action
    updateCurrentStyles = () => {
        this.reportStyles = _.clone(this.styles);
    };

    /**
     * Очистка данных об отчете
     */
    @action
    clearChart = () => {
        this.chart = null;
    };

    /**
     * Очистка стилей
     */
    @action
    clearStyles = () => {
        this.styles = {};
    };

    /**
     * Очистка типа
     */
    @action
    clearType = () => {
        this.type = null;
        this.isDurationAverage = false;
    };

    // Очистка общих параметров
    clearCommonData = () => {
        this.name = '';
        this.record = null;
        this.table = null;
        this.chart = null;
        this.styles = {};
        this.type = null;
        this.indexReportError = false;
        this.stateFlags = 0;
        this.step = 0;
        this.report = null;
        this.reportData = null;
        this.reportStyles = {};
        this.styleOptions = null;
        this.configForm = React.createRef();
        this.dataForm = React.createRef();
        this.additionalGroup = [];
        this.additionalGroupValue = null;
        this.stylesForm = {};
        this.prevType = null;
        this.prevTable = null;
        this.report_id = null;
        this.additionalData = null;
        this.sFormSysId = null;
        this.params = {};
        this.savedRecord = null;
        this.menuItems = [];
        this.typeTable = null;
        this.fixedCondition = '';
        this.isDurationAverage = false;
    };

    /**
     * Очистка всего класса
     */
    @action
    clearData = () => {
        this.clearCommonData();
        this.types = null;
    };

    /**
     * Функция, изменяющая шаг выбора в МО
     * @param step - шаг, на который устанавливаем
     */
    changeStep = (step) => () => {
        // проверка возможно ли установить
        // часто шаг заблокирован, например, перейти с 1й на 3й при условии, что конфигурация не установлена - невозможно
        if (isChangeStep(step, this.name, this.type, this.table, !!(this.stateFlags & IS_LOADING_RECORD), this.record)) {
            this.step = step;
        }
    };

    fetchConditionsData = async (tableId) => {
        if (!tableId || Array.isArray(tableId)) {
            return;
        }
        const params = {
            referenced_table_id: tableId,
        };
        const response = await fetchDotWalkList(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        // condition state for report
        if (data && data.items) {
            this.conditionState.setDotWalkList(data.items || data);
            const condition = this.params ? (this.params as ReportParamsType).condition : '';
            if (condition) {
                this.conditionState.clearData();
                await this.conditionState.parseConditionString(condition, null, tableId);
            }
        }
    };

    /**
     * Получение текущего имени таблицы
     * @returns {*}
     */
    getTableName = () => {
        if (!this.additionalData && !this.reportData) {
            return '';
        }
        let tableName = '';
        const additionalData = this.additionalData;
        if (this.report && additionalData) {
            tableName = (additionalData as AdditionalDataType).source_table;
        }
        const reportData: any = this.reportData;
        if (reportData) {
            tableName = reportData.tableName;
        }
        return tableName;
    };

    /**
     * Установка текущей таблица
     * @param table - Информация о таблице, которую устанавливаем
     * @param notNeedCleanData - Флаг, который показывает, что данные не надо чистить. Например, при получении репорта в дашборде
     */
    setTable = (table, notNeedCleanData = false) => {
        this.prevTable = _.clone(this.table);
        if (!this.report_id && !_.isEqual(table, this.table)) {
            // Обновляем URL
            this.params.table_id = table ? table.database_value : null;
            if (!table) {
                this.params.group_by = null;
            }
            this.updateUrl();
        }
        if (table && !_.isEqual(table, this.table) && !notNeedCleanData) {
            // очищаем все предыдущие значение, т.к. при новой таблицы нужно все заново устанавливать
            this.clearRecord();
            this.clearChart();
            this.clearStyles();
            if (!this.sFormSysId) {
                (window as any).s_form = new SimpleForm(this.typeTable && this.typeTable.name, null, undefined, undefined, this.parentFormId);
                this.sFormSysId = null;
            }
            if (table && this.type) {
                this.setReportType(
                    {
                        name: this.type.type_name,
                        id: this.type.type_id,
                    },
                    this.types, true,
                );
            }
        }
        if (!_.isEqual(table, this.table)) {
            if (this.conditionState && table) {
                this.fetchConditionsData(table.database_value);
            }
            // фильтр закрывается, когда меняем таблицы
            this.stateFlags = this.stateFlags & ~IS_OPEN_FILTER;
        }
        this.table = table;
    };

    /**
     * Установка значения group by в url
     * @param groupBy
     */
    setGroupByToUrl = (groupBy) => {
        this.params.group_by = groupBy;
        this.updateUrl();
    };

    updateConditionInUrl = () => {
        this.params.condition = calculateCondtionString(this.fixedCondition, this.conditionState.getConditionString(), '');
        this.updateUrl();
        (window as any).s_form.setValue('condition', this.params.condition); // для скрытого поля, для возможности обновлять на лету значение на форме и вызова его через s_form.getValue
    };

    /**
     * Устанавливаем ошибки репорта. Если, в индексе ошибки пришли оповещение о том, что слишком много данных\ нет данных
     * Для данных случаев мы отрисовываем макеты специальные.
     */
    setReportError() {
        if (this.chart.error_index) {
            this.indexReportError = this.chart.error_index;
        }
    }

    /**
     * Устанавливает флаг открытия filterCondtions на противоположный
     */
    toggleIsOpen = () => {
        this.stateFlags = this.stateFlags ^ IS_OPEN_FILTER;
    };

    onChangeField = async (model) => {
        switch (model.sysColumnName) {
            case 'name':
                this.setName(model.value);
                break;
            case 'table_id':
                this.setTable(model.value);
                break;
            case 'aggregation_column':
                if (model.value.database_value) {
                    isDurationAverage(this);
                } else {
                    this.isDurationAverage = false;
                }
                break;
            default:
                break;
        }
    }

    /**
     * Установка названия отчета
     * @param name
     */
    setName(name) {
        if (!this.report_id && !_.isEqual(name, this.name)) {
            // Обновляем URL
            this.params.name = name;
            this.updateUrl();
        }
        this.name = name;
    }

    updateNameInUrl = () => {
        if (this.params.name !== this.name) {
            this.params.name = this.name;
            this.updateUrl();
        }
    };

    updateUrl() {
        helperRedirectReplace({
            search: paramsToString(
                _.omitBy(this.params, _.isEmpty),
            ),
        });
    }

    /**
     * Установка типа репорта
     * @param name - название отчета
     * @param id - айди типа отчета, на который хотим переключится
     * @param types - все существующие типы отчета
     * @param forceReloadRecord - параметр, который отвечает за принудительную перегрузку рекорда
     */
    async setReportType({ name, id }, types = this.types, forceReloadRecord = false) {
        if (this.stateFlags & IS_LOADING_RECORD) return;
        this.prevType = _.clone(this.type);
        const newType = getReportType({
            name,
            id,
        }, types);
        if (!_.isEqual(this.type, newType) || forceReloadRecord) {
            this.stateFlags = this.stateFlags | IS_LOADING_RECORD;
            if (!this.sFormSysId) {
                (window as any).s_form = new SimpleForm(this.typeTable && this.typeTable.name, null, undefined, undefined, this.parentFormId);
                this.sFormSysId = null;
            }
            this.clearRecord();
            if (!this.type && this.report_id) {
                this.type = newType;
                this.stateFlags = this.stateFlags | IS_UPDATE_CHART;
                await fetchRecords(this.report_id);
                await getUiActions(this, this.report_id);
                this.stateFlags = this.stateFlags & ~IS_UPDATE_CHART;
            } else {
                this.type = newType;
                await fetchRecords();
                await getUiActions(this, null);
            }
            // Обновляем URL
            if (!this.report_id) {
                this.params.type = newType.type_service_name;
                this.updateUrl();
            }
            this.clearStyles();
        }
    }

    /**
     * Преобразование данных в общий для всех отчетов формат.
     * @param isUpdateStyles
     * @returns {{data: (String.data|{}), error: (String.error|Array)}|{}}
     */
    getData = (isUpdateStyles = false) => {
        if (!this.configForm.current) {
            return {};
        }
        let { data, error } = this.configForm.current.model.serialize(true);
        let nameReport = this.name;
        let tableReport = this.table && this.table.database_value;
        if (this.dataForm.current) {
            let { data: dataFormData } = this.dataForm.current.model.serialize(true);
            nameReport = dataFormData.name;
            tableReport = dataFormData.table_id;
        }
        distributeData(data, nameReport, tableReport, this.type);
        if (this.stylesForm) {
            _.each(this.stylesForm, (form: any) => {
                    if (form.current) {
                        const dataStyle = form.current.model.serialize(true);
                        if (dataStyle.data) {
                            if (isUpdateStyles) {
                                this.styles = { ...this.styles, ...dataStyle.data };
                            }
                            data = { ...dataStyle.data, ...data };
                        }
                    }
                },
            );
        }

        return {
            data,
            error,
        };
    };

    getTypes = () => {
        return this.types;
    };

    getParentFormId = () => {
        return this.parentFormId;
    };

    setParentFormId = () => {
        this.parentFormId = generateRandomString();
        return this.parentFormId;
    };
}


export const reportStateClass = ReportState;

export default new ReportState();
