import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

/**
 * Виджет выпадающего списка (сам выпадающий список)
 * Props:
 * children - <span event-click="...">menu item 1</span><span>menu item 2</span>
 */
@observer
export default class DropdownWidgetItems extends React.Component {

    elementRef = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const { current: el } = this.elementRef;
        const { current: parentEl } = this.context.elementRef;

        if (!el || !parentEl) return false;

        if (!el.contains(e.target) && !parentEl.contains(e.target)) {
            this.context.isOpened = false;
        }
    };

    render() {
        let cn = [ styles.menu ];
        if (this.context.isOpened) cn.push(styles.active);
        cn = cn.join(' ');

        return (
            <div className={ cn } ref={ this.elementRef }>
                <div className={ styles.item }>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
