import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {RouteComponentProps} from "react-router-dom";
import { fetchAuthTranslateData } from 'actions/auth';
import userState from "globalState/user/index";
import { fetchSsoInfo } from 'actions/auth';
import {helperRedirect} from "helpers/history";
import styles from "components/authSsoRedirect/styles.module.scss";
import PageLoader from "components/pageLoader/index";
import {LangData} from "types/page/loginPage";
import {ROUTES} from "constants/routers";

const AuthHOC = (WrappedComponent) => {
    @observer
    class AuthWrappedComponent extends React.Component<RouteComponentProps>  {
        @observable langData: LangData = {};
        @observable isLoadingSsoRequest = false;
        static displayName = `AuthWrapped(${ WrappedComponent.displayName || WrappedComponent.name || 'Component' })`;

        constructor(props) {
            super(props);

            this.fetchSsoInfo().catch(() => this.isLoadingSsoRequest = false);
            this.fetchTranslate().catch(console.error);
        }

        get isNotRequestSso() {
            const { match: { path } } = this.props;

            const closedToRedirect: string[] = [
                ROUTES.SIGNUP,
                ROUTES.NEW_PASSWORD,
                ROUTES.RESET_PASSWORD,
                ROUTES.SIDE_DOOR,
                ROUTES.LOGOUT,
                ROUTES.AUTH_SSO,
            ];

            return closedToRedirect.includes(path);
        }

        fetchSsoInfo = async (): Promise<void> => {
            if (this.isNotRequestSso) return;
            this.isLoadingSsoRequest = true;

            const { data: {enabled, welcomePageUrl, ssoLoginUrl}, isOkStatus } = await fetchSsoInfo();
            if (isOkStatus) {
                userState.ssoEnabled = enabled;
                if(enabled && ssoLoginUrl) {
                    helperRedirect(ssoLoginUrl);
                } else if (welcomePageUrl) {
                    helperRedirect(welcomePageUrl);
                }
            }
            this.isLoadingSsoRequest = false;
        };

        fetchTranslate = async (choseLange = ''): Promise<void> => {
            let lang: string;
            if (choseLange) {
                lang = `/${ choseLange }`;
            }
            else {
                const _lang = window.localStorage.getItem('lang');
                lang = _lang ? `/${ _lang }` : '';
            }
            const { isOkStatus, data: langData } = await fetchAuthTranslateData(lang);
            if (isOkStatus) {
                const langValue = langData.language;
                const langSpecial = langData.special;
                this.langData = {
                    langValue,
                    langSpecial,
                };
                window.localStorage.setItem('lang', langValue);
            }
        };

        render() {
            const { fetchTranslate, langData, isLoadingSsoRequest, isNotRequestSso, props } = this;

            if (isLoadingSsoRequest) {
                return (
                    <div className={ styles.WrapLoader }>
                        <PageLoader />
                    </div>
                );
            }

            return <WrappedComponent
                { ...props }
                onFetchTranslate={ fetchTranslate }
                isNotRequestSso = { isNotRequestSso }
                isLoadingSsoRequest={ isLoadingSsoRequest }
                langData={ langData }
            />;
        }
    }

    return AuthWrappedComponent;
};

export default AuthHOC;
