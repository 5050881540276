import * as React from 'react';
import styles from './styles.module.scss';


function Badge({width, height, background, color, children, className, innerRef, ...props}){
    const styleObj = {};

    if(width){
        styleObj.minWidth = `${width}px`;
    }
    if(height){
        styleObj.height = `${height}px`;
        styleObj.lineHeight = `${height}px`;
    }
    if(background){
        styleObj.background = `${background}`;
    }
    if(color){
        styleObj.color = `${color}`;
    }

    return (
        <div
            { ...props }
            className={ `${styles.Badge} ${className}` }
            style={ styleObj }
            ref={ innerRef }
        >
            { children }
        </div>
    );
}


export default React.forwardRef((props, ref) => <Badge innerRef={ ref } { ...props } />);