import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import styles from './styles.module.scss';
import IconUserDefault from 'assets/img/user-ava.svg';
import IconUserImpersonated from 'assets/img/user-ava-impersonated.svg';
import IconElevate from 'assets/img/icons/circle-up.svg';
import userState from 'globalState/user';
import langStore from 'globalState/lang';
import { Link } from 'react-router-dom';
import { helperRedirect } from 'helpers/history';
import Dropdown from 'components/dropdown';
import { isMedia } from 'helpers/html';
import GlobalPortal from 'components/globalPortal';
import IconX from 'assets/img/icons/close-x.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import apiRequest from 'lib/apiRequest';
import { globalEventEmitter } from 'lib/EventEmitter';
import ImpersonatePopup from 'components/impersonatePopup';
import ElevatePopup from 'components/elevatePopup';
import IconCheck from 'assets/img/icons/check.svg';
import IconChevron from 'assets/img/icons/chevron-right.svg';
import ModalWrapper from 'components/modalWrapper';
import ArrowLeft from 'assets/img/icons/arrow-left.svg';
import { savePreference } from 'actions/preferences';
import cache from 'globalState/cache';
import {ROUTES} from "constants/routers";
import userElevateState from 'globalState/user/elevate';

/**
 * Виджет авторизации для шапки (если авторизован, показывает твой аватар и имя, если нет, то предлагает залогинится)
 * Props:
 * @param {string} loginUrl - урл на странуци логина (по умолчанию "/login")
 * @param {string} loginText - текс ссылки на страницу логина (по умолчанию "Login")
 * @param {string} profileURL
 */
@observer
export default class AuthHeader extends React.Component {
    @observable isOpened = false;
    @observable showImpersonatePopup = false;
    @observable showElevatePopup = false;
    elementRef = React.createRef();
    refDropdown = React.createRef();

    @observable isOpenLangMenu = false;
    langRefDropdown = React.createRef();
    langElementRef = React.createRef();

    constructor(props) {
        super(props);

        reaction(
            () => this.isOpened,
            this.freezeBodyScroll,
        );
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        userElevateState.checkElevateStatus();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    freezeBodyScroll = () => {
        const { body } = document;

        if (!isMedia('sm')) return;

        if (this.isOpened) {
            body.classList.add(styles.Freeze);
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${ scrollY }px`;
        }
        else {
            body.classList.remove(styles.Freeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    };

    handleClickOutside = (event) => {
        if (!this.refDropdown || !this.elementRef || !this.langElementRef) {
            return null;
        }
        const { current: el } = this.refDropdown;
        const { current: parentEl } = this.elementRef;
        const { current: langMenuEl } = this.langRefDropdown;

        if (!el || !parentEl) {
            return false;
        }

        if (!el.contains(event.target) && !parentEl.contains(event.target)) {
            if (langMenuEl) {
                if (!langMenuEl.contains(event.target)) {
                    this.isOpenLangMenu = false;
                    this.isOpened = false;
                }
            }
            else {
                this.isOpened = false;
            }
        }
    };

    handleClick = () => {
        this.isOpened = !this.isOpened;
    };

    handleClickMenu = (url) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        helperRedirect(url);
    };

    handleClickLogout = (event) => {
        event.stopPropagation();
        event.preventDefault();
        userState.logout();
    };

    deImpersonate = async (id) => {
        this.isOpened = false;
        const response = await new apiRequest(`GET /impersonate`).qs({ user_id: id }).send();
        if (response && response.status === 'OK') {
            if (response.data && response.data.auth_key && response.data.auth_key !== '') {
                await userState.setAccessToken(response.data.auth_key);
                // Fire deImpersonate event for subscribers that can do some logic with this
                globalEventEmitter.emit('deImpersonate');
            }
        }
    };

    handleShowPopup = (type) => {
        switch (type) {
            case 'elevate':
                this.showElevatePopup = true;
                break;
            case 'impersonate':
                this.showImpersonatePopup = true;
                break;
        }

        this.isOpened = !this.isOpened;
    };

    impersonatePopup = () => {
        return (
            <ImpersonatePopup
                onClose={ () => this.showImpersonatePopup = false }
                show={ this.showImpersonatePopup }
                userId={ userState.user.sys_id }
                ref="popup"
                onToggle={ () => this.showImpersonatePopup = !this.showImpersonatePopup }
            />
        );
    };

    elevatePopup = () => {
        return (
            <ElevatePopup
                onClose={ () => this.showElevatePopup = false }
                show={ this.showElevatePopup }
                userId={ userState.user.sys_id }
                onToggle={ () => this.showElevatePopup = !this.showElevatePopup }
            />
        );
    };

    renderProfileLink = () => {
        const { profileUrl } = this.props;
        const { user_menu_titles } = langStore.getTranslate();

        if (!profileUrl) {
            return null;
        }

        return (
            <div className={ styles.Element } onClick={ this.handleClickMenu(profileUrl) } data-test={ ATTRIBUTES.userProfileItem }>
                <span className={ styles.ElementTitle }>
                    { user_menu_titles && user_menu_titles.profile }
                </span>
            </div>
        );
    };

    renderImpersonateLink = () => {
        const { user } = userState;
        const { user_menu_titles = {} } = langStore.getTranslate();

        if (!user.hasOwnProperty('impersonate_state')) {
            return null;
        }

        return (
            <div className={ styles.Element } onClick={ () => this.handleShowPopup('impersonate') }>
                <span className={ styles.ElementTitle } data-test={ ATTRIBUTES.showUserImpersonate }>
                  { user_menu_titles.impersonate }
                </span>
            </div>
        );
    };

    renderDeImpersonateLink = () => {
        const { user } = userState;
        const { user_menu_titles = {} } = langStore.getTranslate();

        if (!user.impersonate_state) {
            return null;
        }

        return (
            <>
                <div className={ styles.ImpersonateSeparator } />
                <div className={ styles.Element } onClick={ () => this.deImpersonate(user.impersonate_state) } data-test={ ATTRIBUTES.backToMe }>
                        <span className={ styles.ElementTitle }>
                            { user_menu_titles.back_to_me }
                        </span>
                </div>
            </>
        );
    };

    renderElevateLink = () => {
        const { user } = userState;
        const { user_menu_titles = {} } = langStore.getTranslate();

        if (user?.elevate_access?.toString() === '0' || undefined) {
            return null;
        }

        return (
            <div className={ styles.Element } onClick={ () => this.handleShowPopup('elevate') }>
                <span className={ styles.ElementTitle } data-test={ ATTRIBUTES.showUserElevate }>
                  { user_menu_titles.elevate_roles }
                </span>
            </div>
        );
    };

    handleSelectLang = async (db_lang) => {
        const { isOkStatus } = await savePreference('language_id', db_lang);
        if (isOkStatus) {
            cache.deleteAllByPrefix();
            setTimeout(() => {
                window.location.reload();
            }, 150);
        }
        this.isOpenLangMenu = false;
        this.isOpened = false;
    };

    handleMouseOut = (e) => {
        const { current: langMenuEl } = this.langRefDropdown;
        if (!langMenuEl) {
            return false;
        }
        if (!langMenuEl.contains(e.relatedTarget)) {
            this.isOpenLangMenu = false;
        }
    };

    renderMobileLangPopup = (items) => {
        const { language = '' } = langStore.getTranslateKey('user_menu_titles');
        return (
            <ModalWrapper>
                <div className={ styles.MobilePopup }>
                    <div className={ styles.MobileHeader }>
                        <div
                            className={ styles.MobileButton }
                            dangerouslySetInnerHTML={ { __html: ArrowLeft } }
                            onClick={ () => this.isOpenLangMenu = false }
                        />
                        <div
                            className={ styles.MobileTitle }
                            onClick={ () => this.isOpenLangMenu = false }
                        >
                            { language }
                        </div>
                        <div
                            className={ styles.MobileButton }
                            dangerouslySetInnerHTML={ { __html: IconX } }
                            onClick={ () => {
                                this.isOpened = false;
                                this.isOpenLangMenu = false;
                            }
                            }
                        />
                    </div>
                    <div className={ styles.Menu }>
                        { items }
                    </div>
                </div>
            </ModalWrapper>
        );
    };

    renderLangPopup = (items) => {
        return (
            <Dropdown defaultDirection={ 'middle' } floatRight offsetLeft={ -280 } refParent={ this.langElementRef } ref={ this.langRefDropdown }>
                <div className={ styles.Menu }>
                    { items }
                </div>
            </Dropdown>
        );
    };

    renderChangeLanguage = () => {
        const { language = '' } = langStore.getTranslateKey('user_menu_titles');
        const { user: { language: { language_settings = [] } } } = userState;
        let menu;

        const items = language_settings.map((item, index) => {
            return (
                <div className={ styles.Element } key={ index }>
                    <span
                        onClick={ () => this.handleSelectLang(item.database_value) }
                        className={ styles.ElementTitle }
                        data-test={ ATTRIBUTES.headerLang }
                    >
                        { item.title }
                        { item.is_current === true &&
                        <div
                            className={ `${ styles.ElementIcon } ${ styles.colorGreen }` }
                            data-test={ ATTRIBUTES.activeHeaderLang }
                            dangerouslySetInnerHTML={ { __html: IconCheck } }
                        />
                        }
                    </span>
                </div>
            );
        });

        if (isMedia('sm')) {
            menu = this.renderMobileLangPopup(items);
        }
        else {
            menu = this.renderLangPopup(items);
        }

        return (
            <>
                <div className={ styles.Element } ref={ this.langElementRef }>
                    <span
                        onMouseOut={ this.handleMouseOut }
                        onMouseOver={ () => this.isOpenLangMenu = true }
                        className={ styles.ElementTitle }
                        data-test={ ATTRIBUTES.headerMenuLang }
                    >
                        { language }
                        <div className={ styles.ElementIcon } dangerouslySetInnerHTML={ { __html: IconChevron } } />
                    </span>
                </div>
                { this.isOpenLangMenu && menu }
            </>
        );
    };

    renderDropdown = () => {
        if (!this.isOpened) {
            return null;
        }

        const { user } = userState;
        const { user_menu_titles } = langStore.getTranslate();
        const menuTpl = (
            <div className={ styles.Menu }>
                <div className={ styles.MenuHeader }>{ `${ user.first_name ? user.first_name + ' ' : '' }${ user.last_name ? user.last_name : '' }` }</div>
                { this.renderProfileLink() }
                { this.renderImpersonateLink() }
                { this.renderElevateLink() }
                { this.renderChangeLanguage() }

                <div className={ styles.Element }>
                        <span onClick={ this.handleClickLogout } className={ styles.ElementTitle } data-test={ ATTRIBUTES.userLogout }>
                            { user_menu_titles && user_menu_titles.logout }
                        </span>
                </div>
                { this.renderDeImpersonateLink() }
            </div>
        );

        return isMedia('sm') ? (
            <GlobalPortal>
                <div className={ styles.Popup }>
                    <div className={ styles.PopupHeader }>
                        <div className={ styles.PopupHeaderText }>{ `${ user.first_name ? user.first_name + ' ' : '' }${ user.last_name ? user.last_name : '' }` }</div>
                        <div
                            className={ styles.PopupClose }
                            dangerouslySetInnerHTML={ { __html: IconX } }
                            onClick={ () => {
                                this.isOpened = false;
                            } }
                        />
                    </div>
                    { menuTpl }
                </div>
            </GlobalPortal>
        ) : (
            <Dropdown floatRight refParent={ this.elementRef } ref={ this.refDropdown }>
                { menuTpl }
            </Dropdown>
        );
    };

    render() {
        const { user } = userState;
        const { loginUrl = ROUTES.LOGIN, loginText = 'Login', className } = this.props;

        if (_.isEmpty(user)) {
            return (
                <div>
                    <Link to={ loginUrl }>{ loginText }</Link>
                </div>
            );
        }

        let ava;
        if (user.impersonate_state) {
            ava = <div className={ styles.Ava } dangerouslySetInnerHTML={ { __html: IconUserImpersonated } } />;
        }
        else {
            ava = user.photo_path ?
                <div className={ styles.Ava } style={ { backgroundImage: `url("${ user.photo_path }")` } } /> :
                <div className={ styles.Ava } dangerouslySetInnerHTML={ { __html: IconUserDefault } } />;
        }

        const elevateState = user?.elevate_access?.toString() !== '0' ? <div className={ `${ styles.Elevate } ${ userElevateState.getIsElevate() && user?.elevate_access?.toString() !== '-1' ? styles.Active : '' }` } dangerouslySetInnerHTML={ { __html: IconElevate } } /> : null;

        return (
            <div className={ className || '' } data-test={ this.props['data-test'] ? this.props['data-test'] : `authheader-${ ATTRIBUTES.widget }` }>
                <div className={ `${ styles.User } ${ this.isOpened ? styles.active : ''}` } ref={ this.elementRef }>
                    <div className={ styles.Info } onClick={ this.handleClick }>
                        <div className={ styles.AvaWrap }>
                            { ava }
                            { elevateState }
                        </div>
                    </div>
                </div>
                { this.renderDropdown() }
                { this.elevatePopup() }
                { this.impersonatePopup() }
            </div>
        );
    }
}
