import { action, observable } from 'mobx';
import * as React from 'react';
import * as _ from 'lodash';
import parse from 'html-react-parser';
import ServicePortalWidgetsPage from 'pages/servicePortalWidgets';
import modalsDataState from 'globalState/modals';

class ModalDataState {
    @observable _id: number | undefined;
    @observable _isShow = false;
    @observable _width = 0;
    @observable _title = '';
    @observable _element: JSX.Element | undefined;
    @observable private _isLoading = false;

    constructor(id?: number) {
        if (id) {
            this._id = id;
        }
    }

    @action
    setId = (id: number) => {
        this._id = id;
    };

    getId = (): number | undefined => {
        return this._id;
    };

    @action
    setShow = (isShow = true) => {
        this._isShow = isShow;
    };

    getShow = (): boolean => {
        return this._isShow;
    };

    @action
    setWidth = (width: number) => {
        this._width = width;
    };

    getWidth = (): number => {
        return this._width;
    };

    @action
    setTitle = (title: string) => {
        this._title = title;
    };

    getTitle = (): string => {
        return this._title;
    };

    getIsLoading(): boolean {
        return this._isLoading;
    }

    setIsLoading(value: boolean) {
        this._isLoading = value;
    }

    getElement = (): JSX.Element | undefined => {
        return this._element;
    };

    @action
    renderTemplate = (template: string, styles: string) => {
        _.forEach(modalsDataState.getModals(), modal => {
            if (modal.getId() !== this._id) {
                modal.setShow(false);
            }
        });
        const resultTemplate = styles ? `<style>${ styles }</style>${ template }` : template;
        this.setShow();
        this._element = parse(resultTemplate);
    };

    @action
    renderPageTemplate = (pageTemplatePathName: string, portalSuffix: string) => {
        _.forEach(modalsDataState.getModals(), modal => {
            if (modal.getId() !== this._id) {
                modal.setShow(false);
            }
        });
        this.setShow();
        this._element = (
            <ServicePortalWidgetsPage
                portalSuffix={ portalSuffix }
                pageTemplatePathName={ pageTemplatePathName }
                isModal
            />
        );
    };
}

export const modalDataState = ModalDataState;
export default new ModalDataState();
