import {useEffect} from 'react';
import {mergeRegister } from '@lexical/utils';
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import type {SerializedLexicalNode} from "lexical/LexicalNode";
import type {SerializedRootNode} from "lexical/nodes/LexicalRootNode";

export const OnChangePlugin = ({value, setValue, setEditor, isReadOnly, setRawEditor}) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        editor.setEditable(!isReadOnly);
    }, [isReadOnly]);

    useEffect(() => {
        setEditor(editor);

        editor.update(() => {
            if (!value) return;
            const editorState = editor.parseEditorState(JSON.parse(value));
            editor.setEditorState(editorState);
        });

        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    const snapshot = editorState.toJSON();
                    const rootElement = editor.getRootElement();

                    setRawEditor(editor);

                    const isNotEmpty = (value?: Array<SerializedLexicalNode>) =>
                        value?.some((row: SerializedRootNode) => !!row.children.length);

                    const isEmptyField = !rootElement?.textContent && !isNotEmpty(snapshot?.root?.children);
                    if(isEmptyField) {
                        setValue('');
                    } else {
                        setValue(JSON.stringify(snapshot));
                    }
                });
            }),
        );
    }, [editor]);


    return null;
};