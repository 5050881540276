import * as React from 'react';
import { observer } from 'mobx-react';
import { helperRedirect } from 'helpers/history';
import Heading from 'components/heading';
import styles from './styles.module.scss';


@observer
export default class SLMComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    renderHead() {
        const diagonalCol = <td className={ styles.diagonalCol }>
            <svg className={ styles.line }
                 style={ {
                     width: '100%',
                     height: '100%',
                 } }>
                <line x1="0" y1="0" x2="100%" y2="100%"
                      style={ {
                          stroke: 'rgb(204,204,204)',
                          strokeWidth: 1,
                      } } />
            </svg>
            <div className={ styles.content }>
                <div>{ this.props.data.axes.x }</div>
                <div>{ this.props.data.axes.y }</div>
            </div>
        </td>;
        const cols = this.props.data.targets[0].period.map((item, index) => {
            return <td key={ index }>{ item.title }</td>;
        });
        return <thead className={ styles.tHead }>
        <tr>
            { diagonalCol }
            { cols }
        </tr>
        </thead>;
    }

    renderRows() {
        const rows = this.props.data.targets.map((target, index) => {
            const first = <td>
                <div className={ styles.titleCol } onClick={ () => {
                    helperRedirect(target.url);
                }}>
                    { target.title }
                </div>
            </td>;
            const last = target.period.map((item, index) => {
                const color = item.color ? item.color : 'gray';
                return <td key={ index } className={ styles[color] } onClick={ () => {
                    helperRedirect(item.url);
                } }>
                    <div className={ styles.placeholder }>
                        <span className={ styles.hint } dangerouslySetInnerHTML={{__html: item.hint}}/>
                    </div>
                </td>;
            });
            return <tr key={ index }>
                { first }
                { last }
            </tr>;
        });

        return <tbody className={ styles.tBody }>{ rows }</tbody>;
    }

    renderLegends() {
        return this.props.data.legends.map((legend, index) => <div key={ index } className={ styles.legend + ' ' + styles[legend.color] }>{ legend.title }</div>);
    }

    render() {
        return (
            <>
                <Heading size={'h2'}>{ this.props.data.title }</Heading>
                <div className={ styles.main }>
                    <table className={ styles.table }>
                        { this.renderHead() }
                        { this.renderRows() }
                    </table>
                    <div className={ styles.legendsBlock }>
                        { this.renderLegends() }
                    </div>
                </div>

            </>
        );
    }
}
