import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable, reaction } from 'mobx';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import IconUserDefault from 'assets/img/user-ava.svg';
import IconUserImpersonated from 'assets/img/user-ava-impersonated.svg';
import ImpersonatePopup from 'components/impersonatePopup';
import ElevatePopup from 'components/elevatePopup';
import IconElevate from 'assets/img/icons/circle-up.svg';
import IconDeImpersonate from 'assets/img/icons/circle-right.svg';
import apiRequest from 'lib/apiRequest';
import userState from 'globalState/user';
import langStore from 'globalState/lang';
import { globalEventEmitter } from 'lib/EventEmitter';
import Button from 'components/button';
import IconX from 'assets/img/icons/close-x.svg';
import { isMedia } from 'helpers/html';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { beforeUnload } from 'helpers/form';
import userElevateState from 'globalState/user/elevate';

@observer
export default class UserComponent extends React.Component {
    @observable isOpened = false;
    @observable showImpersonatePopup = false;
    @observable showElevatePopup = false;
    elementRef = React.createRef();

    constructor(props) {
        super(props);

        reaction(
            ()=> this.isOpened,
            this.freezeBodyScroll
        );
        reaction(
            ()=> this.showImpersonatePopup,
            this.freezeBodyScroll
        );
        reaction(
            ()=> this.showElevatePopup,
            this.freezeBodyScroll
        );
    }

    freezeBodyScroll = () => {
        const { body } = document;

        if ((this.isOpened || this.showImpersonatePopup || this.showElevatePopup) && isMedia('sm')) {
            body.classList.add(styles.Freeze);
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${scrollY}px`;
        }
        else {
            const wasOpened = body.classList.contains(styles.Freeze);
            body.classList.remove(styles.Freeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            if(wasOpened){
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            }
        }
    };

    componentDidMount() {
        userElevateState.checkElevateStatus();
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    @action handleShowImpersonatePopup = () => {
        this.showImpersonatePopup = true;
        this.isOpened = !this.isOpened;
    };
    @action handleShowElevatePopup = () => {
        this.showElevatePopup = true;
        this.isOpened = !this.isOpened;
    };
    handleCloseImpersonatePopup = () => {
        this.showImpersonatePopup = false;
    };
    handleCloseElevatePopup = () => {
        this.showElevatePopup = false;
    };
    handleToggleImpersonatePopup = () => {
        this.showImpersonatePopup = !this.showImpersonatePopup;
    };
    handleToggleElevatePopup = () => {
        this.showElevatePopup = !this.showElevatePopup;
    };
    impersonatePopup = () => {
        return (
            <ImpersonatePopup
                onClose={ this.handleCloseImpersonatePopup }
                show={ this.showImpersonatePopup }
                userId={ userState.user.sys_id }
                ref="popup"
                onToggle={ this.handleToggleImpersonatePopup }
            />
        );
    };
    elevatePopup = () => {
        return (
            <ElevatePopup
                onClose={ this.handleCloseElevatePopup }
                show={ this.showElevatePopup }
                userId={ userState.user.sys_id }
                onToggle={ this.handleToggleElevatePopup }
            />
        );
    };

    handleClickOutside(e) {
        const { current: el } = this.elementRef;

        if (!el) return false;

        if (!el.contains(e.target)) {
            this.isOpened = false;
        }
    }

    handleClick = () => {
        this.isOpened = !this.isOpened;
    };

    handleLinkClick = (evt) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
        this.isOpened = !this.isOpened;
    };

    handleLogout = () => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            return;
        }
        userState.logout();
    };

    deImpersonate = async id => {
        this.isOpened = false;
        const response = await new apiRequest(`GET /impersonate`)
            .qs({ user_id: id })
            .send();
        if (response && response.status === 'OK') {
            if (
                response.data &&
                response.data.auth_key &&
                response.data.auth_key !== ''
            ) {
                await userState.setAccessToken(response.data.auth_key);
                // Fire deImpersonate event for subscribers that can do some logic with this
                globalEventEmitter.emit('deImpersonate');
            }
        }
    };

    render() {
        const { user } = userState;

        const { user_menu_titles } = langStore.getTranslate();
        if (_.isEmpty(user)) return null;
        let ava;
        if (user.impersonate_state) {
            ava = (
                <div
                    className={ styles.Ava }
                    dangerouslySetInnerHTML={ { __html: IconUserImpersonated } }
                />
            );
        }
        else {
            ava = user.photo_path ? (
                <div
                    className={ styles.Ava }
                    style={ { backgroundImage: `url("${ user.photo_path }")` } }
                />
            ) : (
                <div
                    className={ styles.Ava }
                    dangerouslySetInnerHTML={ { __html: IconUserDefault } }
                />
            );
        }
        let elevateState = null;
        if (user?.elevate_access?.toString() !== '0') {
            const isActive = userElevateState.getIsElevate() && user?.elevate_access?.toString() !== '-1';
            elevateState = (
                <div
                    className={ `${ styles.Elevate } ${ isActive ? styles.active : '' }` }
                    dangerouslySetInnerHTML={ { __html: IconElevate } }
                />
            );
        }

        return (
            <React.Fragment>
                <div className={ styles.User } ref={ this.elementRef }>
                    <div className={ styles.Info } onClick={ this.handleClick } data-test={ ATTRIBUTES.headerOptionUserItem }>
                        <div className={ styles.AvaWrap }>
                            { ava }
                            { elevateState }
                        </div>

                        <div className={ styles.Text }>
                            { `${ user.first_name ? user.first_name + ' ' : '' }${
                                user.last_name ? user.last_name : ''
                                }` }{ ' ' }
                        </div>
                    </div>

                    <div
                        className={
                            this.isOpened
                                ? [
                                    styles.Menu,
                                    styles.active,
                                ].join(' ')
                                : styles.Menu
                        }
                    >
                        <div className={styles.MobileHeader}>
                            <div className={styles.MobileTitle}>
                                { `${ user.first_name ? user.first_name + ' ' : '' }${
                                    user.last_name ? user.last_name : ''
                                    }` }{ ' ' }
                            </div>
                            <Button
                                className={styles.MobileClose}
                                buttonType={'icon'}
                                svg={IconX}
                                onClick={()=>{
                                    this.isOpened = false;
                                }}
                            />
                        </div>
                        <div className={ styles.MenuItem }>
                            <Link
                                to={ `/record/${ user.essence }/${ user.sys_id }` }
                                onClick={ this.handleLinkClick }
                                data-test={ ATTRIBUTES.userProfileItem }
                            >
                                { user_menu_titles && user_menu_titles.profile }
                            </Link>
                        </div>
                        { user.hasOwnProperty('impersonate_state') && (
                            <div className={ styles.MenuItem }>
                                <span onClick={ () => this.handleShowImpersonatePopup() } data-test={ ATTRIBUTES.showUserImpersonate }>
                                  { user_menu_titles && user_menu_titles.impersonate }
                                </span>
                            </div>
                        ) }
                        { (user?.elevate_access?.toString() !== '0' || undefined) && (
                            <div className={ styles.MenuItem }>
                                <span onClick={ () => this.handleShowElevatePopup() } data-test={ ATTRIBUTES.showUserElevate }>
                                  { user_menu_titles && user_menu_titles.elevate_roles }
                                </span>
                            </div>
                        ) }
                        <div className={ styles.MenuItem }>
                              <span onClick={ this.handleLogout } data-test={ ATTRIBUTES.userLogout }>
                                { user_menu_titles && user_menu_titles.logout }
                              </span>
                        </div>
                        { !!user.impersonate_state && (
                            <>
                                <div className={ styles.MenuSplit } />
                                <div className={ styles.MenuItem }>
                                      <span
                                          onClick={ () => this.deImpersonate(user.impersonate_state) }
                                      >
                                        <span
                                            className={ styles.MenuIcon }
                                            dangerouslySetInnerHTML={ { __html: IconDeImpersonate } }
                                        />
                                          { user_menu_titles && user_menu_titles.back_to_me }
                                      </span>
                                </div>
                            </>
                        ) }
                    </div>
                </div>
                { this.elevatePopup() }
                { this.impersonatePopup() }
            </React.Fragment>
        );
    }
}
