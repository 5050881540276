import * as React from 'react';
import styles from './styles.module.scss';
import Checkbox from 'components/dynamicForms/view/field/checkbox';
import { observer } from 'mobx-react';
import { ActivityMenuItem, MenuSettingsProps } from 'types/components/activityFeed';
import _ from 'lodash';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { observable } from 'mobx';
import IconClose from 'assets/img/icons/close-x.svg';
import IconSearch from 'assets/img/icons/search.svg';
import Button from 'components/button';
import langStore from "globalState/lang";
import { getClassNameAF, getFilteredHistoryFields } from 'helpers/activityFeed';

/**
 * Описание: компонент настроек для activity feed
 * isShowSettings: {type: boolean} - флаг показа настроек
 * activityState: {type: object} - глобальное хранилище
 * isModal: {type: boolean} - в модальном окне или нет
 */
@observer
export default class MenuSettings extends React.Component<MenuSettingsProps> {
    @observable isHistoryFields = false;
    @observable isFocused = false;
    @observable term = '';
    @observable isFilterIconHovered = false;
    refSettings = React.createRef<HTMLDivElement>();
    refFilter = React.createRef<HTMLInputElement>();

    componentDidMount(): void {
        this.checkRightEdge();
        document.addEventListener('click', this.onDocumentClick);
    }

    componentDidUpdate(): void {
        this.checkRightEdge();

        if (!this.props.isShowSettings && this.isHistoryFields) {
            this.isHistoryFields = false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    handleValueChange = (e) => {
        this.term = e.target.value;
    };

    onDocumentClick = ({ target }) => {
        const { refParent, onToggleShowSettings } = this.props;
        const settingsEl = this.refSettings && this.refSettings.current ? this.refSettings.current : null;
        const parentEl = refParent && refParent.current ? refParent.current : null;
        if (!settingsEl || !parentEl) return false;

        if (!settingsEl.contains(target) && !parentEl.contains(target)) {
            if (onToggleShowSettings) {
                onToggleShowSettings();
            }
        }
    };

    handleChangeActivityType = (name, id) => async ({ value }) => {
        const { activityState, tableName, recordId, widgetId } = this.props;
        const isAll = _.find(activityState.getActiveMenuItems(), item => item.name === 'all' && !!item.value);
        const activeTypeIds = isAll
            ? _.map(_.filter(activityState.getActiveMenuItems(),
                item => item.id && item.id.toLowerCase() !== 'null'), (item: ActivityMenuItem) => item.id)
            : _.map(_.filter(activityState.getActiveMenuItems(),
                item => !!item.value && item.id && item.id.toLowerCase() !== 'null'), (item: ActivityMenuItem) => item.id);
        const params = {
            table_name: tableName,
            record_id: recordId,
            widget_instance_id: widgetId,
            type_ids: activeTypeIds,
        };
        activityState.changeActiveMenuItem(name, id, value, params);
    };

    handleChangeHistoryField = (title) => ({ value }) => {
        const { activityState } = this.props;
        activityState.changeActiveMenuHistoryItem(title, value);
    };

    handleSelectAllHistoryField = ({ value }) => {
        const { activityState } = this.props;
        activityState.getActiveHistoryMenuItems().forEach(({ title }) => {
            activityState.changeActiveMenuHistoryItem(title, value);
        });
    };

    handleChangeSettings = () => {
        if (this.isHistoryFields) {
            this.term = '';
            const { activityState, tableName, recordId, widgetId, onToggleShowSettings } = this.props;
            const params = {
                table_name: tableName,
                record_id: recordId,
                widget_instance_id: widgetId,
            };
            activityState.confirmActiveMenuHistoryItem(params);
            if (onToggleShowSettings) {
                onToggleShowSettings();
            }
        }
        this.isHistoryFields = !this.isHistoryFields;
    };

    renderActivityTypeMenu = () => {
        if (this.isHistoryFields) {
            return null;
        }
        const { activityState, isNotStylized, classes } = this.props;
        const activityMenuItems = activityState.getActiveMenuItems();
        const items = _.map(activityState.getActivityTypes(), activityType => {
            const item = _.find(activityMenuItems, menuItem => menuItem.id === activityType.sys_id && menuItem.name === activityType.name);
            if (item?.name === 'all') {
                return null;
            }

            return (
                <div
                    key={ `activityType${ activityType.sys_id }${ activityType.name }` }
                    className={ getClassNameAF(isNotStylized, styles.SettingsMenuItem, classes?.SettingsMenuItem) }
                    data-test={ `${ activityType.name }-${ ATTRIBUTES.activityFeedTypesSettings }` }
                >
                    <Checkbox
                        label={ activityType.title_plural || activityType.title }
                        onChange={ this.handleChangeActivityType(activityType.name, activityType.sys_id) }
                        value={ item ? item.value : 0 }
                        className={ {
                            FieldWrapper: getClassNameAF(isNotStylized, styles.SettingsMenuItemCheckbox, classes?.SettingsMenuItemCheckbox),
                            Input: getClassNameAF(isNotStylized, styles.SettingsMenuItemInput, classes?.SettingsMenuItemInput),
                            Label: getClassNameAF(isNotStylized, styles.SettingsMenuItemLabel, classes?.SettingsMenuItemLabel),
                        } }
                    />
                </div>
            );
        });
        return (
            <div className={ getClassNameAF(isNotStylized, styles.SettingsScroll, classes?.SettingsScroll) }>
                <div className={ getClassNameAF(isNotStylized, styles.MenuBlock, classes?.SettingsMenuBlock) }
                     data-test={ ATTRIBUTES.activityFeedTypesSettings }>
                    { items }
                </div>
            </div>
        );
    };

    onIconClick = () => {
        if (this.term) {
            this.term = '';
        }
        this.refFilter.current?.focus();
    };

    renderHistoryFields = () => {
        if (!this.isHistoryFields) {
            return null;
        }
        const { multi_select_titles }: any = langStore.getTranslate();
        const { activityState, dictionary, isNotStylized, classes } = this.props;
        const filteredHistoryFields = getFilteredHistoryFields(activityState);
        const items = _.map(filteredHistoryFields, field => {
            if (this.term && field.title && !field.title.toLowerCase().includes(this.term.toLowerCase())) {
                return null;
            }
            return (
                <div
                    key={ `historyField${ field.sys_id }` }
                    className={ getClassNameAF(isNotStylized, styles.SettingsMenuItem, classes?.SettingsMenuItem) }
                    data-test={ `${ field.title }-${ ATTRIBUTES.activityFeedHistoriesSettings }` }
                >
                    <Checkbox
                        label={ field.title }
                        onChange={ this.handleChangeHistoryField(field.title) }
                        value={ field.checked ? 1 : 0 }
                        className={ {
                            FieldWrapper: getClassNameAF(isNotStylized, styles.SettingsMenuItemCheckbox, classes?.SettingsMenuItemCheckbox),
                            Input: getClassNameAF(isNotStylized, styles.SettingsMenuItemInput, classes?.SettingsMenuItemInput),
                            Label: getClassNameAF(isNotStylized, styles.SettingsMenuItemLabel, classes?.SettingsMenuItemLabel),
                        } }
                    />
                </div>
            );
        });
        const isAllSelected = _.filter(filteredHistoryFields, item => item.checked).length === filteredHistoryFields.length;

        return (
            <div data-test={ ATTRIBUTES.activityFeedHistoriesSettings }>
                <div className={ getClassNameAF(isNotStylized, styles.SettingsSearchContainer, classes?.SettingsSearchContainer) }>
                    <div className={ `${ getClassNameAF(isNotStylized, styles.SettingsSmallInputContainer, classes?.SettingsSmallInputContainer) } ${ this.isFocused ? getClassNameAF(isNotStylized, styles.SettingsSmallInputContainerFocus, classes?.SettingsSmallInputContainerFocus) : '' }` }>
                        <input
                            className={ getClassNameAF(isNotStylized, styles.SettingsSmallInput, classes?.SettingsSmallInput) }
                            value={ this.term }
                            onChange={ this.handleValueChange }
                            placeholder={ multi_select_titles?.enter_a_value }
                            ref={ this.refFilter }
                            onFocus={ () => {
                                this.isFocused = true;
                            } }
                            onBlur={ () => {
                                this.isFocused = false;
                            } }
                            data-test={ ATTRIBUTES.activityFeedHistorySettingsSearch }
                        />
                        {/*иконка и сама кнопка специально разнесены на разные элементы
                        для того чтобы корректно отрабатывал метод onDocumentClick при очистке фильтра*/ }
                        <div className={ `${ getClassNameAF(isNotStylized, styles.SettingsSmallInputIcon, classes?.SettingsSmallInputIcon) } ${ this.isFilterIconHovered && this.term ? getClassNameAF(isNotStylized, styles.SettingsSmallInputIconHover, classes?.SettingsSmallInputIconHover) : '' }` }
                             dangerouslySetInnerHTML={ { __html: !this.term ? IconSearch : IconClose } }
                        />
                        <div className={ `${ getClassNameAF(isNotStylized, styles.SettingsSmallInputClean, classes?.SettingsSmallInputClean) } ${ this.term ? getClassNameAF(isNotStylized, styles.SettingsSmallInputCleanActive, classes?.SettingsSmallInputCleanActive) : '' }` }
                             onClick={ this.onIconClick }
                             onMouseEnter={ () => {
                                 this.isFilterIconHovered = true;
                             } }
                             onMouseLeave={ () => {
                                 this.isFilterIconHovered = false;
                             } }
                        />
                    </div>
                    <Checkbox
                        label={ dictionary && dictionary.select_all }
                        onChange={ this.handleSelectAllHistoryField }
                        value={ isAllSelected ? 1 : 0 }
                        className={ {
                            FieldWrapper: getClassNameAF(isNotStylized, styles.SettingsSelectAll, classes?.SettingsSelectAll),
                            Input: getClassNameAF(isNotStylized, styles.SettingsMenuItemInput, classes?.SettingsMenuItemInput),
                            Label: getClassNameAF(isNotStylized, styles.SettingsMenuItemLabel, classes?.SettingsMenuItemLabel),
                        } }
                    />
                </div>

                <div className={ getClassNameAF(isNotStylized, styles.SettingsScroll, classes?.SettingsScroll) }>
                    {
                        items.filter((val) => val !== null).length > 0 ?
                            items
                            :
                            <div className={ getClassNameAF(isNotStylized, styles.SettingsNoResults, classes?.SettingsNoResults) }>
                                { multi_select_titles?.no_results }
                            </div>
                    }
                </div>
            </div>
        );
    };

    checkRightEdge = () => {
        const { isShowSettings, isModal, refParent } = this.props;
        if (!isShowSettings || isModal) {
            return;
        }

        const settingsEl = this.refSettings && this.refSettings.current ? this.refSettings.current : null;
        const parentEl = refParent && refParent.current ? refParent.current : null;
        if (!settingsEl || !parentEl) {
            return;
        }
        const offset = 8;
        const parentRect = parentEl.getBoundingClientRect();
        const windowWidth = document.documentElement.clientWidth;
        const settingsRect = settingsEl.getBoundingClientRect();
        const rightEdge = settingsRect.width + settingsRect.left + offset;
        if (rightEdge > windowWidth) {
            let left = settingsRect.width - parentRect.width;
            if (left > settingsRect.left) {
                left = left - (left - settingsRect.left);
                if (settingsRect.width > windowWidth) {
                    left = left - offset;
                    settingsEl.style.width = `${ windowWidth - offset * 2 }px`;
                }
            }
            settingsEl.style.left = `-${ left }px`;
        }
    };

    render() {
        const { isShowSettings, isModal, activityState, dictionary, isNotStylized, classes } = this.props;
        if (!isShowSettings) {
            return null;
        }
        const hasHistory = !_.isEmpty(getFilteredHistoryFields(activityState))
            && _.find(activityState.getActiveMenuItems(), menuItem => menuItem.name === 'history');
        const historyFieldsTitle = dictionary && dictionary.settings ? dictionary.settings.history_fields_title : '';
        const applyTitle = dictionary && dictionary.form && dictionary.form.apply;
        const changeSettingsHtml = hasHistory ? (
            <div className={ getClassNameAF(isNotStylized, styles.SettingsConfigure, classes?.SettingsConfigure) }>
                <Button
                    onClick={ this.handleChangeSettings }
                    data-test={ ATTRIBUTES.activityFeedChangeSettings }
                    buttonType={ this.isHistoryFields ? 'default' : 'expand' }
                    className={ classes?.SettingsChangeButton }
                >
                    { this.isHistoryFields ? applyTitle : historyFieldsTitle }
                </Button>
            </div>
        ) : null;

        return (
            <div
                className={ `${ getClassNameAF(isNotStylized, styles.SettingsMenu, classes?.SettingsMenu) } ${ isModal ? getClassNameAF(isNotStylized, styles.SettingsMenuModal, classes?.SettingsMenuModal) : '' }` }
                data-test={ ATTRIBUTES.activityFeedMenuSettings }
                ref={ this.refSettings }
            >

                { this.renderActivityTypeMenu() }
                { this.renderHistoryFields() }

                { changeSettingsHtml }
            </div>
        );
    }
}
