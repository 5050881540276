/**
 * Описание: Поле для ввода reference полей (таблица workflow)
 *
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import styles from './styles.module.scss';
import IconSearch from 'assets/img/icons/search.svg';
import AutoSuggest from 'components/workflow/autoSuggest';
import sysDBTables from 'globalState/sysDBTables';
import InfoMessagesState from "globalState/infoMessages";

const TABLE_NAME_SYS_DB_TABLE = 'sys_db_table';

@observer
export default class WorkflowReference extends React.Component {
    @observable isEditing;
    @observable isHover = false;
    @observable term;
    @observable value = { database_value: null, display_value: null };
    dictionaryWindow = null;

    sysDbTableId='';

    constructor(props) {
        super(props);
        sysDBTables.fetchSysDbTable()
            .then(() => {
                this.sysDbTableId = sysDBTables.getByName(TABLE_NAME_SYS_DB_TABLE);
            })
            .catch(this.fetchError);
    }

    fetchError = (error) => {
        InfoMessagesState.pushError(error.message);
    };

    handleOnFocus = () => {
        this.isEditing = true;
    };

    handleOnFocusOut = () => {
        this.isEditing = false;
    };

    handleOnChange = (evt) => {
        this.term = evt.target.value;
        this.props.setValue(null, null);
        this.value = { database_value: null, display_value: null };
    };

    handleMouseOver = () => {
        this.isHover = true;
    };

    handleMouseLeave = () => {
        this.isHover = false;
    };

    @action
    updateData = (record) => {
        this.term = '';
        this.value = {database_value: record.database_value, display_value: record.display_value };
        this.props.setValue(record.database_value, record.display_value);
    };

    openDictionary = async () => {
        const width = window.screen.width * .7;
        const height = window.screen.height * .7;
        const top = 0;
        const left = 0;
        window.addEventListener('message', this.onMessage);

        const url = new URL(`/dictionary/sys_db_table`, window.location.origin);

        this.dictionaryWindow = window.open(
            url.pathname + url.search,
            'Dictionary',
            `width=${ width },height=${ height },top=${ top },left=${ left }`,
        );

        if (this.dictionaryWindow) {
            this.dictionaryWindow.onblur = () => {
                this.dictionaryWindow.close();
            };
            this.dictionaryWindow.onbeforeunload = this.onCloseDictionaryWindow;
        }

        return this.dictionaryWindow;
    };

    onCloseDictionaryWindow = () => {
        window.removeEventListener('message', this.onMessage);
    };

    onMessage = (e) => {
        this.updateData(e.data);

        if (this.dictionaryWindow) {
            this.dictionaryWindow.close();
        }
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render(){
        const name = this.props.isMandatory ? '* ' +this.props.label : this.props.label;
        let placeholder, label;
        if (this.isEditing || this.value && this.value.database_value || this.term) {
            placeholder = '';
            label = name;
        } else {
            placeholder = name;
            label = '';
        }
        return (
            <div className={ styles.Control } onMouseOver={ this.handleMouseOver } onMouseLeave={ this.handleMouseLeave }>
                <div className={ styles.Label }>{ label }</div>
                <div className={ styles.StringInput }>
                    <input placeholder={ placeholder }
                           className={ `${styles.Input} ${ (this.isHover || this.isEditing) && styles.InputActive }` }
                           onFocus={ this.handleOnFocus }
                           onBlur={ this.handleOnFocusOut }
                           onChange={ this.handleOnChange }
                           value={ this.term || this.value.display_value || '' }
                           type='text'/>
                    <div className={ styles.IconSearch }
                         dangerouslySetInnerHTML={ { __html: IconSearch } }
                         onClick={ this.openDictionary } />
                    { this.term && <AutoSuggest
                        reference_id={ this.sysDbTableId.sys_id }
                        term={ this.term }
                        ref="autosuggest"
                        updateData={ this.updateData }
                     /> }
                </div>
                <div className={ styles.Line } />
            </div>
        );
    }
}
