import * as React from 'react';
import moment from 'moment-timezone';

import langStore from 'globalState/lang';

import {convertServerTimeToISO, getDateValueByString, getTimeZoneDate, getUserTimezone} from 'helpers/getUserTimeZone';
import {getDaysOfWeek, getFormatFromPHP} from 'helpers/date';
import DurationMsConverter from 'helpers/DurationMsConverter';
import {DEFAULT_TIME_FORMAT} from 'constants/dateTime';
import {fetchListGroupsFilter} from 'actions/list';
import GroupDataState from 'globalState/table/groupData';
import { getLinkDisplayValue } from 'helpers/form';


export const isNotSet = (value) => {
    return value === null ||
        value === undefined ||
        value === '' ||
        value.length === 0;
};

export const filterArrayForList = (values) => {
    /* хак для разделения запятыми */
    return values
        .map(item => {
            return getLinkDisplayValue(item, true);
        }).join(', ');
};

export const addWhitespaces = (value) => {
    let result;
    const string = value.toString();
    const addSpaces = function (str) {
        return str.replace(/\B(?=(\d{3})+(?!\d))/g, "&nbsp;");
    };

    if (string.indexOf('.') !== -1) {
        const stringArr = string.split('.');
        stringArr[0] = addSpaces(stringArr[0]);
        stringArr[1] = addSpaces(stringArr[1]);
        result = stringArr.join('.');
    } else {
        result = addSpaces(string);
    }

    return <span dangerouslySetInnerHTML={{__html: result}}/>;
};

/**
 * Функия, которая создает строку для conditions
 * @param value - значение поля
 * @param columnType - тип поля
 * @param validate - Валидация для поля
 * @returns {*}
 */
export const getCellValue = (value, columnType, validate = {}) => {
    const { bool, template_titles } = langStore.getTranslate();

    switch (columnType) {
        case 'list':
            if (Array.isArray(value) && value.length) {
                return (
                    <ul key="list">
                        {
                            filterArrayForList(value)
                        }
                    </ul>
                );
            }
            return null;

        case 'datetime':
            if (!isNotSet(value)) {
                const format = getFormatFromPHP(validate.format);
                return getDateValueByString(value, getUserTimezone(), format);
            }
            return null;

        case 'datetime_specific':
            if (!isNotSet(value)) {
                const format = getFormatFromPHP(validate.format);
                return getDateValueByString(value, 'UTC', format);
            }
            return null;

        case 'date':
            if (!isNotSet(value)) {
                const format = getFormatFromPHP(validate.format);
                return getDateValueByString(value, 'UTC', format);
            }
            return null;

        case 'time':
            if (!isNotSet(value)) {
                const time = convertServerTimeToISO(value);
                return getTimeZoneDate(time).format(DEFAULT_TIME_FORMAT);
            }
            return null;

        case 'boolean':
            if (!isNotSet(value)) {
                if (value.toString() === '1') {
                    return bool && bool.yes;
                } else {
                    return bool && bool.no;
                }
            }
            return null;

        case 'password ':
        case 'encrypted_password':
            return '********';

        case 'duration':
            if (!isNotSet(value)) {
                const duration = DurationMsConverter.msToString(value);
                return duration === '' ? null : duration;
            }
            return null;

        case 'days_of_week':
            if (!isNotSet(value)) {
                if (Array.isArray(value)) return value;

                const numbersOfDay = getDaysOfWeek(value);
                const days = numbersOfDay.map((day) => (`${moment.weekdaysShort(true)[day]}`));
                return days.join(', ');
            }
            return null;

        case 'id':
            if (!isNotSet(value?.record_title)) {
                return value?.record_title;
            }
            return null;

        case 'html':
            if (!isNotSet(value)) {
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = value.replace(/<table .*>.*<\/table>/gi, '');
                return tempDiv.innerText;
            }
            return null;

        case 'image':
            if (!isNotSet(value)) {
                return value.file_name;
            }
            return null;

        case 'reference':
        case 'field_name':
        case 'record_class':
        case 'choice':
            if (value && !isNotSet(value.display_value)) {
                return value.display_value;
            }
            return null;

        case 'template':
            if (!isNotSet(value)) {
                return template_titles.filled;
            }
            return null;

        case 'float':
        case 'decimal':
        case 'integer':
        case 'biginteger':
            if (!isNotSet(value)) {
                return addWhitespaces(value);
            }
            return null;

        default:
            if (!isNotSet(value)) {
                return value;
            }
            return null;
    }
};

export const changeToGroupTable = async (tableState, columnName) => {
    tableState.setIsShowGroups(true);
    tableState.setIsShowListGroups(false);
    const parsedSearchParams = {
        condition: `^GROUPBY${columnName}`,
    };
    const response = await fetchListGroupsFilter(tableState.getName(), parsedSearchParams);
    if (response.isOkStatus) {
        GroupDataState.setData(response.data);
    }
};
