import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/dynamicForms/view/form/unavailableField/styles.module.scss';
import IconEyeOff from 'assets/img/icons/eye-off.svg';

import langStore from 'globalState/lang';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import BaseFormFieldModel from 'components/dynamicForms/model/field/BaseFormFieldModel';

/***
 * Описание: Поле недоступное для просмотра
 * Параметры:
 * label - заголовок поля
 * isMandatory - значёк обязательности поля
 *
 */

@observer
export default class UnavailableField extends React.Component {
    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        }
        else {
            this.model = new BaseFormFieldModel(props);
        }
    }

    render() {
        const { acl_blocked_titles = {} } = langStore.getTranslate();
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ styles.Text }
                    data-test-visible={ this.model.isVisible }
                    data-test-content-hidden="true"
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                    { acl_blocked_titles.unavailable_data }
                </div>
            </FieldWrapper>
        );
    }
}
