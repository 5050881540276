import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/portalDesigner/styles.module.scss';
import IconPlus from 'assets/img/icons/plus.svg';

import Button from 'components/button';
import DragAndDropState from 'components/portalDesigner/components/dragAndDrop/state';
import PageData from 'components/portalDesigner/state/pageData';

import BaseElement from 'components/portalDesigner/components/base';
import Row from 'components/portalDesigner/components/row';
import { observable } from 'mobx';
import GlobalPortal from 'components/globalPortal';
import LayoutsStorage from 'components/portalDesigner/storage/layouts';
// import DragAndDrop from 'components/portalDesigner/components/dragAndDrop';

/**
 * компонент контейнера дизайнера страниц портала
 *
 * @props id {string}                           sys_id сущности
 * @props index {string}                        sys_id сущности
 * @props breadcrumbsIds [string]               хлебные крошки
 * @props model {ContainerPortalDesignerModel}  модель
 */
@observer
export default class Container extends React.Component {
    @observable added = false;
    layoutsPopupRef = React.createRef();
    addButtonRef = React.createRef();
    /**
     * @type null|{left: number, top: number}
     */
    @observable layoutsPopupPosition = null;

    node = React.createRef();
    breadcrumbsIds = [];

    constructor(props) {
        super(props);

        this.breadcrumbsIds = [
            {
                id: props.id,
                model: props.model,
                // @todo translate
                name: 'Container',
                type: 'container',
            },
        ];
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.addButtonRef.current) {
            const inButton = this.addButtonRef.current && this.addButtonRef.current.contains(e.target);
            const inPopup = this.layoutsPopupRef.current && this.layoutsPopupRef.current.contains(e.target);
            if (!inPopup && !inButton) {
                this.layoutsPopupPosition = null;
            }
        }
    };

    handleDragEnter = (e) => {
        // const nodeRect = e.currentTarget.getBoundingClientRect();
        // const pos = e.nativeEvent.screenY - nodeRect.top;

        e.preventDefault();
        /**
         * @type {ContainerPortalDesignerModel}
         */
        const containerModel = this.props.model;
        if (containerModel.isTemp) {
            return;
        }

        switch (DragAndDropState.type) {
            case PageData.TYPE_CONTAINER:
                PageData.setTempContainer(containerModel);
                break;

            case PageData.TYPE_LAYOUT:
                const { rows } = this.props.model;
                // для корректной работы onDrop, если в контейнере ещё нет ни одной строки
                // нужно очищать при перетаскивание layout
                this.added = rows.items.length === 0;

                PageData.setTempRow(containerModel);
                break;
        }
    };

    handleDragOver = (e) => {
        e.preventDefault();
    };

    handleDragLeave = (e) => {
        e.preventDefault();
        this.added = false;
        this.props.model.rows.clearTemp();
    };

    handleDrop = (e) => {
        e.preventDefault();
        if (DragAndDropState.type === PageData.TYPE_CONTAINER) {
            if (!PageData.tempContainer) {
                return;
            }
            PageData.fetchSave(PageData.tempContainer, PageData.currentPageSysId, DragAndDropState.sysId, DragAndDropState.isNew)
                    .catch(console.error);
        }

        if (DragAndDropState.type === PageData.TYPE_LAYOUT) {
            if (!PageData.tempRow) {
                return;
            }
            PageData.fetchSave(PageData.tempRow, this.props.id, DragAndDropState.sysId, DragAndDropState.isNew)
                    .catch(console.error);
            this.added = false;
        }

        DragAndDropState.clean();
        e.target.style.opacity = '1';
    };

    /**
     * @type MouseEvent
     */
    showPopup = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const left = rect.left + (rect.width / 2);
        const top = rect.top + rect.height;
        this.layoutsPopupPosition = {
            left,
            top,
        };
    };

    /**
     * @type MouseEvent
     */
    showPopup = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const left = rect.left + (rect.width / 2);
        const top = rect.top + rect.height;
        this.layoutsPopupPosition = {
            left,
            top,
        };
    };

    renderEmpty() {
        return <div className={ styles.InnerEmptyContainer }>
            <div className={ styles.InnerEmptyContainerText }>
                {/*@todo translate*/ }
                Drag and drop a set of columns from the Left pane or use the plus [+] button - then drag and drop widgets inside
            </div>
            <Button buttonType="icon-border"
                    svg={ IconPlus }
                    onClick={ this.showPopup }
                    ref={ this.addButtonRef } />
        </div>;
    }

    renderRows() {
        const { rows } = this.props.model;
        return rows.items.map((model) => <Row breadcrumbsIds={ this.breadcrumbsIds }
                                              model={ model }
                                              key={ model.id }
                                              id={ model.id }
                                              columns={ model.columns } />);
    }

     addLayout = (sysId) => async () => {
        this.layoutsPopupPosition = null;
        PageData.setTempRow(this.props.model);
        return PageData.fetchSave(PageData.tempRow, this.props.id, sysId);
    }

    renderLayouts() {
        const data = LayoutsStorage.get();
        const style = _.clone(this.layoutsPopupPosition);
        return <GlobalPortal>
            <div className={ styles.LayoutsPopup } style={ style } ref={ this.layoutsPopupRef }>
                {
                    data.map((layout, index) => {
                        return <div key={ index }
                                    onClick={ this.addLayout(layout.id) }
                                    className={ styles.LayoutsItem }>
                            {
                                layout.columns.length ? _.map(layout.columns, (col, index) => (
                                        <span key={ index }
                                              className={ `${ styles.LayoutsItemsBlock } ${ styles['LayoutsItemsBlock-' + col[PageData.DEFAULT_SIZE]] }` }>
                                        { col[PageData.DEFAULT_SIZE] }
                                    </span>
                                    ),
                                ) : <span>There is no layouts</span>
                            }
                        </div>;
                    })
                }
            </div>
        </GlobalPortal>;
    }

    render() {
        let classes = [ styles.Container ];
        if (this.props.model.isTemp) {
            classes.push(styles.ContainerTemp);
        }
        if (this.props.model.cssClassNames) {
            classes.push(this.props.model.cssClassNames);
        }

        const { items } = this.props.model.rows;
        return <BaseElement className={ classes.join(' ') }
                            type="container"
                            key={ this.props.id }
                            id={ this.props.id }
                            index={ this.props.index }
                            model={ this.props.model }
                            handleDrop={ this.handleDrop }
                            handleDragEnter={ this.handleDragEnter }
                            handleDragOver={ this.handleDragOver }
                            handleDragLeave={ this.handleDragLeave }
                            breadcrumbsIds={ this.breadcrumbsIds }>

            {/* @fixme отладочная инфа, убрать перед релизом */ }
            {/*{ <div className={ styles.ContainerInfo }>{ this.props.id }</div> }*/ }

            {
                !this.added && (
                    this.props.model.isLoaded ?
                        <div className={ styles.ContainerIsLoaded } />
                        : (
                            items.length ? this.renderRows() : this.renderEmpty()
                        )
                )
            }
            { this.layoutsPopupPosition && this.renderLayouts() }
        </BaseElement>;
    }
}
