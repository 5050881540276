import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Dropdown from 'components/dropdown';
import styles from './styles.module.scss';
import _ from 'lodash';
import { ATTRIBUTES } from 'constants/attributesForTests';
import langStore from "globalState/lang";
import { updateMenuScrollTop } from 'helpers/html';
import IconChevron from 'assets/img/icons/chevron-down.svg';

/***
 * Описание: Кастомный селект
 * Праметры:
 * databaseValue: {required: true, type: string} - выбранное значение
 * disabled: {required: false, type: boolean} - readOnly
 * options: {required: true, type: array} - массив доступных значений(database_value/display_value)
 * onChange: {required: true, type: function} - метод вызываемый при изменении выбранного значения(принимает database_value)
 * placeholder: {required: false, type: string}
 */

@observer
export default class SelectComponent extends React.Component {
    @observable isOpened = false;
    @observable isFocused = false;
    @observable value = '';
    @observable current = '';
    refDropdown = React.createRef();
    refInput = React.createRef();
    refMenu = React.createRef();
    refActiveItem = React.createRef();
    isKeyboardOn = false;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, true);
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('click', this.onDocumentClick);
        document.addEventListener('mousemove', this.onMouseMove);

        this.setValues();
    }

    componentDidUpdate(prevProps) {
        const { databaseValue, options } = this.props;
        if (databaseValue !== prevProps.databaseValue || !_.isEqual(prevProps.options, options)) {
            this.setValues();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, true);
        document.removeEventListener('click', this.onDocumentClick);
        document.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('mousemove', this.onMouseMove);
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    get isEmptyData() {
        const { options, databaseValue } = this.props;
        return !options || ((_.isNil(databaseValue) || databaseValue === '' ) && options.length === 0);
    }

    setValues() {
        if (this.isEmptyData) {
            this.value = '';
            return;
        }

        const { databaseValue, options } = this.props;

        if (options.length) {
            const find = options.find((option) => option.database_value == databaseValue);

            if (find && options.length) {
                this.value = find.display_value;
            }
            else {
                this.value = '';
            }
        }

        this.current = !_.isNil(databaseValue) ? databaseValue : options.length ? options[0].database_value : '';
    }

    renderMenu() {
        const items = this.props.options.map((option, index) => {
            const databaseValueToString = option.database_value ? option.database_value.toString() : option.database_value;
            const className = `${ styles.menuItem } ${ option.database_value === this.current ? styles.active : '' } ${ databaseValueToString === this.props.databaseValue ? styles.selected : '' }`;
                return (
                    <div
                        className={ className }
                        key={ option.database_value + index.toString() }
                        onClick={ () => this.onItemClick(option) }
                        onMouseEnter={ () => this.onItemHover(option.database_value) }
                        data-test={ ATTRIBUTES.customSelectDropdownItem }
                        ref={ option.database_value === this.current ? this.refActiveItem : null }
                    >
                        { option.values ? option.values.map((value, index, values) => {
                                return (
                                    <span
                                        key={ index }
                                        title={ value.hint }
                                    >
                                            { value.display_value }{ values.length - 1 !== index ? ' / ' : '' }
                                        </span>
                                );
                            },
                        ) : option.display_value }
                    </div>
                );
        });

        return <div className={ styles.menu } ref={ this.refMenu }>{ items }</div>;
    }

    onItemClick = (option) => {
        this.value = option.display_value;
        this.current = option.database_value;
        this.isOpened = false;
        if (this.props.onChange) {
            this.props.onChange(option);
        }
    };

    onItemHover = (database_value) => {
        if (this.isKeyboardOn) return;
        this.current = database_value;
    };

    onInputClick = () => {
        if (!this.isOpened && this.props.options.length && this.value) {
            const tempCurrent = this.props.options.find((option) => option.display_value === this.value);
            this.current = tempCurrent && tempCurrent.database_value;
        }
        this.isOpened = !this.isOpened;
    };

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const inputEl = this.refInput.current;
        if (!dropdownEl || !inputEl) return false;

        if (!dropdownEl.contains(e.target) && !inputEl.contains(e.target)) {
            this.isOpened = false;
        }
    };

    onScroll = (evt) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        if (!dropdownEl) return false;

        if (!dropdownEl.contains(evt.target)) {
            this.isOpened = false;
        }
    }

    onKeyDown = (e) => {
        if (this.isFocused && this.isOpened) {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                e.preventDefault();
                let nextOptionIndex = 0;
                for (let i = 0, options = this.props.options; i < options.length; i++) {
                    if (this.current === undefined) {
                        nextOptionIndex = 0;
                        break;
                    }
                    if (options[i].database_value === this.current) {
                        nextOptionIndex = (e.keyCode === 38) ? i - 1 : i + 1;
                        if (nextOptionIndex < 0) {
                            nextOptionIndex = options.length - 1;
                        }
                        else if (nextOptionIndex > options.length - 1) {
                            nextOptionIndex = 0;
                        }
                        break;
                    }
                }
                this.current = this.props.options[nextOptionIndex].database_value;
                this.isKeyboardOn = true;
                updateMenuScrollTop(this.refMenu.current, this.refActiveItem.current);
            }

            if (e.key === 'Enter') {
                const option = this.props.options.find((option) => option.database_value === this.current);
                this.value = option.display_value;
                if (this.props.onChange) {
                    this.props.onChange(option);
                }
            }

            // esc
            if (e.key === 'Escape') {
                this.isOpened = false;
            }
        }
    };

    onMouseMove = () => {
        this.isKeyboardOn = false;
    };

    render() {
        const { placeholder } = this.props;
        let value = this.value;
        let classes = [styles.input];
        const { choice_titles } = langStore.getTranslate();

        if (this.props.isWarning) {
            classes.push(styles.IsWarning);
        }
        if (!value) {
            classes.push(styles.placeholder);
            value = placeholder ? placeholder : choice_titles ? choice_titles.placeholder : '';
        }

        return (
            <div className={ `${ styles.container || '' } ${ this.props.className || '' }` } >
                <button
                    type='button'
                    className={ classes.join(' ') }
                    disabled={ this.isEmptyData ? this.isEmptyData : this.props.disabled }
                    onClick={ this.onInputClick }
                    onFocus={ () => this.isFocused = true }
                    onBlur={ () => this.isFocused = false }
                    ref={ this.refInput }
                    data-test={ this.props['data-test'] || ATTRIBUTES.customSelectButton }
                >
                    <span className={ styles.inputText }>{ value }</span>
                    <span className={ styles.inputChevron } dangerouslySetInnerHTML={{ __html: IconChevron }}/>
                </button>
                { this.isOpened &&
                <Dropdown refParent={ this.refInput } ref={ this.refDropdown } data-test={ ATTRIBUTES.customSelectDropdownMenu }>
                    { this.renderMenu() }
                </Dropdown>
                }
            </div>
        );
    }
}
