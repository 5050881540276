/**
 * Описание: Верхняя панель редактора рабочих процессов
 * Для переключения вкладок процессов, вызова бургер меню, вызова logout пользователя
 * Props:
 * isViewer - режим workflow viewer
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import langStore from "globalState/lang";
import styles from './styles.module.scss';
import IconVector from 'assets/img/icons/home.svg';
import IconX from 'assets/img/icons/close-x.svg';
import IconSettings from 'assets/img/icons/settings.svg';
import IconUserAva from 'assets/img/user-ava.svg';
import IconChevronDown from 'assets/img/chevron-down-sm.svg';
import { Link } from 'react-router-dom';
import userState from 'globalState/user';
import listboxState from 'globalState/listboxState';

@observer
export default class HeaderPanel extends React.Component {

    @observable isShowMenu = false;

    menuRef = React.createRef();
    userMenuIconRef = React.createRef();
    userNameRef = React.createRef();

    constructor(props) {
        super(props);
    }

    @action
    handleMenu = (e) => {
        e.preventDefault();
        this.isShowMenu = !this.isShowMenu;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    @action
    handleClickOutside = (e) => {
        const { current: menu } = this.menuRef;
        const { current: userMenuIcon } = this.userMenuIconRef;
        const { current: userName } = this.userNameRef;

        if (!menu) return false;
        if (!menu.contains(e.target) && !userMenuIcon.contains(e.target) && !userName.contains(e.target)) {
            this.isShowMenu = false;
        }
    };

    renderMenu = () => {
        const { user } = userState;
        const { user_menu_titles } = langStore.getTranslate();
        return (
            <ul className={ styles.Menu } ref={ this.menuRef }>
                <li>
                    <Link className={ styles.Link } to={ "/record/user/" + user.sys_id }>{ user_menu_titles && user_menu_titles.profile }</Link>
                </li>
                <li onClick={ () => userState.logout() }>
                    <span>{ user_menu_titles && user_menu_titles.logout }</span>
                </li>
            </ul>
        );
    };

    getTabStyle = (state, isCurrent) => {
        switch (state){
            case 'executing':
                return `${ styles.Tab } ${ styles.TabRunning }`;
            case 'finished':
                return `${ styles.Tab } ${ styles.TabCompleted }`;
            case 'error':
                return `${ styles.Tab } ${ styles.TabError }`;
            case 'cancelled':
                return `${ styles.Tab } ${ styles.TabCancelled }`;
            default:
                return `${ styles.Tab } ${ isCurrent ? styles.TabCurrent : '' }`;
        }
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        const { user } = userState;
        const current_workflow = !!this.props.workflows && (this.props.workflows.length > 0) && this.props.workflows.find((elem) => (elem && elem.sys_id === this.props.currentWorkflow));
        const titles = !!this.props.workflows && this.props.workflows.map((elem) => {
            const isCurrent = !!current_workflow && (current_workflow.sys_id  === elem.sys_id);
            return elem ?
                <div key={ elem.sys_id } className={ this.getTabStyle(elem.state, isCurrent) }>
                    <div className={` ${styles.TabName} ${isCurrent && styles.TabNameCurrent }`}
                         onClick={ () => { this.props.loadWorkflow(elem.sys_id) } }>
                        { elem.name }
                    </div>
                    { !this.props.isViewer &&
                        <div className={ styles.IconX } onClick={ () => { this.props.closeWorkflow(elem.sys_id) } } dangerouslySetInnerHTML={ { __html: IconX } } />
                    }
                </div> : '';
        });

        return (
            <div className={ styles.HeaderPanel }>
                <div className={ styles.LeftPanel }>
                    <Link to={ "/" }>
                        <div className={ styles.Home } dangerouslySetInnerHTML={ { __html: IconVector } } />
                    </Link>
                    { titles }
                </div>
                <div className={ styles.UserPanel }>
                    <div className={ styles.UserIcon } dangerouslySetInnerHTML={ { __html: IconUserAva } } />
                    <div className={ styles.Username } onClick={ this.handleMenu } ref={ this.userNameRef }>{ user.username }</div>
                    <div className={ styles.UserMenuIcon } dangerouslySetInnerHTML={ { __html: IconChevronDown } } ref={ this.userMenuIconRef } onClick={ this.handleMenu } />
                    <div
                        className={ styles.Icon }
                        dangerouslySetInnerHTML={ { __html: IconSettings } }
                        onClick={ () => {
                            listboxState.toggleOpenedState();
                        } }
                    />
                    { this.isShowMenu && this.renderMenu() }
                </div>
            </div>
        );
    }
}
