import { observable } from 'mobx';
import { UiActionStoreType } from 'types/uiActions/index';

class UiActionStore implements UiActionStoreType {
    @observable private _waitServerResponse = false;

    getWaitServerResponse(): boolean {
        return this._waitServerResponse;
    }

    setWaitServerResponse(value: boolean) {
        this._waitServerResponse = value;
    }
}

export default new UiActionStore();
