import * as React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, reaction } from 'mobx';
import { numberFormat } from 'helpers/misc';
import { EnhancedComponent } from 'components/EnhancedComponent';
import User from 'globalState/user';
import langStore from 'globalState/lang';
import { isEqual } from 'lodash';
import { getDateValueByString, getUserTimezone, getUserDateTimeFormat } from 'helpers/getUserTimeZone';
import apiRequest, { API_BASE_URL } from 'lib/apiRequest';
import IconX from 'assets/img/icons/close-x.svg';
import FileMenuIcon from 'assets/img/icons/more-horizontal-kebab.svg';
import FileIconDocs from 'assets/img/icons/file-text.svg';
import FileIconImages from 'assets/img/icons/image.svg';
import FileIconOthers from 'assets/img/icons/file-default.svg';
import IconCheck from 'assets/img/checkbox.svg';
import FileMenuChooseIcon from 'assets/img/icons/check-square.svg';
import FileMenuShowIcon from 'assets/img/icons/size-maximize-2.svg';
import FileMenuDeleteIcon from 'assets/img/icons/trash.svg';
import IconDownload from 'assets/img/icons/download.svg';
import IconUpload from 'assets/img/icons/upload.svg';
import Button from 'components/button';
import newRecordAttachments from 'globalState/newRecordAttachments';
import GlobalPortal from 'components/globalPortal';
import Heading from 'components/heading';
import styles from './styles.module.scss';
import Preview from './preview';
import { ATTRIBUTES } from 'constants/attributesForTests';

function cancelDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
}


/**
 * Описание: компонент DragAndDrop
 * используется для зазгрузки и удаления файлов
 *
 * attachments: {required, type: array} - данные файлов
 * onDropFunc: {type: function} - коллбэк события дарг-эн-дропа
 * onPasteFunc: {type: function} - коллбэк события вставки
 * getUploadedFilesLink: {type: function} - коллбэк для получения uploadedFiles
 * getRef: {type: function} - получить ссылку на компонент
 * uploadFile: {type: function} - метод аплоада файлов
 * recordId: {type: string} - id записи
 * essence: {type: string} - имя таблицы
 * onAttachmentsLengthChange: {type: function} - коллбэк изменения кол-во загруженных файлов
 * onClose: {type: function} - событие закрытия
 * onUpload: {type: function} - колбэк когда мы загрузили файл
 * onRemoveFile: {type: function} - колбэк когда мы удаляем файл
 * multiple: {type: boolean} - флаг разрешения загрузки более одного файла
 *
 * isAclActive: {type: boolean} - флаг редактируемости поля
 * isVisible: {type: boolean} - флаг показа поля
 * is_mandatory: {type: boolean} - флаг обязательности
 * fieldMessage: {type: { message: string, type: string }} - сообщение
 * clientScripts: {type: array} - массив клиентских скриптов
 * className: {type: array} - кастомный класс стилей
 * column_id: {type: string} - id поля
 */

@observer
export default class DragAndDrop extends EnhancedComponent {
    @observable dropHover = false;
    @observable hide = false; // Hide when select files dialog and show again when we choose files
    @observable tallContent = false;
    @observable uploadedFiles = [];
    @observable showPreviewPopup = false;
    @observable fileMenuPosition = 'top';
    overlayRef = React.createRef();
    contentRef = React.createRef();
    fileMenuRef = React.createRef();
    multiple = true;
    @observable previewFileUrl = '';
    @observable previewFileName = '';
    @observable previewFileId = '';
    @observable downloadUrl = '';
    // Флаг нужен для того, чтоб форма закрывалась сразу после добавления файла
    @observable isCloseWhenAdd = false;
    timeout = null;

    @computed get selectedFiles() {
        return this.uploadedFiles.filter((file) => file.selected === true);
    }

    @computed get uploadInProgressFiles() {
        return this.uploadedFiles.filter(file => file.uploaded === false);
    }

    constructor(props) {
        super(props);

        newRecordAttachments.data.clear();

        if (this.props.attachments) {
            this.attachUpdate();
        }

        this.downloadUrl = this.props.downloadUrl || 'attachments';

        this.props.onDropFunc && this.props.onDropFunc(this.handleDrop);
        this.props.onPasteFunc && this.props.onPasteFunc(this.handlePaste);
        this.updateOutsideUploadFileLink();
        this.props.getRef && this.props.getRef(this);


        if (this.props.uploadFileAction) {
            this.uploadFileAction = this.props.uploadFileAction;
        }

        if (this.props.removeFileAction) {
            this.removeFileAction = this.props.removeFileAction;
        }

        if (this.props.multiple !== undefined) {
            this.multiple = this.props.multiple;
        }

        this.useEffect(() => {
            const handleClick = () => {
                this.closeAllFileMenu();
            };
            handleClick({});

            window.addEventListener('click', handleClick);

            return () => {
                window.removeEventListener('click', handleClick);
            };
        });

        this.useEffect(() => {
            this.checkTallContent();
        });

        this.useEffect((didMount, didUpdate, prevProps) => {
            if (didUpdate) {
                if (this.props.multiple !== undefined && this.props.multiple !== this.multiple) {
                    this.multiple = this.props.multiple;
                }
                if (this.props.attachments && !isEqual(prevProps.attachments, this.props.attachments)) {
                    this.attachUpdate();
                }
                this.updateBodyScrollState();
                this.checkTallContent();

                if (this.props.show && this.props.show !== prevProps.show) {
                    document.addEventListener('paste', this.handlePaste);
                } else if (prevProps.show && this.props.show !== prevProps.show) {
                    document.removeEventListener('paste', this.handlePaste);
                }
            }
        }, true);

        this.useEffect(() => {
            window.addEventListener('resize', this.onWindowResize);
            document.addEventListener('keydown', this.onDocumentKeyDown);
            if (this.props.show) {
                document.addEventListener('paste', this.handlePaste);
            }

            return () => {
                window.removeEventListener('resize', this.onWindowResize);
                document.removeEventListener('keydown', this.onDocumentKeyDown);
                document.removeEventListener('paste', this.handlePaste);
            };
        });

        // Update outside uploadedFiles link
        this.useEffect(() => {
            return reaction(
                () => [this.uploadedFiles],
                this.updateOutsideUploadFileLink,
                {
                    fireImmediately: true,
                });
        });

        this.useEffect(() => {
            if (this.fileMenuRef.current) {
                const rects = this.fileMenuRef.current.getClientRects();
                if (rects.length === 0) return;
                const y = rects[0].y;
                if (y < 0) {
                    this.fileMenuPosition = 'bottom';
                }
            }
            else {
                this.fileMenuPosition = 'top';
            }
        }, true);
    }

    onDocumentKeyDown = ({ key }) => {
        if (key === 'Escape' && !this.showPreviewPopup) {
            this.handleClose();
        }
    };

    onWindowResize = () => {
        this.checkTallContent();
    };

    updateBodyScrollState = () => {
        const { body } = document;

        if (this.props.show) {
            body.classList.add(styles.bodyFreeze);

            if (body.style.position === 'fixed') return;
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${ scrollY }px`;
        }
        else {
            const wasOpened = [...body.classList].includes(styles.bodyFreeze);
            body.classList.remove(styles.bodyFreeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';

            if (wasOpened) {
                window.scrollTo(0, parseInt(scrollY || '0') * -1);
            }
        }

    };

    showOrDownloadOnClick = (e, file) => {
        e.stopPropagation();
        const isImage = this.getTypeFile(file) === 'image';
        if (isImage) {
            this.openFile(e, file);
        }
        else {
            this.downloadFile(file);
        }
    };

    updateOutsideUploadFileLink = () => {
        this.props.getUploadedFilesLink && this.props.getUploadedFilesLink(this.uploadedFiles);
    };

    closeAllFileMenu = (exceptFile) => {
        this.uploadedFiles.forEach(file => {
            if (exceptFile && exceptFile === file) return false;
            file.menuActive = false;
        });
    };

    attachUpdate = () => {
        this.uploadedFiles = [];
        for (const attach of this.props.attachments) {
            const fileData = this.getFileData(attach);
            const arrayLength = this.uploadedFiles.push(fileData);

            const pushedItem = this.uploadedFiles[arrayLength - 1];
            pushedItem.index = arrayLength - 1;
        }
    };

    checkTallContent = () => {
        if (this.contentRef.current) {
            const contentHeight = this.contentRef.current.clientHeight;
            const windowInnerHeight = window.innerHeight;
            this.tallContent = (contentHeight > windowInnerHeight);
        }
    };

    handleDragEnter = (e) => {
        const {isAclActive} = this.props;
        if( isAclActive ) return;

        cancelDefaults(e);

        clearTimeout(this.timeout);
        this.dropHover = true;
    };

    handleDragLeave = (e) => {
        cancelDefaults(e);

        this.timeout = setTimeout(() => { this.dropHover = false },100);
    };

    handleDragOver = (e) => {
        const {isAclActive} = this.props;
        if(isAclActive) return;

        cancelDefaults(e);

        clearTimeout(this.timeout);
        this.dropHover = true;
    };

    uploadFileAction = async (fileData) => {
        const { isWaitForRequest } = this.props;
        const formData = new FormData();
        if (!isWaitForRequest) {
            formData.append('files[]', fileData.originalFileData);
        }
        else {
            formData.append('file', fileData.originalFileData);
        }

        fileData.errorText = '';
        fileData.error = false;
        fileData.progress = 0;
        fileData.uploadInProgress = true;
        fileData.uploaded = false;
        let url;
        if (this.props.attachUrl) {
            url = this.props.attachUrl;
        }
        else {
            url = `/attachments/upload/${ this.props.essence }`;
            if (this.props.recordId) url += `/${ this.props.recordId }`;
        }
        return await new apiRequest(`POST ` + url)
            .onUploadProgress((progressPercents) => {
                fileData.progress = progressPercents;
            })
            .send(formData);
    };

    /**
     * Описание: метод проверки статуса uploaded для загруженных файлов
     * return: { type: boolean }
     */
    filesUploaded = () => {
        return this.uploadedFiles.findIndex(file => file.uploaded === true);
    };

    /**
     * Описание: метод для получения массива загруженных файлов без ошибок
     * return: uploadedFiles { type: array }
     */
    uploadedFilesWithoutErrors = () => {
        return this.uploadedFiles.filter(file => file.uploaded === true);
    };

    /**
     * Описание: метод получения сообщения об ошибке
     * return: { type: string}
     */
    getErrorMessage = (error) => {
        if (error.response &&
            error.response.data &&
            error.response.data.errors &&
            Array.isArray(error.response.data.errors)
            && error.response.data.errors.length) {
            return error.response.data.errors[0].message;
        }

        return error.message;
    };

    uploadFile = async (fileData) => {
        let result;
        try {
            this.isCloseWhenAdd = this.props.isCloseWhenAdd;
            result = await this.uploadFileAction(fileData);

            const resp = result.getData()[0];
            // Add file to future attachments for new record
            if (resp && resp.sys_id) {
                newRecordAttachments.data.add(resp.sys_id);
            }
            if (this.props.onUpload) this.props.onUpload(resp);

            fileData.apiResponse = {
                ...fileData.apiResponse,
                ...resp,
            };

            fileData.uploaded = true;
            if (this.props.onAttachmentsLengthChange) {
                this.props.onAttachmentsLengthChange(this.uploadedFiles.length);
            }
        }
        catch (e) {
            fileData.errorText = this.getErrorMessage(e);
            fileData.error = true;
        }
        finally {
            fileData.uploadInProgress = false;
        }
    };

    handleDrop = (e) => {
        const {isAclActive} = this.props;
        if(isAclActive) return;
        cancelDefaults(e);

        this.dropHover = false;

        if (!this.canAddFile()) {
            return;
        }

        const dt = e.dataTransfer;
        const files = dt.files;

        this.processFiles(files);
    };

    handlePaste = (event) => {
        const {isAclActive} = this.props;
        if (isAclActive) return;

        const files = event.clipboardData.files;
        if (files) {
            this.processFiles(files);
        }
    }

    getFileData = (apiAttachmentData = {}, file = false) => {
        const fileData = {
            originalFileData: file,
            progress: 0,
            errorText: '',
            error: false,
            uploaded: true,
            uploadInProgress: false,
            index: 0,
            apiResponse: {
                sys_id: 0,
                file_name: '',
                delete_url: '',
                download_url: '',
                file_size: '',
            },
        };

        if (file) fileData.originalFileData = file;
        fileData.apiResponse = {
            ...fileData.apiResponse,
            ...apiAttachmentData,
        };

        return fileData;
    };

    processFiles = async (files) => {
        const { isWaitForRequest } = this.props;
        this.uploadedFiles = this.uploadedFilesWithoutErrors();
        for (const file of files) {

            if (file.size === 0) {
                continue;
            }

            const fileData = this.getFileData({}, file);

            const arrayLength = this.uploadedFiles.push(fileData);

            const pushedItem = this.uploadedFiles[arrayLength - 1];
            pushedItem.index = arrayLength - 1;

            if (isWaitForRequest) {
                await this.uploadFile(pushedItem)
                          .catch(console.error)
                          .finally(() => {
                              if (pushedItem.error) {
                                  this.uploadedFiles.pop();
                              }
                          });
            }
            else {
                this.uploadFile(pushedItem)
                    .catch(console.error)
                    .finally(() => {
                        if (pushedItem.error) {
                            this.uploadedFiles.pop();
                        }
                    });
            }
        }
    };

    handleDropZoneClick = () => {
        if (!this.canAddFile() || this.uploadInProgressFiles.length > 0) {
            return;
        }
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = this.multiple;
        fileInput.accept = this.downloadUrl === 'image' ? 'image/*' : undefined;

        const handleChange = (e) => {
            const files = e.currentTarget.files;
            this.processFiles(files);
            fileInput.removeEventListener('change', handleChange);
        };
        fileInput.addEventListener('change', handleChange);

        fileInput.click();
    };

    removeFileAction = async (sysId) => {
        try {
            await new apiRequest('POST /attachments/delete/' + sysId).send();
            return true;
        }
        catch (e) {
            return false;
        }
    };

    handleRemoveFile = async (e, file) => {
        e.stopPropagation();
        this.closeAllFileMenu();
        if (this.uploadInProgressFiles.length > 0)  return;

        if (this.props.onRemoveFile) this.props.onRemoveFile(file.apiResponse);

        const isFileRemoved = await this.removeFileAction(file.apiResponse.sys_id);

        if (isFileRemoved) {
            this.uploadedFiles = this.uploadedFiles.filter(f => {
                return file.apiResponse.sys_id !== f.apiResponse.sys_id;
            });

            // Delete attachment for future new record
            newRecordAttachments.data.delete(file.apiResponse.sys_id);

            // update attachments length
            if (this.props.onAttachmentsLengthChange) {
                this.props.onAttachmentsLengthChange(this.uploadedFiles.length);
            }
        }
    };

    getFileSize = (file) => {
        let fileSize = '';
        if (file.originalFileData) {
            fileSize = Math.round(file.originalFileData.size / 1024);
            fileSize = numberFormat(fileSize, 0, ',', ',');
        }
        else {
            if (file.apiResponse.file_size === '') {
                fileSize = Math.round(file.apiResponse.size_bytes / 1024);
                fileSize = numberFormat(fileSize, 0, ',', ',');
            }
            else {
                fileSize = file.apiResponse.file_size;
            }
        }

        return fileSize;
    };

    getFileName = (file) => {
        let fileName = '';
        if (file.apiResponse.file_name) {
            fileName = file.apiResponse.file_name;
        }
        else if (file.originalFileData.name) {
            fileName = file.originalFileData.name;
        }
        else if (file.filename) {
            fileName = file.filename;
        }

        return fileName;
    };

    getTypeFile = (file) => {
        const fileName = this.getFileName(file);
        const fileNameSplitted = fileName.split('.');
        const fileExt = fileNameSplitted.length > 1 ? fileNameSplitted[fileNameSplitted.length - 1].toLocaleLowerCase() : null;

        if ([
            'jpg',
            'jpeg',
            'png',
            'bmp',
            'webp',
        ].includes(fileExt)) {
            return 'image';
        }
        if ([
            'doc',
            'docx',
        ].includes(fileExt)) {
            return 'document';
        }
        return 'other';
    };

    getFileTypeIcon = (file) => {
        if (this.getTypeFile(file) === 'image') return <div className={ `${ styles.fileContentIcon } ${ styles.blue }` } dangerouslySetInnerHTML={ { __html: FileIconImages } } />;
        if (this.getTypeFile(file) === 'document') return <div className={ `${ styles.fileContentIcon } ${ styles.blue }` } dangerouslySetInnerHTML={ { __html: FileIconDocs } } />;
        return <div className={ `${ styles.fileContentIcon } ${ styles.green }` } dangerouslySetInnerHTML={ { __html: FileIconOthers } } />;
    };

    openFile = (e, file) => {
        if(e){
            e.stopPropagation();
        }
        this.closeAllFileMenu();
        if (this.getTypeFile(file) !== 'image') return;

        this.previewFileUrl = file.apiResponse.original_url || file.apiResponse.url || this.getFileUrl(file);
        this.previewFileName = this.getFileName(file);
        this.previewFileId = file.apiResponse.sys_id;
        this.showPreviewPopup = true;
    };

    downloadFile = (file) => {
        const url = `download/${ file.apiResponse.sys_id }?access-token=${ User.accessToken }`;
        window.location = `${ API_BASE_URL }/${ this.downloadUrl }/${ url }`;
    };

    downloadFiles = (files) => {
        const attachment_ids = files.map((file) => {
            return file.apiResponse.sys_id;
        });
        const url = `download-all/?access-token=${ User.accessToken }&attachment_ids=${ attachment_ids.join(',') }`;
        window.location = `${ API_BASE_URL }/${ this.downloadUrl }/${ url }`;
    };

    getFileUrl = (file) => {
        return API_BASE_URL + `/${ this.downloadUrl }/download/${ file.apiResponse.sys_id }?access-token=${ User.accessToken }`;
    };

    downloadAllFiles = (e) => {
        e.stopPropagation();
        if (this.uploadInProgressFiles.length > 0) return;
        this.downloadFiles(this.uploadedFiles);
    };

    downloadSelectedFiles = (e) => {
        e.stopPropagation();
        if (this.uploadInProgressFiles.length > 0) return;
        const files = this.uploadedFiles.filter((file) => file.selected === true);
        this.downloadFiles(files);
    };

    downloadFileClickEvent = (e, file) => {
        e.stopPropagation();
        if (this.uploadInProgressFiles.length > 0) return;
        this.closeAllFileMenu();
        this.downloadFile(file);
    };

    toggleSelectFile = (e, file) => {
        e.stopPropagation();
        this.closeAllFileMenu();

        if (file.selected === undefined) {
            file.selected = true;
        }
        else {
            file.selected = !file.selected;
        }
    };

    renderFileCheckBox = (file) => {
        if (this.selectedFiles.length === 0) return null;

        return (
            <span className={ styles.controlsCheckbox }>
                <div
                    className={ `${ styles.checkbox } ${ file.selected ? styles.active : '' }` }
                    onClick={ (e) => {
                        this.toggleSelectFile(e, file);
                    } }
                    dangerouslySetInnerHTML={ { __html: IconCheck } }
                    data-test={ ATTRIBUTES.attachUploadedFileCheckbox }
                />
            </span>
        );
    };

    renderFileMenu = (file) => {
        if (!file.menuActive) return null;
        const { widget_titles } = langStore.getTranslate();
        const isImage = this.getTypeFile(file) === 'image';
        const { isAclActive } = this.props;
        const menuClasses = [styles.fileMenu];
        if (this.fileMenuPosition !== 'top') menuClasses.push(styles.bottom);

        return (
            <div className={ menuClasses.join(' ') } ref={ this.fileMenuRef } onClick={ (e) => e.stopPropagation() }>
                { this.uploadedFiles.length !== 1 &&
                <div
                    className={ styles.fileMenuItem }
                    onClick={ (e) => this.toggleSelectFile(e, file) }
                    data-test={ ATTRIBUTES.attachUploadedFileMenuChoose }
                >
                    <span className={ `${ styles.fileMenuIcon }` } dangerouslySetInnerHTML={ { __html: FileMenuChooseIcon } } />
                    { widget_titles && widget_titles.choose }
                </div>
                }
                { isImage &&
                <div
                    className={ styles.fileMenuItem }
                    onClick={ (e) => this.openFile(e, file) }
                    data-test={ ATTRIBUTES.attachUploadedFileMenuOpen }
                >
                    <span className={ `${ styles.fileMenuIcon }` } dangerouslySetInnerHTML={ { __html: FileMenuShowIcon } } />
                    { widget_titles && widget_titles.display }
                </div>
                }
                <div
                    className={ styles.fileMenuItem }
                    onClick={ (e) => this.downloadFileClickEvent(e, file) }
                    data-test={ ATTRIBUTES.attachUploadedFileMenuDownload }
                >
                    <span className={ `${ styles.fileMenuIcon }` } dangerouslySetInnerHTML={ { __html: IconDownload } } />
                    { widget_titles && widget_titles.download }
                </div>
                { !isAclActive &&
                <div
                    className={ styles.fileMenuItem }
                    onClick={ (e) => this.handleRemoveFile(e, file) }
                    data-test={ ATTRIBUTES.attachUploadedFileMenuDelete }
                >
                    <span className={ `${ styles.fileMenuIcon }` } dangerouslySetInnerHTML={ { __html: FileMenuDeleteIcon } } />
                    { widget_titles && widget_titles.delete }
                </div>
                }
            </div>
        );
    };

    toggleFileMenu = (e, file) => {
        cancelDefaults(e);

        this.closeAllFileMenu(file);
        file.menuActive = !file.menuActive;
    };

    renderUploadedFileContent = (file) => {
        const fileSize = this.getFileSize(file);
        const fileName = this.getFileName(file);
        const fileUserName = file.apiResponse.user_display_name;
        const fileDate = getDateValueByString(file.apiResponse.created_at, getUserTimezone(), getUserDateTimeFormat().slice(0, -3));
        const fileTypeIcon = this.getFileTypeIcon(file);

        const controlsStyles = [styles.controls];
        if (file.menuActive) controlsStyles.push(styles.active);

        return (
            <div
                className={ styles.fileContent }
                onClick={ (e) => this.showOrDownloadOnClick(e, file) }
                onContextMenu={ (e) => this.toggleFileMenu(e, file) }
                data-test={ ATTRIBUTES.attachUploadedFileContent }
            >
                { fileTypeIcon }
                <div className={ styles.fileParams } title={ fileName }>
                    <div className={ styles.fileName } data-test={ ATTRIBUTES.attachFileName }>
                        <span>{ fileName }</span>
                    </div>
                    <div className={ styles.fileSize }><span>{ fileUserName }</span></div>
                    <div className={ styles.fileSize }><span>{ fileDate } &#x2022; { fileSize } kb</span></div>
                </div>
                <div className={ controlsStyles.join(' ') }>
                    { this.renderFileCheckBox(file) }

                    <span
                        onClick={ (e) => this.downloadFileClickEvent(e, file) }
                        data-test={ ATTRIBUTES.attachUploadedFileDownloadIcon }
                        className={ styles.controlsIcon }
                    >
                        <Button
                            buttonType={'icon'}
                            svg={ IconDownload }
                        />
                    </span>

                    <span className={ styles.fileMenuWrap }>
                            <span
                                onClick={ (e) => this.toggleFileMenu(e, file) }
                                data-test={ ATTRIBUTES.attachUploadedFileMenuIcon }
                                className={ styles.controlsIcon }
                            >
                                <Button
                                    buttonType={'icon'}
                                    svg={ FileMenuIcon }
                                />
                            </span>
                        { this.renderFileMenu(file) }
                    </span>
                </div>
            </div>
        );
    };

    renderUploadedFileContentInProgress = () => {
        return (
            <div className={ styles.fileSkeleton }>
                <div className={ styles.fileSkeletonIcon }>
                    <div className={ styles.fileSkeletonBlank }/>
                </div>
                <div className={ styles.fileSkeletonContent }>
                    <div className={ styles.fileSkeletonBlank }/>
                    <div className={ styles.fileSkeletonBlank }/>
                    <div className={ styles.fileSkeletonBlank }/>
                </div>
            </div>
        );
    };

    renderUploaded = (files) => {
        const items = [];
        for (const file of files) {
            items.push(
                <div
                    key={ JSON.stringify(file) }
                    className={ `${styles.uploadedFileContainer} ${file.selected ? styles.selected : ''} ${file.menuActive ? styles.active : ''} ${file.uploadInProgress ? styles.loading : ''}` }
                    data-test={ ATTRIBUTES.attachUploadedFilesContainer }
                >
                    { file.uploadInProgress && this.renderUploadedFileContentInProgress(file) }
                    { file.uploaded && this.renderUploadedFileContent(file) }
                </div>,
            );
        }

        return <div className={ styles.dropZoneAttachments }>{ items }</div>;
    };


    handleClose = () => {
        const { onClose, attachments, isClearAttachWhenClose } = this.props;
        if ((!attachments && this.filesUploaded()) === -1 || isClearAttachWhenClose) {
            this.uploadedFiles = [];
            this.isCloseWhenAdd = false;
        }

        if (onClose) onClose();
    };

    unselectAllFiles = () => {
        if (this.uploadInProgressFiles.length > 0) return;
        this.selectedFiles.forEach(file => {
            file.selected = false;
        });
    };

    selectAllFiles = () => {
        if (this.uploadInProgressFiles.length > 0) return;
        this.uploadedFiles.forEach(file => {
            file.selected = true;
        });
    };

    // @TODO Когда у команды АПИ появится время, будет добавлен метод который за один вызов удаляет выбранные файлы
    deleteSelected = (e) => {
        if (this.uploadInProgressFiles.length > 0) return;
        this.selectedFiles.forEach(file => {
            this.handleRemoveFile(e, file).catch(console.error);
        });
    };

    handleOverlayClick = (e) => {
        if (e.target === this.overlayRef.current) this.handleClose();
    };

    canAddFile = () => {
        return (this.uploadedFiles.length === 0 || this.filesUploaded() === -1) || this.multiple;
    };

    onArrowClick = (direction) => {
        const uploadedImages = this.uploadedFiles.filter((file) => this.getTypeFile(file) === 'image');
        let currentIndex;
        let nextIndex;

        for (let i = 0; i < uploadedImages.length; i++) {
            if (uploadedImages[i].apiResponse.sys_id === this.previewFileId) {
                currentIndex = i;
                break;
            }
        }

        if (direction === 'prev') {
            nextIndex = currentIndex > 0 ? currentIndex - 1 : uploadedImages.length - 1;
        }
        else {
            nextIndex = currentIndex !== uploadedImages.length - 1 ? currentIndex + 1 : 0;
        }

        this.openFile(null, uploadedImages[nextIndex]);
    };

    render() {
        const { uploadedFiles, selectedFiles, isCloseWhenAdd } = this;
        const { attachments_titles = {} } = langStore.getTranslate();
        const { isAclActive } = this.props;
        if (isCloseWhenAdd) return null;

        let dropZoneClasses = [styles.dropZone];

        if (this.dropHover && this.canAddFile()) dropZoneClasses.push(styles.dropHover);
        if (uploadedFiles.length > 0) dropZoneClasses.push(styles.hasAttachments);
        dropZoneClasses = dropZoneClasses.join(' ');

        const overlayClasses = [styles.dragAndDropOverlay];
        if (this.tallContent) overlayClasses.push(styles.tall_content);
        if (this.props.show) overlayClasses.push(styles.show);

        const showArrows = uploadedFiles.filter((file) => this.getTypeFile(file) === 'image').length > 1;
        const headerTitle = this.props.columnName ?  this.props.columnName : attachments_titles.attachments;

        return (
            <GlobalPortal>
                <div className={ overlayClasses.join(' ') } onClick={ this.handleOverlayClick } ref={ this.overlayRef }>

                    <div className={ styles.dragAndDropContainer } ref={ this.contentRef }>
                        <div className={ styles.header }>
                            <Heading noMargin data-test={ ATTRIBUTES.attachTitle } size={ 'h2' }>{ headerTitle }</Heading>
                            { uploadedFiles.length !== 0 && <span className={ styles.attachCount } data-test={ ATTRIBUTES.attachCount }>{ uploadedFiles.length }</span> }
                            <Button className={ styles.close } buttonType={ 'icon' } svg={ IconX } onClick={ this.handleClose } data-test={ ATTRIBUTES.dragAndDropClose } />
                        </div>

                        { uploadedFiles.length > 0 && <div className={ styles.topActionButtons }>
                            { selectedFiles.length !== 0 ?
                            <>
                                <Button
                                    className={ styles.button }
                                    onClick={ this.downloadSelectedFiles }
                                    buttonType='primary'
                                    data-test={ ATTRIBUTES.attachDownloadSelected }
                                    data-loader={ this.uploadInProgressFiles.length > 0 }
                                >
                                    { attachments_titles?.with_files?.download } ({ this.selectedFiles.length })
                                </Button>
                                { !isAclActive &&
                                <Button
                                    className={ styles.button }
                                    onClick={ this.deleteSelected }
                                    buttonType='destructive'
                                    data-test={ ATTRIBUTES.attachDeleteSelected }
                                    data-loader={ this.uploadInProgressFiles.length > 0 }
                                >
                                    { attachments_titles?.with_files?.delete } ({ this.selectedFiles.length })
                                </Button>
                                }
                                <Button
                                    className={ `${styles.button}` }
                                    onClick={ this.unselectAllFiles }
                                    data-test={ ATTRIBUTES.attachUnselected }
                                    data-loader={ this.uploadInProgressFiles.length > 0 }
                                >
                                    { attachments_titles?.with_files?.cancel }
                                </Button>
                            </>
                            :
                            <>
                                { uploadedFiles.length === 1 ?
                                    <Button
                                        className={ styles.button }
                                        onClick={ (e)=>{
                                            this.downloadFileClickEvent(e, uploadedFiles[0]);
                                        } }
                                        buttonType='primary'
                                        data-loader={ this.uploadInProgressFiles.length > 0 }
                                        data-test={ ATTRIBUTES.attachDownloadAll }
                                    >
                                        { attachments_titles?.with_files?.download }
                                    </Button>
                                    :
                                    <Button
                                        className={ styles.button }
                                        onClick={ this.downloadAllFiles }
                                        buttonType='primary'
                                        data-loader={ this.uploadInProgressFiles.length > 0 }
                                        data-test={ ATTRIBUTES.attachDownloadAll }
                                    >
                                        { attachments_titles?.with_files?.download_all }
                                    </Button>
                                }
                                { uploadedFiles.length === 1 && !isAclActive && <Button
                                    className={ styles.button }
                                    onClick={(e)=>{
                                        this.handleRemoveFile(e, uploadedFiles[0]).catch(console.error);
                                    }}
                                    buttonType='destructive'
                                    data-loader={ this.uploadInProgressFiles.length > 0 }
                                >
                                    { attachments_titles?.with_files?.delete }
                                </Button>
                                }
                                { uploadedFiles.length > 1 && <Button
                                    className={ `${styles.button}` }
                                    onClick={ this.selectAllFiles }
                                    data-test={ ATTRIBUTES.attachSelectAll }
                                    data-loader={ this.uploadInProgressFiles.length > 0 }
                                >
                                    { attachments_titles?.with_files?.select_all }
                                </Button>
                                }
                            </>
                            }
                        </div> }

                        <div className={ styles.content }>
                            <div className={ styles.contentContainer }>
                                <div
                                    className={ dropZoneClasses }
                                     onDragEnter={ this.handleDragEnter }
                                     onDragLeave={ this.handleDragLeave }
                                     onDragOver={ this.handleDragOver }
                                     onDrop={ this.handleDrop }
                                >

                                    { !isAclActive && !(uploadedFiles.length > 0 && !this.multiple) &&
                                    <div className={ `${styles.dropZoneTitle} ${ uploadedFiles.length === 0 ? '' : styles.static }` }>
                                        <div className={ styles.dropZoneTitleContainer }>
                                            <div className={ styles.fileIcon } dangerouslySetInnerHTML={ { __html: IconUpload } } />
                                            <div className={ styles.dndText }>{ attachments_titles?.drag_n_drop_files_or }</div>
                                            <div className={styles.dndButton}>
                                                <Button
                                                    onClick={ this.handleDropZoneClick }
                                                    data-test={ ATTRIBUTES.dropZoneTitle }
                                                    data-loader={ this.uploadInProgressFiles.length > 0 }
                                                >
                                                    { attachments_titles?.upload_from_computer }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    { this.renderUploaded(uploadedFiles) }
                                    <div className={styles.dropHoverContent}>
                                        <div className={ styles.dropHoverContentIcon } dangerouslySetInnerHTML={ { __html: IconUpload } } />
                                        { attachments_titles?.no_files?.drag_n_drop_files }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={ styles.footer } />
                    </div>
                    { this.showPreviewPopup && (
                        <Preview
                            downloadUrl={ this.downloadUrl }
                            fileId={ this.previewFileId }
                            src={ this.previewFileUrl }
                            name={ this.previewFileName }
                            onClose={ () => {
                                this.showPreviewPopup = false;
                                this.previewFileUrl = '';
                                this.previewFileName = '';
                                this.previewFileId = '';
                            } }
                            showArrows={ showArrows }
                            onArrowClick={ this.onArrowClick }
                        />
                    ) }
                </div>
            </GlobalPortal>
        );
    }
}
