import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import styles from './styles.module.scss';
import listboxState from 'globalState/listboxState';
import IconClose from 'assets/img/icons/close-x.svg';
import Field from './field';
import userState from 'globalState/user';
import { EnhancedComponent } from 'components/EnhancedComponent';
import { globalEventEmitter } from 'lib/EventEmitter';
import Heading from 'components/heading';
import Button from 'components/button';
import { isMedia } from 'helpers/html';
import GlobalPortal from 'components/globalPortal';
import langStore from 'globalState/lang';
import cache from 'globalState/cache';
import { resetCache } from 'actions/cache';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { savePreference } from 'actions/preferences';

/**
 * Описание: Листбокс локалпак
 *
 * @props isBlockWorkflow режим для workflow.
 *
 **/

const ADMIN_PREFERENCES = 'admin_preferences';

@observer
export default class ListboxComponent extends EnhancedComponent {
    refListBox = React.createRef();
    @observable data;
    @observable loading = false;

    constructor(props) {
        super(props);

        this.useEffect(this.onMount);
        this.useEffect(this.onUnmount);
        this.useEffect(this.useImpersonateHandler);

        reaction(
            () => listboxState.isOpened,
            this.updateBodyScroll,
        );
    }

    updateBodyScroll = (isOpened) => {
        // for mobile only
        if (isMedia('sm')) {
            if (isOpened) {
                this.freezeBodyScroll(true);
            }
            else {
                this.freezeBodyScroll(false);
            }
        }
    };

    freezeBodyScroll = (boolean) => {
        const { body } = document;

        if (boolean) {
            body.classList.add(styles.Freeze);
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${ scrollY }px`;
        }
        else {
            body.classList.remove(styles.Freeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    };

    onWindowResize = () => {
        if (!isMedia('sm')) {
            listboxState.setOpenedState(false);
        }
    };

    // Update page data when we do impersonate or deImpersonate
    useImpersonateHandler = () => {
        const handleImpersonate = () => {
            this.fetchData().catch(console.error);
        };

        return globalEventEmitter.subscribe([
            'impersonate',
            'deImpersonate',
        ], handleImpersonate);
    };

    onMount = (mount) => {
        if (!mount) return;
        this.fetchData().catch(console.error);
        window.addEventListener('resize', this.onWindowResize);
    };

    onUnmount = () => {
        return () => {
            window.removeEventListener('resize', this.onWindowResize);
        };
    };

    onChange = (value, preference) => {
        this.saveChanges(preference, value).catch(console.error);
    };

    reload = () => {
        setTimeout(() => {
            window.location.reload();
        }, 150);
    };

    saveChanges = async (name, value) => {
        this.loading = true;
        const { isOkStatus } = await savePreference(name, value);
        if (isOkStatus) {
            // проверка на Configuration Pack из Preferences
            if (name === 'vcs.localPack.current') {
                listboxState.setOpenedState(false);
            }
            else {
                this.clearCache();
            }
        }
        this.loading = false;
    };

    fetchData = async () => {
        listboxState.setData();
    };

    clearEveryoneCache = async () => {
        const { data } = await resetCache().catch(console.error);
        if (data.result) {
            this.clearCache();
        }
    };

    clearCache = () => {
        cache.deleteAllByPrefix();
        this.reload();
    };

    renderSections() {
        const { preferences = {} } = langStore.getTranslate();

        const sections = listboxState.getData().sections.map((section, index) => {
            const fields = section.fields.map(this.renderField);
            return (
                <div
                    className={ styles.Block }
                    key={ section.title + index }
                    data-test={ ATTRIBUTES.listBoxSection }
                >
                    <div className={ styles.BlockTitle }>{ section.title }</div>
                    { fields }
                    { section.name === ADMIN_PREFERENCES && <div className={ styles.WrapLink }>
                        <a
                            className={ styles.Link }
                            onClick={ this.clearEveryoneCache }
                            data-test={ ATTRIBUTES.listBoxClearCacheForAll }
                        >
                            { preferences.reset_cache_for_all_users }
                        </a>
                    </div> }
                </div>
            );
        });

        return <div>{ sections }</div>;
    }

    renderField = (field, index) => {
        return (
            <Field
                key={ field.name + index }
                type={ field.type }
                name={ field.name }
                databaseValue={ field.database_value }
                options={ field.options }
                button={ field.button }
                onChange={ this.onChange }
                tableName={ field.table_name }
                preferenceName={ field.preference_name }
                disabled={ this.loading }
            />
        );
    };

    render() {
        const data = listboxState.getData();
        if (!data) {
            return null;
        }
        const { preferences = {} } = langStore.getTranslate();
        const version = (!_.isEmpty(userState.user) && userState.user.version) ? userState.user.version : 'v0.0.0';

        return (
            <GlobalPortal>
                { listboxState.isOpened && (
                    <div
                        className={ styles.ListBoxOverlay }
                        onClick={ () => {
                            listboxState.setOpenedState(false);
                        } }
                    />
                ) }
                <div
                    className={ `${ styles.ListBox } ${ this.props.isBlockWorkflow ? styles.workflow : '' } ${ listboxState.isOpened ? styles.active : '' }` }
                    ref={ this.refListBox }
                    data-test={ ATTRIBUTES.listBoxMenu }
                >
                    <div className={ styles.Headline }>
                        <Heading size={ 'h3' } className={ styles.HeadlineText } noMargin>{ data.title }</Heading>
                        <Button
                            buttonType={ 'icon' }
                            svg={ IconClose }
                            onClick={ () => {
                                listboxState.setOpenedState(false);
                            } }
                            data-test={ ATTRIBUTES.listBoxClose }
                        />
                    </div>
                    <div className={ styles.ListBoxContainer }>
                        <div className={ styles.ListBoxContent }>
                            { this.renderSections() }
                            <div className={ styles.BlockVersion }>
                                <div>
                                    <a
                                        className={ styles.Link }
                                        onClick={ this.clearCache }
                                        data-test={ ATTRIBUTES.listBoxClearCache }
                                    >
                                        { preferences.clear_cache }
                                    </a>
                                </div>
                                <span>
                                { `${ preferences.version }: ${ version }` }
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </GlobalPortal>
        );
    }
}
