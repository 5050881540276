import {convertServerDateToISO, getUserTimezone} from 'helpers/getUserTimeZone';
import langStore from "globalState/lang";
import { dateToString } from 'helpers/date';
import moment from 'moment';

export function dateDiffInDays (olderDate, laterDate) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(olderDate.getFullYear(), olderDate.getMonth(), olderDate.getDate());
    const utc2 = Date.UTC(laterDate.getFullYear(), laterDate.getMonth(), laterDate.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function displayTimePassed (date) {
    if (!date){
        return '';
    }
    const { workflow_titles } = langStore.getTranslate();
    const now = new Date();
    const daysPassed = dateDiffInDays(date, now);
    if (Math.abs(daysPassed) > 1){
        return dateToString(date);
    } else if (daysPassed === 1) {
        return workflow_titles && workflow_titles.yesterday;
    } else {
        const hoursPassed =  now.getHours() - date.getHours();
        if (hoursPassed === 0){
            const minutesPassed =  now.getMinutes() - date.getMinutes();
            if (minutesPassed === 0) {
                return workflow_titles && workflow_titles.now;
            } else {
                return minutesPassed.toString()+' '+(workflow_titles && workflow_titles.minutes);
            }
        } else {
            return hoursPassed.toString() + (workflow_titles && workflow_titles.hours);
        }
    }
}


export function getLocalTime(value){
    const transformedValue = convertServerDateToISO(value);
    const timeZone = getUserTimezone();
    return moment(transformedValue).tz(timeZone);
}