import * as React from 'react';
import { sideMenuState } from 'globalState/widgets/sideMenuState';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { withRouter } from 'react-router-dom';
import { fetchListAData } from 'actions/widgets';
import { observable } from 'mobx';
import _ from 'lodash';
import { helperRedirect } from 'helpers/history';
import EventBusState from 'globalState/eventBus';
import { eventType } from 'constants/eventBusTypes';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getUrlParams } from 'helpers/data';
import { getCurrentItems, getCurrentItemsByCategoryId, getCurrentItemsByItemId, getDataArray } from 'helpers/widgets/categoryWidget';
import Description from './Description';

/**
 * Виджет для отображения списка категорий и элементов в текущей категории
 */
@withRouter
@observer
export default class CategoryWidget extends React.Component {
    @observable data = [];
    @observable items = [];

    constructor(props) {
        super(props);

        this.fetchListAData();

        EventBusState.on(eventType.NAVIGATE, this.onElementChange);
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (!_.isEqual(location, prevProps.location)) {
            const urlParams = location.search ? getUrlParams(location.search) : {};
            this.fetchListAData(urlParams.category);
        }
    }

    fetchListAData = async (parentCategoryId = null) => {
        const { nodeId, categorySubject, categoryDescription, categoryIcon, itemSubject, itemDescription, itemIcon, location } = this.props;
        const urlParams = location.search ? getUrlParams(location.search) : {};
        const arrPath = location.pathname.split('/');
        const urlSuffix = arrPath[1];
        const pathName = arrPath[2];
        const params = _.omitBy({
            url_suffix: urlSuffix,
            path_name: pathName,
            node: nodeId,
            category_description: categoryDescription,
            category_icon: categoryIcon,
            category_subject: categorySubject,
            item_description: itemDescription,
            item_icon: itemIcon,
            item_subject: itemSubject,
            short_data: '1',
            parent_category_id: parentCategoryId || urlParams.category,
        }, _.isEmpty);
        const response = await fetchListAData(params);
        if (response.isOkStatus) {
            this.data = response.data;
            if (!(parentCategoryId || urlParams.category)) {
                this.setItems();
            } else {
                this.items = getDataArray(this.data);
            }
        }
    };

    onElementChange = (data) => {
        this.fetchListAData(data.category_id);
    };

    handleClickItem = (item) => () => {
        if (item.url.includes('?category')) {
            helperRedirect(item.url, null, { preventReload: true });
            EventBusState.emit(eventType.NAVIGATE, item.url.includes('?category') ? { category_id: item.sys_id } : { item_id: item.sys_id });
        } else {
            helperRedirect(item.url);
        }
    };

    setItems = (categoryId, itemId) => {
        const { location } = this.props;
        const params = location.search ? getUrlParams(location.search) : {};
        if (itemId || params.item) {
            this.items = getCurrentItemsByItemId(itemId || params.item, this.data);
        } else if (categoryId || params.category) {
            this.items = getCurrentItemsByCategoryId(categoryId || params.category, this.data);
        } else {
            if (!location.search) {
                this.items = getDataArray(this.data);
            } else if (sideMenuState.getCurrentUrl()) {
                this.items = getCurrentItems(sideMenuState.getCurrentUrl(), this.data);
            } else {
                const currentUrl = location.pathname + location.search;
                this.items = getCurrentItems(currentUrl, this.data);
            }
        }
    };

    renderIcon = (item) => {
        const { categoryIcon, itemIcon } = this.props;
        const icon = item[categoryIcon] || item[itemIcon];
        if (!icon) return null;

        return (
            <div className={ styles.HeaderIcon }>
                <img src={ icon } />
            </div>

        );
    };

    render() {
        if (_.isEmpty(this.data)) {
            return null;
        }
        const { categorySubject, categoryDescription, itemSubject, itemDescription, className, hideDescription } = this.props;
        // const { dictionary } = this.data;
        const categoryItems = _.map(this.items, item => {
            const hasSub = item.categories && item.categories.length > 0 || item.items && item.items.length > 0;
            return (
                <div key={ item.sys_id } className={ `${styles.CategoryItem} ${hasSub ? styles.hasSub : ''}` } onClick={ this.handleClickItem(item) }>
                    <div className={ styles.CategoryItemContainer }>
                        <div className={ styles.Header }>
                            <div className={ styles.HeaderSubject }>
                                { item[categorySubject] || item[itemSubject] || '' }
                            </div>
                            { this.renderIcon(item) }
                        </div>
                        { (!hideDescription && (item[categoryDescription] || item[itemDescription])) && <Description html={ item[categoryDescription] || item[itemDescription] || '' } />}
                    </div>
                </div>
            );
        });
        return (
            <div
                className={ `${ styles.CategoryList } ${ className || '' }` }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `category-${ ATTRIBUTES.widget }` }
            >
                { categoryItems }
            </div>
        );
    }
}
