/**
 * Описание: Workflow search
 * Параметры:
 * setData - метод установки фильтра
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './styles.module.scss';
import IconSearch from 'assets/img/icons/search.svg';
import IconX from "assets/img/icons/close-x.svg";
import langStore from "globalState/lang";

@observer
export default class Search extends React.Component {
    @observable value='';

    constructor(props) {
        super(props);
    }

    handleOnChange = (evt) => {
        this.props.setData(evt.target.value);
        this.value = evt.target.value;
    };

    handleClear = () => {
        this.props.setData('');
        this.value = '';
    };

    handleKeyDown = (evt) => {
        if (evt.keyCode == 27) {
            this.handleClear();
        }
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render(){
        let icon;
        if (this.value) {
            icon = <div className={ styles.IconSearch }
                 dangerouslySetInnerHTML={ { __html: IconX } }
                 onClick={ this.handleClear } />;
        } else {
            icon = <div className={ styles.IconSearch }
               dangerouslySetInnerHTML={ { __html: IconSearch } } />;
        }

        const { workflow_titles } = langStore.getTranslate();
        return (
            <div className={ styles.Search } >
                <input placeholder={ workflow_titles && workflow_titles.search }
                       className={ styles.Input }
                       onFocus={ this.handleOnFocus }
                       onBlur={ this.handleOnFocusOut }
                       onChange={ this.handleOnChange }
                       onKeyDown={ this.handleKeyDown }
                       type='text'
                       value={ this.value }
                />
                { icon }
            </div>
        );
    }
}