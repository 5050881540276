// получение списка значений для choice полей
import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';
import { ResponseGroupsFilterType } from 'types/components/table/index';

// получение списка значений
export const fetchListFilter = async (tableName: string, params: any, handleForbiddenError = false): Promise<any> => {
    return handleResponse(await new apiRequest(`GET /list-filter/${tableName}`).qs(params).send(null, handleForbiddenError));
};

export const fetchPortalListData = async (params: any): Promise<any> => {
    return handleResponse(await new apiRequest(`GET /portal/list-items`).qs(params).send());
};

export const fetchListData = async (tableName: string, params: any): Promise<any> => {
    return handleResponse(await new apiRequest(`GET /list/${tableName}`).qs(params).send());
};

export const fetchListGroupsFilter = async (tableName: string, params: any): Promise<ResponseGroupsFilterType> => {
    return handleResponse(await new apiRequest(`GET /list-group-filter/${ tableName }`).qs(params).send());
};
// user preference list.groups.per_page
