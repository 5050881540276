import * as React from 'react';
import { observer } from 'mobx-react';
import DragAndDrop from 'components/portalDesigner/components/dragAndDrop';

import LayoutsStorage from 'components/portalDesigner/storage/layouts';

import styles from 'components/portalDesigner/styles.module.scss';

import PageData from 'components/portalDesigner/state/pageData';

@observer
export default class layouts extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const data = LayoutsStorage.get();

        return (
            <div className={ styles.List }>
                <div className={ styles.ListMenuTitle } key="menu_title">
                    Layouts
                </div>
                <div className={ styles.ListMenu } key="menu">
                    <DragAndDrop key="container" type={ PageData.TYPE_CONTAINER } isNew>
                        <div key="container" className={ styles.LayoutsItem }>
                            <span className={ `${ styles.LayoutsItemsBlock } ${ styles['LayoutsItemsBlock-1'] }` }>
                                Container
                            </span>
                        </div>
                    </DragAndDrop>
                    {
                        data.map((layout, index) => {
                            return <DragAndDrop sysId={ layout.id } key={ `row-${ index }` } type={ PageData.TYPE_LAYOUT } index={ index } isNew>
                                <div key={ index } className={ styles.LayoutsItem }>
                                    {
                                        _.map(layout.columns, (col, index) => (
                                            <span key={ index }
                                                  className={ `${ styles.LayoutsItemsBlock } ${ styles['LayoutsItemsBlock-' + col[PageData.DEFAULT_SIZE]] }` }>
                                                { col[PageData.DEFAULT_SIZE] }
                                            </span>
                                            )
                                        )
                                    }
                                </div>
                            </DragAndDrop>;
                        })
                    }
                </div>
            </div>
        );
    }
}
