import * as React from 'react';

import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { isEqual } from 'helpers/data';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import CustomSelect from 'components/customselect';

/**
 * Описание: компонент Select
 * Параметры:
 * value - значение
 * getRef - передача ref элемента select родительскому компоненту
 * className - стиль родительского блока для select
 * hasEmptyOption - есть возможность выбора пустого значения
 * special.values - варианты выбора
 * readOnly - режим чтения
 * onChange - обработчик изменения значения select
 */

@observer
export default class Select extends React.Component {
    @observable options = [];
    @observable isReadOnly;
    @observable isVisible;
    @observable _value = '';


    set value(value) {
        this._value = value;
    }

    get value() {
        return this._value;
    }

    constructor(props) {
        super(props);
        if (props.multiple) {
            this._value = [];
        }

        this.isReadOnly = typeof props.readOnly !== 'undefined' ? props.readOnly : false;
        this.isVisible = typeof props.isVisible !== 'undefined' ? props.isVisible : true;
        this.options = props.special.values || [];
    }

    componentDidMount() {
        if (this.props.value) {
            this.value = this.props.value;
        }
    }

    componentDidUpdate(prevProps) {
        const { special, value, readOnly } = this.props;
        if (special && special.values && !isEqual(special.values, prevProps.special.values)) {
            this.options = special.values;
        }
        if (!isEqual(value, prevProps.value)) {
            this.value = value;
        }
        if (readOnly !== prevProps.readOnly) {
            this.isReadOnly = readOnly;
        }
    }

    uiAddOption(value, label) {
        this.options.push({
            database_value: value,
            display_value: label,
        });
    }

    uiSetOption(options) {
        this.options = options;
    }

    uiRemoveOption(value) {
        this.options.forEach(function (item, i, arr) {
            if (item.database_value === value) {
                arr.splice(i, 1);
                return false;
            }
        });
    }

    uiSetValue(value) {
        this.value = value;
        const { onChange } = this.props;
        if (onChange) {
            onChange({ value: this.value });
        }
    }

    uiGetValue() {
        return this.value;
    }

    uiClearOptions() {
        this.options = [];
    }

    uiClearValue() {
        this.value = undefined;
    }

    uiSetReadOnly(readOnly) {
        this.isReadOnly = readOnly;
    }

    uiSetVisible(display) {
        this.isVisible = display;
    }

    onChange = (value) => {
        const { onChange } = this.props;
        this.value = value;
        if (onChange) {
            onChange({ value: this.value });
        }
    };

    getRef = (node) => {
        this.node = node;
        if (this.props.getRef) {
            this.props.getRef(node);
        }
    };


    render() {
        if (!this.isVisible) {
            return null;
        }

        let value;

        if (this.value) {
            if (typeof this.value.database_value !== 'undefined') {
                value = this.value.database_value;
            } else if(typeof this.value.sys_id !== 'undefined') {
                value = this.value.sys_id;
            } else {
                value = this.value;
            }
        } else {
            value = '';
        }

        return (
            <FieldWrapper { ...this.props }
                          label={ this.label }
                          isMandatory={ this.isMandatory }
                          isVisible={ this.isVisible }
                          value={ this.value }
                          column_type="choice"
                          fieldMessage={ this.fieldMessage }>
                <CustomSelect
                    disabled={ this.isReadOnly || !this.options }
                    ref={ this.getRef }
                    className={ this.props.className }
                    databaseValue={ value }
                    options={ this.options }
                    onChange={ this.onChange }
                    data-test={ this.props['data-test'] }
                />
            </FieldWrapper>
        );
    }
}
