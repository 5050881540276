import BaseFormFieldModel from './BaseFormFieldModel';
import _ from 'lodash';
import { LexicalEditor } from "lexical";

export default class RichTextModel extends BaseFormFieldModel {

    columnType = 'richText';

    rawEditor: LexicalEditor | null = null;

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        super.mergeData(data);
    }

    setRawEditor = (editor: LexicalEditor) => {
        this.rawEditor = editor;
    }

    uiGetDisplayValue() {
        const rootElement = this.rawEditor?.getRootElement();
        return rootElement?.textContent;
    }

    uiSetValue(value: string) {
        if (value === '') {
            return this.uiClearValue();
        }
        if (_.isEqual(this.defaultValue, value)) {
            this.changed = false;
        } else if (!_.isEqual(this.value, value)) {
            this.changed = true;
        }
        this.value = value;
    }
}
