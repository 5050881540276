import * as React from 'react';
import Container from 'components/container';

import LayoutEditor from 'components/layoutEditor';
import Button from 'components/button';
import apiRequest from 'lib/apiRequest';

import langStore from 'globalState/lang';

import styles from './styles.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { goBack, helperRedirect } from 'helpers/history';
import SmartTitle from 'components/smartTitle';
import PageLoader from 'components/pageLoader';
import Heading from 'components/heading';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import { getUrlParams } from 'helpers/data';


@observer
class RelatedListLayoutEdit extends React.Component {
    @observable data;
    @observable errorMessage = '';
    @observable isSavingData = false;
    lastRouteParams = '';
    selected = [];
    form_view;


    constructor(props) {
        super(props);
        this.fetchData().catch(this.errorFetchData);
        const urlParams = getUrlParams(window.location.search);
        this.form_view = urlParams ? urlParams.form_view : null;
    }

    componentDidUpdate() {
        this.fetchData().catch(this.errorFetchData);
    }

    fetchData = async () => {
        const routeParams = window.location.pathname + window.location.search;
        if (routeParams.localeCompare(this.lastRouteParams) === 0) return false;
        this.lastRouteParams = window.location.pathname + window.location.search;

        const response = await new apiRequest('GET ' + routeParams).send();
        this.data = response.getData();
    };

    errorFetchData = (e) => {
        this.isSavingData = false;
        this.errorMessage = e.message;
    };

    updateSelected = (selected) => {
        this.selected = selected;
    };

    saveSelected = async () => {
        this.isSavingData = true;
        const data = {
            'view': this.data.current_view,
            'selected_ids': this.selected,
            'form_view': this.form_view,
        };
        const result = await new apiRequest('POST /related-list/' + this.data.essence).sendJSON(data);
        if (result && result.status === 'OK') {
            goBack(`/list/${ this.data.essence }`);
        }
    };

    addView = async (view) => {
        const data = {
            'view': view,
            'form_view': this.form_view,
        };
        const redirect = window.location.pathname + '?record_id=' + this.data.record_id + '&view=' + view;
        const result = await new apiRequest('PUT /related-list/' + this.data.essence).sendJSON(data);
        if (result && result.status === 'OK') {
            helperRedirect(redirect);
            this.fetchData().catch(this.errorFetchData);
        }
    };

    switchView = async (view) => {
        const redirect = window.location.pathname + '?record_id=' + this.data.record_id + '&view=' + view;
        helperRedirect(redirect);
        this.fetchData().catch(this.errorFetchData);
    };

    render() {
        const { related_lists_titles } = langStore.getTranslate();

        if (this.errorMessage) return <div>{ this.errorMessage }</div>;
        if (!this.data) return <PageLoader />;

        return (
            <Container>
                <div className={ styles.all }>
                    <div className={ styles.actionsTop }>
                        <div className={ styles.title }>
                            <Heading size={ 'h2' } noMargin><SmartTitle>{ related_lists_titles && related_lists_titles.related_list_configure }</SmartTitle></Heading>
                        </div>
                        <Button
                            className={ styles.button }
                            onClick={ this.saveSelected }
                            buttonType='primary'
                            data-loader={ this.isSavingData }
                        >
                            { related_lists_titles && related_lists_titles.save_button }
                        </Button>
                        <Button
                            onClick={ () => {
                                goBack(`/list/${ this.data.essence }`);
                            } }>
                            { related_lists_titles && related_lists_titles.cancel_button }
                        </Button>
                    </div>
                    <LayoutEditor
                        onChange={ this.updateSelected }
                        selectedItems={ this.data.selected_items }
                        availableItems={ this.data.available_items }
                        showSplitButtons={ this.data.show_split_buttons }
                        lang={ related_lists_titles ? related_lists_titles : null }
                        location={ this.props.location }
                    />
                </div>
            </Container>
        );
    }

}


export default ErrorWrapperHoc(RelatedListLayoutEdit);
