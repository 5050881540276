import { action, observable } from 'mobx';
import * as _ from 'lodash';

class WidgetsData {
    @observable _widgets = [];
    @observable _forms = {};

    getWidgets = () => {
        return this._widgets;
    };

    getWidgetsId = () => {
        return _.map(this._widgets, w => w.getId());
    };

    getWidgetsWithId = (id) => {
        const widgets = this._widgets.filter(widget => widget.getId() === id);
        if (!widgets.length) {
            console.warn('Widget not found in widgetData! Id widget - ', id);
        }
        return widgets;
    };

    getWidget = (id) => {
        const widget = this._widgets.find(widget => {
            return widget.getId() === id;
        });
        if (!widget) {
            console.warn('Widget not found in widgetData! Id widget - ', id);
        }
        return widget;
    }

    @action
    addWidget = (widget) => {
        this._widgets = [ ...this._widgets, widget ];
    };

    @action
    removeWidget = (widgetId) => {
        this._widgets = _.filter(this._widgets, widget => widget.getId() !== widgetId);
    };

    getFormByName = (name) => {
        return _.find(this._forms,(form) => form.formName === name);
    };

    getFormById = (id) => {
        return this._forms[id];
    };

    @action
    addForm = (id, form) => {
        this._forms = { ...this._forms, [id]: form };
    };

    hasForm = (id) => {
        return !!_.find(this._forms, (form, key) => key === id);
    };

    getForms = () => {
        return this._forms;
    };

    hasOnlyOneForm = () => {
        return !!_.find(this._widgets, w => w.hasOneForm());
    };

    @action
    removeForm = (id) => {
        let forms;
        if (!_.isEmpty(this._forms)){
            forms = { ...this._forms };
            if (forms[id]){
                delete forms[id];
            }
        } else {
            forms = {};
        }
        this._forms = forms;
    };

    getFormOnlyOne = () => {
        return _.find(this._widgets, w => w.hasOneForm());
    }
}

export const widgetsDataState = WidgetsData;

export default new WidgetsData();
