/**
 * Описание: Панель инструментов диаграммы рабочих процессов
 * Для редактирования свойств объекта
 * Параметры:
 * model - модель с данными
 *
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import IconArrow from 'assets/img/sidebar-chevron-right.svg';
import IconCreateRecord from 'assets/img/icons/file-plus.svg';
import IconEnd from 'assets/img/icons/flag.svg';
import IconEvent from 'assets/img/icons/calendar.svg';
import IconGroupApproval from 'assets/img/icons/user-many.svg';
import IconIf from 'assets/img/icons/if.svg';
import IconJoin from 'assets/img/icons/log-in.svg';
import IconNotification from 'assets/img/icons/notification-bell.svg';
import IconRollback from 'assets/img/icons/rotate-left.svg';
import IconRun from 'assets/img/icons/voicemail.svg';
import IconStart from 'assets/img/icons/play-circle.svg';
import IconSubworkflow from 'assets/img/icons/subworkflow.svg';
import IconSwitch from 'assets/img/icons/git-pull-request.svg';
import IconTimer from 'assets/img/icons/timer.svg';
import IconUserApproval from 'assets/img/icons/user-check.svg';
import IconWait from 'assets/img/icons/clock.svg';
import IconRunScript from 'assets/img/icons/voicemail.svg';
import styles from './styles.module.scss';
import IconChevronDown from 'assets/img/chevron-down-sm.svg';


@observer
export default class Tools extends React.Component {
    @observable openedCategories = []; // массив идентификаторов открытых категорий
    @observable activityTypeCategories;

    constructor(props) {
        super(props);
        this.updateOpenedCategories();
    }

    getIcon = (typeId) => {
        const activityType = this.props.activityTypes.find((elem) => (elem.sys_id === typeId));
        if (!activityType.image_url){
            switch (activityType.name) {
                case 'create-record':
                    return IconCreateRecord;
                case 'End':
                    return IconEnd;
                case 'Event':
                    return IconEvent;
                case 'Group-approval':
                    return IconGroupApproval;
                case 'If':
                    return IconIf;
                case 'Join':
                    return IconJoin;
                case 'Notification':
                    return IconNotification;
                case 'Rollback':
                    return IconRollback;
                case 'Run':
                    return IconRun;
                case 'Begin':
                    return IconStart;
                case 'Subworkflow':
                    return IconSubworkflow;
                case 'Switch':
                    return IconSwitch;
                case 'Timer':
                    return IconTimer;
                case 'User-approval':
                    return IconUserApproval;
                case 'Wait':
                    return IconWait;
                case 'Script':
                    return IconRunScript;
                case 'Run Script':
                    return IconRunScript;
                default:
                    return IconCreateRecord;
            }
        }
    };

    toggleCategory = (sys_id) => () => {
        const index = this.openedCategories.findIndex((elem) => (elem === sys_id));
        if (index !== -1) {
            this.openedCategories.splice(index, 1);
        }
        else {
            this.openedCategories.push(sys_id);
        }
    };

    onDragStart = (data) => (event) => {
        this.props.setSelected(null, null); // Для возможности позиционирования блока на связь
        event.dataTransfer.setData('text/plain', JSON.stringify(data));
    };

    updateOpenedCategories = () => {
        this.openedCategories = this.props.isFilter ? this.props.activityTypes
                                                          .map((elem) => (elem.activity_type_category_id))
                                                          .filter((elem, index, self) => (index === self.indexOf(elem)))
            : [];
        this.activityTypeCategories = this.props.isFilter
            ? this.props.activityTypeCategories.filter((elem) => (this.openedCategories.includes(elem.sys_id)))
            : this.props.activityTypeCategories;
    };

    componentDidUpdate(prevProps) {
        if (this.props.activityTypes !== prevProps.activityTypes) {
            this.updateOpenedCategories();
        }
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        return (
            <div className={ styles.ToolsPanel }>
                <div className={ styles.ToolsPanelMenu }>
                    {
                        this.activityTypeCategories
                        && this.activityTypeCategories
                               .map((item) => (
                                   <div key={ item.sys_id }>
                                       <div className={ styles.ToolsPanelMenuTitle } onClick={ this.toggleCategory(item.sys_id) }>
                                           { this.openedCategories.includes(item.sys_id) ?
                                               <div className={ styles.ArrowDown } dangerouslySetInnerHTML={ { __html: IconChevronDown } } />
                                               :
                                               <div className={ styles.ArrowRight } dangerouslySetInnerHTML={ { __html: IconArrow } } />
                                           }
                                           <span className={ styles.ToolsPanelMenuText }>{ item.name }</span>
                                       </div>
                                       { !!this.openedCategories.includes(item.sys_id) &&
                                       <div className={ styles.ToolsPanelSubmenu }>
                                           {
                                               this.props.activityTypes.map((subitem) => {
                                                   return (item.sys_id === subitem.activity_type_category_id) ?
                                                       <div
                                                           key={ subitem.sys_id }
                                                           className={ styles.ToolsPanelSubmenuItem }
                                                           draggable="true"
                                                           onDragStart={ this.onDragStart({
                                                               type_id: subitem.sys_id,
                                                               type_name: subitem.name,
                                                               essence: 'wf_activity',
                                                               table_name: subitem.activity_table_name, // активности разных типов хранятся в разных таблицах
                                                           })
                                                           }
                                                       >
                                                           { subitem.image_url ?
                                                               <img className={ styles.UserIcon } src={ subitem.image_url } /> :
                                                               <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: this.getIcon(subitem.sys_id) } } />
                                                           }
                                                           <span className={ styles.ToolsPanelSubmenuText }>{ subitem.name }</span>
                                                       </div>
                                                       : '';
                                               })
                                           }
                                       </div>
                                       }
                                   </div>
                               ))
                    }
                </div>
            </div>
        );
    }
}