import * as React from 'react';
import { observer } from 'mobx-react';
import styles from 'components/dateTimePicker/styles.module.scss';
import DatePickerCell from 'components/dateTimePicker/datePickerCell';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Строка дней календаря
 * Параметры:
 * picker - ссылка на пикер
 * days - массив дней
 * beginDate - начальная дата
 * endDate - конечная дата
 * rowIndex - индекс строки
 */
@observer
export default class DatePickerRow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { rowIndex, picker: { props: { readonly } } } = this.props;

        return (<div className={ `${styles.Row} ${readonly ? styles.readonly : ''}` }>
            { this.props.days.map((day, index) => {
                return (
                    <DatePickerCell
                        key={ index }
                        day={ day }
                        picker={ this.props.picker }
                        beginDate={ this.props.beginDate }
                        endDate={ this.props.endDate }
                        data-test={ `${ ATTRIBUTES.fieldDatePickerCell }_${ rowIndex }_${ index }` }
                    />
                );
            }) }
        </div>);
    }
}
