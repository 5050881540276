import * as React from 'react';
import { observer } from 'mobx-react';
import IconExternal from 'assets/img/icons/external-link.svg';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import tableStyles from 'components/groupedTable/styles.module.scss';
import { RefLinkProps } from 'types/components/table/refLink/index';

@observer
export default class RefLink extends React.Component<RefLinkProps> {
    renderIcon() {
        const { isOuter, url } = this.props;
        const link = isOuter ?
            <a
                href={ url }
                target="_blank"
                className={ `${ styles.Link }` }
                dangerouslySetInnerHTML={ { __html: IconExternal } }
            /> :
            <Link
                to={ url }
                className={ `${ styles.Link }` }
                dangerouslySetInnerHTML={ { __html: IconExternal } }
            />;
        return (
            <div className={ `${ styles.LinkWrap } ${ tableStyles.refIconLink }` }>
                { link }
            </div>
        );
    }

    render() {
        return (
            <div className={ styles.Text }>
                { this.props.children }
                { this.renderIcon() }
            </div>
        );
    }
}
