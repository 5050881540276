import moment from 'moment';
import styles from 'components/dateTimePicker/styles.module.scss';

export default class DatePickerDayModel {
    constructor(data, currentDate, visibleDay, beginDate, endDate) {
        this.currentDate = currentDate.clone();
        this.visibleDay = visibleDay.clone();
        this.beginDate = beginDate ? beginDate.clone() : null;
        this.endDate = endDate ? endDate.clone() : null;
        switch (true) {
            case data instanceof moment:
                this.fromMomemt(data);
                break;

            case typeof data === 'string':
                this.fromString(data);
                break;
        }
    }


    fromMomemt(date) {
        this.value = date.date();
        this.moment = date.clone();
        const currentDay = this.currentDate.startOf('day');
        const visibleDay = this.visibleDay.startOf('day');
        const presentDay = moment(new Date).startOf('day');
        let selectableClass = styles.ColSelectable;
        const beginDate = this.beginDate ? this.beginDate.startOf('day') : null;
        const endDate = this.endDate ? this.endDate.startOf('day') : null;
        if (this.beginDate && this.endDate) {
            selectableClass = date.startOf('day').isBefore(beginDate)
            || date.startOf('day').isAfter(endDate) ? styles.ColDisabled : styles.ColSelectable;
        }
        else if (this.beginDate) {
            selectableClass = date.startOf('day').isBefore(beginDate) ? styles.ColDisabled : styles.ColSelectable;
        }
        else if (this.endDate) {
            selectableClass = date.startOf('day').isAfter(endDate) ? styles.ColDisabled : styles.ColSelectable;
        }


        this.className = selectableClass;
        this.className += date.startOf('day').isSame(currentDay) ? ' ' + styles.ColCurrent : '';
        this.className += date.startOf('day').isSame(presentDay) ? ' ' + styles.ColPresent : '';
        this.className += date.startOf('day').isSame(visibleDay, 'month') ? '' : ' ' + styles.ColOtherMonth;
    }


    fromString(string) {
        this.value = string;
    }
}
