export const ATTRIBUTES = {
    loginButton: 'login-button',
    loginField: 'login-field',
    loginSelectLang: 'login-select-lang',
    loginSelectLangButton: 'login-select-lang-button',
    loginMenuItem: 'login-menu-item',
    linkResetPassword: 'link-reset-password',
    linkSignUp: 'link-sign-up',
    signUpButton: 'sign-up-button',
    welcomeText: 'welcome-text',
    helpElement: 'help-element',
    helpClickElement: 'help-click-element',
    helpText: 'help-text',
    loginError: 'login-error',
    listNewButton: 'list-new-button',
    quickReturnButton: 'quick-return-button',

    // header
    header: 'header',
    headerBurgerIcon: 'header-burger-icon',
    headerLogo: 'header-logo',
    headerOptions: 'header-options',
    headerOptionItem: 'header-option-item',
    headerOptionUserItem: 'header-option-item-user',
    userProfileItem: 'user-profile-item',
    showUserImpersonate: 'show-user-impersonate',
    impersonateList: 'impersonate-list',
    showUserElevate: 'show-user-elevate',
    userLogout: 'user-logout',
    settingGear: 'setting-gear',
    listBoxMenu: 'list-box-menu',
    listBoxField: 'list-box-field',
    listBoxClose: 'list-box-close',
    listBoxClearCache: 'list-box-clear-cache',
    listBoxSection: 'list-box-section',
    listBoxClearCacheForAll: 'list-box-clear-cache-for-all',
    headerMenuLang: 'header-menu-lang',
    headerLang: 'header-lang',
    activeHeaderLang: 'active-header-lang',
    backToMe: 'back-to-me',

    // drag-and-drop
    dropZoneTitle: 'drop-zone-title',
    attachTitle: 'attach-title',
    attachFileName: 'attach-file-name',
    attachCount: 'attach-count',
    newAttach: 'new-attach',
    attachSelectAll: 'attach-select-all',
    attachDownloadAll: 'attach-download-all',
    attachUnselected: 'attach-unselected',
    attachDeleteSelected: 'attach-delete-selected',
    attachDownloadSelected: 'attach-download-selected',
    attachUploadedFilesContainer: 'attach-uploaded-files-container',
    attachUploadedFileContent: 'attach-uploaded-file-content',
    attachUploadedFileCheckbox: 'attach-uploaded-file-checkbox',
    attachUploadedFileDownloadIcon: 'attach-uploaded-file-download-icon',
    attachUploadedFileMenuIcon: 'attach-uploaded-file-menu-icon',
    attachUploadedFileMenuChoose: 'attach-uploaded-file-menu-choose',
    attachUploadedFileMenuOpen: 'attach-uploaded-file-menu-open',
    attachUploadedFileMenuDownload: 'attach-uploaded-file-menu-download',
    attachUploadedFileMenuDelete: 'attach-uploaded-file-menu-delete',
    attachOpenBtn: 'attach-open-button',
    attachUploadBtn: 'attach-upload-button',
    dragAndDropClose: 'drag-and-drop-close',

    //subFormModal
    subFormAttachIcon: 'sub-form-attach-icon',
    subFormCancelButton: 'sub-form-cancel-button',
    subFormSaveButton: 'sub-form-save-button',
    subFormTitle: 'sub-form-title',
    subForm: 'sub-form',
    subFormHeader2: 'sub-form-header-2',
    subFormMobileHeader2: 'sub-form-mobile-header-2',

    // burger menu
    buttonBurgerMenu: 'button-burger-menu',
    buttonRelatedListsBurgerMenu: 'button-related-lists-burger-menu',
    burgerMenuItem: 'burger-menu-item',
    burgerSubMenuItem: 'burger-sub-menu-item',

    // Классы для элементов layout editor
    layoutEditorAvailableSelect: 'layout-editor-available-select',
    layoutEditorAvailableSelectSearch: 'layout-editor-available-select-search',
    layoutEditorToAvailableButton: 'layout-editor-to-available-button',
    layoutEditorToSelectedButton: 'layout-editor-to-selected-button',
    layoutEditorToAvailableAllButton: 'layout-editor-to-available-all-button',
    layoutEditorToSelectedAllButton: 'layout-editor-to-selected-all-button',
    layoutEditorSelectedSelect: 'layout-editor-selected-select',
    layoutEditorSelectedItemUpButton: 'layout-editor-selected-item-up-button',
    layoutEditorSelectedItemDownButton: 'layout-editor-selected-item-down-button',
    layoutEditorSelectedBeginSplitButton: 'layout-editor-selected-begin-split-button',
    layoutEditorSelectedSplitButton: 'layout-editor-selected-split-button',
    layoutEditorSelectedEndSplitButton: 'layout-editor-selected-and-split-button',
    layoutEditorAvailableItem: 'layout-editor-available-item',
    layoutEditorSelectedItem: 'layour-editor-selected-item',

    // Кнопки на странице list layout
    listlayoutSaveButton: 'list-layout-save-button',
    listlayoutCancelButton: 'list-layout-cancel-button',
    listlayoutResetToDefaultButton: 'list-layout-reset-to-default-button',

    // table classes
    tableHeader: 'table-header',
    tableHeaderCell: 'table-header-cell',
    tableSettingsIcon: 'table-settings-icon',
    tableSearchIcon: 'table-search-icon',
    tableCell: 'table-cell',
    tableCellDescr: 'table-cell-descr',
    tableCellEditor: 'table-cell-editor',

    // report
    reportButtonNext: 'report-button-next',
    reportButtonBack: 'report-button-back',
    reportButtonRefresh: 'report-button-refresh',
    reportType: 'report-type',
    reportSettings: 'report-settings',
    reportChart: 'report-chart',
    reportButtonSave: 'report-button-save',
    reportFieldReportName: 'report-name-field-string',
    reportFieldTable: 'table-field-reference',
    reportViewTableName: 'report-view-table-name',

    // fields
    labelField: 'label',
    field: 'field',
    fieldReference: 'field-reference',
    fieldReferenceSuggest: 'suggest-dropdown-reference',
    fieldString: 'field-string',
    fieldList: 'field-list',
    fieldCheckbox: 'field-checkbox',
    fieldHtml: 'field-html',
    fieldRichText: 'field-rich-text',
    fieldDateInput: 'field-date-input',
    fieldTextArea: 'field-textarea',
    fieldColor: 'field-color',
    fieldScript: 'field-script',
    fieldCondition: 'field-condition',
    fieldSelect: 'field-select',
    form: 'form',
    fieldName: 'field-name',
    fieldDaysOfWeek: 'field-days-of-week',
    fieldUrl: 'field-url',
    fieldDocumentId: 'field-document-id',
    fieldDuration: 'field-duration',
    fieldTemplate: 'field-template',
    fieldImage: 'field-image',
    fieldOptionsInput: 'field-options-input',

    maskedInput: 'masked-input',
    maskedInputTab: 'masked-input-tab',

    clearFieldButton: 'clear-field-button',
    optionSelectButton: 'option-select-button',

    customSelectButton: 'custom-select-button',
    customSelectDropdownItem: 'custom-select-dropdown-item',
    customSelectDropdownMenu: 'custom-select-dropdown-menu',

    fieldReferenceSearchButton: 'reference-search-button',
    fieldReferencePlusButton: 'reference-plus-button',
    fieldReferenceDependencyMapLinkButton: 'reference-dependency-map-link-button',

    fieldListSearchButton: 'list-search-button',
    fieldListClearButton: 'list-clear-button',

    badgeEye: 'badge-eye',
    badgeDelegationIcon: 'badge-delegation-icon',


    splitCol: 'split-col',
    splitRow: 'split-row',
    splitHeading: 'split-heading',

    // suggest
    suggestDropdownItem: 'suggest-dropdown-item',

    // condition builder
    conditionFilter: 'condition-filter',
    conditionButtonAnd: 'condition-button-and',
    conditionButtonOr: 'condition-button-or',
    conditionButtonSort: 'condition-button-sort',
    conditionButtonGroup: 'condition-button-group',
    conditionButtonClearAll: 'condition-button-clear-all',
    conditionButtonRun: 'condition-button-run',
    conditionButtonClose: 'condition-button-close',
    conditionFilteringRow: 'condition-filtering-row',
    conditionSortingRow: 'condition-sorting-row',
    conditionGroupingRow: 'condition-grouping-row',
    conditionRowButtonRemove: 'condition-row-button-remove',
    conditionRowSortButtonRemove: 'condition-row-sort-button-remove',
    conditionRowGroupButtonRemove: 'condition-row-group-button-remove',
    conditionRowField: 'condition-row-field',
    conditionRowOperator: 'condition-field-operator',
    conditionRowValue: 'condition-field-value',
    conditionRowButtonAnd: 'condition-row-button-and',
    conditionRowButtonOr: 'condition-row-button-or',
    conditionRowSortField: 'condition-row-sort-field',
    conditionRowGroupField: 'condition-row-group-field',
    conditionRowDirection: 'condition-row-direction',
    conditionFieldLi: 'condition-field-li',
    conditionFieldSearchInput: 'condition-field-search-input',
    conditionFieldBackButton: 'condition-field-back-button',
    conditionFieldFilteringOptions: 'condition-field-filtering-options',
    conditionFieldSortingOptions: 'condition-field-sorting-options',
    conditionFieldGroupingOptions: 'condition-field-grouping-options',
    conditionFilterIconButton: 'condition-filter-button-icon',
    conditionFilterAllBreadcrumb: 'condition-filter-all-breadcrumb',
    conditionFilterFilteringBreadcrumb: 'condition-filter-filtering-breadcrumb',
    conditionFilterSortingBreadcrumb: 'condition-filter-sorting-breadcrumb',
    conditionFilterGroupingBreadcrumb: 'condition-filter-grouping-breadcrumb',
    conditionSavedFilters: 'condition-saved-filters',
    conditionSaveNewFilterButton: 'condition-save-new-filter-button',
    conditionFilteringRows: 'condition-filtering-rows',
    conditionSortingRows: 'condition-sorting-rows',
    conditionGroupingRows: 'condition-grouping-rows',
    conditionField: 'condition-row-field',


    // message
    message: 'message',
    messageContentText: 'message-content-text',
    messageType: 'message-type',

    fieldMessage: 'field-message',

    // ui-actions
    uiButton: 'ui-button',
    uiLink: 'ui-link',

    // Related Lists
    relatedListTab: 'related-list-tab',

    // sidebar
    sidebarMenuCategory: 'sidebar-menu-category',
    sidebarMenuItem: 'sidebar-menu-item',
    sidebarMenuSubItem: 'sidebar-menu-sub-item',
    sidebarSearchInput: 'sidebar-search-input',
    sidebarSearchClean: 'sidebar-search-clean',
    sidebar: 'sidebar',
    sidebarBlock: 'sidebar-block',
    sidebarMenuIcon: 'sidebar-menu-icon',
    sidebarMenuIconMobile: 'sidebar-menu-icon-mobile',
    sidebarFavoritesIcon: 'sidebar-favorites-icon',
    sidebarFavoritesIconMobile: 'sidebar-favorites-icon-mobile',
    sidebarMenu: 'sidebar-menu',
    sidebarLinkFavorites: 'sidebar-link-favorites',
    sidebarLinkEdit: 'sidebar-link-edit',
    sidebarMenuItemLinks: 'sidebar-menu-item-links',
    sidebarMenuSubItemLinks: 'sidebar-menu-sub-item-links',
    sidebarSubLinkFavorites: 'sidebar-sub-link-favorites',
    sidebarSubLinkEdit: 'sidebar-sub-link-edit',
    sidebarMenuTab: 'sidebar-menu-tab',
    sidebarFavoriteTab: 'sidebar-favorite-tab',
    sidebarPinButton: 'sidebar-pin-button',
    sidebarMobileLogo: 'sidebar-mobile-logo',
    sidebarMobileClose: 'sidebar-mobile-close',
    sidebarDynamicHeader: 'sidebar-dynamic-header',
    sidebarDynamicHeaderTitle: 'sidebar-dynamic-header-title',
    sidebarDynamicIconBack: 'sidebar-dynamic-icon-back',
    sidebarMenuNoResults: 'sidebar-menu-no-results',
    sidebarDynamicNoResults: 'sidebar-dynamic-no-results',
    sidebarDynamicConfigure: 'sidebar-dynamic-configure',
    sidebarMenuLink: 'sidebar-menu-link',
    sidebarArrowContainer: 'sidebar-arrow-container',
    sidebarDynamicIcon: 'sidebar-dynamic-icon',
    sidebarDotIcon: 'sidebar-dot-icon',
    sidebarDefaultCategoryIcon: 'sidebar-default-category-icon',
    sidebarMenuItemIcon: 'sidebar-menu-item-icon',
    sidebarMenuLinkLabel: 'sidebar-menu-link-label',
    sidebarMenuLinkCategory: 'sidebar-menu-link-category',
    sidebarMenuLinkItem: 'sidebar-menu-link-item',
    sidebarCategoryLink: 'sidebar-category-link',
    sidebarCategoryLinkLabel: 'sidebar-category-link-label',
    sidebarCategoryDynamicLink: 'sidebar-category-dynamic-link',
    sidebarItem: 'sidebar-item',

    /**
     * Классы для таблицы
     */
    table: 'table',
    // классы переключения страниц
    tablePagintaionTotalItems: 'table-pagination-total-items',
    tablePagintaionInput: 'table-pagination-input',
    tablePaginationPrevPage: 'table-pagination-prev-page',
    tablePaginationNextPage: 'table-pagination-next-page',
    tablePaginationToFirstPage: 'table-pagination-to-first-page',
    tablePaginationToLastPage: 'table-pagination-to-last-page',
    tablePaginationTotalPages: 'table-pagination-total-pages',

    // table body classes
    tableBody: 'table-body',
    tableRow: 'table-row',
    tableMetaCell: 'table-meta-cell',

    // context menu
    contextMenu: 'context-menu',
    contextMenuItem: 'context-menu-item',

    // info messages
    infoMessages: 'info-messages',
    infoMessageItem: 'info-message-item',

    // Блок view на страницах form-layout и list-layout
    layoutView: 'layout-view',
    layoutViewTitle: 'layout-view-title',
    layoutViewSelect: 'layout-view-select',
    layoutViewAddNewButton: 'layout-view-add-new-button',
    layoutViewLabel: 'layout-view-label',
    layoutViewModal: 'layout-view-modal',
    layoutViewModalTitle: 'layout-view-modal-title',
    layoutViewModalLabel: 'layout-view-modal-label',
    layoutViewModalInput: 'layout-view-modal-input',
    layoutViewModalCancelBtn: 'layout-view-modal-cancel-btn',
    layoutViewModalAddBtn: 'layout-view-modal-add-btn',


    // Блок section на странице form-layout
    layoutSectionSelect: 'layout-section-select',
    layoutSectionSelectItem: 'layout-section-select-item',
    layoutSectionAddNewButton: 'layout-section-add-new-button',
    layoutSectionUpButton: 'layout-section-up-button',
    layoutSectionDownButton: 'layout-section-down-button',
    layoutSectionInput: 'layout-section-input',
    layoutSectionCancelButton: 'layout-section-cancel-button',
    layoutSectionCreateButton: 'layout-section-create-button',
    layoutSectionRemoveButton: 'layout-section-remove-button',
    layoutSectionRemoveConfirmButton: 'layout-section-remove-confirm-button',

    // Кнопки на странице form layout
    formlayoutTitle: 'form-layout-title',
    formlayoutSaveButton: 'form-layout-save-button',
    formlayoutCancelButton: 'form-layout-cancel-button',

    /**
     * Классы для различных типов полей
     */
    // dateTimeInput
    fieldDateTimePicker: 'date-picker',
    fieldDateTimeButton: 'field-date-time-button',
    fieldDatePickerTimeInputHour: 'date-picker-time-input-hour',
    fieldDatePickerTimeInputMinute: 'date-picker-time-input-minute',
    fieldDatePickerTimeInputSecond: 'date-picker-time-input-second',
    fieldDatePickerToToday: 'date-picker-to-today',
    fieldDatePickerApplyButton: 'date-picker-apply-button',
    fieldDatePickerHeader: 'date-picker-header',
    fieldDatePickerPrevMonth: 'date-picker-prev-month',
    fieldDatePickerNextMonth: 'date-picker-next-month',
    fieldDatePickerHeaderMonths: 'date-picker-header-months',
    fieldDatePickerHeaderMonthItem: 'date-picker-header-month-item',
    fieldDatePickerHeaderYears: 'date-picker-header-years',
    fieldDatePickerHeaderYearItem: 'date-picker-header-year-item',
    fieldDatePickerHeaderMonthsPopover: 'date-picker-header-months-popover',
    fieldDatePickerHeaderYearsPopover: 'date-picker-header-years-popover',
    fieldDatePickerCell: 'date-picker-cell',

    // portals and widgets
    portalContainer: 'portal-container',
    portalRow: 'portal-row',
    portalColumn: 'portal-column',
    widget: 'widget',
    sideMenuItem: 'side-menu-item',
    sideMenuSubItem: 'side-menu-sub-item',
    sideMenuItemLink: 'side-menu-item-link',
    sideMenuItemIconExpand: 'side-menu-item-icon-expand',
    durationInput: 'duration-input',
    durationInputItem: 'duration-input-item',

    // activity feed
    activityFeedWindow: 'activity-feed-window',
    activityFeed: 'activity-feed',
    activityFeedHead: 'activity-feed-head',
    activityFeedHeadH4: 'activity-feed-head-h4',
    activityFeedWorkNote: 'activity-feed-work-note',
    activityFeedMenuSettings: 'activity-feed-menu-settings',
    activityFeedTypesSettings: 'activity-feed-types-settings',
    activityFeedHistoriesSettings: 'activity-feed-histories-settings',
    activityFeedCommentTabs: 'activity-feed-comment-tabs',
    activityFeedCommentTab: 'activity-feed-comment-tab',
    activityFeedCommentBlock: 'activity-feed-comment-block',
    activityFeedCommentText: 'activity-feed-comment-text',
    activityFeedCommentSendButton: 'activity-feed-comment-send-button',
    activityFeedChangeSettings: 'activity-feed-change-settings',
    activityFeedCloseWindow: 'activity-feed-close-window',
    activityFeedTypeTab: 'activity-feed-type-tab',
    activityFeedToggleSettings: 'activity-feed-toggle-settings',
    activityFeedToggleShowAll: 'activity-feed-toggle-show-all',
    activityFeedSendCommentButton: 'activity-feed-send-comment-button',
    activityFeedToggleShowAllNewText: 'activity-feed-toggle-show-all-new-text',
    activityFeedToggleShowAllOldText: 'activity-feed-toggle-show-all-old-text',
    activityFeedHistoryItem: 'activity-feed-history-item',
    activityFeedHistoryItemNewTitle: 'activity-feed-history-item-new-title',
    activityFeedHistoryItemNewText: 'activity-feed-history-item-new-text',
    activityFeedHistoryItemOldTitle: 'activity-feed-history-item-old-title',
    activityFeedHistoryItemOldText: 'activity-feed-history-item-old-text',
    activityFeedHistoryToggleShowAll: 'activity-feed-history-toggle-show-all',
    activityFeedHistoryItemTitle: 'activity-feed-history-item-title',
    activityFeedItemAuthor: 'activity-feed-item-author',
    activityFeedItemDate: 'activity-feed-item-date',
    activityFeedCommentItem: 'activity-feed-comment-item',
    activityFeedCommentItemTitle: 'activity-feed-comment-item-title',
    activityFeedCommentItemAuthor: 'activity-feed-comment-item-author',
    activityFeedCommentItemText: 'activity-feed-comment-item-text',
    activityFeedItem: 'activity-feed-item',
    activityFeedItemComment: 'activity-feed-item-comment',
    activityFeedItemHistory: 'activity-feed-item-history',
    activityFeedAvatar: 'activity-feed-avatar',
    activityFeedItemTitle: 'activity-feed-item-title',
    activityFeedCommentDate: 'activity-feed-comment-date',
    activityFeedHistorySettingsSearch: 'activity-feed-history-settings-search',
    activityFeedGroupHeader: 'activity-feed-group-header',
    activityFeedChevron: 'activity-feed-chevron',
    activityFeedActivitiesContainer: 'activity-feed-activities-container',
    activityFeedItemCommentBlock: 'activity-feed-item-comment-block',

    // tabs
    tabs: 'tabs',
    tab: 'tab',
    tabsSlider: 'tabs-slider',
    tabsSliderRight: 'tabs-slider-right',
    tabsSliderLeft: 'tabs-slider-left',
    tabsSliderList: 'tabs-slider-list',
    tabHead: 'tab-head',

    // Activities
    activities: 'activities',
    activitiesItem: 'activities-item',
    activitiesLabel: 'activities-label',
    activitiesToggleButton: 'activities-toggle-button',
    activitiesItemUserName: 'activities-item-user-name',
    activitiesItemInfo: 'activities-item-info',
    activitiesItemBreakWord: 'activities-item-break-word',
    activitiesItemTable: 'activities-item-table',
    activitiesItemTableRow: 'activities-item-table-row',
    activitiesItemTableColumn: 'activities-item-table-column',

    // dropdown
    dropdown: 'dropdown',

    // favorites
    favoriteItem: 'favorite-item',
    favoriteItemLink: 'favorite-item-link',
    favoriteItemDelete: 'favorite-item-delete',
    favoriteSubItem: 'favorite-sub-item',
    favoriteSubItemLink: 'favorite-sub-item-link',

    //Ldap
    ldapLink: 'ldap-link',
    ldapRow: 'ldap-row',
    ldapName: 'ldap-name',
    ldapValue: 'ldap-value',

    //modal
    modalHead: 'modal-head',
    modalTitle: 'modal-title',
    modalWindow: 'modal-window',
    modalBody: 'modal-body',
    modalCloseButton: 'modal-close-button',

    //taskBoard
    taskBoardLanes: 'task-board-lanes',
    taskNewButton: 'task-new-button',
    taskBoardLane: 'task-board-lane',
    taskBoardLaneHeader: 'task-board-lane-header',
    taskBoardItem: 'task-board-item',
    taskBoardItemOpenedAt: 'task-board-item-opened-at',
    taskBoardItemDescription: 'task-board-item-description',
    taskBoardItemUsers: 'task-board-item-users',

    // Heading Title
    headingTitle: 'heading-title',

    // tooltip
    tooltip: 'tooltip',

    //hint
    hint: 'hint',

    // script info and result
    scriptResult: 'script-result',
    scriptInfo: 'script-info',

    //popup
    popup: 'popup',
    popupText: 'popup-text',
    popupFieldName: 'popup-field-name',
    popupFieldContent:  'popup-field-content',

    //delegation
    popupDelegationContainer: 'popup-delegation-container',
    popupDelegationTitleContainer: 'popup-delegation-title-container',
    popupDelegationIcon: 'popup-delegation-icon',
    popupDelegationUsers: 'popup-delegation-users',
    popupDelegationUserLink: 'popup-delegation-user-link',

    // elevatePopup
    elevatePopupHeader: 'elevate-popup-header',
    elevatePopupContent: 'elevate-popup-content',
    elevatePopupFooter: 'elevate-popup-footer',

    quickMessage: 'quick-message',

    // preview
    previewRecordButton: 'preview-record-button',

    //error page
    errorScreen: 'error-screen',
    errorTitle: 'error-title',
    errorLink: 'error-link',

    //search
    searchHeaderIcon: 'search-header-icon',
    searchHeaderIconClean: 'search-header-icon-clean',
    searchHeaderInput: 'search-header-input',
    searchWidgetIcon: 'search-widget-icon',
    searchWidgetIconClean: 'search-widget-icon-clean',
    searchWidgetSummary: 'search-widget-summary',
    searchWidgetHeaderText: 'search-widget-header-text',
    searchRecord: 'search-record',
    searchRecordTitle: 'search-record-title',
    searchRecordLink: 'search-record-link',
    searchDynamicField: 'search-dynamic-field',
    searchDynamicFieldValue: 'search-dynamic-field-value',
    searchDynamicMatchValue: 'search-dynamic-match-value',
    searchStaticField: 'search-static-field',
    searchStaticFieldName: 'search-static-field-name',
    searchStaticFieldValue: 'search-static-field-value',
    searchLoadMore: 'search-load-more',
    searchNoResults: 'search-no-results',
    searchNotFound: 'search-not-found',
    searchAllResults: 'search-all-results',
    searchCategory: 'search-category',
    searchCategoryItem: 'search-category-item',
    searchCategoryLink: 'search-category-link',
    searchCategoryArrow: 'search-category-arrow',
    searchCategoryName: 'search-category-name',
    searchSubCategory: 'search-sub-category',
    searchLargeInput: 'search-large-input',
    searchLargeInputIcon: 'search-large-input-icon',
    searchLargeInputCloseIcon: 'search-large-input-close-icon',
    searchGroupsResult: 'search-groups-result',
    searchGroupResultTitle: 'search-group-result-title',
    searchGroupResultItem: 'search-group-result-item',

    // textEditor
    editorToggleIcon: 'editor-toggle-icon',
    editorImageIcon: 'editor-image-icon',
    editorLinkIcon: 'editor-link-icon',
    editorBoldIcon: 'editor-bold-icon',
    editorItalicIcon: 'editor-italic-icon',
    editorUnderlineIcon: 'editor-underline-icon',
    editorStrikethroughIcon: 'editor-strikethrough-icon',
    editorBulletListIcon: 'editor-bullet-list-icon',
    editorNumberedListIcon: 'editor-number-list-icon',
    editorQuoteIcon: 'editor-quote-icon',
    editorLinkInput: 'editor-link-input',
    editorInput: 'editor-input',

};
