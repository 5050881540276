import _ from 'lodash';

export const isEqual = (first, second) => {
    return JSON.stringify(first) === JSON.stringify(second);
};

// рандомная 18буквенная строка для индификации нового виджета
export const generateRandomString = () => {
    return Math.random().toString(36).substring(2, 11) + Math.random().toString(36).substring(2, 11);
};

export const parseToJson = (data) => {
    return JSON.parse(JSON.stringify(data));
};

export const getUrlParams = (search) => {
    const searchParams = new URLSearchParams(search);
    return parseUrlParams(searchParams);
};

export const parseUrlParams = (searchParams) => {
    const obj = {};
    const entries = searchParams.entries();
    for (const pair of entries) {
        obj[pair[0].replace(/(\n)/gm,"")] = pair[1];
    }
    if (_.isEmpty(entries)) { // в связи с вводом typescript. там поменялись методы, entries не работает
        searchParams.forEach((value, key) => {
            obj[key.replace(/(\n)/gm,"")] = value;
        });
    }
    return obj;
};

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
};

export const paramsToString = (params) => {
    if (Object.keys(params).length === 0) {
        return '';
    }
    const esc = encodeURIComponent;
    const queries = [];
    Object.keys(params)
          .forEach(k => {
              if (params[k]) {
                  queries.push(esc(k) + '=' + esc(params[k]));
              }
          });
    const query = queries.join('&');
    return '?' + query;
};

export const isEmpty = (value) => {
    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    return !value;
};

export const copyToClipboard = (function initClipboardText() {
    const textarea = document.createElement('textarea');

    // Move it off screen.
    textarea.style.cssText = 'position: absolute; right: 100%; bottom: 100%;';

    // Set to readonly to prevent mobile devices opening a keyboard when
    // text is .select()'ed.
    textarea.setAttribute('readonly', true);

    document.body.appendChild(textarea);

    return function setClipboardText(text) {
        textarea.value = text;

        // Check if there is any content selected previously.
        const selected = document.getSelection().rangeCount > 0 ?
            document.getSelection().getRangeAt(0) : false;

        // iOS Safari blocks programmtic execCommand copying normally, without this hack.
        // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            const editable = textarea.contentEditable;
            textarea.contentEditable = true;
            const range = document.createRange();
            range.selectNodeContents(textarea);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            textarea.setSelectionRange(0, 999999);
            textarea.contentEditable = editable;
        } else {
            textarea.select();
        }

        try {
            const result = document.execCommand('copy');

            // Restore previous selection.
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
                document.body.removeChild(textarea);
            }

            return result;
        } catch (err) {
            return false;
        }
    };
})();

export const getTestNameField = (model) => {
    if (model.sysColumnName) {
        return model.sysColumnName.toLowerCase().replace(/\s/g, '-');
    }
    if (model.name) {
        return model.name.toLowerCase().replace(/\s/g, '-');
    }
    return 'unknown';
};

