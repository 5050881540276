import * as React from 'react';
import styles from './styles.module.scss';
import * as _ from 'lodash';
// import IconMoreVertical from 'assets/img/icons/more-vertical-kebab.svg';
import IconPlus from 'assets/img/icons/plus.svg';
import Button from 'components/button';
import TaskBoardItem from 'components/taskBoard/taskBoardItem';
import DragAndDrop from 'components/taskBoard/dragAndDrop';
import DroppableWrapper from 'components/taskBoard/droppable';
import TaskBoardLanesState from 'components/taskBoard/taskBoardLanes/state';
import { observer } from 'mobx-react';
import DragAndDropState from 'components/taskBoard/dragAndDrop/state';
import PageLoader from 'components/pageLoader';
import TaskBoardCardModal from 'components/taskBoard/taskBoardCardModal';
import { observable } from 'mobx';
import Tooltip from 'components/tooltip';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Компонент Столбцы доски
 * @param {Object[]} cards - карточки
 * @param {string} boardId
 * @param {string} tableName
 * @param {boolean} loadingCards
 * @param {string} viewName
 */
@observer
export default class TaskBoardLanes extends React.Component {
    @observable isShowModal = false;
    @observable isShowPopup = false;
    cardButtonCloseRefs = [];
    cardButtonCloseRef;
    timeout = null;

    checkAccess = (key) => () => {
        const { canCreate } = this.props;
        if (!canCreate) {
            this.cardButtonCloseRef = this.cardButtonCloseRefs[key];
            this.showPopup();
            return;
        }
        this.handleToggleNewCard();
    };

    handleToggleNewCard = () => {
        this.isShowModal = !this.isShowModal;
    };

    handleClickMore = () => {
        //todo
    };

    handleClickPlus = () => {
        //todo
    };

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };

    renderCardModal = () => {
        const { boardId, tableName, viewName } = this.props;
        return (
            <TaskBoardCardModal
                isShow={ this.isShowModal }
                doClose={ this.handleToggleNewCard }
                recordId=''
                tableName={ tableName }
                boardId={ boardId }
                viewName={ viewName }
                cardId=''
                laneId=''
            />
        );
    };

    renderBoardItems = (lane, laneCards) => {
        const { boardId, tableName, loadingCards, viewName, canUpdate, canCreate } = this.props;
        if (loadingCards) {
            return (
                <PageLoader />
            );
        }
        if (_.isEmpty(laneCards)) {
            return (
                <DroppableWrapper
                    key={ `empty${ lane.sys_id }` }
                    type='card'
                    sysId={ `emptyLane${ lane.sys_id }0` }
                    order={ 0 }
                    categoryId={ lane.sys_id }
                >
                    <TaskBoardItem
                        key={ `empty${ lane.sys_id }` }
                        boardId={ boardId }
                        tableName={ tableName }
                        viewName={ viewName }
                        canUpdate={ canUpdate }
                        canCreate={ canCreate }
                        isEmpty
                    />
                </DroppableWrapper>
            );
        }
        let lastOrder = 0;
        const cards = _.map(laneCards, card => {
            lastOrder = card.order;
            return (
                <DroppableWrapper
                    key={ card.sys_id }
                    type='card'
                    sysId={ card.sys_id }
                    order={ card.order }
                    categoryId={ lane.sys_id }
                >
                    <TaskBoardItem
                        key={ `${ card.sys_id }${ card.order }` }
                        card={ card }
                        categoryId={ lane.sys_id }
                        boardId={ boardId }
                        tableName={ card.table_name }
                        viewName={ viewName }
                        canUpdate={ canUpdate }
                        canCreate={ canCreate }
                    />
                </DroppableWrapper>
            );
        });
        return (
            <>
                { cards }
                <DroppableWrapper
                    key={ `empty${ lane.sys_id }` }
                    type='card'
                    sysId={ `emptyCard${ lane.sys_id }${ lastOrder + 1 }` }
                    order={ lastOrder + 1 }
                    categoryId={ lane.sys_id }
                >
                    <div style={ { height: '40px' } } />
                </DroppableWrapper>
            </>
        );
    };

    renderLanes = () => {
        const { canCreate } = this.props;
        const lanes = TaskBoardLanesState.getLanes();
        return _.map(lanes, (lane, key) => {
            const laneCards = TaskBoardLanesState.getCards(lane.sys_id) || [];
            const enterId = DragAndDropState.getEnterId();
            let laneLength = laneCards.length;
            if (enterId.includes('emptyLane') && enterId.includes(lane.sys_id)) {
                laneLength += 1;
            }
            return (
                <DroppableWrapper
                    key={ lane.sys_id }
                    type='lane'
                    sysId={ lane.sys_id }
                    order={ lane.order }
                >
                    <DragAndDrop
                        type='lane'
                        sysId={ lane.sys_id }
                        className={ `${ styles.Lane } ${ enterId === `lane${ lane.sys_id }` ? styles.LaneOnEnter : '' }` }
                        order={ lane.order }
                        data-test={ `${ lane.name.toLowerCase() }-${ ATTRIBUTES.taskBoardLane }` }
                    >
                        <div
                            className={ styles.LaneHeader }
                            data-test={ ATTRIBUTES.taskBoardLaneHeader }
                        >
                            <div className={ styles.LineTitleBlock }>
                                <div className={ styles.LineTitle }>
                                    { lane.name }
                                </div>
                                <div className={ styles.LineCount }>
                                    { laneLength }
                                </div>
                            </div>
                            <div className={ styles.LineButtonsBlock } ref={ ref => (this.cardButtonCloseRefs[key] = ref) }>
                                <Button
                                    className={ `${ canCreate ? styles.LaneButton : styles.DisabledLineButton } ` }
                                    data-test={ ATTRIBUTES.taskNewButton }
                                    buttonType='icon'
                                    svg={ IconPlus }
                                    onClick={ this.checkAccess(key) }

                                />
                                {/*<Button
                                 className={ styles.LaneButton }
                                 buttonType='icon'
                                 svg={ IconMoreVertical }
                                 onClick={ this.handleClickMore }
                                 />*/ }
                            </div>
                        </div>
                        <div className={ styles.LaneCards }>
                            { this.renderBoardItems(lane, laneCards) }
                        </div>
                    </DragAndDrop>
                </DroppableWrapper>
            );
        });
    };

    render() {
        const { task_board = {} } = langStore.getTranslate();

        return (
            <div className={ styles.Lanes } data-test={ ATTRIBUTES.taskBoardLanes }>
                { this.renderLanes() }
                { this.renderCardModal() }
                {
                    this.isShowPopup && (
                        <Tooltip
                            onClose={ this.hidePopup }
                            parent={ this.cardButtonCloseRef }
                        >
                            { task_board.create_record_no_access }
                        </Tooltip>
                    ) }
            </div>
        );
    }
}
