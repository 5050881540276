import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import { helperRedirect } from 'helpers/history';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import Button from 'components/button/index';
import styles from 'components/groupedTable/styles.module.scss';
import IconChevronR from 'assets/img/icons/chevron-right.svg';
import IconChevronDoubleR from 'assets/img/icons/chevrons-right.svg';
import IconChevronL from 'assets/img/icons/chevron-left.svg';
import IconChevronDoubleL from 'assets/img/icons/chevrons-left.svg';
import langStore from 'globalState/lang/index';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { PaginationProps } from 'types/components/table/pagination/index';
import GlobalPortal from 'components/globalPortal/index';


@observer
export default class Pagination extends React.Component<PaginationProps> {
    @observable maxPage;
    @observable value;
    @observable isDisablePageCalculation;
    refInput: React.RefObject<HTMLDivElement> = React.createRef();
    refInputContainer: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props) {
        super(props);
        this.calcParams();
        this.value = this.props.pagination.page;

        reaction(
            () => this.value,
            () => {
                this.setInputWidth();
            },
        );
    }

    componentDidMount() {
        this.setInputWidth();
    }

    componentDidUpdate(prevProps) {
        if (this.props.pagination.page !== prevProps.pagination.page) {
            this.value = this.props.pagination.page;
        }
        this.calcParams();
    }

    calcParams() {
        this.maxPage = Math.ceil(this.props.pagination.total / this.props.pagination.per_page);
        this.isDisablePageCalculation = !this.props.pagination.enabled_pages_calculation && this.props.pagination.total < 0;
    }

    toPrev = () => this.toPage(this.props.pagination.page - 1);
    toNext = () => this.toPage(this.props.pagination.page + 1);
    toFirst = () => this.toPage(1);
    toLast = () => this.toPage(this.maxPage);

    onChange = ({ value }) => {
        this.value = value;
    };

    changePage = (e) => {
        if (e.keyCode === 13) {
            this.toPage(this.value);
        }
    };

    toPage = (page) => {
        const { onChangePage } = this.props;
        if (page < 1) page = 1;

        if (!this.isDisablePageCalculation) {
            if (page > this.maxPage) page = this.maxPage;
        }

        if (onChangePage) {
            onChangePage(page);
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', page);
        helperRedirect({
            search: searchParams.toString(),
        });
    };

    setInputWidth = () => {
        const inputMaxWidth = 68;
        const inputEl = this.refInput ? this.refInput.current ? this.refInput.current : null : null;
        const containerEl = this.refInputContainer ? this.refInputContainer.current ? this.refInputContainer.current : null : null;

        if (!inputEl || !containerEl) return;

        containerEl.innerHTML = this.value.toString();
        const containerWidth = containerEl.getBoundingClientRect().width;
        const inputWidth = containerWidth > inputMaxWidth ? inputMaxWidth : containerWidth;
        inputEl.style.width = `${ inputWidth }px`;
    };

    render() {
        const { isShowDisabled, pagination, isMobile, isServicePortal } = this.props;
        const { list_titles, system_buttons_hints } = langStore.getTranslate();
        if (!isShowDisabled && (!pagination.per_page || (pagination.total < pagination.per_page && !this.isDisablePageCalculation))) {
            return null;
        }
        const disabled = (!pagination.per_page || (pagination.total < pagination.per_page && !this.isDisablePageCalculation)) && isShowDisabled;
        const disabledClass = disabled ? styles.Disabled : '';
        return (
            <div className={ `${ styles.Pagination } ${ disabledClass } ${ isMobile ? styles.mobile : '' }` }>
                <Button
                    buttonType='icon'
                    svg={ IconChevronDoubleL }
                    onClick={ this.toFirst }
                    data-test={ ATTRIBUTES.tablePaginationToFirstPage }
                    disabled={ disabled }
                    hint={ system_buttons_hints?.first_page }
                />
                <Button
                    buttonType='icon'
                    svg={ IconChevronL }
                    onClick={ this.toPrev }
                    data-test={ ATTRIBUTES.tablePaginationPrevPage }
                    disabled={ disabled }
                    hint={ system_buttons_hints?.back }
                />

                <span className={ `${ styles.PaginationText } ${ disabledClass }` }>
                    <StringInput
                        isServicePortal={ isServicePortal }
                        onChange={ this.onChange }
                        value={ this.value }
                        onKeyDown={ this.changePage }
                        ref={ this.refInput }
                        className={ `${ styles.PaginationInput } ${ !this.isDisablePageCalculation ? styles.PaginationInputMargin : '' }` }
                        data-test={ ATTRIBUTES.tablePagintaionInput }
                        readonly={ disabled }
                    />
                    {
                        !this.isDisablePageCalculation ?
                            <>
                                { list_titles && list_titles.of }
                                <span
                                    data-test={ ATTRIBUTES.tablePaginationTotalPages }
                                    className={ styles.PaginationLastPageText }
                                >
                                { this.maxPage }
                            </span>
                            </> : null
                    }
                </span>
                <Button
                    buttonType='icon'
                    svg={ IconChevronR }
                    onClick={ this.toNext }
                    data-test={ ATTRIBUTES.tablePaginationNextPage }
                    disabled={ disabled }
                    hint={ system_buttons_hints?.next }
                />
                <Button
                    buttonType='icon'
                    svg={ IconChevronDoubleR }
                    onClick={ this.toLast }
                    data-test={ ATTRIBUTES.tablePaginationToLastPage }
                    disabled={ disabled || this.isDisablePageCalculation }
                    hint={ system_buttons_hints?.last_page }
                />
                <GlobalPortal>
                    <div className={ styles.PaginationInputContainer } ref={ this.refInputContainer } />
                </GlobalPortal>
            </div>
        );
    }
}


