import * as React from 'react';
import styles from './styles.module.scss';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import IconSquarePlus from 'assets/img/icons/square-plus.svg';
import IconArrowClock from 'assets/img/icons/arrow-clock.svg';
import { getUserDateTimeFormat, getUserTimezone } from 'helpers/getUserTimeZone';
import moment from 'moment';
import DragAndDrop from 'components/taskBoard/dragAndDrop';
import { observable } from 'mobx';
import TaskBoardCardModal from 'components/taskBoard/taskBoardCardModal';
import DragAndDropState from 'components/taskBoard/dragAndDrop/state';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Компонент карточки доски
 * @param {boolean} isEmpty
 * @param {Object} card
 * @param {string} categoryId
 * @param {string} boardId
 * @param {string} viewName
 */
@observer
export default class TaskBoardItem extends React.Component {
    @observable isShowModal = false;

    handleToggleNewCard = () => {
        this.isShowModal = !this.isShowModal;
    };

    renderDueDate = dueDate => {
        if (!dueDate) {
            return <div />;
        }
        const date = moment.tz(dueDate, 'UTC');
        if (!date.isValid()) {
            return <div />;
        }
        const result = date.tz(getUserTimezone()).format(getUserDateTimeFormat());

        return (
            <div className={ styles.ItemDueDate }>
                <div
                    className={ styles.ItemDueDateIcon }
                    dangerouslySetInnerHTML={ { __html: IconArrowClock } }
                />
                <div>{ result }</div>
            </div>
        );
    };

    renderOpenedAt = openedAt => {
        if (!openedAt) {
            return '';
        }
        const date = moment.tz(openedAt, 'UTC');
        if (!date.isValid()) {
            return '';
        }
        return date.tz(getUserTimezone()).format(getUserDateTimeFormat().slice(0, -3));
    };

    renderUsers = responsible => {
        if (_.isEmpty(responsible)) {
            return null;
        }
        return responsible.display_value || '';
    };

    renderBottom = (number, responsible) => {
        if (_.isEmpty(number) && _.isEmpty(responsible)) {
            return null;
        }
        return (
            <>
                <div className={ styles.ItemDelimeter } />
                <div
                    className={ styles.ItemUsers }
                    data-test={ ATTRIBUTES.taskBoardItemUsers }
                >
                    { number || '' }
                    <div>{ this.renderUsers(responsible) }</div>
                </div>
            </>
        );
    };

    renderDescription = description => {
        if (!description) {
            return null;
        }
        return (
            <div
                className={ styles.ItemDescription }
                data-test={ ATTRIBUTES.taskBoardItemDescription }
            >
                { description }
            </div>
        );
    };

    renderCardModal = (recordId, cardId) => {
        const { boardId, tableName, viewName, categoryId, canCreate } = this.props;

        // Когда модалка на создание новой записи и нет доступа на создание
        if (!recordId && !cardId && !canCreate) {
            return null;
        }

        return (
            <TaskBoardCardModal
                isShow={ this.isShowModal }
                doClose={ this.handleToggleNewCard }
                recordId={ recordId }
                tableName={ tableName }
                boardId={ boardId }
                cardId={ cardId }
                viewName={ viewName }
                laneId={ categoryId }
            />
        );
    };

    renderCard = card => {
        return (
            <>
                <div className={ styles.ItemHeader }>
                    <div>{ '' }</div>
                    <div
                        className={ styles.ItemOpenedAt }
                        data-test={ ATTRIBUTES.taskBoardItemOpenedAt }
                    >
                        { this.renderOpenedAt(card.opened_at) }
                    </div>
                </div>
                { this.renderDescription(card.description) }
                <div className={ styles.ItemTags }>{ '' }</div>
                <div className={ styles.ItemAttachments }>
                    <div>{ this.renderDueDate(card.due_date) }</div>
                    <div>{ '' }</div>
                </div>
                { this.renderBottom(card.number, card.responsible) }
            </>
        );
    };

    renderEmptyCard() {
        const { categoryId, canUpdate } = this.props;
        const enterId = DragAndDropState.getEnterId();
        const { task_board = {} } = langStore.getTranslate();

        if (canUpdate) {
            return (
                <div
                    className={ `${ styles.EmptyItem } ${
                        enterId === `card${ categoryId }` ? styles.CardOnEnter : ''
                    }` }
                    onClick={ this.handleToggleNewCard }
                >
                    <div
                        className={ styles.AddEmptyItemIcon }
                        dangerouslySetInnerHTML={ { __html: IconSquarePlus } }
                    />
                    <div className={ styles.EmptyText }>
                        { task_board.move_or_create }
                    </div>
                </div>
            );
        }
        else {
            return (
                <div
                    className={ styles.DisabledEmptyItem }
                    onClick={ this.handleToggleNewCard }
                >
                    <div className={ styles.EmptyText }>
                        { task_board.move_card_no_access }
                    </div>
                </div>
            );
        }

    }

    render() {
        const { isEmpty, card, categoryId, canUpdate } = this.props;
        const enterId = DragAndDropState.getEnterId();

        if (isEmpty) {
            return (
                <>
                    { this.renderEmptyCard() }
                    { this.renderCardModal() }
                </>
            );
        }

        return (
            <>
                { canUpdate ? (
                    <DragAndDrop
                        type="card"
                        sysId={ card.sys_id }
                        className={ `${ styles.Item } ${
                            enterId === `card${ card.sys_id }` ? styles.CardOnEnter : ''
                        }` }
                        categoryId={ categoryId }
                        onClick={ this.handleToggleNewCard }
                        order={ card.order }
                        data-test={ ATTRIBUTES.taskBoardItem }
                    >
                        { this.renderCard(card) }
                    </DragAndDrop>
                ) : (
                    <div
                        className={ `${ styles.DisabledItem } ${
                            enterId === `card${ card.sys_id }` ? styles.CardOnEnter : ''
                        }` }
                        onClick={ this.handleToggleNewCard }
                    >
                        { this.renderCard(card) }
                    </div>
                ) }
                { this.renderCardModal(card.record_id, card.sys_id) }
            </>
        );
    }
}
