import * as _ from 'lodash';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import Reference from 'components/dynamicForms/view/field/reference';
import AuthFull from 'components/portalWidgetsComponents/AuthFull';
import GetList from 'components/portalWidgetsComponents/GetList';
import DropdownWidget from 'components/portalWidgetsComponents/DropdownWidget';
import DropdownWidgetItems from 'components/portalWidgetsComponents/DropdownWidget/DropdownWidgetItems';
import AuthHeader from 'components/portalWidgetsComponents/AuthHeader';
import Card from 'components/portalWidgetsComponents/Card';
import Search from 'components/portalWidgetsComponents/Search';
import SearchResults from 'components/portalWidgetsComponents/SearchResults';
import ColorInput from 'components/portalWidgetsComponents/ColorInput';
import ColoredText from 'components/portalWidgetsComponents/ColoredText';
import Button from 'components/button';
import Badge from 'components/badge';
import TextArea from 'components/dynamicForms/view/field/textArea';
import Checkbox from 'components/dynamicForms/view/field/checkbox';
import Select from 'components/dynamicForms/view/field/select';
import Image from 'components/dynamicForms/view/field/image';
import SideMenu from 'components/portalWidgetsComponents/SideMenu';
import CodeMirrorComponent from 'components/dynamicForms/view/field/codeMirror';
import Conditions from 'components/dynamicForms/view/field/conditions';
import Template from 'components/dynamicForms/view/field/template';
import List from 'components/dynamicForms/view/field/list';
import KindChart from 'components/chart/kindChart';
import DateInput from 'components/dynamicForms/view/field/dateTimeInput';
import DaysOfWeek from 'components/dynamicForms/view/field/daysOfWeek';
import Duration from 'components/dynamicForms/view/field/duration';
import ModalWindow from 'components/modalWindow';
import HtmlInput from 'components/dynamicForms/view/field/htmlInput';
import Report from 'components/report';
import WidgetForm from 'components/portalWidgetsComponents/WidgetForm';
import BreadCrumbs from 'components/portalWidgetsComponents/BreadCrumbs';
import ActivityFeed from 'components/activityFeed';
import Attachments from 'components/portalWidgetsComponents/Attachments';
import MultiSelectComponent from 'components/multiselect';
import { runScript } from 'helpers/scriptClientHelper';
import ListWidget from 'components/portalWidgetsComponents/ListWidget';
import CategoryWidget from 'components/portalWidgetsComponents/CategoryWidget';
import PreviewListWiget from 'components/portalWidgetsComponents/PreviewListWiget';
import CategoryFlatWidget from 'components/portalWidgetsComponents/CategoryFlatWidget';
import REMWidget from 'components/portalWidgetsComponents/REMWidget';
import REMFormWidget from 'components/portalWidgetsComponents/REMFormWidget';
import DurationInput from 'components/portalWidgetsComponents/DurationInput';
import StatesFlow from 'components/statesFlow';
import Toggle from "components/toggle";
import FormsState from 'globalState/forms';
import { WIDGET_NAMES } from 'constants/widgets';
import { simpleDecodeUri } from 'helpers/search';
import widgetsDataState from 'globalState/widgets';

export const replaceWidgetCustom = (text, widgetId) => {
    const widgetReplace = text.replace(/(s_widget\.[^(]*)\(/g, `$1('${ widgetId }',`);
    let replacedScript = widgetReplace.replace(/s_widget_custom/g, `s_widget_custom['${ widgetId }']`);
    replacedScript = replacedScript.replace(/s_widget_custom(\[[^\]]*\])(\[[^\.]*)\./g, replacerInn);  // убираем лишние скобки с s_widget_custom из скриптов в аттрибутах
    const addCustom = '=> { window.s_widget_custom = window.s_widget_custom || {}; window.s_widget_custom';
    return replacedScript.replace(/=>[^{]*{[^w}]*window.s_widget_custom/g, addCustom);
};

// убираем лишние скобки с s_widget_custom из скриптов в аттрибутах
export const replaceWidgetProp = (prop) => {
    if (prop === 'true') {
        return true;
    }
    if (prop === 'false') {
        return false;
    }
    return prop.replace(/s_widget_custom(\[[^\]]*\])([^\.]*)\./g, replacerInn);
};

const replacerInn = (match, p1, p2) => {
    return match.replace(p2, '', 'g');
};

export const evalScript = (clientScript, widgetId) => {
    if (clientScript) {
        const f = new Function('"use strict";' + replaceWidgetCustom(clientScript, widgetId));
        try {
            f();
        }
        catch (e) {
            console.error(e);
        }
    }
};

export const getType = (type) => {
    switch (type) {
        case 'string':
            return StringInput;
        case 'reference':
            return Reference;
        case 'authfull':
            return AuthFull;
        case 'attachment':
            return Attachments;
        case 'getlist':
            return GetList;
        case 'dropdownmenu':
            return DropdownWidget;
        case 'dropdownmenuitems':
            return DropdownWidgetItems;
        case 'authheader':
            return AuthHeader;
        case 'card':
            return Card;
        case 'search':
            return Search;
        case 'results':
            return SearchResults;
        case 'color':
            return ColorInput;
        case 'coloredtext':
            return ColoredText;
        case 'button':
            return Button;
        case 'textarea':
            return TextArea;
        case 'checkbox':
            return Checkbox;
        case 'select':
            return Select;
        case 'sidemenu':
            return SideMenu;
        case 'codemirror':
            return CodeMirrorComponent;
        case 'conditions':
            return Conditions;
        case 'template':
            return Template;
        case 'list':
            return List;
        case 'chart':
            return KindChart;
        case 'date':
            return DateInput;
        case 'datetime':
            return DateInput;
        case 'daysofweek':
            return DaysOfWeek;
        case 'duration':
            return Duration;
        case 'modal':
            return ModalWindow;
        case 'htmleditor':
            return HtmlInput;
        case 'report':
            return Report;
        case 'previewlist':
            return PreviewListWiget;
        case 'form':
            return WidgetForm;
        case 'breadcrumbs':
            return BreadCrumbs;
        case 'activityfeed':
            return ActivityFeed;
        case 'multiselect':
            return MultiSelectComponent;
        case 'file':
            return Image;
        case 'listitems':
            return ListWidget;
        case 'category':
            return CategoryWidget;
        case 'categoryflat':
            return CategoryFlatWidget;
        case 'rem':
            return REMWidget;
        case 'remform':
            return REMFormWidget;
        case 'statesflow':
            return StatesFlow;
        case 'badge':
            return Badge;
        case 'durationinput':
            return DurationInput;
        case 'toggle':
            return Toggle;
        default:
            return type;
    }
};

const applyClassDirective = (oldProps) => {
    const DIRECTIVE_CLASS = 'simple-class';
    const CLASS = 'class';
    const CLASS_NAME = 'classname';
    const CLASS_NAME_CC = 'className';
    let attr = {};
    let classes = [];
    if (oldProps && oldProps.hasOwnProperty(CLASS)) {
        classes.push(oldProps[CLASS]);
        delete oldProps[CLASS];
    }
    if (oldProps && oldProps.hasOwnProperty(CLASS_NAME)) {
        classes.push(oldProps[CLASS_NAME]);
        delete oldProps[CLASS_NAME];
    }
    if (oldProps && oldProps.hasOwnProperty(CLASS_NAME_CC)) {
        classes.push(oldProps[CLASS_NAME_CC]);
        delete oldProps[CLASS_NAME_CC];
    }
    if (oldProps && oldProps.hasOwnProperty(DIRECTIVE_CLASS)) {
        classes.push(oldProps[DIRECTIVE_CLASS]);
        delete oldProps[DIRECTIVE_CLASS];
    }
    attr.className = classes.join(' ');
    return attr;
};

export const getAttr = (oldProps) => {
    const {
        type,
        model,
        widgetDataState,
        ...props
    } = oldProps;
    const tempProps = getDataToProps(widgetDataState, props);
    let attr = {};
    attr = { ...attr, ...applyClassDirective(tempProps) };
    let modelItem = '';
    if (model) {
        const fieldIds = model.split('.');
        attr.value = widgetDataState.getFieldValue(fieldIds[1]) === undefined ? props.value : widgetDataState.getFieldValue(fieldIds[1]);
        attr.term = widgetDataState.getTerm(fieldIds[1]);
        modelItem = fieldIds[1];
    }
    attr.widgetId = widgetDataState.getId();

    switch (type) {
        case 'chart':
            attr = { ...attr, ...getChartProps(tempProps) };
            break;

        case 'list':
            attr = { ...attr, ...getListProps(tempProps) };
            break;

        case 'reference':
            attr = { ...attr, ...getReferenceProps(tempProps) };
            break;

        case 'template':
        case 'conditions':
            attr = { ...attr, ...getConditionsProps(tempProps) };
            break;

        case 'select':
            attr = { ...getSelectProps(tempProps), ...attr };
            break;

        case 'multiselect':
            attr = { ...attr, ...getMultiselectProps(tempProps, attr) };
            break;

        case 'codemirror':
            attr = { ...attr, ...getCodemirrorProps(tempProps) };
            break;

        case 'datetime':
            attr = { ...attr, ...getDateTimeProps(tempProps) };
            break;

        case 'date':
            attr = { ...attr, ...getDateProps(tempProps) };
            break;

        case 'activityfeed':
            attr = { ...attr, ...getActivityFeedProps(tempProps, widgetDataState) };
            break;

        case 'modal':
            attr = { ...attr, ...getModalProps(tempProps) };
            break;

        case 'dropdownmenu':
            attr.modelItem = modelItem;
            attr = { ...attr, ...getPopupProps(tempProps) };
            break;

        case 'search':
            attr = { ...attr, ...getSearchWidgetProps(tempProps) };
            break;

        case 'results':
            attr = { ...attr, ...getSearchResultsWidgetProps(tempProps) };
            break;

        case 'authheader':
            attr = { ...attr, ...getAuthHeaderProps(tempProps) };
            break;

        case 'listitems':
            attr = { ...attr, ...getListItemsProps(tempProps, widgetDataState) };
            break;

        case 'category':
        case 'categoryflat':
            attr = { ...attr, ...getCategoryWidgetProps(tempProps) };
            break;

        case 'previewlist':
            attr = { ...attr, ...getPreviewListWidgetAttrs(tempProps) };
            break;

        case 'authfull':
            attr = { ...attr, ...getAuthFullProps(tempProps) };
            break;

        case 'card':
            attr = { ...attr, ...getCardProps(tempProps) };
            break;

        case 'form':
            attr = { ...attr, ...getFormProps(tempProps) };
            break;

        case 'sidemenu':
            attr = { ...attr, ...getSideMenuProps(tempProps) };
            break;

        case 'rem':
            attr = { ...attr, ...getRemProps(tempProps) };
            break;

        case 'remform':
            attr = { ...attr, ...getRemFormProps(tempProps) };
            break;

        case 'button':
            attr = { ...attr, ...getButtonProps(tempProps) };
            break;

        case 'statesflow':
            attr = { ...attr, ...getStatesFlowProps(tempProps) };
            break;

        case 'durationinput':
            attr = { ...attr, ...getDurationInputProps(tempProps) };
            break;

        case 'toggle':
            attr = { ...attr, ...getToggleProps(tempProps) };
            break;

        case 'badge':
            attr = { ...attr };
            break;

        case 'textarea':
        case 'string':
        case 'checkbox':
        case 'daysofweek':
        case 'duration':
        case 'htmleditor':
            attr = { ...attr, ...getFieldProps(tempProps) };
            break;

        case 'attachment':
            attr = { ...attr, ...getAttachmentProps(tempProps) };
            break;

        default:
            delete tempProps['isServicePortal'];
            break;
    }

    delete tempProps.style;
    delete tempProps['event-change'];
    delete tempProps['event-click'];
    delete tempProps['event-mouseover'];
    delete tempProps['event-context'];
    delete tempProps.type;

    return { ...tempProps, ...attr };
};

const getFieldProps = (tempProps) => {
    let attr = {};
    if (tempProps.name) {
        attr.sysColumnName = tempProps.name;
        delete tempProps.name;
    }
    if (tempProps.fieldinfo) {
        attr.fieldInfo = JSON.parse(tempProps.fieldinfo);
        delete tempProps.fieldinfo;
    }
    // у полей в модели поле readonly
    if (tempProps.readOnly !== undefined) {
        attr.readonly = tempProps.readOnly;
        delete tempProps.readOnly;
    }
    return attr;
};

const getDurationInputProps = (tempProps) => {
    let attr = {};
    if (tempProps.onchange) {
        const onChangeStr = tempProps.onchange;
        attr.onChange = (ms) => {
            runScript(onChangeStr, { ms });
        };
        delete tempProps.onchange;
    }
    if (tempProps.maxvalue) {
        attr.maxValue = JSON.parse(tempProps.maxvalue);
        delete tempProps.maxvalue;
    }
    if (tempProps.exclude) {
        attr.exclude = JSON.parse(tempProps.exclude);
        delete tempProps.exclude;
    }
    if (tempProps.alignright) {
        attr.alignRight = tempProps.alignright;
        delete tempProps.alignright;
    }
    return attr;
};

const getToggleProps = (tempProps) => {
    let attr = {};
    if(tempProps.boldtext){
        attr.boldText = tempProps.boldtext;
        delete tempProps.boldtext;
    }
    if (tempProps.disabled) {
        attr.disabled = tempProps.disabled;
        delete tempProps.disabled;
    }
    if (tempProps.checked) {
        attr.checked = tempProps.checked;
    }
    if (tempProps['event-change']) {
        const onChange = tempProps['event-change'];
        attr.onChange = (value) => {
            if (onChange) {
                runScript(onChange, { value });
            }
        };
    }
    return attr;
};

const getButtonProps = (tempProps) => {
    let attr = {};
    if (tempProps.buttontype) {
        attr.buttonType = tempProps.buttontype;
        delete tempProps.buttontype;
    }
    if (tempProps.buttonsize) {
        attr.buttonSize = tempProps.buttonsize;
        delete tempProps.buttonsize;
    }
    return attr;
};

const getStatesFlowProps = (tempProps) => {
    let attr = {};
    if (tempProps.states) {
        attr.states = JSON.parse(tempProps.states);
        delete tempProps.states;
    }
    return attr;
};

const getSideMenuProps = (tempProps) => {
    let attr = {};
    if (tempProps.parentsdepth) {
        attr.parentsDepth = tempProps.parentsdepth;
        delete tempProps.parentsdepth;
    }
    if (tempProps.childrendepth) {
        attr.childrenDepth = tempProps.childrendepth;
        delete tempProps.childrendepth;
    }
    if (tempProps.includecategories) {
        attr.includeCategories = tempProps.includecategories;
        delete tempProps.includecategories;
    }
    if (tempProps.includeitems) {
        attr.includeItems = tempProps.includeitems;
        delete tempProps.includeitems;
    }
    return attr;
};

const getFormProps = (tempProps) => {
    let attr = {};
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('sysid')) {
        attr.sysId = tempProps.sysid;
        delete tempProps.sysid;
    }
    if (tempProps.hasOwnProperty('titlehide')) {
        attr.titleHide = tempProps.titlehide;
        delete tempProps.titlehide;
    }
    if (tempProps.hasOwnProperty('uiactions')) {
        attr.uiActions = typeof tempProps.uiactions === 'boolean' ? tempProps.uiactions : tempProps.uiactions === 'true';
        delete tempProps.uiactions;
    }
    if (tempProps.hasOwnProperty('userscripts')) {
        attr.userScripts = typeof tempProps.userscripts === 'boolean' ? tempProps.userscripts : tempProps.userscripts === 'true';
        delete tempProps.userscripts;
    }
    if (tempProps.hasOwnProperty('name')) {
        attr.formName = tempProps.name;
        delete tempProps.name;
    }
    return attr;
};

const getCardProps = (tempProps) => {
    let attr = {};
    if (tempProps.shortdescription) {
        attr.shortDescription = tempProps.shortdescription;
        delete tempProps.shortdescription;
    }
    return attr;
};

const getAuthFullProps = (tempProps) => {
    let attr = {};
    if (tempProps.isregistration !== undefined) {
        attr.isRegistration = tempProps.isregistration;
        delete tempProps.isregistration;
    }
    if (tempProps.usesourceurl !== undefined) {
        attr.useSourceUrl = tempProps.usesourceurl;
        delete tempProps.usesourceurl;
    }
    if (tempProps.isswitchlang !== undefined) {
        attr.isSwitchLang = tempProps.isswitchlang;
        delete tempProps.isswitchlang;
    }
    if (tempProps.isrememberme !== undefined) {
        attr.isRememberMe = tempProps.isrememberme;
        delete tempProps.isrememberme;
    }
    if (tempProps.isresetpassword !== undefined) {
        attr.isResetPassword = tempProps.isresetpassword;
        delete tempProps.isresetpassword;
    }
    if (tempProps.redirectto !== undefined) {
        attr.redirectTo = tempProps.redirectto;
        delete tempProps.redirectto;
    }
    if (tempProps.usernamevalidation !== undefined) {
        attr.usernameValidation = tempProps.usernamevalidation;
        delete tempProps.usernamevalidation;
    }
    if (tempProps.passwordvalidation !== undefined) {
        attr.passwordValidation = tempProps.passwordvalidation;
        delete tempProps.passwordvalidation;
    }
    return attr;
};

const getChartProps = (tempProps) => {
    let attr = {};
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.charttype) {
        attr.chartType = tempProps.charttype;
        delete tempProps.charttype;
    }
    return attr;
};

const getListProps = (tempProps) => {
    let attr = {};
    attr.special = {
        table_name: tempProps.tablename || '',
        table_id: tempProps.tableid || '',
        dependency_map_link: tempProps.dependencymaplink || '',
        reference_qualifier: {
            condition: tempProps.condition || '',
            is_fixed: tempProps.isfixed,
        },
        can_create: !!tempProps.cancreate,
        can_read: tempProps.canread !== undefined ? !!tempProps.canread : true,
    };
    delete tempProps.tablename;
    delete tempProps.tableid;
    delete tempProps.dependencymaplink;
    delete tempProps.condition;
    delete tempProps.isfixed;
    delete tempProps.cancreate;
    delete tempProps.canread;
    return { ...getFieldProps(tempProps), ...attr };
};

const getReferenceProps = (tempProps) => {
    let attr = {};
    attr.special = {
        table_name: tempProps.tablename || '',
        table_id: tempProps.tableid || '',
        dependency_map_link: tempProps.dependencymaplink || '',
        reference_qualifier: {
            condition: tempProps.condition || '',
            is_fixed: tempProps.isfixed,
        },
        can_create: !!tempProps.cancreate,
        can_read: tempProps.canread !== undefined ? !!tempProps.canread : true,
    };
    delete tempProps.tablename;
    delete tempProps.tableid;
    delete tempProps.dependencymaplink;
    delete tempProps.condition;
    delete tempProps.isfixed;
    delete tempProps.cancreate;
    delete tempProps.canread;
    return { ...getFieldProps(tempProps), ...attr };
};

const getConditionsProps = (tempProps) => {
    let attr = {};
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.tableid) {
        attr.tableId = tempProps.tableid;
        delete tempProps.tableid;
    }
    return { ...getFieldProps(tempProps), ...attr };
};

const getSelectProps = (tempProps) => {
    let attr = {};
    attr.special = {
        values: tempProps.options ? JSON.parse(tempProps.options) : [],
    };
    delete tempProps.options;
    if (tempProps.radiobuttonsmode) {
        attr.radioButtonsMode = tempProps.radiobuttonsmode;
        delete tempProps.radiobuttonsmode;
    }
    return { ...getFieldProps(tempProps), ...attr };
};

const getMultiselectProps = (tempProps, oldAttr) => {
    let attr = {};
    attr.options = tempProps.options ? JSON.parse(tempProps.options) : [];
    delete tempProps.options;
    attr.values = oldAttr.value !== undefined ? oldAttr.value : tempProps.values;
    delete tempProps.values;
    delete oldAttr.value;
    return { ...getFieldProps(tempProps), ...attr };
};

const getCodemirrorProps = (tempProps) => {
    let attr = {};
    attr.options = tempProps.options ? JSON.parse(tempProps.options) : {};
    delete tempProps.options;
    return { ...getFieldProps(tempProps), ...attr };
};

const getDateTimeProps = (tempProps) => {
    let attr = {};
    attr.beginDate = tempProps.begindate ? tempProps.begindate : '';
    attr.endDate = tempProps.enddate ? tempProps.enddate : '';
    delete tempProps.begindate;
    delete tempProps.enddate;
    return { ...getFieldProps(tempProps), ...attr };
};

const getDateProps = (tempProps) => {
    let attr = {};
    attr.beginDate = tempProps.begindate ? tempProps.begindate : '';
    attr.endDate = tempProps.enddate ? tempProps.enddate : '';
    delete tempProps.begindate;
    delete tempProps.enddate;
    if (tempProps.validate !== undefined) {
        attr.validate = JSON.parse(tempProps.validate);
        delete tempProps.validate;
    }
    attr.pickertype = 'date';
    return { ...getFieldProps(tempProps), ...attr };
};

const getActivityFeedProps = (tempProps, widgetDataState) => {
    let attr = {};
    if (widgetsDataState.hasOnlyOneForm()) {
        const widgetForm = widgetsDataState.getFormOnlyOne();
        attr.tableName = widgetForm.getTableNameForm();
        attr.sysId = widgetForm.getRecordIdForm();
        const matchTableName = attr.tableName?.match(/{data\.([^}]*)}/);
        if (matchTableName && matchTableName[1]) {
            attr.tableName = widgetForm.getFieldValue(matchTableName[1]) ? attr.tableName.replace(/{data\.([^}]*)}/, widgetDataState.getFieldValue(matchTableName[1])) : '';
        }
        const matchSysId = attr.sysId?.match(/{data\.([^}]*)}/);
        if (matchSysId && matchSysId[1]) {
            attr.sysId = widgetForm.getFieldValue(matchSysId[1]) ? attr.sysId.replace(/{data\.([^}]*)}/, widgetDataState.getFieldValue(matchSysId[1])) : '';
        }
    }
    attr.hasNotForm = !widgetsDataState.hasOnlyOneForm();
    if (tempProps.tablename !== undefined) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.sysid !== undefined) {
        attr.sysId = tempProps.sysid;
        delete tempProps.sysid;
        attr.hasNotForm = true; // Когда указываем конкретные атрибуты на запись, понятно, что не хотим, чтобы ссылалось на какую-то иную форму
    }
    if (tempProps.config !== undefined) {
        attr.config = JSON.parse(tempProps.config);
        if (attr.config.journal !== undefined) {
            attr.journal = _.split(_.replace(attr.config.journal, /\s/g, ''), ',');
        }
        if (attr.config.columns !== undefined) {
            attr.columns = _.split(_.replace(attr.config.columns, /\s/g, ''), ',');
        }
        if (attr.config.title !== undefined) {
            attr.title = attr.config.title;
        }
        if (attr.config.classes) {
            attr.classes = attr.config.classes;
        }
        attr.isNotStylized = false;
        if (attr.config.isNotStylized !== undefined) {
            attr.isNotStylized = attr.config.isNotStylized === 'true' || attr.config.isNotStylized === '1';
        }
        delete tempProps.config;
    }
    const data = widgetDataState.getData();
    attr.activities = data.response && data.response.activities ? data.response.activities : [];
    attr.activityTypes = data.response && data.response.activity_types ? data.response.activity_types : [];
    attr.historyFields = data.response && data.response.history_fields ? data.response.history_fields : [];
    attr.widgetId = widgetDataState.getId();
    return attr;
};

const getPopupProps = (tempProps) => {
    let attr = {};
    if (tempProps.doClose) {
        const closeStr = tempProps.doClose;
        attr.doClose = () => {
            runScript(closeStr);
        };
        delete tempProps.doClose;
    }
    if (tempProps.classnameactive) {
        attr.classNameActive = tempProps.classnameactive;
        delete tempProps.classnameactive;
    }
    return attr;
};

const getModalProps = (tempProps) => {
    let attr = {};
    if (tempProps.doClose || tempProps.doclose) {
        const closeStr = tempProps.doClose || tempProps.doclose;
        attr.doClose = () => {
            runScript(closeStr);
        };
        delete tempProps.doClose;
        delete tempProps.doclose;
    }
    if (tempProps.isfullscreenmobile) {
        attr.isFullScreenMobile = tempProps.isfullscreenmobile;
        delete tempProps.isfullscreenmobile;
    }
    if (tempProps.isshow) {
        attr.isShow = tempProps.isshow;
        delete tempProps.isshow;
    }
    return attr;
};

const getAuthHeaderProps = (tempProps) => {
    let attr = {};
    if (tempProps.profileurl) {
        attr.profileUrl = tempProps.profileurl;
        delete tempProps.authheader;
    }
    return attr;
};

const getListItemsProps = (tempProps, widgetDataState) => {
    let attr = {};
    if (tempProps.condition) {
        const match = tempProps.condition.match(/{data\.([^}]*)}/);
        if (match && match[1]) {
            attr.condition = widgetDataState.getFieldValue(match[1]) ? tempProps.condition.replace(/{data\.([^}]*)}/, widgetDataState.getFieldValue(match[1])) : '';
            delete tempProps.condition;
        }
    }
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.choiceconditionname) {
        attr.choiceConditionName = tempProps.choiceconditionname;
        delete tempProps.choiceconditionname;
    }
    if (tempProps.dateconditionname) {
        attr.dateConditionName = tempProps.dateconditionname;
        delete tempProps.dateconditionname;
    }
    if (tempProps.perpage) {
        attr.perPage = tempProps.perpage;
        delete tempProps.perpage;
    }
    if (tempProps.listview) {
        attr.listView = tempProps.listview;
        delete tempProps.listview;
    }
    if (tempProps.displaycolumnnumber) {
        attr.displayColumnNumber = tempProps.displaycolumnnumber;
        delete tempProps.displaycolumnnumber;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    if (tempProps.fixedcondition) {
        attr.fixedCondition = tempProps.fixedcondition;
        delete tempProps.fixedcondition;
    }
    return attr;
};

const getCategoryWidgetProps = (tempProps) => {
    let attr = {};
    if (tempProps.nodeid) {
        attr.nodeId = tempProps.nodeid;
        delete tempProps.nodeid;
    }
    if (tempProps.categorydescription) {
        attr.categoryDescription = tempProps.categorydescription;
        delete tempProps.categorydescription;
    }
    if (tempProps.categoryicon) {
        attr.categoryIcon = tempProps.categoryicon;
        delete tempProps.categoryicon;
    }
    if (tempProps.categorysubject) {
        attr.categorySubject = tempProps.categorysubject;
        delete tempProps.categorysubject;
    }
    if (tempProps.itemdescription) {
        attr.itemDescription = tempProps.itemdescription;
        delete tempProps.itemdescription;
    }
    if (tempProps.itemicon) {
        attr.itemIcon = tempProps.itemicon;
        delete tempProps.itemicon;
    }
    if (tempProps.itemsubject) {
        attr.itemSubject = tempProps.itemsubject;
        delete tempProps.itemsubject;
    }
    if (tempProps.hidedescription) {
        attr.hideDescription = tempProps.hidedescription;
        delete tempProps.hidedescription;
    }
    return attr;
};

const getSearchWidgetProps = (tempProps) => {
    let attr = {};
    if (tempProps.resultpage) {
        attr.resultPage = tempProps.resultpage;
        delete tempProps.resultpage;
    }
    if (tempProps.tsgroupid) {
        attr.tsGroupId = tempProps.tsgroupid;
        delete tempProps.tsgroupid;
    }
    if (tempProps.showtitle) {
        attr.showTitle = tempProps.showtitle;
        delete tempProps.showtitle;
    }
    if (tempProps.searchurl) {
        attr.searchUrl = tempProps.searchurl;
        delete tempProps.searchurl;
    }
    if (tempProps.searchquery) {
        attr.searchQuery = simpleDecodeUri(tempProps.searchquery);
        delete tempProps.searchquery;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    return attr;
};

const getSearchResultsWidgetProps = (tempProps) => {
    let attr = {};
    if (tempProps.searchquery) {
        attr.searchQuery = simpleDecodeUri(tempProps.searchquery);
        delete tempProps.searchquery;
    }
    if (tempProps.istreeshown) {
        attr.isTreeShown = tempProps.istreeshown;
        delete tempProps.istreeshown;
    }
    if (tempProps.tsgroupid) {
        attr.tsGroupId = tempProps.tsgroupid;
        delete tempProps.tsgroupid;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    return attr;
};

const getPreviewListWidgetAttrs = (tempProps) => {
    let attr = {};
    if (tempProps.tablename) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.elementcount) {
        attr.elementCount = tempProps.elementcount;
        delete tempProps.elementcount;
    }
    if (tempProps.subjectcolumn) {
        attr.subjectColumn = tempProps.subjectcolumn;
        delete tempProps.subjectcolumn;
    }
    if (tempProps.statecolumn) {
        attr.stateColumn = tempProps.statecolumn;
        delete tempProps.statecolumn;
    }
    if (tempProps.rowstyle) {
        attr.rowStyle = tempProps.rowstyle;
        delete tempProps.rowstyle;
    }
    if (tempProps.itempage) {
        attr.itemPage = tempProps.itempage;
        delete tempProps.itempage;
    }
    if (tempProps.itemview) {
        attr.itemView = tempProps.itemview;
        delete tempProps.itemview;
    }
    if (tempProps.alllinktitle) {
        attr.allLinkTitle = tempProps.alllinktitle;
        delete tempProps.alllinktitle;
    }
    if (tempProps.alllinkpage) {
        attr.allLinkPage = tempProps.alllinkpage;
        delete tempProps.alllinkpage;
    }
    if (tempProps.datecolumn) {
        attr.dateColumn = tempProps.datecolumn;
        delete tempProps.datecolumn;
    }
    return attr;
};

const getRemProps = (tempProps) => {
    let attr = {};
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('recordid')) {
        attr.recordId = tempProps.recordid;
        delete tempProps.recordid;
    }
    if (tempProps.hasOwnProperty('modelid')) {
        attr.modelId = tempProps.modelid;
        delete tempProps.modelid;
    }
    if (tempProps.hasOwnProperty('isuserscripts')) {
        attr.isUserScripts = tempProps.isuserscripts;
        delete tempProps.isuserscripts;
    }
    if (tempProps.hasOwnProperty('isportal')) {
        attr.isPortal = tempProps.isportal;
        delete tempProps.isportal;
    }
    if (tempProps.hasOwnProperty('name')) {
        attr.formName = tempProps.name;
        delete tempProps.name;
    }
    if (tempProps.hasOwnProperty('parentformsectionmodel')) {
        attr.parentFormSectionModel = tempProps.parentformsectionmodel;
        delete tempProps.parentformsectionmodel;
    }
    return attr;
};

const getAttachmentProps = (tempProps) => {
    let attr = {};
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('recordid')) {
        attr.recordId = tempProps.recordid;
        delete tempProps.recordid;
    }
    if (tempProps.hasOwnProperty('parentformsectionmodel')) {
        attr.parentFormSectionModel = tempProps.parentformsectionmodel;
        delete tempProps.parentformsectionmodel;
    }
    return attr;
};

const getRemFormProps = (tempProps) => {
    let attr = {};
    if (tempProps.hasOwnProperty('tablename')) {
        attr.tableName = tempProps.tablename;
        delete tempProps.tablename;
    }
    if (tempProps.hasOwnProperty('recordid')) {
        attr.recordId = tempProps.recordid;
        delete tempProps.recordid;
    }
    if (tempProps.hasOwnProperty('modelid')) {
        attr.modelId = tempProps.modelid;
        delete tempProps.modelid;
    }
    if (tempProps.hasOwnProperty('titlehide')) {
        attr.titleHide = tempProps.titlehide;
        delete tempProps.titlehide;
    }
    if (tempProps.hasOwnProperty('isuserscripts')) {
        attr.isUserScripts = tempProps.isuserscripts;
        delete tempProps.isuserscripts;
    }
    if (tempProps.hasOwnProperty('isportal')) {
        attr.isPortal = tempProps.isportal;
        delete tempProps.isportal;
    }
    if (tempProps.hasOwnProperty('classname')) {
        attr.className = tempProps.classname;
        delete tempProps.classname;
    }
    if (tempProps.hasOwnProperty('savebuttoncaption')) {
        attr.saveButtonCaption = tempProps.savebuttoncaption;
        delete tempProps.savebuttoncaption;
    }
    if (tempProps.hasOwnProperty('readonly')) {
        attr.readOnly = tempProps.readonly;
        delete tempProps.readonly;
    }
    if (tempProps.hasOwnProperty('name')) {
        attr.formName = tempProps.name;
        delete tempProps.name;
    }
    if (tempProps.hasOwnProperty('hidesavebutton')) {
        attr.hideSaveButton = tempProps.hidesavebutton;
        delete tempProps.hidesavebutton;
    }
    if (tempProps.hasOwnProperty('parentformsectionmodel')) {
        attr.parentFormSectionModel = tempProps.parentformsectionmodel;
        delete tempProps.parentformsectionmodel;
    }
    return attr;
};

const getDataToProps = (widgetDataState, oldProps) => {
    const props = oldProps;
    for (const key in props) {
        if (props.hasOwnProperty(key) && typeof props[key] === 'string') {
            const match = props[key].match(/{data\.([^}]*)}/);
            if (match && match[1]) {
                props[key] = injectData(widgetDataState.getData(), props[key]);
            }
            if (props[key] === 'false' || props[key] === 'true') {
                props[key] = props[key] !== 'false';
            }
            if (!_.isNil(props[key]) && typeof props[key] === 'string') {
                const matchOptions = props[key].match(/{?options\.([^}]*)}?/);
                if (matchOptions && matchOptions[1]) {
                    props[key] = widgetDataState.getOptionValue(matchOptions[1]);
                }
            }
        }
    }
    return props;
};

// задание параметров для первоначального запроса к беку
export const getInitializeParams = (template) => {
    const match = template.match(/<activityfeed/i);
    if (match && match[0]) {
        return getActivityFeedParams(template);
    }
};

const getActivityFeedParams = (template) => {
    const params = window.location.href.split('/');
    let table_name = '';
    let record_id = '';
    if (params && params[3] === 'record' && params[4]) {
        table_name = params[4];
        record_id = params[5] ? params[5].replace(/\?.*/, '') : record_id;
    }
    else {
        const matchTableName = template.match(/table_name="([^"]*)"/);
        const matchSysId = template.match(/sys_id="([^"]*)"/);
        table_name = matchTableName && matchTableName[0] ? matchTableName[1] : '';
        record_id = matchSysId && matchSysId[0] ? matchSysId[1] : '';
    }
    return {
        table_name,
        record_id,
    };
};

export const menuType = {
    NODE: 0,
    CATEGORY: 1,
    ITEM: 2,
};

/**
 * Функция определяет тип элемента
 * @param categoryId - id категории
 * @param itemId - id айтема
 * @returns {number} - тип
 */
export const getMenuType = (categoryId, itemId) => {
    if (categoryId) {
        return menuType.CATEGORY;
    }
    if (itemId) {
        return menuType.ITEM;
    }
    return menuType.NODE;
};

/**
 * Функция проверяет, один ли page_template у элумунтов
 * @param prevElement - предыдущий элемент
 * @param newElement - элемент, на который переходим
 * @returns {boolean}
 */
export const hasElementsSamePage = (prevElement, newElement) => {
    return prevElement.nodeId === newElement.nodeId && [
        menuType.NODE,
        menuType.CATEGORY,
        menuType.ITEM,
    ].includes(prevElement.type) && [
        menuType.NODE,
        menuType.CATEGORY,
        menuType.ITEM,
    ].includes(newElement.type);
};

/**
 * Функция подставляет в строку значения из данных
 * @param data - храилище данных
 * @param text - строка, в которой будем заменять выражения {data.some_variable} на значения соответствующих переменных из хранилища
 * @returns string - строка с подставленными данными
 */
export const injectData = (data, text) => {
    const re = new RegExp('{\\s?data\\.([\\w\\.]+)\\s?}', 'g');
    let result = text;
    let match;
    while ((match = re.exec(text)) !== null) {
        const expr = match[0];
        const variableName = match[1];
        const value = getValueFromWidgetStore(data, variableName);
        if (typeof value === 'object' || Array.isArray(value)) {
            result = result.replace(expr, JSON.stringify(value));
        }
        else {
            result = result.replace(expr, value);
        }
    }
    return result;
};

export const getValueFromWidgetStore = (data, variableName) => {
    const f = new Function('data', '"use strict"; return (data.' + variableName + ')'); // для возможности dot-walking
    let value;
    try {
        value = f(data) || '';
    }
    catch (e) {
        value = 'Invalid variable or object property name!';
    }
    return value;
};

export const getRecordLink = (match, tableName, id, itemPage, itemView = 'Default') => {
    if (match && match.params && itemPage) {
        const portal = match.params.portalSuffix || match.params.pageTemplatePathName;
        if (portal) {
            return `/${ portal }/${ itemPage }?table_name=${ tableName }&record_id=${ id }&view=${ itemView }`;
        }
    }
    return `/record/${ tableName }/${ id }`;
};

export const updateWidgets = () => {
    updateActivityFeedWidget();
};

const updateActivityFeedWidget = () => {
    const widgets = FormsState.getWidgets();
    const activityFeedWidget = _.find(widgets, widget => widget.name === WIDGET_NAMES.activityFeed);
    if (activityFeedWidget) {
        activityFeedWidget.fetchResponseData();
    }
};

export const initCustomWidget = (widgetInstanceId) => {
    if (!window.s_widget_custom) {
        window.s_widget_custom = {};
    }
    if (!window.s_widget_custom[widgetInstanceId]) {
        window.s_widget_custom[widgetInstanceId] = {};
    }
};