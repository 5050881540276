import * as React from 'react';
import styles from './styles.module.scss';
import { observable } from 'mobx';
import _ from 'lodash';
import { IS_UPDATE_CHART } from 'globalState/report';
import { getUrlByFieldValues, prepareCutValue } from 'helpers/report/reportComponent';
import { getDurationValue } from 'helpers/report/report';
import { observer } from 'mobx-react';

/**
 * Компонент для отображения чартов по типу digit
 * Параметры:
 * data - данные chart
 * name - заголовок
 * styleOptions - стили digit
 * */
@observer
export default class Digit extends React.Component {
    @observable value;
    @observable name;
    nodeRef = React.createRef();
    valueRef = React.createRef();
    reportState;

    constructor(props) {
        super(props);
        this.reportState = props.reportState;
        this.name = this.props.name;
        this.value = this.props.data[0].value;
        this.formatValue();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.name !== this.props.name) {
            this.name = this.props.name;
        }
        if (!_.isEqual(this.props.data, prevProps.data)) {
            this.value = this.props.data[0].value;
            this.formatValue();
        }
        if (!_.isEqual(this.props.styleOptions, prevProps.styleOptions)) {
            this.setStyles();
        }
        if (this.props.isUpdateChart) {
            this.setStyles();
            this.reportState.stateFlags = this.reportState.stateFlags & ~IS_UPDATE_CHART;
        }
        if (this.props.isDurationAverage && !prevProps.isDurationAverage) {
            this.formatValue();
        }
    }

    componentDidMount() {
        this.setStyles();
    }

    /**
     * Функция форматирует исходный текст к отображаемому формату для digit
     */
    formatValue() {
        const { styleOptions, isDurationAverage, data } = this.props;
        if (isDurationAverage) {
            if (_.isNumber(data[0].value)) {
                this.value = getDurationValue(data[0].value, true);
            }
            return;
        }
        if (styleOptions && styleOptions.decimalPrecision) {
            this.value = _.round(data[0].value, styleOptions.decimalPrecision);
        }
        this.value = this.value.toString().replace('.', ',');
        this.value = prepareCutValue(this.value);
    }

    setStyles() {
        const { styleOptions } = this.props;
        const { current: node } = this.nodeRef;
        const { current: value } = this.valueRef;
        if (!node || !styleOptions) {
            return;
        }
        if (styleOptions.color) {
            value.style['background-color'] = styleOptions.color;
        }
        this.formatValue();
    }

    render() {
        const { type, aggregationType } = this.props;

        return (
            <div key={ type } ref={ this.nodeRef }>
                <a
                    href={ getUrlByFieldValues(this.reportState) }
                    target='__blank'
                    className={ `${ styles.Digit } ${ aggregationType ? styles.SmallDigit : '' }` }
                    ref={ this.valueRef }
                >
                    { this.value }
                </a>
            </div>
        );
    }
}
