import * as React from 'react';
import { observer } from 'mobx-react';
import IconX from 'assets/img/icons/close-x.svg';
import styles from './styles.module.scss';
import { observable } from 'mobx';
import Heading from 'components/heading';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Button from 'components/button';
import ModalWrapper from 'components/modalWrapper';

/**
 * Описание: компонент Modal
 * Параметры:
 * isShow: {required, type: boolean} - флаг показа
 * title: {type: string} - заголовок окна
 * doClose: {type: function} - метод для дополнительного действия при скрытии модального окна
 * modalStyles: {type: object} - стили для компонента
 * children - данные которые надо показать в модальном окне
 * isFullScreenMobile: {type: boolean} - отображение на мобилке во весь экран
 */
@observer
export default class ModalWindow extends React.Component {
    @observable isShow = false;

    constructor(props) {
        super(props);

        this.isShow = props.isShow;
    }

    componentDidUpdate(prevProps) {
        const { isShow } = this.props;
        if (isShow !== prevProps.isShow) {
            this.isShow = isShow;
        }
    }

    doClose = () => {
        this.isShow = false;
        this.props.doClose && this.props.doClose(this.isShow);
    };

    renderHeader = () => {
        const { header, title } = this.props;
        if (header) {
            return header;
        }
        return (
            <div className={ styles.HeaderContent }>
                <Heading
                    size='h2'
                    noMargin
                    data-test={ ATTRIBUTES.modalTitle }
                    className={ styles.ModalTitle }
                >
                    { title }
                </Heading>
                <Button
                    buttonType='icon'
                    onClick={ this.doClose }
                    className={ styles.CloseIco }
                    svg={ IconX }
                    data-test={ ATTRIBUTES.modalCloseButton }
                />
            </div>
        );
    };

    render() {
        const { modalStyles, isFullScreenMobile, modalClassName, modalHeaderClassName,
            modalBodyClassName, isLoading } = this.props;
        const loader = isLoading ? <>
            <div className={ styles.OverlayLoader } />
            <div className={ styles.Loader } />
        </> : '';

        return this.isShow ? (
            <ModalWrapper>
                { loader }
                <div className={ `${ styles.Wrapper } ${ isLoading ? styles.LoaderHide : '' } ${ isFullScreenMobile ? styles.FullScreenMobile : '' }` }>
                    <div className={ styles.AlignContainer }>
                        <div className={ styles.Overlay } onClick={ this.doClose } />
                        <div className={ styles.AlignContent }>
                            <div
                                className={ `${ styles.Window } ${ modalClassName ? modalClassName : '' }` }
                                style={ modalStyles ? modalStyles : undefined }
                                data-test={ ATTRIBUTES.modalWindow }
                            >
                                <div
                                    className={ `${ styles.Header } ${ modalHeaderClassName ? modalHeaderClassName : '' }` }
                                    data-test={ ATTRIBUTES.modalHead }
                                >
                                    { this.renderHeader() }
                                </div>

                                <div
                                    className={ `${ styles.Body } ${ modalBodyClassName ? modalBodyClassName : '' }` }
                                    data-test={ ATTRIBUTES.modalBody }
                                >
                                    { this.props.children }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        ) : null;
    }
}
