import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import _ from 'lodash';

import { tableStateClass as TableStateClass } from 'globalState/table';
import Chart from 'components/chart';
import PivotTable from 'components/report/pivotTable';
import { generateRandomString, parseToJson } from 'helpers/data';
import {
    extractStylesFromRecord,
    extractFormDataFromRecord,
    exportStyles,
    setTextSize,
} from 'helpers/report/reportStylesHelper';
import { simpleHashFromObject } from 'helpers/misc';
import Digit from 'components/chart/digit';
import DigitStyles from 'components/chart/digit/styles.module.scss';
import ReportTable from 'components/report/reportTable';
import {
    prepareDataForGauge,
    prepareDataWithTime,
    prepareDataForHeatmap,
    prepareDataForBar,
    formatItem,
} from 'helpers/report/reportComponent';
import {
    prepareDataForPivot,
    prepareDataForMultiPivot,
    prepareDataForDatatable,
    prepareDataForDatatableWithTime,
} from 'helpers/report/reportTableHelper';
import {
    getParamSection,
    getParamValue,
    STEPS,
    getSysColumnNameById,
    getCurrentGroupBy,
    fetchReportById,
} from 'helpers/report/report';
import reportState, { IS_UPDATE_CHART, IS_UPDATE_MENU, reportStateClass } from 'globalState/report';
import MultiLevelPivotTable from 'components/report/multiLevelPivotTable';
import MultiLevelPivotTableStyles from 'components/report/multiLevelPivotTable/styles.module.scss';
import ContextMenu from 'components/contextMenu';
import styles from './styles.module.scss';
import { Table } from '../table';
import Pagination from '../table/pagination';
import ChartLoader from 'components/chart/chartLoader';
import printJS from 'print-js';
import Select from 'components/dynamicForms/view/field/select';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';
import tableStyles from '../table/styles.module.scss';

/**
 * Описание: компонент отчёта, используется в виджетах и на странице создания отчёта
 * @props:
 data: {
         chart, // данные чарта
         type, // тип чарта
         name,  // имя чарта
         field, // поле по которому строится чарт
         tableName , // display_value исользуемой таблицы
     }
 * onMenuChanged - метод, который вызывается при изменении списка пунктов меню
 */
@observer
export default class Report extends React.Component {
    name = null;
    reportState;
    optionsForUpdateTextStyle = false;
    @observable isShowContextMenu = false;
    @observable menuCoordinates = {
        x: 0,
        y: 0,
    };
    reportRef = React.createRef();
    nodeRef = React.createRef();
    titleRef = React.createRef();
    chartRef = React.createRef();
    timeout = null;

    constructor(props) {
        super(props);
        const {
            reportid,
            widgetId,
        } = props;
        this.reportState = reportid || widgetId ? new reportStateClass() : reportState;
        this.reportState.fixedCondition = props.condition;
        if (reportid) {
            this.reportState.sFormSysId = reportid;
            this.timeout = setTimeout(() => {
                fetchReportById(reportid, null, null, this.getStyles, this.reportState).catch(console.error);
            }, 500);
        }
    }

    componentDidMount() {
        this.updateMenuItems();
        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(prevProps) {
        this.updateMenuItems();
        // Вызывается так, потому что refs доступны всегда только в componentDidUpdate
        if (this.optionsForUpdateTextStyle) {
            this.setTitleOptions(this.optionsForUpdateTextStyle);
        }
        const {
            condition,
            reportid,
        } = this.props;
        if (condition !== prevProps.condition) {
            this.reportState.fixedCondition = condition;
            if (reportid) {
                fetchReportById(reportid, null, null, this.getStyles, this.reportState).catch(console.error);
            }
        }
        if (reportid && reportid !== prevProps.reportid) {
            fetchReportById(reportid, null, null, this.getStyles, this.reportState).catch(console.error);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        clearTimeout(this.timeout);
        this.chart = null;
        this.updateMenuItems();
        const { reportid } = this.props;
        if (reportid) {
            this.reportState.clearData();
        }
    }

    onWindowResize = () => {
        if (this.optionsForUpdateTextStyle) {
            this.setTitleOptions(this.optionsForUpdateTextStyle);
        }
    };

    onChartReady = (chart) => {
        this.chart = chart;
        this.updateMenuItems();
    };

    updateMenuItems() {
        const { report } = langStore.getTranslate();
        const {
            menuItems,
            indexReportError,
        } = this.reportState;
        this.menuItems = [];

        if (report && report.print && !indexReportError) {
            this.menuItems.push({
                name: report && report.print,
                reactScript: () => this.printComponent(),
            });
        }

        if (menuItems && Array.isArray(menuItems)) {
            menuItems.map((item) => {
                this.menuItems.push(item);
            });
        }
        this.reportState.stateFlags = this.reportState.stateFlags & ~IS_UPDATE_MENU;

        if (this.props.onMenuChanged) {
            this.props.onMenuChanged(this.menuItems);
        }
    }

    printComponent() {
        const report = this.reportRef.current;

        if (!report || !report.id) {
            return;
        }
        const reportId = report.id;
        const settings = {
            printable: reportId,
            type: 'html',
            targetStyles: ['*'],
        };

        let type;
        if (this.reportState.report) {
            type = this.reportState.report.report_type.toLowerCase();
        }
        else if (this.reportState.reportData) {
            type = this.reportState.reportData.type.type_service_name.toLowerCase();
        }

        if ([
            'multilevel pivot table',
            'bar',
            'pie',
            'line',
            'trends',
            'pivot',
        ].includes(type)) {
            /**
             * для корректного отображения таблицы в печатной версии проводим ряд манипуляций
             * */
            const table = report.getElementsByTagName('table')[0];
            const title = report.getElementsByClassName(styles.title)[0];

            /**
             * расчитываем максимальную ширину для печати. по умолчанию в настройках плагина она 800(ширина листа).
             * смотрим ширину таблицы. если ширина больше 800 - изменяем её на ширину таблицы для того,
             * чтобы таблица помещалась на лист по ширине целиком, без горизонтальной прокрутки.
             * */
            const maxWidth = table && table.offsetWidth > 800 ? table.offsetWidth : 800;

            /**
             * расчитываем left для заголовка исходя из полученной максимальной ширины и его настроек.
             * */
            let titleLeft = 0;
            const align = this.reportState.styleOptions.title.align;
            if (align === 'center') {
                titleLeft = (maxWidth - title.offsetWidth) / 2;
            }
            else if (align === 'right') {
                titleLeft = maxWidth - title.offsetWidth;
            }

            /**
             * добавляем необходимые стили
             * */
            settings.style = ` 
                .${ styles.highchart } {display:flex!important;justify-content:center!important;overflow:visible!important;} 
                .${ MultiLevelPivotTableStyles.multiPivot } {display:flex!important;justify-content:center!important;overflow:visible!important;} 
                .${ styles.highchart } div {width:auto!important;} 
                table {margin:0!important;border-collapse:collapse!important;}
                .${ styles.title } {left: ${ titleLeft }px!important;}
            `;
            settings.maxWidth = maxWidth;
        }

        if ([
            'heatmap',
            'digits',
        ].includes(type)) {
            const container = report.getElementsByClassName('highcharts-container')[0];
            const maxWidth = container && container.offsetWidth > 800 ? container.offsetWidth : 800;
            const title = report.getElementsByClassName(styles.title)[0];
            let titleLeft = 0;
            const align = this.reportState.styleOptions.title.align;

            if (align === 'center') {
                titleLeft = (maxWidth - title.offsetWidth) / 2;
            }
            else if (align === 'right') {
                titleLeft = maxWidth - title.offsetWidth;
            }

            settings.style = `                  
                .${ styles.highchart } {display:flex!important;justify-content:center!important;overflow:visible!important;width:auto!important;}                                 
                .${ styles.highchart } div {width:auto!important;overflow:visible!important;margin:0!important;}                 
                .${ styles.highchart } > div > div {overflow:visible!important;}    
                .${ DigitStyles.Digit } {margin:0!important;}             
                .${ styles.title } {left: ${ titleLeft }px!important;}
            `;
            settings.maxWidth = maxWidth;
        }

        if (type === 'list') {
            /**
             * изменения для корректного отображения таблицы в печатной версии
             * */
            const table = report.getElementsByTagName('table')[0];

            /**
             * расчитываем максимальную ширину для печати. по умолчанию в настройках плагина она 800(ширина листа).
             * смотрим ширину таблицы. если ширина больше 800 - изменяем её на ширину таблицы для того,
             * чтобы таблица помещалась на лист по ширине целиком, без горизонтальной прокрутки.
             * */
            const maxWidth = table && table.offsetWidth > 800 ? table.offsetWidth : 800;

            /**
             * скрываем пагинацию, градиент над многострочным текстом, и скроллбар таблицы
             * */
            settings.style = `
                .${ styles.FixedHorizontalScroll } {display:none!important;}
                .${ styles.FixedListFooter } {display:none!important;}
                .${ tableStyles.fadeGradient } {display:none!important;}
                .${ tableStyles.tableWrap } {overflow:visible!important;width:auto!important;}               
                .${ styles.TableWrapper } {overflow:visible!important;width:auto!important;margin:0!important;}               
                .${ styles.highchart } {display:flex!important;justify-content:center!important;overflow:visible!important;width:auto!important;max-width:unset!important;}                                 
                .${ styles.highchart } > div {width:auto!important;margin:0!important;overflow:visible!important;} 
                .${ styles.chart } {width:auto!important;overflow:visible!important;margin:0!important;} 
                .${ styles.List } {width:auto!important;overflow:visible!important;margin:0!important;border:none!important;} 
                table {margin:0!important;border-collapse:collapse!important;width:100%!important;}
                table td {border: 1px solid #F1F2F3!important;}
                table th:first-child {border-left: 1px solid #F1F2F3!important;}
                table th:last-child {border-right: 1px solid #F1F2F3!important;}
                table th {border-top: 1px solid #F1F2F3!important;}
            `;
            settings.maxWidth = maxWidth;
        }

        printJS(settings);
    }

    // установка стилистических options для заголовка chart
    setTitleOptions(styleOptions) {
        const { current: node } = this.nodeRef;
        const { current: title } = this.titleRef;
        const { current: chart } = this.chartRef;
        if (!node || !title || !chart) {
            return;
        }
        // Обнуляем стили
        chart.style = {};
        const offsetChart = 11;
        if (styleOptions.title.showType !== 'report only' && styleOptions.title.showType !== 'always') {
            title.textContent = '';
            return;
        }
        if (styleOptions.title.text && styleOptions.title.text.length) {
            title.textContent = styleOptions.title.text;
        }
        else {
            title.textContent = this.reportState.name;
        }
        title.style['font-weight'] = styleOptions.title.style.fontWeight ? styleOptions.title.style.fontWeight : 'normal';
        if (styleOptions.title.style.fontSize) {
            const textSize = setTextSize(styleOptions.title.style.fontSize);
            title.style['font-size'] = textSize;
            title.style['line-height'] = '1.2';
        }
        else {
            title.style['font-size'] = '15px';
        }
        const {
            height,
            width,
        } = title.getBoundingClientRect();

        node.style['left'] = '0';
        node.style.top = '0';

        // расположение блока с заголовком
        switch (styleOptions.title.align) {
            case 'left':
                if (this.reportState.type && this.reportState.type.type_name === 'Pie' && this.chart && (this.chart.chartWidth / 2 - 212 > width)) {
                    // размеры чарта + расстояние до чарта  = 180 + 32 = 212
                    node.style['left'] = `${ this.chart.chartWidth / 2 - 212 - width }px`;
                }
                else {
                    node.style['left'] = '0';
                }
                break;
            case 'center':
                node.style['left'] = '50%';
                node.style['margin-left'] = - width / 2 + 'px';
                break;
            case 'right':
                if (this.reportState.type && this.reportState.type.type_name === 'Pie' && this.chart) {
                    // размеры чарта + расстояние до чарта  = 180 + 32 = 212
                    node.style['left'] = `${ this.chart.chartWidth / 2 + 212 + width }px`;
                }
                else {
                    node.style['left'] = '100%';
                    node.style['margin-left'] = - width + 'px';
                }
        }
        switch (styleOptions.title.verticalAlign) {
            case 'top':
                node.style.top = '0';
                break;
            case 'middle':
                node.style.top = 'calc(50% - ' + height + 'px)';
                break;
            case 'bottom':
                node.style.top = 'auto';
                node.style.bottom = '0';
        }
        // сдвиг chart относительно title
        if (styleOptions.title.style.fontSize) {
            chart.style['padding-top'] = '0px';
            chart.style['padding-left'] = '0px';
            chart.style['padding-bottom'] = '0px';
            if (!styleOptions.title.verticalAlign || styleOptions.title.verticalAlign === 'top') {
                chart.style['padding-top'] = height + 'px';
            }
            if (styleOptions.title.verticalAlign === 'bottom') {
                chart.style['padding-bottom'] = height + 'px';
            }
            if (styleOptions.title.verticalAlign === 'middle' && !(this.reportState.type && this.reportState.type.type_name === 'Pie')) {
                if (!styleOptions.title.align || styleOptions.title.align === 'left') {
                    // chart.style['margin-left'] = '32px';
                    chart.style['padding-left'] = width + offsetChart + 'px';
                }
            }
        }
        // title color
        if (styleOptions.title.style.color) {
            title.style['color'] = styleOptions.title.style.color;
        }
    }

    // получение всех полей таба Styles
    getStyles = (styleOptions = null) => {
        if (!this.reportState.reportData && !this.props.reportid) {
            return;
        }

        let styles = {};
        let type;

        if (this.reportState.report) {
            type = this.reportState.report.report_type.toLowerCase();
        }
        else if (this.reportState.reportData) {
            type = this.reportState.reportData.type.type_service_name.toLowerCase();
        }
        let options;

        if (this.reportState.reportData) {
            this.reportState.additionalData = this.reportState.reportData.chart.additional_data;
            this.reportState.aggregationColumn = this.reportState.reportData.chart.additional_data.aggregation_column_type_name;
        }

        if (styleOptions && this.reportState.reportData) {
            styles = styleOptions;
            const formData = _.keys(this.reportState.reportData.formData).length > 0 ?
                this.reportState.reportData.formData :
                extractFormDataFromRecord(this.reportState.reportData.chart.record_data);
            const data = {
                chart: this.reportState.reportData.chart,
                formData,
            };
            options = exportStyles(styles, type, data, this.reportState, true, this.sliceType);
        }
        else if (this.reportState.report && this.reportState.report.record_data) {
            const styles = extractStylesFromRecord(this.reportState.report.record_data);
            const report = {
                chart: this.reportState.report,
                formData: this.reportState.report.record_data,
            };
            options = exportStyles(styles, type, report, this.reportState, false, this.sliceType);
        }

        if (options && options.title) {
            this.optionsForUpdateTextStyle = options;
        }
        return options;
    };

    prepareData(data, config, displayTableName) {
        let type;
        if (this.reportState.report) {
            type = this.reportState.report.report_type.toLowerCase();
        }
        else if (this.reportState.reportData) {
            type = this.reportState.reportData.type.type_service_name.toLowerCase();
        }

        const groupBy = getCurrentGroupBy(this.reportState);
        switch (type) {
            case 'bar':
                if (!groupBy) return;
                if (!_.isEqual(this.sliceType, getParamValue(config, 'slice_type', true))) {
                    this.sliceType = getParamValue(config, 'slice_type', true);
                    if (this.reportState.reportStyles) {
                        this.reportState.styleOptions = this.getStyles(this.reportState.reportStyles);
                    }
                }
                const sliceBy = getParamValue(config, 'slice_by', true);
                const groupBySysName = getSysColumnNameById(groupBy.database_value, this.reportState);
                if (this.sliceType && sliceBy) {
                    const sliceBySysName = getSysColumnNameById(sliceBy, this.reportState);
                    return prepareDataForBar(data, this.sliceType === 'Grouped', groupBySysName, sliceBySysName, this.reportState);
                }
                else {
                    return formatItem(data, groupBySysName, this.reportState);
                }
            case 'heatmap':
                return prepareDataForHeatmap(data, config, this.reportState);
            case 'gauge':
                return prepareDataForGauge(data, this.reportState);
            case 'pivot':
                return prepareDataForPivot(data, config, this.reportState);
            case 'digits':
                return data;
            case 'multilevel pivot table':
                return prepareDataForMultiPivot(data, config, this.reportState);
            case 'list':
                return data;
            case 'line':
            case 'trends':
                return prepareDataWithTime(data, config, displayTableName, true, this.reportState);
            default:
                if (!groupBy) return;
                const fieldSysName = getSysColumnNameById(groupBy.database_value, this.reportState);
                return formatItem(data, fieldSysName, this.reportState);
        }
    }

    changeAdditionalGroupBy = ({ value }) => {
        const {
            reportid,
            getChart,
        } = this.props;
        if (reportid) {
            this.reportState.additionalGroupValue = value;
            fetchReportById(reportid, value.database_value, null, this.getStyles, this.reportState).catch(console.error);
        }
        else if (getChart) {
            getChart(null, value);
        }
    };

    renderDataTable(data, config, tableName, isTimeReport, groupBy) {
        let groupByFieldValue,
            groupBySysName,
            sliceByFieldValue,
            sliceBySysName;
        if (isTimeReport) {
            groupByFieldValue = getParamValue(config, 'trend_by');
            groupBySysName = getSysColumnNameById(getParamValue(config, 'trend_by', true), this.reportState);
            sliceByFieldValue = groupBy ? groupBy.display_value : null;
            sliceBySysName = groupBy ? getSysColumnNameById(groupBy.database_value, this.reportState) : null;
        }
        else {
            groupByFieldValue = groupBy.display_value;
            groupBySysName = getSysColumnNameById(groupBy.database_value, this.reportState);
            sliceByFieldValue = getParamValue(config, 'slice_by');
            sliceBySysName = getSysColumnNameById(getParamValue(config, 'slice_by', true), this.reportState);
        }

        const aggregationType = getParamValue(config, 'aggregation_type', true);
        const displayAggregationType = getParamValue(config, 'aggregation_type');
        const aggregationColumn = getParamValue(config, 'aggregation_column');
        const aggregationColHeader = (aggregationColumn ? aggregationColumn : tableName) + ' ' + displayAggregationType.toLowerCase();

        const tableData =
            prepareDataForDatatable(isTimeReport ?
                prepareDataForDatatableWithTime(data, config) :
                data, groupBySysName, sliceBySysName, aggregationType, this.reportState);

        return <ReportTable
            data={ tableData }
            groupByField={ groupByFieldValue }
            sliceByField={ sliceByFieldValue }
            aggregationColHeader={ aggregationColHeader }
            aggregationType={ aggregationType }
            displayAggregationType={ displayAggregationType }
            reportState={ this.reportState }
        />;
    }

    renderByData({
                     data,
                     config,
                     type,
                     name,
                 }) {
        if (!this.name && name) {
            this.name = name;
        }
        let stylesChart;
        if (this.reportState.styleOptions) {
            // удаление ключа title из массива с общими стилями
            stylesChart = _.clone(parseToJson(this.reportState.styleOptions));
            stylesChart.title = {
                text: '',
            };
        }
        switch (type) {
            case 'bar':
            case 'pie':
            case 'line':
            case 'gauge':
            case 'heatmap':
            case 'trends':
                if (!(data && name && stylesChart)) {
                    return null;
                }
                let sliceBy;
                if (type === 'bar') {
                    sliceBy = getParamValue(config, 'slice_by', true);
                }
                const aggregationType = getParamValue(config, 'aggregation_type', true);
                return (
                    <Chart
                        key={ simpleHashFromObject(data) }
                        styleOptions={ stylesChart }
                        data={ {
                            chart_data: data,
                            chart_type: type,
                            chart_label: name,
                        } }
                        reportState={ this.reportState }
                        isUpdateChart={ !!(this.reportState.stateFlags & IS_UPDATE_CHART) }
                        onChartReady={ this.onChartReady }
                        isStacked={ sliceBy && this.sliceType === 'Grouped' }
                        aggregationType={ aggregationType }
                        isDurationAverage={ this.reportState.isDurationAverage }
                    />
                );

            case 'digits':
                return (
                    <Digit
                        key={ simpleHashFromObject(data) }
                        type={ type }
                        data={ data }
                        reportState={ this.reportState }
                        isUpdateChart={ !!(this.reportState.stateFlags & IS_UPDATE_CHART) }
                        styleOptions={ stylesChart }
                        name={ name }
                        aggregationType={ getParamValue(config, 'aggregation_type', true) }
                        isDurationAverage={ this.reportState.isDurationAverage }
                    />
                );
            case 'pivot':
                return (
                    <PivotTable
                        key={ simpleHashFromObject(data) }
                        reportState={ this.reportState }
                        data={ data }
                        isUpdateChart={ !!(this.reportState.stateFlags & IS_UPDATE_CHART) }
                        columnFieldName={ getParamValue(config, 'column_id') }
                        rowFieldName={ getParamValue(config, 'row_id') }
                        aggregationType={ getParamValue(config, 'aggregation_type', true) }
                        displayAggregationType={ getParamValue(config, 'aggregation_type') }
                    />
                );

            case 'multilevel pivot table':
                return (
                    <MultiLevelPivotTable
                        reportState={ this.reportState }
                        columnHeaders={ data.colHeaders }
                        tableData={ data.tableData }
                        isUpdateChart={ !!(this.reportState.stateFlags & IS_UPDATE_CHART) }
                        dataColumnsAmount={ data.dataColsAmount }
                        columnCategories={ getParamValue(config, 'column_ids') }
                        rowCategories={ getParamValue(config, 'row_ids') }
                        aggregationType={ getParamValue(config, 'aggregation_type', true) }
                        displayAggregationType={ getParamValue(config, 'aggregation_type') }
                    />
                );

            case 'list':
                const { location } = this.props;
                const isReportPage = location && location.pathname && location.pathname.includes('/report');
                const horizontalScrollStyle = isReportPage ? styles.FixedHorizontalScroll : styles.NonFixedHorizontalScroll;
                const footerStyle = isReportPage ? styles.FixedListFooter : styles.ListFooter;
                const classes = {
                    TableWrapper: styles.TableWrapper,
                    Header: styles.Header,
                    Body: styles.Body,
                    InfoCell: styles.InfoCell,
                    Row: styles.Row,
                    fixedHorizontalScroll: horizontalScrollStyle,
                };
                if (!this.tableState) {
                    this.tableState = new TableStateClass();
                }
                return <>
                    <div className={ styles.List }>
                        <Table
                            key={ data.essence }
                            classes={ classes }
                            essence={ data.essence }
                            columns={ data.columns }
                            items={ data.items }
                            reportState={ this.reportState }
                            isUpdateChart={ !!(this.reportState.stateFlags & IS_UPDATE_CHART) }
                            conditionState={ this.reportState.conditionState }
                            isWindow={ false }
                            pagination={ data.pagination }
                            location={ this.props.location }
                            isBlankMode={ true }
                            tableState={ this.tableState }
                        />
                        <div className={ footerStyle }>
                            <Pagination pagination={ data.pagination } onChangePage={ this.onChangeListChartPage } />
                        </div>
                    </div>
                </>;

            default:
                return null;
        }
    }

    onChangeListChartPage = (page) => {
        const groupBy = getCurrentGroupBy(this.reportState);
        const {
            reportid,
            getChart,
        } = this.props;
        if (reportid) {
            fetchReportById(reportid, groupBy ? groupBy.database_value : null, page, this.getStyles, this.reportState).catch(console.error);
        }
        else if (getChart) {
            getChart(null, groupBy, page);
        }
    };

    renderReport({
                     data,
                     type,
                     name,
                 }) {
        const additionalGroup = this.reportState.additionalGroup;
        let groupBy = getCurrentGroupBy(this.reportState);
        if (!groupBy) {
            groupBy = {};
        }

        const config = getParamSection(data.record_data, STEPS[2]);
        const dataSection = getParamSection(data.record_data, STEPS[0]);
        const displayTableName = getParamValue(dataSection, 'table_id', false);
        // const styleOptions
        if (this.reportState.reportStyles && !_.isEqual(this.reportState.reportStyles, this.reportState.styleOptions)) {
            this.reportState.styleOptions = this.getStyles(this.reportState.reportStyles);
        }
        const pointsLimitMessage = this.reportState.additionalData ? (
            <div className={ styles.PointsLimitMessage }>
                { this.reportState.additionalData.points_limit_message }
            </div>
        ) : null;
        const report = langStore.getTranslateKey('report');
        return (
            <div onContextMenu={ this.handleContextMenu }>
                {
                    this.renderByData({
                        data: this.prepareData(data.report_data, config, displayTableName),
                        config,
                        type,
                        name,
                    })
                }
                { pointsLimitMessage }
                <div data-print="hide">
                    {
                        additionalGroup && (additionalGroup.length > 1) ?
                            <Select className={ styles.SelectGroupBy }
                                    special={ { values: additionalGroup } }
                                    label={ report.group_by }
                                    value={ groupBy }
                                    onChange={ this.changeAdditionalGroupBy } />
                            : null
                    }
                </div>
                {
                    getParamValue(config, 'add_data_table') === 1 &&
                    this.renderDataTable(data.report_data, config, displayTableName, type === 'line' || type === 'trends', groupBy)
                }
                {
                    this.renderContextMenu()
                }
            </div>
        );
    }

    @action
    handleContextMenu = (e) => {
        e.preventDefault();
        this.menuCoordinates.x = e.pageX;
        this.menuCoordinates.y = e.pageY;
        this.isShowContextMenu = true;
    };

    renderContextMenu() {
        if (!this.isShowContextMenu) {
            return null;
        }

        return (
            <ContextMenu
                items={ this.menuItems }
                x={ this.menuCoordinates.x }
                y={ this.menuCoordinates.y }
                isShowContextMenu={ this.isShowContextMenu }
                onToggleContextMenu={ this.handleToggleContextMenu }
            />
        );
    }

    handleToggleContextMenu = () => {
        this.isShowContextMenu = !this.isShowContextMenu;
    };

    render() {
        const { report_type } = langStore.getTranslate();

        let component = null;
        if (this.reportState.report) {
            const reportId = this.props.reportid;
            const type = this.reportState.report.report_type.toLowerCase();
            let name = this.reportState.report.report_name;
            // DEF0011995 Небходимо не полностью удалять имя, чтобы отображался отчёт
            if (!name) name = '&nbsp';
            component = this.renderReport({
                data: this.reportState.report,
                type,
                name,
                reportId,
            });
        }
        else if (this.reportState.reportData) {
            let {
                chart,
                type,
                name,
            } = this.reportState.reportData;
            // DEF0011995 Небходимо не полностью удалять имя, чтобы отображался отчёт
            if (!name) name = '&nbsp';
            if (!chart || !type) {
                return (
                    <ChartLoader
                        textLoading={ report_type && report_type.fill_in_the_data_tab }
                        isMasterReport={ !this.props.reportid } />
                );
            }
            if (!this.props.isLoadedChart) {
                return (
                    <ChartLoader isMasterReport={ !this.props.reportid } />
                );
            }
            const _type = type.type_service_name.toLowerCase();
            component = this.renderReport({
                data: chart,
                type: _type,
                name,
            });
        }
        const reportId = generateRandomString();

        return (
            <>
                <div className={ styles.chart } ref={ this.reportRef } id={ reportId } data-test={ ATTRIBUTES.reportChart }>
                    <div className={ styles.title } ref={ this.nodeRef }>
                        <div ref={ this.titleRef } className={ styles.text } />
                    </div>
                    <div ref={ this.chartRef } className={ styles.highchart }>
                        { component ? component : <ChartLoader isMasterReport={ !this.props.reportid } /> }
                    </div>
                </div>

            </>
        );
    }
}
