import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import { fetchRelatedColumnsLayoutData } from 'actions/layoutEdit';
import styles from './styles.module.scss';
import IconChevronLeft from 'assets/img/icons/chevron-left.svg';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import IconChevronsRight from 'assets/img/icons/chevrons-right.svg';
import IconChevronsLeft from 'assets/img/icons/chevrons-left.svg';
import IconDropDown from 'assets/img/select-arrow.svg';
import IconChevronsCross from 'assets/img/icons/close-x.svg';
import IconX from 'assets/img/icons/close-x.svg';
import { generateRandomString, isEqual } from 'helpers/data';
import IconSearch from 'assets/img/icons/search.svg';
import langStore from 'globalState/lang';
import Button from 'components/button';
import _ from 'lodash';
import { ATTRIBUTES } from 'constants/attributesForTests';
import InfoMessagesState from "globalState/infoMessages";

const TYPE_SELECTED = 'selected';
const TYPE_AVAILABLE = 'available';

@observer
export default class LayoutEditor extends React.Component {
    @observable availableItems = [];
    @observable selectedItems = [];
    @observable availableItemsFiltered = [];
    @observable activeItem = {
        available: new Set,
        selected: new Set,
    };
    @observable widget = null;
    @observable filterValue = '';
    @observable isDragOn = false;
    @observable itemDragOver = null;
    @observable mouseDirection = 'up';
    selectedItemsRollbackCopy = [];
    showWidgetOption;
    pressedKey = null;
    refAvail = React.createRef();
    refSelected = React.createRef();
    refWidget = React.createRef();
    refFilter = React.createRef();
    lastItem = {
        available: null,
        selected: null,
    };
    scrollTimeout = null;

    constructor(props) {
        super(props);
        this.updateData();
        this.showWidgetOption = this.props.showWidgetOption;
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.selectedItems, prevProps.selectedItems) || !isEqual(this.props.availableItems, prevProps.availableItems)) {
            this.updateData();
        }
    }

    updateData = () => {
        this.splitId = 0;
        this.dragObject = {};

        this.showSplitButtons = this.props.showSplitButtons;

        if (this.props.availableItems) {
            this.availableItems = this.props.availableItems.map((item) => {
                // проставляем флаг что элемент не выбран
                item.isSelected = false;
                return item;
            });
            this.filterAvailableItems();
        }

        if (this.props.selectedItems) {
            this.selectedItems = this.props.selectedItems.map((item) => {
                if (item.id === undefined) {
                    // если это сплит - генерим ID и добавляем флаг
                    item.id = this.generateSplitId();
                    item.isSplit = true;
                    item.splitType = item.split_type;
                }
                else {
                    const selectedId = item.id.split(',')[0];
                    const availableItem = _.find(this.availableItems, (item) => (item.id === selectedId));
                    if (availableItem) {
                        item.refItem = availableItem;
                    }
                }
                return item;
            });
        }

        this.props.onChange(this.getSelectedItems());

        reaction(
            () => this.selectedItems.length,
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.getSelectedItems());
                }
            },
        );
    };

    generateSplitId() {
        return 'splitId' + this.splitId++;
    }

    componentDidMount() {
        document.addEventListener('mousemove', this.handleDocumentMouseMove);
        document.addEventListener('keydown', this.handleDocumentKeyDown);
        document.addEventListener('keyup', this.handleDocumentKeyUp);
        document.addEventListener('mouseup', this.handleDocumentMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleDocumentMouseMove);
        document.removeEventListener('mouseup', this.handleDocumentMouseUp);
        document.removeEventListener('keydown', this.handleDocumentKeyDown);
        document.removeEventListener('keyup', this.handleDocumentKeyUp);
    }

    createAvatar() {
        const avatar = document.createElement('div');
        avatar.classList.add(styles.dragAvatar);

        const sorted = _.filter(Array.from(this.dragObject.items), (item) => item !== this.dragObject.item).sort((a, b) => a.currIndex - b.currIndex);
        sorted.unshift(this.dragObject.item);

        const nodes = sorted.map((item) => {
            return `<div>${ item.full_title || item.title || item.value }</div>`;
        });
        avatar.innerHTML = nodes.join('');

        return avatar;
    }

    startDrag() {
        document.body.appendChild(this.dragObject.avatar);
        this.isDragOn = true;

        // если тащим из выбранных
        if (this.dragObject.listType === TYPE_SELECTED) {
            // сохраняем копию для отката к первоначальному состоянию при отмене переноса
            this.selectedItemsRollbackCopy = JSON.parse(JSON.stringify(this.selectedItems));
            // отфильтровываем пункты находящиеся в процессе переноса
            this.selectedItems = _.filter(this.selectedItems,(item) => !Array.from(this.dragObject.items).includes(item));
        }
    }

    handleDocumentKeyDown = (e) => {
        if (e.keyCode !== 17 && e.keyCode !== 16) return;
        this.pressedKey = e.keyCode;
    };

    handleDocumentKeyUp = (e) => {
        if (e.keyCode !== 17 && e.keyCode !== 16) return;
        this.pressedKey = null;
    };

    handleDocumentMouseMove = (e) => {
        if (!this.dragObject.elem || (this.dragObject.item.isSelected && this.dragObject.listType === TYPE_AVAILABLE)) return; // элемент не зажат или уже находится в колонке выбранных

        if (!this.isDragOn) { // если перенос не начат...

            // посчитать дистанцию, на которую переместился курсор мыши
            const moveX = e.pageX - this.dragObject.downX;
            const moveY = e.pageY - this.dragObject.downY;
            if (Math.abs(moveX) < 3 && Math.abs(moveY) < 3) {
                return; // ничего не делать, мышь не передвинулась достаточно далеко
            }

            // создаём аватар
            this.dragObject.avatar = this.createAvatar();
            if (!this.dragObject.avatar) {
                this.dragObject = {}; // аватар создать не удалось, отмена переноса
                return; // возможно, нельзя захватить за эту часть элемента
            }

            // создаём вспомогательные свойства shiftX/shiftY
            const coords = this.dragObject.elem.getBoundingClientRect();
            this.dragObject.shiftX = this.dragObject.downX - coords.left;
            this.dragObject.shiftY = this.dragObject.downY - coords.top;

            this.startDrag(); // отобразить начало переноса
        }

        // перенос объекта при каждом движении мыши
        this.dragObject.avatar.style.left = e.pageX - this.dragObject.shiftX + 'px';
        this.dragObject.avatar.style.top = e.pageY - this.dragObject.shiftY + 'px';

        // обновляем направление движения мыши
        this.mouseDirection = this.getMouseDirection(e);

        return false;
    };

    getMouseDirection(e) {
        let direction;
        if (e.pageY < this.dragObject.prevY) {
            direction = 'up';
        }
        else {
            direction = 'down';
        }
        this.dragObject.prevY = e.pageY;

        return direction;
    }

    handleItemMouseDown(e, item, listType) {
        this.dragObject.elem = e.target;
        this.dragObject.downX = e.pageX;
        this.dragObject.downY = e.pageY;
        this.dragObject.prevY = e.pageY;
        this.dragObject.item = item;
        // если перемещаем элемент среди выделенных, то все остальные выделенные за ним
        if (this.activeItem[listType].size && this.activeItem[listType].has(item)) {
            this.dragObject.items = this.activeItem[listType];
        }
        else {
            this.dragObject.items = [item];
        }
        this.dragObject.listType = listType;
    }

    handleDocumentMouseUp = (e) => {
        // обработать перенос, если он идет
        if (this.isDragOn) {
            this.finishDrag(e);
        }

        // чистим "состояние переноса" dragObject
        this.dragObject = {};
    };

    handleItemMouseEnter = (item, listType) => () => {
        if(this.isDragOn &&  listType === TYPE_SELECTED) {
            this.dragObject.index = item.currIndex;
            this.itemDragOver = item;
        }
    };

    handleSelectedMouseLeave = () => () => {
        if(this.isDragOn) {
            this.itemDragOver = null;
        }
    };

    onChangeWidgetOption = (event) => {
        if (this.widget) {
            this.widget.widget_options = event.target.value;
        }
        if (this.props.onChangeWidgetOptions) {
            this.props.onChangeWidgetOptions(this.getWidgetOptions());
        }
    };

    updateSelectedItem = (item, isNewItem = false) => {
        const { form_layout_widget_instance = {} } = langStore.getTranslate();
        if (item.id && item.id.startsWith('widget')) {
            // для нового элемента виджета без уникального айди генерируем уникальный айди
            if (isNewItem) {
                if (!/widget\.\d+\..+/.test(item.id)) {
                    item.id = item.id + `.${ generateRandomString() }`;
                }
                this.props.onChange(this.getSelectedItems());
            }
            if (item.widget_application_name){
                let infoMessage = form_layout_widget_instance.another_application_message;
                if (infoMessage){
                    infoMessage = infoMessage.replace('{applicationName}', item.widget_application_name);
                }
                InfoMessagesState.pushInfo(infoMessage);
                this.closeWidget();
                return;
            }
            const { current: widget } = this.refWidget;
            widget.style.visibility = 'inherit';
            widget.style.right = '0px';

            this.widget = item;
        }
        else {
            this.closeWidget();
        }
    };

    finishDrag(e) {
        const dragObject = this.dragObject;
        const dropListType = this.getDropListType(e);
        const dragListType = dragObject.listType;

        // удаляем аватар, флаг переноса, драг-ховер
        this.isDragOn = false;
        this.itemDragOver = null;
        dragObject.avatar.remove();

        // не попали в дроп-зону
        if (!dropListType) {
            if (dragListType === TYPE_SELECTED) {
                this.selectedItems = JSON.parse(JSON.stringify(this.selectedItemsRollbackCopy));
            }
        }
        // бросаем в выбранные
        else if (dropListType === TYPE_SELECTED) {
            // из выбранных
            if (dragListType === TYPE_SELECTED) {
                if (typeof dragObject.index !== 'undefined' && !isNaN(dragObject.index)) {
                    const items = Array.from(dragObject.items);
                    const filtered = _.filter(items, (item) => item !== dragObject.item);
                    const sorted = filtered.sort((a, b) => a.currIndex - b.currIndex);
                    sorted.unshift(dragObject.item);

                    const selectedItemsClone = JSON.parse(JSON.stringify(this.selectedItems));
                    let lowestIndex = selectedItemsClone.length;
                    sorted.forEach((item) => {
                        lowestIndex = item.currIndex < lowestIndex ? item.currIndex : lowestIndex;
                    });

                    const insertIndex = this.mouseDirection === 'up' ? dragObject.index : ++dragObject.index;
                    selectedItemsClone.splice(insertIndex, 0, ...sorted);

                    this.selectedItems = JSON.parse(JSON.stringify(selectedItemsClone));
                }
                else {
                    this.selectedItems = JSON.parse(JSON.stringify(this.selectedItemsRollbackCopy));
                }

                // обновляем список активных элементов среди выбранных
                const activeIDs = Array.from(this.activeItem.selected).map(({ id }) => id);
                this.activeItem.selected.clear();
                this.selectedItems.forEach((item) => {
                    if(activeIDs.includes(item.id)) {
                        this.activeItem.selected.add(item);
                    }
                });
            }

            // из доступных
            if (dragListType === TYPE_AVAILABLE) {
                const insertIndex = this.mouseDirection === 'up' ? dragObject.index : ++dragObject.index;
                const items = Array.from(dragObject.items);
                const filtered = _.filter(items, (item) => item !== dragObject.item);
                const sorted = filtered.sort((a, b) => a.currIndex - b.currIndex);
                sorted.unshift(dragObject.item);
                sorted.reverse();

                for (const item of sorted) {
                    this.addToSelected(item, insertIndex);
                }
            }
        }
        // бросаем в доступные
        else if (dropListType === TYPE_AVAILABLE) {
            if (dragListType === TYPE_SELECTED) {
                for (const item of Array.from(this.dragObject.items)) {
                    this.addToAvailable(item);
                }
            }
        }
    }

    getDropListType(e) {
        // элемент под курсором мыши
        const elem = document.elementFromPoint(e.clientX, e.clientY);

        if (elem == null) {
            // такое возможно, если курсор мыши "вылетел" за границу окна
            return null;
        }

        const { current: selected } = this.refSelected;
        const { current: available } = this.refAvail;

        if (selected.contains(elem)) {
            return TYPE_SELECTED;
        }
        else if (available.contains(elem)) {
            return TYPE_AVAILABLE;
        }
        else {
            return null;
        }
    }

    addSplit(value, splitType) {
        const itemSplit = {
            id: this.generateSplitId(),
            isSplit: true,
            splitType: splitType,
            value: value,
        };

        if (this.activeItem.selected.size === 0) {
            // если нет активных пунктов

            // добавляем в конец
            this.selectedItems.push(itemSplit);
        }
        else {
            // если есть активные пункты
            // вычисляем самый нижний из активных в списке
            const indexes = Array.from(this.activeItem.selected, (item) => item.currIndex);
            const highestIndex = Math.max(...indexes);

            // добавляем новый пункт под самый нижний из активных
            this.selectedItems.splice(highestIndex + 1, 0, itemSplit);
        }
    }


    handleItemClick(item, listType) {
        if (this.pressedKey) {
            // нажат ctrl
            if (this.pressedKey === 17) {
                // если нажали на уже выбранный итем, то убираем его из выделения
                if (this.activeItem[listType].has(item)) {
                    this.activeItem[listType].delete(item);
                }
                else {
                    this.activeItem[listType].add(item);
                    this.lastItem[listType] = item;
                }
            }
            // нажат shift
            else if (this.pressedKey === 16) {
                // если уже выбран какой-либо элемент
                if (this.activeItem[listType].size && this.activeItem[listType]) {
                    let refItems;
                    if (listType === TYPE_SELECTED) {
                        refItems = this.selectedItems;
                    }
                    else {
                        refItems = this.availableItems;
                    }
                    let isActiveItems = false;
                    this.activeItem[listType].clear();
                    this.activeItem[listType].add(item);
                    this.activeItem[listType].add(this.lastItem[listType]);
                    // выбор всех элементов между activeItems
                    if (this.activeItem[listType].size > 1) {
                        refItems.forEach((currentItem) => {
                            if (isActiveItems) {
                                if (this.activeItem[listType].has(currentItem)) {
                                    isActiveItems = false;
                                }
                                else {
                                    this.activeItem[listType].add(currentItem);
                                }
                            }
                            else {
                                if (this.activeItem[listType].has(currentItem)) {
                                    isActiveItems = true;
                                }
                            }
                        });
                    }
                }
                this.pressedKey = null;
            }
        }
        else {
            this.activeItem[listType].clear();
            this.activeItem[listType].add(item);
            this.lastItem[listType] = item;
        }
        // Если выбран только один элемент в выбранных, то проверяем на виджет
        if (listType === TYPE_SELECTED && this.activeItem[listType].size === 1 && this.showWidgetOption) {
            this.updateSelectedItem(item);
        }
    }

    addToAvailable(item) {
        const currIndex = item.currIndex;
        const refItem = item.refItem;

        if (!item.isSplit) {
            if (refItem) {
                // если есть оригинал в доступных меняем ему флаг, чтобы он отображался
                refItem.isSelected = false;
                if (this.refWidget.current) {
                    this.closeWidget();
                }
            }
            // и это не виджет
            else if (!(item.id && item.id.startsWith('widget'))) {
                // если нет оригинала, добавляем элемент в список доступных
                const itemCopy = JSON.parse(JSON.stringify(item));
                itemCopy.isSelected = false;
                this.availableItems.push(itemCopy);
            }
        }

        this.selectedItems.splice(currIndex, 1);

        // снимаем выделение с активного
        this.activeItem.selected.delete(item);
        this.filterAvailableItems();
    }

    addToSelected(item, indexToAdd) {
        if (item.isSelected) return false; // если элемент уже выбран - отменяем все дальнейшие действия
        if (this.selectedItems.find((elem) => (elem.id === item.id))) return false; // если элемент является группой и уже выбран

        if (this.showWidgetOption) {
            if (!item.id.startsWith('widget')) {
                item.isSelected = true;
            }
        }
        else {
            item.isSelected = true;
        }


        const itemCopy = JSON.parse(JSON.stringify(item));

        itemCopy.refItem = item;

        if (typeof indexToAdd !== "undefined" && !isNaN(indexToAdd)) {
            // если передан indexToAdd значит это перенос из драга на конкретную позицию
            this.selectedItems.splice(indexToAdd, 0, itemCopy);
        }
        else {
            const { current: list } = this.refSelected;
            const listTop = list.getBoundingClientRect().top;
            const listChildren = list.children;
            const listChildrenLength = listChildren.length;
            let scrollTop;

            if (this.activeItem.selected.size === 0) {
                // если нет активных пунктов

                // вычисляем scrollTop
                scrollTop = listChildrenLength === 0 ? 0 : listChildren[listChildrenLength - 1].getBoundingClientRect().top - listTop;

                // добавляем в конец
                this.selectedItems.push(itemCopy);
            }
            else {
                // если есть активные пункты
                // вычисляем самый нижний из активных в списке
                const indexes = Array.from(this.activeItem.selected, (item) => item.currIndex);
                const highestIndex = Math.max(...indexes);

                // вычисляем scrollTop
                scrollTop = listChildrenLength === 0 ? 0 : listChildren[highestIndex].getBoundingClientRect().top - listTop;

                // добавляем новый пункт под самый нижний из активных
                this.selectedItems.splice(highestIndex + 1, 0, itemCopy);
            }

            // скролим список к свежедобавленным
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = setTimeout(() => {
                list.scrollTop = list.scrollTop + scrollTop;
            }, 100);
        }

        // снимаем выделение с активного
        this.activeItem.available.delete(item);
        if (this.showWidgetOption) {
            const newItem = this.selectedItems.find((currentItem) => currentItem.id === itemCopy.id);
            if (newItem) {
                this.updateSelectedItem(newItem, true);
            }
        }
    }

    addAll() {
        this.activeItem[TYPE_AVAILABLE].clear();

        for (const item of this.availableItems) {
            this.addToSelected(item);
        }
    }

    removeAll() {
        this.activeItem[TYPE_SELECTED].clear();

        for (const item of this.selectedItems) {
            const refItem = item.refItem;

            if (!item.isSplit) {
                if (refItem) {
                    // если есть оригинал в доступных меняем ему флаг, чтобы он отображался
                    refItem.isSelected = false;
                }
                else {
                    // если нет оригинала, добавляем элемент в список доступных
                    const itemCopy = JSON.parse(JSON.stringify(item));
                    itemCopy.isSelected = false;
                    this.availableItems.push(itemCopy);
                }
            }
        }
        this.selectedItems = [];
        this.filterAvailableItems();
    }

    moveActive(listType) {
        if (!this.activeItem[listType].size) return false;

        const sortActiveItem = Array.from(this.activeItem[listType]).sort((a, b) => b.currIndex - a.currIndex);

        if (listType === TYPE_SELECTED) {
            for (const item of sortActiveItem) {
                this.addToAvailable(item);
            }
        }
        else {
            this.activeItem[listType].forEach(item => this.addToSelected(item));
        }
    }

    changeActiveOrder(direction) {
        const activeItem = this.activeItem[TYPE_SELECTED];
        if (activeItem === null || !activeItem.size) return false;
        const sortActiveItem = Array.from(this.activeItem[TYPE_SELECTED]).sort((a, b) => (b.currIndex - a.currIndex) * direction);
        for (const item of sortActiveItem) {
            const newPosition = item.currIndex + direction >= 0 ? item.currIndex + direction : 0;
            this.selectedItems.splice(item.currIndex, 1);
            this.selectedItems.splice(newPosition, 0, item);
        }
    }

    handleSubBtnClick = async (e, item) => {
        const { urlLayout, essence, params } = this.createRequest(item);
        e.stopPropagation();
        if (!item.isFetched) {
            item.isFetched = true;

            const responseColumns = await fetchRelatedColumnsLayoutData(urlLayout, essence, params);
            // когда получили данные добавляем всем элементам поле isSelected для манипуляций с перемещением
            item.items = responseColumns.data.related_columns.map((item) => {
                item.isSelected = false;
                return item;
            });
        }
        item.isOpened = !item.isOpened;
    };

    createRequest = (item) => {
        const { search, pathname } = this.props.location;
        let [, urlLayout, essence] = pathname.split('/');

        const params = {
            id: item.id,
        };

        // получаем параметры для запроса из url
        const searchParams = new URLSearchParams(search);
        searchParams.forEach((value, key) => {
            if ([
                'view',
                'current_view',
                'form_id',
            ].includes(key)) {
                params[key] = value;
            }
        });

        if (urlLayout === 'list-layout-personal') {
            urlLayout = 'list-layout';
            params.personal = true;
        }

        if (urlLayout === 'list-layout') {
            params.view = params.current_view ? params.current_view : params.view;
            delete params.current_view;
        }

        return {
            urlLayout,
            essence,
            params,
        };
    };

    getSelectedItems() {
        const selected = [];

        for (const item of this.selectedItems) {
            let id;
            if (item.isSplit) {
                id = item.splitType;
            }
            else {
                id = item.id;
            }
            selected.push(id);
        }
        return selected;
    }

    getWidgetOptions() {
        const options = {};

        this.selectedItems.forEach((item) => {
            if (item.id.startsWith('widget')) {
                options[item.id] = item.widget_options ? item.widget_options : {};
            }
        });
        return options;
    }

    renderSelected(items) {
        const listType = TYPE_SELECTED;
        return items.map((item, index) => {
            const itemClassList = [styles.boxItem];
            if (this.activeItem[listType] && this.activeItem[listType].has(item)) {
                itemClassList.push(styles.active);
            }
            if (item.id && item.id.startsWith('widget')) {
                itemClassList.push(styles.widgetItem);
            }


            // сохраняем текущий индекс для последующих манипуляций
            item.currIndex = index;

            return (
                <React.Fragment key={ index }>
                    { this.isDragOn && item === this.itemDragOver && this.mouseDirection === 'up' && <span className={ styles.boxSpacer }/>}
                    <span
                        onMouseEnter={ this.handleItemMouseEnter(item, listType) }
                        onMouseMove={ this.handleItemMouseEnter(item, listType) }
                        onMouseDown={ (e) => this.handleItemMouseDown(e, item, listType) }
                        onDoubleClick={ () => this.addToAvailable(item) }
                        onClick={ () => this.handleItemClick(item, listType) }
                        className={ itemClassList.join(' ') }
                        data-test={ ATTRIBUTES.layoutEditorSelectedItem }
                    >
                        <span className={ styles.text }>{ item.full_title || item.title || item.value }</span>
                    </span>
                    { this.isDragOn && item === this.itemDragOver && this.mouseDirection === 'down' && <span className={ styles.boxSpacer }/>}
                </React.Fragment>
            );
        });
    }

    renderAvailable(items) {
        const listType = TYPE_AVAILABLE;
        return items.map((item, index) => {
            // если это элемент подменю, он не корневой, и он уже есть в списке выбранных - не рендерим его
            if (item.isSelected && !item.is_foreign_key) return null;

            const subItems = typeof item.items !== 'undefined' ? item.items : [];
            const btnIcon = <div className={ `${ styles.arrow } ${ item.isOpened ? styles.active : '' }` } dangerouslySetInnerHTML={ { __html: IconDropDown } } />;
            const itemClassList = [styles.boxItem];
            if (item.is_foreign_key) {
                itemClassList.push(styles.has_sub);
            }
            if (this.activeItem[listType] && this.activeItem[listType].has(item)) {
                itemClassList.push(styles.active);
            }
            if (item.id && item.id.startsWith('widget')) {
                itemClassList.push(styles.widgetItem);
            }
            // сохраняем текущий индекс для последующих манипуляций
            item.currIndex = index;

            return <div key={ index }>
                        <span
                            onMouseDown={ (e) => this.handleItemMouseDown(e, item, listType) }
                            onDoubleClick={ () => this.addToSelected(item) }
                            onClick={ () => this.handleItemClick(item, listType) }
                            className={ itemClassList.join(' ') }
                            data-test={ ATTRIBUTES.layoutEditorAvailableItem }
                        >
                            <span className={ styles.text }>{ item.title }</span>

                            { item.is_foreign_key &&
                            <span className={ styles.icon } onDoubleClick={ e => e.stopPropagation() } onClick={ (e) => this.handleSubBtnClick(e, item) }>
                                { btnIcon }
                            </span>
                            }
                        </span>
                { (subItems.length > 0 && item.isOpened) &&
                <div className={ styles.boxSub }>
                    { this.renderAvailable(subItems) }
                </div>
                }
            </div>;

        });
    }

    closeWidget = () => {
        this.widget = null;
        const { current: widget } = this.refWidget;
        widget.style.right = '-250px';
        widget.style.visibility = 'hidden';
    };

    renderWidgetOption() {
        const { form_layout_widget_instance = {} } = langStore.getTranslate();
        return (
            <div className={ styles.widget } ref={ this.refWidget }>
                <div className={ styles.headerWidget }>
                    <div className={ styles.title }>
                        { form_layout_widget_instance.title }
                    </div>
                    <span className={ styles.cross } dangerouslySetInnerHTML={ { __html: IconChevronsCross } } onClick={ this.closeWidget } />
                </div>
                <div className={ styles.bodyWidget }>
                    { form_layout_widget_instance.option_schema_values }
                    <input type="text"
                           value={ this.widget && this.widget.widget_options ? this.widget.widget_options : '' }
                           onChange={ this.onChangeWidgetOption } />
                </div>
            </div>
        );
    }

    handleChangeFilter = (e) => {
        this.filterValue = e.target.value;
        this.filterAvailableItems();
    };

    filterAvailableItems = () => {
        if (this.filterValue) {
            const filterItems = this.availableItems.map(item => {
                const subItemsFiltered = item.hasOwnProperty('items') ? item.items.filter(this.matchUpWithFilter) : [];
                const itemFiltered = this.matchUpWithFilter(item) || subItemsFiltered.length > 0 ? { ...item } : null;
                if (subItemsFiltered.length > 0) {
                    itemFiltered.isOpened = true;
                    itemFiltered.items = [...subItemsFiltered];
                }
                return itemFiltered;
            });
            this.availableItemsFiltered = filterItems.filter(item => item !== null);

        }
        else {
            this.availableItemsFiltered = [...this.availableItems];
        }
    };

    matchUpWithFilter = (item) => {
        return item.title.toString().toLowerCase().includes(this.filterValue.toLowerCase());
    };

    cleanFilter = () => {
        this.filterValue = '';
        this.filterAvailableItems();
        this.refFilter.current.focus();
    };

    render() {
        const { user_menu_titles } = langStore.getTranslate();
        const { lang } = this.props;

        let selectedInfo = lang && lang.selected;
        if (this.props.selectedInfo) {
            selectedInfo = <div>{ this.props.selectedInfo.related_list_name }</div>;
        }

        const filterIcon = this.filterValue ?
            <div className={ `${ styles.icon } ${ styles.hover }` } dangerouslySetInnerHTML={ { __html: IconX } } onClick={ this.cleanFilter } /> :
            <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconSearch } } onClick={ () => {
                this.refFilter.current.focus();
            } } />;

        const availableList = this.filterValue && this.availableItemsFiltered.length === 0 ?
            <div className={ styles.noResults }>No results found</div> :
            this.renderAvailable(this.availableItemsFiltered);

        return (
            <div className={ styles.box }>
                <div className={ styles.boxContent }>
                    <div className={ styles.boxCol }>
                        <div className={ styles.boxTitle }>
                            { lang && lang.available }
                        </div>
                        <div className={ styles.boxListWrap }>
                            <div className={ styles.boxSearch }>
                                <div className={ styles.input }>
                                    <input
                                        type="text"
                                        onChange={ this.handleChangeFilter }
                                        value={ this.filterValue }
                                        ref={ this.refFilter }
                                        placeholder={ user_menu_titles && user_menu_titles.search }
                                        data-test={ ATTRIBUTES.layoutEditorAvailableSelectSearch }
                                    />
                                    { filterIcon }
                                </div>
                            </div>
                            <div className={ styles.boxList } ref={ this.refAvail } data-test={ ATTRIBUTES.layoutEditorAvailableSelect }>
                                { availableList }
                            </div>
                        </div>
                    </div>
                    <div className={ styles.boxButtons }>
                        <div className={ styles.item }>
                            <Button
                                onClick={ () => this.moveActive(TYPE_AVAILABLE) }
                                title="add"
                                buttonType={ 'icon-border' }
                                svg={ IconChevronRight }
                                data-test={ ATTRIBUTES.layoutEditorToSelectedButton }
                            />
                        </div>
                        <div className={ styles.item }>
                            <Button
                                onClick={ () => this.moveActive(TYPE_SELECTED) }
                                title="remove"
                                buttonType={ 'icon-border' }
                                svg={ IconChevronLeft }
                                data-test={ ATTRIBUTES.layoutEditorToAvailableButton }
                            />
                        </div>
                        <div className={ styles.item }>
                            <Button
                                onClick={ () => this.addAll() }
                                title="add all"
                                buttonType={ 'icon-border' }
                                svg={ IconChevronsRight }
                                data-test={ ATTRIBUTES.layoutEditorToSelectedAllButton }
                            />
                        </div>
                        <div className={ styles.item }>
                            <Button
                                onClick={ () => this.removeAll() }
                                title="remove all"
                                buttonType={ 'icon-border' }
                                svg={ IconChevronsLeft }
                                data-test={ ATTRIBUTES.layoutEditorToAvailableAllButton }
                            />
                        </div>
                    </div>
                    <div className={ styles.boxCol }>
                        <div className={ styles.boxTitle }>
                            { selectedInfo }
                        </div>
                        <div className={ styles.boxListWrap }>
                            <div className={ styles.boxList } ref={ this.refSelected } data-test={ ATTRIBUTES.layoutEditorSelectedSelect } onMouseLeave={ this.handleSelectedMouseLeave() }>
                                { this.renderSelected(this.selectedItems) }
                            </div>
                        </div>
                    </div>
                    <div className={ `${ styles.boxButtons } ${ styles.last }` }>
                        <div className={ styles.item }>
                            <Button
                                onClick={ () => this.changeActiveOrder(-1) }
                                title="up"
                                buttonType={ 'icon-border' }
                                svg={ IconChevronUp }
                                data-test={ ATTRIBUTES.layoutEditorSelectedItemUpButton }
                            />
                        </div>
                        <div className={ styles.item }>
                            <Button
                                onClick={ () => this.changeActiveOrder(+1) }
                                title="down"
                                buttonType={ 'icon-border' }
                                svg={ IconChevronDown }
                                data-test={ ATTRIBUTES.layoutEditorSelectedItemDownButton }
                            />
                        </div>
                        { this.showSplitButtons === true &&
                        <div className={ styles.splits }>
                            <div className={ styles.item }>
                                <Button
                                    onClick={ () => this.addSplit(`|-begin_split-|`, 'begin_split') }
                                    className={ styles.btnText }
                                    title="begin_split"
                                    data-test={ ATTRIBUTES.layoutEditorSelectedBeginSplitButton }
                                >
                                    { lang && lang.begin_split_button }
                                </Button>
                            </div>
                            <div className={ styles.item }>
                                <Button
                                    onClick={ () => this.addSplit(`|-split-|`, 'split') }
                                    className={ styles.btnText }
                                    title="split"
                                    data-test={ ATTRIBUTES.layoutEditorSelectedSplitButton }
                                >
                                    { lang && lang.split_button }
                                </Button>
                            </div>
                            <div className={ styles.item }>
                                <Button
                                    onClick={ () => this.addSplit(`|-end_split-|`, 'end_split') }
                                    className={ styles.btnText }
                                    title="end_split"
                                    data-test={ ATTRIBUTES.layoutEditorSelectedEndSplitButton }
                                >
                                    { lang && lang.end_split_button }
                                </Button>
                            </div>
                        </div>
                        }
                    </div>
                    { this.showWidgetOption && this.renderWidgetOption() }
                </div>
            </div>

        );
    }
}
