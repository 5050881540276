import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';
import styles from 'components/login/styles.module.scss';
import FormServerErrors from 'components/formItems/serverErrors';
import LoginInput from 'components/login/input';
import LoginButton from 'components/login/button';
import Select from 'components/login/select';
import Help from 'components/login/help';
import SmartTitle from 'components/smartTitle';
import { fetchRestPass } from 'actions/auth';
import langStore from 'globalState/lang/index';
import brandingState from 'globalState/branding/index';
import FormValidator from 'helpers/formValidator';
import { validateEmail } from 'helpers/misc';
import AuthHOC from 'helpers/hoc/authHoc';
import {ROUTES} from "constants/routers";
import {AuthPagesProps, FormFields} from "types/page/loginPage";
import {LoginData} from "types/globalState/user";

@observer
class ResetPasswordPage extends React.Component<AuthPagesProps> {
    @observable formFields: FormFields = {
        email: FormValidator.createFormFieldObj(),
    };
    @observable serverError = '';
    @observable isFetching = false;

    // Validate form and submit
    validateAndSubmit = async () => {
        if (this.isFetching) return;
        const { langData } = this.props;
        this.isFetching = true;

        const fv = new FormValidator(this.formFields);
        const promises: Array<Promise<void>> = [];

        // Validate username
        promises.push(
            fv.validateField(this.formFields.email, (email) => {
                if (!validateEmail(email)) return 'Invalid email format';
            }),
        );

        // Await for validations
        await Promise.all(promises);

        const isValid = fv.isFieldsValid();

        if (!isValid) {
            this.isFetching = false;
            return false;
        }

        try {
            const data = fv.getFieldsData();
            (data as LoginData).language = langData.langValue as string;
            await fetchRestPass({ language: (data as LoginData).language }, data);
        }
        catch (e) {
            const errorsParsed = fv.applyServerValidationErrors(e.response.data);
            if (!errorsParsed) this.serverError = fv.serverErrorMessage || e.message;
        }
        finally {
            this.isFetching = false;
        }
    };

    handleSwitchLang = (lang) => {
        const { onFetchTranslate } = this.props;

        this.serverError = '';
        for (const field in this.formFields) {
            if (this.formFields.hasOwnProperty(field)) {
                this.formFields[field].errorMessage = '';
            }
        }
        if (onFetchTranslate) {
            onFetchTranslate(lang);
        }
    };

    handleValueChange = (e) => {
        const { name, value } = e.target;
        this.formFields[name].value = value;
    };

    onFormKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.validateAndSubmit().catch(console.error);
        }
    };

    render() {
        const { reset_page = {}, placeholders = {}, help = {} } = langStore.getTranslate();
        const { langData } = this.props;

        return (
            <div className={ styles.Form } onKeyPress={ this.onFormKeyPress }>
                <SmartTitle hidden>{ reset_page.title }</SmartTitle>
                <Select
                    onChange={ this.handleSwitchLang }
                    databaseValue={ langData.langValue }
                    options={ langData.langSpecial }
                />
                <Help title={ help.title } children={ help.content } />
                <div className={ styles.Logo }>
                    <img src={ brandingState.getPlatformLogo() } />
                </div>
                <div className={ styles.TextField }>{ reset_page.text }</div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders.email }
                        name="email"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.email.errorMessage }
                        autoFocus
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginButton
                        isLoading={ this.isFetching }
                        onClick={ this.validateAndSubmit }
                        buttonType='primary'
                        disabled={ !this.formFields.email.value }
                    >
                        { reset_page.submit_button }
                    </LoginButton>
                </div>
                <FormServerErrors msg={ this.serverError } />
                <div className={ `${ styles.LinksField } ${ styles.alignCenter }` }>
                    <div>
                        <Link className={ styles.Link } to={ROUTES.LOGIN}>{ reset_page.login_link }</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export const ResetPasswordComponent = ResetPasswordPage;
export default AuthHOC(ResetPasswordPage);
