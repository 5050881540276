import axios from 'axios';
import userState from 'globalState/user';
import InfoMessagesState from 'globalState/infoMessages';
import debuggerState from 'globalState/debugger';
import { showInfoMessages } from 'helpers/messages';
import { helperRedirect } from 'helpers/history';
import langStore from 'globalState/lang';
import FormsState from 'globalState/forms';
import cache, { DAY } from 'globalState/cache';
import { beforeUnload } from 'helpers/form';
import _ from 'lodash';

let apiUrl = window.API_URL;
if (!apiUrl) {
    const def = 'https://infra02.dev.simpleone.ru/v1';
    try {
        apiUrl = API_URL || def;
    }
    catch (e) {
        apiUrl = def;
    }
}

export const API_BASE_URL = apiUrl;
export const CODE_401_AUTHORIZED = 401;
export const CODE_403_FORBIDDEN = 403;
export const CODE_404_NOTFOUND = 404;
export const CODE_422_VALIDATE = 422;
export const CODE_301_MOVED = 301;
export const CODE_307_REDIRECT = 307;
export const EXCLUDE_PATH = ['/ajax-script/script-to-xml'];

window.API_BASE_URL = apiUrl;

function getDataHandler(resp) {
    return () => {
        return resp.data || resp.Data;
    };
}


class ApiRequest {
    __unifyErrorsHandler = true;
    __method = 'GET';
    __url = '/';
    __options = {
        headers: {},
    };
    __data = null;
    __onUploadProgress = false;
    __ttl = 0;
    __addToCache = false;
    __getFromCache = false;


    constructor(url = 'GET /', unifyErrorsHandler = true) {
        this.__unifyErrorsHandler = unifyErrorsHandler;
        const spaceIndex = url.indexOf(' ');
        this.__method = url.substr(0, spaceIndex).trim().toUpperCase();
        this.__url = url.substr(spaceIndex + 1).trim();
    }

    paramsToUrl = (params = {}, parentKey = '') => {
        const esc = encodeURIComponent;
        return Object.keys(params)
                     .map(k => {
                         let key = k;
                         if (parentKey) {
                             key = `${ parentKey }[${ k }]`;
                         }
                         if (params[k] && typeof params[k] === 'object') {
                             return this.paramsToUrl(params[k], k);
                         }
                         return esc(key) + '=' + esc(params[k]);
                     })
                     .join('&');
    };

    qs(params = {}) {
        const query = this.paramsToUrl(params);

        this.__url += (!this.__url.includes('?') ? '?' : '&') + query;

        return this;
    }

    onUploadProgress(func) {
        this.__onUploadProgress = func;

        return this;
    }

    options(options = {}) {
        this.__options = {
            ...this.options,
            ...options,
        };

        return this;
    }

    async __send(handleForbiddenError) {
        const options = {
            ...this.__options,
            method: this.__method,
        };
        if (this.__data !== null) options.data = this.__data;

        const userAccessToken = getUserAccessToken();
        if (userAccessToken) options.headers['Authorization'] = 'Bearer ' + userAccessToken;

        const elevateRoles = window.localStorage.getItem('elevateRoles');
        if (elevateRoles && userAccessToken) {
            let elevateRolesOn = [];
            _.forEach(JSON.parse(elevateRoles), (value, key) => {
                if (value === 'on') {
                    elevateRolesOn = [ ...elevateRolesOn, key ];
                }
            });
            if (elevateRolesOn.length > 0) {
                options.headers['X-ELEVATED-ROLES'] = `${ elevateRolesOn.join(',') }`;
            }
        } else if (!userAccessToken) {
            window.localStorage.removeItem('elevateRoles');
            window.localStorage.removeItem('elevateRolesTime');
        }

        const baseUrl = window.API_BASE_URL || API_BASE_URL;
        options.url = baseUrl + this.__url;

        // Upload progress
        options.onUploadProgress = (progressEvent) => {
            const uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            if (typeof this.__onUploadProgress === 'function') {
                this.__onUploadProgress(uploadPercentage);
            }
        };

        // Если запрос в кэше, добавляем директиву if-modified-since
        if (this.__getFromCache && !cache.isCacheStale(options)) {
            const cacheTime = cache.getTimeCreateCache(options);
            if (cacheTime) {
                options.headers['If-Modified-Since'] = cacheTime;
            }
        }

        let response;

        let error;

        try {
            response = await axios(options);
        }
        catch (e) {
            error = e;
            response = e.response;
        }
        finally {

        }

        let resp;
        if (response) {
            resp = response.data;
        }
        else {
            return {};
        }

        const isReloadPage = resp.status === CODE_307_REDIRECT && resp.location === window.location.href;
        if (resp.messages && !isReloadPage) {
            showInfoMessages(resp.messages);
        }
        if (resp.page_debug_id) {
            debuggerState.setId(resp.page_debug_id);
        }

        /**
         * Not authorized
         */
        switch (response.status) {
            case CODE_401_AUTHORIZED:
                if (EXCLUDE_PATH.includes(this.__url)) return;
                userState.logout(false);
                break;
            case CODE_403_FORBIDDEN:
                if (handleForbiddenError) {
                    userState.setAccessError(CODE_403_FORBIDDEN);
                }
                break;
            case CODE_404_NOTFOUND:
                const unload = beforeUnload({});
                if (unload && !confirm(unload)) {
                    return;
                }
                userState.setAccessError(CODE_404_NOTFOUND);
                break;
            case CODE_422_VALIDATE:
                this.setValidateError(resp);
                break;
        }

        if ((resp.status === CODE_301_MOVED || resp.status === CODE_307_REDIRECT) && resp.location && resp.location.length) {
            if (isReloadPage) {
                window.localStorage.setItem('messages', JSON.stringify(resp.messages));
            }
            helperRedirect(resp.location);
            return resp;
        }

        /**
         * Success
         */
        const cacheOptions = _.cloneDeep(options);
        delete cacheOptions.headers['If-Modified-Since'];
        if (response.status >= 200 && response.status < 300) {
            if (typeof resp === 'string') {
               return resp;
            } else {
                resp.getData = getDataHandler(resp);

                if (resp.data.dictionary && typeof resp.data.dictionary === 'object') {
                    langStore.addTranslate(resp.data.dictionary);
                }

                if (this.__addToCache) {
                    cache.add(resp, cacheOptions, this.__ttl);
                }
            }

            return resp;
        }

        // Если пришёл ответ с 304, берём запрос из кэша
        if (response.status === 304) {
            const cacheData = cache.getByData(cacheOptions);
            if (cacheData) {
                cacheData.getData = getDataHandler(cacheData);
                if (cacheData.data && cacheData.data.dictionary && typeof cacheData.data.dictionary === 'object') {
                    langStore.addTranslate(cacheData.data.dictionary);
                }
                return cacheData;
            }
        }

        /**
         * Errors
         */
        else {
            if ((response.status !== CODE_403_FORBIDDEN || (response.status === CODE_403_FORBIDDEN && !handleForbiddenError)) && response.status !== CODE_404_NOTFOUND) {
                error.message = (resp && resp.message) || error.message;

                if (!this.__unifyErrorsHandler) throw error;

                // Universal error handling here
                console.error(error);

                try {
                    let msg = 'Internal server error';

                    if (resp.errors && Array.isArray(resp.errors) && resp.errors.length) {
                        msg = '';
                        for (const _msg of resp.errors) {
                            msg += _msg.message + ' ';
                        }
                    }
                    else {
                        msg = error.message;
                    }

                    InfoMessagesState.pushError(msg);
                }
                catch (e) {
                    console.error(e);
                }
            }
            throw error;

        }
    }

    setValidateError(response) {
        if (response.errors && response.errors.length) {
            FormsState.setFieldsMessages(response.errors);
        }
    }

    async sendJSON(data = {}) {
        this.__data = JSON.stringify(data);

        this.__options.headers = {
            'Content-Type': 'application/json',
        };
        return this.__send();
    }

    async send(data = null, handleForbiddenError = false) {
        this.__data = data;

        return this.__send(handleForbiddenError);
    }

    caching(addToCache = true, getFromCache = true, ttl = DAY) {
        this.__ttl = ttl;
        this.__addToCache = addToCache;
        this.__getFromCache = getFromCache;
        return this;
    }
}


export function getUserAccessToken() {
    return window.localStorage.getItem('accessToken');
}


export default ApiRequest;
