import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import * as _ from 'lodash';
import styles from './styles.module.scss';
import TextEditor from "components/textEditor";
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import RichTextModel from 'components/dynamicForms/model/field/RichTextModel';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import {isChanged} from "helpers/form";

@observer
export default class RichText extends React.Component {
    @observable model;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        }
        else {
            this.model = new RichTextModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            }
            else {
                this.model.mergeData(this.props);
            }
        }
    }

    onChange = (value) => {
        this.model.value = value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);

        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    render() {
        const { readonly: readOnly, value } = this.model;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ styles.HTMLInput }
                    data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldRichText }` }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                    ref={ this.model.ref }
                >
                    <TextEditor
                        readOnly={readOnly}
                        value={value}
                        onChange={this.onChange}
                        setRawEditor={this.model.setRawEditor}
                    />
                </div>
            </FieldWrapper>
        );
    }
}
