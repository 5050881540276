import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styles from './styles.module.scss';
import SearchResultsTree from './Tree';
import SearchResultsCard from './Card';
import PageLoader from 'components/pageLoader';
import Search from 'components/portalWidgetsComponents/Search';
import QuickButtonReturn from 'components/quickButtonReturn';
import Button from 'components/button';
import langStore from 'globalState/lang';
import { fetchSearchCategories, fetchSearchResultsData } from 'actions/search';
import { goBack } from 'helpers/history';
import { getRecordLink } from 'helpers/widget';
import IconKebab from 'assets/img/icons/more-vertical-kebab.svg';
import IconX from 'assets/img/icons/close-x.svg';
import IconArrowLeft from 'assets/img/icons/arrow-left.svg';
import ChevronRight from 'assets/img/icons/chevron-right.svg';
import NoResults from 'assets/img/icons/no-results.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { SearchResultProps } from 'types/components/portalWidgetsComponents/searchResult/searchResult';
import { GroupTable, Record } from 'types/actions/search';

/**
 * Props:
 * breadcrumbs - массив хлебных крошек
 * searchQuery - искомое выражение
 * isTreeShown - отображать ли дерево результатов поиска
 * tsGroupId - идентификатор группы таблиц, в которых ведется поиск
 * quantity - количество результатов на странице
 * itemPage - страница для перехода по ссылке в элементе подсказки
 * itemView - view страницы
 * */
@observer
export class SearchResultsWidget extends React.Component<SearchResultProps> {
    @observable displayedData: Record[] = [];
    @observable data: Record[] = [];
    @observable tables: GroupTable[] = []; // Группы найденных записей (соответствуют таблицам)
    @observable page = 0;
    @observable isPopupOpened = false;
    @observable loading = false;
    @observable isLastPost = false;
    @observable isMoreLoaded = false;
    @observable isShowQuickBtn = false;
    @observable isCategoryEmpty = false;
    @observable chosenGroup = ''; // Выбранная группа
    perPage = 20;
    timeout: NodeJS.Timeout;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { quantity } = this.props;
        this.perPage = parseInt(quantity) || this.perPage;
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.searchQuery !== this.props.searchQuery) {
            this.fetchData();
        }
    }

    get isDataNotFound(): boolean {
        const { searchQuery } = this.props;
        return !!searchQuery && !this.data.length && !this.loading;
    }

    freezeBodyScroll = () => {
        const { body } = document;

        if (this.isPopupOpened) {
            body.classList.add(styles.Freeze);
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${ scrollY }px`;
        } else {
            body.classList.remove(styles.Freeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    };

    fetchData = async () => {
        const { searchQuery } = this.props;
        if (!searchQuery) return;

        this.loading = true;
        try {
            await this.fetchGroups();
            this.data = await this.fetchResults();
            this.displayedData = this.data.slice(0, this.perPage);

            this.cleanTables();
        } finally {
            this.loading = false;
        }
    }

    cleanTables = async () => {
        for (const table of this.tables) {
            await this.checkTables(table);
        }
    }

    checkTables = async (table: GroupTable) => {
        const { searchQuery } = this.props;
        const portal = this.getPortal();

        const { data: { records } } = await fetchSearchResultsData(searchQuery, 0, undefined, portal, table.sys_id);
        table.isNotEmpty = !!records.length;
    }

    fetchGroups = async () => {
        const portal = this.getPortal();
        const { data } = await fetchSearchCategories(portal);
        const { search_widget_titles } = langStore.getTranslate();

        const categories = data.groups[0].tables;

        const allResult = {
            title: search_widget_titles?.all_results || 'All',
            sys_id: "",
            isNotEmpty: true,
            icon: "",
            table_name: "",
        };

        this.tables = [ allResult, ...categories ];
    }

    fetchResults = async (nextPage = 0, categoryId = this.chosenGroup) => {
        const { searchQuery } = this.props;
        const portal = this.getPortal();

        const {
            data: {
                page,
                records,
            },
        } = await fetchSearchResultsData(searchQuery, nextPage, undefined, portal, categoryId);
        this.page = page + 1;

        if (records.length < this.perPage) {
            this.isLastPost = true;
        }

        return records;
    }

    getPortal = () => {
        const { match } = this.props;
        return match?.params ? match.params.portalSuffix || match.params.pageTemplatePathName : '';
    }

    chooseGroup = async (group: string) => {
        this.loading = true;

        this.chosenGroup = group;
        this.page = 0;
        this.isLastPost = false;
        this.isPopupOpened = false;

        this.data = await this.fetchResults().finally(() => this.loading = false);
        this.isCategoryEmpty = !!this.data.length;
        this.displayedData = this.data.slice(0, this.perPage);
    };

    handleClickBack = () => {
        goBack();
    };

    handleLoadMore = async () => {
        // Записи берутся из "кэша" не выводим все что пришли
        if (this.data.length > this.displayedData.length) {
            this.displayedData = this.data.slice(0, this.perPage + this.displayedData.length);
        } else {
            // Записи кончились в "кэше"
            this.isMoreLoaded = true;
            const results = await this.fetchResults(this.page).finally(() => this.isMoreLoaded = false);
            if (results.length) {
                this.data.push(...results);
                this.displayedData = this.data.slice(0, this.perPage + this.displayedData.length);
            }
        }
    }

    calculateLoadPercent() {
        let itemsLength = 0;
        let loadItemsLength = 0;

        for (const table of this.tables) {
            if (!table.sys_id) continue;

            itemsLength++;
            if (table.hasOwnProperty('isNotEmpty')) {
                loadItemsLength++;
            }
        }

        return (loadItemsLength * 100 / itemsLength) || 0;
    }

    renderBreadcrumbs() {
        const { breadcrumbs } = this.props;
        const breadcrumbItems = breadcrumbs ? breadcrumbs.map((elem, index) => {
            return <div className={ styles.Breadcrumb } key={ elem }>
                { !!index &&
                    <div className={ styles.BreadcrumbIcon } dangerouslySetInnerHTML={ { __html: ChevronRight } } /> }
                <div className={ styles.Breadcrumb }>{ elem }</div>
            </div>;
        }) : null;

        return breadcrumbItems ? <div className={ styles.Breadcrumbs }>{ breadcrumbItems }</div> : null;
    }

    renderHeading() {
        const { itemPage, itemView } = this.props;

        const searchUrl = location.pathname;

        return (
            <div className={ styles.Heading }>
                <div
                    className={ styles.SearchIcon }
                    dangerouslySetInnerHTML={ { __html: IconArrowLeft } }
                    onClick={ this.handleClickBack }
                />
                <Search
                    size={ 'sm' }
                    top={ '4' }
                    preventHidden={ true }
                    searchUrl={ searchUrl }
                    itemPage={ itemPage }
                    itemView={ itemView }
                    isBlockInput
                    focusOnLoad
                />

                { !this.isDataNotFound &&
                    <div
                        dangerouslySetInnerHTML={ { __html: IconKebab } }
                        className={ styles.Kebab }
                        onClick={ () => {
                            this.isPopupOpened = true;
                            this.freezeBodyScroll();
                        } }
                    />
                }
            </div>
        );
    }

    renderNotFound() {
        const { search_widget_titles } = langStore.getTranslate();

        const message = this.isCategoryEmpty ? (
                <div className={ styles.NoResultsText }>{ search_widget_titles?.no_results_change_category }</div>
            )
            : (
                <>
                    <div className={ styles.NoResultsText }>{ search_widget_titles?.nothing_found }</div>
                    <div>{ search_widget_titles?.try_change_query }</div>
                </>
            );

        return (
            <div
                className={ styles.NoResults }
                data-test={ ATTRIBUTES.searchNotFound }
            >
                <div
                    className={ styles.NoResultsIcon }
                    dangerouslySetInnerHTML={ { __html: NoResults } }
                />
                { message }
            </div>
        );
    }

    renderCards() {
        const { match, itemPage, itemView } = this.props;
        if (this.isDataNotFound) return null;

        return (
            <div className={ styles.Cards }>
                { this.displayedData.map((card) => {
                    const link = getRecordLink(match, card.table_name, card.sys_id, itemPage, itemView);

                    return (
                        <SearchResultsCard
                            key={ `${card.sys_id}-${card.ts_table_title}` }
                            title={ card.title }
                            link={ link }
                            displayedColumns={ card.displayed_columns }
                            subInfoColumns={ card.sub_info_columns }
                        />
                    );
                })
                }
            </div>
        );
    }

    renderFooter() {
        const { search_widget_titles } = langStore.getTranslate();

        if (!this.data.length) return;

        const lastPost = <div
            className={ styles.FooterEndText }
            data-test={ ATTRIBUTES.searchNoResults }
        >
            { search_widget_titles?.no_more_results }
        </div>;
        const moreBtn = this.isMoreLoaded ?
            (
                <div className={ styles.MoreLoader } />
            ) :
            (
                <Button
                    data-test={ ATTRIBUTES.searchLoadMore }
                    onClick={ this.handleLoadMore }
                >
                    { search_widget_titles?.load_more }
                </Button>
            );

        return (
            <div className={ styles.Footer }>
                { this.isLastPost ? lastPost : moreBtn }
            </div>
        );
    }

    renderProgressBar = () => {
        const loadPercent = this.calculateLoadPercent();

        let stylesStr = [ styles.progressBarLine ];
        if (loadPercent === 100) {
            stylesStr.push(styles.hideBar);
        }

        return (
            <div className={ styles.progressBar }>
                <div className={ stylesStr.join(' ') } style={ { width: `${ loadPercent }%` } } />
            </div>
        );
    }

    renderTree = () => {
        const { sp_search } = langStore.getTranslate();
        const { isTreeShown } = this.props;

        const isHaveData = !!this.data.length;
        const isEmptyDataAndChosenCategory = !this.data.length && this.isCategoryEmpty;

        if (isTreeShown) {
            return (
                <div className={ `${ styles.Tree } ${ this.isPopupOpened ? styles.active : '' }` }>
                    <div className={ styles.TreeHeader }>
                        <div className={ styles.TreeHeading }>{ sp_search?.categories }</div>
                        <div
                            className={ styles.TreeClose }
                            dangerouslySetInnerHTML={ { __html: IconX } }
                            onClick={ () => {
                                this.isPopupOpened = false;
                                this.freezeBodyScroll();
                            } }
                        />
                    </div>
                    { isHaveData ? "" : '\u00A0' }
                    { (isHaveData || isEmptyDataAndChosenCategory) &&
                        <SearchResultsTree
                            groups={ this.tables }
                            chosenGroup={ this.chosenGroup }
                            chooseGroup={ this.chooseGroup }
                            renderProgressBar={ this.renderProgressBar }
                        />
                    }
                </div>
            );
        }
    }

    render() {
        const { className } = this.props;

        return (
            <div className={ `${ styles.Container } ${ className || '' }` }
                 data-test={ this.props['data-test'] ? this.props['data-test'] : `searchresults-${ ATTRIBUTES.widget }` }>
                { this.renderTree() }
                <div className={ `${ styles.Results }` }>
                    { this.renderBreadcrumbs() }
                    { this.renderHeading() }
                    { !this.loading && (
                        <>
                            { this.renderCards() }
                            { this.renderFooter() }
                        </>
                    ) }
                </div>
                { this.isDataNotFound && this.renderNotFound() }
                { this.loading && (
                    <div className={ styles.WrapLoader }>
                        <PageLoader backgroundColor={ 'transparent' } />
                    </div>

                ) }
                <QuickButtonReturn />
            </div>
        );
    }
}

export default withRouter(SearchResultsWidget);
