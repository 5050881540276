import { action, observable } from 'mobx';
import * as _ from 'lodash';
import {
    ActivityStateProps,
    ActivityMenuItem,
    TrueOrFalse,
    Activity,
    ActivityType,
    DictionaryActivity,
    HistoryField,
    ActivitySendCommentParams,
    CommentTextTab,
} from 'types/components/activityFeed';
import {
    changeColumnFilter,
    changeTypeFilter,
    createActivityType,
    fetchActivityFeedResponse,
} from 'actions/activityFeed';
import { filterTypes, getFilteredTypeTabs } from 'helpers/activityFeed';
import formsState from 'globalState/forms/index';
import { SectionType } from 'types/components/dynamicForms/model/field';

const EMPTY_DICTIONARY = {
    widget_title: '',
    form: {
        submit_option_prefix: '',
        textarea_placeholder: '',
        submit_button: '',
        apply: '',
        filter: '',
        no_activity: '',
        no_fields: '',
    },
    select_all: '',
    settings: {
        bubble_menu_title: '',
        history_fields_title: '',
        advanced_history_fields_url: '',
    },
    item: {
        comment_placeholder: '',
        comments_title: '',
        hide_comments_button: '',
        hide_information_button: '',
        show_comments_button: '',
    },
    bool: {
        yes: '',
        no: '',
    },
};

class ActivityFeedState implements ActivityStateProps {
    @observable private _activeTab = 'all';
    @observable private _activeMenuItems: ActivityMenuItem[] = [];
    @observable private _activeHistoryMenuItems: HistoryField[] = [];
    @observable private _activities: Activity[] = [];
    @observable private _activityTypes: ActivityType[] = [];
    @observable private _allActivityTypes: ActivityType[] = [];
    @observable private _dictionary: DictionaryActivity = EMPTY_DICTIONARY;
    @observable private _historyFields: HistoryField[] = [];
    @observable private _sysId = '';
    @observable private _tableName = '';
    @observable private _widgetId = '';
    @observable private _textTabs: CommentTextTab[] = [];
    @observable private _isFolded = true;
    @observable private _isFetchRun = false;
    @observable private _isLoadingFromForm = false;
    @observable private _hasNotForm = false;
    @observable private _journalFilter: string[] = [];
    @observable private _columnFilter: string[] = [];

    getIsFetchRun(): boolean {
        return this._isFetchRun || this._isLoadingFromForm;
    }

    setIsLoadingFromForm(value: boolean) {
        this._isLoadingFromForm = value;
    }

    getSysId(): string {
        return this._sysId;
    }

    setSysId(value: string) {
        this._sysId = value;
    }

    getTableName(): string {
        return this._tableName;
    }

    setTableName(value: string) {
        this._tableName = value;
    }

    setWidgetId(value: string) {
        this._widgetId = value;
    }

    getWidgetId() {
        return this._widgetId;
    }

    setJournalFilter(value: string[]) {
        this._journalFilter = value.length === 1 && value[0] === '' ? ['emptyFilter'] : value;
    }

    getJournalFilter(): string[] {
        return this._journalFilter;
    }

    setColumnFilter(value: string[]) {
        this._columnFilter = value.length === 1 && value[0] === '' ? ['emptyFilter'] : value;
    }

    getColumnFilter(): string[] {
        return this._columnFilter;
    }

    getActivities(): Activity[] {
        return this._activities;
    }

    @action
    setActivities(value: Activity[]) {
        this._activities = value;
    }

    getHasNotForm() {
        return this._hasNotForm;
    }

    @action
    setHasNotForm(value: boolean) {
        this._hasNotForm = value;
    }

    getActivityTypes(): ActivityType[] {
        return this._activityTypes;
    }

    @action
    setActivityTypes(value: ActivityType[]) {
        this._allActivityTypes = value;

        const agentForm = formsState.getDynamicFormBySysId(this._tableName, this._sysId);
        if (!agentForm && this._hasNotForm) {
            this._activityTypes = _.map(value, type => {
                return { ...type, mandatory: false };
            });
            this._activityTypes = filterTypes(this);
            return;
        }
        const otherTypes = _.filter(value, type => ['all', 'history'].includes(type.name));
        if (!_.isEmpty(this._activityTypes)) {
            const oldTypes = [ ...this._activityTypes ];
            const actTypes: ActivityType[] = [];
            _.forEach(oldTypes, (oldType: ActivityType) => {
                const findType = _.find(value, type => !!oldType.journal_input_column_id
                    && oldType.journal_input_column_id === type.journal_input_column_id);
                if (findType) {
                    const { sys_id, checked, title_background_color, title_color } = findType;
                    actTypes.push({ ...oldType, sys_id, checked, title_background_color, title_color });
                }
                if (!findType && !['all', 'history'].includes(oldType.name)) {
                    actTypes.push(oldType);
                }
            });
            this._activityTypes = [ ...otherTypes, ...actTypes ];
        } else {
            this._activityTypes = otherTypes;
        }
        this._activityTypes = filterTypes(this);
    }

    getTypeTabs(): CommentTextTab[] {
        return this._textTabs;
    }

    @action
    setTypeTabs(value: CommentTextTab[]) {
        this._textTabs = value;
    }

    getDictionary(): DictionaryActivity {
        return this._dictionary;
    }

    @action
    setDictionary(value: DictionaryActivity) {
        this._dictionary = value;
    }

    getHistoryFields(): HistoryField[] {
        return this._historyFields;
    }

    @action
    setHistoryFields(value: HistoryField[]) {
        this._historyFields = value;
        this._activeHistoryMenuItems = value;
    }

    getActiveHistoryMenuItems(): HistoryField[] {
        return this._activeHistoryMenuItems;
    }

    getActiveMenuItems(): ActivityMenuItem[] {
        return this._activeMenuItems;
    }

    @action
    setActiveMenuItems(types: ActivityType[]) {
        const findTypeAll = _.find(types, type => type.name === 'all' && !!type.checked);
        this._activeMenuItems = _.map(types, (type: ActivityType) => {
            const value = type.checked || findTypeAll ? 1 : 0;
            return {
                id: type.sys_id,
                name: type.name,
                value: value,
            };
        });
    }

    getIsFolded(): boolean {
        return this._isFolded;
    }

    @action
    setIsFolded(value: boolean) {
        this._isFolded = value;
    }

    @action
    changeActiveMenuHistoryItem = (key: string, value: TrueOrFalse) => {
        this._activeHistoryMenuItems = _.map(this._activeHistoryMenuItems, (item: HistoryField) => {
            return {
                ...item,
                checked: item.title === key ? !!value : item.checked,
            };
        });
    };

    @action
    confirmActiveMenuHistoryItem = async (params: ActivitySendCommentParams) => {
        const activeHistoryMenuItems = _.filter(this._activeHistoryMenuItems, historyMenuItem => historyMenuItem.checked);
        const ids = _.map(_.filter(this._activeMenuItems, item => item.id !== null && !!item.value), item => item.id);
        const data = {
            active_column_ids: _.map(activeHistoryMenuItems, menuItem => menuItem.sys_id),
        };
        const response = await changeColumnFilter({ ...params, type_ids: ids }, data);
        if (response.isOkStatus) {
            this._activities = response.data;
        }
    };

    @action
    changeActiveMenuItem = async (key: string, id: string, value: TrueOrFalse, params: ActivitySendCommentParams) => {
        let typeId = id;
        if (_.isNil(id)) {
            const afTypeParams = { ...params, column_name: key };
            const response = await createActivityType(afTypeParams);
            if (response.isOkStatus) {
                const activityTypes = response.data.activity_types;
                this.setActivityTypes(activityTypes);
                this.setTypeTabs(getFilteredTypeTabs(this._activityTypes, this._tableName, this._sysId));
                if (!_.isEmpty(this._activityTypes)) {
                    this.setActiveMenuItems(this._activityTypes);
                }
                const findActivityType = _.find(activityTypes, type => type.name === key);
                typeId = findActivityType && findActivityType.sys_id ? findActivityType.sys_id : '';
            }
        }
        if (key !== 'all') {
            this._activeMenuItems = _.map(this._activeMenuItems, (item: ActivityMenuItem) => {
                if (item.name === 'all') {
                    return {
                        id: item.id,
                        name: item.name,
                        value: 0,
                    };
                }
                const isActive = !_.isNil(typeId) ? item.id === typeId : item.name === key;
                return {
                    id: item.id,
                    name: item.name,
                    value: isActive ? value : item.value,
                };
            });
        }

        this.updateActiveTab();
        this.changeActiveMenuItemToAll(key, value);
        const ids = _.map(_.filter(this._activeMenuItems, item => item.id !== null && !!item.value), item => item.id);
        const data = {
            active_type_ids: ids,
        };
        const response = await changeTypeFilter({ ...params, type_ids: ids }, data);
        if (response.isOkStatus) {
            this._activities = response.data;
            this.fetchHistoryFields();
        }
    };

    @action
    changeActiveMenuItemToAll = (key: string, value: TrueOrFalse) => {
        const filterItems = _.filter(this._activeMenuItems, (item: ActivityMenuItem) => item.value === 1 && item.name !== 'all');
        const isAllSelected = filterItems.length === this._activeMenuItems.length - 1;
        const applyToAll = (value) => {
            this._activeMenuItems = _.map(this._activeMenuItems, (item: ActivityMenuItem) => {
                return {
                    id: item.id,
                    name: item.name,
                    value: value,
                };
            });
        };

        if(key === 'all'){
            applyToAll(value);
        } else if (isAllSelected) {
            applyToAll(1);
        }
    };

    @action
    updateActiveTab = () => {
        const filterItems = _.filter(this._activeMenuItems, (item: ActivityMenuItem) => item.value === 1);
        if (filterItems.length === 1 && filterItems[0].name !== 'all') {
            this._activeTab = filterItems[0].name;
            return;
        }
        const findItem = _.find(this._activeMenuItems, (item: ActivityMenuItem) => item.name === this._activeTab && item.value === 1);
        if (!findItem) {
            this._activeTab = 'all';
        }
    };

    getActiveTab = (): string => {
        return this._activeTab;
    };

    @action
    changeTab = (tab: string) => {
        this._activeTab = tab;
    };

    fetchHistoryFields = async () => {
        if (!this._sysId) {
            return;
        }
        const response = await fetchActivityFeedResponse({
            table_name: this._tableName,
            record_id: this._sysId,
            widget_instance_id: this._widgetId,
        });
        if (response.isOkStatus) {
            this.setHistoryFields(response.data.history_fields);
        }
    };

    fetchResponseData = async () => {
        if (!this._sysId || this._sysId === 'undefined') {
            return;
        }
        this._isFetchRun = true;
        const response = await fetchActivityFeedResponse({
            table_name: this._tableName,
            record_id: this._sysId,
            widget_instance_id: this._widgetId,
        });
        if (response.isOkStatus) {
            this.setActivities(response.data.activities);
            this.setActivityTypes(response.data.activity_types);

            this.setIsFolded(response.data.is_folded === 'true');
            this.setTypeTabs(getFilteredTypeTabs(this._activityTypes, this._tableName, this._sysId));
            if (!_.isEmpty(this._activityTypes)) {
                this.setActiveMenuItems(this._activityTypes);
            }
            this.setDictionary(response.data.dictionary);
            this.setHistoryFields(response.data.history_fields);
        }
        this._isFetchRun = false;
    };

    setActivityTypesFromFields = (sections: SectionType[]) => {
        const agentForm = formsState.getDynamicFormBySysId(this._tableName, this._sysId);
        if (!agentForm && this._hasNotForm) {
            return;
        }
        let journalFields: ActivityType[] = [];
        _.forEach(sections, section => {
            _.forEach(section.elements, element => {
                if (element.column_type === 'journal_input' && !element.hidden) {
                    const field: ActivityType = {
                        checked: true,
                        enable_create: !element.read_only,
                        icon: null,
                        journal_input_column_id: element.column_id || '',
                        mandatory: !!element.is_mandatory,
                        column_name: element.sys_column_name || '',
                        name: `${ element.sys_column_name }.${ element.sys_table_name }` || '',
                        sys_id: null,
                        title: element.name || '',
                        title_background_color: "",
                        title_color: "",
                        title_plural: "",
                    };
                    journalFields.push(field);
                }
            });
        });
        const otherTypes = _.filter(this._activityTypes, type => ['all', 'history'].includes(type.name));
        this._activityTypes = [ ...otherTypes, ..._.map(journalFields, jField => {
            const findJField = _.find(this._allActivityTypes, aType =>
                aType.journal_input_column_id === jField.journal_input_column_id);
            let resultJField = jField;
            if (findJField) {
                resultJField = { ...findJField };
                resultJField.journal_input_column_id = jField.journal_input_column_id || findJField.journal_input_column_id;
                resultJField.column_name = jField.column_name || findJField.column_name;
                resultJField.name = jField.name || findJField.name;
                resultJField.title = jField.title || findJField.title;
                resultJField.enable_create = jField.enable_create;
                resultJField.mandatory = jField.mandatory;
                resultJField.sys_id = findJField.sys_id;
            }
            return resultJField;
        }) ];
        this._activityTypes = filterTypes(this);
        this.setTypeTabs(getFilteredTypeTabs(this._activityTypes, this._tableName, this._sysId));
        if (!_.isEmpty(this._activityTypes)) {
            this.setActiveMenuItems(this._activityTypes);
        }
    };

    clear = () => {
        this._activeTab = 'all';
        this._activeMenuItems = [];
        this._activeHistoryMenuItems = [];
        this._activities = [];
        this._dictionary = EMPTY_DICTIONARY;
        this._historyFields = [];
        this._sysId = '';
        this._tableName = '';
        this._widgetId = '';
        this._textTabs = [];
    };
}

export const activityFeedStore = ActivityFeedState;

export default new ActivityFeedState();
