import { action, observable } from 'mobx';
import { SearchStateType } from 'types/globalState/search';

class SearchState implements SearchStateType {
    @observable private _searchParam = '';
    @observable private _term = '';
    @observable private _searchOnPage = 0;

    getSearchParam(): string {
        return this._searchParam;
    }

    @action
    setSearchParam(value: string) {
        this._searchParam = value;
        this._term = value;
    }

    getSearchTerm(): string {
        return this._term;
    }

    @action
    setSearchTerm(value: string) {
        this._term = value;
    }

    getSearchOnPage(): number {
        return this._searchOnPage;
    }

    @action
    setSearchOnPage(value: number) {
        this._searchOnPage += value;
    }
}

export default new SearchState();
