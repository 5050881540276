import { observable } from 'mobx';


class ComponentsUpdater {
    @observable activitiesUpdater = 0;
}

const componentsUpdaterState = new ComponentsUpdater();

export default componentsUpdaterState;
