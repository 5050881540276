import * as React from 'react';
import styles from 'components/dynamicForms/view/field/checkbox/styles.module.scss';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import Check from 'assets/img/checkbox.svg';
import { observer } from 'mobx-react';
import { observable } from "mobx";
import _ from 'lodash';
import langStore from 'globalState/lang';
import CustomSelect from 'components/customselect';
import CheckboxModel from 'components/dynamicForms/model/field/CheckboxModel';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getTestNameField } from 'helpers/data';
import { isChanged } from 'helpers/form';

/**
 * Описание: компонент Checkbox
 * Параметры:
 * onChange: {required, type: function} - метод для изменения значения
 * className: {type: string} - class css
 * value: {type: boolean}
 * cellEditMode: {type: boolean} - компонент используется в редакторе ячеек листа
 */
@observer
export default class Checkbox extends React.Component {
    @observable model;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new CheckboxModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    /*
     uiClearValue() {
     this.node.checked = false;
     }

     uiShowFieldMsg(message, type){
     this.fieldMessage = {
     message: message,
     type: type,
     };
     }
     */
    onChange = (event) => {
        this.model.value = event.target.checked;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    onSelectChange = (value) => {
        this.model.value = value.database_value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }

        // в режиме редактора ячеек сохраняем изменённое значение
        if (this.model.cellEditMode && this.model.save){
            this.model.save();
        }
    };

    getSelectOptions() {
        const { bool } = langStore.getTranslate();
        return [
            {
                database_value: 1,
                display_value: bool && bool.yes,
            },
            {
                database_value: 0,
                display_value: bool && bool.no,
            },
        ];
    }

    /**
     * этот лейаут используется для редактирования ячеек таблицы(листа)
     * @return {*}
     */
    renderSelectView() {
        return <CustomSelect
            data-test={ `${ getTestNameField(this.model) }-${ ATTRIBUTES.fieldCheckbox }` }
            databaseValue={ this.model.value }
            options={ this.getSelectOptions() }
            onChange={ this.onSelectChange }
        />;
    }

    renderCheckboxView() {
        let stylesStr = [styles.Checkbox];
        if (this.model.className) {
            stylesStr.push(this.model.className);
        }
        let stylesIcon = [styles.icon];
        if (this.model.readonly) {
            stylesIcon.push(styles.disabled);
        }
        if (this.model.isWarning && this.model.cellEditMode) {
            stylesStr.push(styles.warningInput);
        }

        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldCheckbox }` : `${ ATTRIBUTES.fieldCheckbox }`;
        return (
            <div
                className={ stylesStr.join(' ') }
                data-test={ dataTest }
                data-test-visible={ this.model.isVisible }
                data-test-mandatory={ this.model.isMandatory }
                data-test-readonly={ this.model.readonly }
                data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                data-test-field-name={ this.model.sys_column_name }
            >
                <label>
                    <input
                        type="checkbox"
                        ref={ this.model.ref }
                        checked={ this.model.value }
                        value={ this.model.value }
                        onChange={ this.onChange }
                        onKeyDown={ this.onKeyDown }
                        required={ this.model.isMandatory }
                        disabled={ this.model.readonly }
                        data-test={ `${ dataTest }-checkbox` }
                    />
                    <span
                        className={ stylesIcon.join(' ') }
                        onClick={ this.onLabelClick }
                    >
                        <div className={ styles.Check } dangerouslySetInnerHTML={ { __html: Check } } />
                    </span>
                </label>
            </div>
        );
    }

    onKeyDown = (e) => {
        if(e.key === 'Enter') {
            this.onLabelClick(e);
        }
    };

    onLabelClick = (e) => {
        e.preventDefault();
        if(this.model.readonly) return;

        this.model.value = !this.model.value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        this.model.ref?.current.focus();
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    render() {
        return (
            <FieldWrapper model={ this.model } onLabelClick={ this.onLabelClick }>
                { this.model.cellEditMode ? this.renderSelectView() : this.renderCheckboxView() }
            </FieldWrapper>
        );
    }
}
