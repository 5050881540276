import * as React from 'react';
import { observer } from 'mobx-react';
import MultiSelectComponent from 'components/multiselect';
import langStore from 'globalState/lang';

/**
 * @param value
 * @param options
 * @method onChange
 */
@observer
export default class ListFilterSearchState extends React.Component {
    handleChangeValue = (value) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(value);
        }
    };

    render() {
        const { filter_titles } = langStore.getTranslate();
        const { values, options, placeholderLabel } = this.props;
        return (
            <MultiSelectComponent
                values={ values }
                options={ options }
                onClose={ this.handleChangeValue }
                onRemoveItem={ this.handleChangeValue }
                placeholder={ filter_titles && filter_titles.filter_by_state ? filter_titles.filter_by_state.replace('[x]', placeholderLabel) : '' }
                canExcludeAll
                isHideHeader
            />
        );
    }
}
