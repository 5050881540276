import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import userState from 'globalState/user';
import FormValidator from 'helpers/formValidator';
import FormServerErrors from 'components/formItems/serverErrors';
import LoginInput from 'components/login/input';
import LoginButton from 'components/login/button';

import styles from 'components/login/styles.module.scss';
import SmartTitle from 'components/smartTitle';
import langStore from 'globalState/lang';
import Select from 'components/login/select';
import Help from 'components/login/help';
import { ATTRIBUTES } from 'constants/attributesForTests';

@observer
export default class SignUp extends React.Component {
    @observable formFields = {
        username: FormValidator.createFormFieldObj(),
        password: FormValidator.createFormFieldObj(),
        email: FormValidator.createFormFieldObj(),
    };
    @observable serverError = '';
    @observable isFetching = false;
    @observable langData = {};

    // Validate form and submit
    validateAndSubmit = async () => {
        const { langData, onRedirect } = this.props;
        if (this.isFetching) {
            return;
        }
        this.isFetching = true;

        const fv = new FormValidator(this.formFields);
        const promises = [];

        // Validate login
        promises.push(
            fv.validateField(this.formFields.username, () => {
                //if (val.length < 3) return `Login must contain not less than 3 symbols`;
            }),
        );

        // Validate password
        promises.push(
            fv.validateField(this.formFields.password, () => {
                //if (val.length < 3) return `Password must contain not less than 3 symbols`;
            }),
        );

        // Validate email
        promises.push(
            fv.validateField(this.formFields.email, () => {
                // if (!validateEmail(val)) return 'Invalid email';
            }),
        );

        // Await for validations
        await Promise.all(promises);

        const isValid = fv.isFieldsValid();

        if (!isValid) {
            this.isFetching = false;
            return;
        }

        try {
            const data = fv.getFieldsData();
            data.language = langData.langValue;
            let result = await userState.signup(data);
            if (result && onRedirect) {
                // Success
                onRedirect();
            }
        }
        catch (error) {
            const errorsParsed = fv.applyServerValidationErrors(error.response.data);
            if (!errorsParsed) {
                this.serverError = fv.serverErrorMessage || error.message;
            }
            this.isFetching = false;
        }
    };

    handleValueChange = (event) => {
        const { name, value } = event.target;
        this.formFields[name].value = value;
        this.formFields[name].errorMessage = '';
    };

    onFormKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.validateAndSubmit().catch(console.error);
        }
    };

    renderLogo = () => {
        const { logo } = this.props;
        if (!logo) {
            return null;
        }
        return (
            <div className={ styles.Logo }>
                <img src={ logo } />
            </div>
        );
    };

    renderSwitchLang = () => {
        const { onFetchTranslate, langData, isSwitchLang } = this.props;
        if (!isSwitchLang) {
            return null;
        }
        return (
            <Select
                onChange={ onFetchTranslate }
                databaseValue={ langData.langValue }
                options={ langData.langSpecial }
            />
        );
    };

    render() {
        const { placeholders, sign_up_page, help } = langStore.getTranslate();

        return (
            <div className={ styles.Form } onKeyPress={ this.onFormKeyPress }>
                <SmartTitle hidden>{ sign_up_page ? sign_up_page.title : '' }</SmartTitle>
                { this.renderSwitchLang() }
                <Help title={ help && help.title } children={ help && help.content } />
                { this.renderLogo() }
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders && placeholders.email }
                        name="email"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.email.errorMessage }
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders && placeholders.username }
                        name="username"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.username.errorMessage }
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders && placeholders.password }
                        type="password"
                        name="password"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.password.errorMessage }
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginButton
                        isLoading={ this.isFetching }
                        onClick={ this.validateAndSubmit }
                        buttonType='primary'
                        data-test={ ATTRIBUTES.signUpButton }
                    >
                        { sign_up_page && sign_up_page.submit_button }
                    </LoginButton>
                </div>
                <FormServerErrors msg={ this.serverError } />
                <div className={ `${ styles.LinksField } ${ styles.alignCenter }` }>
                    <div>
                        <span className={ styles.Link } onClick={ this.props.onToggleRegistration }>{ sign_up_page && sign_up_page.login_link }</span>
                    </div>
                </div>
            </div>
        );
    }
}
