import { action, observable } from 'mobx';
import _ from 'lodash';
import apiRequest from 'lib/apiRequest';
import { getUrlParams } from 'helpers/data';
import brandingState from 'globalState/branding/index';
import { helperRedirect } from 'helpers/history';
import BusinessSolutionStorage from 'globalState/bsStorage/index';
import PortalContainer from 'globalState/servicePortalWidgets/containers/index';
import SimpleRecord from 'ui-actions-scripts/simple-record';
import { containerSort } from 'helpers/portal';
import {ROUTES} from "constants/routers";
import { PageDataType } from 'types/pages/servicePortal/index';
import { PortalContainerClassType } from 'types/pages/servicePortal/container/index';

const PORTAL_OBJECT_NAME = 'portal';

export class ServicePortalWidgetsState {
    @observable hasLayout = false;
    @observable pageData: PageDataType = {};
    @observable isFetching = false;
    @observable isFetchingWithoutLoading = false;
    @observable pageDataLoaded = false;
    @observable errorMessage = '';
    @observable containers: PortalContainerClassType[] = [];
    _timeout;

    @action
    setTimeout(func) {
        clearTimeout(this._timeout);
        this._timeout = setTimeout(func, 500);
    }

    @action
    setIsFetching = (isFetching) => {
        this.isFetching = isFetching;
    };

    getIsFetching = () => {
        return this.isFetching;
    };

    @action
    setIsFetchingWithoutLoading = (isFetching) => {
        this.isFetchingWithoutLoading = isFetching;
    };

    getIsFetchingWithoutLoading = () => {
        return this.isFetchingWithoutLoading;
    };

    getContainers = () => {
        return this.containers;
    };

    @action
    setContainers = (containers) => {
        this.containers = containers;
    };

    @action
    fetchData = async (props) => {
        this.isFetching = true;
        this.pageDataLoaded = false;

        try {
            this.fetchDataWithoutLoading(props);
            this.pageDataLoaded = true;
        }
        catch (e) {
            console.error(e);
            this.errorMessage = e.message;
        }
        finally {
            this.isFetching = false;
        }
    };

    @action
    updateContainers = (containers: PortalContainer[]) => {
        if (_.isEmpty(this.containers) || _.isEqual(this.containers, containers)) {
            this.containers = containers;
            return;
        }
        const moreContainers = _.filter(containers, (container, index) => index > this.containers.length - 1);
        let newContainers = [ ...this.containers ];
        _.forEach(this.containers, (container, index) => {
            const isFindContainer = _.find(containers, cont => container.isEqualSomeWidgets(cont.getRows()));
            if (!isFindContainer) {
                newContainers[index] = containers[index];
            } else {
                newContainers[index].updateRows(containers[index].getRows());
            }
        });
        this.containers = [ ...newContainers, ...moreContainers ];
    };

    @action
    fetchDataWithoutLoading = async (props) => {
        this.isFetchingWithoutLoading = true;
        const { portalSuffix, pageTemplatePathName, params, pageId } = this.parseLocation(props);
        const { location } = props;
        let url = '';
        let notFoundUrl: string = ROUTES.NOT_FOUND;
        const defaultNotFoundUrl = notFoundUrl;
        let saveRedirectUrl = `/${ pageTemplatePathName }`;
        if (props.cmtLayout) {
            url = `GET /page/${ pageTemplatePathName }`;
        }
        else if (props.singlePortal) {
            url = `GET /portal/${ pageTemplatePathName }`;
        }
        else if (props.pageById) {
            url = `GET /page-by-id/${ pageId }`;
        }
        else {
            url = `GET /portal/${ portalSuffix }/${ pageTemplatePathName }`;
            notFoundUrl = `/${ portalSuffix }${ notFoundUrl }`;
            saveRedirectUrl = `/${ portalSuffix }/${ pageTemplatePathName }`;
        }
        const result = await new apiRequest(url).qs(params).send();
        this.pageData = result.getData();
        if (this.pageData && this.pageData.page_404_path_name) {
            notFoundUrl = `/${ portalSuffix }/${ this.pageData.page_404_path_name }`;
        }
        if (this.pageData && this.pageData.error_index) {
            if (this.pageData.error_index === 4 && !this.pageData.page_login_path_name) {
                window.localStorage.setItem('redirect', `${ saveRedirectUrl }${ location.search }`);
                if (this.pageData.welcome_page_url) {
                    helperRedirect(this.pageData.welcome_page_url);
                } else {
                    helperRedirect(ROUTES.LOGIN);
                }
            }
            else if (this.pageData.error_index === 4) {
                window.localStorage.setItem('redirect', `${ saveRedirectUrl }${ location.search }`);
                helperRedirect(`/${ portalSuffix ? portalSuffix : pageTemplatePathName }/${ this.pageData.page_login_path_name || 'login' }`);
            }
            else if (!this.pageData.page_404_path_name || pageTemplatePathName === this.pageData.page_404_path_name
                || pageTemplatePathName === this.pageData.page_login_path_name) {
                helperRedirect(defaultNotFoundUrl);
            }
            else if (this.pageData.error_index === 2 || this.pageData.error_index === 3) {
                helperRedirect(notFoundUrl);
            }
            else if (this.pageData.error_index === 1) {
                helperRedirect(defaultNotFoundUrl);
            }
        }
        this.updateContainers(_.map(containerSort(this.pageData.containers), container => new PortalContainer(container)));
        if (this.pageData && this.pageData.main && this.pageData.main.portal_id) {
            this.setPortalRecord(this.pageData.main.portal_id);
        }
        this.setTitleEffect();
        this.isFetchingWithoutLoading = false;
    };

    //TODO Убрать запрос на странице входа, если он не нужен
    setPortalRecord(sys_id) { //создать SimpleRecord для portal и добавить его в SimpleStorage
        if (sys_id) {
            try {
                let record = new SimpleRecord(PORTAL_OBJECT_NAME);
                record.get(sys_id, () => {});
                BusinessSolutionStorage.setItem(PORTAL_OBJECT_NAME, record);
            }
            catch (e) {
                console.error(e);
                this.errorMessage = e.message;
            }
        }
    }

    parseLocation(data) {
        let portalSuffix;
        let pageTemplatePathName;
        let pageId;

        if (data.pagePathName) {
            pageTemplatePathName = data.pagePathName;
        }
        else if (data.portalSuffix && data.pageTemplatePathName) {
            portalSuffix = data.portalSuffix;
            pageTemplatePathName = data.pageTemplatePathName;
        }
        else {
            portalSuffix = data.match.params.portalSuffix;
            pageTemplatePathName = data.match.params.pageTemplatePathName;
            pageId = data.match.params.pageId;
        }

        let params = {};

        if (data.location) {
            params = getUrlParams(data.location.search);
        }
        return {
            portalSuffix,
            pageTemplatePathName,
            params,
            pageId,
        };
    }

    setTitleEffect = () => {
        try {
            document.title = this.pageData?.main?.title_or_id || '';
            if (this.pageData?.main?.favicon) {
                brandingState.setFavicon(this.pageData.main.favicon);
            }
        }
        catch (e) {

        }
    };

    @action
    clear = () => {
        this.pageData = {};
        this.isFetching = false;
        this.pageDataLoaded = false;
        this.errorMessage = '';
        this.containers = [];
        clearTimeout(this._timeout);
    };
}

export default new ServicePortalWidgetsState();
