import * as React from 'react';
import styles from './styles.module.scss';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import * as _ from 'lodash';
import { observer } from 'mobx-react';

/**
 * @param {array} columns
 * @param {object} sort
 * @method onChangeSorting
 */
@observer
export default class ListSorting extends React.Component {
    handleClickSort = (name) => () => {
        const { onChangeSorting, sort } = this.props;
        if (onChangeSorting) {
            onChangeSorting({ ...sort, [name]: !sort[name] || sort[name] === 'desc' ? 'asc' : 'desc' });
        }
    };

    renderSortIcon = (name) => {
        const { sort } = this.props;
        if (!sort[name]) {
            return null;
        }
        const icon = sort[name] === 'asc' ? IconChevronUp : IconChevronDown;
        return (
            <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: icon } } />
        );
    };

    render() {
        const { columns } = this.props;
        const items = _.map(columns, column => {
            return (
                <th className={ styles.ListSortingItem } key={ column.db_column_id }>
                        <div className={ styles.Title } onClick={ this.handleClickSort(column.db_column_name) }>{ column.label }</div>
                        { this.renderSortIcon(column.db_column_name) }
                </th>
            );
        });
        return (
            <thead>
                <tr className={ styles.ListSorting }>
                    { items }
                </tr>
            </thead>
        );
    }
}
