import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import LoginButton from 'components/login/button';
import SmartTitle from 'components/smartTitle';
import langStore from 'globalState/lang/index';
import brandingState from 'globalState/branding/index';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { helperRedirect } from 'helpers/history';
import userState from 'globalState/user/index';
import { fetchSsoInfo } from 'actions/auth';
import AuthHOC from "helpers/hoc/authHoc";
import {ROUTES} from "constants/routers";
import { AuthHocProps } from 'types/components/auth/auth';

@observer
class LogoutPage extends React.Component<AuthHocProps> {

    handleLoginSubmit = async () => {
        const {
            data: {enabled, ssoLoginUrl, welcomePageUrl},
            isOkStatus,
        } = await fetchSsoInfo();
        if (isOkStatus) {
            userState.ssoEnabled = enabled;
            if (enabled && ssoLoginUrl) {
                helperRedirect(ssoLoginUrl);
            } else if (welcomePageUrl) {
                helperRedirect(welcomePageUrl);
            } else {
                helperRedirect(ROUTES.LOGIN);
            }
        }
    };

    renderLogoutTemplate = () => {
        const {
            login_page = {},
        } = langStore.getTranslate();

        return (
            <div className={ styles.enterBtn }>
                <LoginButton
                    onClick={ this.handleLoginSubmit }
                    buttonType="default"
                    data-test={ ATTRIBUTES.loginButton }
                >
                    { login_page.submit_button }
                </LoginButton>
            </div>
        );
    };

    render() {
        const {
            logout_page = {},
        } = langStore.getTranslate();

        return (
            <div className={ styles.form }>
                <div className={ styles.container }>
                    <SmartTitle hidden>{ logout_page.title }</SmartTitle>
                    <div className={ styles.logo }>
                        <img src={ brandingState.getPlatformLogo() } />
                    </div>
                    {this.renderLogoutTemplate()}
                </div>
            </div>
        );
    }
}

export const LogoutPageComponent = LogoutPage;
export default AuthHOC(LogoutPage);
