/**
 * Описание: Input
 * label - надпись
 * isMandatory - обязательное поле
 * setValue - передать значение в родительский компонент
 *
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './styles.module.scss';
import IconX from "assets/img/icons/close-x.svg";

@observer
export default class WorkflowStringInput extends React.Component {
    @observable isEditing = false;
    @observable isHover = false;
    refInput = React.createRef();

    constructor(props) {
        super(props);
    }

    handleOnFocus = () => {
        this.isEditing = true;
    };

    handleOnFocusOut = () => {
        this.isEditing = false;
    };

    handleOnChange = (evt) => {
        this.props.setValue(evt.target.value);
    };

    clearValue = () => {
        this.props.setValue('');
        this.refInput.current.value = '';
    };

    handleMouseOver = () => {
        this.isHover = true;
    };

    handleMouseLeave = () => {
        this.isHover = false;
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render(){
        const name = this.props.isMandatory ? '* ' +this.props.label : this.props.label;
        const placeholder = this.isEditing || this.refInput.current && this.refInput.current.value ? '' : name;
        const label = this.isEditing || this.refInput.current && this.refInput.current.value ? name : '';
        return (
            <div className={ styles.Control } onMouseOver={ this.handleMouseOver } onMouseLeave={ this.handleMouseLeave } >
                <div className={ styles.Label }>{ label }</div>
                <div className={ styles.StringInput }>
                    <input
                           type='text'
                           placeholder={ placeholder }
                           className={ `${styles.Input} ${ (this.isHover || this.isEditing) && styles.InputActive }` }
                           onFocus={ this.handleOnFocus }
                           onBlur={ this.handleOnFocusOut }
                           onChange={ this.handleOnChange }
                           ref={ this.refInput }
                    />
                    { !!this.refInput.current && !!this.refInput.current.value &&
                        <div className={ styles.IconX }
                             onClick={ this.clearValue }
                             dangerouslySetInnerHTML={ { __html: IconX } } />
                    }
                </div>
                <div className={ styles.Line } />
            </div>
        );
    }
}
