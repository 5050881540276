import moment from 'moment';
import * as React from 'react';
import langStore from 'globalState/lang';
import reportState, { IS_LOADING_RECORD, IS_UPDATE_CHART } from 'globalState/report';
import _ from 'lodash';
import { dayMonthYearFormat } from 'helpers/date';
import sysDBTables from 'globalState/sysDBTables';
import { actionFetchRecords, actionFetchReportById } from 'actions/report';
import { extractDataFromRecord, mergeRecordDataWithSaved, parseRecordData } from 'helpers/report/reportMaster';
import SimpleForm from 'ui-actions-scripts/simple-form';
import { runOnTypeScripts, TYPE_ON_LOAD } from 'helpers/scriptClientHelper';
import { parseReport } from './reportComponent';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from 'constants/dateTime';
import { calculateCondtionString } from 'helpers/condition';

const DISPLAY_NAME_MAX_CHARS = 32;
const MINIMUM_FRACTION_DIGITS = 0;
const MAXIMUM_FRACTION_DIGITS = 20;

export const STEPS = [
    'Data',
    'Type',
    'Configure',
    'Style',
];

export const typesWithGroupBy = [
    'Bar',
    'Pie',
];

export const REPORT_ERROR_KEY = {
    noData: 1,
    tooMuchData: 2,
    hiddenReport: 3,
    hiddenTable: 4,
    hiddenColumn: 5,
};

/**
 * Получение отформатированного значения поля
 * @param name
 * @returns {string}
 */
export function getFieldValueString(name) {
    const {
        report,
        bool,
    } = langStore.getTranslate();

    if (Array.isArray(name) && name.length === 0) {
        return `(${ report.empty })`;
    }
    switch (name) {
        case null:
            return `(${ report.not_set })`;
        case '':
            return `(${ report.empty })`;
        case true:
            return bool ? `(${ bool.yes })` : name.toString();
        case false:
            return bool ? `(${ bool.no })` : name.toString();
        default:
            return name.toString();
    }
}

/**
 * Получение рекорда с сервера
 */
export async function fetchRecords(report_id = null) {
    if (!reportState.type) {
        reportState.stateFlags = reportState.stateFlags & ~IS_LOADING_RECORD;
        return;
    }
    const table = sysDBTables.getById(reportState.type.configure_table);
    if (!table) {
        return;
    }
    if (reportState.type && !typesWithGroupBy.some((elementType) => reportState.type.type_service_name === elementType)) {
        reportState.params.group_by = null;
        reportState.updateUrl();
    }
    reportState.typeTable = table;
    let params = {};
    if (reportState.name && !report_id) {
        params['field_name'] = reportState.name;
    }
    if (!!reportState.params.condition && !report_id) {
        params['field_condition'] = reportState.params.condition;
    }
    const response = await actionFetchRecords(table, report_id, params, reportState);

    if (response.status === 'OK') {
        const record = response.getData();

        const parsedRecord = parseRecordData(record, reportState.table);
        // Заполняем значения сохранённых полей
        reportState.record = reportState.savedRecord ?
            mergeRecordDataWithSaved(parsedRecord, reportState.savedRecord) :
            parsedRecord;
        const dataSection = getParamSection(reportState.record.sections, 'Data');
        const name = getParamField(dataSection, 'name');
        const table = getParamField(dataSection, 'table_id');
        table.sourceOnChange = (({ value }) => reportState.setTable(value));
        name.sourceOnChange = (({ value }) => reportState.setName(value));
        name.onBlur = reportState.updateNameInUrl;
        // Сохраняем значения полей
        if (report_id) {
            reportState.savedRecord = extractDataFromRecord(reportState.record);
        }

        reportState.stateFlags = reportState.stateFlags & ~IS_LOADING_RECORD;
        window.s_form = new SimpleForm(reportState.typeTable && reportState.typeTable.name, report_id, undefined, undefined, reportState.getParentFormId());
        if (reportState.sFormSysId && !report_id) {
            reportState.sFormSysId = null;
        }
        const isSetTable = reportState.prevTable && reportState.table && reportState.prevTable.database_value !== reportState.table.display_value;
        if (reportState.record && (!reportState.prevType || reportState.type.type_id !== reportState.prevType.type_id
            || isSetTable)) {
            runOnTypeScripts(reportState.record.client_scripts, TYPE_ON_LOAD, null, null, reportState.parentFormId);
        }
    }
}

/**
 * Получение отформатирванного значения даты
 * @param date
 * @param type
 * @param isCombine
 * @returns {string|*}
 */
export function getDateGroupName(date, type, isCombine = false) {
    const { report } = langStore.getTranslate();
    if (date === null || date === undefined || !type) {
        return `(${ report.not_set })`;
    }
    switch (type.toLowerCase()) {
        case 'month':
            if (isCombine) return moment.monthsShort()[date - 1];
            return moment.monthsShort()[moment(date).month()] + ' ' + moment(date).year();
        case 'day':
            if (isCombine) return moment.weekdays()[date % 7];
            return moment(date).format(dayMonthYearFormat);
        case 'hour':
            if (isCombine) return date;
            return moment(date).hour() + `${ report.h } ` + moment(date).format(dayMonthYearFormat);
        case 'date':
            if (isCombine) return date;
            return moment(date).format(dayMonthYearFormat);
        case 'quarter':
            if (isCombine) return date;
            return moment(date).quarter() + `${ report.qr }` + moment(date).year();
        case 'week':
            if (isCombine) return date;
            return moment(date).isoWeek() + `${ report.w }` + moment(date).year();
        default:
            return date;
    }
}

/**
 * Получение репорта по его айди
 * @param reportId - айди репорта
 * @param additionalGroupValue - значения по которым группируем
 * @param page - страница отображения, для листов
 * @param getStyles - функция, которая получает стили
 * @param currentReportState - текущий стект репорта
 * @returns {Promise<void>}
 */
export const fetchReportById = async (reportId, additionalGroupValue, page, getStyles, currentReportState = reportState) => {
    const condition = calculateCondtionString(currentReportState.fixedCondition, currentReportState.params.condition, '');
    let params = {
        group_id: additionalGroupValue,
        condition: condition,
    };

    if (page) {
        params.page = page;
    }

    const response = await actionFetchReportById(reportId, params);

    if (response.status === 'OK') {
        currentReportState.report = parseReport(response.getData(), currentReportState);
        currentReportState.reportStyles = getStyles();
        currentReportState.stateFlags = currentReportState.stateFlags | IS_UPDATE_CHART;
    }
};

/**
 * Получение нужной секции по имени
 * @param data
 * @param sectionName
 * @returns {null|*}
 */
export function getParamSection(data, sectionName) {
    if (!data) {
        return null;
    }

    return data.find((section) => section.service_name === sectionName);
}


/**
 * Получение нужного поля секции по имени
 * @param section
 * @param fieldName
 * @returns {null|*}
 */
export function getParamField(section, fieldName) {
    if (!section) {
        return null;
    }

    return section.elements.find((field) => field.sys_column_name === fieldName);
}

export function getParamValue(section, fieldName, isDb) {
    const field = getParamField(section, fieldName);
    return field ? getFieldValue(field, isDb) : null;
}

export function getFieldValue(field, isDb) {
    switch (field.column_type) {
        case 'choice':
        case 'field_name':
        case 'reference':
            return field.value && (isDb ? field.value.database_value : field.value.display_value);

        case 'list':
            return field.value && field.value.map((item) => isDb ? item.database_value : item.display_value);

        default:
            return field.value;
    }
}

/**
 * Рендерит значения полей для типа list
 * @param arrDisplayName массив объектов { name, link }
 * @param maxChars - максимальное число символов
 * @returns {*}
 */
function renderDisplayNameArray(arrDisplayName, maxChars = DISPLAY_NAME_MAX_CHARS) {
    let rest = maxChars;        // Кол-во оставшихся символов
    const arrLinks = [];
    const arrTitle = [];
    // Проходим по массиву и добавляем ссылки, пока не достигнем допустимого кол-ва символов
    arrDisplayName.forEach((displayName, i) => {
        let name = getFieldValueString(displayName.name);
        arrTitle.push(name);
        if (rest > 0) {
            rest -= name.length;
            if (rest < 0) {
                name = name.substring(0, name.length + rest) + '...';
                arrLinks.push(<a href={ displayName.link } key={ displayName.name }>{ name }</a>);
            }
            else {
                arrLinks.push(<a href={ displayName.link } key={ displayName.name }>{ name }</a>);
                if (i < arrDisplayName.length - 1) {
                    arrLinks.push(rest === 0 ? ', ...' : ', ');
                }
            }
        }
    });
    const title = arrTitle.join(', ');
    return <div title={ title }>
        { arrLinks }
    </div>;
}

export function renderFieldDisplayName(name) {
    if (Array.isArray(name) && name.length > 0) {
        return renderDisplayNameArray(name);
    }
    return trimTwoLinesString(getFieldValueString(name));
}

export function getDisplayNameString(name) {
    if (Array.isArray(name) && name.length > 0) {
        return name
            .map(item => `<a href='${ item.link }' >${ getFieldValueString(item.name) }</a>`)
            .join(', ');
    }
    return getFieldValueString(name);
}

/**
 * Функция обрезает строку с учётом переноса
 * str - исходная строка
 * maxChars - максимальное кол-во символов на каждой строчке
 */
export function trimTwoLinesString(str, maxChars = 16) {
    const string = str.trim();
    if (string.length <= maxChars) {
        return <span> { string } </span>;
    }

    const spaceRe = /\s/g;
    let lastSpacePos = -1;
    let spacePos;
    do {    // Ищем последнее вхождение пробельного символа для первой строки
        spacePos = string.substring(lastSpacePos + 1, maxChars).search(spaceRe);
        lastSpacePos += spacePos + 1;
    } while (spacePos > 0);
    if (lastSpacePos <= 0) {    // Если не удалось перенести на вторую строчку
        return <span title={ string }>{ string.substring(0, maxChars) }...</span>;
    }

    const len = string.length;
    const firstStr = string.substring(0, lastSpacePos);
    const secondStr = len - lastSpacePos < maxChars ?
        string.substring(lastSpacePos, len) :
        string.substring(lastSpacePos, lastSpacePos + maxChars + 1) + '...';
    return <span title={ string }>
            <span>{ firstStr }</span>
            <span>{ secondStr }</span>
        </span>;
}

/**
 * Функция для обработки нажатия на элемент
 * @param item
 * @returns {function(...[*]=)}
 */
export const onClick = (item) => {
    if (item.point) {
        item = item.point;
    }
    if (item.url) {
        window.open(item.url);
    }
};

export const onClickCell = (item) => (e) => {
    e.preventDefault();
    onClick(item);
};

/**
 * @param item - объект { url, value, count }, где
 *      url - url для перехода на список записей
 *      value - значение для ячейки
 *      count - кол-во записей для ячейки
 * @param precision - точность округления
 */
export function renderValueCell(item, precision) {
    if (!item || !item.count) {
        return <>0</>;
    }
    const value = item.value === null ? '0' : formatNumberValue(item.value, precision);

    return <span title={ item.count + ' records' }>
        {
            item.url ?
                <a href={ item.url }>
                    { item.displayValue || value }
                </a> :
                item.displayValue || value
        }
    </span>;
}

/**
 * Приводит точность округления к валидным значениям
 * @param precision - точность округления
 * @returns {null|number|*}
 */
export function limitPrecision(precision) {
    if (_.isNil(precision) || precision === '') {
        return null;
    }
    if (precision < MINIMUM_FRACTION_DIGITS) {
        return MINIMUM_FRACTION_DIGITS;
    }
    if (precision > MAXIMUM_FRACTION_DIGITS) {
        return MAXIMUM_FRACTION_DIGITS;
    }
    return precision;
}

/**
 * Форматирует число
 * @param value - значение
 * @param precision - точность округления. Если не задана, кол-во знаков после запятой не меняется
 */
export function formatNumberValue(value, precision) {
    precision = limitPrecision(precision);
    const formatter = precision !== null ?
        new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        }) :
        new Intl.NumberFormat('ru-RU', {
            maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        });
    return formatter.format(value).replace(' ', '').replace(',', '.');
}

/**
 * Функция формирует условие для фильтрации даты по частям
 * @param dateData - данные с информацией о дате и способе фильтраации
 * @returns {string}
 */
function createCombinedDateConditionStr(dateData) {
    if (!dateData.value) {
        return 'ISEMPTY';
    }
    let result = '';
    switch (dateData.per.toLowerCase()) {
        case 'month':
            result += 'MONTH_IS';
            break;
        case 'day':
            result += 'DAY_OF_WEEK_IS';
            break;
        case 'hour':
            result += 'HOUR_IS';
            break;
        case 'date':
            result += 'DAY_IS';
            break;
        case 'quarter':
            result += 'QUARTER_IS';
            break;
        case 'week':
            result += 'WEEK_IS';
            break;
        case 'year':
            result += 'YEAR_IS';
    }
    result += dateData.value;
    return result;
}

/**
 * Функция формирует условие для фильтрации по промежутку дат
 * @param dateData - данные с информацией о дате и способе фильтраации
 * @param name - имя поля
 * @returns {string}
 */
function createBetweenDateConditionStr(dateData, name, dateType) {
    if (!dateData.value) {
        return 'ISEMPTY';
    }
    const startDate = moment(dateData.value);
    const endDate = moment(startDate);
    const year = startDate.format('YYYY');
    switch (dateData.per.toLowerCase()) {
        case 'month':
            const month = startDate.format('M');
            return `MONTH_IS${ month }^${ name }YEAR_IS${ year }`;
        case 'day':
        case 'date':
            endDate.add(1, 'days');
            break;
        case 'hour':
            endDate.add(1, 'hours');
            break;
        case 'quarter':
            const quarter = startDate.format('Q');
            return `QUARTER_IS${ quarter }^${ name }YEAR_IS${ year }`;
        case 'week':
            const week = startDate.isoWeek();
            return `WEEK_IS${ week }^${ name }YEAR_IS${ year }`;
    }
    if (dateType === 'date') {
        return `ON${ startDate.format(DEFAULT_DATE_FORMAT) }`;
    }
    return `BETWEEN${ startDate.format(DEFAULT_DATE_TIME_FORMAT) }@${ endDate.format(DEFAULT_DATE_TIME_FORMAT) }`;
}

/**
 * Функия, которая создает строку для conditions
 * @param name - название поля
 * @param value - значение поля
 * @returns {string}
 */
export const createConditionStr = (name, value, trendType) => {
    if (_.isObject(value) && value && value.per) {  // Если тип - дата
        return name +
            (value.isCombine || value.per === 'year' ?
                createCombinedDateConditionStr(value) :
                createBetweenDateConditionStr(value, name, trendType));
    }
    const getParamString = (name, value) => {
        switch (value) {
            case null:
            case '':
                return `${ name }ISEMPTY`;
            case true:
                return `${ name }=1`;
            case false:
                return `${ name }=0`;
            default:
                return `${ name }=${ value }`;
        }
    };
    if (_.isObject(value) && value.database_name !== undefined) {
        return getParamString(name, value.database_name);
    }

    return getParamString(name, value);
};

/**
 * Получение колонки по ее айди
 * @param {Array | string} sysId  - айди колонки
 * @param currentReportState - текущий стейт репорта (может быть глобальным для МО, или локальным для виджета)
 * @returns {*}
 */
export const getSysColumnNameById = (sysId, currentReportState) => {
    const { column_data } = currentReportState.report ? currentReportState.report : currentReportState.reportData.chart;
    if (column_data && column_data[sysId]) {
        return column_data[sysId];
    }
    else if (column_data && Array.isArray(sysId)) {
        for (let id of sysId) {
            if (id in column_data) {
                return column_data[id];
            }
        }
    }
};

/**
 * Получение процентов в зависимости от общего значения
 * @param value
 * @param total
 * @returns {string}
 */
export const getPercentage = (value, total) => {
    return total ?
        (value / total * 100).toFixed(2) + '%' :
        '-';
};

/**
 * Получить текущее значение колонки, по которой группируем
 * @param currentReportState - текущий стейт репорта
 * @returns {null|*}
 */
export const getCurrentGroupBy = (currentReportState = reportState) => {
    if (currentReportState.additionalGroupValue) {
        return currentReportState.additionalGroupValue;
    }
    else if (currentReportState.additionalGroup && currentReportState.additionalGroup.length) {
        return currentReportState.additionalGroup[0];
    }
    return null;
};

// const reportHelper = {
//     getFieldValueString,
// };
//
// export default reportHelper;

export const getDurationValue = (value, isFull = false) => {
    if (value === 0) {
        return 0;
    }
    const durationTitles = langStore.getTranslateKey('duration_titles');
    const langD = durationTitles?.days ? durationTitles.days.substring(0, 1) : '';
    const langH = durationTitles?.hours ? durationTitles.hours.substring(0, 1) : '';
    const langM = durationTitles?.minutes ? durationTitles.minutes.substring(0, 1) : '';
    const langS = durationTitles?.seconds ? durationTitles.seconds.substring(0, 1) : '';
    const duration = moment.duration(value, 'milliseconds');
    let result = '';
    if (Math.floor(duration.asDays()) > 0) {
        result += `${ Math.floor(duration.asDays()) }${ langD } `;
    }
    if (duration.hours() > 0) {
        result += `${ duration.hours() }${ langH } `;
    }
    if (duration.minutes() > 0) {
        result += `${ duration.minutes() }${ langM } `;
    }
    if (duration.seconds() > 0 && isFull) {
        result += `${ duration.seconds() }${ langS }`;
    }
    return result;
};

export const changeYValue = (value, isFull, reportState) => {
    if (!reportState.isDurationAverage) {
        return value;
    }
    return getDurationValue(value, isFull);
};

export const getTickPositioner = (dataMax, reportState) => {
    if (!reportState.isDurationAverage) {
        return undefined;
    }
    const MILLISECONDS = 1000;
    const evaluateInterval = (dataMax, interval, index, time) => {
        let localIndex = index;
        if (dataMax / interval > 6) {
            localIndex++;
            const newInterval = localIndex * time * MILLISECONDS;
            return evaluateInterval(dataMax, newInterval, localIndex, time);
        }
        return interval;
    };
    const positions = [];
    let time = 3600 * 24 * 10; // 10 дней
    let startInterval = time * MILLISECONDS;
    if (dataMax / startInterval < 2) {
        time = 3600 * 24 * 4; // 4 дня
        startInterval = time * MILLISECONDS;
        if (dataMax / startInterval < 1) {
            time = 3600 * 24; // 1 день
            startInterval = time * MILLISECONDS;
            if (dataMax / startInterval < 1) {
                time = 3600; // 1 час
                startInterval = time * MILLISECONDS;
                if (dataMax / startInterval < 1) {
                    time = 60; // 1 минута
                    startInterval = time * MILLISECONDS;
                }
            }
        }
    }
    const interval = evaluateInterval(dataMax, startInterval, 1, time);

    for (let i = 0; i <= dataMax; i += interval) {
        positions.push(i);
    }

    return positions;
};

// Форматер для числовых данных
export const numberFormatter = (series, point, reportState) => {
    if (series.options.dataLabels && series.options.align === 'middle') {
        // скрываем label элемента, если он занимает менее 5%
        let max = series.yAxis.max;
        if (point.y / max < 0.05) return '';
    }

    if (series.type === 'column' && point.tooltipPos && point.tooltipPos[2] < 12) return ''; // скрываем текст, если он не влезает по высоте

    const decimalPrecision = series.chart.userOptions.decimalPrecision;
    if (reportState.isDurationAverage) {
        return getDurationValue(point.y, true);
    }
    return formatNumberValue(point.y, decimalPrecision);
};

export const isDurationAverage = (reportState) => {
    reportState.isDurationAverage = reportState.aggregationColumn === 'duration';
};
