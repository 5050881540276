import * as React from 'react';
import {observer} from "mobx-react";

import styles from 'components/portalDesigner/styles.module.scss';
import pageData from 'components/portalDesigner/state/pageData';

import PortalPageTemplateStorage from 'components/portalDesigner/storage/portalPageTemplate';

@observer
export default class Pages extends React.Component {

    constructor(props) {
        super(props);
    }

    onClick = (page) => {
        pageData.setCurrentPage(page);
    };

    render() {
        const data = PortalPageTemplateStorage.filterByTitle(this.props.filterValue);

        return (
            <div className={styles.List}>
                <div className={styles.ListMenuTitle}>
                    Pages
                </div>
                <ul className={styles.ListMenu}>
                    {
                        data.map((page) => {
                            return <li key={page.sys_id} onClick={() => this.onClick(page.sys_id)}>
                                {page.title}
                            </li>;
                        })
                    }
                </ul>
            </div>
        );
    }
}