import * as React from 'react';
import styles from './styles.module.scss';
import TextArea from 'components/dynamicForms/view/field/textArea';
import SendIcon from 'assets/img/icons/send.svg';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ActivityMenuItem, CommentBlockProps, CommentTextTab } from 'types/components/activityFeed';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { createActivityType, sendComment } from 'actions/activityFeed';
import _ from 'lodash';
import Button from 'components/button/index';
import { getClassNameAF, getFilteredTypeTabs } from 'helpers/activityFeed';
import Hint from "components/hint/index";

/**
 * Описание: компонент отправки комментария или рабочей записи
 * Параметры:
 * widgetId: {type: string} - id виджета
 * tableName: {type: string} - имя таблицы
 * recordId: {type: string} - id записи
 * activityState: {type: object}
 */
@observer
export default class CommentBlock extends React.Component<CommentBlockProps> {
    @observable sendDisabled = false;

    handleChangeComment = ({ value }) => {
        const { onChangeComment, activeTab } = this.props;
        if (onChangeComment) {
            onChangeComment(activeTab.id, value);
        }
    };

    handleKeyDown = (e) => {
        if (e.keyCode === 13 && e.ctrlKey) {
            this.sendComment(this.props.activeTab)(e);
        }
    };

    createAFType = async (textTab: CommentTextTab, params) => {
        if (!textTab.id) {
            const { activityState } = this.props;
            const afTypeParams = { ...params, column_name: textTab.column_name || textTab.name };
            const response = await createActivityType(afTypeParams);
            if (response.isOkStatus) {
                const activityTypes = response.data.activity_types;
                activityState.setActivityTypes(activityTypes);
                activityState.setTypeTabs(getFilteredTypeTabs(activityState.getActivityTypes(), activityState.getTableName(), activityState.getSysId()));
                if (!_.isEmpty(activityTypes)) {
                    activityState.setActiveMenuItems(activityState.getActivityTypes());
                }
                const findActivityType = _.find(activityTypes, type => type.journal_input_column_id === textTab.journalInputId);
                const typeId = findActivityType && findActivityType.sys_id ? findActivityType.sys_id : '';
                params.type_ids.push(typeId);
                return typeId;
            }
        }
        return '';
    };

    sendComment = (textTab: CommentTextTab) => async (event) => {
        const { widgetId, activityState, tableName, recordId, activeTab, onChangeComment } = this.props;
        event.stopPropagation();
        event.preventDefault();
        if (activeTab.comment && !this.sendDisabled) {
            this.sendDisabled = true;
            const isAll = _.find(activityState.getActiveMenuItems(), item => item.name === 'all' && !!item.value);
            const activeTypeIds = isAll
                ? _.map(_.filter(activityState.getActiveMenuItems(),
                    item => item.id && item.id.toLowerCase() !== 'null'), (item: ActivityMenuItem) => item.id)
                : _.map(_.filter(activityState.getActiveMenuItems(),
                    item => !!item.value && item.id && item.id.toLowerCase() !== 'null'), (item: ActivityMenuItem) => item.id);
            const params = {
                table_name: tableName,
                record_id: recordId,
                widget_instance_id: widgetId,
                type_ids: activeTypeIds,
            };
            try {
                const typeId = await this.createAFType(textTab, params);
                const data = {
                    type_id: textTab.id || typeId,
                    message: activeTab.comment,
                };
                const response = await sendComment(params, data);
                if (response.isOkStatus) {
                    activityState.setActivities(response.data);
                    if (onChangeComment) {
                        onChangeComment(activeTab.id, '');
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                this.sendDisabled = false;
            }
        }
    };

    render() {
        const { activityState, activeTab, isSliderOn, isNotStylized, classes } = this.props;
        const dictionary = activityState.getDictionary();
        const placeholder = dictionary.form ? dictionary.form.textarea_placeholder : '';
        const send = dictionary.form ? dictionary.form.submit_button : '';
        let btnRef = React.createRef<HTMLDivElement>();

        return (
            <div
                className={ `${ getClassNameAF(isNotStylized, styles.CommentBlock, classes?.CommentBlock)} ${ isSliderOn ? getClassNameAF(isNotStylized, styles.CommentBlockSlider, classes?.CommentBlockSlider) : '' }` }
                data-test={ ATTRIBUTES.activityFeedCommentBlock }
            >
                <div className={getClassNameAF(isNotStylized, styles.CommentBlockTextAreaWrap, classes?.CommentBlockTextAreaWrap)}>
                    <TextArea
                        className={ getClassNameAF(isNotStylized, styles.CommentBlockTextarea, classes?.CommentBlockTextarea) }
                        placeholder={ placeholder }
                        onChange={ this.handleChangeComment }
                        onKeyDown={ this.handleKeyDown }
                        value={ activeTab.comment }
                        data-test={ ATTRIBUTES.activityFeedCommentText }
                    />
                </div>
                <div ref={ btnRef } className={ getClassNameAF(isNotStylized, styles.CommentBlockButtonWrap, classes?.CommentBlockButtonWrap) }>
                    <Button
                        buttonType={'icon-primary'}
                        svg={SendIcon}
                        onClick={ this.sendComment(activeTab) }
                        data-test={ ATTRIBUTES.activityFeedCommentSendButton }
                        disabled={ !activeTab.comment && !this.sendDisabled }
                        data-loader={ this.sendDisabled  }
                        className={ classes?.CommentBlockSendButton }
                    />
                </div>
                { (!this.sendDisabled && activeTab.comment && send) && (
                    <Hint refParent={ btnRef }>
                        { send } <span>Ctrl</span><span>Enter</span>
                    </Hint>
                ) }
            </div>
        );
    }
}
