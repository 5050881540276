/**
 * Описание: Панель свойств объекта диаграммы рабочих процессов
 * Для редактирования свойств объекта
 * Параметры:
 * type - тип объекта (чаще всего соответствует tableName, но описывает тип объекта с точки зрения диаграммы workflow).
 *  т.е. для диараммы нет разницы между wf_activity и wf_activity_script
 * tableName - название таблицы соответствующей объекту ( для формирования запроса на бекенд, только для активностей). добавлено из-за типа RunScript
 * sys_id - идентификатор объекта
 * isOpened - открыта ли панель
 * header - заголовок панели (соответствует типу объекта)
 * activeTab - 'properties', 'info'
 * header - заголовок
 * loadWorkflow - метод загрузки workflow
 * fetchWorkflowList - метод получения списка workflow
 * togglePropertiesPanel - открыть/закрыть панель
 * refreshData - метод обновления данных
 * readOnly
 * status - объект с данными для workflow viewer
 * */

import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import DynamicForm from 'components/dynamicForms';
import styles from './styles.module.scss';
import IconX from 'assets/img/icons/close-x.svg';
import langStore from 'globalState/lang';
import PageLoader from 'components/pageLoader';
import LongText from 'components/workflow/longText';
import { getLocalTime } from '../helpers/datetime';
import { shortDateTimeToString } from 'helpers/date';
import { fetchRecord } from 'actions/record';
import SimpleForm from 'ui-actions-scripts/simple-form';
import Button from 'components/button';
import { generateRandomString } from 'helpers/data';

@observer
export default class PropertiesPanel extends React.Component {
    @observable data;
    @observable activeTab = 0; // 0 - properties, 1 - info
    @observable loading = false;
    @observable isFormSave = false;
    @observable dynamicFormId = '';

    constructor(props) {
        super(props);
        this.activeTab = (this.props.activeTab === 'info') ? 1 : 0; // по умолчанию properties
    }

    componentDidMount() {
        if (this.props.type) {
            this.setupForm();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.type && (this.props.sys_id !== prevProps.sys_id || this.props.type !== prevProps.type)) {
            this.setupForm();
        }
    }

    async setupForm() {
        this.dynamicFormId = generateRandomString();
        await this.getData();
        window.isNewRecord = !!this.props.sys_id;
        window.recordGlobal[this.props.tableName || this.props.type] = this;
    }

    getData = async () => {
        const requestOptions = {
            form_view: 'Workflow',
            open_first_rel_list: 0,
        };
        this.loading = true;
        try {
            const tableName = this.props.tableName || this.props.type;
            const { data } = await fetchRecord(tableName, this.props.sys_id, requestOptions);
            window.s_form = new SimpleForm(tableName, this.props.sys_id, data.form_title, '', this.dynamicFormId);
            this.data = data;
        }
        finally {
            this.loading = false;
        }
    };

    handleCloseClick = () => {
        this.props.togglePropertiesPanel(false);
    };

    handleSaveClick = () => {
        this.isFormSave = true;
        window.s_form.save()
              .then((data) => {
                  if (data && this.props.type === 'wf_workflow') {
                      this.props.fetchWorkflowList().then(() =>
                          this.props.loadWorkflow(data));
                  }
                  else {
                      this.props.refreshData();
                  }
                  this.props.togglePropertiesPanel(false);
              })
              .catch((error) => {
                  console.error(error);
              }).finally(() => this.isFormSave = false);
    };

    toggleTabs = (id) => {
        if (id) {
            this.activeTab = id;
        }
        else {
            this.activeTab = (this.activeTab === 0) ? 1 : 0;
        }
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render() {
        const {
            workflow_titles = {},
            get_types = {},
        } = langStore.getTranslate();

        if (this.loading || !this.data) {
            return <div key='propertiesPanel' className={ styles.PropertiesPanel }>
                <PageLoader />
            </div>;
        }

        let header = this.props.sys_id ?
            this.props.header.substring(0, 1).toUpperCase() + this.props.header.substring(1) :
            `${ get_types.new } ${ this.props.header }`;

        if (this.props.sys_id && this.props.header === get_types.activity_type_category) {
            header = get_types.new_activity_type_category;
        }

        let currentTab;
        if (this.activeTab === 0) {
            const saveButtonCaption = this.props.sys_id ? workflow_titles.save_button : workflow_titles.button_create;
            currentTab =
                <>
                    <div key='formContainer' className={ `${ styles.Form } ${ styles.Scroll }` }>
                        <DynamicForm
                            key={ 'dynamicForm' }
                            sections={ this.data.sections }
                            fields={ this.data.sections[0].elements }
                            name='Default'
                            sysId={ this.props.sys_id }
                            tableName={ this.props.tableName || this.props.type }
                            tableId={ this.data.table_id }
                            clientScripts={ this.data.client_scripts }
                            silentMode
                            id={ this.dynamicFormId }
                        />
                    </div>
                    { (!this.props.readOnly || !this.props.sys_id) && <div className={ styles.ButtonsPanel }>
                        <Button
                            className={ styles.ButtonCancel }
                            onClick={ this.handleCloseClick }
                            disabled={ this.isFormSave }
                        >
                            { workflow_titles.cancel_button }
                        </Button>
                        <Button
                            className={ styles.ButtonSave }
                            buttonType={ 'primary' }
                            onClick={ this.handleSaveClick }
                            disabled={ this.isFormSave }
                        >
                            { saveButtonCaption }
                        </Button>
                    </div> }
                </>;
        }
        else {
            const start = this.props.status.started_at ? new Date(getLocalTime(this.props.status.started_at)) : null;
            const started_at = start ? shortDateTimeToString(start) : '';
            const end = this.props.status.ended_at ? new Date(getLocalTime(this.props.status.ended_at)) : null;
            const ended_at = end ? shortDateTimeToString(end) : '';

            currentTab = <div key='formContainer' className={ `${ styles.Info } ${ styles.Scroll }` }>
                <div className={ styles.Group }>
                    <div className={ styles.Label }>{ workflow_titles.time_begin }</div>
                    <div className={ styles.Text }>{ started_at }</div>
                </div>
                <div className={ styles.Group }>
                    <div className={ styles.Label }>{ workflow_titles.time_end }</div>
                    <div className={ styles.Text }>{ ended_at }</div>
                </div>
                <div className={ styles.Group }>
                    <div className={ styles.Label }>{ workflow_titles.activity_state }</div>
                    <div className={ styles.Text }>{ this.props.status.state }</div>
                </div>
                { this.props.status.result && <div className={ styles.Group }>
                    <div className={ styles.Label }>{ workflow_titles.result }</div>
                    <LongText text={ this.props.status.result } />
                </div>
                }
                { this.props.status.fault_description && <div className={ styles.Group }>
                    <div className={ styles.Label }>{ workflow_titles.details }</div>
                    <LongText text={ this.props.status.fault_description } />
                </div>
                }
            </div>;
        }

        return (
            <div key='propertiesPanel' className={ styles.PropertiesPanel }>
                <div className={ styles.Header }>
                    { header }
                </div>
                <div className={ styles.CloseButton } dangerouslySetInnerHTML={ { __html: IconX } } onClick={ this.handleCloseClick } />
                { !!this.props.status &&
                <div className={ styles.Tabs }>
                    <div className={ `${ styles.Tab } ${ this.activeTab === 0 ? styles.ActiveTab : '' }` } onClick={ () => this.toggleTabs(0) }>{ workflow_titles.properties }</div>
                    <div className={ `${ styles.Tab } ${ this.activeTab === 1 ? styles.ActiveTab : '' }` } onClick={ () => this.toggleTabs(1) }>{ workflow_titles.info }</div>
                </div>
                }
                { currentTab }
            </div>
        );
    }
}
