import * as React from 'react';
import { observer } from 'mobx-react';
import InfoMessagesState from 'globalState/infoMessages';
import { observable } from 'mobx';
import { fetchListData } from 'actions/list';
import * as _ from 'lodash';

/**
 * Props:
 * table_name - имя таблицы с которой будет получен список
 */
@observer
export default class GetList extends React.Component {
    @observable items = [];

    constructor(props) {
        super(props);
        this.fetchData().catch((e) => InfoMessagesState.pushError(e.message));
    }

    transformItem = (item) => {
        const result = {};

        for (const key in item) {
            if (key === 'type') continue; // reserved React prop
            const data = item[key];
            if (data.read_access) {
                let value = data.value;
                if (typeof value === 'object' && value instanceof Object) value = value.display_value;

                result[key] = value;
            }
        }

        return result;
    };

    fetchData = async () => {
        const { table_name, widgetId } = this.props;
        const response = await fetchListData(widgetId, { essence: table_name });
        const data = response.isOkStatus ? response.data : {};
        this.items = !_.isEmpty(data.items) ? data.items.map(this.transformItem) : [];
    };

    render() {
        const { children } = this.props;
        if (this.items.length === 0) return null;

        return this.items.map(item => React.cloneElement(children[0], item));
    }
}
