import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import styles from './styles.module.scss';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import userState from 'globalState/user';
import { DateTimePicker } from 'components/dateTimePicker';
import DateInput from 'components/dynamicForms/view/field/dateTimeInput';
import Dropdown from 'components/dropdown';
import { getUserTimezone, convertServerTimeToISO, getTimeZoneDate, convertToServerDateTimeFormat } from 'helpers/getUserTimeZone';
import IconCalendar from 'assets/img/icons/calendar.svg';
import Button from 'components/button';
import langStore from 'globalState/lang';
import IconClose from 'assets/img/icons/close-x.svg';
import IconPlus from 'assets/img/icons/plus.svg';
import IconArrowL from 'assets/img/icons/arrow-left.svg';
import { isMedia } from 'helpers/html';
import GlobalPortal from 'components/globalPortal';
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATE_REGEXP,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_DATE_TIME_REGEXP,
    DEFAULT_TIME_FORMAT,
    DEFAULT_TIME_REGEXP,
    DEFAULT_TIMESTAMP_FORMAT,
    DEFAULT_TIMESTAMP_REGEXP,
} from 'constants/dateTime';
import { getFormatFromPHP } from 'helpers/date';

/**
 * Описание: компонент dateTimeInput
 * Параметры:
 * @param {string} pickertype - тип
 * @param {string} format - формат
 * @method onChange - метод изменения значения в родителе
 * @param {string} placeholder - placeholder
 * @param {object} value
 * @method onSearch
 */
@observer
export default class ListFilterSearchDate extends React.Component {
    @observable isShowDateTimePicker;
    refInput = React.createRef();
    refDropdown = React.createRef();
    @observable beginDate = null;
    @observable endDate = null;

    @observable showDateEnd = false;
    @observable showCalendarEnd = false;
    @observable showCalendarStart = false;

    constructor(props) {
        super(props);
        this.isShowDateTimePicker = false;

        reaction(
            () => this.isShowDateTimePicker,
            this.freezeBodyScroll
        );
    }

    freezeBodyScroll = () => {
        const { body } = document;

        if(!isMedia('sm')) return;

        if (this.isShowDateTimePicker) {
            body.classList.add(styles.Freeze);
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${scrollY}px`;
        }
        else {
            body.classList.remove(styles.Freeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        window.addEventListener('scroll', this.onWindowScroll);

        const { value } = { ...this.props };
        const beginVal = value.beginDate ? getTimeZoneDate(value.beginDate).format(this.getFormat()) : null;
        const endVal = value.endDate ? getTimeZoneDate(value.endDate).format(this.getFormat()) : null;
        this.beginDate = beginVal;
        this.endDate = beginVal === endVal ? null : endVal;
        this.showDateEnd = !!this.endDate;
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        window.removeEventListener('scroll', this.onWindowScroll);
    }

    onWindowScroll = () => {
        if(isMedia('sm')) return;
        this.handleModalClose();
    };

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const inputEl = this.refInput.current;
        if (!dropdownEl || !inputEl) return false;

        if (!dropdownEl.contains(e.target) && !inputEl.contains(e.target)) {
            this.handleModalClose();
        }
    };

    applyTimeZone = (value, format) => {
        if (getUserTimezone().toLowerCase() !== 'utc' &&
            (format === DEFAULT_DATE_TIME_FORMAT || format === DEFAULT_TIME_FORMAT)) {
            return value.tz('UTC').format(format);
        }
        return value.format(format);
    };

    prepareValue = (data) => {
        const format = this.getFormat();

        if (data instanceof moment) {
            return this.applyTimeZone(moment(data), format);
        }

        if (data.match(this.getRegExp())) {
            let value = data;
            if (format === DEFAULT_TIME_FORMAT) {
                const time = convertServerTimeToISO(data);
                value = moment.tz(time, getUserTimezone());
            }
            else {
                value = moment.tz(data, getUserTimezone());
            }
            return this.applyTimeZone(value, format);
        }

        return data;
    };

    getFormat = () => {
        if (this.props.format) {
            return moment.formatPHP(this.props.format);
        }

        switch (this.props.pickertype) {
            case 'date':
                return DEFAULT_DATE_FORMAT;

            case 'datetime':
                return DEFAULT_DATE_TIME_FORMAT;

            case 'time':
                return DEFAULT_TIME_FORMAT;

            case 'timestamp':
                return DEFAULT_TIMESTAMP_FORMAT;

            default:
                return DEFAULT_DATE_TIME_FORMAT;
        }
    };

    getRegExp = () => {
        switch (this.props.pickertype) {
            case 'date':
                return DEFAULT_DATE_REGEXP;

            case 'datetime':
                return DEFAULT_DATE_TIME_REGEXP;

            case 'time':
                return DEFAULT_TIME_REGEXP;

            case 'timestamp':
                return DEFAULT_TIMESTAMP_REGEXP;

            default:
                return DEFAULT_DATE_REGEXP;
        }
    };

    handleModalClose = () => {
        this.isShowDateTimePicker = false;
    };

    showDateTimePicker = () => {
        this.isShowDateTimePicker = this.isReadOnly ? false : !this.isShowDateTimePicker;
    };

    handleUpdate = (type) => (date) => {
        if (type === 'beginDate') {
            this.beginDate = this.prepareValue(date);
            this.showCalendarStart = false;
        }
        else {
            this.endDate = this.prepareValue(date);
            this.showCalendarEnd = false;
        }
    };

    handleApplyClick = () => {
        const { onSearch } = this.props;
        let beginDate = null;
        let endDate = null;

        if(this.beginDate && this.endDate){
            beginDate = this.beginDate;
            endDate = this.endDate;
        }
        else if (!this.beginDate && this.endDate) {
            beginDate = this.endDate;
            endDate = this.endDate;
        }
        else if(this.beginDate && !this.endDate){
            beginDate = this.beginDate;
            endDate = this.beginDate;
        }

        beginDate = beginDate ? convertToServerDateTimeFormat(`${beginDate} 00:00:00`) : null;
        endDate = endDate ? convertToServerDateTimeFormat(`${endDate} 23:59:59`) : null;

        this.props.onChange({
            beginDate: beginDate,
            endDate: endDate,
        });
        if (onSearch) {
            onSearch();
        }
        this.handleModalClose();
    };

    handleInputChange = (type) => ({ value }) => {
        if(!value) return;

        if (type === 'beginDate') {
            this.beginDate = value;
        }
        else {
            this.endDate = value;
        }

        this.checkEndDate();
    };

    getDate = (date) => {
        let result = null;
        if (!date) {
            return result;
        }
        switch (true) {
            case date instanceof moment:
                result = date;
                break;

            case date instanceof Date:
            case typeof date === 'number':
                result = moment(date);
                break;
            case typeof date === 'string':
                result = moment(date, 'YYYY-MM-DD');
                if (!result.isValid()) {
                    throw new Error(result.format('YYYY-MM-DD'));
                }
                break;
        }
        return result;
    };

    checkEndDate = () => {
        const beginDate = this.getDate(this.beginDate) ? this.getDate(this.beginDate).startOf('day') : null;
        const endDate = this.getDate(this.endDate) ? this.getDate(this.endDate).startOf('day') : null;

        if(beginDate && endDate){
            if(beginDate.isAfter(endDate)){
                this.endDate = this.beginDate;
            }
        }
    };

    handleCleanDate = (type) => () => {
        if (type === 'beginDate') {
            this.beginDate = null;
        }
        else {
            this.endDate = null;
        }
    };

    handleIntervalBtnClick = () => {
        if(this.showDateEnd) {
            this.handleCleanDate('endDate')();
        }
        this.showDateEnd = !this.showDateEnd;
    };

    getDisplayValue = () => {
        const { value } = { ...this.props };
        const beginDate = this.getInputDisplayDate(value.beginDate);
        const endDate = this.getInputDisplayDate(value.endDate);
        if (_.isNil(value.beginDate) && _.isNil(value.endDate)) {
            return null;
        }
        else if (_.isNil(value.endDate)) {
            return beginDate;
        }
        else if (_.isNil(value.beginDate)) {
            return endDate;
        }
        else if (beginDate === endDate) {
            return beginDate;
        }
        else {
            return `${ beginDate } - ${ endDate }`;
        }
    };

    getDisplayDate = (value) => {
        if (_.isNil(value) || (typeof value === 'string' && !value.match(this.getRegExp()))) {
            return value;
        }
        const format = this.getFormat();

        if (format === DEFAULT_DATE_TIME_FORMAT) {
            return getTimeZoneDate(value).format(format);
        }

        if (format === DEFAULT_TIME_FORMAT) {
            const time = convertServerTimeToISO(value);
            return getTimeZoneDate(time).format(format);
        }
        return value;
    };

    getInputDisplayDate = (value) => {
        return getTimeZoneDate(value).format(getFormatFromPHP(this.getDateInputFormat()));
    };

    renderValue = () => {
        const { placeholder } = this.props;
        const value = this.getDisplayValue();
        return (
            <div className={ styles.Value }>
                <div className={ `${styles.ValueText} ${!value ? styles.Placeholder : ''}` }>{ value ? value : placeholder }</div>
            </div>
        );
    };

    getDateInputFormat = () => {
        const { user = {} } = userState;
        const { pickertype } = this.props;
        let format = '';

        if(user.datetime_format) {
            if(pickertype === 'date') {
                format = user.datetime_format.split(' ')[0];
            }
            else {
                format = user.datetime_format;
            }
        }

        return format;
    };

    renderDropdown = () => {
        if (!this.isShowDateTimePicker) {
            return null;
        }
        const { pickertype } = this.props;
        const { data_picker_titles } = langStore.getTranslate();
        const beginDate = this.getDisplayDate(this.beginDate);
        const endDate = this.getDisplayDate(this.endDate);
        const dateBlockTpl = (
            <div className={ styles.DateBlock }>
                <div className={`${styles.DateForm} ${this.showCalendarEnd || this.showCalendarStart ? styles.Hidden : ''}`}>
                    <div className={styles.DateFormHeading}>
                        { data_picker_titles && data_picker_titles.filter_by_date }
                        <div
                            className={styles.DateFormClose}
                            dangerouslySetInnerHTML={{__html:IconClose}}
                            onClick={ this.handleModalClose }
                        />
                    </div>
                    <div className={styles.DateFormField}>
                        <div className={styles.DateFormTitle}>{ data_picker_titles && (this.showDateEnd ? data_picker_titles.start : data_picker_titles.date) }</div>
                        <div className={styles.DateFormFlex}>
                            <div className={styles.DateFormInput}>
                                <DateInput
                                    placeholder={ data_picker_titles && data_picker_titles.pick_a_date }
                                    pickertype={ pickertype }
                                    hidePickerButton
                                    value={this.beginDate}
                                    onChange={this.handleInputChange('beginDate')}
                                    format={ this.getDateInputFormat() }
                                />
                                <div
                                    dangerouslySetInnerHTML={{__html: IconClose}}
                                    className={`${styles.DateFormCross} ${!this.beginDate ? styles.Hidden : ''}`}
                                    onClick={this.handleCleanDate('beginDate')}
                                />
                            </div>
                            <div className={styles.DateFormButton}>
                                <Button
                                    buttonType={'icon-border'}
                                    svg={IconCalendar}
                                    onClick={()=>{
                                        this.showCalendarStart = true;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.DateFormField} ${!this.showDateEnd ? styles.Hidden : ''}`}>
                        <div className={styles.DateFormTitle}>{ data_picker_titles && data_picker_titles.end }</div>
                        <div className={styles.DateFormFlex}>
                            <div className={styles.DateFormInput}>
                                <DateInput
                                    placeholder={ data_picker_titles && data_picker_titles.pick_a_date }
                                    pickertype={ pickertype }
                                    hidePickerButton
                                    value={this.endDate}
                                    onChange={this.handleInputChange('endDate')}
                                    format={ this.getDateInputFormat() }
                                />
                                <div
                                    dangerouslySetInnerHTML={{__html: IconClose}}
                                    className={`${styles.DateFormCross} ${!this.endDate ? styles.Hidden : ''}`}
                                    onClick={this.handleCleanDate('endDate')}
                                />
                            </div>
                            <div className={styles.DateFormButton}>
                                <Button
                                    buttonType={'icon-border'}
                                    svg={IconCalendar}
                                    onClick={()=>{
                                        this.showCalendarEnd = true;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.DateFormField}>
                        <div
                            className={styles.IntervalBtn}
                            onClick={this.handleIntervalBtnClick}
                        >
                            <div className={`${styles.IntervalBtnIcon} ${!this.showDateEnd ? styles.Hidden : ''}`} dangerouslySetInnerHTML={{__html: IconClose}}/>
                            <div className={`${styles.IntervalBtnIcon} ${this.showDateEnd ? styles.Hidden : ''}`} dangerouslySetInnerHTML={{__html: IconPlus}}/>
                            <div className={styles.IntervalBtnText}>{ data_picker_titles && (this.showDateEnd ? data_picker_titles.delete_the_interval : data_picker_titles.add_an_interval)}</div>
                        </div>
                    </div>
                    <div className={ styles.ApplyButton }>
                        <Button
                            buttonType='primary'
                            className={ styles.FooterButton }
                            onClick={ this.handleApplyClick }
                        >
                            { data_picker_titles && data_picker_titles.apply }
                        </Button>
                    </div>
                </div>


                <div className={ styles.DatePickers }>
                    <div className={ `${styles.DatePickersItem}  ${!this.showCalendarStart ? styles.Hidden : ''}` }>
                        <div className={ styles.HeaderDate }>
                            <div
                                dangerouslySetInnerHTML={{__html:IconArrowL}}
                                className={styles.HeaderDateArrow}
                                onClick={()=>{
                                    this.showCalendarStart = false;
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{__html:IconClose}}
                                className={styles.HeaderDateClose}
                                onClick={ this.handleModalClose }
                            />
                            { data_picker_titles && (this.showDateEnd ? data_picker_titles.start : data_picker_titles.date) }
                        </div>
                        <DateTimePicker
                            initialValue={
                                beginDate && beginDate.match(this.getRegExp()) ?
                                    moment.tz(beginDate, getUserTimezone()).format(this.getFormat())
                                    : moment.tz(getUserTimezone())
                            }
                            pickertype={ pickertype }
                            update={ this.handleUpdate('beginDate') }
                            endDate={ this.endDate }
                            onClose={ this.handleModalClose }
                            isHideFooter
                            isHiddenNowBtn
                        />
                    </div>
                    <div className={ `${styles.DatePickersItem} ${!this.showCalendarEnd ? styles.Hidden : ''}` }>
                        <div className={ styles.HeaderDate }>
                            <div
                                dangerouslySetInnerHTML={{__html:IconArrowL}}
                                className={styles.HeaderDateArrow}
                                onClick={()=>{
                                    this.showCalendarEnd = false;
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{__html:IconClose}}
                                className={styles.HeaderDateClose}
                                onClick={ this.handleModalClose }
                            />
                            { data_picker_titles && data_picker_titles.end }
                        </div>
                        <DateTimePicker
                            initialValue={
                                endDate && endDate.match(this.getRegExp()) ?
                                    moment.tz(endDate, getUserTimezone()).format(this.getFormat())
                                    : moment.tz(getUserTimezone())
                            }
                            pickertype={ pickertype }
                            update={ this.handleUpdate('endDate') }
                            beginDate={ this.beginDate }
                            onClose={ this.handleModalClose }
                            isHideFooter
                            isHiddenNowBtn
                        />
                    </div>
                </div>
            </div>
        );

        return isMedia('sm') ?
            <GlobalPortal>{ dateBlockTpl }</GlobalPortal> :
            <Dropdown disableMinWidth refParent={ this.refInput } ref={ this.refDropdown }>{ dateBlockTpl }</Dropdown>;
    };

    renderButton = () => {
        return (
            <button
                type={'button'}
                className={ styles.Button }
                onClick={ this.showDateTimePicker }
            >
                { this.renderValue() }
            </button>
        );
    };

    render() {
        return (
            <div className={ styles.Search } ref={ this.refInput }>
                <div className={ styles.Group }>
                    { this.renderButton() }
                </div>
                { this.renderDropdown() }
            </div>
        );
    }
}
