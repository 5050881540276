/**
 /**
 * Описание: Диаграмма рабочих процессов
 * Для отображения объектов и связей между ними
 * Параметры:
 * model - модель с данными
 * method - объект с методами обновления сущностей модели
 *{
 *   activity: {
 *       create: createActivity,
 *       remove: removeActivity,
 *       update: updateActivity,
 *   },
 *   transition: {
 *       create: createTransition,
 *       remove: removeTransition,
 *       update: updateTransition,
 *   }
 *}
 * togglePropertiesPanel - метод открытия спанели свойств объекта
 * selected - выбранный объект диаграммы
 * setSelected - установить объект выбранным
 * readOnly - закрыто для редактирования
 * currentWorkflow - открытый workflow
 * isViewer - режим просмотра
 * zoom - зум (100%=1)
 * decreaseZoom - уменьшить зум
 * increaseZoom - увеличить зум
 * resetZoom - сбросить зум
 * adjustZoom - настроить диаграмму под размеры экрана
 * stageX - смещение диаграммы по X
 * stageY - смещение диаграммы по Y
 * setStagePosition - установить смещение
 * activityWidth - ширина активности
 * activityHeight - высота активности
 * isUserAction - изменение зума или позиции инициировано пользователем
 * */

import { observer } from 'mobx-react';
import * as React from 'react';
import { Stage, Layer } from 'react-konva';
import { observable, computed, action } from 'mobx';
import Activity from './activity';
import Transition from './transition';
import End from './end';
import styles from './styles.module.scss';

import IconCreateRecord from 'assets/img/icons/file-plus.svg';
import IconEnd from 'assets/img/icons/flag.svg';
import IconEvent from 'assets/img/icons/calendar.svg';
import IconGroupApproval from 'assets/img/icons/user-many.svg';
import IconIf from 'assets/img/icons/if.svg';
import IconJoin from 'assets/img/icons/log-in.svg';
import IconNotification from 'assets/img/icons/notification-bell.svg';
import IconRollback from 'assets/img/icons/rotate-left.svg';
import IconRun from 'assets/img/icons/voicemail.svg';
import IconStart from 'assets/img/icons/play-circle.svg';
import IconSubworkflow from 'assets/img/icons/subworkflow.svg';
import IconSwitch from 'assets/img/icons/git-pull-request.svg';
import IconTimer from 'assets/img/icons/timer.svg';
import IconUserApproval from 'assets/img/icons/user-check.svg';
import IconWait from 'assets/img/icons/clock.svg';
import IconRunScript from 'assets/img/icons/voicemail.svg';
import langStore from 'globalState/lang';
import _ from 'lodash';

const exitHeight = 32;
const endWidth = 92;
const endHeight = 92;

@observer
export default class Workflow extends React.Component {
    @observable scaleBy = 1.01;
    @observable activeExit = null; // выбранный выход для создания связи
    @observable activeActivity = null;
    @observable activeTransition = null; // связь для изменения, на которую в данный момент указывает мышь
    @observable isFontLoaded = false;
    @observable isFinished = false;
    @observable contextMenu = {
        isVisible: false,
        top: 0,
        left: 0,
        ref: React.createRef(),
    };

    refStage = React.createRef();
    layer = {};
    userArrow = {};
    editableTransition; // объект редактируемой связи { sys_id: null, points: [x0, x1, y0, y1]}, sys_id - идентификатор transition, points - координаты точек линии
    transitionAction; // 'create'/'edit'

    @observable stage = {
        stageScale: 1,
        stageX: 0,
        stageY: 0,
        height: window.innerHeight,
        width: window.innerWidth,
    };
    @observable isPanMode = false;
    @observable isPanModeGrabbing = false;

    static icons = {};

    getIcon = (typeId, overrideColor) => {
        let svg,
            color;
        color = overrideColor ? overrideColor.substring(1, 7) : '000000';
        const activityType = this.props.model.wf_activity_type.find((elem) => (elem.sys_id === typeId));
        const iconId = ''.concat(activityType.name, color);
        if (Workflow.icons[iconId]) {
            return Workflow.icons[iconId];
        }
        const img = document.createElement('img');
        if (activityType.image_url){
            img.src = activityType.image_url;
            img.width = 24;
            img.height = 24;
        } else {
            switch (activityType.name) {
                case 'create-record':
                    svg = IconCreateRecord;
                    break;
                case 'End':
                    svg = IconEnd;
                    break;
                case 'Event':
                    svg = IconEvent;
                    break;
                case 'Group-approval':
                    svg = IconGroupApproval;
                    break;
                case 'If':
                    svg = IconIf;
                    break;
                case 'Join':
                    svg = IconJoin;
                    break;
                case 'Notification':
                    svg = IconNotification;
                    break;
                case 'Rollback':
                    svg = IconRollback;
                    break;
                case 'Run':
                    svg = IconRun;
                    break;
                case 'Begin':
                    svg = IconStart;
                    break;
                case 'Subworkflow':
                    svg = IconSubworkflow;
                    break;
                case 'Switch':
                    svg = IconSwitch;
                    break;
                case 'Timer':
                    svg = IconTimer;
                    break;
                case 'User-approval':
                    svg = IconUserApproval;
                    break;
                case 'Wait':
                    svg = IconWait;
                    break;
                case 'Script':
                    svg = IconRunScript;
                    break;
                case 'Run Script':
                    svg = IconRunScript;
                    break;
                default :
                    svg = IconCreateRecord;
                    break;
            }
            if (!svg) {
                return;
            }
            const fillProperty = `fill="#${ color }"`;
            img.src = `data:image/svg+xml;base64,${ btoa(svg.replace(/fill="#(.*?)"/, fillProperty)) }`;
        }

        return Workflow.icons[iconId] = new Promise((resolve, reject) => {
            img.addEventListener('load', () => resolve(img));
            img.addEventListener('error', (e) => reject(e));
        });
    };

    constructor(props) {
        super(props);
    }

    @computed get transitions() {
        if (this.props.isViewer) {
            const transitions = _.cloneDeep(this.props.model.wf_transition);
            return transitions.map((elem) => {
                const transitionDone = this.props.model.wf_executing_activity_history.find((transition) => (transition.transition_id === elem.sys_id));
                elem.state = !!transitionDone;
                return elem;
            });
        }
        else {
            return this.props.model.wf_transition;
        }
    }

    componentDidMount() {
        const stage = this.refStage.current;
        const container = stage.container();
        container.focus();
        container.addEventListener('keydown', this.handleKeyDown);
        container.addEventListener('keyup', this.handleKeyUp);
        this.layer = new Konva.Layer();
        stage.add(this.layer);
        stage.on('mousedown', (e) => {
            if (this.activeExit && e.evt.button === 0) { // нажатие левой кнопкой мыши на выходе активности
                // Создание новой связи
                this.transitionAction = 'create';
                this.startTransition();
            }
            if (this.activeTransition && this.props.selected.type === 'wf_transition'
                && this.props.selected.sys_id === this.activeTransition && e.evt.button === 0) { // нажатие левой кнопкой мыши на стрелке связи
                // Изменение текущей связи
                const transition = this.transitions.find((elem) => (elem.sys_id === this.activeTransition));
                const exit = this.exits.find((elem) => (elem.sys_id === transition.activity_exit_id));
                this.activeExit = exit.sys_id;
                const activity = this.activities.find((elem) => (elem.sys_id === exit.activity_id));
                const start = {
                    x: activity.x + activity.width,
                    y: activity.y + activity.height + exit.y + exit.height / 2,
                };
                this.transitionAction = 'edit';
                this.startTransition(start.x, start.y);
            }
        });
        stage.on('mousemove', () => {
            if (this.editableTransition) { // режим редактирования связи
                this.modifyTransition();
            }
        });
        stage.on('mouseleave', () => {
            if (this.editableTransition) { // режим редактирования связи
                this.cancelTransition();
            }
        });
        stage.on('mouseup', () => {
            if (this.editableTransition) { // режим редактирования связи
                this.finishTransition();
            }
        });
        document.fonts.ready.then(() => {
            this.isFontLoaded = true;
        });
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.changeRect);
        setTimeout(function () {
            this.isFinished = true;
        }.bind(this), 2000); // workaround для перерисовки иконок после начальной загрузки
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.changeRect);
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentWorkflow !== prevProps.currentWorkflow) {
            // Сбросить настройки просмотра stage
            this.stage = {
                stageScale: 1,
                stageX: 0,
                stageY: 0,
                height: window.innerHeight,
                width: window.innerWidth,
            };
        }
        if (this.props.currentWorkflow === prevProps.currentWorkflow) {
            if (this.props.zoom !== prevProps.zoom || this.props.stageX !== prevProps.stageX || this.props.stageY !== prevProps.stageY) {
                if (this.props.isUserAction) {
                    const stage = this.refStage.current;
                    let position = stage.getPointerPosition();
                    if (!position) {
                        position = {
                            x: window.innerWidth / 2,
                            y: window.innerHeight / 2,
                        };
                    }

                    const mousePointTo = {
                        x: position.x / prevProps.zoom - stage.x() / prevProps.zoom,
                        y: position.y / prevProps.zoom - stage.y() / prevProps.zoom,
                    };

                    if (this.props.zoom !== prevProps.zoom) { // меняем zoom
                        this.stage = {
                            ...this.stage,
                            stageScale: this.props.zoom,
                            stageX: -(mousePointTo.x - position.x / this.props.zoom) * this.props.zoom,
                            stageY: -(mousePointTo.y - position.y / this.props.zoom) * this.props.zoom,
                        };
                    }
                    else { // перетаскиваем
                        this.stage = {
                            ...this.stage,
                            stageX: this.props.stageX,
                            stageY: this.props.stageY,
                        };
                    }
                }
                else {
                    this.stage = {
                        ...this.stage,
                        stageX: this.props.stageX,
                        stageY: this.props.stageY,
                        stageScale: this.props.zoom,
                    };
                }
            }
        }
    }

    changeRect = () => {
        this.stage = {
            ...this.stage,
            height: window.innerHeight,
            width: window.innerWidth,
        };
    };

    @computed get activities() {
        const activities = _.cloneDeep(this.props.model.wf_activity);
        return activities.map((act) => {
            const activity_type = this.props.model.wf_activity_type.find((elem) => (elem.sys_id === act.activity_type_id));
            act.type_name = activity_type.name;
            const exits = this.exits.filter((elem) => (elem.activity_id === act.sys_id));
            exits.sort((exit1, exit2) => (exit1.order - exit2.order)).map((exit, index) => {
                exit.y = index * exitHeight;
                exit.height = exitHeight;
                return exit;
            });
            if (this.props.isViewer) {
                const running_activity = this.props.model.wf_executing_activity.find((elem) => (elem.activity_id === act.sys_id)); // count(wf_activity) == count(wf_executing_activity)
                act.state = running_activity ? running_activity.state : null;
                act.executed_order = running_activity ? running_activity.executed_order : null;
            }
            if (act.type_name === 'End') {
                act.width = endWidth;
                act.height = endHeight;
                act.fact_height = endHeight;
                act.exits = [];
            }
            else {
                act.width = this.props.activityWidth;
                act.height = this.props.activityHeight;
                act.fact_height = exits && exits.length > 0 ? this.props.activityHeight + exits.length * exitHeight : this.props.activityHeight;
                act.exits = exits;
            }
            return act;
        });
    }

    @computed get exits() {
        return this.props.model.wf_activity_exit.map((elem) => {
            const linked = this.transitions.find((transition) => (transition.activity_exit_id === elem.sys_id));
            return {
                ...elem,
                linked: !!linked,
                state: linked && linked.state,
            };
        });
    }

    selectObject = (type, sys_id, state) => {
        if (this.props.selected.type === type && this.props.selected.sys_id === sys_id) { // объект уже выбран
            if (!state) //возможность оставить выделенным элемемент при DblClick
            {
                this.props.setSelected(null, null);
            }
        }
        else {
            if (typeof (state) === 'undefined' || state === true) {
                this.props.setSelected(type, sys_id);
            }
        }
    };


    handleWheel = e => {
        e.evt.preventDefault();
        if (e.evt.deltaY > 0) {
            this.props.decreaseZoom(true);
        }
        else {
            this.props.increaseZoom(true);
        }
    };

    handleKeyDown = (evt) => {
        const type = this.props.selected.type;
        const sys_id = this.props.selected.sys_id;
        if (evt.code === 'Delete' && type && sys_id && !this.props.readOnly) {
            this.props.setSelected(null, null);
            if (type === 'wf_activity') {
                this.props.method.wf_activity.remove(sys_id);
                this.props.setSelected(null, null);
            }
            if (type === 'wf_transition') {
                this.props.method.wf_transition.remove(sys_id);
                this.props.setSelected(null, null);
                this.activeTransition = null;
            }
            if (type === 'wf_activity_exit') {
                this.props.method.wf_activity_exit.remove(sys_id);
                this.props.setSelected(null, null);
            }
        }
        if (evt.code === 'Space') { // pan mode on
            this.isPanMode = true;
        }
        if (evt.ctrlKey && evt.code === 'Digit0') {
            this.props.resetZoom();
        }
        if (evt.ctrlKey && evt.altKey && evt.code === 'Digit0') {
            this.props.adjustZoom();
        }
    };

    handleKeyUp = (e) => {
        if (e.code === 'Space') { // pan mode off
            this.isPanMode = false;
            this.isPanModeGrabbing = false;
        }
    };

    handleMouseDown = () => {
        if (this.isPanMode) {
            this.isPanModeGrabbing = true;
        }
    };

    handleClick = () => {
        this.props.setSelected(null, null);
    };

    handleMouseUp = () => {
        //if (this.isPanMode)
        this.isPanModeGrabbing = false;
    };

    setActiveExit = (sys_id) => {
        if (!this.editableTransition) // не меняем активный выход до окончания создания новой связи
        {
            this.activeExit = sys_id;
        }
    };

    setActiveTransition = (sys_id) => {
        this.activeTransition = sys_id;
    };

    setActiveActivity = (sys_id) => {
        if (this.editableTransition) // только для выбора активности в режиме создания/изменения связи
        {
            this.activeActivity = sys_id;
        }
    };

    getScaledPointerPosition = (x, y) => {
        const stage = this.refStage.current;
        let pointerPosition;
        if (!x || !y) {
            pointerPosition = stage.getPointerPosition();
        }
        else {
            pointerPosition = {
                x,
                y,
            };
        }

        const scaledX = Math.round((pointerPosition.x - stage.attrs.x) / stage.attrs.scaleX);
        const scaledY = Math.round((pointerPosition.y - stage.attrs.y) / stage.attrs.scaleY);
        return {
            x: scaledX,
            y: scaledY,
        };
    };

    startTransition = (x, y) => {
        if (!this.props.readOnly && !this.isPanMode) {
            const pos = this.getScaledPointerPosition();
            const startPos = (x && y) ? {
                x,
                y,
            } : pos;
            this.editableTransition = {
                sys_id: this.activeTransition,
                points: [
                    startPos.x,
                    startPos.y,
                ],
            };
            this.userArrow = new Konva.Arrow({
                stroke: '#0f8af9',
                fill: '#0f8af9',
                lineCap: 'round',
                lineJoin: 'round',
                points: this.editableTransition.points,
                listening: false,
            });
            this.layer.add(this.userArrow);
        }
    };

    modifyTransition = () => {
        if (!this.props.readOnly && !this.isPanMode) {
            const pos = this.getScaledPointerPosition();
            this.editableTransition.points[2] = pos.x;
            this.editableTransition.points[3] = pos.y;
            this.userArrow.points(this.editableTransition.points);
            this.layer.draw();
        }
    };

    cancelTransition = () => {
        this.editableTransition = null;
        if (this.userArrow) {
            this.userArrow.destroy();
        }
        this.userArrow = {};
        this.layer.draw();
        this.activeExit = null;
        this.activeActivity = null;
        this.transitionAction = null;
    };

    finishTransition = () => {
        if (!this.props.readOnly && !this.isPanMode) {
            if (this.userArrow) {
                this.userArrow.destroy();
            }
            this.userArrow = {};
            this.layer.draw();
            const exit = this.exits.find((elem) => (elem.sys_id === this.activeExit && elem.activity_id === this.activeActivity));
            if (this.activeExit && this.activeActivity && !exit) {
                if (this.transitionAction === 'create') {
                    this.props.method.wf_transition.create({
                        activity_exit_id: this.activeExit,
                        activity_to_id: this.activeActivity,
                    });
                }
                if (this.transitionAction === 'edit') {
                    this.props.method.wf_transition.update(this.editableTransition.sys_id, {
                        activity_exit_id: this.activeExit,
                        activity_to_id: this.activeActivity,
                    });
                }
            }
            this.activeExit = null;
            this.activeActivity = null;
            this.transitionAction = null;
            this.editableTransition = null;
        }
    };

    onDrop = (event) => {
        event.preventDefault();
        const data = JSON.parse(event.dataTransfer.getData('text'));
        this.refStage.current.setPointersPositions(event);
        let coor = this.getScaledPointerPosition();
        coor = {
            x: coor.x - this.props.activityWidth / 2,
            y: coor.y - this.props.activityHeight / 2,
        };
        switch (data.essence) {
            case 'wf_activity':
                if (this.props.selected.type === 'wf_transition' && this.props.selected.sys_id) {
                    this.props.method.wf_activity.create(coor, data.type_id, data.type_name, data.table_name, this.props.selected.sys_id);
                }
                else {
                    this.props.method.wf_activity.create(coor, data.type_id, data.type_name, data.table_name);
                }
                break;
            case 'wf_activity_exit':
                this.props.method.wf_activity_exit.create(coor);
                break;
        }
    };

    handleContextMenu = (e) => {
        e.evt.preventDefault(true);
        e.cancelBubble = true;
        if (!this.props.readOnly) {
            this.contextMenu.isVisible = true;
            this.contextMenu.top = e.evt.clientY;
            this.contextMenu.left = e.evt.clientX;
        }
    };

    @action
    handleClickOutside = (e) => {
        const { current: menu } = this.contextMenu.ref;
        if (!menu) return false;
        if (!menu.contains(e.target)) {
            this.contextMenu.isVisible = false;
        }

    };

    addExit = () => {
        this.props.method.wf_activity_exit.create({
            activity_id: this.props.selected.sys_id,
            description: '',
            name: 'New exit',
            order: 0,
        });
        this.contextMenu.isVisible = false;
    };

    removeExit = () => {
        const sys_id = this.props.selected.sys_id;
        this.props.setSelected(null, null);
        this.props.method.wf_activity_exit.remove(sys_id);
        this.contextMenu.isVisible = false;
    };

    handleStageDragEnd = (evt) => {
        this.props.setStagePosition(evt.target.x(), evt.target.y(), true);
    };

    handleTogglePropertiesClick = () => {
        this.props.togglePropertiesPanel(true);
        this.contextMenu.isVisible = false;
    };

    handleDeleteActivityClick = () => {
        const sys_id = this.props.selected.sys_id;
        this.props.setSelected(null, null);
        this.props.method.wf_activity.remove(sys_id);
        this.contextMenu.isVisible = false;
    };

    renderContextMenu = () => {
        const { workflow_titles } = langStore.getTranslate();

        const selectedSysId = this.props.selected && this.props.selected.sys_id;
        const checkActivities = this.activities.find((elem) =>
            (selectedSysId === elem.sys_id && (elem.type_name === 'Begin' || elem.type_name === 'End')),
        );
        const checkRemove = _.isEmpty(checkActivities);

        return (
            <ul className={ styles.ContextMenu }
                ref={ this.contextMenu.ref }
                style={ {
                    top: this.contextMenu.top,
                    left: this.contextMenu.left,
                } }>
                { !!this.props.selected && this.props.selected.type === 'wf_activity' && <li onClick={ this.addExit }>
                    { workflow_titles && workflow_titles.add_exit }
                </li> }
                { !!this.props.selected && this.props.selected.type === 'wf_activity' && !!checkRemove && <li onClick={ this.handleDeleteActivityClick }>
                    { workflow_titles && workflow_titles.delete_activity }
                </li> }
                { !!this.props.selected && this.props.selected.type === 'wf_activity_exit' && <li onClick={ this.removeExit }>
                    { workflow_titles && workflow_titles.remove_exit }
                </li> }
                { !!checkRemove &&
                <li onClick={ this.handleTogglePropertiesClick }>
                    { workflow_titles && workflow_titles.properties }
                </li>
                }
            </ul>
        );
    };

    handleDragOver = (event) => {
        const stage = this.refStage.current;
        event.preventDefault();
        this.refStage.current.setPointersPositions(event);
        const coor = stage.getPointerPosition();
        const pos = {
            x: coor.x,
            y: coor.y,
        };
        const shape = stage.getIntersection(pos);
        if (shape) {
            if (shape.className === 'Arrow') {
                if (this.props.selected.sys_id !== shape.attrs.id && this.props.selected.type !== 'wf_transition') {
                    this.props.setSelected('wf_transition', shape.attrs.id);
                }
            }
        }
        else {
            if (this.props.selected.sys_id || this.props.selected.type) {
                this.props.setSelected(null, null);
            }
        }
    };

    handleStageContextMenu = (event) => {
        event.evt.preventDefault(true);
    };

    render() {
        const activities = this.activities.map((act) => {
            const active = !!this.activeActivity && this.activeActivity === act.sys_id;
            switch (act.type_name) {
                case 'End':
                    return <End
                        sys_id={ act.sys_id }
                        key={ act.sys_id }
                        x={ act.x }
                        y={ act.y }
                        height={ act.height }
                        width={ act.width }
                        name={ act.name }
                        updatePosition={ this.props.method.wf_activity.update }
                        selectObject={ this.selectObject }
                        selected={ this.props.selected }
                        activeExit={ this.activeExit }
                        isActive={ active }
                        setActiveActivity={ this.setActiveActivity }
                        togglePropertiesPanel={ this.props.togglePropertiesPanel }
                        getIcon={ this.getIcon }
                        readOnly={ this.props.readOnly || this.isPanMode }
                        state={ act.state }
                        typeId={ act.activity_type_id }
                    />;
                default:
                    return <Activity
                        sys_id={ act.sys_id }
                        type_name={ act.type_name }
                        type_id={ act.activity_type_id }
                        key={ act.sys_id }
                        x={ act.x }
                        y={ act.y }
                        height={ act.height }
                        width={ act.width }
                        fact_height={ act.fact_height }
                        name={ act.name }
                        description={ act.description }
                        exits={ act.exits }
                        updatePosition={ this.props.method.wf_activity.update }
                        selectObject={ this.selectObject }
                        selected={ this.props.selected }
                        activeExit={ this.activeExit }
                        setActiveExit={ this.setActiveExit }
                        isActive={ active }
                        setActiveActivity={ this.setActiveActivity }
                        togglePropertiesPanel={ this.props.togglePropertiesPanel }
                        getIcon={ this.getIcon }
                        onContextMenu={ this.handleContextMenu }
                        isNew={ act.isNew }
                        readOnly={ this.props.readOnly || this.isPanMode }
                        state={ act.state }
                        executedOrder={ act.executed_order }
                        updateExit={ this.props.method.wf_activity_exit.update }
                        isPanMode={ this.isPanMode }
                        editCompleted={ act.active }
                    />;
            }
        });

        const transitions = this.transitions.map((transition) => {
            let exit = this.exits.find((elem) => (elem.sys_id === transition.activity_exit_id)); // нашли выход (старт) для связи
            const act1 = this.activities.find((elem) => (elem.sys_id === exit.activity_id)); // нашли активность
            exit = act1.exits.find((elem) => (elem.sys_id === transition.activity_exit_id)); // находим запись выхода с позиционированием (результат сортировки внутри активности)
            const act2 = this.activities.find((elem) => (elem.sys_id === transition.activity_to_id)); // находим целевую активность (конец связи)
            if (act1 && exit && act2) {
                return <Transition
                    sys_id={ transition.sys_id }
                    key={ transition.sys_id }
                    start={ act1 }
                    exit={ exit }
                    end={ act2 }
                    selectObject={ this.selectObject }
                    selected={ this.props.selected && this.props.selected.type === 'wf_transition' && this.props.selected.sys_id === transition.sys_id }
                    togglePropertiesPanel={ this.props.togglePropertiesPanel }
                    readOnly={ this.props.readOnly || this.isPanMode }
                    state={ transition.state }
                    setActiveTransition={ this.setActiveTransition }
                    isViewer={ this.props.isViewer }
                />;
            }
        });

        return (
            <div
                onDrop={ this.onDrop }
                onDragEnter={ (event) => event.preventDefault() }
                onDragOver={ this.handleDragOver }
                onClick={ (event) => this.handleClickOutside(event) }
            >
                <Stage
                    id='listen'
                    width={ this.stage.width }
                    height={ this.stage.height }
                    onWheel={ this.handleWheel }
                    scaleX={ this.stage.stageScale }
                    scaleY={ this.stage.stageScale }
                    x={ this.stage.stageX }
                    y={ this.stage.stageY }
                    className={ `${ styles.Main } ${ this.isPanMode ? styles.PanMode : '' } ${ this.isPanModeGrabbing ? styles.PanModeGrabbing : '' }` }
                    ref={ this.refStage }
                    tabIndex={ -1 }
                    isFontLoaded={ this.isFontLoaded } // for stage redraw after fonts are loaded
                    isFinished={ this.isFinished }
                    draggable={ this.isPanModeGrabbing }
                    onMouseDown={ this.handleMouseDown }
                    onMouseUp={ this.handleMouseUp }
                    onDragEnd={ this.handleStageDragEnd }
                    onKeyDown={ this.handleKeyDown }
                    onClick={ this.handleClick }
                    onContextMenu={ this.handleStageContextMenu }
                >
                    <Layer>
                        { transitions }
                        { activities }
                    </Layer>
                </Stage>
                { this.contextMenu.isVisible && this.renderContextMenu() }
            </div>
        );
    }
}
