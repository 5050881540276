import React, {useState, useRef} from 'react';
import styles from "./styles.module.scss";
import Hint from 'components/hint';
import Button from "components/button";
import {isMedia} from "helpers/html";

export const ToolbarItem = ({ children, onClick, icon, isActive, disabled, dataTest, ...rest }: any) => {
    const [showHint, setShoHint] = useState(false);
    const refParent = useRef<HTMLSpanElement | null>(null);

    const checkOverflow = () => {
        const labelEl = refParent?.current;
        if (!labelEl) return;
        setShoHint(true);
    };

    const isShowHint = !isMedia('sm') && showHint;

    return (
        <>
            <span
                className={styles.ToolbarItem}
                onMouseEnter={ checkOverflow }
                ref={ refParent }
                {...rest}
            >
                 <Button
                     buttonType='icon'
                     className={ isActive ? styles.Active : '' }
                     onClick={onClick}
                     svg={ icon }
                     data-test={ dataTest }
                     disabled={disabled}
                 />
            </span>
            { isShowHint && children && <Hint refParent={ refParent }>{ children }</Hint> }
        </>
    );
};