import { TablesStateTypes, TableTypes } from 'types/components/table/index';

class TablesState implements TablesStateTypes {

    _tables = []; // список таблиц

    constructor() {
    }

    /*
     * Получить список таблиц
     * */
    getTables = () => {
        return this._tables;
    };

    /**
     * Получить имена таблиц
     *  @returns {Array<string>}
     * */
    getTablesName() {
        return Object.keys(this._tables);
    }

    /*
     * Получить таблицу
     * Параметры:
     * sysId - идентификатор таблицы
     * */
    getTable = (sysId: string) => {
        return this._tables[sysId];
    };

    /*
     * Установить ссылку на таблицу
     * Параметры:
     * sysId - идентификатор таблицы
     * table - компонент таблицы
     * */
    setTable = (sysId: string, table: TableTypes) => {
        this._tables[sysId] = table;
    };

    /*
     * Удалить таблицу
     * Параметры:
     * sysId - идентификатор таблицы
     * */
    deleteTable = (sysId: string) => {
        delete this._tables[sysId];
    };

}


export default new TablesState();
