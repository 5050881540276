import * as React from 'react';
import { observer } from 'mobx-react';
import InfoMessages from 'components/infoMessages';
import { EnhancedComponent } from 'components/EnhancedComponent';
import MainLayout from 'layouts/main';
import servicePortalWidgetsState from 'globalState/servicePortalWidgets';
import ErrorBoundary from 'components/errorBoundary';

@observer
export default class NewServicePortalLayout extends EnhancedComponent {
    render() {
        const { children, ...restParams } = this.props;

        let content;
        if (servicePortalWidgetsState.hasLayout) {
            content = <MainLayout  { ...restParams } >{ React.cloneElement(children, restParams) }</MainLayout>;
        } else {
            content = React.cloneElement(children, restParams);
        }

        return (
            <>
                <ErrorBoundary>
                    { content }
                </ErrorBoundary>
                <ErrorBoundary>
                    <InfoMessages />
                </ErrorBoundary>
            </>
        );
    }
}
