import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import User from './user';
import langStore from 'globalState/lang';
import styles from './styles.module.scss';
import IconCog from 'assets/img/icons/settings.svg';
import IconBurger from 'assets/img/icons/sidebar.svg';
// import IconBell from 'assets/img/icons/bell.svg';
import IconSearch from 'assets/img/icons/search.svg';
import IconX from 'assets/img/icons/close-x.svg';
import { observable } from 'mobx';
import listboxState from 'globalState/listboxState';
import sidebarState from 'globalState/sidebarState';
import { handleKeyDown, onSearch, simpleDecodeUri } from 'helpers/search';
import InfoMessagesState from 'globalState/infoMessages';
import brandingState from 'globalState/branding';
import { isMedia } from 'helpers/html';
import MobileModal from 'components/modalWrapper';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { beforeUnload } from 'helpers/form';
import searchState from 'globalState/search';
import Button from 'components/button';

@withRouter
@observer
export default class LayoutHeader extends React.Component {
    @observable isFocused = false;
    @observable isSearchPopupVisible = false;
    @observable isMediaSm = false;
    refSearch = React.createRef();


    constructor(props) {
        super(props);
        searchState.setSearchParam(props.match.params.global_search_param ? simpleDecodeUri(props.match.params.global_search_param) : '');
    }

    componentDidMount() {
        this.checkMedia();
        window.addEventListener('resize', this.checkMedia);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkMedia);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.global_search_param !== prevProps.match.params.global_search_param) {
            searchState.setSearchParam(this.props.match.params.global_search_param ? simpleDecodeUri(this.props.match.params.global_search_param) : '');
        }
    }

    checkMedia = () => {
        this.isMediaSm = isMedia('sm');
    };

    errorFetchData = (message) => {
        InfoMessagesState.pushError(message);
    };

    handleValueChange = (e) => {
        searchState.setSearchTerm(e.target.value);
    };

    handleLoupClick = () => {
        onSearch(null, searchState.getSearchTerm());

        if (this.isMediaSm) { // if mobile - close search popup
            this.showSearchPopup(false)();
        }
        else {
            this.refSearch.current.focus();
        }
    };

    handleLogoClick = (evt) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
    };

    cleanSearch = () => {
        searchState.setSearchParam('');
        this.refSearch.current.focus();
    };

    onBurgerClick = () => {
        sidebarState.preventCloseNav = true;
        sidebarState.setOpenedState(true);
        if (sidebarState.activeTabIndex === -1) {
            sidebarState.setTabIndex(0).catch(console.error);
            sidebarState.fetchMenuList().catch(console.error);
        }
    };

    showSearchPopup = (flag) => () => {
        this.isSearchPopupVisible = flag;
    };

    renderSearch = () => {
        const { user_menu_titles } = langStore.getTranslate();
        const searchParam = searchState.getSearchTerm();
        const btnClean = searchParam.trim() !== '' ? <>
            <div
                className={ styles.SearchIcon }
                dangerouslySetInnerHTML={ { __html: IconX } }
                onClick={ this.cleanSearch }
                data-test={ ATTRIBUTES.searchHeaderIconClean }
            />
            <div className={ styles.SearchIconSplit } />
        </> : null;
        const searchStateClass = (searchParam.trim() !== '' || this.isFocused) ? styles.opened : '';
        const searchTpl = (
            <div
                className={ styles.SearchWrap }
                data-test={ ATTRIBUTES.searchHeaderInput }
            >
                <input className={ `${ styles.Search } ${ searchStateClass }` }
                       value={ searchParam }
                       onKeyDown={ (e) => {
                           handleKeyDown(e, null, searchParam);
                           if (e.key === 'Enter' && this.isMediaSm) {
                               this.showSearchPopup(false)();
                           }
                       } }
                       onFocus={ () => {
                           this.isFocused = true;
                       } }
                       onBlur={ () => {
                           this.isFocused = false;
                       } }
                       ref={ this.refSearch }
                       type="text"
                       placeholder={ user_menu_titles && user_menu_titles.search }
                       onChange={ this.handleValueChange } />
                <div className={ styles.SearchControls }>
                    { btnClean }
                    <div
                        className={ styles.SearchIcon }
                        dangerouslySetInnerHTML={ { __html: IconSearch } }
                        onClick={ this.handleLoupClick }
                        data-test={ ATTRIBUTES.searchHeaderIcon }
                    />
                </div>
            </div>
        );

        if (this.isMediaSm) {
            if (this.isSearchPopupVisible) {
                return (
                    <MobileModal
                        onMount={ () => {
                            if (this.refSearch.current) {
                                this.refSearch.current.focus();
                            }
                        } }
                    >
                        <div className={ `${ styles.SearchPopup }` }>
                            <div className={ styles.SearchHeader }>
                                <div className={ styles.SearchHeadingText }>{ user_menu_titles?.search }</div>
                                <div
                                    className={ styles.SearchHeadingClose }
                                    dangerouslySetInnerHTML={ { __html: IconX } }
                                    onClick={ this.showSearchPopup(false) }
                                />
                            </div>
                            { searchTpl }
                        </div>
                    </MobileModal>
                );
            }
            else {
                return null;
            }
        }
        else {
            return searchTpl;
        }
    };

    render() {
        const logoSrc = brandingState.getPlatformLogo();
        const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');

        return (
            <header className={ styles.Header } data-test={ ATTRIBUTES.header }>
                <button
                    dangerouslySetInnerHTML={ { __html: IconBurger } }
                    onClick={ this.onBurgerClick }
                    className={ `${ styles.BurgerIcon }` }
                    data-test={ ATTRIBUTES.headerBurgerIcon }
                />
                <Link
                    to='/'
                    onClick={ this.handleLogoClick }
                    className={ `${ styles.Logo } ${ styles.smHidden }` }
                    data-test={ ATTRIBUTES.headerLogo }
                >
                    <img src={ logoSrc }/>
                </Link>
                <div className={ styles.Options } data-test={ ATTRIBUTES.headerOptions }>
                    <div className={ styles.OptionsItem } data-test={ ATTRIBUTES.headerOptionItem }>
                        { this.renderSearch() }
                    </div>
                    <div className={ `${ styles.OptionsItem } ${ styles.smOnly }` } data-test={ ATTRIBUTES.headerOptionItem }>
                        <button
                            className={ styles.OptionsIcon }
                            dangerouslySetInnerHTML={ { __html: IconSearch } }
                            onClick={ this.showSearchPopup(true) }
                        />
                    </div>
                    <div className={ `${ styles.OptionsItem } ${ styles.user }` } data-test={ ATTRIBUTES.headerOptionItem }>
                        <User />
                    </div>
                    <div className={ styles.OptionsItem } data-test={ ATTRIBUTES.headerOptionItem }>
                        <Button
                            hint={ system_buttons_hints?.preferences }
                            buttonType={'icon'}
                            svg={ IconCog }
                            onClick={ () => {
                                listboxState.toggleOpenedState();
                            } }
                            data-test={ ATTRIBUTES.settingGear }
                        />
                    </div>
                </div>
            </header>
        );
    }
}
