import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import StringInput from 'components/dynamicForms/view/field/stringInput';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import RecordPopupButton from 'components/recordPopup/infoButton';
import Button from 'components/button';

import styles from 'components/dynamicForms/view/field/reference/styles.module.scss';
import Search from 'assets/img/icons/search.svg';
import Plus from 'assets/img/icons/plus.svg';
import Tree from 'assets/img/icons/tree.svg';
import apiRequest from 'lib/apiRequest';
import { observable, reaction } from 'mobx';
import SuggestDropDown from 'components/suggestDropDown';
import IconClose from 'assets/img/icons/close-circle.svg';
import ReferenceModel from 'components/dynamicForms/model/field/ReferenceModel';
import StringInputModel from 'components/dynamicForms/model/field/StringInputModel';
import { isMedia } from 'helpers/html';
import { getTestNameField, getUrlParams } from 'helpers/data';
import FormsState from 'globalState/forms';

import { ATTRIBUTES } from 'constants/attributesForTests';
import IconArrowDown from 'assets/img/icons/chevron-down.svg';
import Dropdown from 'components/dropdown';
import { getLinkDisplayValue, isChanged } from 'helpers/form';
import { fetchCondition } from 'actions/components/reference';
import IconEyeOff from 'assets/img/icons/eye-off.svg';
import UsersIcon from 'assets/img/icons/user-many.svg';

import subFormState from 'globalState/subForm';
import { withRouter } from 'react-router-dom';
import reportState from 'globalState/report';
import { LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND, LINK_UNAVAILABLE } from 'constants/strings';
import langStore from 'globalState/lang';
import {ROUTES} from "constants/routers";

/**
 * Описание: компонент Reference
 * Параметры:
 * onChange: {required, type: function} - метод для изменения значения
 * special: {required, type: object} - параметры
 * className: {type: string} - class css
 * value: {type: string | object}
 * term: {required, type: string} - значение поисковой строки
 * sys_column_name: {type: string} - параметр для поиска condition
 * sys_table_name: {type: string} - параметр для поиска condition
 * forReference: {type: string} - параметр для поиска condition
 * usedByList: {type: boolean} - флаг используется ли компонент полем типа лист
 * refField - ссылка на поле, относительно которого будет позиционироваться саджест. используется для поля лист
 * isFindDictionary: {type: boolean} - флаг используется для определения нажал ли пользователь клавиши "Ctrl + F" в окне dictionary
 * cellEditMode: {type: boolean} - компонент используется в редакторе ячеек листа
 * onEditFlagChange: {type: boolean} - вызывается при смене флага режима редактирования
 * setFocus: {type: boolean} - включение режима редактирования
 * isWindow - открыто в словаре
 */
@withRouter
@observer
class Reference extends React.Component {
    /**
     *
     * @type Window|null
     */
    dictionaryWindow = null;
    @observable model;
    @observable tableId = '';
    @observable isEdit = false;
    @observable isOptionsListOpened = false;
    @observable isFocused = false;

    /**
     * @type StringInputModel
     */
    stringInputModel;

    refField;
    refDropdown = React.createRef();
    refDropdownOpt = React.createRef();

    refOverlay = React.createRef();
    stringInputRef;
    isFindDictionary = false;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        }
        else {
            this.model = new ReferenceModel(props);
        }

        this.stringInputModel = new StringInputModel({
            isMandatory: this.model.isMandatory,
            readonly: this.model.readonly,
            className: this.getStringInputClass(),
            value: '',
            cellEditMode: this.model.cellEditMode,
            isEdit: this.isEdit || this.model.cellEditMode,
            save: this.handleEnterKeyDown,
            onFocus: () => { this.isFocused = true },
            onBlur: () => { this.isFocused = false },
        });

        this.stringInputRef = this.props.innerRef || React.createRef();
        this.refField = this.props.refField || React.createRef();

        if (!this.model.special.table_id && this.model.special.table_name) {
            this.fetchTableId(this.model.special.table_name);
        }

        reaction(
            () => this.isEdit,
            (isEdit) => {
                if (this.props.onEditFlagChange) {
                    this.props.onEditFlagChange(isEdit);
                }
            },
        );
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        if (this.props.setFocus || (this.model.cellEditMode && (!this.model.value || !this.model.value.database_value))) {
            this.isEdit = true;
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(
            _.filter(this.props, (el) => typeof el !== 'function'),
            _.filter(prevProps, (el) => typeof el !== 'function'),
        )) {
            if (this.props.model) {
                this.model = this.props.model;
            }
            else {
                this.model.mergeData(this.props);
            }
        }

        const { term } = this.model;

        let StringInputValue = term || '';

        this.stringInputModel.mergeData({
            isMandatory: this.model.isMandatory,
            readonly: this.model.readonly,
            className: this.getStringInputClass(),
            value: StringInputValue,
            cellEditMode: this.model.cellEditMode,
            isEdit: this.isEdit || this.model.cellEditMode,
            save: this.handleEnterKeyDown,
        });

        if (this.props.setFocus) {
            this.isEdit = true;
        }

        if (!this.stringInputRef.current) {
            this.isFocused = false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    handleEnterKeyDown = () => {
        if (!this.model.term && this.model.cellEditMode && !this.model.readOnly) {
            this.model.save();
        }
    };

    fetchTableId = async (tableName) => {
        const response = await new apiRequest(`GET /table/id-by-name/${ tableName }`).send();
        const data = response.getData();
        this.tableId = data.table_id;
    };

    onClick = () => {
        if (this.model.readonly) return;
        this.toggleOptions(false);
        this.openDictionary().catch(console.error);
    };

    handleAddClick = () => {
        const {
            special,
            sysColumnName,
            isServicePortal,
            label,
            sysColumnId,
            listLabel,
            columnId,
        } = this.model;
        subFormState.setTitle(label || listLabel);
        subFormState.setTableName(special.table_name);
        subFormState.setColumnName(sysColumnName);
        subFormState.setIsServicePortal(isServicePortal);
        subFormState.setIsShow(!subFormState.getIsShow());
        subFormState.setColumnId(sysColumnId || columnId || null);
        subFormState.setEditField(this);
        this.onInputChange('');
    };

    openDictionary = async () => {
        const {
            special,
            sysColumnName,
            label,
            listLabel,
            sysColumnId,
            columnId,
        } = this.model;
        const {
            match,
            location,
        } = this.props;
        const width = window.screen.width * .7;
        const height = window.screen.height * .7;
        const top = 0;
        const left = 0;
        window.editReferenceField = this;
        window.addEventListener('message', this.onMessage);

        const url = new URL(`/dictionary/${ special.table_name }`, window.location.origin);
        if (special.reference_qualifier) {
            if (special.reference_qualifier.condition) {
                url.searchParams.set('condition', special.reference_qualifier.condition);
            }
            if (special.reference_qualifier.is_fixed) {
                url.searchParams.set('is_fixed', special.reference_qualifier.is_fixed);
            }
            if (special.reference_qualifier.is_need_request) {
                url.searchParams.set('condition', await this.fetchCondition());
            }
        }
        const params = getUrlParams(location.search);
        const isAnotherDictionary = match.path === ROUTES.DICTIONARY_ROUTE && !!params.column_name;
        if (!this.model.parentFormSectionModel?.parentFormModel?.isSubForm && special.can_create && !isAnotherDictionary && _.isEmpty(reportState.getTypes()) && !subFormState.getIsShow()) {
            url.searchParams.set('column_name', sysColumnName);
            url.searchParams.set('label', label || listLabel);
            url.searchParams.set('column_id', sysColumnId);
        }

        if (columnId) {
            url.searchParams.set('reference_column_id', columnId);
        }
        url.searchParams.set('type', 'dictionary');
        if (this.model.usedByList) {
            url.searchParams.set('used_by_list', 'true');
        }

        this.dictionaryWindow = window.open(
            url.pathname + url.search,
            isAnotherDictionary ? 'Dictionary2' : 'Dictionary',
            `width=${ width },height=${ height },top=${ top },left=${ left }`,
        );

        this.dictionaryWindow.addEventListener('keydown', this.onKeydownDictionary);

        if (this.dictionaryWindow) {
            const windowOpener = this.dictionaryWindow.opener;
            windowOpener.onblur = () => {};
            this.dictionaryWindow.onblur = () => {
                if (this.dictionaryWindow.isShowModal) {
                    return;
                }
                if (this.isFindDictionary) {
                    this.isFindDictionary = false;
                }
                else {
                    if (isAnotherDictionary) {
                        this.dictionaryWindow.close();
                        windowOpener.onblur = () => {
                            windowOpener.close();
                        };
                    }
                    else {
                        this.dictionaryWindow.close();
                    }
                }
            };
            setTimeout(() => this.dictionaryWindow.onbeforeunload = this.onCloseDictionaryWindow, 300);
        }

        return this.dictionaryWindow;
    };
    //
    // getValue = (value) => {
    //     if (value && value.database_value) {
    //         return value.database_value;
    //     }
    //     return value;
    // };

    getCurrent = () => {
        const {
            sysId,
            form,
            parentFormSectionModel,
        } = this.model;
        const _sysId = form ? form.sysId : sysId;
        const dynamicFormId = form?.id || parentFormSectionModel?.parentFormId;

        const formComponent = FormsState.getSection(dynamicFormId);
        if (formComponent) {
            const result = {};
            const { data } = FormsState.serialize(dynamicFormId, true, false);
            result.record = data;
            result.record.sys_id = _sysId;
            const remSection = FormsState.getRemSection(this.model.parentFormSectionModel.parentFormId);
            if (remSection && remSection.remId !== undefined) {
                const rem_data = remSection.serialize(true);
                result.rem_id = remSection.remId;
                result.rem_attributes = rem_data.data;
            }
            return result;
        }
        else {
            return {};
        }
    };

    fetchCondition = async () => {
        //FormModel
        let {
            forReference,
            cellSysId,
            sysId,
            tableName,
            columnId,
        } = this.model;
        sysId = cellSysId ? cellSysId : sysId;

        let current = this.getCurrent();
        if (forReference && forReference.current) {
            if (current.record) {
                _.merge(current.record, forReference.current);
            }
            else {
                current = {
                    ...current,
                    record: forReference.current,
                };
            }
        }

        const table = forReference && forReference.table_name ? forReference.table_name : tableName;
        const column = forReference && forReference.column_id ? forReference.column_id : columnId;
        const { data } = await fetchCondition(table, column, sysId, current);
        return data.condition;
    };

    onCloseDictionaryWindow = () => {
        window.removeEventListener('message', this.onMessage);
    };

    onMessage = (e) => {
        this.updateData(e.data);

        if (this.dictionaryWindow) {
            this.dictionaryWindow.close();
        }
    };

    onKeydownDictionary = (e) => {
        if (e.ctrlKey && e.code === 'KeyF') {
            this.isFindDictionary = true;
        }
    };

    updateData = (value) => {
        this.model.mergeData({
            value,
            term: '',
        });
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);

        this.props.onChange && this.props.onChange(this.model);
        this.disableEdit();
        if (!this.model.term && this.model.cellEditMode && !this.model.readOnly && !this.model.usedByList) { // Сохраняем при выборе из списка
            this.model.save();
        }
    };

    /**
     *
     * @param inputModel {StringInputModel}
     */
    onInputChange = (inputModel) => {
        this.model.term = inputModel.value;
    };

    onFieldClear = () => {
        this.model.mergeData({
            value: '',
            term: '',
        });
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        this.props.onChange && this.props.onChange(this.model);

        // в режиме инлайн-редактирования ставим фокус на поле
        if (this.model.cellEditMode) {
            document.removeEventListener('click', this.onDocumentClick);
            this.onOverlayClick();
            document.addEventListener('click', this.onDocumentClick);
        }
        this.toggleOptions(false);
    };

    onOverlayClick = () => {
        if (this.model.readonly) return;
        this.isEdit = true;
        this.toggleOptions(false);
    };

    disableEdit = () => {
        this.isEdit = false;
        this.model.term = '';
    };

    onDocumentClick = (e) => {
        const overlay = this.refOverlay.current;

        if (this.isEdit && overlay && !overlay.contains(e.target)) {
            const input = this.stringInputRef.current;

            const selection = window.getSelection();
            const selText = selection.toString();
            const inputVal = input.value;
            if (inputVal.trim() !== '' && selText !== '' && inputVal.indexOf(selText) !== -1 && selection.anchorNode === input.parentNode) {
                // случай когда текст в инпуте был выделен мышью
                return;
            }

            if (this.refDropdown.current) {
                const dropDown = this.refDropdown.current;
                if (!input.contains(e.target) && !dropDown.contains(e.target)) {
                    this.disableEdit();
                }
            }
            else {
                if (!input.contains(e.target)) {
                    this.disableEdit();
                }
            }
        }
        const dropdownEl = this.refDropdownOpt ? this.refDropdownOpt.current : null;
        const inputEl = this.refField.current;
        if (!dropdownEl || !inputEl) return false;

        if (!dropdownEl.contains(e.target) && !inputEl.contains(e.target)) {
            this.toggleOptions(false);
        }
    };

    onBadgeClick = () => {
        const isDisabled = this.model.value?.reference_state === LINK_UNAVAILABLE || this.model.value?.reference_state === LINK_NOT_FOUND;
        if (isMedia('sm') && !isDisabled) {
            document.removeEventListener('click', this.onDocumentClick);
            this.onOverlayClick();
            document.addEventListener('click', this.onDocumentClick);
        }
    };

    getSuggestDropDown() {
        const {
            term,
            special,
            columnId,
        } = this.model;
        const canRead = special.can_read === undefined || special.can_read === true;
        if (!term || !canRead) {
            return null;
        }
        const referenceQualifierCondition = this.model.getReferenceQualifierCondition();

        return (
            <SuggestDropDown
                refField={ this.refField }
                ref={ this.refDropdown }
                reference_id={ this.tableId || special.table_id }
                reference_column_id={columnId}
                term={ term }
                reference_qualifier_condition={ referenceQualifierCondition }
                updateData={ this.updateData }
                fetchCondition={ this.model.isNeedRequest() && this.fetchCondition }
                usedByList={ this.model.usedByList }
                extraAttributes={this.model.extraAttributes}
                data-test={ ATTRIBUTES.fieldReferenceSuggest }
            />
        );
    }

    getStringInput() {
        return (
            <StringInput
                model={ this.stringInputModel }
                ref={ this.stringInputRef }
                onChange={ this.onInputChange }
                onKeyDown={ this.onInputKeyDown }
                autofill="off"
                autoComplete="off"
            />
        );
    }

    onInputKeyDown = (e) => {
        if(e.key === 'Tab') {
            this.disableEdit();
            const { onTabBlur } = this.props;
            if (onTabBlur) {
                onTabBlur(e);
            }
        }
    };

    getDependencyMapLink() {
        const { dependency_map_link: dependencyMapLink } = this.model.special;
        if (!dependencyMapLink) {
            return null;
        }
        return (
            <Button
                buttonType='icon-border'
                className={ `${ styles.button }` }
                svg={ Tree }
                onClick={ () => window.open(dependencyMapLink, '_blank') }
                data-test={ ATTRIBUTES.fieldReferenceDependencyMapLinkButton }
            />
        );
    }

    getStringInputClass() {
        if (this.model.usedByList) {
            return `${ styles.ListInput }`;
        }
        else {
            return !this.isEdit && !this.model.isEmptyValue() ? styles.Hidden : '';
        }
    }

    onDropDownArrowClick = () => {
        this.toggleOptions();
    };

    toggleOptions(state) {
        if (state !== undefined) {
            this.isOptionsListOpened = state;
        }
        else {
            this.isOptionsListOpened = !this.isOptionsListOpened;
        }
        this.model.currentOption = '';
    }

    onOptionClick = (option) => { // выбор элемента из выпадающего меню
        this.model.value = {
            display_value: option.display_value,
            database_value: option.database_value,
            is_option: true,
            reference_state: option.reference_state || null,
        };
        this.toggleOptions(false);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    onOptionHover = (database_value) => {
        this.model.currentOption = database_value;
    };

    renderPlusButton = (className) => {
        const {
            isWindow,
            isServicePortal,
            special,
        } = this.model;
        const {
            location,
        } = this.props;
        const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');
        const isWorkflow = location?.pathname === '/workflow';
        if (isWindow || this.model.parentFormSectionModel?.parentFormModel?.isSubForm || !special.can_create
            || !_.isEmpty(reportState.getTypes()) || subFormState.getIsShow() || isWorkflow) {
            return null;
        }
        return (
            <Button
                buttonType='icon-border'
                isServicePortal={ isServicePortal }
                className={ className }
                svg={ Plus }
                onClick={ this.handleAddClick }
                data-test={ ATTRIBUTES.fieldReferencePlusButton }
                hint={ system_buttons_hints?.create_a_new_record }
            />
        );
    };

    renderMenu() {
        const items = this.model.valueOpts.map((option, index) => {
            const databaseValueToString = option.database_value ? option.database_value.toString() : option.database_value;
            const className = `${ styles.menuItem } ${ option.database_value === this.model.currentOption ? styles.active : '' } ${ this.model.value && databaseValueToString === this.model.value.database_value ? styles.selected : '' }`;
            return (
                <div
                    className={ className }
                    key={ option.database_value + index.toString() }
                    onClick={ () => this.onOptionClick(option) }
                    onMouseEnter={ () => this.onOptionHover(option.database_value) }
                    data-test={ ATTRIBUTES.customSelectDropdownItem }
                >
                    { option.display_value }
                </div>
            );
        });

        return <div className={ styles.menu }>{ items }</div>;
    }

    render() {
        const {
            className,
            value,
            usedByList,
            columnId,
            special,
            isWindow,
        } = this.model;
        const {
            location,
        } = this.props;
        const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');
        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldReference }` : `${ ATTRIBUTES.fieldReference }`;
        const optsMode = _.isArray(this.model.valueOpts) && this.model.valueOpts.length > 0;
        let layout;
        const canRead = special.can_read === undefined || special.can_read === true;
        if (usedByList) {
            const isWorkflow = location?.pathname === '/workflow';
            const canCreateClass = !this.model.parentFormSectionModel?.parentFormModel?.isSubForm && special.can_create && _.isEmpty(reportState.getTypes())
            && !subFormState.getIsShow() && !isWorkflow ? styles.CanCreate : '';
            layout = (
                <>
                    <div
                        className={ styles.ListInputWrap }
                        data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                        data-test-visible={ this.model.isVisible }
                        data-test-mandatory={ this.model.isMandatory }
                        data-test-readonly={ this.model.readonly }
                        data-test-field-type={ this.model.column_type }
                        data-test-field-name={ this.model.sys_column_name }
                    >
                        { this.getStringInput() }
                        { this.getSuggestDropDown() }
                    </div>
                    { this.renderPlusButton(styles.ListPlus) }
                    { !isWindow && canRead && <Button
                        buttonType='icon-border'
                        className={ `${ styles.ListSearch } ${ canCreateClass }` }
                        svg={ Search }
                        onClick={ this.onClick }
                        data-test={ ATTRIBUTES.fieldReferenceSearchButton }
                        hint={ system_buttons_hints?.open_the_dictionary }
                    /> }
                </>
            );
        }
        else {
            const isDisabled = value?.reference_state === LINK_UNAVAILABLE || value?.reference_state === LINK_NOT_FOUND;
            const badgeEye = (value?.reference_state === LINK_UNAVAILABLE || value?.reference_state === LINK_TITLE_UNAVAILABLE) && <span data-test={ ATTRIBUTES.badgeEye } className={ styles.BadgeEye } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />;
            const badgeDeletion = value?.has_delegate && <span
                className={ styles.BadgeDelegation }
                dangerouslySetInnerHTML={ { __html: UsersIcon } }
                data-test={ ATTRIBUTES.badgeDelegationIcon }
            />;
            const displayValue = getLinkDisplayValue(value);
            let fieldClasses = [styles.ReferenceWrap];

            layout = (
                <div
                    className={ fieldClasses.join(' ') }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <div className={ ` ${ this.model.readonly ? styles.readOnly : '' } ${ styles.Reference } ${ className ? className : '' } ` } onKeyDown={ this.handleKeyDown }>
                        <div className={ styles.input } ref={ this.refField }>
                            <div className={ `${styles.FieldWrap} ${this.isFocused ? styles.focus : ''} ${ this.model.isWarning && this.model.cellEditMode ? styles.Warning : '' }` }>
                            { (this.isEdit || this.model.isEmptyValue()) && !this.model.readonly && this.getStringInput() }
                            {
                                (this.model.readonly && this.model.isEmptyValue()) ? <div className={ styles.FieldReadonly } /> :
                                    (
                                        <div className={ `${ styles.Field } ${ (this.isEdit || this.model.isEmptyValue()) ? styles.Hidden : '' }` }>
                                            <button type={'button'}
                                                className={ styles.FieldOverlay }
                                                onClick={ this.onOverlayClick }
                                                ref={ this.refOverlay }
                                                disabled={ this.model.readonly }
                                                onFocus={ () => { this.isFocused = true } }
                                                onBlur={ () => { this.isFocused = false } }
                                            >
                                                { isWindow && this.model.isEmptyValue() && <div className={ styles.FieldSearch } dangerouslySetInnerHTML={ { __html: Search } } /> }
                                            </button>
                                            { !this.model.isEmptyValue() && (
                                                <>
                                                    <div className={ styles.BadgeWrap }>
                                                        { this.model.value.is_option ?
                                                            <div className={ styles.SelectedOption }>
                                                                { displayValue }
                                                            </div>
                                                            :
                                                            isWindow ?
                                                                <button type={'button'} onClick={ this.onBadgeClick } className={ `${styles.Badge} ${styles.cursorDefault} ${isDisabled ? styles.disabled : ''}` }>
                                                                    { badgeEye }<span>{ displayValue }</span>
                                                                </button> :
                                                                <RecordPopupButton
                                                                    fieldType='record_field'
                                                                    essence={ special.table_name }
                                                                    sys_id={ value.database_value }
                                                                    refColumnId={columnId}
                                                                    className={ `${styles.Badge}  ${isDisabled ? styles.disabled : ''}` }
                                                                    onClick={ this.onBadgeClick }
                                                                    isDisabled={ isDisabled }
                                                                >
                                                                    {badgeDeletion}{ badgeEye }{ displayValue }
                                                                </RecordPopupButton>
                                                        }
                                                    </div>

                                                    { !this.model.readonly && (
                                                        < div
                                                            onClick={ this.onFieldClear }
                                                            data-test={ ATTRIBUTES.clearFieldButton }
                                                            className={ optsMode ? styles.FieldClearOpt : styles.FieldClear }
                                                            dangerouslySetInnerHTML={ { __html: IconClose } }
                                                        />
                                                    ) }
                                                </>
                                            ) }
                                            { !this.model.readonly && optsMode &&
                                                <div
                                                    onClick={ this.onDropDownArrowClick }
                                                    className={ styles.DropDownArrow }
                                                    dangerouslySetInnerHTML={ { __html: IconArrowDown } }
                                                    data-test={ ATTRIBUTES.customSelectButton }
                                                />
                                            }
                                        </div>
                                    )
                            }
                            </div>
                        </div>
                        { this.getSuggestDropDown() }
                        { this.isOptionsListOpened &&
                            <Dropdown refParent={ this.refField } ref={ this.refDropdownOpt } data-test={ ATTRIBUTES.customSelectDropdownMenu }>
                                { this.renderMenu() }
                            </Dropdown>
                        }
                        { !this.model.readonly && this.renderPlusButton(styles.button) }
                        { !this.model.readonly && !isWindow && canRead && <Button
                            buttonType='icon-border'
                            className={ `${ styles.button }` }
                            svg={ Search }
                            onClick={ this.onClick }
                            data-test={ ATTRIBUTES.fieldReferenceSearchButton }
                            hint={ system_buttons_hints?.open_the_dictionary }
                        /> }
                        { this.getDependencyMapLink() }
                    </div>
                </div>
            );
        }

        return (
            <FieldWrapper model={ this.model }>
                { layout }
            </FieldWrapper>
        );
    }
}


export default React.forwardRef((props, ref) => <Reference
    innerRef={ ref } { ...props }
/>);
