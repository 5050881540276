import {observable, action} from 'mobx';
import _ from 'lodash';
import InfoMessages from 'globalState/infoMessages';
import { fetchSelectionList } from 'actions/portalDesigner';
import {SectionListData} from "types/actions/portalDesigner";


class sysDBTables {
    @observable list: SectionListData[] = [];

    TABLE_NAME = 'sys_db_table';

    @action async fetchSysDbTable() {
        const requestOptions = {
            table_name: this.TABLE_NAME,
            columns: 'sys_id,name,title',
            per_page: 0,
        };

        const { data, isOkStatus } = await fetchSelectionList(requestOptions);

        if (isOkStatus) {
            (this.list as any).replace(data.list);

            /**
             * в случае если методы были вызваны до того как отработал запрос, кэш наполнится null значениями
             * после отработки запроса нужно его очистить
             */
            (this.getByName as any).cache.clear();
            (this.getById as any).cache.clear();
        }
    }

    getByName = _.memoize((name: string): SectionListData | undefined => {
        return this.list.find((table) => table.name === name );
    });

    getById = _.memoize((id: string): SectionListData | undefined => {
        return this.list.find((table) => table.sys_id === id );
    });

    getList(): SectionListData[] {
        return this.list;
    }

    fetchError(error) {
        InfoMessages.pushError(error.message);
    }

    isReady(): boolean {
        return this.list.length > 0;
    }
}

export default new sysDBTables();
