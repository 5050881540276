import React from 'react';
import { CommentTabsProps, CommentTextTab } from 'types/components/activityFeed';
import styles from './styles.module.scss';
import CommentBlock from 'components/activityFeed/commentBlock';
import { observable } from 'mobx';
import CommentTab from 'components/activityFeed/commentTabs/commentTab';
import { observer } from 'mobx-react';
import { ATTRIBUTES } from 'constants/attributesForTests';
import _ from 'lodash';
import TabsSlider from 'components/tabs/slider';
import EventBusState from 'globalState/eventBus';
import { eventType } from 'constants/eventBusTypes';
import { getClassNameAF } from 'helpers/activityFeed';

@observer
export default class CommentTabs extends React.Component<CommentTabsProps> {
    @observable isSliderOn = false;
    refActiveTab = React.createRef<HTMLDivElement>();

    onSliderUpdate = (isSliderOn) => {
        this.isSliderOn = isSliderOn;
    };

    handleChangeActiveTab = (activeTab: CommentTextTab) => () => {
        const { activityState } = this.props;
        const previousTab = _.find(activityState.getTypeTabs(), tab => tab.isActive);
        const previousName = previousTab?.name || '';
        if (activeTab.name === previousName) {
            return;
        }
        EventBusState.emit(eventType.AF_COMMENT_TAB_CHANGED, {
            previousTab: previousName,
            currentTab: activeTab.name,
            widgetInstanceId: activityState.getWidgetId(),
        });
        activityState.setTypeTabs(_.map(activityState.getTypeTabs(), tab => {
            if (tab.name === activeTab.name) {
                return { ...tab, isActive: true };
            }
            return { ...tab, isActive: false };
        }));
    };

    handleChangeComment = (id, comment) => {
        const { activityState } = this.props;
        activityState.setTypeTabs(_.map(activityState.getTypeTabs(), tab => {
            if (tab.id === id) {
                return { ...tab, comment };
            }
            return tab;
        }));
    };

    renderTabs = (filteredTypes: CommentTextTab[]) => {
        const { isNotStylized, classes } = this.props;
        const activeTab = _.find(filteredTypes, type => type.isActive) || filteredTypes[0] || '';
        const tabs = _.map(filteredTypes, (type, index) => {
            if (type.isReadOnly) {
                return null;
            }
            const isActiveTab = _.isEqual(activeTab, type);
            return (
                <CommentTab
                    ref={ type.isActive ? this.refActiveTab : null }
                    key={ type.id || index }
                    isActiveTab={ isActiveTab }
                    columnName={ type.column_name }
                    tab={ { sysId: type.id || '', name: type.title || type.name } }
                    onChangeActiveTab={ this.handleChangeActiveTab(type) }
                    isRequired={ type.isRequired }
                    isNotStylized={ isNotStylized }
                    classes={ classes }
                />
            );
        });
        return (
            <TabsSlider
                noMargins
                arrowsClassName={ `${ getClassNameAF(isNotStylized, styles.CommentsTabsSliderArrow, classes?.CommentsTabsSliderArrow) }` }
                activeTab={ this.refActiveTab }
                active={ activeTab.id || activeTab.name }
                onUpdate={ this.onSliderUpdate }
            >
                { tabs }
            </TabsSlider>
        );
    };

    render() {
        const { widgetId, activityState, tableName, recordId, isNotStylized, classes } = this.props;
        const filteredTypes = activityState.getTypeTabs();
        if (_.isEmpty(filteredTypes)) {
            return null;
        }
        const activeTab = _.find(filteredTypes, type => type.isActive) || filteredTypes[0] || '';
        return (
            <div>
                <div className={ getClassNameAF(isNotStylized, styles.CommentsTabsHeadWrap, classes?.CommentsTabsHeadWrap) }>
                    <div className={ getClassNameAF(isNotStylized, styles.CommentsTabsHeadContainer, classes?.CommentsTabsHeadContainer) }>
                        <div
                            className={ getClassNameAF(isNotStylized, styles.CommentsTabsHead, classes?.CommentsTabsHead) }
                            data-test={ ATTRIBUTES.activityFeedCommentTabs }
                        >
                            { this.renderTabs(filteredTypes) }
                        </div>
                    </div>
                </div>

                <CommentBlock
                    widgetId={ widgetId }
                    tableName={ tableName }
                    recordId={ recordId }
                    activityState={ activityState }
                    activeTab={ activeTab }
                    isSliderOn={ this.isSliderOn }
                    onChangeComment={ this.handleChangeComment }
                    isNotStylized={ isNotStylized }
                    classes={ classes }
                />
            </div>
        );
    }
}
