import { observable } from 'mobx';
import { CurrentCellDataType, CurrentCellType, FieldType } from 'types/components/table/cell/index';

class CurrentCell implements CurrentCellType {
    @observable private _attribute: string | FieldType = '';
    @observable private _value: string | null = '';
    @observable private _tableName: string | null = '';
    @observable private _fieldName: string | undefined;
    @observable private _tableId: string | null = '';
    @observable private _columnId: string | null = '';
    @observable private _originColumnId: string | undefined;
    @observable private _recordColumnName: string | null = '';
    @observable private _recordId: string | undefined;
    @observable private _columnType: string | undefined;

    getAttribute(): string | FieldType {
        return this._attribute;
    }

    setAttribute(value: string | FieldType) {
        this._attribute = value;
    }

    getValue(): string | null {
        return this._value;
    }

    setValue(value: string | null) {
        this._value = value;
    }

    getTableName(): string | null {
        return this._tableName;
    }

    setTableName(value: string | null) {
        this._tableName = value;
    }

    getFieldName(): string | undefined {
        return this._fieldName;
    }

    setFieldName(value: string) {
        this._fieldName = value;
    }

    getTableId(): string | null {
        return this._tableId;
    }

    setTableId(value: string | null) {
        this._tableId = value;
    }

    getColumnId(): string | null {
        return this._columnId;
    }

    setColumnId(value: string | null) {
        this._columnId = value;
    }

    getOriginColumnId(): string | undefined {
        return this._originColumnId;
    }

    setOriginColumnId(value: string | undefined) {
        this._originColumnId = value;
    }

    getRecordColumnName(): string | null {
        return this._recordColumnName;
    }

    setRecordColumnName(value: string | null) {
        this._recordColumnName = value;
    }

    getRecordId(): string | undefined {
        return this._recordId;
    }

    setRecordId(value: string | undefined) {
        this._recordId = value;
    }

    getColumnType(): string | undefined {
        return this._columnType;
    }

    setColumnType(value: string | undefined) {
        this._columnType = value;
    }

    setData(data: CurrentCellDataType) {
        this._attribute = data.attribute;
        this._value = data.value;
        this._tableName = data.tableName;
        this._fieldName = data.fieldName;
        this._tableId = data.tableId;
        this._columnId = data.columnId;
        this._columnType = data.columnType;
        this._originColumnId = data.originColumnId;
        this._recordColumnName = data.recordColumnName;
        this._recordId = data.recordId;
    }
}

export default new CurrentCell();
