/**
 * Описание: Список последних workflow
 * */
import * as React from 'react';

import styles from './styles.module.scss';
import { action, observable } from "mobx";
import { observer } from 'mobx-react';
import IconWorkflow from 'assets/img/icons/workflow.svg';
import IconCheckedOutWorkflow from 'assets/img/icons/external-link.svg';
import Search from './../search';
import { displayTimePassed } from './../helpers/datetime';
import langStore from 'globalState/lang';
import userState from 'globalState/user';
import WorkflowChoice from './../choice';

const blockWidth=251;
const blockHorizontalMargin=12;
const pageRatio=0.56;
const scrollbarWidth=13;
const upperBlocksHeight=429;

@observer
export default class WorkflowRecent extends React.Component {

    @observable term='';
    @observable filterMode=0; // 0 - All, 1 - Published, 2 - Checked out, 3 - Checked out by me
    filterOptions = [{ id: 0, display_value: 'All' },
        { id: 1, display_value: 'Published' },
        { id: 2, display_value: 'Checked out' },
        { id: 3, display_value: 'Checked out by me' }]
    @observable windowWidth = document.documentElement.clientWidth;
    @observable windowHeight = document.documentElement.clientHeight;
    user; // текущий пользователь

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener('resize', this.setSize);
        this.user = userState.user;
    }

    componentWillMount() {
        window.removeEventListener('resize', this.setSize);
    }

    @action
    setSize = () => {
        this.windowWidth = document.documentElement.clientWidth;
        this.windowHeight = document.documentElement.clientHeight;
    };

    checkRecord = (elem) => {
        return (elem && (!this.term || elem.name.toLowerCase().includes(this.term.toLowerCase())) // подходящие по фильтру по имени
            && (this.filterMode !== 1 || this.filterMode === 1 && !elem.checked_out_by) //published
            && (this.filterMode !== 2 || this.filterMode === 2 && !!elem.checked_out_by) // checked out
            && (this.filterMode !== 3 || this.filterMode === 3 && !!(elem.checked_out_by === this.user.sys_id))
    );};

    setFilter = (term) => {
        this.term = term;
    };

    setFilterMode = (value) => {
        this.filterMode = value;
    };

    openWorkflow = (id) => {
        this.props.openWorkflow(id);
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render(){
        const { workflow_titles } = langStore.getTranslate();
        const workflows = this.props.workflows
            .filter((workflow) => (this.checkRecord(workflow)))
            .sort((elem1, elem2) => (elem2.edited_at - elem1.edited_at))
            .map((workflow) => (
                <div className={ styles.Workflow } key={ workflow.sys_id } onClick={ () => { this.openWorkflow(workflow.sys_id) } }>
                    <div className={ styles.Name }>{ workflow.name }</div>
                    <div className={ workflow.checked_out_by ? styles.IconCheckedOut : styles.IconPublished }
                         dangerouslySetInnerHTML={ workflow.checked_out_by ? { __html: IconCheckedOutWorkflow } : { __html: IconWorkflow } }
                    />
                    <div className={ styles.User }>{ workflow.edited_by_display_value }</div>
                    <div className={ styles.Date }>{ displayTimePassed(workflow.edited_at) }</div>
                    <div className={ styles.Table }>{ `${ workflow_titles.table_field }: ${ workflow.table }` }</div>
                </div>
            ));

        // Количество блоков умещающихся при текущем разрешении, но не меньше 2 (увеличиваем до 2 при необходимости)
        const widthInBlocks = Math.max(Math.floor(this.windowWidth*pageRatio/(blockWidth+blockHorizontalMargin)),2);
        // Если рабочих процессов меньше, чем можно было бы разместить по ширине, то сокращаем ширину до количества процессов, но не меньше 2
        const correctedWidthInBlocks = (this.props.workflows && this.props.workflows.length < widthInBlocks) ? Math.max(this.props.workflows.length, 2) : widthInBlocks;
        //Расчёт ширины списка так, чтобы он точно соответсвовал размерам содержимого без пустых областей (будет заменено на media запросы)
        const width = correctedWidthInBlocks *(blockWidth+blockHorizontalMargin)+scrollbarWidth+'px'; // точная ширина листа для умещающихся по ширине в нём блоков
        const height = this.windowHeight-upperBlocksHeight; // оставшаяся свободная область по высоте
        return (
            <div className={ styles.List } style={ { width: width, height: height } }>
                <div className={ styles.SearchPanel }>
                    <Search setData={ this.setFilter } />
                    <WorkflowChoice options={ this.filterOptions } setValue={ this.setFilterMode } value={ this.filterMode }/>
                </div>
                { workflows.length > 0 ?
                    <div className={ styles.Workflows }>
                        { workflows }
                    </div>
                    : ''
                }
            </div>
        );
    }
}
