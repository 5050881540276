import { fetchRemAttr } from 'actions/record';
import { observable } from 'mobx';
import { runOnTypeScripts, TYPE_ON_LOAD } from 'helpers/scriptClientHelper';

export default class REMWidgetState {
    @observable remData;
    tableName;
    recordId;
    @observable modelId;
    isUserScripts = true;
    timeout = null;
    @observable remAttached; // Было ли сохранение записи на бэк
    parentFormSectionModel;

    constructor(data) {
        this.updateData(data).catch(console.error);
    }

    async updateData({
                         tableName,
                         recordId,
                         modelId,
                         isUserScripts = true,
                         readOnly,
                         parentFormSectionModel,
                     }) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            this.tableName = tableName;
            this.recordId = recordId || null;
            this.modelId = modelId || null;
            this.isUserScripts = isUserScripts;
            this.parentFormSectionModel = parentFormSectionModel;
            if (this.tableName && (this.recordId || this.modelId)) {
                const data = await this.fetch(this.tableName, this.recordId, this.modelId);
                if (data && !_.isEmpty(data)){ // Backend возвращает [] в случае отсутствия данных
                    if (readOnly) {
                        data.sections[0].elements.forEach(elem => { elem.readonly = true });
                    }
                    this.remData = data;
                    this.modelId = data.rem_id; // Получаем модель для существующей записи
                    this.remAttached = data.rem_attached; // Было ли сохранение записи на бэк
                }
            }
            if (this.isUserScripts) {
                this.runOnLoadScript();
            }
        }, 100);
    }

    runOnLoadScript() {
        if (!this.remData) {
            return null;
        }
        const clientScripts = this.remData ? this.remData.client_scripts : [];
        const parentFormModelId = this.parentFormSectionModel.parentFormModel?.id || this.parentFormSectionModel.parentFormId || this.parentFormSectionModel.id;
        if (clientScripts.length) {
            runOnTypeScripts(clientScripts, TYPE_ON_LOAD, null, null, parentFormModelId, this.parentFormSectionModel.parentFormModel?.isSubForm);
        }
    }

    async fetch(tableName, recordId, modelId) {
        const record = await fetchRemAttr(tableName, recordId, modelId);
        return record.data;
    }
}
