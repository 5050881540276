import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import styles from './styles.module.scss';
import IconEye from 'assets/img/icons/eye.svg';
import IconEyeOff from 'assets/img/icons/eye-off.svg';

@observer
export default class InputComponent extends React.Component {
    @observable value = '';
    @observable isFocused = false;
    @observable isMouseDown = false;
    @observable showPassword = false;


    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.value = this.props.value || '';
    }

    onChange = (e) => {
        this.value = e.target.value;
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    onFocus = () => {
        this.isFocused = true;
    };

    onBlur = () => {
        this.isFocused = false;
    };

    togglePasswordVisibility = () => {
        this.showPassword = !this.showPassword;
    };

    render() {
        const { msg, innerRef, placeholder, isDisabled, type, ...props } = this.props;

        let inputType = type;
        let inputClass = styles.input;
        let passBtn = null;

        if (type === 'password') {
            let btnIcon = IconEye;
            inputClass = `${ styles.input } ${ styles.typePassword }`;

            if(this.showPassword){
                inputType = 'text';
                btnIcon = IconEyeOff;
            }

            if(this.value !== ''){
                passBtn = (
                    <div className={ styles.passBtn }
                         onMouseLeave={ () => {
                             this.isMouseDown = false;
                         } }
                         onMouseUp={ () => {
                             this.isMouseDown = false;
                         } }
                         onMouseDown={ () => {
                             this.isMouseDown = true;
                         } }
                         onClick={ this.togglePasswordVisibility }
                         dangerouslySetInnerHTML={ { __html: btnIcon} }
                    />
                );
            }
        }

        let baseClassList = [styles.base];
        let message = <div className={ styles.errMessage }>{ !!msg && msg }</div>;

        if (this.isFocused || this.isMouseDown) {
            baseClassList.push(styles.focus);
        }
        if (msg) {
            baseClassList.push(styles.error);
        }
        if (this.value !== '') {
            baseClassList.push(styles.notEmpty);
        }
        if (isDisabled) {
            baseClassList.push(styles.disabled);
        }

        baseClassList = baseClassList.join(' ');

        return (
            <>
                <label className={ baseClassList } data-test={ this.props['data-test'] ? this.props['data-test'] : '' }>
                    <div className={ styles.placeholder }>{ placeholder }</div>
                    <div className={ styles.inputWrap }>
                        <input
                            { ...props }
                            type={ inputType }
                            ref={ innerRef }
                            className={ inputClass }
                            value={ this.value }
                            onFocus={ this.onFocus }
                            onBlur={ this.onBlur }
                            onChange={ this.onChange }
                            disabled={ isDisabled }
                        />
                    </div>
                    { passBtn }
                </label>
                { message }
            </>
        );
    }
}

