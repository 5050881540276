import { observer } from 'mobx-react';
import * as React from 'react';
import { observable } from 'mobx';

import langStore from 'globalState/lang';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import styles from './styles.module.scss';
import { fetchData, fetchDataLdap } from 'actions/ldap';
import Container from 'components/container';
import SmartTitle from 'components/smartTitle';
import Heading from 'components/heading';
import IconDropDown from 'assets/img/select-arrow.svg';
import _ from 'lodash';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Button from 'components/button';
import Search from 'assets/img/icons/search.svg';

@observer
export default class LdapBrowse extends React.Component {
    @observable data = {};
    @observable dataAttr = {};
    @observable errorMessage = '';
    @observable filter = '';
    @observable rdn = '';
    @observable root_dn = '';
    sys_id = null;
    timeout = null;
    prevItem = null;

    constructor(props) {
        super(props);
        this.sys_id = this.props.sys_id || this.props.match.params.sys_id || null;
    }

    componentDidMount() {
        this.getDataBrowse(this.sys_id).catch(this.errorFetchData);
    }

    errorFetchData = e => {
        this.errorMessage = e.message;
    };

    getDataBrowse = async (sys_id, filter = '') => {
        const response = await fetchData(sys_id, filter);
        const responseData = response.isOkStatus && response.data ? response.data : {};
        this.root_dn = Object.keys(responseData)[0];
        // Проверям на пустые элементы
        this.data = _.mapValues(responseData[this.root_dn], items => {
            return items.filter(item => item.uid || item.cn || item.ou).map(item => {
                if (item.cn) {
                    item.isNodeEmpty = true;
                }
                return item;
            });
        });
    };

    getDataLevel = async (sys_id, dn, filter = '') => {
        const response = await fetchDataLdap(sys_id, dn, filter);
        return response.isOkStatus ? response.data : {};
    };

    resetSelected(items) {
        return _.forEach(items, item => {
            item.isNodeOpened = false;
            item.isNodeSelected = false;
            if (!_.isEmpty(item.subItems)) {
                this.resetSelected(item.subItems);
            }
            return item;
        });
    }

    dataLdap = (sys_id, item) => async () => {
        // Если элемент последний в дереве
        if (item.cn) {
            this.dataAttr = _.cloneDeep(item);
            delete this.dataAttr.isNodeEmpty;
            delete this.dataAttr.isNodeSelected;

            if (!_.isEqual(item, this.prevItem)) {
                if (this.prevItem !== null) {
                    this.prevItem.isNodeActive = false;
                }
                this.prevItem = item;
            }
            item.isNodeActive = true;
        } else {
            if (!item.subItems) {
                const response = await this.getDataLevel(sys_id, item.dn);

                item.subItems = Object.values(response['dn'])[0]
                    .filter(subItem => subItem.uid || subItem.cn || subItem.ou)
                    .map((subItem) => {
                    if (subItem.cn) {
                        subItem.isNodeEmpty = true;
                    }
                    return subItem;
                });
                item.isNodeSelected = true;
            } else {
                if (item.isNodeSelected) {
                    item.isNodeSelected = false;
                    this.resetSelected(item.subItems);
                } else {
                    item.isNodeSelected = true;
                }

            }
            item.isNodeOpened = !item.isNodeOpened;
        }
    };

    onKeyDown = (evt) => {
        if (evt.key === 'Enter') {
            this.filterData();
        }
    };

    filterData = () => {
        this.getDataBrowse(this.sys_id, this.filter).catch(console.error);
    };

    filterRdn = ({ value }) => {
        this.rdn = value;
        const dn = this.root_dn ? this.root_dn.trim() : '';
        if (this.rdn.length > 3) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getDataLevel(this.sys_id, dn, this.rdn).catch(err =>
                    console.error(err),
                );
            }, 300);
        } else if (this.rdn.length === 0) {
            this.dataLdap(this.sys_id, dn);
        }
    };

    renderLdap = data => {
        return data.map(item => {
            const subItems = typeof item.subItems !== 'undefined' ? item.subItems : [];
            const btnIcon = !item.isNodeEmpty &&
                <div className={ `${ styles.Arrow } ${ item.isNodeOpened ? styles.activeArrow : '' }` }
                     dangerouslySetInnerHTML={ { __html: IconDropDown } }/>;

            const linkName = (item.uid && `uid=${ item.uid }`) ||
                (item.cn && `cn=${ item.cn }`) ||
                (item.ou && `ou=${ item.ou }`);

            return (
                <div
                    key={ `${ item.ou }_${ item.dn }` }
                    className={ styles.Item }
                >
                    <div className={ `${ styles.LinkWrap } ${ item.isNodeSelected ? styles.Selected : '' }` }>
                        <div
                            className={ `${ styles.Link } ${ item.isNodeActive ? styles.active : '' }` }
                            data-test={ `${ linkName }_${ ATTRIBUTES.ldapLink }` }
                            onClick={ this.dataLdap(this.sys_id, item) }
                        >
                            <div className={ styles.LinkInner }>
                                { btnIcon }
                                { linkName }
                            </div>
                        </div>
                    </div>
                    { (subItems.length > 0 && item.isNodeOpened) &&
                    <div className={ styles.Submenu }>
                        { this.renderLdap(subItems) }
                    </div>
                    }
                </div>
            );
        });
    };

    renderAttribute = dataAttr => {
        return Object.keys(dataAttr).filter(key => !['isNodeActive', 'isNodeOpened', 'isNodeSelected', 'isNodeEmpty'].includes(key)).map(key => {
            return (
                <div key={ key } data-test={ `${ key }_${ ATTRIBUTES.ldapRow }` } className={ styles.AttrRow }>
                    <div className={ styles.AttrName } data-test={ `${ key }_${ ATTRIBUTES.ldapName }` }>{ key }</div>
                    <div className={ styles.AttrValue } data-test={ `${ key }_${ ATTRIBUTES.ldapValue }` }>{ dataAttr[key] }</div>
                </div>
            );
        });
    };

    renderInput = (label, value, readOnly = false, onChange, onKeyDown,) => {
        return (
            <FieldWrapper label={ label } column_type={ 'string' }>
                <div>
                    <StringInput
                        value={ value }
                        onChange={ onChange }
                        onKeyDown={ onKeyDown }
                        readOnly={ readOnly }
                        autofill="off"
                        sys_column_name={ label }
                        autoComplete="off"
                    />
                </div>
            </FieldWrapper>
        );
    };

    render() {
        if (_.isEmpty(this.data)) return null;
        const dictionary = langStore.getTranslate();
        return (
            <Container>
                <SmartTitle hidden>{ dictionary.ldap_browse }</SmartTitle>

                <Heading size='h1'>{ dictionary.ldap_browse }</Heading>

                <div className={ styles.LdapContainer }>
                    <div className={ styles.LeftBlock }>
                        <div className={ styles.LdapInput }>
                            { this.renderInput(
                                dictionary.rdn,
                                this.rdn,
                                true,
                                this.filterRdn,
                            ) }
                            <FieldWrapper label={ dictionary.filter } column_type={ 'string' }>
                                <div className={ styles.FilterStyles }>
                                    <StringInput
                                        value={ this.filter }
                                        onKeyDown={ this.onKeyDown }
                                        onChange={ ({ value }) => this.filter = value }
                                        readOnly={ false }
                                        autofill="off"
                                        sys_column_name={ dictionary.filter }
                                        autoComplete="off"
                                    />
                                    <Button buttonType='icon-border' className={ styles.FieldSearch } svg={ Search }
                                            onClick={ this.filterData }/>
                                </div>
                            </FieldWrapper>
                            <div className={ styles.Ldap }>{ this.renderLdap(Object.values(this.data)[0]) }</div>
                        </div>
                    </div>

                    <div className={ styles.RightBlock }>
                        { this.renderInput(
                            dictionary.ldap_details,
                            this.root_dn,
                            true,
                            '',
                        ) }
                        { this.renderInput(
                            dictionary.dn,
                            this.root_dn,
                            true,
                            this.filterRdn,
                        ) }

                        <div className={ styles.Ldap }>
                            { this.dataAttr && (
                                <div className={ styles.TableAttribute }>
                                    { this.renderAttribute(this.dataAttr) }
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}
