import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Container from 'components/container';
import CMDB from 'components/CMDB';
import apiRequest from 'lib/apiRequest';
import FormMessages from 'components/formMessages';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';

@observer
class CMDBPage extends React.Component {
    @observable data;
    @observable uiActions;
    @observable errMessage = '';
    lastRouteParams = '';

    constructor(props) {
        super(props);
        this.fetchData().catch(this.errorFetchData);
    }

    fetchData = async () => {
        const routeParams = window.location.pathname + window.location.search;
        if (routeParams.localeCompare(this.lastRouteParams) === 0) return false;
        this.lastRouteParams = window.location.pathname + window.location.search;

        const response = await new apiRequest('GET ' + routeParams).send();
        const data = response.getData();
        this.uiActions = data.uiActions;
        this.data = data.data;
    };

    errorFetchData = (e) => {
        this.errMessage = e.message;
    };

    render() {
        const cont = this.errMessage ? this.errMessage : <CMDB data={ this.data } contextMenu={ this.uiActions }/>;
        return (
            <Container>
                <FormMessages />
                { cont }
            </Container>
        );
    }
}

export default ErrorWrapperHoc(CMDBPage);
