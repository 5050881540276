import { action, observable } from 'mobx';

class AttachmentsWidgetState {
    @observable _attachments = {};

    @action
    setAttachment = (id, attachment = {}) => {
        if (id){
            const attachmentTemplate = {
                hasWidget: false,
                essence: null,
                recordId: null,
                dndAttachmentsProps: [],
                widgetComponent: null,
            };
            if (!this._attachments){
                this._attachments = {};
            }
            this._attachments[id] = { ...attachmentTemplate, ...this._attachments[id], ...attachment };
        }
    };

    getAttachment = (id) => {
        return this._attachments && this._attachments[id];
    };

    @action
    reset = (id) => {
        if (id){
            if (!this._attachments){
                this._attachments = {};
            }
            this._attachments[id] = {
                hasWidget: false,
                essence: null,
                recordId: null,
                dndAttachmentsProps: [],
                widgetComponent: null,
            };
        }
    }
}

const attachmentsWidgetState = new AttachmentsWidgetState();

export default attachmentsWidgetState;


