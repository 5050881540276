import * as React from 'react';
import { observer } from 'mobx-react';

import GlobalPortal from 'components/globalPortal';
import IconX from 'assets/img/icons/close-x.svg';

import styles from './styles.module.scss';
import { observable } from 'mobx';
import { ActivityFeedWindowProps } from 'types/components/activityFeed';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getClassNameAF } from 'helpers/activityFeed';

/**
 * Описание: компонент Modal
 * Параметры:
 * isShow: {required, type: boolean} - флаг показа
 * title: {type: string} - заголовок окна
 * doClose: {type: function} - метод для дополнительного действия при скрытии модального окна
 * modalStyles: {type: object} - стили для компонента
 * children - данные которые надо показать в модальном окне
 * className: {type: string} - класс
 */
@observer
export default class ActivityFeedWindow extends React.Component<ActivityFeedWindowProps> {
    @observable isShow = false;

    constructor(props) {
        super(props);

        this.isShow = props.isShow;
    }

    componentDidUpdate(prevProps) {
        const { isShow } = this.props;
        if (isShow !== prevProps.isShow) {
            this.isShow = isShow;
        }
    }

    doClose = () => {
        this.isShow = false;
        this.props.doClose && this.props.doClose(this.isShow);
    };

    render() {
        const { modalStyles, className, isNotStylized, classes } = this.props;

        return this.isShow ? (
            <GlobalPortal>
                <div
                    className={ `${ getClassNameAF(isNotStylized, styles.ModalWrapper, classes?.ModalWrapper) } ${ className ? className : '' }` }
                    data-test={ ATTRIBUTES.activityFeedWindow }>
                    <div
                        className={ getClassNameAF(isNotStylized, styles.ModalWindow, classes?.ModalWindow) }
                        style={ modalStyles ? modalStyles : undefined }
                    >
                        <div className={ getClassNameAF(isNotStylized, styles.ModalHeader, classes?.ModalHeader) }>
                            <span className={ getClassNameAF(isNotStylized, styles.ModalText, classes?.ModalText) }>{ this.props.title }</span>
                            <div
                                onClick={ this.doClose }
                                className={ getClassNameAF(isNotStylized, styles.ModalCloseIco, classes?.ModalCloseIco) }
                                dangerouslySetInnerHTML={ { __html: IconX } }
                                data-test={ ATTRIBUTES.activityFeedCloseWindow }
                            />
                        </div>

                        <div className={ getClassNameAF(isNotStylized, styles.ModalBody, classes?.ModalBody) }>
                            { this.props.children }
                        </div>
                    </div>
                </div>
            </GlobalPortal>
        ) : null;
    }
}
