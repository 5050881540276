import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { helperRedirect } from 'helpers/history';
import apiRequest from 'lib/apiRequest';

import Breadcrumb from 'components/filter/breadcrumb';
import Button from 'components/button';
import ConditionFilters from 'components/conditionFilters';

import styles from './styles.module.scss';
import { getUrlParams, paramsToString } from 'helpers/data';
import Choice from './choice';
import langStore from 'globalState/lang';
import MobileModal from 'components/modalWrapper';
import IconClose from 'assets/img/icons/close-x.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import _ from 'lodash';
import IconMore from 'assets/img/icons/more-horizontal-kebab.svg';
import { isMedia } from 'helpers/html';
import Dropdown from 'components/dropdown';

/**
 * Описание: компонент Filter
 * Параметры:
 * isOpen - открыт ли condition builder
 * tableId - идентификатор фильтруемой таблицы
 * onChangeCondition - обработчик изменения условия
 * location - строка браузера
 * conditionState - хранилище
 * tableName - имя фильтруемой таблицы
 * readOnly - поля доступны только для чтения
 * notEditCondition - не редактируемый condition (для связанных списков. для запрета удаления из breadcrumb)
 * notEditTable - не используется
 * options - список сохраненных фильтров
 * isRunDisabled - скрыта ли кнопка Run
 * isSaveDisabled - скрыто ли меню сохраненных фильтров
 * isSortingDisabled - убрана ли возможность сортировки
 * isGroupingDisabled - убрана ли возможность группировки
 * isMobile - мобильное отображение
 * isWindow - открыто в словаре
 */

@observer
export default class FilterComponent extends React.Component {
    @observable isOpen = false;
    @observable textSave = '';
    @observable data = [];
    @observable activeConditionFilter = null;
    refControls = React.createRef();
    @observable isSmallControls = false;
    refMoreButton = React.createRef();
    refDropdown = React.createRef();
    @observable isShowDropdown = false;

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen && !this.props.toggleIsOpen) {
            this.toggleOpen();
        }

        if (this.props.toggleIsOpen && prevProps.isOpen !== this.props.isOpen) {
            this.isOpen = this.props.isOpen;
        }

        if (prevProps.isRunCondition !== this.props.isRunCondition && this.props.isRunCondition) {
            this.props.handleRunCondition(false);
            this.handleRun();
        }
        this.onWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onWindowResize = () => {
        if (this.refControls.current) {
            this.isSmallControls = isMedia('md') && !isMedia('sm');
        }
    };

    handleClickOutside = (evt) => {
        if (!this.refDropdown || !this.refDropdown.current) {
            return false;
        }

        if (!this.refDropdown.current.contains(evt.target)) {
            this.isShowDropdown = false;
        }
    };

    handleToggleDropdown = () => {
        this.isShowDropdown = !this.isShowDropdown;
    };

    handleClickAll = () => {
        const { onChangeCondition } = this.props;
        if (onChangeCondition) {
            onChangeCondition();
            return;
        }
        const parsedSearchParams = getUrlParams(this.props.location.search);
        const params = {
            ...parsedSearchParams,
            condition: '',
        };
        helperRedirect({
            search: paramsToString(params),
        });
    };

    handleRun = () => {
        const { onChangeCondition, conditionState } = this.props;
        if (onChangeCondition) {
            onChangeCondition();
            return;
        }
        conditionState.showLoader = true;
        const parsedSearchParams = getUrlParams(this.props.location.search);
        const isFixed = parsedSearchParams.is_fixed ? parsedSearchParams.is_fixed : undefined;
        if (parsedSearchParams && parsedSearchParams.page) {
            delete parsedSearchParams.page;
        }
        const condition = this.props.conditionState.getConditionString();
        if (parsedSearchParams?.condition === condition || !condition && !parsedSearchParams?.condition){
            window.s_list.refresh(this.props.tableName); // DEF0012358
            conditionState.showLoader = false;
        } else {
            const params = {
                ...parsedSearchParams,
                condition: condition,
                is_fixed: isFixed,
            };
            helperRedirect({
                search: paramsToString(params),
            }, () => {
                conditionState.showLoader = false;
            });
        }
    };

    handleChangeSave = (value) => {
        this.textSave = value;
    };

    handleSave = async () => {
        const trimmedText = this.textSave.replace(/^\s+|\s+$/g, '');
        if (!trimmedText) {
            return;
        }

        const selectedFilter = this.props.options.filter(filter => filter.is_selected === true);
        const body = {
            essence: this.props.tableName,
            title: trimmedText,
            condition: this.props.conditionState.getConditionString(),
        };

        if (selectedFilter.length) {
            body.current_filter_name = selectedFilter[0].default_name;
        }

        const response = await new apiRequest('POST /filter/save').sendJSON(body);
        if (response && response.status === 'OK') {
            const data = response.getData();
            this.props.updateUiActions(data.saved_filters);
        }
    };

    handleAddFiltering = (type, block, index) => () => {
        this.props.conditionState.addFiltering(type, block, index);
    };

    handleAddSorting = () => {
        this.props.conditionState.addSorting();
        this.isShowDropdown = false;
    };

    handleAddGrouping = () => {
        this.props.conditionState.addGrouping();
        this.isShowDropdown = false;
    };

    handleClearAll = () => {
        this.props.conditionState.clearData();
        this.props.conditionState.addFiltering('AND');
    };

    handleRemoveBreadcrumb = (id) => {
        this.props.conditionState.removeFilteringRow(id);
        this.props.conditionState.removeSortingRow(id);
        this.props.conditionState.removeGroupingRow(id);
        this.props.conditionState.removeConditionBreadcrumbField(id);
        this.handleRun();
    };

    toggleOpen = () => {
        const { isOpen, toggleIsOpen } = this.props;
        if (toggleIsOpen) {
            toggleIsOpen();
            return;
        }
        this.isOpen = typeof isOpen === 'undefined' ? !this.isOpen : isOpen;
    };

    @action
    setActiveConditionFilter = (id) => {
        this.activeConditionFilter = id;
    };

    renderFiltering = () => {
        const { conditionState, readOnly, tableId } = this.props;
        const { filter_titles } = langStore.getTranslate();

        let prevBlock = conditionState.getMinBlockNumber();
        let filterFields = conditionState.getFilterFields();

        if (filterFields.length === 0) {
            conditionState.addFiltering('AND');
            filterFields = conditionState.getFilterFields();
        }

        const fields = filterFields.map((row, index) => {
            const orHead = row.getBlock() > prevBlock ? (
                <div className={ styles.FilterLabel }>
                    { filter_titles && `${ filter_titles.label_or } ${ filter_titles.conditions_must_be_met.toLowerCase() }` }
                </div>
            ) : null;

            prevBlock = row.getBlock();
            const isActive = this.activeConditionFilter === row.getId();
            return (
                <div className={ `${ orHead ? styles.FilterOrCondition : '' }` } key={ row.getId() }>
                    { orHead }
                    <ConditionFilters
                        tableId={ tableId }
                        row={ row }
                        className={ row.getType() === 'OR' ? styles.BorderBottom : '' }
                        onAddFiltering={ this.handleAddFiltering }
                        onRemoveFilteringRow={ conditionState.removeFilteringRow }
                        index={ index }
                        data={ conditionState.getDotWalkList() }
                        isActive={ isActive }
                        setActive={ this.setActiveConditionFilter }
                        readOnly={ readOnly || row.getDisabled() || conditionState.showLoader }
                        conditionCount={ filterFields.length }
                        isHideOrButton={ conditionState.getIsHideOr() }
                        maxLength={ this.props.maxLength }
                        isMobile={ this.props.isMobile }
                        isWindow={ this.props.isWindow }
                    />
                </div>
            );
        });

        return (
            <div
                className={ styles.FilterConditions }
                data-test={ ATTRIBUTES.conditionFilteringRows }
            >
                { fields }
            </div>
        );
    };

    renderSorting = () => {
        const { conditionState } = this.props;

        const { filter_titles } = langStore.getTranslate();

        if (!conditionState.isShowSorting()) {
            return null;
        }
        const fields = conditionState.getSortingFields().map((row) => {
            return (
                <ConditionFilters
                    row={ row }
                    key={ row.getId() }
                    tableId={ this.props.tableId }
                    onRemoveSortingRow={ conditionState.removeSortingRow }
                    data={ conditionState.getDotWalkList() && conditionState.getDotWalkList().filter(row => row.condition_type !== 'keywords') }
                    isActive={ this.activeConditionFilter === row.getId() }
                    setActive={ this.setActiveConditionFilter }
                    readOnly={ this.props.readOnly || conditionState.showLoader }
                    isMobile={ this.props.isMobile }
                />
            );
        });

        if (fields.length === 0) {
            return null;
        }

        return (
            <div
                className={ styles.FilterSorts }
                data-test={ ATTRIBUTES.conditionSortingRows }
            >
                <div className={ styles.FilterLabel }>
                    { filter_titles && filter_titles.order }
                </div>
                { fields }
            </div>
        );
    };

    renderGrouping = () => {
        const { conditionState } = this.props;

        const { filter_titles } = langStore.getTranslate();

        if (!conditionState.isShowGrouping()) {
            return null;
        }
        const fields = conditionState.getGroupingFields().map((row) => {
            return (
                <ConditionFilters
                    row={ row }
                    key={ row.getId() }
                    tableId={ this.props.tableId }
                    onRemoveGroupingRow={ conditionState.removeGroupingRow }
                    data={ conditionState.getDotWalkList() && conditionState.getDotWalkList().filter(row => row.condition_type !== 'keywords') }
                    isActive={ this.activeConditionFilter === row.getId() }
                    setActive={ this.setActiveConditionFilter }
                    readOnly={ this.props.readOnly || conditionState.showLoader }
                    isMobile={ this.props.isMobile }
                />
            );
        });

        if (fields.length === 0) {
            return null;
        }

        return (
            <div
                className={ styles.FilterSorts }
                data-test={ ATTRIBUTES.conditionGroupingRows }
            >
                <div className={ styles.FilterGroupRow }>
                    <div className={ this.props.isMobile ? styles.FilterLabel : styles.FilterGroupLabel }>
                        { filter_titles && filter_titles.group }
                    </div>
                    { !this.props.isMobile &&
                    <>
                        <div className={ styles.FilterGroupLabel }>
                            { filter_titles && filter_titles.group_sort }
                        </div>
                        <div className={ styles.FilterGroupSpace } />
                    </> }
                </div>
                { fields }
            </div>
        );
    };

    renderAdditionalButtons = () => {
        const { filter_titles } = langStore.getTranslate();
        const { isSortingDisabled, isGroupingDisabled, readOnly, conditionState } = this.props;
        if (this.isSmallControls) {
            return (
                <Button
                    buttonType="icon"
                    svg={ IconMore }
                    className={ styles.MoreControls }
                    onClick={ this.handleToggleDropdown }
                    ref={ this.refMoreButton }
                    disabled={ readOnly }
                />
            );
        }
        const sortButton = !isSortingDisabled && (
            <Button
                className={ styles.Button }
                onClick={ this.handleAddSorting }
                disabled={ readOnly }
                data-test={ ATTRIBUTES.conditionButtonSort }
            >
                { filter_titles && filter_titles.button_sort }
            </Button>
        );
        const groupButton = !isGroupingDisabled && (
            <Button
                className={ styles.Button }
                onClick={ this.handleAddGrouping }
                disabled={ readOnly || !_.isEmpty(conditionState.getGroupingFields()) }
                data-test={ ATTRIBUTES.conditionButtonGroup }
            >
                { filter_titles && filter_titles.button_group }
            </Button>
        );
        return (
            <>
                { sortButton }
                { groupButton }
            </>
        );
    };

    render() {
        const {
            conditionState, readOnly, isMobile, location, tableId, tableName, notEditCondition,
            notEditTable, isSortingDisabled, isGroupingDisabled, isSaveDisabled, isRunDisabled,
            options,
        } = this.props;
        const { filter_titles } = langStore.getTranslate();
        const breadcrumbTpl = (
            <Breadcrumb
                filterFields={ conditionState ? conditionState.getBreadcrumbFilterFields() : [] }
                sortingFields={ conditionState ? conditionState.getBreadcrumbSortingFields() : [] }
                groupingFields={ conditionState ? conditionState.getBreadcrumbGroupingFields() : [] }
                onClickHandler={ this.toggleOpen }
                onClickLink={ this.handle }
                onRemoveCondition={ this.handleRemoveBreadcrumb }
                conditionState={ conditionState }
                location={ location }
                tableId={ tableId }
                tableName={ tableName }
                data={ conditionState.getDotWalkList() }
                notEditCondition={ notEditCondition }
                notEditTable={ notEditTable }
                onClickAll={ this.handleClickAll }
                readOnly={ readOnly }
            />
        );

        const oRButton = !conditionState.getIsHideOr() ? (
            <Button
                className={ styles.Button }
                onClick={ this.handleAddFiltering('OR') }
                disabled={ readOnly }
                data-test={ ATTRIBUTES.conditionButtonOr }
            >
                { filter_titles && filter_titles.button_or }
            </Button>
        ) : null;
        let filterTpl = (
            <div className={ `${ styles.Filter } ${ isMobile ? styles.MobileFilter : '' }` }>
                <div className={ styles.FilterControls } key="controls">
                    <div
                        className={ styles.FilterControlsContainer }
                        ref={ this.refControls }
                    >
                        <div className={ styles.Column }>
                            <div className={ styles.FilterMainLabel }>
                                { filter_titles && filter_titles.filter_main_label }
                            </div>

                            <Button
                                className={ styles.Button }
                                onClick={ this.handleAddFiltering('AND') }
                                disabled={ readOnly }
                                data-test={ ATTRIBUTES.conditionButtonAnd }
                            >
                                { filter_titles && filter_titles.button_and }
                            </Button>
                            { oRButton }
                            { this.renderAdditionalButtons() }
                        </div>

                        <div className={ styles.Column }>
                            {
                                !isSaveDisabled &&
                                <Choice
                                    onChange={ this.handleChangeSave }
                                    value={ this.textSave }
                                    readOnly={ readOnly }
                                    onSave={ this.handleSave }
                                    options={ options || [] }
                                    isMobile={ isMobile }
                                />
                            }
                        </div>

                        <Button
                            className={ styles.ClearAll }
                            onClick={ this.handleClearAll }
                            disabled={ readOnly }
                            data-test={ ATTRIBUTES.conditionButtonClearAll }
                        >
                            { filter_titles && filter_titles.clear_all }
                        </Button>
                    </div>
                </div>
                { this.renderFiltering() }
                {
                    !isSortingDisabled &&
                    this.renderSorting()
                }
                {
                    !isGroupingDisabled &&
                    this.renderGrouping()
                }
                <div className={ styles.Buttons }>
                    {
                        !isRunDisabled &&
                        <Button
                            className={ styles.Button }
                            buttonType={ 'primary' }
                            onClick={ this.handleRun }
                            disabled={ readOnly }
                            data-test={ ATTRIBUTES.conditionButtonRun }
                            data-loader={ conditionState.showLoader }
                        >
                            { filter_titles && filter_titles.button_run }
                        </Button>
                    }
                    <Button
                        className={ styles.Button }
                        onClick={ this.toggleOpen }
                        data-test={ ATTRIBUTES.conditionButtonClose }
                    >
                        { filter_titles && filter_titles.button_close }
                    </Button>
                </div>
                {
                    this.isShowDropdown &&
                    <Dropdown refParent={ this.refMoreButton } ref={ this.refDropdown } minWidth={ 168 }>
                        <div className={ styles.DropdownItems }>
                            <div className={ styles.DropdownItem } onClick={ this.handleAddSorting }>
                                { filter_titles && filter_titles.button_sort }
                            </div>
                            { _.isEmpty(conditionState.getGroupingFields()) &&
                            <div className={ styles.DropdownItem } onClick={ this.handleAddGrouping }>
                                { filter_titles && filter_titles.button_group }
                            </div>
                            }
                        </div>
                    </Dropdown>
                }
            </div>
        );

        if (isMobile) {
            filterTpl = this.isOpen ? (
                <MobileModal>
                    <div className={ styles.Mobile }>
                        <div className={ styles.MobileTop }>
                            { breadcrumbTpl }
                            <Button
                                buttonType={ 'icon' }
                                svg={ IconClose }
                                onClick={ this.toggleOpen }
                            />
                        </div>
                        { filterTpl }
                    </div>
                </MobileModal>
            ) : null;
        }
        else {
            filterTpl = (
                <div className={`${styles.FilterWrap} ${this.isOpen ? styles.active : ''}`}>
                    <div className={ styles.FilterWrapContent } >
                        { filterTpl }
                    </div>
                </div>
            );
        }

        return (
            <div
                data-test={ ATTRIBUTES.conditionFilter }
            >
                { breadcrumbTpl }

                { filterTpl }
            </div>
        );
    }
}
