import BasePortalDesignerModel from './base';
import WidgetInstancesPortalDesignerCollection from 'components/portalDesigner/collection/widgetInstances';
import { observable } from 'mobx';

/**
 * модель ячейки
 */
export default class ColumnPortalDesignerModel extends BasePortalDesignerModel {
    TABLE_NAME = 'portal_column';

    sizeXS = null;
    sizeSM = null;
    sizeMD = null;
    sizeLG = null;
    @observable widgetInstances = new WidgetInstancesPortalDesignerCollection([], this);

    constructor(params) {
        super(params);
        this.setData(params);
    }

    setData(params) {
        super.setData(params);

        this.widgetInstances.updateItems(params.widget_instances);

        this.sizeXS = params.size_xs;
        this.sizeSM = params.size_sm;
        this.sizeMD = params.size_md;
        this.sizeLG = params.size_lg;
    }
}
