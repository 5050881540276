import * as React from 'react';
import { observer } from 'mobx-react';

import Col from 'components/portalDesigner/components/col';
import BaseElement from 'components/portalDesigner/components/base';

import styles from 'components/portalDesigner/styles.module.scss';

@observer
export default class Row extends React.Component {
    breadcrumbsIds = [];

    constructor(props) {
        super(props);

        this.breadcrumbsIds = this.props.breadcrumbsIds.concat([
            {
                id: props.id,
                model: props.model,
                // @todo translate
                name: 'Row',
                type: 'row',
            },
        ]);
    }

    handleDragEnter = (e) => {
        e.preventDefault();
    };

    handleDragOver = (e) => {
        e.preventDefault();
    };

    handleDragLeave = (e) => {
        e.preventDefault();
    };

    handleDrop = (e) => {
        e.preventDefault();
    };

    render() {
        let classes = [ styles.Row ];
        if (this.props.model.isTemp) {
            classes.push(styles.RowTemp);
        }

        return <BaseElement className={ classes.join(' ')  }
                            type="row"
                            key={ this.props.id }
                            id={ this.props.id }
                            index={ this.props.index }
                            model={ this.props.model }
                            onMouseUp={ this.handleDrop }
                            handleDragEnter={ this.handleDragEnter }
                            handleDragOver={ this.handleDragOver }
                            handleDragLeave={ this.handleDragLeave }
                            breadcrumbsIds={ this.breadcrumbsIds }>
            {
                this.props.model.isLoaded ?
                    <div className={ styles.ContainerIsLoaded } />
                    : this.props.columns.items.map((model, index) => (
                        <Col key={ model.id }
                             index={ index }
                             model={ model }
                             breadcrumbsIds={ this.breadcrumbsIds }
                             id={ model.id }
                             widgetInstances={ model.widgetInstances } />
                    ),
                )
            }
        </BaseElement>;
    }
}
