import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';
import { observable } from 'mobx/lib/mobx';
import _ from 'lodash';

@observer
export default class Description extends React.Component {
    refContent = React.createRef();
    refContainer = React.createRef();
    @observable hasOverflow = false;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.checkTextOverflow();
        window.addEventListener('resize', this.checkTextOverflow);
    }

    componentDidUpdate(prevProps) {
        const { html } = this.props;
        if (!_.isEqual(html, prevProps.html)) {
            this.checkTextOverflow();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkTextOverflow);
    }

    checkTextOverflow = () => {
        const containerRect = this.refContainer ? this.refContainer.current ? this.refContainer.current.getBoundingClientRect()  : null : null;
        const contentRect = this.refContainer ? this.refContent.current ? this.refContent.current.getBoundingClientRect() : null : null;

        if(!containerRect || !contentRect) return;

        this.hasOverflow = containerRect.height > contentRect.height;
    };

    render() {
        const { html } = this.props;

        return (
            <div ref={ this.refContent } className={ styles.Text }>
                <div ref={ this.refContainer } dangerouslySetInnerHTML={{__html: html}}/>
                { this.hasOverflow && <div className={ styles.Gradient }/> }
            </div>
        );
    }
}