import { GroupDataType, GroupType } from 'types/components/table/groupData/index';
import { observable } from 'mobx';
import _ from 'lodash';
import { DataGroupsType } from 'types/components/table/index';

class GroupDataState implements GroupDataType {
    @observable private _perPage = 20;
    @observable private _page = 1;
    @observable private _columnTitle = '';
    @observable private _groups: GroupType[] = [];
    @observable private _groupsCount = 0;
    @observable private _hidden_entries = false;

    constructor(obj?: DataGroupsType) {
        this.setData(obj);
    }

    setData(obj?: DataGroupsType) {
        if (obj && !_.isEmpty(obj)) {
            this._page = obj.page;
            this._columnTitle = obj.column_title;
            this._groups = obj.groups;
            this._perPage = obj.per_page;
            this._groupsCount = obj.groups_count;
            this._hidden_entries = obj.hidden_entries;
        }
    }

    getPerPage(): number {
        return this._perPage;
    }

    setPerPage(value: number) {
        this._perPage = value;
    }

    getColumnTitle(): string {
        return this._columnTitle;
    }

    setColumnTitle(value: string) {
        this._columnTitle = value;
    }


    getPage(): number {
        return this._page;
    }

    setPage(value: number) {
        this._page = value;
    }

    getGroups(): GroupType[] {
        return this._groups;
    }

    setGroups(value: GroupType[]) {
        this._groups = value;
    }

    getGroupsCount(): number {
        return this._groupsCount;
    }

    setGroupsCount(value: number) {
        this._groupsCount = value;
    }

    clear() {
        this._groupsCount = 0;
        this._perPage = 20;
        this._page = 1;
        this._columnTitle = '';
        this._groups = [];
    }

    haveHiddenEntries() {
        return this._hidden_entries;
    }
}

export const groupDataStateClass = GroupDataState;

export default new GroupDataState();
