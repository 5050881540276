import * as React from 'react';
import styles from 'components/dynamicForms/view/fieldWrapper/styles.module.scss';

import FieldMessage from 'components/dynamicForms/view/fieldMessage';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { executeScripts } from 'helpers/uiActionsHelper';
import mapper from 'components/dynamicForms/model/field/mapper';
import _ from 'lodash';
import Tooltip from "components/tooltip";
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import IconQuestion from 'assets/img/icons/help-circle.svg';
import Button from 'components/button';
import Hint from 'components/hint';
import Dropdown from 'components/dropdown';

@observer
export default class FieldWrapper extends React.Component {
    @observable isShowContextMenu = false;
    @observable model = null;
    @observable isShowTooltip = false;
    @observable isShowHint = false;
    hasOverflow = false;

    contextMenuRef = React.createRef();
    helpButtonRef = React.createRef();
    tooltipRef = React.createRef();
    wrapperRef = React.createRef();
    labelRef = React.createRef();
    containerRef = React.createRef();

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        } else if (mapper[props.column_type]) {
            this.model = new mapper[props.column_type](props);
        }
        // this.checkWarning(this.model.value);
    }

    componentDidUpdate(prevProps) {
        const { model } = this.props;
        if (model && !_.isEqual(model, prevProps.model)) {
            this.model = model;
        }
    }


    componentDidMount() {
        this.checkOverflow();
        window.addEventListener('resize', this.checkOverflow);
        document.addEventListener('mousedown', this.handleClickOutsideMenu);
        window.addEventListener('scroll', this.handleScroll);
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.column_type === 'id' && _.isObject(this.props.value)) {
    //         this.checkWarning(this.props.value.record_title);
    //     }
    //     else {
    //         this.checkWarning(this.props.value);
    //     }
    // }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideMenu);
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.checkOverflow);
    }

    @action
    handleContextMenu = (e) => {
        e.preventDefault();
        const { current: helpButton } = this.helpButtonRef;
        if (!helpButton?.contains(e.target)) {
            this.isShowContextMenu = true;
            this.isShowTooltip = false;
            this.isShowHint = false;
        }
        // this.checkWarning(this.props.value);
    };

    @action
    handleClickOutsideMenu = (e) => {
        const { current: el } = this.contextMenuRef;

        if (!el) return;

        if (!el.contains(e.target)) {
            this.isShowContextMenu = false;
        }
    };

    handleTooltipClose = () => {
        this.isShowTooltip = false;
    };

    handleLabelClick = (e) => {
        const { onLabelClick } = this.props;
        if(onLabelClick) {
            onLabelClick(e);
        }
    };

    /**
     * @todo убрать это
     * @returns {string}
     */
    getColumnId() {
        return this.model.columnId;
    }

    getCSSClasses() {
        const { className, columnType, notice, hidden } = this.model;

        const fieldClasses = [styles.Field];
        const nameClasses = [styles.Name];
        const inputClasses = [styles.Input];

        if(columnType === 'boolean' && !hidden){
            fieldClasses.push(styles.Checkbox);
        }

        if (className) {
            let { FieldWrapper, Label, Input } = (
                className.Boolean && columnType === 'boolean'
            ) ? className.Boolean : className;

            if (FieldWrapper) {
                fieldClasses.push(FieldWrapper);
            }
            if (Label) {
                nameClasses.push(Label);
            }
            if (Input) {
                inputClasses.push(Input);
            }
        }

        if (hidden) {
            nameClasses.push(styles.unavailable);
        }

        if (notice && notice.type) {
            if (notice.type === 'error') {
                inputClasses.push(styles.ErrorInput);
            } else if (notice.type === 'warning') {
                inputClasses.push(styles.WarningInput);
            }
        }

        return {
            fieldClasses,
            nameClasses,
            inputClasses,
        };
    }

    toggleTooltip = () => {
        this.isShowTooltip = !this.isShowTooltip;
    };

    handleLabelMouseEnter = () => {
        this.isShowHint = true;
    };

    handleLabelMouseLeave = () => {
        this.isShowHint = false;
    };

    renderContextMenu = () => {
        const { contextMenu } = this.model;

        /**
         * @todo убрать это
         */
        window.currentFieldLabel = this;
        return (
            <Dropdown disableMinWidth offsetTop={ 1 } offsetLeft={ 25 } refParent={ this.containerRef }  ref={ this.contextMenuRef }>
                <ul className={ styles.ContextMenu }>
                    { contextMenu.map((item) => {
                        return (<li key={ item.sys_id } onClick={ () => executeScripts(item) }>
                            { item.name }
                        </li>);
                    }) }
                </ul>
            </Dropdown>
        );
    };

    renderLabel() {
        const { isMandatory, label, notice, sysColumnName, fieldInfo } = this.model;

        const {
            fieldClasses,
            nameClasses,
            inputClasses,
        } = this.getCSSClasses();

        const lengthText = this.model.getEnteredLength && this.model.isFocused && this.model.getEnteredLength();
        let lengthClasses = [styles.EnteredLength];
        if (lengthText < 0) {
            lengthClasses.push(styles.Warning);
        }

        const fieldName = getTestNameField(this.model);
        const dataTestLabel = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.labelField }` : `${ ATTRIBUTES.labelField }`;

        let fieldMessage = '';
        if (notice) {
            fieldMessage = <FieldMessage type={ notice.type } message={ notice.message } wrapperRef={ this.wrapperRef }
                                         columnName={ sysColumnName }/>;
        }
        return (
            <div className={ fieldClasses.join(' ') } data-test={ `${ ATTRIBUTES.field }-${ label }` }>
                <div className={ styles.NameWrap }>
                    <div className={ `${ nameClasses.join(' ') } ${ this.model.isWarning ? styles.Warning : '' }` }
                         onContextMenu={ this.handleContextMenu }
                         onMouseEnter={ this.handleLabelMouseEnter }
                         onMouseLeave={ this.handleLabelMouseLeave }
                    >
                        <div className={ styles.NameText } data-test={ dataTestLabel }>
                            {
                                isMandatory && <span className={ styles.Required }>
                                    <span className={ this.model.isWarning ? styles.Warning : '' }>*</span>
                                </span>
                            }
                            <span className={ styles.Label } onClick={ this.handleLabelClick } ref={ this.labelRef }><span ref={ this.containerRef }>{ label }</span></span>
                            { fieldInfo?.help &&
                                <span ref={ this.helpButtonRef } className={ styles.BtnIconWrapper }>
                                    <Button buttonType='icon-mini' svg={ IconQuestion } onClick={ this.toggleTooltip } className={ styles.BtnIcon } />
                                </span>
                            }
                        </div>
                        {
                            !_.isNil(lengthText) && <div className={ lengthClasses.join(' ') }>{ lengthText }</div>
                        }
                    </div>
                    { this.isShowContextMenu && this.renderContextMenu() }
                </div>
                <div className={ inputClasses.join(' ') }>
                    { this.props.children }
                </div>
                { fieldMessage }
            </div>
        );
    }

    handleScroll = () => {
        this.isShowTooltip = false;
    };

    checkOverflow = () => {
        const labelEl = this.labelRef && this.labelRef.current ? this.labelRef.current : null;
        const labelContainerEl = this.containerRef && this.containerRef.current ? this.containerRef.current : null;
        this.hasOverflow = labelEl && labelContainerEl && labelEl.offsetWidth < labelContainerEl.offsetWidth;
    };

    render() {
        const { isVisible, label, validateNotice, fieldInfo, cellEditMode } = this.model || {};
        if (!isVisible) { return null }

        const content = label && !cellEditMode ? this.renderLabel() : this.props.children;

        let validateMessage = '';

        if (validateNotice) {
            validateMessage =
                <Tooltip parent={ this.wrapperRef.current }>{ validateNotice.message }</Tooltip>;
        }

        const helpMessage = this.isShowTooltip && fieldInfo?.help &&
            <div ref={ this.tooltipRef }>
                <Tooltip parent={ this.helpButtonRef.current } onClose={ this.handleTooltipClose }>{ fieldInfo.help }</Tooltip>
            </div>;

        let hint;
        if (this.isShowHint){
            if (this.hasOverflow){
                if (fieldInfo?.hint){
                    hint = <Hint refParent={ this.labelRef }><div className={ styles.HintTitle }>{ label }</div>{ fieldInfo?.hint }</Hint>;
                } else {
                    hint = <Hint refParent={ this.labelRef }>{ label }</Hint>;
                }
            } else if (fieldInfo?.hint){
                hint = <Hint refParent={ this.labelRef }>{ fieldInfo.hint }</Hint>;
            }
        }

        return (
            <div style={ { flex: 'auto' } } ref={ this.wrapperRef }>
                { content }
                { validateMessage }
                { helpMessage }
                { hint }
            </div>
        );
    }
}
