import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './styles.module.scss';
import IconMore from 'assets/img/icons/more-horizontal-kebab.svg';
import Button from 'components/button';
import ChevronRight from 'assets/img/icons/chevron-right.svg';
import CategoryDropdown from 'components/portalWidgetsComponents/DropdownWidget/CategoryDropdown';
import Dropdown from 'components/dropdown';
import { runScript } from 'helpers/scriptClientHelper';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Виджет выпадающего списка (обертка)
 * Props:
 * isShow: {type: boolean} - флаг открытия/скрытия
 * doClose: {type: function} - метод для дополнительного действия при скрытии
 * classNameActive: {type: string} - класс для пункта меню когда дропдаун открыт
 */
@observer
export default class DropdownWidget extends React.Component {
    @observable isShow = false;
    @observable isOpenCategory = [];
    refButton = React.createRef();
    refDropdown = React.createRef();
    refElements = [];
    refSubDropdowns = [];
    @observable isMouseOver = false;
    @observable isSubMouseOver = false;
    @observable timeout = null;

    constructor(props) {
        super(props);

        this.isShow = props.isShow || this.isShow;
    }

    componentDidUpdate(prevProps) {
        const { isShow } = this.props;
        if (isShow !== prevProps.isShow) {
            this.isShow = isShow;
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleButtonClick = () => {
        if (!this.isCanEventDisplay('click')) {
            return;
        }
        this.isShow = !this.isShow;
    };

    handleButtonContextMenu = (event) => {
        if (!this.isCanEventDisplay('context')) {
            return;
        }
        event.preventDefault();
        this.isShow = !this.isShow;
    };

    handleButtonMouseOver = () => {
        if (!this.isCanEventDisplay('mouseover')) {
            return;
        }
        this.isShow = true;
    };

    handleButtonMouseOut = () => {
        if (!this.isCanEventDisplay('mouseover')) {
            return;
        }
        this.isShow = false;
    };

    isCanEventDisplay = (eventName) => {
        const { value = {} } = this.props;
        return value.menu && value.menu.event_to_display && _.includes(value.menu.event_to_display, eventName);
    };

    handleClickOutside = (event) => {
        if (!this.refDropdown || !this.refButton) {
            return null;
        }
        const { current: el } = this.refDropdown;
        const { current: parentEl } = this.refButton;

        if (!el || !parentEl) {
            return false;
        }

        let isContain = false;
        _.forEach(this.refSubDropdowns, element => {
            if (element && element.current && element.current.contains(event.target)) {
                isContain = true;
            }
        });
        if (!el.contains(event.target) && !parentEl.contains(event.target) && !isContain) {
            this.doClose();
            this.isOpenCategory = [];
            this.refSubDropdowns = [];
        }
    };

    doClose = () => {
        this.isShow = false;
        this.props.doClose && this.props.doClose(this.isShow);
    };

    handleCloseCategory = () => {
        this.isOpenCategory = [];
    };

    addSubDropdown = (refDropdown) => {
        this.refSubDropdowns.push(refDropdown);
    };

    handleChevronMouseOver = (element, indexSection, index) => () => {
        if (element.event_to_display && _.includes(element.event_to_display, 'mouseover')) {
            clearTimeout(this.timeout);
            this.isOpenCategory = [
                indexSection,
                index,
            ];
            this.isMouseOver = true;
        }
        if (!element.events || !element.events.mouseover) {
            return;
        }
        runScript(element.events.mouseover);
    };

    handleChevronMouseOut = (element) => () => {
        if (element.event_to_display && _.includes(element.event_to_display, 'mouseover')) {
            this.isMouseOver = false;
            this.handleHideSubMenu();
        }
    };

    handleSubDropdownMouseOver = () => {
        clearTimeout(this.timeout);
        this.isSubMouseOver = true;
    };

    handleSubDropdownMouseOut = () => {
        this.isSubMouseOver = false;
        this.handleHideSubMenu();
    };

    handleHideSubMenu = () => {
        this.timeout = setTimeout(this.handleHideCategory, 250);
    };

    handleHideCategory = () => {
        if (!this.isMouseOver && !this.isSubMouseOver) {
            this.isOpenCategory = [];
        }
    };

    handleClickElement = (element, indexSection, index) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (element.event_to_display && _.includes(element.event_to_display, 'click')) {
            this.isOpenCategory = [
                indexSection,
                index,
            ];
        }
        if (!element.events || !element.events.click) {
            return;
        }
        runScript(element.events.click);
    };

    handleButtonContextMenuElement = (element, indexSection, index) => (event) => {
        if (element.event_to_display && _.includes(element.event_to_display, 'context')) {
            event.preventDefault();
            this.isOpenCategory = [
                indexSection,
                index,
            ];
        }
        if (!element.events || !element.events.context) {
            return;
        }
        runScript(element.events.context);
    };

    getMenuSize = () => {
        const { value } = this.props;
        return value && value.menu && value.menu.size === 'low' ? '224px' : '464px';
    };

    renderCategory = (elements, indexSection, index) => {
        if (!elements || !elements.length || !_.isEqual([
            indexSection,
            index,
        ], this.isOpenCategory)) {
            return null;
        }
        const style = { width: this.getMenuSize() };
        return (
            <CategoryDropdown
                elements={ elements }
                refParent={ this.refElements[index] }
                addSubDropdown={ this.addSubDropdown }
                onClose={ this.handleCloseCategory }
                onMouseOver={ this.handleSubDropdownMouseOver }
                onMouseOut={ this.handleSubDropdownMouseOut }
                isShow
                styleMenu={ style }
            />
        );
    };

    renderElements = (elements, indexSection) => {
        return _.map(elements, (element, index) => {
            this.refElements[index] = React.createRef();
            const chevronRight = element.child_elements && element.child_elements.length ? (
                <div
                    className={ styles.Arrow }
                    dangerouslySetInnerHTML={ { __html: ChevronRight } }
                />
            ) : null;
            const isActive = _.isEqual([
                indexSection,
                index,
            ], this.isOpenCategory);
            return (
                <React.Fragment key={ `element${ element.title }${index}` }>
                    <div
                        className={ `${ styles.Element } ${ isActive ? styles.Active : '' }` }
                        key={ `element${ element.title }` }
                        ref={ this.refElements[index] }
                        onClick={ this.handleClickElement(element, indexSection, index) }
                        onMouseOver={ this.handleChevronMouseOver(element, indexSection, index) }
                        onMouseOut={ this.handleChevronMouseOut(element) }
                        onContextMenu={ this.handleButtonContextMenuElement(element, indexSection, index) }
                    >
                        <div className={ styles.ElementTitle }>
                            { element.title }
                        </div>
                        { chevronRight }
                    </div>
                    { this.renderCategory(element.child_elements, indexSection, index) }
                </React.Fragment>
            );
        });
    };

    renderSections = (sections) => {
        return _.map(sections, (section, index) => {
            return (
                <div className={ styles.Section } key={ `section${ index }` }>
                    { this.renderElements(section.elements, index) }
                </div>
            );
        });
    };

    renderChildren = () => {
        const { value } = this.props;
        if (!this.isShow || !value || !value.menu) {
            return null;
        }
        let cn = [ styles.Menu ];
        if (this.isShow) {
            cn.push(styles.Active);
        }
        const style = { width: this.getMenuSize() };
        const elements = value.menu.sections ? this.renderSections(value.menu.sections) : this.renderElements(value.menu.elements || [], 0);
        return (
            <Dropdown refParent={ this.refButton } ref={ this.refDropdown }>
                <div className={ cn.join(' ') } style={ style }>
                    { value.menu ? elements : null }
                </div>
            </Dropdown>
        );
    };

    render() {
        const { children, className, classNameActive } = this.props;
        const activeClass = children && classNameActive && this.isShow ? classNameActive : ''; //класс для пункта меню когда дропдаун открыт
        const button = children ? (
            <div onClick={ this.handleButtonClick }>
                { children }
            </div>
        ) : (
            <Button
                buttonType={ 'icon' }
                svg={ IconMore }
                onClick={ this.handleButtonClick }
                onMouseOver={ this.handleButtonMouseOver }
                onMouseOut={ this.handleButtonMouseOut }
                onContextMenu={ this.handleButtonContextMenu }
            />
        );

        return (
            <div
                className={ `${ className || '' } ${ activeClass }` }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `dropdown-${ ATTRIBUTES.widget }` }
            >
                <div ref={ this.refButton } className={ styles.InlineBlock }>
                    { button }
                </div>
                { this.renderChildren() }
            </div>
        );
    }
}
