import { observer } from 'mobx-react';
import * as React from 'react';
import stylesTabs from 'components/tabs/styles.module.scss';
import styles from 'components/relatedLists/styles.module.scss';
import Filter from 'components/filter';
import conditionState from 'globalState/conditions/condition';
import UiButtons from 'components/uiButtons';
import ActionsPanel from 'components/actionsPanel';
import UiButtonsContainer from 'components/uiButtonsContainer';
import { observable } from 'mobx';
import { isMedia } from 'helpers/html';
import Skeleton from 'pages/list/skeleton';
import { GroupedTable } from 'components/groupedTable';

@observer
export default class RelatedList extends React.Component {
    @observable isMediaSm = false;

    constructor(props) {
        super(props);

        conditionState.clearData();
    }

    componentDidMount(){
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onWindowResize);
    }

    checkMedia = () => {
        this.isMediaSm = isMedia('sm');
    };

    onWindowResize = () => {
        this.checkMedia();
    };

    getFilterOptions(burgerMenu) {
        if (burgerMenu === undefined) return [];
        for (let i = 0; i < burgerMenu.length; i++) {
            if (burgerMenu[i].special === "list_filters") {
                return burgerMenu[i].sub_menu;
            }
        }

        return [];
    }

    render() {
        const { data, location, isShow, onChangeCondition, onChangePage, notEditCondition, uiActions, formId, isFirst, isFetchingData, isLoadingList } = this.props;

        if (!isShow) {
            return null;
        }

        if (!data || isFetchingData) {
            return (
                <div className={ `${ stylesTabs.TabsItem } ${ isFirst ? stylesTabs.TabsItemFirst : '' }` }>
                    <div className={ styles.tabLoader } />
                </div>
            );
        }

        const filterOptions = this.getFilterOptions(uiActions.list_burger_menu);

        return (
            <div className={ `${ stylesTabs.TabsItem } ${ isFirst ? stylesTabs.TabsItemFirst : '' } ${ styles.TabsItem }` }>
                { !this.props.isWindow && <ActionsPanel pageName={ this.isMediaSm ? data.page_name || data.essence : null}
                                                        isRelatedLists
                                                        center={
                                                            uiActions.list_header_right_button &&
                                                            <UiButtonsContainer
                                                                data={ uiActions.list_header_right_button }
                                                                tableName={ data.essence }
                                                                actionsContextMenu={ uiActions.button_context_menu }
                                                                disableUiActions={ this.props.disableUiActions }
                                                            />
                                                        }
                                                        contextMenu={ uiActions.list_burger_menu }
                                                        disableUiActions={ this.props.disableUiActions }
                                                        headerButton={
                                                            uiActions.list_banner_button &&
                                                            <UiButtons
                                                                data={ uiActions.list_banner_button }
                                                                tableName={ data.essence } actionsContextMenu={ uiActions.button_context_menu }
                                                                disableUiActions={ this.props.disableUiActions }
                                                            />
                                                        }
                                                        tableName={ data.essence }
                /> }
                <Filter
                    location={ location }
                    tableName={ data.essence }
                    tableId={ data.sys_id }
                    conditionState={ conditionState }
                    notEditCondition={ notEditCondition }
                    notEditTable={ data.essence }
                    onChangeCondition={ onChangeCondition }
                    options={ filterOptions }
                    isMobile={ this.isMediaSm }
                />
                { !isLoadingList ? <GroupedTable
                    parent={ this }
                    key={ data.essence }
                    essence={ data.essence }
                    columns={ data.columns }
                    items={ data.items }
                    pagination={ data.pagination }
                    location={ location }
                    conditionState={ conditionState }
                    onChangeCondition={ onChangeCondition }
                    onChangePage={ onChangePage }
                    contextMenuHeader={ uiActions.list_table_header_context_menu }
                    contextMenuBody={ uiActions.list_context_menu }
                    tableId={ data.sys_id }
                    recordId={ this.props.recordId }
                    recordTableName={ this.props.recordTableName }
                    recordColumnName={ this.props.relatedColumnName }
                    formId={ formId }
                    selectUiAction={ uiActions.list_choice }
                    isRelatedList={ true }
                    classes={ {
                        TableWrapper: styles.bgWhite,
                        fixedHead: styles.bgWhite,
                    } }
                    doFetch = { this.props.doFetch }
                    isMobile={ this.isMediaSm }
                    relatedListId = { this.props.id }
                /> : <Skeleton isMobile={this.isMediaSm} isList/>}
                { uiActions.list_bottom_button && <UiButtons data={ uiActions.list_bottom_button }
                                                             tableName={ data.essence }
                                                             actionsContextMenu={ uiActions.button_context_menu } /> }
                { uiActions.list_link && uiActions.list_link.length > 0 &&
                <UiButtons
                    data={ uiActions.list_link }
                    tableName={ data.essence }
                    actionsContextMenu={ uiActions.button_context_menu }
                    isRelatedLinks={ true }
                    disableUiActions={ this.props.disableUiActions }
                />
                }
            </div>
        );
    }
}
