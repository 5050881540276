import * as React from 'react';
import { observer } from 'mobx-react';
import styles from 'components/dateTimePicker/styles.module.scss';
import DatePickerTimeInput from 'components/dateTimePicker/datePickerTimeInput';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Часы датапикера
 * Параметры:
 * picker - ссылка на датапикер
 */
@observer
export default class DatePickerTime extends React.Component {
    constructor(props) {
        super(props);
    }

    onChange = (unit) => {
        return (value) => {
            this.props.picker.state.date = this.props.picker.state.date.clone().set(unit, value);
        };
    };

    render() {
        return <div className={ styles.Timer }>
            <div className={ styles.TimerField }>
                <DatePickerTimeInput
                    value={ this.props.picker.state.date.hours() }
                    onChange={ this.onChange('hour') }
                    data-test={ ATTRIBUTES.fieldDatePickerTimeInputHour }
                />
            </div>
            <div className={ styles.TimerField }>
                <DatePickerTimeInput
                    value={ this.props.picker.state.date.minutes() }
                    onChange={ this.onChange('minute') }
                    data-test={ ATTRIBUTES.fieldDatePickerTimeInputMinute }
                />
            </div>
            <div className={ styles.TimerField }>
                <DatePickerTimeInput
                    value={ this.props.picker.state.date.seconds() }
                    onChange={ this.onChange('second') }
                    data-test={ ATTRIBUTES.fieldDatePickerTimeInputSecond }
                />
            </div>
        </div>;
    }
}
