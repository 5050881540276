import BaseFormFieldModel from './BaseFormFieldModel';
import { observable } from 'mobx';
import { isEmptyObject } from 'helpers/isEmpty';
import { isChanged } from 'helpers/form';
import { getDisplayValueTranslate } from 'helpers/translations';

export default class ReferenceModel extends BaseFormFieldModel {

    /**
     * компонент используется в редакторе ячеек листа
     * cellEditMode
     *
     * @type {boolean}
     */
    cellEditMode;

    /**
     * флаг используется ли компонент полем типа лист
     * usedByList
     *
     * @type {boolean}
     */
    usedByList;

    /**
     * Параметры
     * special
     *
     * @type {*}
     */
    @observable special;

    /**
     * значение поисковой строки
     * term
     *
     * @type {string}
     */
    @observable term = '';

    /**
     * @type {{
     *     display_value: {string},
     *     database_value: {string},
     *     is_option: {boolean}
     * }|null}
     */
    value;

    /**
     * параметр для поиска condition
     * forReference
     *
     * @type {string}
     */
    forReference;

    columnType = 'reference';

    /**
     * список опций для выбора
     * @type {[{
     *     display_value: {string},
     *     database_value: {string},
     * }]|[]}
     */
    @observable valueOpts = [];

    /**
     * database_value текущей выбираемОЙ опции
     * currentOption
     *
     * @type {string}
     */
    @observable currentOption = ''; // database_value текущей выбираемОЙ опции

    constructor(data, parentFormSectionModel) {
        super(data, parentFormSectionModel);
        this.mergeData(data);
    }

    isEmptyValue() {
        return !this.value ||
            !this.value.database_value;
    }

    getReferenceQualifier() {
        return this.special.reference_qualifier && this.special.reference_qualifier;
    }

    getReferenceQualifierCondition() {
        const referenceQualifier = this.getReferenceQualifier();
        return referenceQualifier && referenceQualifier.condition;
    }

    isNeedRequest() {
        const referenceQualifier = this.getReferenceQualifier();
        return referenceQualifier && referenceQualifier.is_need_request;
    }

    get databaseValue() {
        let value = this.value;

        if (!value) {
            return null;
        }

        if (!value.database_value) {
            return null;
        }

        return value.database_value;
    }

    set databaseValue(value) {
        this.value = {
            display_value: this.displayValue,
            database_value: value,
        };
    }

    get displayValue() {
        let value = this.value;

        if (!value) {
            return '';
        }

        if (!value.display_value) {
            return '';
        }

        return value.display_value;
    }

    set displayValue(value) {
        this.value = {
            display_value: value,
            database_value: this.databaseValue,
        };
    }

    uiGetValue() {
        return this.databaseValue;
    }

    uiGetDisplayValue() {
        return this.displayValue;
    }

    uiClearValue() {
        if (this.columnType === 'record_class') return false;

        this.value = null;
        this.changed = isChanged(this.defaultValue, this.value);
        return true;
    }

    async uiSetValue(value) {
        if (value === '') {
            return this.uiClearValue();
        }

        const params = {
            table_name: this.sysTableName,
            column_id: this.columnId,
            value: value,
        };

        this.value = await getDisplayValueTranslate(params);
        this.changed = isChanged(this.defaultValue, this.value);
    }

    checkEmptyValue() {
        return isEmptyObject(this.value);
    }

    getValueForSave(){
        return this.value && this.value.database_value;
    }
}
