import * as React from 'react';
import { observer } from 'mobx-react';

import styles from 'components/portalDesigner/styles.module.scss';

/**
 * компонент загрузки страницы портала
 */
@observer
export default class PortalPageDesignerEmptyPage extends React.Component {
    render() {
        return <div className={ styles.ViewLoading } />;
    }
}