import { observable } from 'mobx';

import _ from 'lodash';
import { setSearchQuery } from 'helpers/history';
import apiRequest from 'lib/apiRequest';
import { fetchPortalPageData } from 'actions/portalDesigner';
import PortalStorage from 'components/portalDesigner/storage/portal';

import ContainersPortalDesignerCollection from 'components/portalDesigner/collection/containers';
import DragAndDropState from 'components/portalDesigner/components/dragAndDrop/state';


/**
 * state всех данный дизайнера страниц порталов
 */
class PageData {
    /**
     * коллекция всех контейнеров
     * @type ContainersPortalDesignerCollection
     */
    @observable containers = null;

    @observable main = {};
    portal = null;

    /**
     * выделенный элменет
     * @type {BasePortalDesignerModel}
     */
    @observable activeElement = null;

    /**
     * @type {BasePortalDesignerModel|ContainerPortalDesignerModel}
     */
    tempContainer = null;

    /**
     * @type {BasePortalDesignerModel|RowPortalDesignerModel}
     */
    tempRow = null;

    /**
     * @type {BasePortalDesignerModel|WidgetInstancePortalDesignerModel}
     */
    tempWidget = null;

    @observable breadcrumbs = [];

    currentPageSysId = null;

    TYPE_CONTAINER = 'container';
    TYPE_LAYOUT = 'layout';
    TYPE_WIDGET_INSTANCE = 'widget_instance';

    DEFAULT_SIZE = 'size_md_for_column';

    constructor() {
        /**
         * @fixme для дебага. убрать после релиза
         */
        window.PageData = this;
    }

    /**
     *
     * @param page
     * @returns {Promise<void>}
     */
    setCurrentPage(page) {
        this.currentPageSysId = page;
        setSearchQuery({
            page,
        });
        return this.fetchPage();
    }

    /**
     *
     * @returns {Promise<void>}
     */
    async fetchPage() {
        if (!this.currentPageSysId) {
            return;
        }

        this.containers = null;
        let { data } = await fetchPortalPageData(this.currentPageSysId);
        this.main = data.main;
        this.containers = new ContainersPortalDesignerCollection(data.containers);
    }

    getPortalList() {
        const portals = PortalStorage.get().map((portal) => ({
            database_value: portal,
            display_value: portal.title,
        }));

        return [
            {
                database_value: null,
                display_value: '-',
            },
            ...portals,
        ];
    }

    /**
     *
     * @param model {BasePortalDesignerModel}
     * @param breadcrumbsIds [{id}]
     */
    setActiveElement = (model, breadcrumbsIds = this.breadcrumbs) => {
        let breadcrumbs = _.clone(breadcrumbsIds);
        if (model) {
            const index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.id === model.id);
            if (index > -1) breadcrumbs.splice(index + 1, breadcrumbs.length);
            this.breadcrumbs = breadcrumbs;
            this.activeElement = model;
        }
        else {
            this.breadcrumbs = [];
            this.activeElement = null;
        }
    };

    /**
     *
     * @returns {Promise<*>}
     */
    async removeActiveElement() {
        if (this.activeElement) {
            const response = await this.activeElement.remove();

            if (response.status === 'OK') {
                this.activeElement = null;
            }

            return response.getData();
        }
        const data = await this.activeElement.remove();
        this.activeElement = null;
        return data;
    }

    /**
     *
     * @param nextRecordId
     * @param parentId
     * @param layoutId
     * @param name
     * @returns {*}
     */
    fetchLayout = ({ nextRecordId, parentId, sysId, name }) => {
        let params = {
            parent_id: parentId || this.currentPageSysId,
            name,
        };

        if (sysId !== undefined) {
            params.sys_id = sysId;
        }

        if (nextRecordId !== undefined) {
            params.next_record_id = nextRecordId;
        }

        return new apiRequest('PUT /portal/layout').qs(params).send();
    };

    /**
     *
     * @param index
     * @returns {mockData.containers|{rows, css_class_names}|*|Array}
     */
    getContainers(index) {
        if (!this.containers) {
            return [];
        }

        return this.containers.getItems(index);
    }

    /**
     * добавляет пустой контейнер
     *
     * @returns {Promise<void>}
     */
    async fetchSave(model, parentSysId, sysId = null, isNew = true) {
        let params = {
            name: model.TYPE,
            parent_id: parentSysId,
            is_new: isNew,
        };

        if (sysId) {
            params.sys_id = sysId;
        }

        const data = await model.fetchSave(params);
        this.tempContainer = null;
        this.tempRow = null;
        this.tempWidget = null;

        return data;
    }

    /**
     *
     * @param containerModel {ContainerPortalDesignerModel}
     */
    setTempContainer(containerModel = null) {
        if (this.tempContainer) {
            this.tempContainer.setData({
                order: containerModel ? containerModel.order - .5 : 0,
            });
        }
        else if (DragAndDropState.isNew) {
            this.tempContainer = containerModel.appendBefore({ isTemp: true });
        }
        else {
            this.tempContainer = containerModel;
        }
        return this.tempContainer;
    }

    /**
     *
     * @param containerModel {ContainerPortalDesignerModel}
     * @param params {*}
     */
    setTempRow(containerModel, params = {}) {
        if (this.tempRow) {
            this.tempRow.remove();
            containerModel.rows.clearTemp();
        }
        return this.tempRow = containerModel.rows.insert({ isTemp: true, ...params });
    }

    /**
     *
     * @param columnModel {ColumnPortalDesignerModel}
     * @param params {*}
     */
    setTempWidget(columnModel, params = {}) {
        if (this.tempWidget) {
            this.tempWidget.remove();
            columnModel.collection.clearTemp();
        }
        const findItem = columnModel.widgetInstances.getItems().find(item => item.sysId === DragAndDropState.sysId);
        if (findItem) {
            return this.tempWidget = findItem;
        }
        return this.tempWidget = columnModel.widgetInstances.insert({
            isTemp: true,
            ...params,
        });
    }
}


export default new PageData;
