import * as React from 'react';
import ModalWindow from 'components/modalWindow';
import * as _ from 'lodash';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { fetchRecord, fetchRecordUiActions } from 'actions/record';
import Heading from 'components/heading';
import DynamicForm from 'components/dynamicForms';
import PageLoader from 'components/pageLoader';
import styles from './styles.module.scss';
import { createCard, getCard } from 'actions/taskBoard';
import TaskBoardLanesState from 'components/taskBoard/taskBoardLanes/state';
import Button from 'components/button';
import SimpleForm from 'ui-actions-scripts/simple-form';
import langStore from 'globalState/lang';
import { generateRandomString } from 'helpers/data';

const DEFAULT = 'Default';

/**
 * @param {boolean} isShow
 * @method doClose
 * @param {string} recordId
 * @param {string} tableName
 * @param {string} boardId
 * @param {string} cardId
 * @param {string} viewName
 * @param {string} laneId
 */
@observer
export default class TaskBoardCardModal extends React.Component {
    @observable uiAction = {};
    @observable resultData;
    @observable disableUiActions = false;
    @observable pageName = '';
    @observable loading = false;
    @observable isSubmitting = false;
    @observable dynamicFormId;
    uiActionGetTimer = null;

    constructor(props) {
        super(props);
        this.fetchRecord().catch(console.error);
        this.dynamicFormId = generateRandomString();
    }

    componentDidUpdate(prevProps) {
        const { isShow } = this.props;
        if (isShow && isShow !== prevProps.isShow) {
            this.fetchRecord().catch(console.error);
        }
    }

    disableUiActionOnSaveForm = (condition) => {
        this.disableUiActions = condition;
    };

    fetchRecord = async () => {
        const { recordId, tableName, isShow, viewName } = this.props;
        if (!isShow) {
            return;
        }
        this.dynamicFormId = generateRandomString();
        this.loading = true;
        this.uiAction = {};
        this.setUiActionGetTimer();

        const params = {
            form_view: viewName || DEFAULT,
            open_first_rel_list : 0,
        };
        const { data } = await fetchRecord(tableName, recordId, params);
        this.dynamicFormId = generateRandomString();
        window.s_form = new SimpleForm(tableName, recordId, data.form_title, undefined, this.dynamicFormId);
        window.s_form.formId = data.form_id;
        this.resultData = data;

        window.isNewRecord = !!recordId;
        this.setPageName(this.resultData.page_name || '');
        this.loading = false;
    };

    setUiActionGetTimer = () => {
        if (this.uiActionGetTimer !== null) {
            clearTimeout(this.uiActionGetTimer);
            this.uiActionGetTimer = null;
        }
        this.uiActionGetTimer = setTimeout(() => this.fetchUiActions().catch(console.error), 500);
    };

    fetchUiActions = async () => {
        const { recordId, tableName, viewName } = this.props;
        const params = {
            form_view: viewName || DEFAULT,
        };
        const response = await fetchRecordUiActions(tableName, recordId, params);
        this.uiAction = response.isOkStatus && response.data ? response.data : {};
    };

    setPageName = (pageName) => {
        this.pageName = pageName;
    };

    updateUiActionsAfterSaveForm = () => {
        this.setUiActionGetTimer();
    };

    createNewCard = async (tableRecordId) => {
        const { boardId } = this.props;
        const data = await createCard({
            record_id: tableRecordId,
            board_id: boardId,
        });
        if (data.isOkStatus) {
            const laneId = data.data.card.lane_id;
            TaskBoardLanesState.addCard(laneId, data.data.card);
            TaskBoardLanesState.updateCards(laneId, data.data.cards_order);
        }
    };

    getCard = async () => {
        const { cardId, laneId } = this.props;
        const data = await getCard({
            card_id: cardId,
        });
        if (data.isOkStatus) {
            const card = data.data;
            const newLaneId = card.lane_id;
            if (newLaneId === laneId) {
                TaskBoardLanesState.updateCard(newLaneId, card);
            }
            else {
                TaskBoardLanesState.addCard(newLaneId, card);
                TaskBoardLanesState.deleteCard(laneId, card.sys_id);
            }
        }
    };

    handleSaveClick = () => {
        const { doClose, recordId } = this.props;
        this.isSubmitting = true;
        window.s_form.save()
              .then(async (tableRecordId) => {
                  try {
                      this.loading = true;

                      if (!recordId) {
                          await this.createNewCard(tableRecordId);
                      }
                      else {
                          await this.getCard();
                      }
                      doClose();
                  }
                  catch (error) {
                      console.error(error);
                  }
                  finally {
                      this.isSubmitting = false;
                      this.loading = false;
                  }
              })
              .catch((error) => {
                  console.error(error);
                  this.isSubmitting = false;
              });
    };

    renderRecordData = () => {
        const { recordId, tableName } = this.props;
        if (this.loading) {
            return (
                <PageLoader />
            );
        }
        if (_.isEmpty(this.resultData)) {
            return null;
        }
        const { button_save } = langStore.getTranslateKey('filter_titles');
        const data = this.resultData;
        const fields = data.sections[0].elements;
        const { table_id } = data;
        return (
            <div id="container">
                <Heading size='h2'>{ this.pageName }</Heading>
                <DynamicForm
                    sections={ data.sections }
                    key="firstSection"
                    fields={ fields }
                    name={ data.sections[0].name }
                    sysId={ recordId }
                    tableName={ tableName }
                    tableId={ table_id }
                    clientScripts={ data.client_scripts }
                    contextMenu={ this.uiAction ? this.uiAction.form_field_label_context_menu : null }
                    form_id={ data.form_id }
                    setPageName={ this.setPageName }
                    updateUiActionsAfterSaveForm={ this.updateUiActionsAfterSaveForm }
                    disableUiActionOnSaveForm={ this.disableUiActionOnSaveForm }
                    silentMode
                    id={ this.dynamicFormId }
                />
                <div className={ styles.ButtonsPanel }>
                    {/*<div className={ styles.ButtonCancel } onClick={ doClose }>Отмена</div>*/ }
                    { this.uiAction?.form_bottom_button?.length > 0 &&
                        <Button
                            onClick={  this.handleSaveClick }
                            buttonType={ 'primary' }
                            disabled={ this.isSubmitting }
                            className={ styles.ButtonSave }
                        >
                            { button_save }
                        </Button>
                    }
                </div>
            </div>
        );
    };

    render() {
        const { isShow, doClose } = this.props;
        const modalStyles = {
            width: '80vw',
            height: '80vh',
            overflow: 'auto',
        };
        return (
            <ModalWindow
                isShow={ isShow }
                modalStyles={ modalStyles }
                doClose={ doClose }
            >
                { this.renderRecordData() }
            </ModalWindow>
        );
    }
}
