import { WidgetDataType } from 'types/components/widgets/index';
import { ContainerWidgetInstanceType } from 'types/pages/servicePortal/index';
import { action, observable } from 'mobx';
import { PortalWidgetInstanceType, PortalWidgetType } from 'types/pages/servicePortal/container/index';
import PortalWidget from 'globalState/servicePortalWidgets/containers/rows/columns/widgetInstances/widgets/index';
import _ from 'lodash';

export default class PortalWidgetInstance implements PortalWidgetInstanceType {
    @observable private _schemaOptions: WidgetDataType;
    @observable private _sysOrder: number;
    @observable private _widget: PortalWidgetType;
    @observable private _widgetInstanceId: string;

    constructor(data: ContainerWidgetInstanceType) {
        this._sysOrder = data.sys_order;
        this._widgetInstanceId = data.widget_instance_id;
        this._schemaOptions = data.schema_options;
        this._widget = new PortalWidget(data.widget);
    }

    getSchemaOptions(): WidgetDataType {
        return this._schemaOptions;
    }

    @action
    setSchemaOptions(value: WidgetDataType) {
        this._schemaOptions = value;
    }

    getSysOrder(): number {
        return this._sysOrder;
    }

    @action
    setSysOrder(value: number) {
        this._sysOrder = value;
    }

    getWidget(): PortalWidgetType {
        return this._widget;
    }

    @action
    setWidget(value: PortalWidgetType) {
        this._widget = value;
    }

    getWidgetInstanceId(): string {
        return this._widgetInstanceId;
    }

    @action
    setWidgetInstanceId(value: string) {
        this._widgetInstanceId = value;
    }

    @action
    updateWidget = (widgetInstance: PortalWidgetInstanceType) => {
        const widget = widgetInstance.getWidget();
        if (_.isEmpty(this._widget)) {
            this._widget = widget;
            return;
        }
        if (!this.isEqualWidget(widgetInstance)) {
            this._widget = widget;
        } else {
            this._widget.updateData(widget.getTemplate(), widget.getClientScript(), widget.getCss());
        }
    };

    isEqualWidget = (widgetInstance: PortalWidgetInstanceType) => {
        const widget = widgetInstance.getWidget();
        return this._widgetInstanceId === widgetInstance.getWidgetInstanceId() && this._schemaOptions === widgetInstance.getSchemaOptions()
            && this._widget.getSysId() === widget.getSysId() && this._widget.getClientScript() === widget.getClientScript()
            && this._widget.getTemplate() === widget.getTemplate() && this._widget.getCss() === widget.getCss();
    };
}
