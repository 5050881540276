import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import FieldFactory from 'components/dynamicForms/view/form/fieldFactory';

import QuickMessage from 'components/quickMessage';

import styles from 'components/table/styles.module.scss';

import globalTableState from 'globalState/table';
import langStore from 'globalState/lang';
import CellEditor from 'components/table/cellEditor';
import TableTitle from 'components/table/tableTitle';
import CellDescription from 'components/table/cellDescription';
import { isEqual } from 'helpers/data';
import IconEyeOff from 'assets/img/icons/eye-off.svg';
import InfoMessagesState from 'globalState/infoMessages';
import pleaseStopTriggeringClicksOnDoubleClick from 'helpers/hoc/stopTriggeringClicks';
import { runOnTypeScripts, TYPE_ON_CELL_EDIT } from 'helpers/scriptClientHelper';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import _, { isNull } from 'lodash';
import Tooltip from 'components/tooltip';
import mapper from 'components/dynamicForms/model/field/mapper';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { filterArrayForList, getCellValue, isNotSet } from 'helpers/tableHelpers';
import RefLink from 'components/table/refLink';
import { beforeUnload } from 'helpers/form';
import { fetchCellData, updateCellData } from 'actions/record';
import { fetchListFilter } from 'actions/list';

/**
 * Cell - компонент ячейки в таблице.
 * */
@withRouter
@observer
export default class Cell extends React.Component {
    sortType = 'desc';
    @observable message = null;
    @observable isShowHeadListMenu = false;
    @observable isShowPopup = false;
    @observable value = null;
    @observable isValidField = true;
    @observable validateMessage = '';
    @observable disabledButtonSave = false;

    cellRef = React.createRef();
    field = React.createRef();
    cursorPosition;
    @observable badgeColor = '';
    @observable writeAccess = false;
    @observable isMandatory = false;
    @observable special;
    @observable dependentOnColumn;

    constructor(props) {
        super(props);

        this.state = {
            type: this.isHead() ? 'th' : 'td',
        };
        const { data } = props;

        this.badgeColor = this.getStateColor(data.color);
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (!isEqual(prevProps.data, data)) {
            this.badgeColor = this.getStateColor(data.color);
        }

        if (this.props.isFixedHead) {
            this.props.table.setFixedHeadColSizes();
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
    }

    getStateColor(color) {
        return color ? color : '';
    }

    errorFetchData = (message) => {
        this.disabledButtonSave = false;
        InfoMessagesState.pushError(message);
    };

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };


    handleClick = () => {
        const { location } = this.props;
        if (location && location.pathname && location.pathname === '/report') {
            return false;
        }
        const { filter_titles } = langStore.getTranslate();
        const asc = filter_titles && filter_titles.a_to_z;
        const desc = filter_titles && filter_titles.z_to_a;

        if (this.isHead() && this.canSorted()) {
            const { field, fieldType } = this.props;
            const sortRow = this.props.sortingFields.find(sortRow => sortRow.getField().dot_walking_attribute === field);
            this.sortType = asc;
            if (sortRow) {
                this.sortType = sortRow.getDirection() === asc ? desc : asc;
            }
            else {
                //начальная сортировка по убыванию для колонки Number, и колонки с типом datetime DEF0005527
                //по дефекту указание хардкодить на фронте
                if (fieldType === 'datetime' || fieldType === 'datetime_specific' || field === 'number') {
                    this.sortType = desc;
                }
            }
            this.props.onChangeSorting(field, this.sortType);
        }
        else {
            if (this.props.table.props.isWindow) {
                this.selectDictionary();
            }
        }
    };

    onDocumentClick = ({ target }) => {
        const { current: cellEl } = this.cellRef;
        if(!cellEl || !cellEl.contains || this.props.table.props.isWindow || this.props.isMobile) return;

        if (cellEl.contains(target)) {
            cellEl.classList.add(styles.focus);
        }
        else {
            cellEl.classList.remove(styles.focus);
        }
    };

    handleContextMenu = (e) => {
        if (!this.isHead() && !this.props.data.read_access || this.props.table.editCol) {
            return;
        }
        e.preventDefault();
        window.currentCell = {
            attribute: this.props.field,
            value: this.getValue(),
            tableName: this.props.table.props.essence,
            fieldName: this.props.head_column_name,
            tableId: globalTableState.getTableId(),
            columnId: this.props.db_column_id,
            originColumnId: this.props.origin_column_id,
            recordColumnName: globalTableState.getRecordColumnName(),
            recordId: this.props.id,
            columnType: this.props.column_type,
        };
        this.props.table.menuCoordinates.x = e.pageX;
        this.props.table.menuCoordinates.y = e.pageY;
        this.props.table.isShowHeadListMenu = this.isHead();
        this.props.table.isShowContextMenu = !this.isHead();
    };

    getInlineValue(value) {
        const { list_titles } = langStore.getTranslate();

        const { data, db_column_name, column_type, database_value, validate } = this.props;

        if (this.isHead()) {
            return data;
        }
        const NOT_SET = <span className={ styles.NotSet }>({ list_titles && list_titles.not_set })</span>;
        let result = NOT_SET;
        if (db_column_name === 'sys_id') {
            if (typeof database_value === 'object') {
                if (isNotSet(database_value.value)) {
                    return NOT_SET;
                }
                else {
                    return database_value.value;
                }
            }
            else {
                return database_value;
            }
        }

        const cellValue = getCellValue(value, column_type, validate);
        if (!isNull(cellValue)) {
            result = cellValue;
        }

        if (result !== NOT_SET) {
            if (this.badgeColor) {
                result = (
                    <div className={ [
                        styles.stateBadge,
                        styles[this.badgeColor],
                    ].join(' ') }>
                        { result }
                    </div>
                );
            }
        }
        return result;
    }


    tooltip = (message) => {
        this.message = message;
        setTimeout(() => this.message = null, 1000);
    };

    changeSortingByDoubleClick = () => {
        const { location } = this.props;
        if (location && location.pathname && location.pathname === '/report') {
            return;
        }
        if (this.isHead() && this.canSorted()) {
            const { filter_titles } = langStore.getTranslate();
            const desc = filter_titles && filter_titles.z_to_a;
            const { field } = this.props;
            const sortRow = this.props.sortingFields.find(sortRow => sortRow.getField().dot_walking_attribute === field);
            if (sortRow && sortRow.getDirection()) {
                return;
            }
            this.sortType = desc;
            this.props.onChangeSorting(field, this.sortType);
        }
    };

    handleLinkClick = (evt) => {
        const { isRelatedList } = this.props;
        const unload = beforeUnload({});
        if (isRelatedList && unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
    };

    handleDoubleClick = async (e) => {
        const { list_titles = {} } = langStore.getTranslate();
        e.persist();
        e.preventDefault();
        this.changeSortingByDoubleClick();
        if (!this.props.table || this.isHead() || this.disabledButtonSave || this.props.isMobile || this.isEditColOpened()) {
            return;
        }
        const response = await fetchCellData(this.props.table_name || '', this.props.field || '', this.props.id || '');
        this.writeAccess = response.data.write_access;
        this.isMandatory = !!response.data.column?.is_mandatory;
        this.special = response.data.column?.special;
        this.dependentOnColumn = response.data.column?.dependent_on_column;

        const editCol = this.props.table.props.columns.find((col) => this.props.field === col.attribute);
        if (!editCol) return;

        if (this.props.column_type === 'choice' && this.writeAccess) {
            editCol.special = response.data.column?.special;
        }

        if (editCol.read_only
            || !this.writeAccess
            || editCol.type === 'html') {
            this.tooltip(list_titles.read_only);
            return;
        }
        this.database_value = this.props.database_value;

        editCol.clickCoordinates = {
            x: e.pageX,
            y: e.pageY,
        };

        this.value = this.props.data.value;

        this.props.table.editRow = this.props.id;
        this.props.table.editCol = editCol;
    };

    getValue = () => {
        const { column_type, database_value, data } = this.props;
        const value = data.value;

        if ((Array.isArray(value) && value.length === 0)) {
            return null;
        }
        if (column_type === 'choice') {
            return database_value === '' ? null : database_value;
        }
        if ([
            'reference',
            'record_class',
            'field_name',
        ].includes(column_type)) {
            return value.database_value;
        }
        if (column_type === 'list') {
            return value.map((item) => item.database_value).join(',');
        }
        if (column_type === 'id') {
            return value.document_id;
        }
        if (this.value === 0) {
            return '0';
        }
        return value;
    };


    onContextMenu = (e) => {
        if (!this.props.data.read_access) {
            return;
        }
        e.preventDefault();
        window.currentCell = {
            attribute: this.props.field,
            value: this.getValue(),
            tableName: this.props.table.props.essence,
            fieldName: this.props.head_column_name,
            tableId: this.props.table.props.tableId,
            columnId: this.props.db_column_id,
            columnType: this.props.column_type,
            originColumnId: this.props.origin_column_id,
            recordColumnName: globalTableState.getRecordColumnName(),
            recordId: this.props.id,
        };
    };

    resetEdit = () => {
        this.props.table.editRow = null;
        this.props.table.editCol = null;
        this.value = null;
    };

    getValueForSave() {
        if (typeof this.value === 'undefined' || this.value === null) {
            return null;
        }
        if (this.value && _.isPlainObject(this.value) && 'database_value' in this.value) {
            return this.value.database_value;
        }

        if (this.value && this.value.sys_id) {
            return this.value.sys_id;
        }

        if (this.props.column_type === 'list') {
            return this.value.map((item) => item.database_value).join(',');
        }

        if (this.props.column_type === 'id') {
            return this.value.document_id;
        }

        return ([
            'boolean',
            'object',
        ].includes(typeof this.value)) ? this.value : this.value.toString();
    }

    save = async () => {
        const { clientScripts, db_column_id, db_column_name, table_name, record_table_name, id, formId } = this.props;

        const value = this.getValueForSave();

        if (!this.isValidField) {
            this.showPopup();
            return;
        }

        if (this.isMandatory) {
            const { list_titles = {} } = langStore.getTranslate();
            const REACT_NOT_SET = (
                <span className={ styles.NotSet }>({ list_titles.not_set })</span>
            );
            const checkedNewValue = this.getInlineValue(this.value);

            if (_.isEqual(checkedNewValue, REACT_NOT_SET)) {
                this.validateMessage = list_titles.is_mandatory;
                this.showPopup();
                return;
            }
        }

        this.disabledButtonSave = true;

        const data = {
            record: {
                [db_column_name]: value,
            },
        };

        if (Array.isArray(clientScripts) && clientScripts.length) {
            const param = {
                oldValue: this.props.data.value,
                newValue: value,
                table: table_name,
                recordTableName: record_table_name,
                sysId: id,
                isContinue: true,
                current: data.record,
                callback(isContinue = true) {
                    param.isContinue = isContinue;
                },
            };

            runOnTypeScripts(clientScripts, TYPE_ON_CELL_EDIT, db_column_id, param);
        }

        const view = new URLSearchParams(window.location.search).get('view');
        const response = await updateCellData(table_name || '',
            db_column_name || '',
            id || '',
            value);

        if (response.isOkStatus) {
            const params = _.omitBy( {
                condition: `sys_id=${id}`,
                view: view,
                form_id: formId || '',
            }, _.isEmpty);
            const response = await fetchListFilter(table_name || '', params);
            const items = response?.data?.items;
            if(Array.isArray(items) && items.length > 0) {
                globalTableState.setRowByID(id, items[0]);
            } else {
                globalTableState.deleteRow(id);
            }
            this.disabledButtonSave = false;
            this.resetEdit();
        }
    };

    setNewValueCell = () => {
        const { rowIndex, field } = this.props;
        const stateValue = globalTableState.getCellValue(rowIndex, field);
        let value = this.value;
        if (stateValue) {
            if (typeof stateValue.sys_id !== 'undefined') {
                value = {
                    sys_id: this.field.value,
                    display_value: this.field.display_value,
                };
            }
            if (typeof stateValue.database_value !== 'undefined') {
                value = {
                    database_value: this.field.value,
                    display_value: this.field.display_value,
                };
            }
            if (Array.isArray(stateValue)) {
                value = this.field.list;
            }
        }
        globalTableState.setCellValue(rowIndex, field, value);
    };

    isHead = () => {
        return this.props.type === 'head';
    };

    canSorted = () => {
        return this.props.fieldType !== 'journal_input';
    };

    isSortedField = () => {
        const { field } = this.props;
        const { filter_titles } = langStore.getTranslate();
        const asc = filter_titles && filter_titles.a_to_z;
        const sortRow = this.props.sortingFields.find(sortRow => sortRow.getField().dot_walking_attribute === field);
        this.sortType = sortRow ? sortRow.getDirection() : asc;
        return this.canSorted() && sortRow;
    };

    isEditColOpened = () => {
        return !!this.props.table.editCol;
    };

    getSortIcon = () => {
        const { filter_titles } = langStore.getTranslate();
        const asc = filter_titles && filter_titles.a_to_z;

        const getSortIconASC = <span className={ styles.TableSort }><span className={ styles.TableSortIcon } dangerouslySetInnerHTML={ { __html: IconChevronUp } } /></span>;
        const getSortIconDESC = <span className={ styles.TableSort }><span className={ styles.TableSortIcon } dangerouslySetInnerHTML={ { __html: IconChevronDown } } /></span>;

        return this.sortType === asc ?
            getSortIconASC :
            getSortIconDESC;
    };

    checkDisplayValue = (value) => {
        if (_.isPlainObject(value) && 'display_value' in value) {
            return value.display_value;
        }
        else if (_.isArray(value)) {
            return value.length ? filterArrayForList(value) : '';
        }
        return value;
    };

    selectDictionary = () => {
        const displayValue = this.checkDisplayValue(this.props.label.value);
        window.opener.postMessage({
            database_value: this.props.id,
            display_value: displayValue || this.props.id,
        }, '*');
    };

    /**
     * custom attributes
     */
    getCustomAttr = () => {
        const attr = {};

        if (this.props.column_type === 'field_name' && this.props.table.editCol) {
            attr.dependent_on_column = this.dependentOnColumn;
            attr.sys_table_name = this.props.table.props.essence;
            attr.sys_column_name = this.props.db_column_name;
            attr.recordId = this.props.id;
            attr.allElements = this.props.columns;
        }
        return attr;
    };

    onChange = ({ value, isValid, validate: messages = {} }) => {
        this.isValidField = isValid;
        if (!isValid) {
            this.validateMessage = messages.wrong_data_message;
        }

        if (value && _.isPlainObject(value) && 'database_value' in value && isNull(value.database_value) && 'display_value' in value) {
            this.value = { ...value };
            this.value.display_value = null;
        }
        else {
            this.value = value;
        }
    };

    getChildren = () => {
        const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];

        const isEdited = !!this.props.table
            && !!this.props.table.editCol
            && !this.props.table.editCol.read_only
            && this.props.table.editRow === this.props.id
            && this.props.table.editCol.attribute === this.props.field
            && this.writeAccess;


        if (isEdited) {
            let model = {};
            const forReference = {
                sys_column_name: this.props.db_column_name,
                current: this.props.rowValues,
                table_name: this.props.table_name,
            };

            const fieldProps = {
                tableId: this.props.id,
                is_mandatory: this.isMandatory,
                column_type: this.props.column_type,
                special: this.special,
                validate: this.props.validate,
                ref: this.field,
                cellEditMode: true,
                forReference: forReference,
                value: this.value,
                ...this.getCustomAttr(),
            };

            try {
                model = new mapper[this.props.column_type](fieldProps);
                model.save = this.save;
            }
            catch (e) {
                console.error(e);
            }

            children.push(
                <CellEditor
                    key={ `edit${ this.props.field }` }
                    onSave={ this.save }
                    onReset={ this.resetEdit }
                    cellRef={ this.cellRef }
                    clickCoordinates={ this.props.table.editCol.clickCoordinates }
                    isBooleanCol={ this.props.column_type === 'boolean' }
                    hideButtons={ this.props.column_type === 'boolean' || this.props.column_type === 'choice' }
                    isDisabled={ this.disabledButtonSave }
                >
                    <>
                        <FieldFactory
                            model={ model }
                            onChange={ this.onChange }
                        />
                        { this.isShowPopup && <Tooltip
                            onClose={ this.hidePopup }
                            parent={ this.field.current }
                        >
                            { this.validateMessage }
                        </Tooltip> }
                    </>
                </CellEditor>,
            );
        }

        if ((this.props.column_type && this.props.column_type.toLowerCase() === 'url' && this.value) || this.props.refLink || this.props.recordLink) {

            if (this.props.table.props.isWindow) { // таблица открыта в словаре - значит ссылка не нужна. у нас кликабельны строки целиком
                children.push(<span
                    key="value"
                >
                   { this.getInlineValue(this.props.data.value) }
                </span>);
            }
            else {
                if (this.props.column_type && this.props.column_type.toLowerCase() === 'url' && this.value) {
                    let linkUrl = this.value;
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_ID}', 'g'), this.props.id);
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_TABLE}', 'g'), this.props.table.props.essence);
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_TABLE_ID}', 'g'), (this.props.table.props.id || this.props.table.props.essence));

                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_LIST_VIEW}', 'g'), '');
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_RELATED_LIST_VIEW}', 'g'), '');
                    linkUrl = linkUrl.replace(new RegExp('{CURRENT_FORM_VIEW}', 'g'), '');

                    const urlStarts = linkUrl.split('://')[0];
                    if (urlStarts === 'http' || urlStarts === 'https') {
                        children.push(<RefLink
                            key="link"
                            url={ linkUrl }
                            isOuter
                            isRelatedList={ this.props.isRelatedList }
                        >
                            { this.getInlineValue(this.props.data.value) }
                        </RefLink>);
                    }

                }
                else {
                    if (this.props.recordLink) {
                        // ссылка на запись
                        children.push(<Link
                            key="link"
                            to={ this.props.recordLink }
                            className={ `${ styles.RelativeLink }` }
                            onClick={ this.handleLinkClick }
                        >
                            { this.getInlineValue(this.props.data.value) }
                        </Link>);
                    }
                    else if (this.props.refLink) {
                        // референсная ссылка
                        children.push(<RefLink
                            key="link"
                            url={ this.props.refLink }
                            isRelatedList={ this.props.isRelatedList }
                        >
                            { this.getInlineValue(this.props.data.value) }
                        </RefLink>);
                    }

                }
            }
        }
        else {
            children.push(<span key="value"
                                className={ styles.ForContextMenu + ((this.isHead()) ? (' ' + styles.headerSort) : '') }
                                onContextMenu={ this.handleContextMenu }>
                        { this.getInlineValue(this.props.data.value) }
            </span>);
        }

        if (this.isHead()) {
            const sortIcon = this.isSortedField() ? this.getSortIcon() : null;
            return <TableTitle tooltipText={ this.getInlineValue(this.props.data.value) } gradientClassName={ styles.fadeGradient } sortIcon={ sortIcon }>{ children }</TableTitle>;
        }
        else {
            if (typeof this.props.data.read_access === 'boolean' && !this.props.data.read_access) {
                const { list_titles } = langStore.getTranslate();

                return (
                    <div className={ styles.unavailableData }>
                        <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconEyeOff } } />
                        <div className={ styles.text }>{ list_titles && list_titles.unavailable_data }</div>
                    </div>
                );
            }
            else {
                const isEdited = !!this.props.table
                    && !!this.props.table.editCol
                    && !this.props.table.editCol.read_only
                    && this.props.table.editRow === this.props.id
                    && this.props.table.editCol.attribute === this.props.field;

                return <CellDescription isMobile={ this.props.isMobile } hideTooltip={ isEdited } columnName={ this.props.db_column_name } tooltipText={ this.getInlineValue(this.props.data.value) } gradientClassName={ styles.fadeGradient }>{ children }</CellDescription>;
            }

        }
    };

    render() {
        const { className, columnLabel } = this.props;
        const classes = [];
        if (!this.isHead()) {
            classes.push(styles.hasHover);
        }

        if (className) {
            classes.push(className);
        }

        if (this.props.table.props.isWindow) {
            classes.push(styles.CursorPointer);
        }

        const refFunctionContextMenu = this.props.table.props.isWindow ? this.onContextMenu : this.handleContextMenu;
        const EnhancedClickableBox = this.state.type === 'th' ? pleaseStopTriggeringClicksOnDoubleClick(this.state.type) : this.state.type;
        const dataTest = this.state.type === 'th' ? ATTRIBUTES.tableHeaderCell : ATTRIBUTES.tableCell;
        return (
            <EnhancedClickableBox
                key={ this.props.field }
                onClick={ this.handleClick }
                onDoubleClick={ this.handleDoubleClick }
                onContextMenu={ refFunctionContextMenu }
                className={ classes.join(' ') }
                ref={ this.cellRef }
                data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                data-test-column-title={ columnLabel }
            >
                { this.getChildren() }
                {
                    this.message && <QuickMessage>{ this.message }</QuickMessage>
                }
            </EnhancedClickableBox>
        );
    }
}

