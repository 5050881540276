import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Button from 'components/button';
import { executeScripts } from 'helpers/uiActionsHelper';
import GlobalPortal from 'components/globalPortal';

import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import UiActionState from 'globalState/uiAction';

/**
 * компонент кнопки
 * используется в составе группы кнопок
 *
 * @props item
 * @props isRelatedLinks
 * @props tableName
 * @props recordId
 * @props actionsContextMenu
 * @props isBurgerItem
 * @props afterClick
 */

@observer
export default class UiButton extends React.Component {
    @observable isShowContextMenu = false;
    actionsContextMenu;
    elementRef = React.createRef();
    menuRef = React.createRef();

    constructor(props) {
        super(props);
        this.actionsContextMenu = this.props.actionsContextMenu;
    }

    componentDidUpdate(prevProps) {
        const { isDisabled, disableUiActions } = this.props;
        if (this.props.item.wait_server_response &&
            typeof disableUiActions !== undefined && this.isDisabled() !== disableUiActions) {
            this.setDisabled(this.props.disableUiActions);
        }
        if (isDisabled !== prevProps.isDisabled) {
            this.setDisabled(isDisabled);
        }
        this.setMenuPosition();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleClickOutside);
        if (this.props.item.wait_server_response &&
            typeof this.props.disableUiActions !== undefined && this.isDisabled() !== this.props.disableUiActions) {
            this.setDisabled(this.props.disableUiActions);
        }
        this.setMenuPosition();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleContextMenu = (e) => {
        e.preventDefault();
        const { item } = this.props;
        this.isShowContextMenu = true;
        window.currentUiAction = {
            sys_id: item.sys_id,
        };
    };

    handleClickOutside = (e) => {
        const { current: element } = this.elementRef;
        const { current: menu } = this.menuRef;

        if (!element || !menu) return false;

        if (!element.contains(e.target) && !menu.contains(e.target)) {
            this.isShowContextMenu = false;
        }
    };


    handleClick = (item) => {
        if (this.isDisabled()) {
            return false;
        }
        if (item.wait_server_response) {
            UiActionState.setWaitServerResponse(true);
        }

        const scriptResult = executeScripts(item, this.props.tableName, this.props.recordId);
        if (scriptResult && scriptResult instanceof Promise) {
            scriptResult
                .catch((err) => console.error(err))
                .finally(() => {
                    UiActionState.setWaitServerResponse(false);
                });
        }
        this.isShowContextMenu = false;

        if (this.props.afterClick) {
            this.props.afterClick();
        }
    };


    isDisabled = () => {
        const { item } = this.props;
        const buttonName = `button${ item.sys_id }`;
        return (!!(this.refs[buttonName]
            && this.refs[buttonName].getAttribute('data-loader'))) || item.disabled;
    };


    setDisabled = (condition) => {
        const { item } = this.props;
        const buttonName = `button${ item.sys_id }`;
        if (this.refs[buttonName]) {
            if (condition) {
                this.refs[buttonName].setAttribute('data-loader', true);
            }
            else {
                this.refs[buttonName].removeAttribute('data-loader');
            }
        }
    };

    renderContextMenu = () => {
        if (!this.isShowContextMenu || !this.actionsContextMenu) return null;

        return (
            <GlobalPortal>
                <ul className={ styles.ContextMenu } ref={ this.menuRef }>
                    { this.actionsContextMenu.map((item) => {
                        return (<li key={ item.sys_id }
                                    onClick={ () => this.handleClick(item) }>
                            { item.name }
                        </li>);
                    }) }
                </ul>
            </GlobalPortal>
        );
    };

    setMenuPosition = () => {
        const { current: element } = this.elementRef;
        const { current: menu } = this.menuRef;
        if (!element || !menu) return;

        const offset = 4;
        const dropRect = menu.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;
        const scrollTop = window.pageYOffset;

        // calculate top
        let top;
        if (elementRect.top + elementRect.height + dropRect.height + offset < windowHeight) {
            top = elementRect.top + scrollTop + elementRect.height + offset;
        }
        else if (elementRect.top > dropRect.height + offset) {
            top = elementRect.top + scrollTop - (dropRect.height + offset);
        }
        else {
            const initTop = elementRect.top + elementRect.height + offset;
            top = initTop + scrollTop - (initTop + dropRect.height - windowHeight) - offset;
        }

        // calculate left
        let left = elementRect.left;
        if (elementRect.left + dropRect.width + offset > windowWidth) {
            left = elementRect.left - ((elementRect.left + dropRect.width + offset) - windowWidth);
        }

        menu.style.left = left + 'px';
        menu.style.top = top + 'px';
        menu.style.position = 'absolute';
    };

    renderLink() {
        const { item } = this.props;
        return (
            <div
                className={ styles.item }
                ref={ `button${ item.sys_id }` }
                key={ item.sys_id }
                onContextMenu={ this.handleClickOutside }
                onClick={ () => this.handleClick(item) }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `${ item.page_key }-${ ATTRIBUTES.uiLink }` }
                disabled={ item.disabled }
            >
                { item.name }
            </div>);
    }

    renderButton() {
        const { item } = this.props;
        return (
            <Button
                key={ item.sys_id }
                buttonType={ item.style }
                ref={ `button${ item.sys_id }` }
                onClick={ () => this.handleClick(item) }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `${ item.page_key }-${ ATTRIBUTES.uiButton }` }
                disabled={ item.disabled }
            >
                { item.name }
            </Button>);
    }

    renderBurgerItem() {
        const { item } = this.props;
        return (
            <div
                ref={ `button${ item.sys_id }` }
                key={ item.sys_id }
                onClick={ () => this.handleClick(item) }
                data-test={ this.props['data-test'] }
                disabled={ item.disabled }
            >
                { item.name }
            </div>
        );
    }

    render() {
        const { item } = this.props;
        if (!item) return null;

        if (this.props.isBurgerItem) {
            return this.renderBurgerItem();
        }

        return (
            <div
                ref={ this.elementRef }
                onContextMenu={ this.handleContextMenu }>
                { !this.props.isRelatedLinks && this.renderButton() }
                { this.props.isRelatedLinks && this.renderLink() }
                { this.renderContextMenu() }
            </div>
        );
    }
}
