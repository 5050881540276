import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import styles from './styles.module.scss';
import stylesTabs from 'components/tabs/styles.module.scss';
import apiRequest from 'lib/apiRequest';
import conditionState from 'globalState/conditions/condition';
import langStore from 'globalState/lang';
import RelatedList from 'components/relatedLists/relatedList';
import RelatedListTab from 'components/relatedLists/relatedListTab';
import { helperRedirectReplace } from 'helpers/history';
import { getUrlParams, isEqual, paramsToString } from 'helpers/data';
import { fetchDotWalkList } from 'actions/conditions';
import TabsSlider from 'components/tabs/slider';
import Heading from 'components/heading';
import FormsState from 'globalState/forms';
import DragAndDrop from 'components/dragAndDrop';
import { withRouter } from 'react-router-dom';
import GroupDataState from 'globalState/table/groupData';
import { fetchListGroupsFilter } from 'actions/list';

@withRouter
@observer
export default class RelatedLists extends React.Component {
    @observable active = '';
    @observable data;
    @observable errorMessage = '';
    lastRouteParams = '';
    @observable notEditCondition = '';
    @observable isShow = true;
    @observable isShowRelatedList = {};
    @observable uiActions = {};
    @observable isSliderOn = false;
    @observable isFetchingData = false;
    lastList = {};
    @observable showAttachPopup = false;
    dndDropFuncLink = null;
    @observable isLoadingList = false;
    refActiveTab = React.createRef();

    constructor(props) {
        super(props);
        this.active = props.activeElementsId;
        this.storedPages = {};
        if (!this.props.isRelatedListsDelayed) {
            this.data = props.activeData;
            this.uiActions = props.activeData.ui_actions;
            this.notEditCondition = props.activeData.fixed_condition;
            if (props.activeData.condition) {
                conditionState.parseConditionString(props.activeData.condition, props.activeData.essence);
                conditionState.setRelatedListCondition(props.activeData.sys_id, props.activeData.condition);
                this.storedPages[props.activeData.sys_id] = props.activeData.pagination.page;
            }
        }
        else {
            const defaultList = this.props.elements.find(list => list.sysId === this.active);
            if (defaultList) {
                const parsedSearchParams = getUrlParams(location.search);
                conditionState.parseConditionString(parsedSearchParams.condition, defaultList.related_table_name, defaultList.relatedTableId);
                conditionState.setRelatedListCondition(defaultList.sysId, parsedSearchParams.condition);
                this.storedPages[defaultList.sysId] = parsedSearchParams.page;
            }
        }

        if (props.tableName || props.essence) {
            //window.dynamicFormsGlobals[(props.tableName || props.essence) + '.relatedLists'] = this;
            FormsState.setRelatedLists(this);
        }
    }

    componentDidMount() {
        this.updateDataRelatedList();
        // const { elements } = this.props;
        // if (elements && elements.length > 0) {
        //     this.setFirstShowRelatedLists(elements);
        // }
        // if (this.props.isRelatedListsDelayed) {
        //     this.changeActiveLists(this.active)();
        // }
    }

    updateDataRelatedList() {
        const { elements } = this.props;
        if (elements && elements.length > 0) {
            this.setFirstShowRelatedLists(elements);
        }
        if (this.props.isRelatedListsDelayed) {
            this.changeActiveLists(this.active)();
        }
    }

    componentDidUpdate(prevProps) {
        const { location, record } = this.props;
        if (!isEqual(location.search, prevProps.location.search)) {
            const parsedSearchParams = getUrlParams(location.search);
            const prevParsedSearchParams = getUrlParams(prevProps.location.search);
            const relatedListsCondition = conditionState.getRelatedListsCondition();
            if (parsedSearchParams.condition && parsedSearchParams.condition !== prevParsedSearchParams.condition
                && relatedListsCondition[this.active] !== parsedSearchParams.condition) {
                this.findDataList(parsedSearchParams.condition, parsedSearchParams.page);
            }
        }
        if (record && record.isActionUpdate) {
            this.active = this.props.activeElementsId;
            if (this.props.activeData.length > 0) {
                this.data = this.props.activeData;
                this.uiActions = this.props.activeData.ui_actions;
                this.notEditCondition = this.props.activeData.fixed_condition;
            }
            this.updateDataRelatedList();
        }
    }

    findDataList = (condition, page) => {
        const { elements } = this.props;
        const list = elements.find(el => this.active === el.sysId);
        if (list) {
            this.fetchDataList(page || 1, list.sysId, condition || '');
        }
    };

    fetchUiActions = () => {
        this.props.fetchUiActions();
    };

    componentWillUnmount() {
        conditionState.clearRelatedListsCondition();
    }

    handleCloseAttachPopup = () => {
        this.showAttachPopup = false;
    };

    // Используется для экшенов при вызове окна из скриптов
    handleShowAttachPopup = () => {
        this.showAttachPopup = true;
    };

    @action
    uiHideRelatedLists = () => {
        this.isShow = false;
        const keys = Object.keys(this.isShowRelatedList);
        keys.forEach(key => {
            this.isShowRelatedList[key] = false;
        });
    };

    @action
    uiShowRelatedLists = () => {
        this.isShow = true;
        const keys = Object.keys(this.isShowRelatedList);
        keys.forEach(key => {
            this.isShowRelatedList[key] = true;
        });
    };

    @action
    showRelatedList = (essence) => {
        this.isShowRelatedList[essence] = true;
        this.isShow = true;
    };

    @action
    hideRelatedList = (essence) => {
        const { elements } = this.props;
        const currentList = _.find(elements, list => list.name === essence);
        if (currentList) {
            const relatedLists = _.map(this.isShowRelatedList, (value, key) => ({
                name: key,
                value: value,
            }));
            const filteredList = _.filter(elements, list => {
                const isShowRelatedList = _.find(relatedLists, rList => rList.name === list.name && rList.value);
                return list.name !== essence && isShowRelatedList;
            });
            this.active = filteredList.length ? filteredList[0].sysId : '';
            this.updateDataRelatedList();
        }
        this.isShowRelatedList[essence] = false;
        const keys = Object.keys(this.isShowRelatedList);
        const filterKeys = keys.filter(key => this.isShowRelatedList[key]);
        if (filterKeys.length === 0) {
            this.isShow = false;
        }
    };

    fetchData = async (updateRequired = true, list = this.lastList) => {
        this.lastList = list;
        const { essence, recordId } = this.props;
        if (!recordId || !essence || !list.sysId) {
            return;
        }
        const routeParamsString = essence + recordId + list.sysId;
        const isActionUpdate = this.props.record && this.props.record.isActionUpdate;
        if (this.lastRouteParams === routeParamsString && !isActionUpdate && updateRequired) {
            return;
        }
        if (isActionUpdate && this.props.resetIsActionUpdate) {
            this.props.resetIsActionUpdate();
        }
        this.lastRouteParams = routeParamsString;
        const requestOptions = {};
        const parsedSearchParams = getUrlParams(location.search);
        if (parsedSearchParams.related_view) {
            requestOptions.view = parsedSearchParams.related_view;
        }

        requestOptions.related_list_element_id = list.sysId;
        if (this.props.record && this.props.record.resultData && this.props.record.resultData.form_id) {
            requestOptions.form_id = this.props.record.resultData.form_id;
        }
        this.isFetchingData = true;
        const response = await new apiRequest('GET /switch-related-list/' + essence + '/' + recordId).qs(requestOptions).send();
        const data = response.getData();
        window.s_list.query = data.active_related_grid_data.full_condition;
        this.uiActions = data.active_related_grid_data.ui_actions;
        const relatedListsCondition = conditionState.getRelatedListsCondition();
        const condition = relatedListsCondition[list.sysId] || data.active_related_grid_data.condition;
        const page = this.storedPages[list.sysId];
        window.listGlobal[data.active_related_grid_data.essence] = this;
        window.listGlobal[essence] = this;
        if (condition || (page && page !== data.active_related_grid_data.pagination.page)) {
            this.fetchDataList(page, list.sysId, condition);
        }
        else {
            this.data = data.active_related_grid_data;
            this.notEditCondition = this.data.fixed_condition;
            conditionState.setRelatedListCondition(list.sysId, condition);
            conditionState.parseConditionString(condition, this.data.essence, this.data.sys_id);
            this.fetchListGroupsFilter(this.data.essence, condition);
            this.fetchDotWalk(this.data.sys_id, this.data.columns || []);
            this.isFetchingData = false;
        }
    };

    fetchListGroupsFilter = async (tableName, params) => {
        if (params.condition && params.condition.includes('GROUP')) {
            const response = await fetchListGroupsFilter(tableName, params);
            if (response.isOkStatus) {
                GroupDataState.setData(response.data);
            }
        }
    };

    fetchDataList = async (page = 1, listSysId, condition) => {
        const { essence, recordId } = this.props;
        if (!essence || !recordId) {
            return;
        }
        this.isLoadingList = true;
        conditionState.showLoader = true;
        const requestOptions = {};
        requestOptions.condition = typeof condition !== 'undefined' ? condition : conditionState.getConditionString();
        requestOptions.page = page;
        if (this.props.record && this.props.record.resultData && this.props.record.resultData.form_id) {
            requestOptions.form_id = this.props.record.resultData.form_id;
        }
        const response = await new apiRequest('GET  /list-filter/' + essence + '/' + recordId).qs(requestOptions).send();
        this.data = response.getData();
        this.notEditCondition = this.data.fixed_condition;
        window.s_list.query = this.data.full_condition;
        conditionState.setSortingColumns(this.data.columns || []);
        conditionState.sortingBreadcrumb();
        conditionState.setRelatedListCondition(listSysId, this.data.condition);
        conditionState.parseConditionString(this.data.condition, this.data.essence, this.data.sys_id);
        this.fetchDotWalk(this.data.sys_id, this.data.columns || []);
        this.fetchListGroupsFilter(this.data.essence, { ...requestOptions, condition: this.data.full_condition });
        this.storedPages[listSysId] = page;
        this.isFetchingData = false;
        this.isLoadingList = false;
        conditionState.showLoader = false;
    };

    fetchDotWalk = async (tableId, columns) => {
        if (!tableId) {
            return;
        }
        const params = {
            referenced_table_id: tableId,
        };
        const response = await fetchDotWalkList(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        conditionState.setDotWalkList(data.items || data);
        conditionState.findAndSetAddColumnsDotWalkList(columns);
    };

    handleChangeCondition = (listSysId) => () => {
        this.fetchDataList(1, listSysId, conditionState.getConditionString());
        this.updateLocation({
            condition: conditionState.getConditionString(),
            page: '',
        });
    };

    handleChangePage = (listSysId) => (page) => {
        const relatedListsCondition = conditionState.getRelatedListsCondition();
        const condition = relatedListsCondition[listSysId];
        this.fetchDataList(page, listSysId, condition);
        this.updateLocation({ page: page });
    };

    updateLocation(newParams) {
        const { location } = this.props;
        const parsedSearchParams = getUrlParams(location.search);
        const params = {
            ...parsedSearchParams,
        };
        for (const key in newParams) {
            params[key] = newParams[key];
        }
        helperRedirectReplace(paramsToString(params));
    }

    changeActiveLists = (key) => async (event) => {
        if (event) {
            event.preventDefault();
        }
        const { elements } = this.props;
        if (elements && elements.length > 0) {
            const list = elements.find(el => key === el.sysId);
            this.fetchData(false, list || elements[0]).catch(this.errorFetchData);

            const relatedListsCondition = conditionState.getRelatedListsCondition();
            this.updateLocation({
                condition: relatedListsCondition[list.sysId] || '',
                page: this.storedPages[list.sysId] || '',
            });
        }
        this.active = key;
    };

    @action
    setFirstShowRelatedLists = (elements) => {
        elements.forEach(element => {
            if (typeof this.isShowRelatedList[element.name] === 'undefined') {
                this.isShowRelatedList[element.name] = true;
            }
        });
    };

    onSliderUpdate = (isSliderOn) => {
        this.isSliderOn = isSliderOn;
    };

    renderPopup = () => {
        if (_.isEmpty(this.data)) {
            return null;
        }
        return (
            <DragAndDrop
                onClose={ this.handleCloseAttachPopup }
                show={ this.showAttachPopup }
                attachUrl={ '/import/json' }
                essence={ this.props.match.params.table_name }
                onDropFunc={ (func) => {
                    this.dndDropFuncLink = func;
                } }
                isWaitForRequest
            />
        );
    };

    render() {
        const { related_lists } = langStore.getTranslate();
        const formId = this.props.record && this.props.record.resultData && this.props.record.resultData.form_id;
        if (!this.isShow) {
            return null;
        }

        if (this.errorMessage) {
            return <div>{ this.errorMessage }</div>;
        }

        const names = [];
        const elements = this.props.elements.map((list, index) => {
            let isShow = this.isShowRelatedList[list.name];
            if (isShow === undefined) isShow = true;

            names.push(<RelatedListTab
                key={ list.sysId }
                list={ list }
                active={ this.active }
                ref={ this.active === list.sysId ? this.refActiveTab : null }
                name={ list.name }
                tableName={ this.props.tableName || this.props.essence }
                onChangeActiveLists={ this.changeActiveLists }
                onShowRelatedList={ this.showRelatedList }
                onHideRelatedList={ this.hideRelatedList }
                isShow={ isShow }
            />);

            return this.active === list.sysId ?
                (
                    <RelatedList
                        isFirst={ index === 0 && !this.isSliderOn }
                        key={ list.sysId }
                        data={ this.data }
                        location={ this.props.location }
                        relatedColumnName={ list.related_column_name }
                        recordId={ this.props.recordId }
                        onChangeCondition={ this.handleChangeCondition(list.sysId) }
                        onChangePage={ this.handleChangePage(list.sysId) }
                        isShow={ isShow }
                        notEditCondition={ this.notEditCondition }
                        uiActions={ this.uiActions }
                        recordTableName={ this.props.essence }
                        formId={ formId }
                        disableUiActions={ this.props.disableUiActions }
                        isFetchingData={ this.isFetchingData }
                        isLoadingList={ this.isLoadingList }
                        doFetch={ this.fetchData }
                        id={ list.sysId }
                    />
                )
                : null;
        });

        return (
            <div className={ styles.RelatedLists }>
                <Heading size={ 'h3' }> { related_lists ? related_lists : '' } </Heading>
                <div className={ stylesTabs.RelatedTabs }>
                    <div className={ stylesTabs.TabsHead }>
                        <TabsSlider activeTab={ this.refActiveTab } active={ this.active } onUpdate={ this.onSliderUpdate }>
                            { names }
                        </TabsSlider>
                    </div>
                    { elements }
                </div>
                { this.renderPopup() }
            </div>
        );
    }
}
