import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';


import styles from './styles.module.scss';
import { TableTitleProps } from 'types/components/table/tableTitle/index';
import Hint from 'components/hint';

/**
 * Описание: короткое описание и тултип для заголовков таблицы
 * Праметры:
 * gradientClassName: {required: true,  type: string} - цсс класс, описывает цвет для градиента, скрывающего текст.
 * sortIcon: {required: false,  type: html} - иконка сортировки
 * isActive: {boolean} - вызвано меню для заголовка
 * */

@observer
export default class TableTitleComponent extends React.Component<TableTitleProps> {
    contentRef: React.RefObject<HTMLDivElement> = React.createRef();
    containerRef: React.RefObject<HTMLDivElement> = React.createRef();
    @observable hasOverflow = false;
    timeout: NodeJS.Timeout;

    componentDidMount() {
        this.checkOverflow();
        window.addEventListener('resize', this.checkOverflow);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkOverflow);
    }

    checkOverflow = () => {
        const contentRect = this.contentRef?.current?.getBoundingClientRect();
        const containerRect = this.containerRef?.current?.getBoundingClientRect();
        this.hasOverflow = !!contentRect && !!containerRect && containerRect.width > contentRect.width;
    };

    render() {

        let hint;
        if (this.hasOverflow){
            if (this.props.hint){
                hint = <Hint refParent={ this.contentRef }><div className={ styles.HintTitle }>{ this.props.value }</div>{ this.props.hint }</Hint>;
            } else {
                hint = <Hint refParent={ this.contentRef }>{ this.props.value }</Hint>;
            }
        } else if (this.props.hint){
            hint = <Hint refParent={ this.contentRef }>{ this.props.hint }</Hint>;
        }

        return (
            <div className={ styles.content } >
                <div className={ `${styles.contentBadge} ${this.props.isActive ? styles.active : ''}` }>
                    <div className={ styles.containerWrap } ref={ this.contentRef }>
                        <div className={ styles.container } ref={ this.containerRef }>
                            { this.props.children }
                        </div>
                        { this.hasOverflow &&
                        <div className={ `${ styles.gradient } ${ this.props.gradientClassName }` } /> }
                    </div>
                    { this.props.sortIcon }
                </div>
                { hint }
            </div>
        );
    }
}
