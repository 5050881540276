import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './styles.module.scss';
import { executeScripts } from 'helpers/uiActionsHelper';
import GlobalPortal from '../globalPortal';
import { isEqual } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import IconArrow from "assets/img/icons/chevron-right.svg";

/**
 * Описание: контекстное меню для листов
 * Параметры:
 * items: {required: true, type: array} - массив элементов меню
 * table: {required: true, type: link} - ссылка на родительскую таблицу
 * isHeaderMenu: {required: false, type: boolean} - тип контекстного меню
 * x: {required: true, type: number} - координаты по x, по которым будет показано меню
 * y: {required: true, type: number} - координаты по y, по которым будет показано меню
 */
@observer
export default class ContextMenu extends React.Component {
    @observable items;

    constructor(props) {
        super(props);
        this.items = this.props.items;
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.items, this.props.items)) {
            this.items = this.props.items;
        }
        this.updateMenuPosition();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('scroll', this.handleClickOutside);
        this.updateMenuPosition();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    getRef = (node) => {
        this.node = node;
    };

    handleClickOutside = (e) => {
        const { menu } = this.refs;
        const { onToggleContextMenu } = this.props;

        if (!menu) {
            return false;
        }

        if (!menu.contains(e.target) && onToggleContextMenu) {
            onToggleContextMenu();
        }
    };

    updateMenuPosition = () => {
        const { node, menu, submenu } = this.refs;

        if (!node || !menu) {
            return;
        }

        const offset = 10;
        const popupRect = menu.getBoundingClientRect();
        const windowWidth = document.documentElement.clientWidth;
        const windowHeight = document.documentElement.clientHeight;

        let left = this.props.x;
        let top = this.props.y;

        if (left + popupRect.width > windowWidth) {
            left = left - (left + popupRect.width - windowWidth) - offset / 2;
            menu.style.left = `${ left }px`;
        }
        else {
            menu.style.left = `${ left }px`;
        }

        if (top + popupRect.height > windowHeight + document.documentElement.scrollTop) {
            top = top - (top + popupRect.height - windowHeight - document.documentElement.scrollTop) - offset / 2;
            menu.style.top = `${ top }px`;
        }
        else {
            menu.style.top = `${ top }px`;
        }

        if (submenu) {
            submenu.style.top = `${ this.props.y } px`;
            left = this.props.x + popupRect.width;
            submenu.style.left = `${ left }px`;
        }
    };

    handleExecuteScripts = (item) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { onToggleContextMenu } = this.props;
        if (!item.sub_menu) {
            executeScripts(item, null, null, this.props.rowId, this.props.addParams);
            if (onToggleContextMenu) {
                onToggleContextMenu();
            }
        }
    };

    renderMenu = (items, parentIndex) => {
        const result = [];

        items.forEach((item, index) => {
            const classIndex = parentIndex ? `${ parentIndex }_${ index }` : index;
            result.push(
                <div
                    className={ styles.item }
                    key={ JSON.stringify(item) + index }
                    onClick={ this.handleExecuteScripts(item) }
                    data-test={ `${ ATTRIBUTES.contextMenuItem }_${ classIndex }` }
                >
                    { item.name }
                    { item.sub_menu && item.sub_menu.length > 0 &&
                    <React.Fragment>
                        <span className={ styles.arrow } dangerouslySetInnerHTML={{__html: IconArrow}}/>
                        <div className={ styles.menu }>
                            { this.renderMenu(item.sub_menu, index) }
                        </div>
                    </React.Fragment>
                    }
                </div>,
            );
        });

        return result;
    };

    render() {
        if (!this.props.isShowContextMenu) {
            return null;
        }

        return (
            <div ref="node">
                <GlobalPortal>
                    <div
                        className={ `${ styles.menu }` }
                        ref="menu"
                        style={ {
                            left: `${ this.props.x }px`,
                            top: `${ this.props.y }px`,
                        } }
                        data-test={ ATTRIBUTES.contextMenu }
                    >
                        { this.renderMenu(this.items) }
                    </div>
                </GlobalPortal>
            </div>
        );
    }
}
