import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import sidebarState from 'globalState/sidebarState';

import styles from './styles.module.scss';
import Filter from 'assets/img/icons/filter.svg';
import ChevronRight from 'assets/img/icons/chevron-right.svg';

import Button from 'components/button';
import { copyToClipboard, getUrlParams } from 'helpers/data';
import ContextMenu from 'components/contextMenu';
import { getConditionString } from 'helpers/condition';
import langStore from 'globalState/lang';
import { helperRedirect } from 'helpers/history';
import {
    convertServerTimeToISO,
    getDateValueByString,
    getTimeZoneDate, getUserTimezone,
} from 'helpers/getUserTimeZone';
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_TIME_FORMAT } from 'constants/dateTime';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { getFormatFromPHP } from "helpers/date";
import moment from "moment-timezone";
import _ from 'lodash';
import { saveFavorite } from 'actions/menu';
import { LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND, LINK_UNAVAILABLE } from 'constants/strings';
import { getLinkDisplayValue } from 'helpers/form';

@observer
export default class Breadcrumbs extends React.Component {
    @observable itemSelectForRemove = '';
    @observable dragged = null;
    @observable isShowContextMenu = false;
    @observable menuCoordinates = {
        x: 0,
        y: 0,
    };
    @observable forCopy = '';

    constructor(props) {
        super(props);

        document.addEventListener('dragover', this.onDragOver);
        document.addEventListener('drop', this.onDrop);
        document.addEventListener('dragend', this.onDragEnd);
    }

    componentWillUnmount() {
        document.removeEventListener('dragover ', this.onDragOver);
        document.removeEventListener('drop ', this.onDrop);
        document.removeEventListener('dragend ', this.onDragEnd);
    }

    handleForRemove = (id) => () => {
        this.itemSelectForRemove = id;
    };

    handleLeaveForRemove = () => {
        this.itemSelectForRemove = '';
    };

    handleRemove = (id) => () => {
        this.props.onRemoveCondition(id);
    };

    handleClickAll = () => {
        this.props.conditionState.clearData();
        const { onClickAll } = this.props;
        if (onClickAll) {
            onClickAll();
        }
    };

    onClick = () => {
        if (this.props.onClickHandler) {
            this.props.onClickHandler();
        }
    };


    onDragStart = (item) => () => {
        return this.dragged = item;
    };


    onDragEnd = () => {
        this.dragged = null;
    };

    onDragOver = (e) => {
        e.preventDefault();
    };

    getUrl = (isHost = true, id = '') => {
        const { location, conditionState } = this.props;
        const url = new URL(location.pathname, window.location.origin);
        if (id !== 'All') {
            let conditionString = conditionState.getConditionString();
            if (this.forCopy) {
                const filterFields = conditionState.getFilterFields().filter(row => row.getId() === this.forCopy);
                const sortingFields = conditionState.getSortingFields().filter(row => row.getId() === this.forCopy);
                const groupingFields = conditionState.getGroupingFields().filter(row => row.getId() === this.forCopy);
                conditionString = getConditionString(filterFields, sortingFields, groupingFields);
            }
            url.searchParams.set('condition', conditionString);
        }
        return (isHost ? url.hostname : '') + url.pathname + url.search;
    };

    onDrop = async (e) => {
        const { conditionState } = this.props;
        if (this.isFavoriteDropArea(e.target) && this.dragged) {
            const label = this.getLabel([
                ...conditionState.getFilterFields(),
                ...conditionState.getSortingFields(),
                ...conditionState.getGroupingFields(),
            ]);

            const response = await saveFavorite({
                'label': label,
                'url': this.getUrl(false, this.dragged.getId ? this.dragged.getId() : this.dragged.id),
            });
            if (response.isOkStatus) {
                sidebarState.fetchFavoritesList(false).catch(console.error);
            }
        }
    };

    getLabel = (item, delimeter = ' ', parentText = '') => {
        const { filter_titles } = langStore.getTranslate();
        const { tableName } = this.props;
        let mainText = parentText ? '' : tableName + ': All';
        const draggedID = this.dragged.getId ? this.dragged.getId() : this.dragged.id;
        if (draggedID === 'All') {
            return mainText;
        }
        if (!item || (Array.isArray(item) && item.length === 0)) {
            return '';
        }
        if (Array.isArray(item)) {
            item.forEach(it => {
                mainText += this.getLabel(it, ' ', mainText);
            });
            return mainText;
        }
        if (item.getId() === 'All') {
            return mainText;
        }
        if (item.getId().includes('sorting')) {
            return mainText + ' > ' + item.getField().dot_walking_attribute + delimeter + 'order by ' + (item.getDirection().value || item.getDirection());
        }

        if (item.getId().includes('grouping')) {
            return mainText + ' > ' + filter_titles.group + delimeter + item.getField().dot_walking_attribute;
        }
        if (Array.isArray(item.getValue())) {
            const arrValue = _.map(item.getValue(), val => {
                const nullValue = val.display_value === null ? 'null' : val.display_value;
                return nullValue || val;
            });
            return mainText + ' > ' + (item.getField().column_title || item.getField().dot_walking_attribute) + delimeter +
                (item.getOperator().database_value || item.getOperator()) + delimeter + arrValue;
        }
        return mainText + ' > ' + (item.getField().column_title || item.getField().dot_walking_attribute) + delimeter +
            (item.getOperator().database_value || item.getOperator()) + delimeter +
            (item.getValue().display_value || item.getValue());
    };

    isFavoriteDropArea = (node) => {
        while (node && node.nodeType === document.ELEMENT_NODE) {
            if (node.classList.contains('favoriteDropArea')) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    };

    isFiltering = (item) => {
        return item.getId() && item.getId().includes('filter');
    };

    isSorting = (item) => {
        return item.getId() && item.getId().includes('sorting');
    };

    isGrouping = (item) => {
        return item.getId() && item.getId().includes('grouping');
    };

    handleToggleContextMenu = () => {
        if (this.isShowContextMenu) {
            this.forCopy = '';
        }
        this.isShowContextMenu = !this.isShowContextMenu;
    };

    handleContextMenu = (link) => (e) => {
        e.preventDefault();
        this.forCopy = link;
        this.menuCoordinates.x = e.pageX;
        this.menuCoordinates.y = e.pageY;
        this.handleToggleContextMenu();
    };

    handleGoTo = (id, isDisabled) => (e) => {
        const { location }= this.props;
        const params = getUrlParams(location.search);
        e.preventDefault();
        if (isDisabled || params.is_fixed) {
            return;
        }
        this.forCopy = id;
        helperRedirect(this.getUrl(false));
    };

    renderContextMenu = () => {
        if (!this.isShowContextMenu) {
            return null;
        }

        const menu = [
            {
                name: 'Copy',
                reactScript: copyToClipboard,
            },
        ];
        return (
            <ContextMenu
                items={ menu }
                x={ this.menuCoordinates.x }
                y={ this.menuCoordinates.y }
                isShowContextMenu={ this.isShowContextMenu }
                onToggleContextMenu={ this.handleToggleContextMenu }
                addParams={ this.getUrl(true, this.forCopy) }
            />
        );
    };

    renderNotEditCondition = () => {
        const { notEditCondition } = this.props;
        if (!notEditCondition) {
            return null;
        }
        return (
            <React.Fragment key={ 'notEdit' }>
                <li draggable="true" onDragStart={ this.onDragStart('notEdit') }>
                    <div
                        className={ styles.BreadcrumbsLinkDisabled }
                        onClick={ this.handleClickAll }
                        data-test={ ATTRIBUTES.conditionFilterAllBreadcrumb }
                    >
                        <span title={ notEditCondition }>{ notEditCondition }</span>
                    </div>
                </li>
            </React.Fragment>
        );
    };

    renderFilteringItems = () => {
        return this.props.filterFields.map((item) => ( this.renderItem(item)));
    };

    renderChevronItem = (item) => {
        if (this.props.readOnly || item.getField().disabled || item.getDisabled()) {
            return (
                <div className={ styles.BreadcrumbsChevronDisabled }
                     dangerouslySetInnerHTML={ { __html: ChevronRight } }
                />
            );
        }
        return (
            <div
                className={ styles.BreadcrumbsChevron }
                onMouseEnter={ this.handleForRemove(item.getId()) }
                onMouseLeave={ this.handleLeaveForRemove }
                onClick={ this.handleRemove(item.getId()) }
                dangerouslySetInnerHTML={ { __html: ChevronRight } }
            />
        );
    };

    renderSortingItems = () => {
        return this.props.sortingFields.map((item) => ( this.renderItem(item)));
    };

    renderGroupingItems = () => {
        return this.props.groupingFields.map((item) => ( this.renderItem(item)));
    };

    renderItem = (item) => {
        if (!item.getField()) {
            return null;
        }
        const { location }= this.props;
        const params = getUrlParams(location.search);
        const linkClassname = styles.BreadcrumbsLink + (item.getId() === this.itemSelectForRemove ? ` ${ styles.ForRemove }` : '');
        let dataTest;
        if (this.isFiltering(item)){
            dataTest = ATTRIBUTES.conditionFilterFilteringBreadcrumb;
        }
        if (this.isSorting(item)){
            dataTest = ATTRIBUTES.conditionFilterSortingBreadcrumb;
        }
        if (this.isGrouping(item)){
            dataTest = ATTRIBUTES.conditionFilterGroupingBreadcrumb;
        }
        return (
            <React.Fragment key={ item.getId() }>
                <li>
                    { this.renderChevronItem(item) }
                </li>
                <li
                    draggable="true"
                    onDragStart={ this.onDragStart(item) }
                    onContextMenu={ this.handleContextMenu(item.getId()) }
                    onClick={ this.handleGoTo(item.getId(), item.getDisabled()) }
                >
                    <div
                        className={ this.props.readOnly || item.getDisabled() || params.is_fixed
                            ? styles.BreadcrumbsLinkDisabled : linkClassname }
                        data-test={ dataTest }
                    >
                        <BreadcrumbsLink
                            item={ item }
                            delimeter=" "
                            tableId={ this.props.tableId }
                            data={ this.props.data }
                        />
                    </div>
                </li>
            </React.Fragment>
        );
    };

    renderAll = () => {
        const { location } = this.props;
        const { filter_titles } = langStore.getTranslate();
        if (this.props.notEditCondition) {
            return null;
        }
        const params = getUrlParams(location && location.search);
        const all = this.props.readOnly || params.is_fixed
            ? (
                <div
                    className={ styles.BreadcrumbsOperatorDisabled }
                    data-test={ ATTRIBUTES.conditionFilterAllBreadcrumb }
                >
                    { filter_titles && filter_titles.main_breadcrumb }
                </div>
            )
            : (
                <div
                    className={ styles.BreadcrumbsLink }
                    onClick={ this.handleClickAll }
                    data-test={ ATTRIBUTES.conditionFilterAllBreadcrumb }
                    onContextMenu={ this.handleContextMenu('All') }
                >
                    { filter_titles && filter_titles.main_breadcrumb }
                </div>
            );
        return (
            <li draggable="true" onDragStart={ this.onDragStart({ id: 'All' }) }>
                { all }
            </li>
        );
    };

    render() {
        const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');
        return (
            <div className={ styles.Breadcrumbs }>
                <Button
                    hint={ system_buttons_hints?.condition_builder }
                    className={ styles.Filter }
                    buttonType='icon'
                    onClick={ this.onClick }
                    key="button"
                    svg={ Filter }
                    data-test={ ATTRIBUTES.conditionFilterIconButton }
                />

                <ul className={ styles.BreadcrumbsList } key="list">
                    { this.renderAll() }
                    { this.renderNotEditCondition() }
                    { this.renderFilteringItems() }
                    { this.renderSortingItems() }
                    { this.renderGroupingItems() }
                </ul>
                { this.renderContextMenu() }
            </div>
        );
    }
}


@observer
class BreadcrumbsLink extends React.Component {
    isSameOptions = () => {
        const { item } = this.props;
        return item.getOperator() && [
            'SAMEAS',
            'NSAMEAS',
            'GT_FIELD',
            'LT_FIELD',
            'GT_OR_EQUALS_FIELD',
            'LT_OR_EQUALS_FIELD',
        ].includes(item.getOperator().database_value);
    };

    isSorting = (item) => {
        return item.getId() && item.getId().includes('sorting');
    };

    isGrouping = (item) => {
        return item.getId() && item.getId().includes('grouping');
    };

    getArrayValue = (value, result) => {
        let resultValue = result;
        const { item } = this.props;
        const opts = item._valueOpts;
        value.forEach(v => {
            if (Array.isArray(v)) {
                resultValue = this.getArrayValue(v, resultValue);
            } else {
                let displayValue;
                if ([LINK_NOT_FOUND, LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(v.reference_state)){
                    displayValue = getLinkDisplayValue(v, true);
                } else if (v.display_value !== undefined){
                    displayValue = v.display_value;
                } else if (Array.isArray(opts) && opts.length > 0 && typeof v === 'string' && v.search(/^opt:/) === 0){
                    displayValue = this.lookupOptsDisplayValue(v);
                } else {
                    displayValue = this.getValue(v);
                }
                resultValue += !resultValue ? v.value || displayValue : `, ${ v.value || displayValue }`;
            }
        });
        return resultValue;
    };

    lookupOptsDisplayValue = (value) => {
        const { item } = this.props;
        let resultValue = value;
        const opts = item._valueOpts;
        const opt = Array.isArray(opts) && opts.find((opt) => (opt.database_value === value));
        resultValue = opt && opt.display_value;
        return resultValue;
    };

    getValue = (value) => {
        const { data, item } = this.props;
        const { bool } = langStore.getTranslate();
        if (!value) {
            return '';
        }
        if (item.getOperator() && item.getOperator().database_value && this.isSameOptions()) {
            if (value && value.display_value) {
                return value.display_value;
            }
            const filteredData = data.find(field => field.dot_walking_attribute === value);
            return filteredData ? filteredData.column_title : value;
        }
        let resultValue = '';
        const opts = item._valueOpts;
        if ([LINK_NOT_FOUND, LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(value.reference_state)){
            resultValue = getLinkDisplayValue(value, true);
        } else if (Array.isArray(value)) {
            resultValue = this.getArrayValue(value, resultValue);
        } else if(Array.isArray(opts) && opts.length > 0 && typeof value === 'string' && value.search(/^opt:/) === 0){
            resultValue = this.lookupOptsDisplayValue(value, item);
        } else if (typeof value.display_value !== 'undefined') {
            resultValue = value.display_value;
        } else if (item.getField().column_type === 'boolean') {
            resultValue = (value === '1') ? (bool && bool.yes) : (bool && bool.no);
        } else if (item.getField().column_type === 'id') {
            resultValue = value.record_title;
        } else if (item.getField().column_type === 'datetime') {
            const validateParams = item.getField().validate;
            const format = getFormatFromPHP(validateParams.format);
            const isValidDate = moment(value, DEFAULT_DATE_TIME_FORMAT, true).isValid();
            const excludeOperators = ['LIKE', 'NOTLIKE', 'YEAR_IS', 'WEEK_IS', 'QUARTER_IS', 'DAY_IS', 'HOUR_IS', 'DAY_OF_WEEK_IS', 'MONTH_IS'];
            if (excludeOperators.includes(item.getOperator().database_value) || !isValidDate) {
                resultValue = value;
            } else {
                resultValue = getDateValueByString(value, getUserTimezone(), format);
            }
        } else if (item.getField().column_type === 'time') {
            const time = convertServerTimeToISO(value);
            resultValue = getTimeZoneDate(time).format(DEFAULT_TIME_FORMAT);
        } else {
            resultValue = value;
        }
        return resultValue;
    };

    render() {
        const { item, delimeter, data } = this.props;
        const { filter_titles } = langStore.getTranslate();
        if (!item) {
            return null;
        }
        let value;
        if (this.isSorting(item)) {
            const direction = item.getDirection().database_value || item.getDirection();
            value = (item.getDisplayValue().is_dwa ? '... ' : '')
                + (item.getDisplayValue().display_column_name ? item.getDisplayValue().display_column_name : item.getField().column_title)
                + delimeter + direction;
        } else  if (this.isGrouping(item)) {
            value = (item.getDisplayValue().is_dwa ? '... ' : '')
                + filter_titles.group + delimeter +(item.getDisplayValue().display_column_name ? item.getDisplayValue().display_column_name : item.getField().column_title);
        } else {
            let columnTitle = item.getField().column_title;
            let refFieldDBName,
                dotWalkLevels = [];
            //Если используется dotwalking, находим имя reference колонки (крайней слева)
            if (item.getField().dot_walking_attribute && item.getField().dot_walking_attribute.includes('.')) {
                dotWalkLevels = item.getField().dot_walking_attribute.split('.');
                refFieldDBName = dotWalkLevels[0];
            }
            const refField = refFieldDBName ? data.find((elem) => elem.dot_walking_attribute === refFieldDBName) : null;
            if (refField) {
                const refFieldName = refField.column_title.charAt(0).toUpperCase() + refField.column_title.slice(1);
                const fieldsDelimiter = dotWalkLevels.length > 2 ? ' - ' : ' ';
                if (refFieldName) {
                    columnTitle = `${ refFieldName }${ fieldsDelimiter }${ columnTitle }`;
                }
            }
            value = columnTitle
                + delimeter + (item.getOperator().display_value || item.getOperator()) +
                delimeter + (this.getValue(item.getValue()));
        }

        return <span title={ value }>{ value }</span>;
    }
}
