import * as React from 'react';
import { observer } from 'mobx-react/index';
import GlobalPortal from 'components/globalPortal';
import styles from './styles.module.scss';
import { isMedia } from 'helpers/html';

/**
 * Описание:
 * Обертка для модалки.
 * появляется во весь экран.
 * фризит экран когда появляется.
 *
 * Параметры:
 * onMount
 */


@observer
export default class ModalWrapper extends React.Component {
    refModal = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.freezeBodyScroll(true);

        const { onMount } = this.props;
        if (onMount) {
            onMount();
        }
    }

    componentWillUnmount() {
        this.freezeBodyScroll(false);
    }

    freezeBodyScroll = (boolean) => {
        const { body } = document;
        const isMobile = isMedia('sm');

        if (boolean) {
            body.classList.add(styles.Freeze);

            if(isMobile && body.style.position !== 'fixed'){
                const scrollY = window.scrollY;
                body.style.position = 'fixed';
                body.style.top = `-${scrollY}px`;
            }
        }
        else {
            const wasOpened = body.classList.contains(styles.Freeze);
            body.classList.remove(styles.Freeze);

            if(isMobile){
                const scrollY = body.style.top;
                body.style.position = '';
                body.style.top = '';
                if(wasOpened){
                    window.scrollTo(0, parseInt(scrollY || '0') * -1);
                }
            }
        }
    };

    render() {
        return (
            <GlobalPortal>
                <div className={ styles.Modal } ref={ this.refModal }>
                    { this.props.children }
                </div>
            </GlobalPortal>
        );
    }
}
