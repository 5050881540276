import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';

/**
 * Описание: Заголовок страницы
 * Параметры:
 * size(type: string) - размеры заголовка. h1, h2, h3
 * isRegular(type: boolean) - если начертание не жирное
 * noMargin(type: boolean) - если нет отсупа снизу
 * className - дополнительные классы стилей
 */

@observer
export default class Heading extends React.Component {
    render() {
        const { children, size, isRegular, noMargin, className, isNotStylized } = this.props;

        let classNames = [isNotStylized ? '' : styles.Heading];
        switch (size) {
            case 'h1':
                classNames.push(styles.sizeH1);
                break;
            case 'h2':
                classNames.push(styles.sizeH2);
                break;
            case 'h3':
                classNames.push(styles.sizeH3);
                break;
            case 'h4':
                classNames.push(styles.sizeH4);
                break;
            default:
                break;
        }
        if(!isRegular) {
            classNames.push(styles.isBold);
        }
        if(!noMargin) {
            classNames.push(styles.hasMargin);
        }
        if(className){
            classNames.push(className);
        }
        classNames = classNames.join(' ');

        return (
            <div data-test={ this.props['data-test'] } className={ classNames }>
                { children }
            </div>
        );
    }
}
