import { observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

class UserElevateState {
    @observable private _isElevate = false;
    private _interval;

    constructor() {
        const elevateRoles = (window as any).localStorage.getItem('elevateRoles');
        if (elevateRoles) {
            const elevateRolesOn = _.filter(JSON.parse(elevateRoles), value => value === 'on');
            if (elevateRolesOn.length > 0) {
                this._isElevate = true;
            }
        }
    }

    getIsElevate(): boolean {
        return this._isElevate;
    }

    setIsElevate(value: boolean) {
        this._isElevate = value;
    }

    checkElevateStatus = (): void => {
        this._interval = setInterval(() => {
            const elevateRoles = (window as any).localStorage.getItem('elevateRoles');
            if (!elevateRoles) {
                return;
            }
            const elevateRolesOn = _.filter(JSON.parse(elevateRoles), value => value === 'on');
            if (elevateRolesOn.length === 0) {
                return;
            }
            const elevateRolesTime = (window as any).localStorage.getItem('elevateRolesTime');
            const elevateRolesTimeMoment = moment(Number(elevateRolesTime), 'x');
            const now = moment();
            const duration = moment.duration(now.diff(elevateRolesTimeMoment));
            if (duration.hours() > 0) {
                (window as any).localStorage.removeItem('elevateRoles');
                (window as any).localStorage.removeItem('elevateRolesTime');
                this._isElevate = false;
                clearInterval(this._interval);
            }
        }, 10000);
    }
}

export const userElevateState = UserElevateState;

export default new UserElevateState();
