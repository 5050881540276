import * as React from 'react';
import Container from 'components/container';
import WidgetComponent from 'components/widget';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import Select from 'components/dynamicForms/view/field/select';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import langStore from 'globalState/lang';

const special = {
    values: [
        {
            database_value: '1',
            display_value: 'String input with styles',
        },
        {
            database_value: '2',
            display_value: '2 string input',
        },
        {
            database_value: '3',
            display_value: '2 string input (ex 2)',
        },
        {
            database_value: '4',
            display_value: 'String input',
        },
        {
            database_value: '5',
            display_value: 'Select',
        },
        {
            database_value: '6',
            display_value: 'CodeMirror',
        },
        {
            database_value: '7',
            display_value: 'Reference',
        },
        {
            database_value: '8',
            display_value: 'Conditions',
        },
        {
            database_value: '9',
            display_value: 'Template',
        },
        {
            database_value: '10',
            display_value: 'List',
        },
        {
            database_value: '11',
            display_value: 'Chart',
        },
        {
            database_value: '12',
            display_value: 'DateTimeInput',
        },
        {
            database_value: '13',
            display_value: 'DaysOfWeek',
        },
        {
            database_value: '14',
            display_value: 'Duration',
        },
        {
            database_value: '15',
            display_value: 'Announcement',
        },
        {
            database_value: '16',
            display_value: 'Dropdown',
        },
        {
            database_value: '17',
            display_value: 'Cards list',
        },
        {
            database_value: '18',
            display_value: 'Activity Feed',
        },
        {
            database_value: '19',
            display_value: 'Attachments',
        },
        {
            database_value: '20',
            display_value: 'Breadcrumbs',
        },
        {
            database_value: '21',
            display_value: 'AuthFull',
        },
        {
            database_value: '22',
            display_value: 'AuthHeader',
        },
        {
            database_value: '25',
            display_value: 'Test data in data',
        },
    ],
};

const examples = [
    {
        widgetId: '1',
        template: '<div class="test"><stringinput model="data.input" class="input"></stringinput></div><h1>{data.input}</h1>',
        styles: '.test {' +
            'width: 400px;' +
            '.input { width: 100px; }' +
            '}',
        clientScript: ';(() => { const lastname = s_widget.getFieldValue(\'lastname\') || \'\'; const firstname = s_widget.getFieldValue(\'firstname\') || \'\'; s_widget.setFieldValue(\'fullname\', lastname + \' \' + firstname) })();',
    },
    {
        widgetId: '2',
        template: '<stringinput model="data.lastname" event-change=";(() => { const lastname = s_widget.getFieldValue(\'lastname\') || \'\'; const firstname = s_widget.getFieldValue(\'firstname\') || \'\'; s_widget.setFieldValue(\'fullname\', lastname + \' \' + firstname) })();"></stringinput>' +
            '<stringinput model="data.firstname" event-change=";(() => { const lastname = s_widget.getFieldValue(\'lastname\') || \'\'; const firstname = s_widget.getFieldValue(\'firstname\') || \'\'; s_widget.setFieldValue(\'fullname\', lastname + \' \' + firstname) })();"></stringinput>' +
            '<h1>{data.fullname}</h1>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '3',
        template: '<stringinput model="data.lastname" event-change="s_widget_custom.updateFullname();"></stringinput>' +
            '<stringinput model="data.firstname" event-change="s_widget_custom.updateFullname();"></stringinput>' +
            '<h1>{data.fullname}</h1>',
        styles: '',
        clientScript: ';(() => { window.s_widget_custom = window.s_widget_custom || {}; window.s_widget_custom.updateFullname = function() { const lastname = s_widget.getFieldValue(\'lastname\') || \'\'; const firstname = s_widget.getFieldValue(\'firstname\') || \'\'; s_widget.setFieldValue(\'fullname\', lastname + \' \' + firstname) } })();',
    },
    {
        widgetId: '4',
        template: '<div>' +
            '<stringinput model="data.input" label="Поле для ввода значения"></stringinput>' +
            '<h1>Значение: {data.input}</h1>' +
            '</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '5',
        template: '<select model="data.select" options=\'[{ "database_value": "1", "display_value": "Test" }, { "database_value": "2", "display_value": "Test 2" }]\'></select>' +
            '<div>{data.select}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '6',
        template: '<codemirror model="data.mirror"></codemirror>' +
            '<div>{data.mirror}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '7',
        template: '<reference model="data.reference" data-table="itsm_incident"></reference>' +
            '<div>{data.reference}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '8',
        template: '<conditions model="data.conditions" data-table="itsm_incident" data-table_id="155964310500000569"></conditions>' +
            '<div>{data.conditions}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '9',
        template: '<template model="data.template" data-table="itsm_incident" data-table_id="155964310500000569"></template>' +
            '<div>{data.template}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '10',
        template: '<list model="data.list" data-table="itsm_incident" data-table_id="155964310500000569"></list>' +
            '<div>{data.list}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '11',
        template: '<chart model="data.chart" data-table="defect" data-column="subject" data-chart_type="pie"></chart>' +
            '<div>{data.chart}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '12',
        template: '<datetimeinput model="data.datetime" data-pickertype="datetime"></datetimeinput>' +
            '<div>{data.datetime}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '13',
        template: '<daysofweek model="data.daysofweek"></daysofweek>' +
            '<div>{data.daysofweek}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '14',
        template: '<duration model="data.duration"></duration>' +
            '<div>{data.duration}</div>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '15',
        template: '<PreviewListWidget tableName="itsm_incident" view="Widget" elementCount="2" subjectColumn="subject" dateColumn="sys_created_at" ' +
            'stateColumn="state" wrap="true" rowStyle="separated"><ListHeader><div>Привет</div></ListHeader></PreviewListWidget>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '158315449711686995',
        template: `
            <dropdown_widget id="some_dd_id" isOpened="true">
                <dropdown_widget_items>
                    <span event-click="window.s_widget_custom.alert()">Test 1</span>
                    <span><a href="/test" target="_blank">Test 2</a></span>
                </dropdown_widget_items>
            </dropdown_widget>
        `,
        styles: '',
        clientScript: `
            ;
            (() => {
                window.s_widget_custom = window.s_widget_custom || {};
                const widgetApiId = "some_dd_id";
                
                window.s_widget_custom.alert = function() {
                    alert(123);
                };
                window.s_widget_custom.showDropdown = () => {
                    window.s_widget_api[widgetApiId].showDropdown();
                }
            })();
        `,
    },
    {
        widgetId: '17',
        template: '<getlist table_name="sys_service_portal"><card></card></getlist>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '18',
        template: '<activityfeed table_name="itsm_incident" sys_id="157322330317307618"></activityfeed>', //157322330317307618    157322284211919857
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '19',
        template: '<attachment model="data.attachments"></attachment>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '20',
        template: '<breadcrumbs></breadcrumbs>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '21',
        template: '<authfull redirectTo="/" isRegistration="true" isSwitchLang="true" isRememberMe="false" isResetPassword="true"></authfull>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '22',
        template: '<authheader profileURL="/portal/cabinet"></authheader>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '23',
        template: '<listitems tableName="itsm_incident" condition="" listView="Widget"' +
            ' choiceConditionName="state" dateConditionName="sys_created_at" perPage="25" displayColumnNumber="2"></listitems>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '24',
        template: '<categorywidget nodeId="158766114011088368" categorySubject="name" categoryDescription="short_description" ' +
            'itemDescription="vendor" itemSubject="city"></categorywidget>',
        styles: '',
        clientScript: '',
    },
    {
        widgetId: '25',
        template: '<div class="{data.FormUrl.url}"><div>{data.test.test}<div>{data.test2}</div></div></div>',
        styles: '',
        clientScript: `;
            window.s_widget.setFieldValue('test', { test: 'test1' });
            window.s_widget.setFieldValue('test2', 'test2');
            window.s_widget.setFieldValue('FormUrl', { url: 'test1' });
            `,
    },
];

@observer
class Widget extends React.Component {
    @observable current = '25';

    @action
    handleChangeExample = (e) => {
        if (e.value) {
            this.current = e.value.database_value;
        }
    };

    renderView = () => {
        const widgetEx = examples.find(ex => ex.widgetId === this.current);
        if (!widgetEx) {
            return null;
        }
        return (
            <div>
                <br />
                <h3>
                    Пример:
                    <br />
                    { widgetEx.template }
                </h3>
                <br />
                <WidgetComponent
                    template={ widgetEx.template }
                    client_script={ widgetEx.clientScript }
                    widget_instance_id={ widgetEx.widgetId }
                    styles={ widgetEx.styles }
                    dictionary={ langStore.getTranslate() }
                />
            </div>
        );
    };

    render() {
        return (
            <Container>
                <Select special={ special } value={ this.current } onChange={ this.handleChangeExample } />
                { this.renderView() }
            </Container>
        );
    }
}


export default ErrorWrapperHoc(Widget);
