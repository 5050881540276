import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';
import styles from 'components/login/styles.module.scss';
import FormServerErrors from 'components/formItems/serverErrors';
import LoginInput from 'components/login/input';
import LoginButton from 'components/login/button';
import SmartTitle from 'components/smartTitle';
import Select from 'components/login/select';
import Help from 'components/login/help';
import userState from 'globalState/user/index';
import langStore from 'globalState/lang/index';
import brandingState from 'globalState/branding/index';
import FormValidator from 'helpers/formValidator';
import { smartRedirect } from 'helpers/history';
import AuthHOC from 'helpers/hoc/authHoc';
import { ATTRIBUTES } from 'constants/attributesForTests';
import {ROUTES} from "constants/routers";
import {AuthPagesProps, FormFields} from "types/page/loginPage";
import {SignupData} from "types/globalState/user";

@observer
class SignUpPage extends React.Component<AuthPagesProps> {
    @observable formFields: FormFields = {
        username: FormValidator.createFormFieldObj(),
        password: FormValidator.createFormFieldObj(),
        email: FormValidator.createFormFieldObj(),
    };
    @observable serverError = '';
    @observable isFetching = false;

    // Validate form and submit
    validateAndSubmit = async () => {
        if (this.isFetching) return;
        const { langData } = this.props;
        this.isFetching = true;

        const fv = new FormValidator(this.formFields);
        const promises: Array<Promise<void>> = [];

        // Validate login
        promises.push(
            fv.validateField(this.formFields.username, () => {
                //if (val.length < 3) return `Login must contain not less than 3 symbols`;
            }),
        );

        // Validate password
        promises.push(
            fv.validateField(this.formFields.password, () => {
                //if (val.length < 3) return `Password must contain not less than 3 symbols`;
            }),
        );

        // Validate email
        promises.push(
            fv.validateField(this.formFields.email, () => {
                // if (!validateEmail(val)) return 'Invalid email';
            }),
        );

        // Await for validations
        await Promise.all(promises);

        const isValid = fv.isFieldsValid();

        if (!isValid) {
            this.isFetching = false;
            return false;
        }

        try {
            const data = fv.getFieldsData();
            (data as SignupData).language = langData.langValue as string;
            const result = await userState.signup((data as SignupData));
            if (result) {
                // Success
                smartRedirect('/');
            }
        }
        catch (e) {
            const errorsParsed = fv.applyServerValidationErrors(e.response.data);
            if (!errorsParsed) this.serverError = fv.serverErrorMessage || e.message;
            this.isFetching = false;
        }
    };

    handleSwitchLang = (lang) => {
        const { onFetchTranslate } = this.props;

        this.serverError = '';
        for (const field in this.formFields) {
            if (this.formFields.hasOwnProperty(field)) {
                this.formFields[field].errorMessage = '';
            }
        }
        if (onFetchTranslate) {
            onFetchTranslate(lang);
        }
    };

    handleValueChange = (e) => {
        const { name, value } = e.target;
        this.formFields[name].value = value;
    };

    onFormKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.validateAndSubmit().catch(console.error);
        }
    };

    render() {
        const { placeholders, sign_up_page, help } = langStore.getTranslate();
        const { langData } = this.props;

        return (
            <div className={ styles.Form } onKeyPress={ this.onFormKeyPress }>
                <SmartTitle hidden>{ sign_up_page?.title }</SmartTitle>
                <Select
                    onChange={ this.handleSwitchLang }
                    databaseValue={ langData.langValue }
                    options={ langData.langSpecial }
                />
                <Help title={ help?.title } children={ help?.content } />
                <div className={ styles.Logo }>
                    <img src={ brandingState.getPlatformLogo() } />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders?.email }
                        name="email"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.email.errorMessage }
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders?.username }
                        name="username"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.username.errorMessage }
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders?.password }
                        type="password"
                        name="password"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.password.errorMessage }
                        isDisabled={ this.isFetching }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginButton
                        isLoading={ this.isFetching }
                        onClick={ this.validateAndSubmit }
                        buttonType='primary'
                        data-test={ ATTRIBUTES.signUpButton }
                        disabled={ !this.formFields.email.value || !this.formFields.username.value || !this.formFields.password.value }
                    >
                        { sign_up_page?.submit_button }
                    </LoginButton>
                </div>
                <FormServerErrors msg={ this.serverError } />
                <div className={ `${ styles.LinksField } ${ styles.alignCenter }` }>
                    <div>
                        <Link className={ styles.Link } to={ROUTES.LOGIN}>{ sign_up_page?.login_link }</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export const SignUpPageComponent = SignUpPage;
export default AuthHOC(SignUpPage);
