import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { isMedia } from 'helpers/html';
import sidebarState from 'globalState/sidebarState';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { beforeUnload } from 'helpers/form';
import { MenuComponentType } from 'types/components/menu';
import { MenuItemType } from 'types/globalState/menu';
import langStore from 'globalState/lang';
import MenuItem from 'layouts/main/sidebar/menu/menuItem';
import { isEmptyMenu } from 'helpers/sidebar';

@observer
export default class MenuComponent extends React.Component<MenuComponentType> {
    constructor(props) {
        super(props);

        if (sidebarState.getIsOpenDynamic()) {
            const categoriesOpen = window.localStorage.getItem('dynamicCategoriesOpen') || '[]';
            sidebarState.setDynamicActiveMenuItems(JSON.parse(categoriesOpen) || []);
        } else {
            const categoriesOpen = window.localStorage.getItem('categoriesOpen') || '[]';
            sidebarState.setActiveMenuItems(JSON.parse(categoriesOpen) || []);
        }
    }

    handleClickEdit = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
        if (isMedia('sm') || !sidebarState.isPinned) {
            sidebarState.setOpenedState(false);
            if (!isMedia('sm')) {
                sidebarState.setTabIndex(-1).catch(console.error);
            }
        }
    };

    toggleFavorites = (item) => () => {
        if (this.props.toggleFavorites) {
            this.props.toggleFavorites(item);
        }
    };
    
    handleDynamicLinkClick = (item: MenuItemType): React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement> =>
        (event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
            event.preventDefault();
            window.localStorage.setItem('openDynamicMenuId', item.getSysId());
            sidebarState.setIsOpenDynamic(true);
            sidebarState.setDynamicTitle(item.getLabel());
            sidebarState.preventCloseNav = true;
            sidebarState.fetchDynamicMenuList(item.getSysId());
            const navElement = document.querySelector('[class*="NavContent"]');
            navElement?.scrollTo(0, 0);
        };

    renderMenuItems = (item: MenuItemType, index: number, array, level = 0): JSX.Element | null => {
        return (
            <MenuItem
                handleClickEdit={ this.handleClickEdit }
                toggleFavorites={ this.toggleFavorites }
                renderMenuItems={ this.renderMenuItems }
                item={ item }
                index={ index }
                level={ level }
                filterValue={ this.props.filterValue }
                handleDynamicLinkClick={ this.handleDynamicLinkClick }
                key={ `item${ item.getSysId() }${ index }` }
            />
        );
    };

    render() {
        const { items, filterValue } = this.props;
        const { no_items_found } = langStore.getTranslateKey('navigation_search') || {};
        const noItems = langStore.getTranslateKey('no_items') || '';
        const configure = langStore.getTranslateKey('configure') || '';
        let noResultString: JSX.Element | null = null;
        if (isEmptyMenu(items) || items.length === 0) {
            if (filterValue) {
                noResultString = (
                    <div
                        className={ styles.noResults }
                        data-test={ ATTRIBUTES.sidebarMenuNoResults }
                    >
                        { no_items_found }
                    </div>
                );
            } else if (sidebarState.getIsOpenDynamic()) {
                noResultString = (
                    <div
                        className={ styles.noResults }
                        data-test={ ATTRIBUTES.sidebarDynamicNoResults }
                    >
                        { noItems }.&nbsp;
                        <Link
                            to={ sidebarState.getConfigureUrl() }
                            data-test={ ATTRIBUTES.sidebarDynamicConfigure }
                        >
                            { configure }
                        </Link>
                    </div>
                );
            }
        }
        return (
            <div className={ styles.menu } data-test={ ATTRIBUTES.sidebarMenu }>
                { isEmptyMenu(items) || items.length === 0 ? noResultString : items.map(this.renderMenuItems) }
            </div>
        );
    }
}
