import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import langStore from 'globalState/lang';
import Button from 'components/button';
import Modal from 'components/modalWindow';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import styles from './styles.module.scss';
import trashIcon from 'assets/img/icons/trash.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Tooltip from 'components/tooltip';
import { isForbiddenSymbol } from 'helpers/misc';
import _ from 'lodash';

@observer
export default class SectionSelect extends React.Component {
    contentRef = React.createRef();
    @observable current = {};
    @observable mouseDirection = 'up';
    @observable itemDragOver = null;
    @observable items;
    itemsRollbackCopy = [];
    @observable showModal = false;
    @observable value = '';
    @observable isDraggable = false;
    @observable isShowPopup = false;
    @observable isDisabledStyle = false;
    timeout = null;
    timeoutDisabledStyle = null;

    currentModal = '';
    item = '';
    dragObject = {};
    refFields = React.createRef();

    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.updateData();
    }

    componentDidMount() {
        document.addEventListener('mousemove', (e) => {
            this.handleMouseMove(e);
        });
        document.addEventListener('mouseup', (e) => {
            this.handleMouseUp(e);
        });
    }

    componentDidUpdate(prevProps) {
        if(this.props.isSaveRun) {
            this.timeoutDisabledStyle = setTimeout(()=>{
                this.isDisabledStyle = true;
            },360);
        }
        else {
            clearTimeout(this.timeoutDisabledStyle);
            this.isDisabledStyle = false;
        }
        if (!_.isEqual(prevProps,this.props) && !this.props.isSaveRun) {
            this.updateData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', (e) => {
            this.handleMouseMove(e);
        });
        document.removeEventListener('mouseup', (e) => {
            this.handleMouseUp(e);
        });
    }

    updateData() {
        this.current.item = this.props.current;
        this.items = this.props.items.map((item, index) => {
            if (item.db_title === this.current.item) {
                this.current.index = index;
            }
            return item;
        });
    }

    handleMouseMove(e) {
        if (!this.dragObject.elem) {
            this.isDraggable = false;
            return; // элемент не зажат
        }

        if (!this.isDraggable) { // если перенос не начат...

            // посчитать дистанцию, на которую переместился курсор мыши
            const moveX = e.pageX - this.dragObject.downX;
            const moveY = e.pageY - this.dragObject.downY;
            if (Math.abs(moveX) < 3 && Math.abs(moveY) < 3) {
                return; // ничего не делать, мышь не передвинулась достаточно далеко
            }

            this.dragObject.avatar = this.createAvatar(); // создаём аватар
            if (!this.dragObject.avatar) {
                this.dragObject = {}; // аватар создать не удалось, отмена переноса
                return; // возможно, нельзя захватить за эту часть элемента
            }

            // аватар создан успешно
            // создаём вспомогательные свойства shiftX/shiftY
            const coords = this.dragObject.elem.getBoundingClientRect();
            this.dragObject.shiftX = this.dragObject.downX - coords.left;
            this.dragObject.shiftY = this.dragObject.downY - coords.top;

            this.startDrag(); // отобразить начало переноса
        }

        // перенос объекта при каждом движении мыши
        this.dragObject.avatar.style.left = this.dragObject.left + 'px';
        this.dragObject.avatar.style.top = e.pageY - this.dragObject.shiftY + 'px';

        // обновляем направление движения мыши
        this.mouseDirection = this.getMouseDirection(e);

        return false;
    }

    getMouseDirection(e) {
        let direction;
        if (e.pageY < this.dragObject.prevY) {
            direction = 'up';
        }
        else {
            direction = 'down';
        }
        this.dragObject.prevY = e.pageY;

        return direction;
    }

    handleMouseDown(e, item, currentIndex) {
        if (this.items.length === 1 || this.props.isSaveRun) return; // если один элемент

        this.dragObject.elem = e.target;
        this.dragObject.left = e.target.getBoundingClientRect().left;
        this.dragObject.downX = e.clientX;
        this.dragObject.downY = e.clientY;
        this.dragObject.prevY = e.clientY;
        this.dragObject.item = item;
        this.dragObject.currentIndex = currentIndex;
    }

    handleMouseUp(e) {
        // обработать перенос, если он идет
        if (this.dragObject.avatar) {
            this.finishDrag(e);
        }

        // чистим "состояние переноса" dragObject
        this.dragObject = {};
    }

    handleItemMouseEnter = (item) => () => {
        if (this.isDraggable) {
            this.dragObject.index = item.currIndex;
            this.itemDragOver = item;
        }
    };

    handleMouseLeave = () => () => {
        if (this.isDraggable) {
            this.itemDragOver = null;
        }
    };

    createAvatar() {
        const avatar = document.createElement('div');
        avatar.classList.add(styles.DragAvatar);
        avatar.innerHTML = this.dragObject.item.title;

        return avatar;
    }

    startDrag() {
        document.body.appendChild(this.dragObject.avatar);
        this.isDraggable = true;

        // сохраняем копию для отката к первоначальному состоянию при отмене переноса
        this.itemsRollbackCopy = JSON.parse(JSON.stringify(this.items));
        // отфильтровываем пункт находящийся в процессе переноса
        this.items = _.filter(this.items,(item) => item !== this.dragObject.item);

    }

    finishDrag() {
        const dragObject = this.dragObject;

        dragObject.avatar.remove();

        if (typeof dragObject.index !== 'undefined') {
            const itemsClone = JSON.parse(JSON.stringify(this.items));
            const insertIndex = this.mouseDirection === 'up' ? dragObject.index : ++dragObject.index;
            itemsClone.splice(insertIndex, 0, dragObject.item);
            this.items = JSON.parse(JSON.stringify(itemsClone));
            if (dragObject.currentIndex !== insertIndex) {
                this.props.onChange(itemsClone);
            }
        }
        else {
            this.items = JSON.parse(JSON.stringify(this.itemsRollbackCopy));
        }
        this.isDraggable = false;
    }

    setCurrent(item, index) {
        this.current = {
            item,
            index,
        };
        this.props.onSelect(item);
    }

    handleShowModal = (currentModal = '', item = '') => (e) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        }
        this.props.onSelect(this.props.current);
        this.item = item;
        this.value = '';
        this.currentModal = currentModal;
        this.showModal = !this.showModal;
    };

    handleChange = (e) => {
        const { value } = e.target;
        if (isForbiddenSymbol(value)) {  // т.к. информация парситься с урла, запретить спец. символы
            this.showPopup();
        }
        else {
            this.value = value;
            this.hidePopup();
        }
    };

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };

    renderCreateItemModal() {
        const { form_layout_titles = {} } = langStore.getTranslate();

        return (
            <div>
                <div className={ styles.Label }>
                    { form_layout_titles.section_name }
                </div>
                <input
                    type="text"
                    value={ this.value }
                    onChange={ this.handleChange }
                    className={ styles.Input }
                    ref={ this.contentRef }
                    data-test={ ATTRIBUTES.layoutSectionInput }
                />
                <div className={ styles.Buttons }>
                    <Button
                        buttonType={'secondary'}
                        onClick={ this.handleShowModal() }
                        data-test={ ATTRIBUTES.layoutSectionCancelButton }
                    >
                        { form_layout_titles.cancel_button }
                    </Button>
                    <Button
                        onClick={ this.addItem() }
                        buttonType="primary"
                        disabled={ !this.value }
                        data-test={ ATTRIBUTES.layoutSectionCreateButton }
                    >
                        { form_layout_titles.create_button }
                    </Button>
                </div>
                {
                    this.isShowPopup && (
                        <Tooltip
                            onClose={ this.hidePopup }
                            parent={ this.contentRef.current }
                        >
                            { form_layout_titles.title_message }
                        </Tooltip>
                    )
                }
            </div>
        );
    }

    renderRemoveItemModal() {
        const { form_layout_delete_window = {} } = langStore.getTranslate();
        const modalText = form_layout_delete_window && form_layout_delete_window.warning.replace(/{sectionName}/gi, `<b>${this.item.title }</b>`).replaceAll('"','');

        return (
            <div>
                <div className={ styles.Text }  dangerouslySetInnerHTML={{__html:modalText}}/>
                <div className={ styles.Buttons }>
                    <Button
                        buttonType="secondary"
                        onClick={ this.handleShowModal() }
                        data-test={ ATTRIBUTES.layoutSectionCancelButton }
                    >
                        { form_layout_delete_window.btn_cancel }
                    </Button>
                    <Button
                        onClick={ this.removeSelect }
                        buttonType="destructive"
                        data-test={ ATTRIBUTES.layoutSectionRemoveConfirmButton }
                    >
                        { form_layout_delete_window.btn_delete }
                    </Button>
                </div>
            </div>
        );
    }

    removeSelect = () => {
        this.showModal = false;
        this.props.onRemove(this.item);
    };

    isEqualItem(newItem) {
        return this.items.some(item => _.isEqual(item.title, newItem));
    }

    addItem = () => async () => {
        this.showModal = false;
        const displayItem = this.value;

        if (displayItem === undefined || displayItem.trim() === '' || this.isEqualItem(displayItem)) return;
        await this.props.onAdd(displayItem);
        await this.setCurrent(displayItem, this.items.length - 1);
        this.value = '';
    };

    changeActiveOrder(direction) {
        if (this.props.isSaveRun) return;

        const {
            item: db_title,
            index,
        } = this.current;
        const item = this.items.find(item => {
            return item.db_title === db_title;
        });

        if (!item) return;

        const newPosition = index + direction >= 0 ? index + direction : 0;
        this.items.splice(index, 1);
        this.items.splice(newPosition, 0, item);
        this.props.onChange(this.items);
    }

    renderList() {
        return this.items.map((item, index) => {
            const classNames = [styles.Item];
            if (item.db_title === this.current.item) {
                classNames.push(styles.Selected);
                this.current.index = index;
            }
            // сохраняем текущий индекс для последующих манипуляций
            item.currIndex = index;

            return (
                <React.Fragment key={ index }>
                    { this.isDraggable && item === this.itemDragOver && this.mouseDirection === 'up' && <span className={ styles.Spacer }/>}
                    <div
                        className={ `${ styles.SelectWrap } ${ !this.isDraggable && styles.HoverClass }` }
                        onMouseEnter={ this.handleItemMouseEnter(item) }
                        onMouseMove={ this.handleItemMouseEnter(item) }
                        onMouseDown={ (e) => {
                            this.handleMouseDown(e, item, index);
                        } }
                        onClick={ () => {
                            this.setCurrent(item.db_title, index);
                        } }
                    >
                        <div className={ classNames.join(' ') } data-test={ ATTRIBUTES.layoutSectionSelectItem }>
                            { item.title }
                        </div>
                        { (this.items.length > 1) &&
                            <div className={ styles.RemoveBtn }
                                 data-test={ ATTRIBUTES.layoutSectionRemoveButton }
                                 onMouseDown={ e => e.stopPropagation() }
                                 onClick={ this.handleShowModal('delete', item) }
                                 dangerouslySetInnerHTML={ { __html: trashIcon } }
                            />
                        }
                    </div>
                    { this.isDraggable && item === this.itemDragOver && this.mouseDirection === 'down' && <span className={ styles.Spacer }/>}
                </React.Fragment>
            );


        });
    }

    render() {
        const {
            form_layout_titles = {},
            form_layout_delete_window = {},
        } = langStore.getTranslate();

        return (
            <>
                <div className={ styles.Label }>{ form_layout_titles.section }</div>
                <div className={ `${ styles.Select } ${ this.isDisabledStyle ? styles.disabled : '' }` }>
                    <div
                        onMouseLeave={ this.handleMouseLeave() }
                        className={ styles.Fields }
                        ref={ this.refFields }
                        data-test={ ATTRIBUTES.layoutSectionSelect }
                    >
                        { this.renderList() }
                    </div>
                    <div className={ styles.PickButtons }>
                        <div className={ styles.PickButton }>
                            <Button
                                onClick={ () => {
                                    this.changeActiveOrder(-1);
                                } }
                                buttonType={ 'icon-border' }
                                title="up"
                                svg={ IconChevronUp }
                                data-test={ ATTRIBUTES.layoutSectionUpButton }
                                disabled={ this.isDisabledStyle }
                            />
                        </div>
                        <div className={ styles.PickButton }>
                            <Button
                                onClick={ () => {
                                    this.changeActiveOrder(+1);
                                } }
                                buttonType={ 'icon-border' }
                                title="down"
                                svg={ IconChevronDown }
                                data-test={ ATTRIBUTES.layoutSectionDownButton }
                                disabled={ this.isDisabledStyle }
                            />
                        </div>
                    </div>
                </div>

                <Button
                    onClick={ this.handleShowModal('create') }
                    data-test={ ATTRIBUTES.layoutSectionAddNewButton }
                >
                    { form_layout_titles.add_new_button }
                </Button>


                <Modal
                    isShow={ this.showModal }
                    title={ this.currentModal === 'delete' ? form_layout_delete_window.title : form_layout_titles.create_section }
                    doClose={ this.handleShowModal() }
                >
                    { this.currentModal === 'delete' ? this.renderRemoveItemModal() : this.renderCreateItemModal() }
                </Modal>
            </>
        );
    }
}
