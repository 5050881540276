import * as React from 'react';
import { observer } from 'mobx-react';
import WidgetComponent from 'components/widget';
import { ServicePortalWidgetsState } from 'globalState/servicePortalWidgets';
import EventBusState from 'globalState/eventBus';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';

import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import FormMessages from 'components/formMessages';
import langStore from 'globalState/lang';
import { helperRedirectReplace } from 'helpers/history';
import _ from 'lodash';
import { globalEventEmitter } from 'lib/EventEmitter';
import { errorListener } from 'helpers/misc';
import { eventType } from 'constants/eventBusTypes';
import SubFormModal from 'components/subForm/subFormModal';

@observer
export class ServicePortalWidgetsPage extends React.Component {
    state = {};
    prevLocation = ''

    constructor(props) {
        super(props);
        this.prevLocation = props.location;
        this.state = new ServicePortalWidgetsState();

        globalEventEmitter.subscribe(['impersonate', 'deImpersonate'], this.handleImpersonate);

        this.state.fetchData(props).catch(console.error);
        window.removeEventListener('error', errorListener); // TODO убрать после исправления дефекта с запуском клиентских скриптов после перехода на другой адрес
    }

    componentDidMount() {
        this.state.setTimeout(this.emitAfterLoadWidgets);
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        const { location: prevLocation } = prevProps;
        this.prevLocation = location;
        if (location && prevLocation && location.pathname !== prevLocation.pathname) {
            EventBusState.reset(); // очищаем при смене location, т.к. ссылки на виджеты в подписках не актуальны
            if (window.s_form) {
                delete window.s_form;
            }
        }
        const state = location && location.state;
        // Если preventReload = true, значит переход был обработан с помощью шины и обновление страницы не требуется
        if (state && state.preventReload) {
            helperRedirectReplace({
                search: location.search,
            });  // Удаляем preventReload из истории
            this.state.fetchDataWithoutLoading(this.props);
        }
        else if (location && prevLocation
            && (location.pathname !== prevLocation.pathname || location.search !== prevLocation.search)) {
            if (window.s_form) {
                delete window.s_form;
            }
            this.state.fetchData(this.props);
        }
        this.state.setTimeout(this.emitAfterLoadWidgets);
    }

    componentWillUnmount() {
        const { isModal } = this.props;
        if (!isModal) {
            EventBusState.reset(); // при переходе на другую страницу очищаем eventbus state
        }
        window.addEventListener('error', errorListener); // TODO убрать после исправления дефекта с запуском клиентских скриптов после перехода на другой адрес
        globalEventEmitter.subscribe(['impersonate', 'deImpersonate'], this.handleImpersonate);
    }

    emitAfterLoadWidgets = () => {
        EventBusState.emit(eventType.AFTER_LOAD_WIDGETS, true);
    }

    handleImpersonate = () => {
        EventBusState.reset();
        this.state.fetchData(this.props);
    };

    getContainerBackgroundStyle(container) {
        let style = {};
        if (container.getBackgroundImage()) {
            style.backgroundImage = `url("${ container.getBackgroundImage() }")`;
        }
        if (container.getBackgroundColor()) {
            style.backgroundColor = container.getBackgroundColor();
        }
        return style;
    }

    getBackgroundStyle(data) {
        let style = {};
        if (data.background_image) {
            style.backgroundImage = `url("${ data.background_image }")`;
        }
        if (data.background_color) {
            style.backgroundColor = data.background_color;
        }
        return style;
    }

    renderPage = () => {
        return _.map(this.state.getContainers(), this.renderContainer);
    };

    renderContainer = (container) => {
        let style = this.getContainerBackgroundStyle(container);
        const rows = _.map(container.getRows(), this.renderRow);
        const content = container.getFixedWidth() ? <div className={ styles.FixedContainer }>{ rows }</div> : rows;

        return (
            <div
                key={ container.getSysId() }
                className={ `${ container.getCssClassNames() }` }
                style={ style }
                data-test={ ATTRIBUTES.portalContainer }
            >
                { content }
            </div>
        );
    };

    renderRow = (row) => {
        let classes = [
            styles.Row,
            row.getCssClassNames(),
        ];
        return (
            <div
                className={ classes.join(' ') }
                key={ row.getSysId() }
                data-test={ ATTRIBUTES.portalRow }
            >
                { _.map(row.getColumns(), this.renderColumn) }
            </div>
        );
    };

    renderColumn = (column) => {
        if (_.isEmpty(column.getWidgetInstances())) {
            return null;
        }
        let classes = [
            styles.Columns,
            styles[`Columns-xs-${ column.getSizeXs() }`],
            styles[`Columns-sm-${ column.getSizeSm() }`],
            styles[`Columns-md-${ column.getSizeMd() }`],
            styles[`Columns-lg-${ column.getSizeLg() }`],
            column.getCssClassNames(),
        ];
        return (
            <div
                key={ column.getSysId() }
                className={ classes.join(' ') }
                data-test={ ATTRIBUTES.portalColumn }
            >
                { _.map(column.getWidgetInstances(), this.renderWidget) }
            </div>
        );
    };

    renderWidget = (widgetInstance) => {
        const widget = widgetInstance.getWidget();
        return (
            <WidgetComponent
                key={ widgetInstance.getWidgetInstanceId() }
                template={ widget.getTemplate() }
                options={ widgetInstance.getSchemaOptions() }
                widget_instance_id={ widgetInstance.getWidgetInstanceId() }
                css={ widget.getCss() }
                client_script={ widget.getClientScript() }
                isServicePortal
                dictionary={ langStore.getTranslate() }
                portalState={ this.state }
                isWait={ this.state.getIsFetchingWithoutLoading() }
            />
        );
    };

    render() {
        const { location } = this.props;
        const { state, prevLocation } = this;
        const { pageData } = state;
        const isGoToAnotherPath = prevLocation?.pathname !== location?.pathname;
        if (state.getIsFetching() || isGoToAnotherPath) {
            return <div>Загрузка...</div>;
        }
        else if (state.errorMessage) {
            return <div>{ state.errorMessage }</div>;
        }
        else if (!state.pageDataLoaded || !pageData || !pageData.main) {
            return null;
        }

        let style = this.getBackgroundStyle(pageData.main);
        return (
            <div style={ style }>
                <style>{ pageData.main.css }</style>
                { this.renderPage() }
                <FormMessages />
                <SubFormModal />
            </div>
        );
    }
}


export default ErrorWrapperHoc(ServicePortalWidgetsPage);
