import { getDateGroupName, getDurationValue, getFieldValueString, getParamValue, getPercentage, getSysColumnNameById } from 'helpers/report/report';
import _ from 'lodash';
import { getUrlByFieldValues } from 'helpers/report/reportComponent';
import moment from 'moment';
import { AVERAGE_TXT } from 'constants/report';

/**
 * Обновляет значения и кол-во записей для столбца, строки и общей суммы
 * @param item - элемент с данными для одной ячейки
 * @param isAverage - нужно ли вычислять среднее значение
 * @param row - объект с информацией о строке
 * @param column - объект с информацией о строке
 * @param total - объект с информацией о результирующей ячейке
 */
const updatePivotValues = (item, isAverage, row, column, total) => {
    const newValue = item.value ? item.value : 0;
    const newTotalValue = isAverage ? newValue * item.count : newValue;
    row.value += newTotalValue;
    column.value += newTotalValue;
    total.value += newTotalValue;
    row.count += item.count;
    column.count += item.count;
    total.count += item.count;
};

/**
 * Заполняет объект tableData конечными данными
 * @param tableData - объект, в который собирается информация о ячейках, сгруппированных по строкам
 * @param curColIndex - индекс обрабатываемой колонки
 * @param value - значение ячейки
 * @param count - количество записей для ячейки
 * @param isAverage - нужно ли вычислить среднее значение
 * @param currentReportState
 */
const fillMultiPivotRowValues = (tableData, curColIndex, value, count, isAverage, currentReportState) => {
    const newValue = isAverage ? value * count : value;
    tableData.cells[curColIndex].value = newValue;
    tableData.total.value += newValue;
    tableData.cells[curColIndex].displayValue = currentReportState.isDurationAverage && tableData.cells[curColIndex].value
        ? getDurationValue(tableData.cells[curColIndex].value) : '';
    tableData.total.displayValue = currentReportState.isDurationAverage && tableData.total.value
        ? getDurationValue(tableData.total.value) : '';
    tableData.cells[curColIndex].count = count;
    tableData.total.count += count;
};

/**
 * Устанавливает среднее значение для item
 * @param item - исходный объект с данными
 * @param reportState
 */
const computeAverage = (item, reportState) => {
    if (!item) return;
    _.set(item, 'value',
        item.count ? item.value / item.count : 0);
    _.set(item, 'displayValue',
        item.count ? (reportState?.isDurationAverage ? getDurationValue(item.value / item.count) : '') : '');
};

/**
 * Получение аггрегированных значений для среднего
 * @param items - неформатированные элементы
 * @param reportState
 * @returns {*}
 */
const getAggregationsForAverage = (items, reportState) => {
    const aggregations = _.reduce(items, (aggregations, item) => {
        aggregations.sum += _.toNumber(item.value);
        aggregations.value += _.toNumber(item.value) * item.count;     // Вычисляем общую сумму значений (среднее * кол-во)
        aggregations.count += item.count;                              // Вычисляем общее кол-во записей
        return aggregations;
    }, {
        sum: 0,
        value: 0,
        count: 0,
    });

    if (reportState.isDurationAverage) {
        const avgValue = aggregations.count ? moment.duration(aggregations.value/aggregations.count || 0) : 0;
        _.set(aggregations, 'value', avgValue);
        aggregations.displayValue = aggregations.count && aggregations.value ? getDurationValue(aggregations.value) : 0;
    } else {
        computeAverage(aggregations, reportState);
    }

    return aggregations;
};

/**
 * Ищет объект с информацией о колонке по имени, если не существует, создаёт новый
 * @param item - объект с исходными данными
 * @param rowSysName - sysName поля, значения которого формируют строки таблицы
 * @param rows - массив с объектами строк
 * @param currentReportState - текущий стейт
 */
const createPivotRow = (item, rowSysName, rows, currentReportState) => {
    const dataBaseName = _.isPlainObject(item.database_name) ? item.database_name.database_name : item.database_name;
    let row = _.find(rows, [
        'dbName',
        dataBaseName,
    ]);
    if (!row) {
        row = {
            name: item.display_name,
            dbName: dataBaseName,
            value: 0,
            count: 0,
            url: getUrlByFieldValues(currentReportState,
                [ {
                    name: rowSysName,
                    value: item.database_name,
                },
            ]),
        };
        rows.push(row);
    }
    return row;
};

/**
 * Создает таблицу для 2го уровня вложенности строки
 * @param subItem
 * @param item
 * @param groupBySysName
 * @param sliceBySysName
 * @param totalValue
 * @param currentReportState - текущий стейт репорта
 * @param resultValue
 * @returns {{rowName: (string|*), percentage: string, count: *, value: (number|*), url: *}}
 */
const createDataTableSubRow = (subItem, item, groupBySysName, sliceBySysName, totalValue, currentReportState) => {
    let resultValue = '';
    if (currentReportState.isDurationAverage && subItem.value) {
        resultValue = getDurationValue(subItem.value);
    }
    return {
        rowName: subItem.display_name,
        displayValue: resultValue ? resultValue : '',
        value: _.toNumber(subItem.value),
        count: subItem.count,
        url: getUrlByFieldValues(currentReportState, [
            {
                name: groupBySysName,
                value: item.database_name,
            },
            {
                name: sliceBySysName,
                value: subItem.database_name,
            },
        ]),
        percentage: getPercentage(subItem.value, totalValue),
    };
};

/**
 * Устанавливает среднее значение для item и вложенных элементов
 * @param item - исходный объект с данными
 * @param reportState
 */
const computeMultilevelAverages = (item, reportState) => {
    if (item.subRows) {
        _.map(item.subRows, subRow => computeMultilevelAverages(subRow, reportState));
    }
    if (item.cells) {
        _.map(item.cells, cell => computeAverage(cell, reportState));
    }
    computeAverage(item.total, reportState);
};

/**
 * Заполняет значениями таблицу для отображения pivot
 * @param tableData - данные таблицы
 * @param colData - массив с названиями колонок
 * @param item - данные текущей ячейки
 * @param columnSysName - колонка, по которой строится таблица
 * @param rowSysName - строка, по которой строится таблица
 * @param currentReportState - текущий стейт
 */
const fillPivotData = (tableData, colData, item, columnSysName, rowSysName, currentReportState) => {
    const dataBaseName = _.isPlainObject(item.database_name) ? item.database_name.database_name : item.database_name;
    tableData[colData.database_name][dataBaseName] = {
        value: _.toNumber(item.value),
        count: item.count,
        url: getUrlByFieldValues(currentReportState, [
                {
                    name: columnSysName,
                    value: colData.database_name,
                },
                {
                    name: rowSysName,
                    value: item.database_name,
                },
            ]),
    };
};

/**
 * Создает таблицу под графиком для каждого элемента
 * @param item - элемент, где которого создаем
 * @param aggregationType - тип аггрегирования, задается в настройках
 * @param groupBySysName - поле группировки
 * @param sliceBySysName - поле slice by тоже задается в настройках
 * @param currentReportState - текущий стейт
 * @returns {*}
 */
const createDatatableRow = (item, aggregationType, groupBySysName, sliceBySysName, currentReportState) => {
    // Не отображаем строки, по которым нет записей
    const nonEmptySubItems = item.items ?
        item.items.filter(child => child.count !== null && child.count !== 0) :
        [];
    let resultValue = '';
    if (currentReportState.isDurationAverage && item.value) {
        resultValue = getDurationValue(item.value);
    }
    // Вычисляем общее значение и кол-во записей, суммируя по вложенным элементам
    const rowAggregation = _.has(item, 'value') ?
        {
            value: _.toNumber(item.value),
            displayValue: resultValue,
            count: item.count,
        } :
        getTotalAggregation(aggregationType, nonEmptySubItems, currentReportState);
    return _.merge(rowAggregation, {
        rowName: getFieldValueString(item.display_name),
        url: getUrlByFieldValues(currentReportState, [
            {
                name: groupBySysName,
                value: item.database_name,
            },
        ]),
        subRows: nonEmptySubItems.map(subItem => createDataTableSubRow(subItem, item, groupBySysName, sliceBySysName, rowAggregation.sum, currentReportState)),
    });
};

/**
 * Вычисляет агрегирующие значения для массива элементов
 * @param aggregationType - тип агрегации
 * @param items - массив элементов
 * @param reportState
 * @returns {{
 *      count: *,       общее количество записей
 *      value: *        результат агрегации сумма или среднее
 *      sum: *,         сумма значений
 * }}
 */
const getTotalAggregation = (aggregationType, items, reportState) => {
    if (aggregationType.toLowerCase() === AVERAGE_TXT) {
        return getAggregationsForAverage(items, reportState);
    }

    const value = _(items).sumBy('value');
    const count = _(items).sumBy('count');
    return {
        count: count,
        sum: value,
        value: value,
        displayValue: reportState.isDurationAverage ? getDurationValue(value) : '',
    };
};

/**
 * @param sourceDataObj - объект с исходными данными
 * @param tableData - объект, в который собирается информация о ячейках, сгруппированных по строкам
 * @param curColIndex - индекс обрабатываемой колонки
 * @param urlFields - массив незаполенных полей для формирования URL
 * @param colsUrlFieldValues - массив значений полей, соответствующие колонкам
 * @param rowsUrlFieldValues - массив значений полей, соответствующие строкам
 * @param isAverage - нужно ли вычислить среднее значение
 * @param currentReportState - текущий стейт репорта
 */
const iterateMultiPivotRowLevels = (sourceDataObj, tableData, curColIndex, urlFields, colsUrlFieldValues, rowsUrlFieldValues, isAverage, currentReportState) => {
    tableData.cells[curColIndex] = {
        value: 0,
        count: 0,
        url: getUrlByFieldValues(currentReportState, _.concat(colsUrlFieldValues, rowsUrlFieldValues)),
    };
    tableData.total.url = getUrlByFieldValues(currentReportState, rowsUrlFieldValues);
    if (sourceDataObj.hasOwnProperty('value')) { // Дошли до последнего уровня вложенности
        fillMultiPivotRowValues(tableData, curColIndex, _.toNumber(sourceDataObj.value), sourceDataObj.count, isAverage, currentReportState);
        return;
    }

    sourceDataObj.items.forEach(subObj => {
        let row = tableData.subRows.find(item => item.dbRowName === subObj.database_name); // Ещем подстроку по имени
        const oldRowSpan = row ? row.rowSpan : 0;   // сколько строк занимала запись перед добавлением новых дочерних элементов
        if (!row) { // Если подстрока не найдена, создаём новую
            row = {
                rowName: subObj.display_name,
                dbRowName: subObj.database_name,
                rowSpan: 1,
                subRows: [],
                cells: [],
                total: {
                    value: 0,
                    count: 0,
                },
            };
        }
        // Добавляем к параметрам url ещё одно значение
        const childUrlFieldValues = _.concat(rowsUrlFieldValues, {
            name: urlFields[0],
            value: subObj.database_name,
            displayValue: currentReportState.isDurationAverage ? getDurationValue(subObj.database_name) : '',
        });
        iterateMultiPivotRowLevels(subObj, row, curColIndex, _.tail(urlFields), colsUrlFieldValues, childUrlFieldValues, isAverage, currentReportState);

        if (row.total.count === 0) {  // Если все подстроки пустые, пропускаем
            return;
        }
        if (oldRowSpan === 0) { // Если появились непустые подстроки, добавляем родительскую скроку в объект с данными
            tableData.subRows.push(row);
        }
        tableData.rowSpan = tableData.rowSpan + row.rowSpan - oldRowSpan;   // Увеличиваем кол-во подстрок
        tableData.cells[curColIndex].value += row.cells[curColIndex].value; // Инкрементируем сумму по текущему столбцу
        tableData.total.value += row.cells[curColIndex].value;              // Сумма для всей таблицы
        tableData.cells[curColIndex].displayValue = currentReportState.isDurationAverage && tableData.cells[curColIndex].value
            ? getDurationValue(tableData.cells[curColIndex].value) : '';
        tableData.total.displayValue = currentReportState.isDurationAverage && tableData.total.value ? getDurationValue(tableData.total.value) : '';
        tableData.cells[curColIndex].count += row.cells[curColIndex].count; // Кол-во записей по текущему столбцу
        tableData.total.count += row.cells[curColIndex].count;              // Кол-во записей для всей таблицы
    });
};

/**
 * Создаёт объект с информацией о колонке
 * @param columnSysName - sysName поля, значения которого формируют колонки таблицы
 * @param colData - объект с исходными данными колонки
 * @param columns - массив с объектами колонок
 * @param currentReportState - текущий стейт
 */
const createPivotColumn = (columnSysName, colData, columns, currentReportState) => {
    const column = {
        name: colData.display_name,
        dbName: colData.database_name,
        value: 0,
        count: 0,
        url: getUrlByFieldValues(currentReportState, [
            {
                name: columnSysName,
                value: colData.database_name,
            },
        ]),
    };
    columns.push(column);
    return column;
};

/**
 * Преобразует данные для отчёта Pivot table
 * @param data - объект с исходными данными
 * @param config - объект, содержащий в себе информацию о конфигурационных параметрах
 * @param currentReportState
 * @returns {{
 *      tableData: *,                   двумерный массив с данными
 *      columns: *, rows: *,            информация для колонок и строк таблицы
 *      total, totalUrl: string         информация для результирующей ячейки таблицы
 *      }}
 */
export const prepareDataForPivot = (data, config, currentReportState) => {
    const columnSysName = getSysColumnNameById(getParamValue(config, 'column_id', true), currentReportState);
    const rowSysName = getSysColumnNameById(getParamValue(config, 'row_id', true), currentReportState);
    const aggregationType = getParamValue(config, 'aggregation_type', true);
    const isAverage = aggregationType.toLowerCase() === AVERAGE_TXT;

    const result = _.reduce(data, (result, colData) => {
        const column = createPivotColumn(columnSysName, colData, result.columns, currentReportState);

        result.tableData[colData.database_name] = {};
        colData.items.forEach(item => {
            let row = createPivotRow(item, rowSysName, result.rows, currentReportState);
            fillPivotData(result.tableData, colData, item, columnSysName, rowSysName, currentReportState);
            // Инкрементим значения для соответствующего столбца, строки и общей суммы
            updatePivotValues(item, isAverage, row, column, result.total);
        });
        return result;
    }, {
        columns: [],
        rows: [],
        total: {
            value: 0,
            count: 0,
            url: getUrlByFieldValues(currentReportState),
        },
        tableData: {},
    });

    if (isAverage) {
        result.columns.map(column => computeAverage(column, currentReportState));
        result.rows.map(row => computeAverage(row, currentReportState));
        computeAverage(result.total, currentReportState);
    }

    _.remove(result.columns, (col) => col.count === 0);
    _.remove(result.rows, (col) => col.count === 0);

    return result;
};

/**
 * Рекурсивно проходит по объекту с данными и заполняет colHeaders
 * @param sourceDataObj - исходные данные, представляет собой объект с n+m уровнями вложенности,
 *         где n/m соответствуют кол-ву категорий столбцов/строк
 * @param colHeaders - объект, в который собирается информация о заголовках колонок
 * @param colLevelAmount - уровень вложенности, по которому проходит разграничение колонок и строк
 * @param curLevel - текущий уровень вложенности
 * @param curColIndex - индекс обрабатываемой колонки
 * @param tableData - объект, в который собирается информация о ячейках, сгруппированных по строкам
 * @param urlFields - массив незаполенных полей для формирования URL
 * @param urlFieldValues - массив значений полей для формирования URL
 * @param isAverage - нужно ли вычислить среднее значение
 * @param currentReportState - текущий стейт
 * @returns {number} - сколько колонок было добавлено
 */
const iterateMultiPivotColumnLevels = (sourceDataObj, colHeaders, colLevelAmount, curLevel, curColIndex, tableData, urlFields, urlFieldValues, isAverage, currentReportState) => {
    if (curLevel === colLevelAmount) { // Дошли до последнего уровня вложенности колонок
        iterateMultiPivotRowLevels(sourceDataObj, tableData, curColIndex, urlFields, urlFieldValues, [], isAverage, currentReportState);
        return tableData.cells[curColIndex].count > 0 ? 1 : 0; // Отсеиваем пустые столбцы
    }

    if (!colHeaders[curLevel]) {
        colHeaders[curLevel] = [];
    }

    const startColIndex = curColIndex; // Фиксируем текущее кол-во колонок
    sourceDataObj.items.forEach(subObj => {
        // Добавляем ещё одно значение поля для формирования url'а
        const childUrlFieldValues = _.concat(urlFieldValues, {
            name: urlFields[0],
            value: subObj.database_name,
            displayValue: currentReportState.isDurationAverage && subObj.database_name ? getDurationValue(subObj.database_name) : '',
        });
        const subCatColSpan = iterateMultiPivotColumnLevels(subObj, colHeaders, colLevelAmount, curLevel + 1, curColIndex, tableData, _.tail(urlFields), childUrlFieldValues, isAverage, currentReportState);
        if (subCatColSpan > 0) { // Добавляем колонку, если есть непустые дочерние
            colHeaders[curLevel].push({
                colName: subObj.display_name,
                colSpan: subCatColSpan,
            });
        }
        curColIndex += subCatColSpan;
    });
    return curColIndex - startColIndex; // Вычисляем, сколько колонок прибавилось
};

/**
 * Преобразует данные для отчета multilevelpivot table
 * @param data - исходные данные
 * @param config - конфигурация отчета (3й шаг в МО)
 * @param currentReportState - текущий стейт
 * @returns {{colHeaders: [], tableData: {rowSpan: number, total: {count: number, value: number}, cells: [], subRows: []}, dataColsAmount: number}}
 */
export const prepareDataForMultiPivot = (data, config, currentReportState) => {
    const columnCategoryIds = getParamValue(config, 'column_ids', true);
    const rowCategoryIds = getParamValue(config, 'row_ids', true);
    const urlFields = _.concat(columnCategoryIds, rowCategoryIds).map(id => getSysColumnNameById(id, currentReportState));

    const aggregationType = getParamValue(config, 'aggregation_type', true);
    const isAverage = aggregationType.toLowerCase() === AVERAGE_TXT;

    const colHeaders = [];
    const tableData = {
        rowSpan: 0,
        subRows: [],
        cells: [],
        total: {
            value: 0,
            count: 0,
        },
    };
    const dataColsAmount = iterateMultiPivotColumnLevels({ items: data }, colHeaders, columnCategoryIds.length, 0, 0, tableData, urlFields, [], isAverage, currentReportState);

    if (isAverage) {
        computeMultilevelAverages(tableData, currentReportState);
    }
    return {
        tableData,
        colHeaders,
        dataColsAmount,
    };
};

/**
 * Ищет подходящую дате группу (если нет - создаёт новую) и добавляет в неё элемент
 * @param subItem - элемент
 * @param item - объект с данными
 * @param per - способ группировки дат
 * @param isCombine - объединены ли периоды с одним именем
 * @param timelineData - массив объектов групп
 */
const addItemToDatatableTimeGroup = (subItem, item, per, isCombine, timelineData) => {
    if (!item.count) {  // Игнорируем данные, для которых нет записей
        return;
    }
    // Значение для последующей сортировки
    const dateValue = item.display_name === null ? null :
        isCombine || per === 'year' ?
            Number(item.display_name) :
            moment(item.display_name).valueOf();
    const groupName = getDateGroupName(dateValue, per, isCombine);
    let group = _.find(timelineData, [
        'display_name',
        groupName,
    ]);
    if (!group) {
        group = {
            display_name: groupName,
            database_name: { per: per, isCombine: isCombine === 1, value: dateValue },
            items: [],
            dateValue,
        };
        timelineData.push(group);
    }
    group.items.push({
        display_name: subItem.display_name,
        database_name: subItem.database_name,
        value: item.value,
        count: item.count,
    });
};

/**
 * Подготовка данных для таблички под графиков, когда установлен период
 * @param data - изначальные данные
 * @param config - форма конфигурации
 * @returns {Array}
 */
export const prepareDataForDatatableWithTime = (data, config) => {
    let per = getParamValue(config, 'per', true);
    if (per) per = per.toLowerCase();

    const isCombine = getParamValue(config, 'combine_periods');
    const timelineData = [];
    data.forEach(line => {
        if (!line.items) {
            const dateValue = line.display_name === null ? null :
                isCombine || per === 'year' ?
                    Number(line.display_name) :
                    moment(line.display_name).valueOf();
            const groupName = getDateGroupName(dateValue, per, isCombine);
            timelineData.push(_.merge(_.clone(line), {
                display_name: groupName,
                database_name: { per: per, isCombine: isCombine === 1, value: dateValue },
                dateValue: dateValue,
            }));
        }
        else {
            line.items.forEach(item => addItemToDatatableTimeGroup(line, item, per, isCombine, timelineData));
        }
    });
    return _.sortBy(timelineData, 'dateValue');
};

/**
 * Преобразует данные для таблиц под отчётами
 * @param data - объект с исходными данными
 * @param groupBySysName - sysName поля, по которому производится группировка
 * @param sliceBySysName - sysName поля, по которому разделяются данные внутри группы
 * @param aggregationType - тип агрегирования
 * @param currentReportState - текущий стейт репорта
 * @returns {{
 *      subRows: *,             массив строк
 *      count: *,               общее количество записей
 *      value: *,               суммарное значение
 *      percentage: (string),   общее количество процентов
 *      url: string             url для перехода на список записей
 * }}
 */
export const prepareDataForDatatable = (data, groupBySysName, sliceBySysName, aggregationType, currentReportState) => {
    const subRows = data.map(item => createDatatableRow(item, aggregationType, groupBySysName, sliceBySysName, currentReportState));

    const totalAggregation = getTotalAggregation(aggregationType, subRows, currentReportState);
    _.map(subRows, row => _.set(row, 'percentage', getPercentage(row.value, totalAggregation.sum))); // Заполняем проценты от общего числа

    return _.merge(totalAggregation, {
        subRows: subRows,
        percentage: totalAggregation.value ? '100.00%' : '-',
        url: getUrlByFieldValues(currentReportState),
    });
};
