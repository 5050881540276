import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './styles.module.scss';
import Dropdown from 'components/dropdown';
import ChevronRight from 'assets/img/icons/chevron-right.svg';
import { runScript } from 'helpers/scriptClientHelper';

/**
 * Виджет выпадающего списка (обертка)
 * Props:
 * @param [array] elements - элементы
 * @param {ref} refParent - ссылка на родителя
 * @param {object} [styleMenu] - объект со стилями для меню
 * @param {boolean} isShow
 * @method onClose
 * @method onMouseOver
 * @method onMouseOut
 */
@observer
export default class CategoryDropdown extends React.Component {
    @observable isShow = false;
    @observable isOpenCategory = -1;
    refDropdown = React.createRef();
    refElements = [];
    refSubDropdowns = [];
    @observable isMouseOver = false;
    @observable isSubMouseOver = false;
    @observable timeout = null;

    constructor(props) {
        super(props);

        this.isShow = props.isShow || this.isShow;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.props.addSubDropdown(this.refDropdown);
    }

    componentDidUpdate(prevProps) {
        const { isShow } = this.props;
        if (isShow !== prevProps.isShow) {
            this.isShow = isShow;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const { refParent, onClose } = this.props;
        const { current: el } = refParent;
        const { current: parentEl } = this.refDropdown;

        if (!el || !parentEl) {
            return;
        }

        let isContain = false;
        _.forEach(this.refSubDropdowns, element => {
            if (element && element.current && element.current.contains(e.target)) {
                isContain = true;
            }
        });
        if (!el.contains(e.target) && !parentEl.contains(e.target) && !isContain) {
            this.isShow = false;
            this.isOpenCategory = -1;
            this.refSubDropdowns = [];
            if (onClose) {
                onClose();
            }
        }
    };

    addSubDropdown = (refDropdown) => {
        this.refSubDropdowns.push(refDropdown);
        this.props.addSubDropdown(refDropdown);
    };

    handleChevronMouseOver = (element, index) => () => {
        const { onMouseOver } = this.props;
        if (onMouseOver) {
            onMouseOver();
        }
        if (element.event_to_display && _.includes(element.event_to_display, 'mouseover')) {
            clearTimeout(this.timeout);
            this.isOpenCategory = index;
            this.isMouseOver = true;
        }
        if (!element.events || !element.events.mouseover) {
            return;
        }
        runScript(element.events.mouseover);
    };

    handleChevronMouseOut = (element) => () => {
        const { onMouseOut } = this.props;
        if (onMouseOut) {
            onMouseOut();
        }
        if (element.event_to_display && _.includes(element.event_to_display, 'mouseover')) {
            this.isMouseOver = false;
            this.handleHideSubMenu();
        }
    };

    handleCloseCategory = () => {
        this.isOpenCategory = -1;
    };

    handleSubDropdownMouseOver = () => {
        clearTimeout(this.timeout);
        this.isSubMouseOver = true;
    };

    handleSubDropdownMouseOut = () => {
        this.isSubMouseOver = false;
        this.handleHideSubMenu();
    };

    handleHideSubMenu = () => {
        this.timeout = setTimeout(this.handleHideCategory, 250);
    };

    handleHideCategory = () => {
        if (!this.isMouseOver && !this.isSubMouseOver) {
            this.isOpenCategory = -1;
        }
    };

    handleClickElement = (element, index) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (element.event_to_display && _.includes(element.event_to_display, 'click')) {
            this.isOpenCategory = index;
        }
        if (!element.events || !element.events.click) {
            return;
        }
        runScript(element.events.click);
    };

    handleButtonContextMenuElement = (element, index) => (event) => {
        if (element.event_to_display && _.includes(element.event_to_display, 'context')) {
            event.preventDefault();
            this.isOpenCategory = index;
        }
        if (!element.events || !element.events.context) {
            return;
        }
        event.preventDefault();
        runScript(element.events.context);
    };

    renderCategory = (elements, index) => {
        if (!elements || !elements.length || index !== this.isOpenCategory) {
            return null;
        }
        const { styleMenu } = this.props;
        return (
            <CategoryDropdown
                elements={ elements }
                refParent={ this.refElements[index] }
                addSubDropdown={ this.addSubDropdown }
                onClose={ this.handleCloseCategory }
                onMouseOver={ this.handleSubDropdownMouseOver }
                onMouseOut={ this.handleSubDropdownMouseOut }
                isShow
                styleMenu={ styleMenu }
            />
        );
    };

    renderElements = (elements) => {
        return _.map(elements, (element, index) => {
            this.refElements[index] = React.createRef();
            const chevronRight = element.child_elements && element.child_elements.length ? (
                <div
                    className={ styles.Arrow }
                    dangerouslySetInnerHTML={ { __html: ChevronRight } }
                />
            ) : null;
            const isActive = index === this.isOpenCategory;
            return (
                <>
                    <div
                        className={ `${ styles.Element } ${ isActive ? styles.Active : '' }` }
                        key={ `element${ element.title }` }
                        onClick={ this.handleClickElement(element, index) }
                        ref={ this.refElements[index] }
                        onMouseOver={ this.handleChevronMouseOver(element, index) }
                        onMouseOut={ this.handleChevronMouseOut(element) }
                        onContextMenu={ this.handleButtonContextMenuElement(element, index) }
                    >
                        <div className={ styles.ElementTitle }>
                            { element.title }
                        </div>
                        { chevronRight }
                    </div>
                    { this.renderCategory(element.child_elements, index) }
                </>
            );
        });
    };

    render() {
        const { elements, refParent, styleMenu } = this.props;
        if (!this.isShow) {
            return null;
        }
        let cn = [styles.Menu];
        if (this.isShow) {
            cn.push(styles.Active);
        }
        return (
            <Dropdown refParent={ refParent } ref={ this.refDropdown } defaultDirection='right-middle' offsetTop={ -16 } offsetLeft={ -20 }>
                <div className={ cn.join(' ') } style={ styleMenu }>
                    { this.renderElements(elements) }
                </div>
            </Dropdown>
        );
    }
}
