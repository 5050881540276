import * as React from 'react';
import { observer } from 'mobx-react';

import GlobalPortal from '../globalPortal';

import { Link } from 'react-router-dom';
import Button from '../button';
import styles from './styles.module.scss';
import { beforeUnload } from 'helpers/form';
import { ATTRIBUTES } from 'constants/attributesForTests';

@observer
export default class Popup extends React.Component {
    initialStyles = {
        top: 0,
        left: 0,
        visibility: 'hidden',
    };
    windowScrollTop = 0;

    componentDidMount() {
        if (this.props.freezeScreen) {
            this.windowScrollTop = window.pageYOffset;
            this.initContentWheel();
        }
        document.addEventListener('click', this.doClose);
        window.addEventListener('scroll', this.onWindowScroll, { passive: false });
        window.addEventListener('wheel', this.onWindowWheel, { passive: false });
    }

    componentDidUpdate() {
        this.updatePopupPosition();
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.doClose);
        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('wheel', this.onWindowWheel);
    };

    onWindowWheel = (e) => {
        if (!this.props.freezeScreen) return;

        const { popupContent } = this.refs;
        if (!popupContent) return;
        if (!popupContent.contains(e.target) || popupContent.scrollHeight === popupContent.clientHeight) {
            e.preventDefault();
        }
    };

    initContentWheel = () => {
        const { popupContent } = this.refs;
        if (!popupContent) return;

        let i = 0;
        popupContent.onwheel = function (e) {
            const max = this.scrollHeight;
            const min = 0;

            if (e.deltaY > 0) {
                i += 100;
                if (i > max) i = max;
            }

            if (e.deltaY < 0) {
                i -= 100;
                if (i < min) i = min;
            }

            if (i >= max || i <= min) return false;
        };
    };

    handleLinkClick = (evt) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
    };

    cutText = (text, maxLength = 70) => {
        const stringText = text.toString();
        if (stringText.length > maxLength) {
            return stringText.slice(0, maxLength) + '...';
        }
        else {
            return stringText;
        }
    };

    onWindowScroll = (e) => {
        if (!this.props.isShow) {
            return;
        }

        if (this.props.freezeScreen) {
            e.preventDefault();
            window.scrollTo(0, this.windowScrollTop);
        }
        else {
            if (this.props.onToggle) {
                this.props.onToggle();
            }
        }
    };

    updatePopupPosition = () => {
        let { node, popup, triangle } = this.refs;

        if (this.props.parent) {
            node = this.props.parent;
        }

        if (!node || !popup) {
            return;
        }

        const offset = 10;
        const popupRect = popup.getBoundingClientRect();
        const nodeRect = node.getBoundingClientRect();
        const triangleRect = triangle.getBoundingClientRect();
        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;

        // рассчитываем top для попапа
        if (popupRect.height + offset > windowHeight) { // если высота попапа больше высоты окна
            const { popupContent, popupTitle } = this.refs;
            const titleHeight = popupTitle ? popupTitle.getBoundingClientRect().height : 0;
            popup.style.top = `${ window.scrollY + offset / 2 }px`;
            popupContent.style.height = `${ windowHeight - offset - titleHeight }px`;
        }
        else {
            let top = window.scrollY + nodeRect.top + nodeRect.height / 2 - popupRect.height / 2;
            if (top - window.scrollY < 0) { // если попап вылезает за верх окна
                top = window.scrollY + offset / 2;
            }
            else if (top + popupRect.height - window.scrollY > windowHeight) { // если попап вылезает за низ окна
                top = top - (top + popupRect.height - window.scrollY - windowHeight) - offset / 2;
            }
            // проставляем top для попапа
            popup.style.top = `${ top }px`;
        }
        // расчитываем и проставляем top для уголка
        triangle.style.top = `${ window.scrollY + nodeRect.top + nodeRect.height / 2 - triangleRect.height / 2 }px`;

        // рассчитываем left для попапа и уголка
        let left = nodeRect.left + nodeRect.width + triangleRect.width;
        let leftTriangle = nodeRect.left + nodeRect.width;
        if (left + popupRect.width > windowWidth) { // если попап вылезает за правый край окна
            if (nodeRect.left > popupRect.width) { // если попап помещается слева от иконки
                left = nodeRect.left - (popupRect.width + triangleRect.width);
                leftTriangle = nodeRect.left - (triangleRect.width);
                triangle.style.transform = 'rotateY(180deg) translate(1px, 0)';

                setTimeout(() => { // используем небольшой таймаут чтобы проверить есть ли у попапа скроллбар и соответственно поправить left
                    const oldWidth = popupRect.width;
                    const newWidth = popup.getBoundingClientRect().width;
                    if (newWidth > oldWidth) { // если есть скроллбар - корректируем left
                        left = left - (newWidth - oldWidth);
                    }

                    // проставляем left для попапа и уголка
                    popup.style.left = `${ left }px`;
                    triangle.style.left = `${ leftTriangle }px`;

                    // отображаем попап и уголок
                    popup.style.visibility = 'visible';
                    triangle.style.visibility = 'visible';
                    popup.classList.add(styles.animated);
                    triangle.classList.add(styles.animated);
                }, 100);
            }
            else { // если попап не помещается слева целиком - сдвигаем его влево так, чтобы он не залезал за правый край окна
                left = left - (left + popupRect.width - windowWidth) - offset / 2;

                // проставляем left для попапа и уголка
                popup.style.left = `${ left }px`;
                triangle.style.left = `${ leftTriangle }px`;

                // отображаем попап, уголок остаётся скрытым
                popup.style.visibility = 'visible';
                triangle.style.visibility = 'hidden';
                popup.classList.add(styles.animated);
            }
        }
        else {
            // проставляем left для попапа и уголка
            popup.style.left = `${ left }px`;
            triangle.style.left = `${ leftTriangle }px`;

            // отображаем попап и уголок
            popup.style.visibility = 'visible';
            triangle.style.visibility = 'visible';
            popup.classList.add(styles.animated);
            triangle.classList.add(styles.animated);
        }

    };


    renderPopup = () => {
        if (!this.props.isShow) {
            return null;
        }

        const attr = {
            className: styles.Popup,
        };

        if (this.props.popupClassName) {
            attr.className += ' ' + this.props.popupClassName;
        }

        return (
            <>
                <div { ...attr } style={ this.initialStyles } ref="popup" data-test={ ATTRIBUTES.popup }>
                    {
                        this.props.title && <div className={ styles.PopupTitle } ref="popupTitle">
                            <span className={ styles.PopupText } data-test={ ATTRIBUTES.popupText }>{ this.cutText(this.props.title) }</span>
                            {
                                (this.props.button && this.props.button.title && this.props.button.link)
                                && <Link
                                    className={ styles.PopupLink }
                                    to={ this.props.button.link } 
                                    onClick={ this.handleLinkClick }
                                    data-test={ ATTRIBUTES.previewRecordButton }
                                >
                                    <Button buttonSize='sm'>{ this.props.button.title }</Button>
                                </Link>
                            }
                        </div>
                    }

                    <div className={ styles.PopupContent } ref="popupContent">
                        { this.props.children || <div className={ styles.preloader } /> }
                    </div>
                </div>
                <div ref="triangle" className={ styles.PopupTriangle } style={ this.initialStyles } />
            </>
        );
    };


    doClose = (e) => {
        if (!this.props.isShow) {
            return;
        }

        if (!document.body.contains(e.target)
            || (this.props.parent && this.props.parent.contains(e.target))
            || (this.refs.popup && this.refs.popup.contains(e.target))) {
            return;
        }
        this.props.onToggle && this.props.onToggle(e);
    };


    render() {
        if (this.props.parent) {
            return (
                <GlobalPortal>
                    { this.renderPopup() }
                </GlobalPortal>
            );
        }

        const attr = {};

        if (this.props.nodeClassName) {
            attr.className = this.props.nodeClassName;
        }

        return (
            <div ref="node" { ...attr } >
                <GlobalPortal>
                    { this.renderPopup() }
                </GlobalPortal>
            </div>
        );
    }
}
