import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.scss';

@observer
export default class ReactClassComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    };

    renderOptions() {
        const options = this.props.options.map((option, index) => {
            return <option value={ option.value } key={ index }>{ option.label }</option>;
        });
        options.unshift(<option key='none' value="">none</option>);

        return options;
    }

    render() {
        const classNames = [styles.select];

        if (this.props.isError) {
            classNames.push(styles.error);
        }

        return (
            <select className={ classNames.join(' ') } onChange={ this.onChange } value={ this.props.value }>
                { this.renderOptions() }
            </select>
        );
    }
}
