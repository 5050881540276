import * as React from 'react';
import { observer } from 'mobx-react';
import brandingState from 'globalState/branding';

@observer
export default class SmartTitle extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
        this.setTitle();
    }

    setTitle = () => {
        const { children } = this.props;
        let pageTitle = '';
        if (!this.props.isWithoutPageTitle) {
            if (this.props.isServicePortal) {
                pageTitle = brandingState.getServicePortalPageTitle();
            } else {
                pageTitle = brandingState.getPlatformPageTitle();
            }
            pageTitle = ' | ' + pageTitle || '';
        }
        document.title = children + pageTitle;
    };

    render() {
        const { children, hidden } = this.props;
        this.setTitle();

        // Set document.title only
        if (hidden) return null;

        return children + '';
    }
}
