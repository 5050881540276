import * as React from 'react';
import styles from './styles.module.scss';
import SmartTitle from 'components/smartTitle';
import langStore from 'globalState/lang';
import apiRequest from '../../lib/apiRequest';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PageLoader from 'components/pageLoader';
import Heading from 'components/heading';
import { ServicePortalWidgetsPage } from 'pages/servicePortalWidgets';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import { ATTRIBUTES } from 'constants/attributesForTests';


@observer
class MainPage extends React.Component {
    @observable data;
    @observable pagePathName;
    @observable errorMessage = '';

    constructor(props) {
        super(props);
        this.fetchData().catch(this.errorFetchData);
    }

    fetchData = async () => {
        const response = await new apiRequest('GET /info/main-page').caching().send();
        if (response.status === 'OK'){
            const data = response.getData();
            const { page_path_name: pagePathName } = data;

            if (pagePathName) {
                this.pagePathName = pagePathName;
            }
            this.data = data;
        }
    };

    errorFetchData = (e) => {
        this.errorMessage = e.message;
    };

    renderDefault() {
        const { main_page, welcome } = langStore.getTranslate();

        if (this.errorMessage) return <div>{ this.errorMessage }</div>;

        return (
            <main className={ styles.Main }>
                <SmartTitle hidden>{ main_page }</SmartTitle>
                <Heading size='h1' className={ styles.MainIntro } data-test={ ATTRIBUTES.welcomeText }>{ welcome }</Heading>
            </main>
        );
    }

    renderPage() {
        return <ServicePortalWidgetsPage cmtLayout pagePathName={ this.pagePathName } />;
    }

    render() {
        if (!this.data) return <PageLoader />;
        return this.pagePathName ? this.renderPage() : this.renderDefault();
    }
}


export default ErrorWrapperHoc(MainPage);


