import BasePortalDesignerModel from './base';
import ColumnsPortalDesignerCollection from 'components/portalDesigner/collection/columns';
import { observable } from 'mobx';

/**
 * модель строки
 */
export default class RowPortalDesignerModel extends BasePortalDesignerModel {
    TABLE_NAME = 'page_row';
    /**
     * тип блока
     * @type {string}
     */
    TYPE = 'layout';
    @observable columns = new ColumnsPortalDesignerCollection([], this);

    constructor(params) {
        super(params);
        this.setData(params);
    }

    setData(params) {
        super.setData(params);

        this.columns.updateItems(params.columns);
    }

    async fetchSave(params) {
        const data = await super.fetchSave(params);
        this.setData({
            columns: data.columns,
        });
        return data;
    }
}
