import BaseFormFieldModel from './BaseFormFieldModel';
import { observable } from 'mobx';
import { MaskedInputModelTypes, MaskedInputPropsTypes, MaskInput } from 'types/components/dynamicForms/maskInput';
import langStore from 'globalState/lang/index';

export default class MaskedInputModel extends BaseFormFieldModel implements MaskedInputModelTypes {
    @observable activeTab = -1;
    @observable maskValues: Array<MaskInput> = [];
    @observable isValidInput = true;
    isNeedClearActiveTabMask = true;
    mask;
    separator;
    placeholder;
    is_mandatory;

    constructor(data: MaskedInputPropsTypes, parentFormSectionModel?: any) {
        super(data, parentFormSectionModel);
        this.mergeData(data);
    }

    setMask() {
        const { date_time = {} } = langStore.getTranslate();
        const separatorsFormat = this.format.match(new RegExp(this.separator, 'g'));
        const tabsFormat = this.format.split(new RegExp(this.separator));

        this.mask = tabsFormat.reduce((acc, cur, index) => {
            const separator = separatorsFormat[index] ? separatorsFormat[index] : '';
            return acc + date_time[cur] + separator;
        }, '');
    }

    changeValueTabMask = (newValue, newMask) => {
        this.maskValues[this.activeTab] = {
            ...this.maskValues[this.activeTab],
            tabMask: newMask,
            tabValue: newValue,
            isFilled: !newMask,
        };

        this.value = this.serializeValue();
    }

    setMaskValues() {
        const maskTabs: string[] = this.mask.split(new RegExp(this.separator));
        const separatorsMask: string[] = this.mask.match(new RegExp(this.separator, 'g'));
        let valueTabs: string[] = [];

        if (this.value) {
            valueTabs = this.value.split(new RegExp(this.separator));
        }

        this.maskValues = maskTabs.map((partOfMask, index) => {
            if (valueTabs[index]) {
                const diff = partOfMask.length - valueTabs[index].length;
                const tabMask = partOfMask.slice(0, diff);

                return {
                    tabMask: tabMask,
                    tabValue: valueTabs[index],
                    separator: separatorsMask[index],
                    isFilled: tabMask.length === 0,
                };
            } else {
                return {
                    tabMask: partOfMask,
                    separator: separatorsMask[index],
                    tabValue: '',
                    isFilled: false,
                };
            }
        });
    }

    serializeValue() {
        return this.maskValues.reduce((acc, cur, index) => {
            if (index !== this.maskValues.length - 1 && (cur.isFilled || cur.tabValue)) {
                return acc + cur.tabValue + cur.separator;
            } else {
                return acc + cur.tabValue;
            }
        }, '').trim();
    }

    getMask = () => {
        const tabMasks = this.mask.split(new RegExp(this.separator));
        const activeTabMask = tabMasks[this.activeTab];

        return { activeTabMask, tabMasks };
    }
}
