import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import styles from './styles.module.scss';
import apiRequest from 'lib/apiRequest';
import IconX from 'assets/img/icons/close-x.svg';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import userState from 'globalState/user';
import langStore from 'globalState/lang';
import { globalEventEmitter } from 'lib/EventEmitter';
import Button from 'components/button';
import ModalWrapper from 'components/modalWrapper';
import { ATTRIBUTES } from 'constants/attributesForTests';


@observer
export default class ImpersonatePopup extends React.Component {
    @observable sections = [];
    @observable downloading = false;
    @observable value = '';
    timeout = null;

    constructor(props) {
        super(props);
    }

    @action
    getUsersData = async (data) => {
        this.downloading = true;
        const response = await new apiRequest(`GET /impersonate/users`).qs({ search: data }).send();
        if (response && response.status === 'OK') {
            this.downloading = false;
            this.sections = response.data;
        }
    };

    onSelectUser = async (user_id) => {
        this.handleClose();
        const response = await new apiRequest(`GET /impersonate`).qs({ user_id: user_id }).send();
        if (response && response.status === 'OK') {
            if (response.data && response.data.auth_key && response.data.auth_key !== '') {
                await userState.setAccessToken(response.data.auth_key);
                window.localStorage.removeItem('elevateRoles');
                window.localStorage.removeItem('elevateRolesTime');
                // Fire impersonate event for subscribers that can do some logic with this
                globalEventEmitter.emit('impersonate');
            }
            this.value = '';
        }
    };

    handleClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
            this.sections = [];
        }
    };

    onChangeInput = ({ value }) => {
        this.value = value;
        if (this.value.length >= 2) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getUsersData(this.value).catch(console.error);
            }, 300);
        }
        else {
            this.sections = [];
        }
    };

    renderRole = (id, name, username) => {
        return (
            <li key={ id } className={ styles.userContainer } onClick={ ()=>{ this.onSelectUser(id).catch(console.error) } }>
                { `${ name } (${ username })` }
            </li>
        );
    };

    renderUsers = () => {
        return this.sections.map(user => {
            return this.renderRole(user.sys_id + '', user.first_name + ' ' + user.last_name, user.username);
        });
    };

    render() {
        const { user_menu_titles } = langStore.getTranslate();
        if (!this.props.show) {
            return null;
        }

        let content = null;
        if (this.downloading) {
            content = <div className={ styles.preloader } />;
        }
        else {
            if (this.sections.length > 0) {
                content = (
                    <ul className={ styles.contentContainer } data-test={ATTRIBUTES.impersonateList}>
                        { this.renderUsers() }
                    </ul>
                );
            }
        }

        return (
            <ModalWrapper>
                <div className={ styles.popUp }>
                    <div className={ styles.popUpOverlay } onClick={ this.handleClose } />
                    <div className={ styles.popUpContainer }>
                        <div className={ styles.header }>
                            <div className={styles.headerText}>{ user_menu_titles && user_menu_titles.impersonate }</div>
                            <Button onClick={ this.handleClose } className={ styles.close } svg={ IconX } buttonType={'icon'}/>
                        </div>

                        <div className={ styles.content }>
                            <div className={ styles.searchContainer }>
                                <StringInput placeholder={ user_menu_titles && user_menu_titles.search_for_user }
                                             ref="input"
                                             value={ this.value }
                                             onChange={ this.onChangeInput }
                                             cellEditMode
                                />
                                {/*<div className={ styles.searchIcon } dangerouslySetInnerHTML={ { __html: IconSearch } } />*/ }
                            </div>
                            { content }
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        );
    }
}
