import * as React from 'react';
import { observer } from 'mobx-react';
import GlobalPortal from 'components/globalPortal';
import styles from './styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Тултип
 * Параметры:
 * parent - элемент, относительно которого появляется тултип
 * onClose - метод. вызывается при закрытии тултипа
 * placing - как позиционировать тултип. возможные значения: top, bottom, left, right(по умолчанию), bottom-corner, top-corner
 */

@observer
export default class Tooltip extends React.Component {
    refTooltip = React.createRef();
    refTriangle = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.updatePosition();

        document.addEventListener('click', this.onDocumentClick);
        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate() {
        this.updatePosition();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        window.removeEventListener('resize', this.onWindowResize);
    }

    onDocumentClick = ({ target }) => {
        const { current: triangle } = this.refTriangle;
        const { current: tooltip } = this.refTooltip;
        const { parent, onClose } = this.props;

        if (!parent || !tooltip || !triangle || !onClose) {
            return;
        }

        if (!tooltip.contains(target) && !triangle.contains(target) && !parent.contains(target)) {
            onClose();
        }
    };

    onWindowResize = () => {
        this.updatePosition();
    };

    checkPlacing = (name) => {
        const { placing } = this.props;
        return placing && placing.includes(name);
    };

    updatePosition = () => {
        const { current: triangle } = this.refTriangle;
        const { current: tooltip } = this.refTooltip;
        const { parent } = this.props;

        if (!parent || !tooltip || !triangle) {
            return;
        }

        const offset = 12;
        const tooltipRect = tooltip.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();
        let triangleRect = triangle.getBoundingClientRect();
        const windowHeight = document.documentElement.clientHeight;
        const windowWidth = document.documentElement.clientWidth;


        // рассчитываем top
        if(this.checkPlacing('bottom')){
            // позиционируем снизу
            let top = window.scrollY + parentRect.top + parentRect.height + offset;

            if(top + tooltipRect.height - window.scrollY < windowHeight) {// если достаточно места снизу
                // проставляем top для тултипа
                tooltip.style.top = `${ top }px`;

                // разворачиваем уголок вверх, обновляем rect, проставляем top
                triangle.classList.add(styles.top);
                triangleRect = triangle.getBoundingClientRect();
                triangle.style.top = `${ top - triangleRect.height }px`;
            }
            else { // если места снизу нет
                if(parentRect.top > tooltipRect.height + offset) { // если достаточно места сверху
                    top = window.scrollY + parentRect.top - (tooltipRect.height + offset);
                    // проставляем top для тултипа
                    tooltip.style.top = `${ top }px`;

                    // разворачиваем уголок вниз, обновляем rect, проставляем top
                    triangle.classList.add(styles.bottom);
                    triangleRect = triangle.getBoundingClientRect();
                    triangle.style.top = `${ top + tooltipRect.height }px`;
                }
                else {
                    top = top - (top + tooltipRect.height - window.scrollY - windowHeight);
                    // проставляем top для тултипа
                    tooltip.style.top = `${ top }px`;
                    // прячем уголок
                    triangle.style.display = 'none';
                }
            }
        }
        else if(this.checkPlacing('top')){
            // позиционируем сверху
            let top = window.scrollY + parentRect.top - (parentRect.height + offset);

            if(parentRect.top > tooltipRect.height + offset) { // если достаточно места сверху
                top = window.scrollY + parentRect.top - (tooltipRect.height + offset);
                // проставляем top для тултипа
                tooltip.style.top = `${ top }px`;

                // разворачиваем уголок вниз, обновляем rect, проставляем top
                triangle.classList.add(styles.bottom);
                triangleRect = triangle.getBoundingClientRect();
                triangle.style.top = `${ top + tooltipRect.height }px`;
            }
            else {
                let top = window.scrollY + parentRect.top + parentRect.height + offset;

                if(top + tooltipRect.height - window.scrollY < windowHeight) {// если достаточно места снизу
                    // проставляем top для тултипа
                    tooltip.style.top = `${ top }px`;

                    // разворачиваем уголок вверх, обновляем rect, проставляем top
                    triangle.classList.add(styles.top);
                    triangleRect = triangle.getBoundingClientRect();
                    triangle.style.top = `${ top - triangleRect.height }px`;
                }
                else { // если места снизу нет
                    top = top - (top + tooltipRect.height - window.scrollY - windowHeight);
                    // проставляем top для тултипа
                    tooltip.style.top = `${ top }px`;
                    // прячем уголок
                    triangle.style.display = 'none';
                }
            }
        }
        else {
            // позиционируем по бокам
            if (tooltipRect.height + offset > windowHeight) { // если высота тултипа больше высоты окна
                tooltip.style.top = `${ window.scrollY + offset / 2 }px`;
                tooltip.style.height = `${ windowHeight - offset }px`;
            }
            else {
                let top = window.scrollY + parentRect.top + parentRect.height / 2 - tooltipRect.height / 2;
                if (top - window.scrollY < 0) { // если тултип вылезает за верх окна
                    top = window.scrollY + offset / 2;
                }
                else if (top + tooltipRect.height - window.scrollY > windowHeight) { // если тултип вылезает за низ окна
                    top = top - (top + tooltipRect.height - window.scrollY - windowHeight) - offset / 2;
                }
                // проставляем top для тултипа
                tooltip.style.top = `${ top }px`;
            }
            // расчитываем и проставляем top для уголка
            triangle.style.top = `${ window.scrollY + parentRect.top + parentRect.height / 2 - triangleRect.height / 2 }px`;
        }


        // рассчитываем left
        if(this.checkPlacing('bottom') || this.checkPlacing('top') || this.checkPlacing('corner')){
            // позиционируем снизу/сверху
            let left = parentRect.left + (parentRect.width/2 - tooltipRect.width/2);
            let leftTriangle = parentRect.left + (parentRect.width/2 - triangleRect.width/2);

            if(this.checkPlacing('corner')){ // по левому краю
                left = parentRect.left;
                leftTriangle = parentRect.left + offset;
            }

            if(left + tooltipRect.width + offset > windowWidth) {// если вылезает за правый край экрана - смещаем влево
                left = left - (left + tooltipRect.width + offset - windowWidth);
            }
            else if(left < offset/2) {// если вылезает за левый край - смещаем вправо
                left = offset/2;
            }

            // проставляем left для тултипа и уголка
            tooltip.style.left = `${ left }px`;
            triangle.style.left = `${ leftTriangle }px`;
        }
        else {
            // позиционируем по бокам
            if(this.checkPlacing('left')){// позиционируем слева
                let left = parentRect.left - (tooltipRect.width + offset);
                let leftTriangle = left + tooltipRect.width;

                if (parentRect.left > tooltipRect.width) { // если тултип помещается слева от иконки
                    // разворачиваем уголок вправо
                    triangle.classList.add(styles.right);

                    // проставляем left для тултипа и уголка
                    tooltip.style.left = `${ left }px`;
                    triangle.style.left = `${ leftTriangle }px`;
                }
                else {
                    let left = parentRect.left + parentRect.width + offset;
                    let leftTriangle = left - triangleRect.width;
                    if (left + tooltipRect.width < windowWidth){ // если тултип помещается справа
                        // проставляем left для тултипа и уголка
                        tooltip.style.left = `${ left }px`;
                        triangle.style.left = `${ leftTriangle }px`;
                        triangle.classList.add(styles.left);
                    }
                    else {
                        // если тултип не помещается справа целиком
                        left = left - (left + tooltipRect.width - windowWidth) - offset / 2;

                        // проставляем left для тултипа
                        tooltip.style.left = `${ left }px`;
                        // прячем уголок
                        triangle.style.display = 'none';
                    }
                }
            }
            else {// позиционируем справа
                let left = parentRect.left + parentRect.width + offset;
                let leftTriangle = left - triangleRect.width;
                if (left + tooltipRect.width > windowWidth) { // если тултип вылезает за правый край окна
                    if (parentRect.left > tooltipRect.width) { // если тултип помещается слева от иконки
                        left = parentRect.left - (tooltipRect.width + offset);
                        leftTriangle = left + tooltipRect.width;

                        // разворачиваем уголок вправо
                        triangle.classList.add(styles.right);

                        // проставляем left для тултипа и уголка
                        tooltip.style.left = `${ left }px`;
                        triangle.style.left = `${ leftTriangle }px`;
                    }
                    else { // если тултип не помещается слева целиком - сдвигаем его влево так, чтобы он не залезал за край окна
                        left = left - (left + tooltipRect.width - windowWidth) - offset / 2;

                        // проставляем left для тултипа
                        tooltip.style.left = `${ left }px`;
                        // прячем уголок
                        triangle.style.display = 'none';
                    }
                }
                else {
                    // проставляем left для тултипа и уголка
                    tooltip.style.left = `${ left }px`;
                    triangle.style.left = `${ leftTriangle }px`;
                    triangle.classList.add(styles.left);
                }
            }
        }
    };

    render() {
        const { children } = this.props;

        if (!children) return null;

        return (
            <GlobalPortal>
                <div className={ styles.Tooltip } ref={ this.refTooltip } data-test={ATTRIBUTES.tooltip}>
                    { children }
                </div>
                <div className={ styles.Triangle } ref={ this.refTriangle } />
            </GlobalPortal>
        );
    }
}
