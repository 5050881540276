import * as React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import styles from './styles.module.scss';
import Button from 'components/button';
import Checkbox from 'components/dynamicForms/view/field/checkbox';

import langStore from 'globalState/lang';
import IconX from 'assets/img/icons/close-x.svg';
import ModalWrapper from 'components/modalWrapper';
import { ATTRIBUTES } from 'constants/attributesForTests';
import cache from 'globalState/cache';
import { fetchElevateRoles } from 'actions/roles';
import moment from 'moment';
import userElevateState from 'globalState/user/elevate';


@observer
export default class ElevatePopup extends React.Component {
    @observable sections = {};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.show) {
            this.getData();
        }
    }

    componentDidUpdate(prevProps) {
        const { show } = this.props;
        if (show && !prevProps.show) {
            this.getData();
        }
    }

    componentWillUnmount = () => {

    };

    @action
    getData = async () => {
        const response = await fetchElevateRoles();
        if (response && response.isOkStatus) {
            this.sections = response.data;
        }
    };

    handleSubmitClick = async () => {
        if (this.props.onClose) this.props.onClose();
        cache.deleteAllByPrefix();
        window.localStorage.setItem('elevateRoles', JSON.stringify(this.sections));
        window.localStorage.setItem('elevateRolesTime', moment().format('x'));
        userElevateState.setIsElevate(true);
        window.location.reload();
    };

    handleClose = () => {
        if (this.props.onClose) this.props.onClose();
    };

    onChange = (id) => ({ value }) => {
        if (value) {
            this.sections[id] = 'on';
        }
        else {
            this.sections[id] = 'off';
        }
    };

    renderRole = (name, value) => {
        const checked = value === 'on' ? 1 : 0;
        return (
            <div className={ styles.rolesItem } key={name}>
                <div className={ styles.rolesItemCheckbox }>
                    <Checkbox id={ name } value={ checked } onChange={ this.onChange(name) } />
                </div>
                <div className={ styles.rolesItemLabel }>
                    <span
                        onClick={()=>{
                            this.onChange(name)({ value: !checked });
                        }}
                    >{name}</span>
                </div>
            </div>
        );
    };

    renderRoles = () => {
        const result = [];
        for (const key in  this.sections) {
            if (typeof this.sections[key] != 'function') {
                result.push(this.renderRole(key, this.sections[key]));
            }
        }
        return result;
    };

    render() {
        const { user_menu_titles } = langStore.getTranslate();

        if (!this.props.show) {
            return null;
        }

        const content = this.sections ? (
            this.sections !== [] ? (this.renderRoles()) : (<div> No roles</div>)
        ) : (
            <div className={ styles.preloader } />
        );

        return (
            <ModalWrapper>
                <div className={ styles.popUp }>
                    <div className={ styles.popUpOverlay } onClick={ this.handleClose } />
                    <div className={ styles.popUpContainer }>
                        <div className={ styles.header } data-test={ ATTRIBUTES.elevatePopupHeader }>
                            <div className={styles.headerText}>{ user_menu_titles && user_menu_titles.elevate_roles }</div>
                            <Button onClick={ this.handleClose } className={ styles.close } svg={IconX} buttonType={'icon'}/>
                        </div>

                        <div className={ styles.content } data-test={ ATTRIBUTES.elevatePopupContent }>
                            { content }
                        </div>

                        <div className={ styles.footer } data-test={ ATTRIBUTES.elevatePopupFooter }>
                            <Button onClick={ this.handleClose }>{ user_menu_titles && user_menu_titles.cancel_button }</Button>
                            { this.sections && this.sections.length !== [] && <Button onClick={ this.handleSubmitClick } buttonType='primary'>{ user_menu_titles && user_menu_titles.submit_button }</Button> }
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        );
    }
}
