import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import styles from 'components/dynamicForms/view/field/image/preview/styles.module.scss';
import IconImage from 'assets/img/icons/image.svg';

import IconDownload from 'assets/img/icons/download.svg';
import IconClose from 'assets/img/icons/close-x.svg';
import User from 'globalState/user';
import { API_BASE_URL } from 'lib/apiRequest';
import MobileModal from 'components/modalWrapper';

/***
 * Описание: Превью картинки
 * Параметры:
 * src: {required, type: string} - путь до картинки
 * name: {required, type: string} - имя картинки
 * onClose: {required, type: function} - метод, вызываемый при закрытии
 *
 */

@observer
export default class PreviewComponent extends React.Component {
    @observable showLoader = false;
    timeout = null;
    refImage = React.createRef();
    refHeader = React.createRef();
    refContent = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.body.classList.add(styles.BodyFreeze);
        document.addEventListener('click', this.onDocumentClick, true);
        document.addEventListener('keydown', this.onDocumentKeyDown);

        this.timeout = setTimeout(()=>{
            this.showLoader = true;
        },500);

        const imageEl = this.refImage.current;
        const contentEl = this.refContent.current;
        if (imageEl && contentEl) {
            imageEl.addEventListener('load', () => {
                contentEl.classList.add(styles.loaded);
                clearTimeout(this.timeout);
                this.showLoader = false;
            });
        }
    }

    componentWillUnmount() {
        document.body.classList.remove(styles.BodyFreeze);
        document.removeEventListener('click', this.onDocumentClick, true);
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    }

    onDocumentClick = (e) => {
        const imageEl = this.refImage.current;
        const headerEl = this.refHeader.current;
        if (!imageEl || !headerEl) return;

        if (!imageEl.contains(e.target) && !headerEl.contains(e.target)) {
            this.onClose();
        }
    };

    onDocumentKeyDown = ({key}) => {
        if(key === 'Escape'){
            this.onClose();
        }
    };

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    onDownloadFileClick = (e) => {
        e.stopPropagation();
        const { downloadUrl, fileId }  = this.props;
        window.location = API_BASE_URL + `/${ downloadUrl }/download/${ fileId }?access-token=${ User.accessToken }`;
    };

    render() {
        const { src, name } = this.props;
        return (
            <MobileModal>
                <div className={ styles.Preview }>
                    <div className={ styles.Content } ref={ this.refContent }>
                        { this.showLoader && <div className={ styles.Loader } /> }
                        <img src={ src } ref={ this.refImage } />
                    </div>
                    <div className={ styles.Header } ref={ this.refHeader }>
                        <div className={ styles.Title }>
                            <div className={ `${styles.Icon} ${styles.noHover}` } dangerouslySetInnerHTML={ { __html: IconImage } } />
                            { name }
                        </div>
                        <div className={ styles.Buttons }>
                            {/*<a href={ this.props.src } target="_blank" className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconExternal } } />*/}
                            <div onClick={ (e) => this.onDownloadFileClick(e) } className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconDownload } } />
                            <div
                                className={ styles.Icon }
                                dangerouslySetInnerHTML={ { __html: IconClose } }
                                onClick={ this.onClose }
                            />
                        </div>
                    </div>
                </div>
            </MobileModal>
        );
    }
}
