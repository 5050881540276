import BasePortalDesignerModel from './base';
import { observable } from 'mobx';

/**
 * модель виджета
 */
export default class WidgetInstancePortalDesignerModel extends BasePortalDesignerModel {
    TABLE_NAME = 'sys_widget_instance';
    TYPE = 'widget_instance';

    @observable widget = null;
    schemaOptions = {};

    constructor(params) {
        super(params);
        this.setData(params);
    }

    setData(params) {
        super.setData(params);

        this.schemaOptions = params.schema_options;
        this.widget = params.widget;
    }
}
