import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import * as _ from 'lodash';

import styles from 'components/dynamicForms/view/field/url/styles.module.scss';
import LockIcon from 'assets/img/icons/lock.svg';
import UnlockIcon from 'assets/img/icons/unlock.svg';

import StringInput from 'components/dynamicForms/view/field/stringInput';
import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import Button from 'components/button';
import URLModel from 'components/dynamicForms/model/field/URLModel';
import StringInputModel from 'components/dynamicForms/model/field/StringInputModel';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { isChanged } from 'helpers/form';


@observer
export class URL extends React.Component {
    @observable model;
    @observable isEdit = false;
    isDomain = true;
    /**
     * @type StringInputModel
     */
    stringInputModel;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new URLModel(props);
        }

        this.stringInputModel = new StringInputModel({
            placeholder: window.location.origin + '/',
            value: this.model.value,
            isMandatory: this.model.isMandatory,
            readonly: this.model.readonly,
        });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
        this.stringInputModel.mergeData({
            value: this.model.value,
            isMandatory: this.model.isMandatory,
            readonly: this.model.readonly,
        });
    }

    /*
     uiShowFieldMsg(message, type){
     this.fieldMessage = {
     message: message,
     type: type,
     };
     }
     */

    onClick = (e) => {
        e.preventDefault();
        this.isEdit = !this.isEdit;
    };


    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.isEdit = false;
        }
    };

    /**
     *
     * @param stringInputModel {StringInputModel}
     */
    onChange = (stringInputModel) => {
        let value = stringInputModel.value;
        if (this.isDomain && !this.model.value) {
            value = window.location.origin + '/' + value;
            stringInputModel.value = value;
        }
        this.isDomain = true;

        this.model.value = value;
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        if (this.props.onChange) {
            this.props.onChange(this.model);
        }
    };

    onPaste = () => {
        this.isDomain = false;
    };

    renderLink() {
        const value = this.model.value && this.model.value.trim() !== '';

        return (
            <div className={ `${styles.linkWrap} ${this.model.readonly ? styles.readOnly : ''}` }>
                { value && (
                    <a className={ styles.link } href={ this.model.value }>
                        { this.model.getShortValue() }
                    </a>
                )}
            </div>
        );
    }

    renderStringInput() {
        return (
            <StringInput
                onPaste={ this.onPaste }
                onChange={ this.onChange }
                onKeyPress={ this.onKeyPress }
                model={ this.stringInputModel }
            />
        );
    }

    render() {
        const icon = this.isEdit ? UnlockIcon : LockIcon;
        let classes = [styles.URL];

        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldUrl }` : `${ ATTRIBUTES.fieldUrl }`;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ classes.join(' ') }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    {!this.model.readonly && <Button className={ styles.button } onClick={ this.onClick } buttonType={ 'icon-border' } svg={ icon } />}
                    {
                        this.isEdit ? this.renderStringInput() : this.renderLink()
                    }
                </div>
            </FieldWrapper>
        );
    }
}


export default React.forwardRef((props, ref) => <URL
    innerRef={ ref } { ...props }
/>);
