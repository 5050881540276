import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import IconCheck from 'assets/img/checkbox.svg';
import IconSearch from 'assets/img/icons/search.svg';
import IconClose from 'assets/img/icons/close-x.svg';
import Badges from './badges';
import Dropdown from 'components/dropdown';
import styles from './styles.module.scss';
import { isEqual } from 'helpers/data';
import langStore from 'globalState/lang';

/***
 * Описание: мультиселект
 * Праметры:
 * @param {array} values - массив выбранных значений
 * @param {array} options - массив доступных значений
 * @method onChange - метод вызываемый при изменении выбранных значений
 * @param {boolean} [canExcludeAll] - флаг можно ли снять выбор со всех
 * @param {boolean} [isReadOnly] - readonly
 * @param {boolean} [isHideHeader]
 * @param {string} [className]
 * @method onClose
 * @method onRemoveItem
 */

@observer
export default class MultiSelectComponent extends React.Component {
    @observable isFocused = false;
    @observable isOpened = false;
    @observable isFilterIconHovered = false;
    @observable isAllSelected = false;
    @observable values = [];
    @observable options = [];
    @observable optionsFiltered = [];
    @observable filterValue = '';
    refFilter = React.createRef();
    refInput = React.createRef();
    refDropdown = React.createRef();

    constructor(props) {
        super(props);

        const { values, options } = props;
        this.values = values ? this.getInitValues(values) : [];
        this.options = options ? options : [];

        reaction(
            () => this.values.length,
            () => {
                if (this.props.onChange) {
                    this.props.onChange({ value: this.values });
                }
            },
        );
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocumentClick);
        window.addEventListener('scroll', this.onWindowScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onDocumentClick);
        window.removeEventListener('scroll', this.onWindowScroll);
    }

    componentDidUpdate(prevProps) {
        const { values, options } = this.props;

        if (!isEqual(values, prevProps.values)) {
            this.values = values ? this.getInitValues(values) : [];
        }
        if (!isEqual(options, prevProps.options)) {
            this.options = options ? options : [];
        }
    }

    getInitValues = (values) => {
        if (typeof values === 'string') {
            if (values.search(/{?\[/) !== -1) {
                return JSON.parse(values);
            }
            else {
                return [values];
            }
        }
        else {
            return values || [];
        }
    };

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const inputEl = this.refInput.current;
        if (!dropdownEl || !inputEl) return false;

        if (!dropdownEl.contains(e.target) && !inputEl.contains(e.target)) {
            const { onClose } = this.props;
            if (this.isOpened && onClose) {
                onClose(this.values);
            }
            this.isOpened = false;
        }
    };

    onWindowScroll = () => {
        const { onClose } = this.props;
        if (this.isOpened && onClose) {
            onClose(this.values);
        }
        this.isOpened = false;
    };

    renderOptionsList(noResultsText) {
        const optionsFiltered = this.filterValue ? this.options.filter(this.matchUpWithFilter) : [...this.options];
        if (this.filterValue && optionsFiltered.length === 0) {
            // если в фильтр что-то введено и соответствий нет
            return (
                <div className={ styles.List }>
                    <div className={ styles.NoResults }>{ noResultsText }</div>
                </div>
            );
        }

        const items = optionsFiltered.map((item, index) => {
            const isActive = this.values.includes(item.database_value);
            let onClick = isActive ? () => this.removeItem(item) : () => this.addItem(item);

            if (this.values.length === 1 && isActive && !this.props.canExcludeAll) {
                onClick = null;
            }

            return (
                <div key={ index } className={ styles.Option }>
                    <div onClick={ onClick } className={ `${ styles.Checkbox } ${ isActive ? styles.active : '' }` } dangerouslySetInnerHTML={ { __html: IconCheck } } />
                    <div onClick={ onClick } className={ styles.OptionText }>
                        { item.values ? item.values.map((value, index, values) => {
                                return (
                                    <span
                                        key={ index }
                                        title={ value.hint }
                                    >
                                        { value.display_value }{ values.length - 1 !== index ? ' / ' : '' }
                                    </span>
                                );
                            },
                        ) : item.display_value }
                    </div>
                </div>
            );
        });

        return <div className={ styles.List }>{ items }</div>;
    }

    matchUpWithFilter = (option) => {
        return option.display_value.toLowerCase().includes(this.filterValue.toLowerCase());
    };

    renderHeader = (selectAllText, phText) => {
        const { isHideHeader } = this.props;
        if (isHideHeader) {
            return null;
        }
        return (
            <div className={ styles.Header }>
                <div className={ styles.Filter }>
                    <div className={ styles.Wrapper }>
                        <input
                            type="text"
                            ref={ this.refFilter }
                            value={ this.filterValue }
                            onChange={ (e) => {
                                this.filterValue = e.target.value;
                            } }
                            placeholder={ phText }
                        />
                    </div>


                    {/*иконка и сама кнопка специально разнесены на разные элементы
                     для того чтобы корректно отрабатывал метод onDocumentClick при очистке фильтра*/ }
                    <div
                        className={ `${ styles.Icon } ${  !this.filterValue ? styles.disabled : '' }  ${ this.isFilterIconHovered ? styles.hover : '' }` }
                        dangerouslySetInnerHTML={ { __html: this.filterValue ? IconClose : IconSearch } }
                    />
                    <div
                        className={ `${ styles.IconBtn }  ${  !this.filterValue ? styles.disabled : '' }` }
                        onClick={ this.onFilterIconClick }
                        onMouseEnter={ () => {
                            this.isFilterIconHovered = true;
                        } }
                        onMouseLeave={ () => {
                            this.isFilterIconHovered = false;
                        } }
                    />
                </div>
                { this.options.length > 3 && (
                    <div className={ styles.SelectAll }>
                        <div onClick={ this.onSelectAllClick } className={ `${ styles.Checkbox } ${ this.isAllSelected ? styles.active : '' }` } dangerouslySetInnerHTML={ { __html: IconCheck } } />
                        <div onClick={ this.onSelectAllClick } className={ styles.OptionText }>
                            { selectAllText }
                        </div>
                    </div>
                ) }
            </div>
        );
    };

    onSelectAllClick = () => {
        if (this.isAllSelected) {
            // если не разрешено снимать все - оставляем первую
            this.values = this.props.canExcludeAll ? [] : [this.options[0].database_value];
        }
        else {
            this.values = this.options.map(item => item.database_value);
        }
        this.isAllSelected = !this.isAllSelected;
    };

    onFilterIconClick = () => {
        if (this.filterValue) {
            this.filterValue = '';
        }
        this.refFilter.current.focus();
    };

    onInputClick = () => {
        const { onClose } = this.props;
        if (this.isOpened && onClose) {
            onClose(this.values);
        }
        this.isOpened = !this.isOpened;
    };

    removeItem = (item) => {
        this.values = this.values.filter((value) => value !== item.database_value);
        const { onRemoveItem } = this.props;
        if (onRemoveItem) {
            onRemoveItem(this.values);
        }
    };

    addItem = (item) => {
        this.values.push(item.database_value);
    };

    renderReadonlyList = (options) => {
        const items = options.map((item) => {
            return (
                <div key={ item.database_value } className={ styles.Badge }>
                    { item.display_value }
                </div>
            );
        });
        return <div className={ styles.Badges }>{ items }</div>;
    };

    render() {
        const { isReadOnly, canExcludeAll, className, placeholder } = this.props;
        if (isReadOnly) {
            return this.renderReadonlyList(this.options.filter(item => this.values.includes(item.database_value)));
        }
        const { multi_select_titles: titles } = langStore.getTranslate();
        const placeholderItem = placeholder && _.isEmpty(this.values) ? <span className={ styles.Placeholder }>{ placeholder }</span> : null;

        return (
            <div className={ `${ styles.Wrapper }  ${ className }` }>
                <button
                    type='button'
                    className={ styles.Input }
                    onClick={ this.onInputClick }
                    onFocus={ () => this.isFocused = true }
                    onBlur={ () => this.isFocused = false }
                    ref={ this.refInput }
                >

                    { placeholderItem }
                    <Badges
                        canExcludeAll={ canExcludeAll }
                        values={ this.options.filter(item => this.values.includes(item.database_value)) }
                        onRemoveItem={ this.removeItem }
                    />
                </button>

                { this.isOpened &&
                <Dropdown refParent={ this.refInput } ref={ this.refDropdown }>
                    <div className={ `${ styles.Body } ` }>
                        { this.renderHeader(titles?.select_all, titles?.enter_a_value) }
                        { this.renderOptionsList(titles && titles.no_results) }
                    </div>
                </Dropdown>
                }
            </div>
        );
    }
}
