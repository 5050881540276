import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from 'components/groupedTable/styles.module.scss';
import MetaCell from 'components/groupedTable/metaCell/index';
import Cell from 'components/groupedTable/cell/index';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { TableRowProps } from 'types/components/table/row';
import { DatabaseValueType } from 'types/components/table/cell';

const FIELD_ID = 'sys_id';

@observer
export default class Row extends React.Component<TableRowProps> {
    @observable checked = 0;

    getDatabaseValue = (field, value): DatabaseValueType | string | null => {
        if (field.type === 'choice') {
            if (typeof value === 'string') {
                const findValue = field.special.values.find(opt => opt.display_value === value);
                return findValue ? findValue.database_value : '';
            }
            return value && value.value && (typeof value.value.database_value !== undefined) ? value.value.database_value : value;
        }
        if (field.type === 'list') {
            if (value && value.value && Array.isArray(value.value) && value.value.length > 0) {
                const values = value.value.map(v => v.sys_id);
                return values.join('@');
            }
            return null;
        }
        return value;
    };

    items = (label) => {
        const { items, rowIndex, classes, formId, relatedListId } = this.props;

        return this.props.table.props.columns.map((column, index, columns) => {
            const field = column.attribute;
            const item = items[field];
            const row = {};
            for (const key in items) {
                if (items.hasOwnProperty(key)) {
                    columns.forEach(column => {
                        const isDwaColumn = column.origin_column_id !== column.db_column_id;
                        if (!isDwaColumn && key === column.attribute) {
                            if (items[key].value && typeof items[key].value === 'object') {
                                row[key] = items[key].value.sys_id || items[key].value.database_value || '';
                            }
                            else {
                                row[key] = items[key].value;
                            }
                        }
                    });
                }
            }
            if (item === undefined) {
                return;
            }

            let refLink = '';
            let recordLink = '';
            if (index === 0) { // если это первая колонка - делаем её ссылкой на запись
                recordLink = this.getRecordLink();
            }
            else {
                if (column.type === 'reference' && row[column.attribute]) { // если это референсная колонка - делаем её референсной ссылкой
                    const tableName = item.table_name ? item.table_name : column.special.table_name;
                    const essence = row[column.attribute];
                    refLink = this.props.isServicePortal ? `/sp/record/${ tableName }/${ essence }` : `/record/${ tableName }/${ essence }`;
                }
            }

            const classNames = [ styles[field] ];
            if (classes && classes.Cell) {
                classNames.push(classes.Cell);
            }

            return (
                <Cell
                    key={ `${ field }${ index }` }
                    id={ items[FIELD_ID] }
                    data={ item }
                    database_value={ this.getDatabaseValue(column, item) }
                    columns={ columns }
                    label={ label }
                    db_column_name={ column.db_column_name }
                    db_column_id={ column.db_column_id }
                    columnLabel={ column.label }
                    field={ field }
                    table={ this.props.table }
                    table_name={ this.props.table.props.essence }
                    record_table_name={ this.props.items.__table_name }
                    type={ this.props.type || '' }
                    column_type={ column.type }
                    className={ classNames.join(' ') }
                    filterFields={ this.props.filterFields }
                    conditionState={ this.props.conditionState }
                    isServicePortal={ this.props.isServicePortal }
                    rowValues={ row }
                    rowIndex={ rowIndex }
                    refLink={ refLink }
                    recordLink={ recordLink }
                    validate={ column.validate }
                    clientScripts={ this.props.clientScripts }
                    isMobile={ this.props.isMobile }
                    fieldInfo={ column.field_info }
                    formId={ formId }
                    relatedListId={ relatedListId }
                    special={ column.special }
                />
            );
        });
    };

    getRecordLink = () => {
        let linkUrl = this.props.isServicePortal ?
            `/sp/record/${ this.props.items.__table_name }/${ this.props.items[FIELD_ID] }` :
            `/record/${ this.props.items.__table_name }/${ this.props.items[FIELD_ID] }`;

        // если мы не внутри рекорда и это не сервисный портал.
        // добавление параметра для определения активного пункта меню
        if (this.props.table.props.location && this.props.table.props.location.pathname.split('/')[1] !== 'record' && !this.props.isServicePortal) {
            const menuId = new URLSearchParams(this.props.table.props.location.search).get('menu_id');
            if (menuId) { // сохраняем id активного пункта меню
                const urlSpl = linkUrl.split('?');
                const location = urlSpl[0];
                const search = urlSpl.slice(1).join('&');
                const usp = new URLSearchParams(search);
                usp.set('menu_id', menuId);
                const resultSearchParams = usp.toString();
                linkUrl = location + '?' + resultSearchParams;
            }
        }

        return linkUrl;
    };

    render() {
        const { classes, items, table, usedByList, isServicePortal, isRelatedList, isMobile, isBlankMode, isWindow, onCheckRow } = this.props;
        const trClassName: string[] = [];

        if (isServicePortal) {
            trClassName.push(styles.rowSP);
        }
        if (isRelatedList) {
            trClassName.push(styles.rowRL);
        }
        if (isWindow) {
            trClassName.push(styles.hasHover);
        }
        const itemId = items[FIELD_ID];
        const value = table._checked ? table._checked.has(itemId) : false;
        if (value) trClassName.push(styles.selectedRow);

        const metaCellClass = classes && classes.InfoCell ? `${ classes.InfoCell }` : '';
        const label = { value: items.__display_value, reference_state: items.__reference_state, has_delegate: items.__has_delegate  };
        return (
            <tr
                className={ trClassName.join(' ') }
                data-test={ ATTRIBUTES.tableRow }
            >
                { !isMobile && <MetaCell
                    id={ itemId }
                    label={ label }
                    tableName={ table.props.essence || items.table_name }
                    onCheckRow={ onCheckRow }
                    value={ value }
                    className={ metaCellClass }
                    isBlankMode={ isBlankMode }
                    isWindow={ isWindow }
                    usedByList={ usedByList }
                    isServicePortal={ isServicePortal }
                />
                }
                { this.items(label) }
            </tr>
        );
    }
}