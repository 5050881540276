import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import _ from 'lodash';
import styles from './styles.module.scss';
import TabsSlider from 'components/tabs/slider';
import Heading from 'components/heading';
import FormsState from 'globalState/forms';
import { ATTRIBUTES } from 'constants/attributesForTests';


@observer
export class Tabs extends React.Component {
    @observable active = '';
    @observable tabs = [];
    @observable isSliderOn = false;
    refActiveTab = React.createRef();

    constructor(props) {
        super(props);
        this.tabs = this.props.tabs;
        this.tabs.forEach((tab) => {
            tab.isVisible = true;
        });
    }

    componentDidUpdate(prevProps) {
        // Проверяет, изменилось ли количество или имена табов (_.isEqual смотрит слишком глубоко)
        const isDeepEqual = prevProps.tabs.length === this.props.tabs.length &&
            prevProps.tabs.every((value, index) =>
                value.name === this.props.tabs[index].name &&
                value.isWarning === this.props.tabs[index].isWarning &&
                value.isMandatory === this.props.tabs[index].isMandatory &&
                _.isEqual(value.value.props.contextMenu, this.props.tabs[index].value.props.contextMenu),
            );

        const isEqualFields = _.isEqual(prevProps.allFields, this.props.allFields);

        if (!isDeepEqual) {
            this.tabs = this.props.tabs.map((propTab, index) => {
                if (!isEqualFields) {
                    propTab.isVisible = true;
                }
                return _.defaults(propTab, this.tabs[index]);
            });
        }

        if (this.tabs.length) {
            const findActiveTab = this.tabs.find(tab => this.active === tab.serviceName);
            const isActiveAndDisableTab = findActiveTab && !findActiveTab.isVisible;
            const lastVisibleTab = this.tabs.find(tab => tab.isVisible);

            if (!findActiveTab || isActiveAndDisableTab) {
                const firstTab = this.tabs[0];
                if (firstTab.isVisible) {
                    this.active = firstTab.serviceName;
                }
                else if (lastVisibleTab) {
                    this.active = lastVisibleTab.serviceName;
                }
            }
        }
    }

    uiIsSectionVisible(sectionName) {
        const tab = this.tabs.find((tab) => (tab.serviceName === sectionName || tab.name === sectionName));
        return tab ? tab.isVisible : null;
    }

    uiSetSectionDisplay(sectionName, display) {
        const tab = this.tabs.find((tab) => (tab.serviceName === sectionName || tab.name === sectionName));
        if (tab) {
            tab.isVisible = display;
        }
    }

    uiGetSectionNames() {
        return this.tabs.map((tab) => (tab.name));
    }

    uiGetSections() {
        return this.tabs;
    }

    componentDidMount() {
        const {
            tableName,
            widgetId,
            dynamicFormId,
        } = this.props;
        if (!tableName) {
            return;
        }
        FormsState.setTabs(dynamicFormId, this);
        if (widgetId) {
            FormsState.setTabs(widgetId, this);
        }

    }

    componentWillUnmount() {
        const {
            tableName,
            widgetId,
            dynamicFormId,
        } = this.props;
        if (!tableName) {
            return;
        }

        FormsState.deleteTabs(dynamicFormId);
        if (widgetId) {
            FormsState.deleteTabs(widgetId);
        }
    }

    onSliderUpdate = (isSliderOn) => {
        this.isSliderOn = isSliderOn;
    };

    renderPortalTabs = () => {
        return this.tabs.map(tab => {
            const styleHead = tab.isVisible ? {} : {
                display: 'none',
            };

            return (
                <div
                    key={ tab.name }
                    style={ styleHead }
                >
                    { tab.name?.toLowerCase() !== 'default' && (
                        <Heading
                            size={ 'h2' }
                            className={ `${ styles.SectionWidgetHeading }` }
                        >
                            { tab.name }
                        </Heading>
                    ) }
                    { tab.value }
                </div>
            );
        });
    };

    render() {
        if (this.props.isPortal) {
            return this.renderPortalTabs();
        }

        if (this.tabs.length === 0 || (this.tabs.length === 1 && !this.tabs[0].isVisible)) {
            return null;
        }

        let tabChosen = false,
            names = [],
            isActive,
            tabs = [],
            head;

        this.tabs.forEach((tab, index) => {
            isActive = false;
            if (!tabChosen && tab.isVisible && (!this.active || this.active === tab.serviceName)) {
                isActive = true;
                tabChosen = true;
            }
            const styleHead = tab.isVisible ? {} : {
                display: 'none',
            };
            const tabName = tab.isMandatory ? (
                <span className={ `${ styles.Required } ${ tab.isWarning ? styles.Warning : '' }` }>
                    <span>*</span>{ tab.name }
                </span>
            ) : tab.name;
            head = (
                <div
                    ref={ this.active === tab.serviceName ? this.refActiveTab : null }
                    key={ 'head_' + tab.name }
                    onClick={ this.changeTab(tab.serviceName) }
                    className={ `${ styles.TabsHeadItem } ${ isActive ? styles.TabsHeadItemActive : '' }` }
                    style={ styleHead }
                    data-test={ ATTRIBUTES.tabHead }
                >
                    { tabName }
                </div>
            );

            names.push(head);
            tabs.push(<Tab
                key={ 'tab_' + tab.name }
                isFirst={ index === 0 && !this.isSliderOn }
                active={ isActive }
                content={ tab.value }
            />);
        });

        return (
            <div className={ styles.Tabs } data-test={ ATTRIBUTES.tabs }>
                <div className={ styles.TabsHead }>
                    <TabsSlider activeTab={ this.refActiveTab } active={ this.active } onUpdate={ this.onSliderUpdate }>
                        { names }
                    </TabsSlider>
                </div>
                <div>
                    { tabs }
                </div>
            </div>
        );
    }

    changeTab = (key) => () => {
        return this.active = key;
    };
}


@observer
export class Tab extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className={ `${ this.props.active ? styles.TabsItem : styles.TabsInactive } ${ this.props.isFirst ? styles.TabsItemFirst : '' }` }
                data-test={ ATTRIBUTES.tab }
            >
                { this.props.content }
            </div>
        );
    }
}
