import * as React from 'react';
import { observer } from 'mobx-react';
import { Router } from 'react-router';
import _ from 'lodash';
import { Redirect, Route as ReactRouterRoute, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import userState from 'globalState/user';
import modalsDataState from 'globalState/modals';
import { showInfoMessages } from 'helpers/messages';
import { setHistory } from 'helpers/history';

import MainPage from './pages/main';
import ListPage from './pages/list';
import RecordPage from './pages/record';
import LoginPage from './pages/login';
import LogoutPage from './pages/logout';
import ResetPasswordPage from './pages/resetPassword';
import SetNewPasswordPage from './pages/setNewPassword';
import ErrorPage from './pages/ErrorPage';
import SignUpPage from './pages/signup';
import FormLayoutPage from './pages/formLayout';
import ListLayoutPage from './pages/listLayout';
import RelatedListLayoutPage from './pages/relatedListLayout';
import RelatedListEditLayoutPage from 'pages/relatedListEditLayout';
import MainLayout from 'layouts/main';
import BlankLayout from 'layouts/blank';
import AuthLayout from 'layouts/auth';
import NewServicePortalLayout from 'layouts/newServicePortal';
import ServicePortalWidgetsPage from 'pages/servicePortalWidgets';
import ChartPage from 'pages/chart';
import SLMPage from 'pages/slm';
import CMDBPage from 'pages/cmdb';
import Widget from 'pages/widget';
import Report from 'pages/report';
import WorkflowPage from 'pages/workflow';
import SearchPage from 'pages/search';
import UrlActionRunPage from './pages/urlActionRun';
import UntranslatedPage from './pages/untranslated';
import PortalDesigner from 'pages/portalDesigner';
import HeapSelectPage from './pages/heapSelect';
import LdapBrowse from './pages/ldapBrowse';
import AttachmentRedirect from 'components/attachmentRedirect';
import AuthSsoRedirect from 'components/authSsoRedirect';
import UiModal from 'components/uiModal';
import TaskBoardPage from 'pages/taskBoard';

import {ROUTES} from "constants/routers";

const history = createBrowserHistory();
setHistory(history);

@observer
export default class Routes extends React.Component {
    render() {
        return <Router history={ history }>
            <Switch>
                <PrivateRoute path="/" exact><MainLayout><MainPage /></MainLayout></PrivateRoute>

                /* Only login/signup stuff can be accessible without authorization */
                <Route path={ROUTES.LOGIN} exact><AuthLayout><LoginPage /></AuthLayout></Route>
                <Route path={ROUTES.RESET_PASSWORD} exact><AuthLayout><ResetPasswordPage /></AuthLayout></Route>
                <Route path={ROUTES.NEW_PASSWORD} exact><AuthLayout><SetNewPasswordPage /></AuthLayout></Route>
                <Route path={ROUTES.SIGNUP} exact><AuthLayout><SignUpPage /></AuthLayout></Route>
                <Route path={ROUTES.SIDE_DOOR} exact><AuthLayout><LoginPage /></AuthLayout></Route>
                <Route path={ROUTES.LOGOUT} exact><AuthLayout noDefaultBackground><LogoutPage /></AuthLayout></Route>
                <Route path={ROUTES.AUTH_SSO} exact><AuthLayout noDefaultBackground><AuthSsoRedirect/></AuthLayout></Route>
                /* Only login/signup stuff can be accessible without authorization -- END */

                <PrivateRoute path={ROUTES.SEARCH} exact><MainLayout><SearchPage /></MainLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.FORM_LAYOUT} exact><MainLayout><FormLayoutPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.LIST_LAYOUT} exact><MainLayout><ListLayoutPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.LIST_LAYOUT_PERSONAL} exact><MainLayout><ListLayoutPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.LIST_LAYOUT_WITH_FORM} exact><MainLayout><RelatedListLayoutPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.RELATED_LIST_EDIT} exact><MainLayout><RelatedListEditLayoutPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.RELATED_LIST} exact><MainLayout><RelatedListLayoutPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.VISUAL} exact><MainLayout><CMDBPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.SLAM_CHART} exact><MainLayout><SLMPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.HEAP_SELECT} exact><MainLayout><HeapSelectPage /></MainLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.ATTACHMENTS_ROUTE} exact><AttachmentRedirect /></PrivateRoute>

                <PrivateRoute path={ROUTES.SP_NEW} exact><NewServicePortalLayout><ServicePortalWidgetsPage /></NewServicePortalLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.V1_RECORD} exact><MainLayout><RecordPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.RECORD} exact><MainLayout><RecordPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.LIST} exact><MainLayout><ListPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.DICTIONARY_ROUTE} exact><BlankLayout><ListPage isWindow={ true } /></BlankLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.CHART} exact><MainLayout><ChartPage /></MainLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.WIDGET} exact><MainLayout><Widget /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.REPORT} exact><MainLayout><Report /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.WORKFLOW} exact><BlankLayout><WorkflowPage /></BlankLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.WORKFLOW_VIEWER} exact><BlankLayout><WorkflowPage viewer={ true } /></BlankLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.URL_ACTION} exact><MainLayout><UrlActionRunPage /></MainLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.UNTRANSLATED} exact><MainLayout><UntranslatedPage /></MainLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.PORTAL_DESIGNER} exact><BlankLayout><PortalDesigner /></BlankLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.LDAP} exact><MainLayout><LdapBrowse /></MainLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.TASK_BOARD} exact><MainLayout><TaskBoardPage /></MainLayout></PrivateRoute>

                <PrivateRoute path={ROUTES.PAGE} exact><MainLayout><ServicePortalWidgetsPage cmtLayout /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.PAGE_ID} exact><MainLayout><ServicePortalWidgetsPage pageById /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.PORTAL_SUFFIX} exact><NewServicePortalLayout><ServicePortalWidgetsPage /></NewServicePortalLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.NOT_FOUND}><MainLayout><ErrorPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.FORBIDDEN}><MainLayout><ErrorPage /></MainLayout></PrivateRoute>
                <PrivateRoute path={ROUTES.PAGE_TEMPLATE} exact><NewServicePortalLayout><ServicePortalWidgetsPage singlePortal /></NewServicePortalLayout></PrivateRoute>
                <PrivateRoute>
                    <Redirect to={ {
                        pathname: ROUTES.NOT_FOUND,
                    } } />
                </PrivateRoute>
            </Switch>
        </Router>;
    }
}

const PrivateRoute = observer(({ children, ...rest }) => {
    const { initialFetching, user } = userState;

    userState.setAccessError(null);
    const renderFunc = (props) => {
        if (initialFetching === true) return null;

        const messages = window.localStorage.getItem('messages');
        if (messages) {
            showInfoMessages(JSON.parse(messages));
            window.localStorage.removeItem('messages');
        }

        //TODO: перепроверить после исправления ответов api
        if (!_.isEmpty(user) && user.page_access) {
            if (
                props.location.pathname.startsWith(user.page_access.starts_with) ||
                props.location.pathname.startsWith(`${ROUTES.ATTACHMENTS}/`) ||
                props.location.pathname.startsWith(`${ROUTES.DICTIONARY}/`) ||
                props.location.pathname.startsWith(ROUTES.URL_ACTION)
            ) {
                return React.cloneElement(children, props);
            }
            return <Redirect to={ user.page_access.redirect_url } />;
        }

        const isServicePortalPage = props.match && props.match.params && props.match.params.pageTemplatePathName;
        if (!_.isEmpty(user) || (_.isEmpty(user) && isServicePortalPage && !props.match.path.startsWith('/page/'))) {
            return React.cloneElement(children, props);
        }

        if (window.location.pathname + window.location.search !== ROUTES.NOT_FOUND) {
            window.localStorage.setItem('redirect', window.location.pathname + window.location.search);
        }

        return (
            <Redirect to={ {
                pathname: ROUTES.LOGIN,
                state: { from: props.location },
            } } />
        );
    };

    const modals = modalsDataState.getModals().length
        ? (
            _.map(modalsDataState.getModals(), modal => (
                <UiModal
                    modalState={ modal }
                    key={ `modal${ modal.getId() }` }
                />
            ))
        )
        : null;
    return (
        <>
            <ReactRouterRoute { ...rest } render={ renderFunc } />
            { modals }
            <UiModal key="firstModal" />
        </>
    );
});

const Route = observer(({ children, ...rest }) => {
    const { user, authorized } = userState;
    const redirectTo = window.localStorage.getItem('redirect');

    const renderFunc = (props) => {

        //TODO: перепроверить после исправления ответов api
        if (!_.isEmpty(user) && user.page_access) {
            return <Redirect to={ user.page_access.redirect_url } />;
        }
        else if (authorized) {
            return <Redirect to={ redirectTo ? redirectTo : '/' } />;
        }
        else {
            return React.cloneElement(children, props);
        }
    };

    const modals = modalsDataState.getModals().length
        ? (
            _.map(modalsDataState.getModals(), modal => (
                <UiModal
                    modalState={ modal }
                    key={ `modal${ modal.getId() }` }
                />
            ))
        )
        : null;
    return (
        <>
            <ReactRouterRoute { ...rest } render={ renderFunc } />
            { modals }
            <UiModal key="firstModal" />
        </>
    );
});

