import { observable } from 'mobx';
import { getUrlParams } from 'helpers/data';
import _ from 'lodash';
import { fetchBreadcrumbs } from 'actions/widgets';
import EventBusState from 'globalState/eventBus';
import { helperRedirect } from 'helpers/history';
import { eventType } from 'constants/eventBusTypes';
import { getMenuType } from 'helpers/widget';

const CURRENT_NODE = 'currentNode';
const PREVIOUS_NODE = 'previousNode';

export default class BreadcrumbsState {
    @observable data = [];

    constructor(location) {
        const url = location.pathname.split('/');
        this.urlSuffix = url[1];
        this.pathName = url[2];
        const searchParams = getUrlParams(location.search);

        this.fetchBreadcrumbs(searchParams.category, searchParams.item).catch(console.error);
    }

    onNavigate() {
        // Подписываемся на событие
        const result = EventBusState.on(eventType.NAVIGATE, this.onElementChange);
        this.unSubscribe = result.unsubscribe;
    }

    /**
     * Метод срабатываает при получнии события о смене элемента
     * @param data - данные события
     */
    onElementChange = (data) => {
        this.fetchBreadcrumbs(data.category_id, data.item_id).catch(console.error);
    };

    /**
     * Метод срабатывает при смене текущего элемента
     * @param element - новый элемент
     */
    beforeElementChange(element) {
        return () => {
            // Получаем информацию об элементе из url'а
            const arrLocation = element.url.split('?');
            const searchParams = getUrlParams(arrLocation.length === 2 ? arrLocation[1] : '');

            // Генерируем событие, если произошёл переход между элементами, у которых гарантированно один page_template
            helperRedirect(element.url, null, { preventReload: true }); // Предотвращаем перезагрузку страницы
            EventBusState.emit(eventType.NAVIGATE, { category_id: searchParams.category, item_id: searchParams.item });
        };
    }

    /**
     * Метод подготавливает параметры и получает данные
     * @param categoryId - id категории
     * @param itemId - id айтема
     */
    fetchBreadcrumbs = async (categoryId, itemId) => {
        this.currentElement = {
            nodeId: this.pathName,
            type: getMenuType(categoryId, itemId),
        };
        const fetchParams = _.omitBy({
            url_suffix: this.urlSuffix,
            path_name: this.pathName,
            category_id: categoryId,
            item_id: itemId,
        }, _.isEmpty);
        this.data = await fetchBreadcrumbs(fetchParams);
        this.setCurrentAndPreviousNodes(this.data);
    };

    setCurrentAndPreviousNodes = (data) => {
        if (data.length === 0) {
            return;
        }
        const isPrevious = data.length > 1;
        setTimeout(() => {
            EventBusState.emit(eventType.AFTER_LOAD_BREADCRUMBS, {
                [CURRENT_NODE]: {
                    title: data[data.length - 1].title_or_id,
                    url: data[data.length - 1].url,
                },
                [PREVIOUS_NODE]: {
                    title: isPrevious ? data[data.length - 2].title_or_id : '',
                    url: isPrevious ? data[data.length - 2].url : '#',
                },
            });
        }, 500);
    };

    /**
     * Метод отписывается от события и очищает данные
     */
    clearState() {
        this.unSubscribe();
        this.data = [];
    }
}
