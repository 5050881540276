import {
    ChoiceTitlesType,
    ConditionOperatorTitles,
    DataPickerTitles,
    DataTranslateType,
    DocumentIdTitles,
    DurationTitles,
    FilterTitles,
    ListErrorMessages,
    ListTitles,
    MultiSelectTitles,
    Page403Titles,
    Page404Titles,
    SearchTitlesType,
    SpMainPageType,
    SpSearchType,
    TranslateAuthSsoPageType,
    TranslateBoolType,
    TranslateBrowserUtilitiesType,
    TranslateDateTimeType,
    TranslateFormType,
    TranslateHelpType,
    TranslateItemType,
    TranslateLoginPageType,
    TranslateLogoutPageType,
    TranslateNavigation,
    TranslateNavigationSearchType,
    TranslateNewPasswordPageType,
    TranslatePlaceholdersType,
    TranslatePreferencesType,
    TranslateRecordActions,
    TranslateReference,
    TranslateResetPageType,
    TranslateRichTextType,
    TranslateSearchWidgetTitlesType,
    TranslateSettingsType,
    TranslateSignUpPageType,
    TranslateTemplate,
    TranslateType,
    TranslateWidgetTitlesType,
    UserMenuTitlesType,
    TranslateFormNotificationType,
    TranslateButtonsHints,
} from 'types/translate';
import { observable } from 'mobx';
import _ from 'lodash';

export default class Translate implements TranslateType {
    @observable choice_titles: ChoiceTitlesType;
    @observable search_titles: SearchTitlesType;
    @observable sp_main_page: SpMainPageType;
    @observable sp_search: SpSearchType;
    @observable user_menu_titles: UserMenuTitlesType;
    @observable form: TranslateFormType;
    @observable item: TranslateItemType;
    @observable settings: TranslateSettingsType;
    @observable widget_title: string;
    @observable preferences: TranslatePreferencesType;
    @observable bool: TranslateBoolType;
    @observable condition_operator_titles: ConditionOperatorTitles;
    @observable data_picker_titles: DataPickerTitles;
    @observable date_time: TranslateDateTimeType;
    @observable document_id_titles: DocumentIdTitles;
    @observable duration_titles: DurationTitles;
    @observable filter_titles: FilterTitles;
    @observable list_error_messages: ListErrorMessages;
    @observable list_titles: ListTitles;
    @observable multi_select_titles: MultiSelectTitles;
    @observable logout_page: TranslateLogoutPageType;
    @observable help: TranslateHelpType;
    @observable login_page: TranslateLoginPageType;
    @observable template_titles: TranslateTemplate;
    @observable page_403_titles: Page403Titles;
    @observable page_404_titles: Page404Titles;
    @observable auth_sso_page: TranslateAuthSsoPageType;
    @observable placeholders: TranslatePlaceholdersType;
    @observable reset_page: TranslateResetPageType;
    @observable new_password_page: TranslateNewPasswordPageType;
    @observable sign_up_page: TranslateSignUpPageType;
    @observable browser_utilities: TranslateBrowserUtilitiesType;
    @observable record_actions: TranslateRecordActions;
    @observable navigation_placeholder: TranslateNavigation;
    @observable widget_titles: TranslateWidgetTitlesType;
    @observable search_widget_titles: TranslateSearchWidgetTitlesType;
    @observable reference_titles: TranslateReference;
    @observable navigation_search: TranslateNavigationSearchType;
    @observable rich_text: TranslateRichTextType;
    @observable system_buttons_hints: TranslateButtonsHints;
    @observable form_notification_data: TranslateFormNotificationType;
    @observable no_items: string;
    @observable configure: string;
    @observable validation_field_mandatory: string;
    @observable open_record: string;
    @observable not_set: string;

    merge(data: DataTranslateType) {
        _.forEach(data, (item, key) => {
            this[key] = item;
        });
    }
}
