import * as React from 'react';
import styles from 'components/report/multiLevelPivotTable/styles.module.scss';
import { renderValueCell, trimTwoLinesString, renderFieldDisplayName, onClickCell, getDurationValue } from 'helpers/report/report';
import _ from 'lodash';
import { observer } from 'mobx-react';
import langStore from 'globalState/lang';
import { IS_UPDATE_CHART } from 'globalState/report';
import { simpleHashFromObject } from 'helpers/misc';

/**
 * Описание: отчёт multi-level pivot table
 * Параметры:
 * columnHeaders - заголовки столбцов
 * tableData - строки таблицы с данными, разбитые на блоки
 * dataColumnsAmount - общее кол-во колонок с данными
 * name - название отчёта
 * columnCategories, rowCategories - массивы названий полей, на основании который формируются столбцы и строки таблицы
 * aggregationType - способ агрегирования
 * displayAggregationType - имя для отображения способа агрегирования
 * */

@observer
export default class MultiLevelPivotTable extends React.Component {
    reportState;

    constructor(props) {
        super(props);
        this.reportState = props.reportState;
    }

    componentDidUpdate() {
        if (this.props.isUpdateChart) {
            this.reportState.stateFlags = this.reportState.stateFlags & ~IS_UPDATE_CHART;
        }
    }

    getPrecision() {
        return this.reportState && this.reportState.styleOptions &&
            this.reportState.styleOptions.decimalPrecision;
    }

    renderHeaderRow(curCatLevel, columnCategoriesAmount, rowCategoriesAmount, colCategoryName, colHeaders) {
        const borderClass = columnCategoriesAmount > 1 ? styles.leftBorder : '';
        return <tr key={ 'level_' + curCatLevel }>
            {
                curCatLevel === 0 && rowCategoriesAmount > 1 &&
                <th className={ styles.emptyCell } rowSpan={ columnCategoriesAmount }
                    colSpan={ rowCategoriesAmount - 1 } key={ 'empty' } />
            }
            <th className={ styles.colCategory } key={ 'category' }>
                { trimTwoLinesString(colCategoryName) }
            </th>
            {
                colHeaders[curCatLevel].map((colHeader, colIndex) =>
                    <th className={ styles.colHeader } colSpan={ colHeader.colSpan } key={ colCategoryName + colIndex }>
                        { renderFieldDisplayName(colHeader.colName) }
                    </th>,
                )
            }
            {
                curCatLevel === 0 &&
                <th className={ `${ styles.totalColTd } ${ borderClass }` } rowSpan={ columnCategoriesAmount }
                    key={ 'total' }>
                    { this.props.displayAggregationType }
                </th>
            }
        </tr>;
    }

    renderHeader(rowCategories, columnCategories, colHeaders) {
        return <thead>
        {
            columnCategories.map((colCategoryName, curCatLevel) =>
                this.renderHeaderRow(curCatLevel, columnCategories.length, rowCategories.length, colCategoryName, colHeaders),
            )
        }
        <tr>
            {
                rowCategories.map(rowCategoryName =>
                    <th className={ styles.rowCategory } key={ rowCategoryName }>
                        { trimTwoLinesString(rowCategoryName) }
                    </th>)
            }
            <td colSpan={ _.sumBy(colHeaders[0], 'colSpan') + 1 } />
        </tr>
        </thead>;
    }

    renderRowsBlock(tableData, curColIndex, rowCategoriesAmount, dataColsAmount, parentRows) {
        return tableData.map((row, index) => {
                //const isCollapsed = this.collapsedBranches[row.rowName];
                const newParentRows = index === 0 ? _.concat(parentRows, row) : [row];
                const isTotal = curColIndex < rowCategoriesAmount - 1;
                const typeClass = isTotal ? styles.totalRow : styles.dataRow;
                const firstInBlockClass = index === 0 ? styles.firstRow : '';
                return <React.Fragment key={ row.rowName + index }>
                    {
                        // !isCollapsed &&
                        row.subRows &&
                        this.renderRowsBlock(row.subRows, curColIndex + 1, rowCategoriesAmount, dataColsAmount, newParentRows)
                    }

                    <tr className={ `${ typeClass } ${ firstInBlockClass }` }>
                        {
                            isTotal ?
                                this.renderTotalRow(curColIndex + 1, rowCategoriesAmount, dataColsAmount, row) :
                                this.renderRowValues(dataColsAmount, row, newParentRows)
                        }
                    </tr>
                </React.Fragment>;
            },
        );
    }

    renderTotalRow(curColIndex, rowCategoriesAmount, dataColsAmount, row, rowName) {
        const { report } = langStore.getTranslate();

        return <>
            {
                <th colSpan={ rowCategoriesAmount - curColIndex + 1 }>
                    {
                        rowName ?
                            rowName :
                            curColIndex > 1 ?
                                report && report.subtotal :
                                report && report.total
                    }
                </th>
            }
            {
                this.renderRowValues(dataColsAmount, row)
            }
        </>;
    }

    renderRowValues(dataColsAmount, row, parentRows) {
        const total = { ...row.total };
        total.displayValue = this.reportState.isDurationAverage ? getDurationValue(total.value, true) : total.value;
        return <>
            {
                parentRows &&
                parentRows.map(row => {
                    return <th key={ simpleHashFromObject(row) }
                               className={ styles.rowHeader }
                               rowSpan={ row.rowSpan === 1 ? 1 : row.rowSpan - 1 }>
                        { renderFieldDisplayName(row.rowName) }
                    </th>;
                })
            }
            {
                _.times(dataColsAmount, (index) => {
                        const cell = { ...row.cells[index] };
                        cell.displayValue = this.reportState.isDurationAverage && cell.value ? getDurationValue(cell.value, true) : cell.value;
                        return (
                            <td key={ index } onClick={ onClickCell(cell) }>
                                {
                                    renderValueCell(cell, this.getPrecision())
                                }
                            </td>
                        );
                    },
                )
            }
            <td className={ styles.totalColTd } key={ 'total' } onClick={ onClickCell(row.total) }>
                {
                    renderValueCell(row.total, this.getPrecision())
                }
            </td>
        </>;
    }

    render() {
        const {
            columnCategories,
            rowCategories,
            columnHeaders,
            tableData,
            dataColumnsAmount,
            styleOptions,
            displayAggregationType,
        } = this.props;

        const style = styleOptions && styleOptions.report_width ?
            { width: styleOptions.report_width } :
            {};

        return <div className={ styles.multiPivot }>
            <table style={ style }>
                {
                    this.renderHeader(rowCategories, columnCategories, columnHeaders)
                }
                <tbody>
                {
                    this.renderRowsBlock(tableData.subRows, 0, rowCategories.length, dataColumnsAmount, [])
                }
                <tr className={ styles.totalRow } key="total">
                    {
                        this.renderTotalRow(1, rowCategories.length, dataColumnsAmount, tableData, displayAggregationType)
                    }
                </tr>
                </tbody>
            </table>
        </div>;
    }
}

// @observable isAllCollapsed = false;
// @observable collapsedBranches = {};
//
// constructor(props) {
//     super(props);
//     this.initCollapsedBranches();
// }
//
// componentDidUpdate(prev) {
//     if (!_.isEqual(prev.tableData, this.props.tableData)) {
//         this.initCollapsedBranches();
//     }
// }
//
// initCollapsedBranches() {
//     this.isAllCollapsed = false;
//     this.collapsedBranches = {};
//     this.props.tableData.subRows.forEach(row => {
//         this.collapsedBranches[row.rowName] = false;
//     });
// }
//
// onAllCollapsed() {
//     this.isAllCollapsed = !this.isAllCollapsed;
//     _.forEach(this.collapsedBranches,
//         (isCollapsed, branchKey) => {
//             this.collapsedBranches[branchKey] = this.isAllCollapsed;
//             return true;
//         }
//     );
// }
//
// onBranchCollapsed(branchName) {
//     return () => {
//         this.collapsedBranches[branchName] = !this.collapsedBranches[branchName];
//         const hasExpanded = _(this.collapsedBranches).some(isBranchCollapsed => !isBranchCollapsed);
//         this.isAllCollapsed = !hasExpanded;
//     };
// }
/**
 * Описание: Элемент, отображающий состояние expanded/collapsed
 * Параметры:
 * isCollapsed - текущее состояние
 * onChange - метод для изменения значения
 */
// class CollapseBtn extends React.Component {
//
//     render() {
//         return <input className={ styles.collapseBtn }
//                       type='button'
//                       value={ this.props.isCollapsed ? '+' : '-' }
//                       onClick={ this.props.onChange } />;
//     }
// }
