import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Button from 'components/button';
import { fetchItemRecord, saveItemRecord, fetchPortal } from 'actions/portalDesigner';
import InfoMessagesState from 'globalState/infoMessages';
import PageData from 'components/portalDesigner/state/pageData';

import IconTrash from 'assets/img/icons/trash.svg';
import IconPlus from 'assets/img/icons/plus.svg';
import IconEdit from 'assets/img/icons/edit-2.svg';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import ModalWindow from 'components/modalWindow';

import { FormSection } from 'components/dynamicForms/view/form';
import CustomSelect from 'components/customselect';

import styles from 'components/portalDesigner/styles.module.scss';

/**
 *
 */
@observer
export default class HeaderPanel extends React.Component {
    name = '';
    form = React.createRef();
    @observable portalCSS;
    @observable section = null;
    @observable buttonIsLoading = {
        remove: false,
        add: false,
        edit: false,
    };

    portalsContainers = [];

    constructor(props) {
        super(props);
    }

    removeActiveElement = async () => {
        this.buttonIsLoading.remove = true;
        const data = await PageData.activeElement.fetchRemove();
        PageData.activeElement.remove();
        PageData.setActiveElement(null);
        this.buttonIsLoading.remove = false;
        InfoMessagesState.pushInfo(data.description);
    };

    editActiveElement = async () => {
        if (!PageData.activeElement) {
            return;
        }
        this.buttonIsLoading.edit = true;

        const { TABLE_NAME, id } = PageData.activeElement;
        const { data } = await fetchItemRecord(TABLE_NAME, id);
        this.name = data.page_name;
        this.section = data.sections[0];

        this.buttonIsLoading.edit = false;
    };

    save = async () => {
        const { TABLE_NAME, id } = PageData.activeElement;
        const form = this.form.current.serialize();

        await saveItemRecord(TABLE_NAME, id, form.data);
        this.name = '';
        this.section = null;

        return PageData.fetchPage();
    };

    addContainer = async () => {
        this.buttonIsLoading.add = true;
        const container = PageData.containers.insert({
            isLoaded: true,
        });
        await PageData.fetchSave(container, PageData.currentPageSysId);
        this.buttonIsLoading.add = false;
    };

    renderBreadcrumbs() {
        return PageData.breadcrumbs.map(({ name, id, model }) => {
            return <div key={ id } className={ styles.BreadcrumbsItems }>
                {
                    PageData.activeElement && PageData.activeElement.id === id ?
                        name
                        : [
                            <a key="link" onClick={ () => PageData.setActiveElement(model) }>
                                { name }
                            </a>,
                            <span key="icon" className={ styles.IconChevronRight } dangerouslySetInnerHTML={ { __html: IconChevronRight } } />,
                        ]
                }
            </div>;
        });
    }

     onSelectPortal = async ({ database_value: portal }) => {
        PageData.portal = portal;
        if (PageData.containers) {
            PageData.containers.items = PageData.containers.items.filter((container) => !this.portalsContainers.includes(container) );
            const { data } = await fetchPortal(portal.path_name).catch(console.error);
            const containers = data.containers.filter(({is_header, is_footer}) => is_header || is_footer );
            this.portalsContainers = PageData.containers.insert(containers);
        }
        this.portalCSS = data.main.css;
    };

    render() {
        const { main, currentPageSysId } = PageData;
        return <div className={ styles.Header }>
            <div className={ styles.HeaderLogo }>

            </div>
            <div className={ styles.HeaderInfo }>
                <div className={ styles.HeaderTitle }>
                    { main.title || '' }
                </div>
                <div>
                    { this.renderBreadcrumbs() }
                </div>
            </div>

            <div className={ styles.HeaderControls }>
                <div className={ styles.PortalSelectWrapper }>
                    <span>Portals</span>
                    <CustomSelect className={ styles.PortalSelect }
                                  databaseValue={ null }
                                  options={ PageData.getPortalList() }
                                  onChange={ this.onSelectPortal } />
                    {
                        this.portalCSS && <style>{ this.portalCSS }</style>
                    }
                </div>
                {
                    currentPageSysId && <Button
                        buttonType={ 'icon-border' }
                        svg={ this.buttonIsLoading.add ? null : IconPlus }
                        onClick={ this.buttonIsLoading.add ? null : this.addContainer }
                    >
                        { this.buttonIsLoading.add && <div className={ styles.ButtonLoader } /> }
                    </Button>
                }
                {
                    PageData.activeElement ? <Button
                        buttonType={ 'icon-border' }
                        svg={ this.buttonIsLoading.remove ? null : IconTrash }
                        onClick={ this.buttonIsLoading.remove ? null : this.removeActiveElement }
                    >
                        { this.buttonIsLoading.remove && <div className={ styles.ButtonLoader } /> }
                    </Button> : null
                }
                {
                    PageData.activeElement ? <Button
                        buttonType={ 'icon-border' }
                        svg={ this.buttonIsLoading.edit ? null : IconEdit }
                        onClick={ this.buttonIsLoading.edit ? null : this.editActiveElement }
                    >
                        { this.buttonIsLoading.edit && <div className={ styles.ButtonLoader } /> }
                    </Button> : null
                }
            </div>

            <ModalWindow
                isShow={ !!this.section }
                doClose={ () => this.section = null }
                title={ this.name }
            >
                {
                    this.section && [
                        <FormSection
                            key="form"
                            index={ 0 }
                            ref={ this.form }
                            name={ this.section.name }
                            fields={ this.section.elements }
                            allFields={ this.section.elements }
                            isServicePortal={ false }
                        />,
                        <Button onClick={ this.save }>
                            Save
                        </Button>,
                    ]
                }
            </ModalWindow>
        </div>;
    }
}