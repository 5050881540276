import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';
import styles from 'components/login/styles.module.scss';
import FormServerErrors from 'components/formItems/serverErrors';
import LoginInput from 'components/login/input';
import LoginButton from 'components/login/button';
import SmartTitle from 'components/smartTitle';
import Select from 'components/login/select';
import Help from 'components/login/help';
import langStore from 'globalState/lang/index';
import brandingState from 'globalState/branding/index';
import userState from 'globalState/user/index';
import InfoMessagesState from 'globalState/infoMessages';
import { fetchAuthAccessData } from 'actions/auth';
import { smartRedirect } from 'helpers/history';
import FormValidator from 'helpers/formValidator';
import AuthHOC from 'helpers/hoc/authHoc';
import { ATTRIBUTES } from 'constants/attributesForTests';
import {ROUTES} from "constants/routers";
import {AuthPagesProps, FormFields} from "types/page/loginPage";
import {LoginData} from "types/globalState/user";

@observer
class LoginPage extends React.Component <AuthPagesProps> {
    @observable formFields: FormFields = {
        username: FormValidator.createFormFieldObj(),
        password: FormValidator.createFormFieldObj(),
    };
    @observable serverError = '';
    @observable isFetching = false;
    @observable signInEnabled = false;
    @observable passwordResetEnabled = false;

    constructor(props) {
        super(props);
        this.fetchAccessInfo().catch(console.error);
    }

    fetchAccessInfo = async () => {
        const { isNotRequestSso } = this.props;
        if (isNotRequestSso) {
            this.signInEnabled = false;
            this.passwordResetEnabled = false;
            return;
        }

        const { isOkStatus, data } = await fetchAuthAccessData();
        if (isOkStatus) {
            this.signInEnabled = data.signup_access;
            this.passwordResetEnabled = data.password_reset_access;
        }
    };

    // Validate form and submit
    validateAndSubmit = async () => {
        const { langData, isNotRequestSso } = this.props;
        if (this.isFetching) return;
        this.isFetching = true;

        const fv = new FormValidator(this.formFields);
        const promises: Array<Promise<void>> = [];

        // Validate username
        promises.push(
            fv.validateField(this.formFields.username, () => {
                //if (val.length < 3) return `Login must contain not less than 3 symbols`;
                //if (val === '111') return `This login not allowed`;
            }),
        );

        // Validate password
        promises.push(
            fv.validateField(this.formFields.password, () => {
                //if (val.length < 3) return `Password must contain not less than 3 symbols`;
            }),
        );

        // Await for validations
        await Promise.all(promises);

        const isValid = fv.isFieldsValid();

        if (!isValid) {
            this.isFetching = false;
            return false;
        }

        try {
            const data = fv.getFieldsData();
            (data as LoginData).language = langData.langValue as string;
            const result = await userState.login((data as LoginData), isNotRequestSso);
            if (result) {
                InfoMessagesState.removeAll();
                smartRedirect('/');
            }
        }
        catch (e) {
            const errorsParsed = fv.applyServerValidationErrors(e.response.data);
            if (!errorsParsed) this.serverError = fv.serverErrorMessage || e.message;
            this.isFetching = false;
        }
    };

    handleSwitchLang = (lang) => {
        const { onFetchTranslate } = this.props;

        this.serverError = '';
        for (const field in this.formFields) {
            if (this.formFields.hasOwnProperty(field)) {
                this.formFields[field].errorMessage = '';
            }
        }
        if (onFetchTranslate) {
            onFetchTranslate(lang);
        }
    };

    handleValueChange = (e) => {
        const { name, value } = e.target;
        this.formFields[name].value = value;
    };

    onFormKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.validateAndSubmit().catch(console.error);
        }
    };

    render() {
        const { placeholders = {}, login_page = {}, help = {} } = langStore.getTranslate();
        const { langData } = this.props;

        return (
            <div className={ styles.Form } onKeyPress={ this.onFormKeyPress }>
                <SmartTitle hidden>{ login_page.title }</SmartTitle>
                <Select
                    onChange={ this.handleSwitchLang }
                    databaseValue={ langData.langValue }
                    options={ langData.langSpecial }
                    data-test={ ATTRIBUTES.loginSelectLang }
                />
                <Help
                    title={ help.title }
                    children={ help.content }
                    data-test={ `login-${ ATTRIBUTES.helpElement }` }
                />
                <div className={ styles.Logo }>
                    <img src={ brandingState.getPlatformLogo() } />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders.username }
                        name="username"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.username.errorMessage }
                        autoFocus
                        isDisabled={ this.isFetching }
                        data-test={ `username-${ ATTRIBUTES.loginField }` }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginInput
                        placeholder={ placeholders.password }
                        type="password"
                        name="password"
                        onChange={ this.handleValueChange }
                        msg={ this.formFields.password.errorMessage }
                        isDisabled={ this.isFetching }
                        data-test={ `password-${ ATTRIBUTES.loginField }` }
                    />
                </div>
                <div className={ styles.Field }>
                    <LoginButton
                        isLoading={ this.isFetching }
                        onClick={ this.validateAndSubmit }
                        buttonType="primary"
                        data-test={ ATTRIBUTES.loginButton }
                        disabled={ !this.formFields.username.value || !this.formFields.password.value }
                    >
                        { login_page.submit_button }
                    </LoginButton>
                </div>
                <FormServerErrors msg={ this.serverError } />
                <div className={ `${ styles.LinksField } ${ this.passwordResetEnabled !== this.signInEnabled ? styles.alignCenter : '' }` }>
                    { this.passwordResetEnabled &&
                        <div>
                            <Link className={ styles.Link } to={ROUTES.RESET_PASSWORD} data-test={ ATTRIBUTES.linkResetPassword }>
                                { login_page.reset_link }
                            </Link>
                        </div>
                     }
                    { this.signInEnabled &&
                        <div>
                            <Link className={ styles.Link } to={ROUTES.SIGNUP} data-test={ ATTRIBUTES.linkSignUp }>
                                { login_page.sign_up_link }
                            </Link>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export const LoginPageComponent = LoginPage;
export default AuthHOC(LoginPage);
