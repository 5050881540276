import moment from 'moment';
import styles from 'pages/report/styles.module.scss';
import langStore from 'globalState/lang';
import conditionState from 'globalState/conditions/condition';
import { getParamField, getParamSection, isDurationAverage, STEPS } from 'helpers/report/report';
import { extractStylesFromRecord } from 'helpers/report/reportStylesHelper';
import reportState, { IS_LOAD_DATA, IS_LOADING_RECORD, IS_UPDATE_CHART, IS_UPDATE_MENU } from 'globalState/report';
import * as React from 'react';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import Reference from 'components/dynamicForms/view/field/reference';
import sysDBTables from 'globalState/sysDBTables';
import Button from 'components/button';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';
import _ from 'lodash';
import { FormSection } from 'components/dynamicForms/view/form';
import { getAllFields, simpleHashFromObject } from 'helpers/misc';
import { actionGetUiAction, actionSaveReport, getReportByData } from 'actions/report';
import InfoMessagesState from 'globalState/infoMessages';
import { runOnTypeScripts, TYPE_ON_SUBMIT } from '../scriptClientHelper';
import { helperRedirect } from 'helpers/history';
import SimpleForm from 'ui-actions-scripts/simple-form';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { removeConditionSortAndGroup } from 'helpers/report/reportComponent';
import FormsState from 'globalState/forms';

/**
 * Функция, которая показывает доступен ли репорт
 * @param step - Шаг выбора репорта в МО (мастер отчетов)
 * @returns {boolean|boolean}
 */
function isEnabledTab(step, reportState) {
    switch (step) {
        case 0:
            return true;
        case 1:
            return !!reportState.name && !!reportState.table;
        case 2:
            return !!reportState.name && !!reportState.table && !!reportState.type && !!(~reportState.stateFlags & IS_LOADING_RECORD);
        case 3:
            return !!reportState.name && !!reportState.table && !!reportState.type && !!(~reportState.stateFlags & IS_LOADING_RECORD);
    }
}

/**
 * Получает искомое поле из секции
 * @param section - секция, в которой ведем поиск
 * @param fieldName - название поля
 * @returns {null|T}
 */
const getFieldFromRecordSection = (section, fieldName) => {
    if (!section) return null;
    return section.elements.find((el) => el.sys_column_name === fieldName);
};

/**
 * Получение секции по имени секции
 * @param sections - все секции
 * @param sectionName - название секции, которую получаем
 * @returns {*}
 */
const getRecordSectionByName = (sections, sectionName) => {
    return Array.isArray(sections) ? sections.find((section) => section.service_name === sectionName) : null;
};

/**
 * Функция, которая показывает возможность изменить шаг выбора настроек в МО
 * @param step - текущий шаг выбора
 * @param name - название отчета
 * @param type - тип репорта
 * @param table - таблица, по которой строим отчет
 * @param isLoadingRecord - происходит ли загрузка репорта(при загрузке все действия блокируются)
 * @param record - сам рекорд МО
 */
export function isChangeStep(step, name, type, table, isLoadingRecord, record) {
    switch (true) {
        case step > 0 && (!name || !table):
            return false;
        case (step > 1 && (!type)) || isLoadingRecord:
            return false;
        case (step > 2 && (!record)) || isLoadingRecord:
            return false;
    }
    return true;
}

/**
 * Генерация названия репорта по типу репорта
 * @param typeName - тип отчета
 * @returns {string}
 */
export function generateReportNameFromType(typeName) {
    const now = (new moment).format('YYYY/MM/DD HH:mm:ss');
    return `${ typeName } ${ now }`;
}

/**
 * Распределяет стили по типу компонента
 * @type {{Input: *, Form: *, Label: *, FieldWrapper: *, Boolean: {Input: *, Label: *, FieldWrapper: *}}}
 */
export const formStyles = {
    // Form: styles.Form,
    // FieldWrapper: styles.FieldWrapper,
    // Label: styles.Label,
    // Input: styles.Input,
    // Boolean: {
    //     FieldWrapper: styles.FieldWrapperBoolean,
    //     Label: styles.LabelBoolean,
    //     Input: styles.InputBoolean,
    // },
};

/**
 * Возвращает все шаги в МО
 * @returns {null|*[]}
 */
export function getSteps() {
    const { report_type } = langStore.getTranslate();
    if (!report_type) return null;
    return [
        report_type.data_section,
        report_type.type_section,
        report_type.configure_section,
        report_type.style_section,
    ];
}

/**
 * Добавляет дополнительные данные
 * @param data - данные с формы
 * @param name - название репорта
 * @param tableId - таблаца, по которой строим репорт
 * @param type - тип репорта
 * @returns {*}
 */
export function distributeData(data, name, tableId, type) {
    data.name = name;
    data.table_id = tableId;
    data.type_id = type && type.type_id;
    data.condition = conditionState.getConditionString() ? conditionState.getConditionString() : data.condition ? data.condition : null;
    return data;
}

/**
 * Заполняет объект с данными секции значениями сохранённых полей
 * @param section - секция
 * @param savedRecordData - объект с сохранёнными полями
 */
function mergeRecordSectionWithSaved(section, savedRecordData) {
    _.forEach(section.elements, (element, index) => {
        if (_.has(savedRecordData, element.column_id)) {
            section.elements[index].value = savedRecordData[element.column_id];
        }
    });
}

/**
 * Заполняет объект с данными рекорда значениями сохранённых полей
 * @param record - рекорд
 * @param savedRecordData - объект с сохранёнными полями
 */
export function mergeRecordDataWithSaved(record, savedRecordData) {
    const resultRecord = _.clone(record);
    // DEF0009783 - Configure должно отчиститься при смене таблицы
    // mergeRecordSectionWithSaved(resultRecord.config, savedRecordData);
    _.map(resultRecord.styles, stylesSection => mergeRecordSectionWithSaved(stylesSection, savedRecordData));
    return resultRecord;
}

/**
 * Сортировка данных формы по различным данным репорта
 * @param record - данные формы
 * @param tableValue - таблица, по которой строим репорт
 * @returns {{data: *, styles: [], config: *}}
 */
export const parseRecordData = (record, tableValue) => {
    const data = getRecordSectionByName(record.sections, STEPS[0]);
    const table = getFieldFromRecordSection(data, 'table_id');
    table.value = tableValue;
    const condition = getFieldFromRecordSection(data, 'condition');
    if (reportState.params.condition) {
        condition.value = reportState.params.condition;
    }

    const config = getRecordSectionByName(record.sections, STEPS[2]);
    const styles = extractStylesFromRecord(record.sections);

    return {
        data,
        styles,
        config,
        ...record,
    };
};


/**
 * Отлавливание ошибки и обнуление флагов
 */
export const fetchError = (reportState) => () => {
    reportState.stateFlags = reportState.stateFlags & ~IS_LOAD_DATA;
    reportState.stateFlags = reportState.stateFlags & ~IS_LOADING_RECORD;
};

/**
 * Функция, которая парсит данные, пришедшие с АПИ в формат, использующиеся на фронте
 * @param chart - отчет
 * @param isLoadDataFromRecordId - флаг, показывающий данные ли существующего репорта
 */
export const parseChartData = (chart, isLoadDataFromRecordId = false, reportState) => {
    const { report_type, record_data, additional_data } = chart;
    reportState.aggregationColumn = additional_data.aggregation_column_type_name;
    if (report_type) {
        reportState.setReportType({ name: report_type });
    }

    const section = record_data && getRecordSectionByName(record_data, STEPS[0]);
    reportState.setReportError();
    // add fixed condition from attribute widget

    const table = getFieldFromRecordSection(section, 'table_id');
    if (table) {
        const condition = getFieldFromRecordSection(section, 'condition');
        const tempCondition = removeConditionSortAndGroup(condition?.value);
        if (tempCondition) {
            conditionState.parseConditionString(tempCondition, null, table.value.database_value);
            reportState.params.condition = tempCondition;
        }
        reportState.setTable(table.value, isLoadDataFromRecordId);
    }

    const name = getFieldFromRecordSection(section, 'name');
    if (name) {
        reportState.setName(name.value);
    }
    if (isLoadDataFromRecordId) {
        const config = getParamSection(record_data, STEPS[2]);
        const groupBy = getParamField(config, 'group_by');
        reportState.additionalGroup = groupBy ? _.clone(groupBy.value) : [];
    }
    isDurationAverage(reportState);
};

/**
 * Извлечение из секций всех элементов в тип column_id: value
 * @param record - рекорд, из которого извлекаем
 */
export function extractDataFromRecord(record) {
    if (!record || !record.sections) return [];
    let data = {};
    _.forEach(record.sections, (section) => {
        _.forEach(section.elements, element => data[element.column_id] = element.value);
    });
    return data;
}

/**
 * Извлечение из секций всех элементов в тип column_id: value
 * @param configForm - форма с настройками
 * @param stylesForm - форма со стилями
 */
function extractDataFromForm(configForm, stylesForm) {
    if (!configForm || !stylesForm) {
        return {};
    }
    let data = {};
    _.forEach(configForm.fields, field => data[field.column_id] = field.value);

    _.forEach(stylesForm, (section) => {
        _.forEach(section.fields, field => data[field.column_id] = field.value);
    });

    return data;
}

/**
 * Возвращает заголовок у документа репорта
 * @returns {string}
 */
export const getReportTitle = (reportState) => {
    const { report_type } = langStore.getTranslate();
    let reportTitle = '';
    if (report_type && report_type.report_designer) {
        reportTitle += report_type.report_designer;
    }
    if (reportState.name) {
        if (reportTitle !== '') {
            reportTitle += ' ';
        }
        reportTitle += reportState.name;
    }
    if (reportState.type) {
        reportTitle += ' ' + reportState.type.type_name;
    }
    return reportTitle;
};


/**
 * Функция рендерит секцию с названием и таблицей, по которым строит отчет
 * @param report_type - перевод для элементов репорта
 * @param index - шаг выбора секции
 * @returns {*}
 */
export const renderFirstSection = (report_type, index, reportState) => {
    const sys_db_table = sysDBTables.getByName(sysDBTables.TABLE_NAME);
    if (reportState.table && reportState.record && reportState.record.sections && reportState.record.data) {
        const name = getParamField(reportState.record.data, 'name');
        const table = getParamField(reportState.record.data, 'table_id');
        const elements = [
            name,
            table,
        ];
        return (
            <div className={ `${ index !== 0 ? styles.hide : '' } ${ styles.SettingBlockContent }` }>
                <div className={ styles.SettingData }>
                    <FormSection
                        key="data"
                        tableName={ reportState.typeTable && reportState.typeTable.name }
                        sysId={ reportState.sFormSysId }
                        index={ reportState.record.data.index }
                        name={ reportState.record.data.name }
                        service_name={ reportState.record.data.service_name }
                        fields={ elements }
                        conditionState={ reportState.conditionState }
                        ref={ reportState.dataForm }
                        onChangeField={ reportState.onChangeField }
                        allFields={ getAllFields(reportState.record.sections) }
                        parentFormId={ reportState.getParentFormId() } />
                </div>
            </div>
        );
    }
    return (
        <div className={ `${ index !== 0 ? styles.hide : '' } ${ styles.SettingBlockContent }` }>
            {/*data*/ }
            <div className={ styles.SettingData }>
                <div className={ styles.FieldWrapper }>
                    <StringInput
                        className={ styles.Input }
                        value={ reportState.name }
                        label={ report_type && report_type.report_name }
                        isMandatory={ true }
                        onChange={ ({ value }) => reportState.setName(value) }
                        onBlur={ reportState.updateNameInUrl }
                        readOnly={ !!(reportState.stateFlags & IS_LOAD_DATA) }
                        data-test={ ATTRIBUTES.reportFieldReportName }
                    />
                </div>

                <div className={ styles.FieldWrapper }>
                    <Reference
                        className={ styles.Reference }
                        special={ sys_db_table ? {
                            table_id: sys_db_table.sys_id,
                            table_name: sys_db_table.name,
                        } : {} }
                        label={ report_type && report_type.table }
                        value={ reportState.table }
                        isMandatory
                        onChange={ ({ value }) => reportState.setTable(value) }
                        readOnly={ !!(reportState.stateFlags & IS_LOAD_DATA) }
                        data-test={ ATTRIBUTES.reportFieldTable }
                    />
                </div>
            </div>
        </div>
    );
};

/**
 * Получение данных отчета по введенным данным в МО
 * @param additionalGroupValue
 * @param page
 * @returns {Promise<unknown>}
 */
export const getChartByData = (reportState) => ({ additionalGroupValue, page }) => {
    let { data, error } = reportState.getData(true);

    reportState.additionalGroupValue = additionalGroupValue;
    if (!reportState.report_id && reportState.additionalGroupValue) {
        reportState.setGroupByToUrl(reportState.additionalGroupValue.database_value);
    }
    if (reportState.configForm.current) {
        const groupBy = reportState.configForm.current.model.getFieldByName('group_by');
        reportState.additionalGroup = groupBy ? _.clone(groupBy.value) : [];
        const aggregationColumn = reportState.configForm.current.model.getFieldByName('aggregation_column');
        if (aggregationColumn?.value?.database_value) {
            isDurationAverage(reportState);
        }
    }
    if (error && error.length) {
        return Promise.reject(error.join('\n'));
    }
    if (!data) {
        return Promise.resolve(null);
    }
    return getReportByData(additionalGroupValue, page, data);
};

/**
 * Функция, которая отправляет репорт на сохранение
 */
export const saveReport = async (data, error, getChart, reportState) => {
    const record = reportState.record;
    if (error && error.length) {
        InfoMessagesState.pushError(error.join('<br />'));
        return Promise.reject(error.join('\n'));
    }

    if (record.client_scripts) {
        const result = await runOnTypeScripts(record.client_scripts, TYPE_ON_SUBMIT, null, null, reportState.parentFormId);
        if (!result) {
            return Promise.reject('Validation failed');
        }
    }
    const type = reportState.type;
    const report_id = reportState.report_id;
    const table = sysDBTables.getById(type.configure_table);
    const response = await actionSaveReport(data, type, report_id, table);
    if (response && response.status === 'OK') {
        const responseData = response.getData();
        FormsState.clearChangedFields(responseData.record_id);
        if (responseData && responseData.record_id) {
            reportState.report_id = responseData.record_id;
            reportState.sFormSysId = responseData.record_id;
            helperRedirect('/report/' + responseData.record_id);
            window.s_form = new SimpleForm(table.name, reportState.report_id, undefined, undefined, reportState.getParentFormId());
        } else {
            if (getChart) {
                await getChart();
                reportState.updateCurrentStyles();
            }
        }
        // Сохраняем значения полей
        reportState.savedRecord = extractDataFromForm(reportState.configForm.current, reportState.stylesForm.current);
    }
};

/**
 * Получение экшенов для репорта
 * @param currentReportState - текущий стейт
 * @param reportId
 * @returns {Promise<void>}
 */
export const getUiActions = async (currentReportState, reportId = null) => {
    const type = currentReportState.type;
    if (!type) {
        return;
    }
    const table = sysDBTables.getById(type.configure_table);
    const response = await actionGetUiAction(table?.name, reportId);
    const data = response?.data;
    currentReportState.menuItems = data && data.form_context_menu;
    currentReportState.stateFlags = currentReportState.stateFlags | IS_UPDATE_MENU;
};

/**
 * Функция, которая рендерит нижние кнопки переключения секций
 * @param report_type - перевод для элементов репорта
 * @param getChart - ссылка на функцию, которая получает данные репорта
 * @returns {*}
 */
export const renderFooterButtons = (report_type, getChart, reportState) => {
    return (
        <div key="settings-footer" className={ styles.SettingFooter } data-test={ ATTRIBUTES.reportSettings }>
            { !!reportState.step &&
            <Button
                className={ styles.SettingFooterButton }
                onClick={ reportState.changeStep(reportState.step - 1) }
                data-test={ ATTRIBUTES.reportButtonBack }
            >
                { report_type && report_type.back_button }
            </Button>
            }
            { reportState.step !== 3 &&
            <Button
                className={ !reportState.step ? `${ styles.SettingFooterButton } ${ styles.NextButton }` : styles.SettingFooterButton }
                onClick={ reportState.changeStep(reportState.step + 1) }
                buttonType='primary'
                disabled={ !isEnabledTab(reportState.step + 1, reportState) }
                data-test={ ATTRIBUTES.reportButtonNext }
            >
                { report_type && report_type.next_button }
            </Button>
            }
            { reportState.step === 3 &&
            <Button
                className={ styles.SettingFooterButton }
                onClick={ () => getChart() }
                buttonType='primary'
                disabled={ !!(reportState.stateFlags & IS_UPDATE_CHART) }
                data-test={ ATTRIBUTES.reportButtonRefresh }
            >
                { report_type && report_type.refresh_button }
            </Button>
            }
        </div>
    );
};

/**
 * Функция, которая рендерит табы у секций
 * @param steps - шаги выбора (Data, type, configure, style)
 * @returns {*}
 */
export const renderSectionsTabs = (steps, reportState) => {
    return (
        <div key="settings-tabs" className={ styles.SettingTabs }>
            {
                STEPS.map((step, index) => {
                    let classes = [styles.SettingTabsItem];
                    if (reportState.step === index) {
                        classes.push(styles.SettingTabsItemActive);
                    }
                    else if (isEnabledTab(index, reportState)) {
                        classes.push(styles.SettingTabsEnabled);
                    }
                    return <div key={ `tab-${ step }` }
                                className={ classes.join(' ') }
                                onClick={ reportState.changeStep(index) }>
                        { steps && steps[index] }
                        {
                            (index < (STEPS.length - 1)) ?
                                <div className={ styles.IconChevronRight }
                                     dangerouslySetInnerHTML={ { __html: IconChevronRight } } />
                                : null
                        }
                    </div>;
                })
            }
        </div>
    );
};

/**
 * Рендер одного блока категорий типа с картинками типов, относящийся к этой категории
 * @param category - категория, для которой происходит рендер
 */
const renderTypeBlock = (category, reportState) => {
    const changeReportType = (type) => () => {
        reportState.setReportType({ name: type.type_service_name });
        reportState.isDurationAverage = false;
        reportState.aggregationRecordId = null;
    };
    const types = reportState.types.sys_report_type.filter((type) => type.category_id === category.sys_id);
    return types.map((type) => {
        let classes = [styles.TypeItems];
        if (~reportState.stateFlags & IS_LOADING_RECORD) {
            classes.push(styles.TypeLoaded);
        }
        if (reportState.type && reportState.type.type_service_name === type.type_service_name) {
            classes.push(styles.TypeItemsActive);
        }
        return (
            <div className={ styles.OneType } key={ type.type_service_name }>
                <p>
                    {/* рендерим названия у типов только, если количество типов больше 1го, по макетам*/ }
                    { types.length > 1 && type.type_name }
                </p>
                <div
                    className={ classes.join(' ') }
                    onClick={ changeReportType(type) }
                    data-test={ `${ type.type_service_name.toLowerCase() }-${ ATTRIBUTES.reportType }` }
                >
                    <img key="type_logo"
                         className={ reportState.stateFlags & IS_LOADING_RECORD ? styles.DisabledImg : '' }
                         src={ type.logo_image }
                         alt={ type.description }
                         title={ type.description } />
                </div>
            </div>
        );
    });
};

/**
 * Функция, которая рендерить секцию с выбором типа отчета
 * @param index - шаг выбора секции в МО
 * @returns {*}
 */
export const renderTypeSection = (index, reportState) => {
    return (
        <div className={ `${ index !== 1 ? styles.hide : '' } ${ styles.SettingBlockContent }` }>
            {/*types*/ }
            <div className={ styles.SettingTypes }>
                {
                    reportState.types && reportState.types.sys_report_type_category && reportState.types.sys_report_type_category.map((category) => {
                        return <div key={ category.name } className={ styles.CategoryField }>
                            <div key="category-name"
                                 className={ styles.CategoryName }>{ category.name }</div>
                            <div key="category-description"
                                 className={ styles.CategoryDescription }>{ category.description }</div>
                            <div key="types" className={ styles.Types }>
                                {
                                    renderTypeBlock(category, reportState)
                                }
                            </div>
                        </div>;
                    })
                }
            </div>
        </div>
    );
};

/**
 * Отдельно получение поля group by
 * @param record
 * @returns {null|*|T}
 */
export function getFieldGroupBy(record) {
    if (!record) {
        return null;
    }
    let config = getRecordSectionByName(record.sections, STEPS[2]);
    return config && getFieldFromRecordSection(config, 'group_by');
}

/**
 * Функция, которая рендерить секцию со стилями
 * @param index - шаг выбора секции в МО
 * @param styleTab - множество с табами в секции Стилей (настройки заголовка, цвета и пр.)
 * @returns {*}
 */
export const renderStyleSection = (index, styleTab, reportState) => {
    return (
        <div className={ `${ index !== 3 ? styles.hide : '' } ${ styles.SettingBlockContent }` }>
            <div className={ styles.SettingStyle }>
                {/*style*/ }
                {
                    reportState.table && reportState.record && reportState.record.styles &&
                    <div key="stylesBlock" className={ styles.StylesBlock }>
                        { _.map(reportState.record.styles, (section, indexStyle) => {
                                reportState.stylesForm[indexStyle] = React.createRef();
                                return (
                                    <React.Fragment key={ simpleHashFromObject(section) }>
                                        <div key={ `styleTab-${ section.index }` }
                                             onClick={ () => styleTab.has(section.index) ?
                                                 styleTab.delete(section.index)
                                                 : styleTab.add(section.index) }
                                             className={ styleTab.has(section.index) ? styles.StylesActiveTab : styles.StylesTab }>
                                            { section.name }
                                            <div className={ styles.StylesIconChevronRight }
                                                 dangerouslySetInnerHTML={ { __html: IconChevronRight } } />
                                        </div>
                                        <div key={ `styleForm-${ section.index }` }
                                             className={ styleTab.has(section.index) ? styles.StylesForm : styles.hide }>
                                            <FormSection
                                                ref={ reportState.stylesForm[indexStyle] }
                                                key={ `styleForm${ indexStyle }` }
                                                tableName={ reportState.typeTable && reportState.typeTable.name }
                                                sysId={ reportState.sFormSysId }
                                                clientScripts={ reportState.record.client_scripts }
                                                className={ formStyles }
                                                index={ section.index }
                                                name={ section.name }
                                                service_name={ section.service_name }
                                                fields={ section.elements }
                                                allFields={ getAllFields(reportState.record.sections) }
                                                parentFormId={ reportState.getParentFormId() }
                                            />
                                        </div>
                                    </React.Fragment>);
                            },
                        ) }
                    </div>
                }
            </div>
        </div>);
};
