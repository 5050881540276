import * as React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import styles from './styles.module.scss';
import Button from 'components/button';
import DragAndDrop from 'components/dragAndDrop';
import attachmentsWidgetState from 'globalState/attachmentsWidgetState';
import langStore from 'globalState/lang';
import { getUrlParams } from 'helpers/data';
import { fetchRecord } from 'actions/record';
import FileMenuDownloadIcon from 'assets/img/icons/download.svg';
import FileMenuDeleteIcon from 'assets/img/icons/trash.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { AttachmentsProps } from 'types/components/portalWidgetsComponents/attachments';
import { FileData } from 'types/components/dragAndDrop/dragAndDrop';
import IconUpload from 'assets/img/icons/upload.svg';
import FormsState from 'globalState/forms';

function cancelDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
}

/**
 * Props:
 * model - поле в которе будет сохранятся массив с айди аттачей (работает аналогично полю model для stringinput)
 */
@observer
export class Attachments extends React.Component<AttachmentsProps> {
    @observable uploadedFiles: FileData[] = [];
    @observable showAttachPopup = false;
    @observable dropHover = false;
    @observable isAclActive = false;
    @observable isFetchRun = false;
    isMouseEntered = false;
    dndDropFuncLink: (e) => void;
    pasteFuncLink: (e) => void;
    dndComponentRef = {} as DragAndDrop;
    timeout: NodeJS.Timeout;
    dragLeaveTimeout: NodeJS.Timeout;

    @computed get uploadFinishedFiles() {
        return this.uploadedFiles.filter(file => file.uploaded === undefined || file.uploaded === true);
    }

    @computed get attachmentsIds() {
        return this.uploadFinishedFiles.map(file => file.sys_id);
    }

    constructor(props) {
        super(props);
        attachmentsWidgetState.setAttachment(this.getId());
        this.triggerChange();
    }

    componentDidMount() {
        attachmentsWidgetState.setAttachment(this.getId(),{
            widgetComponent: this,
            hasWidget: true,
        });
        this.startState();
        window.addEventListener('paste', this.handlePaste);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            this.updateState(prevProps);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        attachmentsWidgetState.reset(this.getId());
        window.removeEventListener('paste', this.handlePaste);
    }

    startState = () => {
        const { location, tableName, recordId, match } = this.props;

        const params = getUrlParams(location.search);
        if (tableName) {
            attachmentsWidgetState.setAttachment(this.getId(), { essence: tableName });
            if (recordId) {
                this.delayedFetchAttachment(recordId);
            }
            return;
        }
        if (match && match.params && match.params.table_name) {
            attachmentsWidgetState.setAttachment(this.getId(), { essence: match.params.table_name });
            if (match.params.number) {
                this.delayedFetchAttachment(match.params.number);
            }
            return;
        }
        if (params.table_name) {
            attachmentsWidgetState.setAttachment(this.getId(), { essence: params.table_name });
            if (params.record_id) {
                this.delayedFetchAttachment(params.record_id);
            }
        }
    };

    updateState = (prevProps) => {
        const { location, tableName, recordId, match } = this.props;
        const params = getUrlParams(location.search);
        const prevParams = getUrlParams(prevProps.location.search);
        if (tableName) {
            if (tableName !== prevProps.tableName) {
                attachmentsWidgetState.setAttachment(this.getId(), { essence: tableName });
            }
            if (recordId && recordId !== prevProps.recordId) {
                this.delayedFetchAttachment(recordId);
            }
            return;
        }
        if (match && match.params && match.params.table_name) {
            const prevMatch = prevProps.match;
            if (match.params.table_name !== prevMatch.params.table_name) {
                attachmentsWidgetState.setAttachment(this.getId(), { essence: match.params.table_name });
            }
            if (match.params.number && match.params.number !== prevMatch.params.number) {
                this.delayedFetchAttachment(match.params.number);
            }
            return;
        }
        if (params.table_name) {
            if (params.table_name !== prevParams.table_name) {
                attachmentsWidgetState.setAttachment(this.getId(), { essence: params.table_name });
            }
            if (params.record_id && params.record_id !== prevParams.record_id) {
                this.delayedFetchAttachment(params.record_id);
            }
        }
    };

    delayedFetchAttachment = (recordId: string) => {
        this.isFetchRun = true;
        attachmentsWidgetState.setAttachment(this.getId(), { recordId: recordId });
        this.timeout = setTimeout(() => {
            this.fetchAttachment();
        }, 200);
    }

    fetchAttachment = async () => {
        const attachmentsWidget = attachmentsWidgetState.getAttachment(this.getId());
        if (attachmentsWidget){
            if (!attachmentsWidget.essence) {
                return;
            }
            const { isReadOnly } = this.props;
            const parsedSearchParams = { open_first_rel_list: 0 };
            const { isOkStatus, data } = await fetchRecord(attachmentsWidget.essence, attachmentsWidget.recordId, parsedSearchParams, true);
            if (isOkStatus) {
                this.isAclActive = typeof isReadOnly === 'boolean' ? isReadOnly : data.is_clip_visible === false;
                attachmentsWidget.dndAttachmentsProps = data.attachments;
                this.isFetchRun = false;
            }
        }
    };

    handleCloseAttachPopup = () => {
        this.showAttachPopup = false;
    };

    triggerChange = () => {
        if (this.props.onChange) {
            const obj = {
                target: {
                    value: this.attachmentsIds,
                },
            };

            this.props.onChange(obj);
        }
    };

    onAttachClick = () => {
        this.showAttachPopup = true;
    };

    handleDragEnter = (e) => {
        if (this.isAclActive) return;
        cancelDefaults(e);
        clearTimeout(this.dragLeaveTimeout);
        this.dropHover = true;
    };

    handleDragLeave = (e) => {
        cancelDefaults(e);
        this.dragLeaveTimeout = setTimeout(() => { this.dropHover = false },100);
    };

    handleDragOver = (e) => {
        if (this.isAclActive) return;
        cancelDefaults(e);
        clearTimeout(this.dragLeaveTimeout);
        this.dropHover = true;
    };

    handleDrop = (e) => {
        cancelDefaults(e);
        this.dropHover = false;

        if (e?.dataTransfer?.files?.length) {
            this.showAttachPopup = true;
            this.dndDropFuncLink(e);
        }
    };

    handlePaste = (e) => {
        if (!this.isMouseEntered || this.showAttachPopup) return;
        this.pasteFuncLink(e);
    };

    addFileBtnClick = () => {
        this.dndComponentRef.handleDropZoneClick();
    };

    addAttachBtnClick = () => {
        this.showAttachPopup = true;
    };

    canAddFile = () => {
        return this.dndComponentRef.canAddFile();
    };

    getId = () => {
        const { parentFormSectionModel } = this.props;
        let id;
        if (parentFormSectionModel) {
            //Виджет аттачей находится на форме в агенте
            id = parentFormSectionModel?.parentFormModel?.id
                || parentFormSectionModel?.parentFormId
                || parentFormSectionModel?.id;
        } else {
            const dynamicForm = FormsState.findDynamicForm(this.props.tableName || '', this.props.recordId || '');
            if (dynamicForm){
                //Виджет аттачей используется в связке с портальной формой
                id = dynamicForm?.id;
            } else {
                //Виджет аттачей работает без привязки к форме
                id = (this.props.tableName || '') + (this.props.recordId || '');
            }
        }
        return id;
    };

    renderAttachContainer = () => {
        if (this.isFetchRun) {
            return (
                <div className={ styles.Skeleton }>
                    <div className={ styles.SkeletonTop }>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem }/>
                    </div>
                    <div className={ styles.SkeletonListItem }>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem } style={{flexBasis: '241px'}}/>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem }/>
                    </div>
                    <div className={ styles.SkeletonListItem }>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem } style={{flexBasis: '333px'}}/>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem }/>
                    </div>
                    <div className={ styles.SkeletonListItem }>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem } style={{flexBasis: '146px'}}/>
                        <div className={ styles.SkeletonItem }/>
                        <div className={ styles.SkeletonItem }/>
                    </div>
                </div>
            );
        }

        const { widget_titles } = langStore.getTranslate();

        if (this.isAclActive && !this.uploadFinishedFiles.length) {
            return <div className={ styles.emptyState }>{ widget_titles?.no_attachments }</div>;
        }

        const classes = [ styles.attachmentsContainer ];
        if (this.dropHover) classes.push(styles.dropHovered);

        const propsToPass = {
            onDragEnter: this.handleDragEnter,
            onDragLeave: this.handleDragLeave,
            onDragOver: this.handleDragOver,
            onDrop: this.handleDrop,
            onMouseEnter: () => { this.isMouseEntered = true },
            onMouseLeave: () => { this.isMouseEntered = false },
        };

        if (this.uploadFinishedFiles.length) {
            classes.push(styles.hasAttachments);
        }

        return (
            <div
                className={ classes.join(' ') }
                { ...propsToPass }
            >
                { !this.isAclActive &&
                    <div className={ styles.addFiles }>
                        <div dangerouslySetInnerHTML={ { __html: IconUpload } } className={ styles.addFilesIcon } />
                        <div className={ styles.addFilesText }>{ widget_titles?.drag_n_drop_files_or }</div>
                        <Button data-test={ ATTRIBUTES.attachUploadBtn } onClick={ this.addFileBtnClick }>{ widget_titles?.upload_from_computer }</Button>
                    </div>
                }
                { !this.isAclActive && this.uploadFinishedFiles.length === 0 &&
                    <div key={ 'addBtnWrap' } className={ styles.addBtnWrap }>
                        <Button
                            className={ styles.addBtn }
                            data-test={ ATTRIBUTES.attachOpenBtn }
                            onClick={ this.addAttachBtnClick }>
                            { `${ widget_titles?.open_attachments }` }
                        </Button>
                    </div>
                }
                { this.renderAttachContent() }
                <div className={ styles.dragBox }>
                    <div className={ styles.dragBoxIcon } dangerouslySetInnerHTML={{__html: IconUpload}}/>
                    <div className={ styles.dragBoxText }>{ widget_titles?.drag_n_drop_files }</div>
                </div>
            </div>
        );
    };

    renderAttachContent = () => {
        if (!this.uploadFinishedFiles.length) {
            return null;
        }
        return this.renderUploaded(this.uploadFinishedFiles);
    };

    renderUploadedFileContent = (file: FileData) => {
        const fileName = this.dndComponentRef.getFileName(file);
        const fileTypeIcon = this.dndComponentRef.getFileTypeIcon(file);

        return (
            <React.Fragment>
                <div
                    className={ styles.fileContent }
                    onClick={ (e) => this.dndComponentRef.showOrDownloadOnClick(e, file) }
                >
                    { fileTypeIcon }
                    <div className={ styles.fileParams }>
                        <div className={ styles.fileName }>
                            <span title={ fileName }>{ fileName }</span>
                        </div>




                        <div className={ styles.fileIcon } onClick={ (e) => {
                            e.stopPropagation();
                            this.dndComponentRef.downloadFile(file);
                        } } dangerouslySetInnerHTML={ { __html: FileMenuDownloadIcon } } />
                        { !this.isAclActive &&
                        <div className={ styles.fileIcon } onClick={ (e) => {
                            e.stopPropagation();
                            this.dndComponentRef.handleRemoveFile(e, file);
                        } } dangerouslySetInnerHTML={ { __html: FileMenuDeleteIcon } } />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderUploaded = (files: FileData[]) => {
        const { widget_titles } = langStore.getTranslate();
        let items: JSX.Element[] = [];
        for (const file of files) {
            items.push(
                <div key={ JSON.stringify(file) } className={ styles.uploadedFileContainer }>
                    { file.uploaded && this.renderUploadedFileContent(file) }
                </div>,
            );
        }

        items = items.slice(-3).reverse();

        if (this.canAddFile() && files.length) {
            items.push(
                <div key={ 'addBtnWrap' } className={ styles.addBtnWrap }>
                    <Button
                        className={ styles.addBtn }
                        data-test={ ATTRIBUTES.attachOpenBtn }
                        onClick={ this.addAttachBtnClick }>
                        { `${ widget_titles?.open_attachments } ${ files.length > 0 && ` (${ files.length })` }` }
                    </Button>
                </div>
            );
        }

        return <div className={ styles.uploadedItems }>{ items }</div>;
    };

    render() {
        const { className } = this.props;
        const attachmentsWidget = attachmentsWidgetState.getAttachment(this.getId());
        return (
            <div
                className={ `${ styles.wrapper } ${ className || '' }` }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `attachments-${ ATTRIBUTES.widget }` }
            >
                { this.renderAttachContainer() }
                <DragAndDrop
                    onClose={ this.handleCloseAttachPopup }
                    show={ this.showAttachPopup }
                    isAclActive={ this.isAclActive }
                    attachments={ attachmentsWidget?.dndAttachmentsProps }
                    onUpload={ this.triggerChange }
                    onRemoveFile={ this.triggerChange }
                    essence={ attachmentsWidget?.essence }
                    recordId={ attachmentsWidget?.recordId }
                    onDropFunc={ func => this.dndDropFuncLink = func }
                    onPasteFunc={ func => this.pasteFuncLink = func }
                    getUploadedFilesLink={ uploadedFiles => this.uploadedFiles = uploadedFiles }
                    getRef={ componentRef => this.dndComponentRef = componentRef }
                />
            </div>
        );
    }
}

export default withRouter(Attachments);
