import * as React from 'react';
import styles from './styles.module.scss';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {
    HistoryContentActivity,
    HistoryContentItemActivity,
    HistoryItemProps,
} from 'types/components/activityFeed';
import IconHistory from 'assets/img/icons/book-open.svg';
import { getDateValueByString, getTranslateDateFormat, getUserTimezone } from 'helpers/getUserTimeZone';
import { ATTRIBUTES } from 'constants/attributesForTests';
import DefaultAvatar from 'assets/img/user-ava.svg';
import langStore from 'globalState/lang/index';
import { getClassNameAF, getFilteredHistoryFields } from 'helpers/activityFeed';
import Badges from 'components/dynamicForms/view/field/list/badges';

const BOOLEAN_TEXT = 'boolean';
const DATETIME_TEXT = 'datetime';
const DATETIME_SPECIFIC_TEXT = 'datetime_specific';
const DATE_TEXT = 'date';
const TIME_TEXT = 'time';
const REFERENCE_TEXT = 'reference';
const LIST_TEXT = 'list';
const ONE_TEXT = '1';
/**
 * Описание: компонент истории в activity feed
 */
@observer
export default class HistoryItem extends React.Component<HistoryItemProps> {
    @observable historyEl: React.RefObject<HTMLDivElement> = React.createRef();
    @observable showAll = false;
    @observable isBigText = false;
    timeout: any = null;


    componentDidMount() {
        this.checkSizeText();
        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate() {
        this.checkSizeText();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=>{
            this.isBigText = false;
            this.checkSizeText();
        },200);
    };

    checkSizeText = () => {
        const { current: historyItem } = this.historyEl;
        if(!historyItem) return;

        if (historyItem.scrollHeight > historyItem.offsetHeight + 20) {
            this.isBigText = true;
        } else {
            const textFields = [...Array.from(historyItem.getElementsByClassName(styles.DefaultOldText)), ...Array.from(historyItem.getElementsByClassName(styles.DefaultNewText))];

            for(let i = 0; i < textFields.length; i++){
                const current = textFields[i];
                if(current && this.checkScroll(current)){
                    this.isBigText = true;
                    break;
                }
            }
        }
    };

    checkScroll = (containerEl) => {
        const contains = containerEl.classList.contains(styles.HideText);
        containerEl.classList.add(styles.HideText);
        const result = containerEl.clientHeight + 8 < containerEl.scrollHeight;
        if(!contains) {
            containerEl.classList.remove(styles.HideText);
        }

        return result;
    };

    handleToggleShowAll = () => {
        this.showAll = !this.showAll;
    };

    getTypeValue = (type, value) => {
        const bool = langStore.getTranslateKey('bool');
        if (type === BOOLEAN_TEXT && bool) {
            return value === ONE_TEXT ? bool.yes : bool.no;
        }
        if (type === DATETIME_TEXT) {
            return value ? getDateValueByString(value, getUserTimezone(), `${ getTranslateDateFormat() } HH:mm:ss`, 'YYYY-MM-DD HH:mm:ss') : '';
        }
        if (type === DATE_TEXT) {
            return value ? getDateValueByString(value, getUserTimezone(), getTranslateDateFormat(), 'YYYY-MM-DD HH:mm:ss') : '';
        }
        if (type === TIME_TEXT) {
            return value ? getDateValueByString(value, getUserTimezone(), `HH:mm:ss`, 'HH:mm:ss') : '';
        }
        if (type === DATETIME_SPECIFIC_TEXT) {
            return value ? getDateValueByString(value, 'UTC', `${ getTranslateDateFormat() } HH:mm:ss`, 'YYYY-MM-DD HH:mm:ss') : '';
        }
        if ([LIST_TEXT, REFERENCE_TEXT].includes(type) && !_.isEmpty(value)) {
            const parseValue = JSON.parse(value);
            if (!Array.isArray(parseValue) && _.isEmpty(parseValue.database_value)) {
                return null;
            }
            return (
                <Badges
                    values={type === REFERENCE_TEXT ? [parseValue] : parseValue}
                    readOnly
                    tableName={ this.props.activityState.getTableName() }
                    pureBadge
                ></Badges>
            );
        }
        return value;
    };

    renderItem = (item: HistoryContentItemActivity) => {
        const { activityState, isNotStylized, classes } = this.props;
        if (!_.find(getFilteredHistoryFields(activityState), filteredField => filteredField.column_name === item.column_name
            && filteredField.checked)) {
            return null;
        }

        const newText = this.getTypeValue(item.column_type, item.new_display_value || item.new_database_value);
        const oldText = this.getTypeValue(item.column_type, item.old_display_value || item.old_database_value);

        return (
            <div
                className={ getClassNameAF(isNotStylized, styles.HistoryItemBlock, classes?.HistoryItemBlock) }
                key={ item.column_title + item.new_database_value + item.old_database_value }
                data-test={ ATTRIBUTES.activityFeedHistoryItem }
            >
                <div className={ getClassNameAF(isNotStylized, styles.HistoryItemNew, classes?.HistoryItemNew) }>
                    <div
                        className={ getClassNameAF(isNotStylized, styles.HistoryNewTitle, classes?.HistoryNewTitle) }
                        data-test={ ATTRIBUTES.activityFeedHistoryItemNewTitle }
                    >
                        { item.column_title }
                    </div>
                    <div
                        className={ `${ styles.DefaultNewText } ${ getClassNameAF(isNotStylized, styles.HistoryNewText, classes?.HistoryNewText) } ${ !this.showAll ? styles.HideText : '' }` }
                        data-test={ ATTRIBUTES.activityFeedHistoryItemNewText }
                    >
                        { newText }
                    </div>
                </div>
                <div className={ getClassNameAF(isNotStylized, styles.HistoryItemOld, classes?.HistoryItemOld) }>
                    <div className={ getClassNameAF(isNotStylized, styles.HistoryOldTitleBlock, classes?.HistoryOldTitleBlock) }>
                        <div
                            className={ getClassNameAF(isNotStylized, styles.HistoryOldTitle, classes?.HistoryOldTitle) }
                            data-test={ ATTRIBUTES.activityFeedHistoryItemOldTitle }
                        >
                            { !!oldText && item.column_title }
                        </div>
                    </div>
                    <div
                        className={ `${ styles.DefaultOldText } ${ getClassNameAF(isNotStylized, styles.HistoryOldText, classes?.HistoryOldText) } ${ !this.showAll ? styles.HideText : '' }` }
                        data-test={ ATTRIBUTES.activityFeedHistoryItemOldText }
                    >
                        { oldText }
                    </div>
                </div>
            </div>
        );
    };

    renderChanges = () => {
        const { item, dictionary, isNotStylized, classes } = this.props;
        const items = (item.content as HistoryContentActivity).items;
        if (_.isEmpty(items)) {
            return (
                <div className={ getClassNameAF(isNotStylized, styles.HistoryNoFields, classes?.HistoryNoFields) }>
                    { dictionary.form?.no_fields }
                </div>
            );
        }

        return _.map(items, item => this.renderItem(item));
    };

    renderAvatar = (user) => {
        const { isNotStylized, classes } = this.props;
        if (!user || !user.avatar) {
            return (
                <div
                    dangerouslySetInnerHTML={ { __html: DefaultAvatar } }
                    className={ getClassNameAF(isNotStylized, styles.HistoryUserAvatar, classes?.HistoryUserAvatar) }
                    data-test={ ATTRIBUTES.activityFeedAvatar }
                />
            );
        }
        return (
            <img
                className={ getClassNameAF(isNotStylized, styles.HistoryUserAvatar, classes?.HistoryUserAvatar) }
                src={ user.avatar }
                alt={ user.name }
                data-test={ ATTRIBUTES.activityFeedAvatar }
            />
        );
    };

    renderShowAll = () => {
        if (!this.isBigText) {
            return null;
        }
        const { dictionary, isNotStylized, classes } = this.props;
        const text = !this.showAll ? dictionary.item.show_comments_button : dictionary.item.hide_information_button;
        return (
            <div
                className={ getClassNameAF(isNotStylized, styles.HistoryShowInfo, classes?.HistoryShowInfo) }
                onClick={ this.handleToggleShowAll }
                data-test={ ATTRIBUTES.activityFeedHistoryToggleShowAll }
            >
                <div className={ getClassNameAF(isNotStylized, styles.HistoryShowInfoButton, classes?.HistoryShowInfoButton) }>
                    <span>{ text }</span>
                    <div dangerouslySetInnerHTML={ { __html: this.showAll ? IconChevronUp : IconChevronDown } }
                         className={ getClassNameAF(isNotStylized, styles.HistoryIconChevron, classes?.HistoryIconChevron) } />
                </div>
            </div>

        );
    };

    render() {
        const { style, item, title, isNotStylized, classes } = this.props;
        const dateValue = getDateValueByString(item.timestamp, getUserTimezone(), `${ getTranslateDateFormat() } HH:mm:ss`);

        return (
            <div
                className={ getClassNameAF(isNotStylized, styles.HistoryItem, classes?.HistoryItem) }
                data-test={ ATTRIBUTES.activityFeedItemHistory }
            >
                <div className={ getClassNameAF(isNotStylized, styles.HistoryHeadBlock, classes?.HistoryHeadBlock) }>
                    <div className={ getClassNameAF(isNotStylized, styles.HistoryUser, classes?.HistoryUser) }>
                        { this.renderAvatar(item.author) }
                        <div className={getClassNameAF(isNotStylized, styles.HistoryContent, classes?.HistoryContent)}>
                            <div
                                className={getClassNameAF(isNotStylized, styles.HistoryUserTitle, classes?.HistoryUserTitle)}
                                data-test={ ATTRIBUTES.activityFeedItemAuthor }
                            >
                                { item.author.name }
                            </div>
                            <div
                                className={ getClassNameAF(isNotStylized, styles.HistoryDate, classes?.HistoryDate) }
                                data-test={ ATTRIBUTES.activityFeedItemDate }
                            >
                                { dateValue }
                            </div>
                        </div>

                    </div>
                    <div className={ getClassNameAF(isNotStylized, styles.HistoryHead, classes?.HistoryHead) } style={ style }>
                        <style>{ `.IconColor${ item.sys_id } > svg > path { fill: ${ style && style.color } !important; }` }</style>
                        <div dangerouslySetInnerHTML={ { __html: IconHistory } }
                             className={ `${ getClassNameAF(isNotStylized, styles.HistoryIconComment, classes?.HistoryIconComment) } IconColor${ item.sys_id }` } />
                        <div
                            className={ getClassNameAF(isNotStylized, styles.HistoryTitle, classes?.HistoryTitle) }
                            data-test={ ATTRIBUTES.activityFeedItemTitle }
                            style={style ? { color: style.color } : {}}
                        >
                            { title }
                        </div>
                    </div>
                </div>

                <div
                    className={ `${ getClassNameAF(isNotStylized, styles.HistoryChanges, classes?.HistoryChanges) } ${ this.isBigText && !this.showAll ? styles.BigText : '' } ${ this.showAll ? styles.ShowAll : '' }` }
                    ref={ this.historyEl }
                >
                    { this.renderChanges() }
                </div>
                { this.renderShowAll() }
            </div>
        );
    }
}
