import * as React from 'react';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import OptionsInput from 'components/dynamicForms/view/field/optionsInput';
import TextArea from 'components/dynamicForms/view/field/textArea';
import Select from 'components/conditionFilters/select';
import MultiSelect from 'components/multiselect';
import Reference from 'components/dynamicForms/view/field/reference';
import List from 'components/dynamicForms/view/field/list';
import DocumentId from 'components/dynamicForms/view/field/documentId';
import DateTimeInput from 'components/dynamicForms/view/field/dateTimeInput';
import langStore from 'globalState/lang';
import { fetchDynamicFilterOptions, fetchTableChoices, fetchDynamicOpts } from 'actions/conditions';
import * as _ from 'lodash';
import { convertServerTimeToISO, getUserTimezone } from 'helpers/getUserTimeZone';
import moment from 'moment-timezone';
import { getUrlParams } from 'helpers/data';

const IS_EMPTY_OPERATOR = 'ISEMPTY';
const IS_NOT_EMPTY_OPERATOR = 'ISNOTEMPTY';
const IS_VALCHANGES_OPERATOR = 'VALCHANGES';
const IS_ANYTHING_OPERATOR = 'EMPTYSTRING';
const IS_EMPTYSTRING_OPERATOR = 'EMPTYSTRING';


// Для фильтрации в заголовке таблицы
const handleKeyUp = (onChange, isSubmitEnter) => (e) => {
    if (isSubmitEnter && e.keyCode === 13) {
        onChange({
            value: e.target.value,
            submit: true,
        });
    }
};

//Отображение третьего поля в condition
export const showField = (type, value, onChange, onValueChange, options = [], special = {}, props = {}, isSubmitEnter = false, maxLengthFind, valueOpts = []) => {
    let hasEmptyOption = undefined;
    const { filter_titles } = langStore.getTranslate();
    const isServicePortal = props.isServicePortal;
    if (typeof props.hasEmptyOption !== 'undefined') {
        hasEmptyOption = props.hasEmptyOption;
        delete props.hasEmptyOption;
    }
    switch (type) {
        case 'text':
        case 'url':
        case 'translated_text':
        case 'phone':
            const textValue = Array.isArray(value) ? '' : value;
            return (
                <OptionsInput
                    { ...props }
                    value={ textValue }
                    onKeyUp={ handleKeyUp(onChange, isSubmitEnter) }
                    onChange={ onValueChange }
                    maxLength={ maxLengthFind }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
        case 'datetime':
            return (
                <DateTimeInput
                    { ...props }
                    pickertype="datetime"
                    value={ value }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
        case 'datetime_specific':
            return (
                <DateTimeInput
                    { ...props }
                    pickertype="datetime_specific"
                    value={ value }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
        case 'date':
            return (
                <DateTimeInput
                    { ...props }
                    pickertype="date"
                    value={ value }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
        case 'time':
            return (
                <DateTimeInput
                    { ...props }
                    pickertype="time"
                    value={ value }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
        case 'timestamp':
            return (
                <DateTimeInput
                    { ...props }
                    pickertype="timestamp"
                    value={ value }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
        case 'select':
            let selectValue = value;
            if (value && typeof value === 'object') {
                selectValue = value.database_value || value;
            }
            return (
                <Select
                    { ...props }
                    value={ selectValue }
                    special={ { values: options } }
                    onChange={ onChange }
                    hasEmptyOption={ hasEmptyOption }
                    isServicePortal={ isServicePortal }
                />
            );
        case 'multiSelect':
            let mSelectValue = value || [];
            if (Array.isArray(value)) {
                mSelectValue = value.map(v => {
                    if (v === null || v.database_value === null) {
                        return null;
                    }
                    return v.database_value || v;
                });
            }
            else if (typeof value === 'object') {
                mSelectValue = [value.database_value === null ? null : (value.database_value || value)];
            }
            else if (typeof value === 'string') {
                mSelectValue = [value];
            }
            return (
                <MultiSelect
                    { ...props }
                    isReadOnly={ props.readOnly || false }
                    values={ mSelectValue }
                    options={ options }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                />
            );
        case 'multiText':
            const textAreaValue = Array.isArray(value) ? value.join('\n') : value;
            return (
                <TextArea { ...props }
                          value={ textAreaValue }
                          onChange={ onChange }
                          isServicePortal={ isServicePortal } />
            );
        case 'betweenDatetime':
            return (
                <React.Fragment>
                    <DateTimeInput
                        { ...props }
                        pickertype="datetime"
                        value={ value && value[0] ? value[0] : '' }
                        onChange={ onChangeByIndex(onChange, value, 0, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                    <span> { filter_titles.button_and.toLowerCase() } </span>
                    <DateTimeInput
                        { ...props }
                        pickertype="datetime"
                        value={ value && value[1] ? value[1] : '' }
                        onChange={ onChangeByIndex(onChange, value, 1, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                </React.Fragment>
            );
        case 'betweenDate':
            return (
                <React.Fragment>
                    <DateTimeInput
                        { ...props }
                        pickertype="date"
                        value={ value && value[0] ? value[0] : '' }
                        onChange={ onChangeByIndex(onChange, value, 0, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                    <span> { filter_titles.button_and.toLowerCase() } </span>
                    <DateTimeInput
                        { ...props }
                        pickertype="date"
                        value={ value && value[1] ? value[1] : '' }
                        onChange={ onChangeByIndex(onChange, value, 1, 1) }
                        valueOpts={ valueOpts }
                    />
                </React.Fragment>
            );
        case 'betweenTime':
            return (
                <React.Fragment>
                    <DateTimeInput
                        { ...props }
                        pickertype="time"
                        value={ value && value[0] ? value[0] : '' }
                        onChange={ onChangeByIndex(onChange, value, 0, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                    <span> { filter_titles.button_and.toLowerCase() } </span>
                    <DateTimeInput
                        { ...props }
                        pickertype="time"
                        value={ value && value[1] ? value[1] : '' }
                        onChange={ onChangeByIndex(onChange, value, 1, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                </React.Fragment>
            );
        case 'betweenTimestamp':
            return (
                <React.Fragment>
                    <DateTimeInput
                        { ...props }
                        pickertype="timestamp"
                        value={ value && value[0] ? value[0] : '' }
                        onChange={ onChangeByIndex(onChange, value, 0, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                    <span> { filter_titles.button_and.toLowerCase() } </span>
                    <DateTimeInput
                        { ...props }
                        pickertype="timestamp"
                        value={ value && value[1] ? value[1] : '' }
                        onChange={ onChangeByIndex(onChange, value, 1, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                </React.Fragment>
            );
        case 'selectBetween':
            return (
                <React.Fragment>
                    <Select
                        { ...props }
                        value={ value && value.length ? value[0] : '' }
                        special={ { values: options && options.length && Array.isArray(options[0]) ? options[0] : options } }
                        onChange={ onChangeByIndex(onChange, value, 0, 1) }
                        isServicePortal={ isServicePortal }
                    />
                    <span> { filter_titles.button_and.toLowerCase() } </span>
                    <Select
                        { ...props }
                        value={ value && value.length > 1 ? value[1] : '' }
                        special={ { values: options && options.length > 1 && Array.isArray(options[1]) ? options[1] : options } }
                        onChange={ onChangeByIndex(onChange, value, 1, 1) }
                        isServicePortal={ isServicePortal }
                    />
                </React.Fragment>
            );
        case 'between':
            return (
                <React.Fragment>
                    <StringInput
                        { ...props }
                        value={ value && value[0] ? value[0] : '' }
                        onChange={ onChangeByIndex(onChange, value, 0, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                    <span> { filter_titles.button_and.toLowerCase() } </span>
                    <StringInput
                        { ...props }
                        value={ value && value[1] ? value[1] : '' }
                        onChange={ onChangeByIndex(onChange, value, 1, 1) }
                        isServicePortal={ isServicePortal }
                        valueOpts={ valueOpts }
                    />
                </React.Fragment>
            );
        case 'trend':
            return (
                <React.Fragment>
                    <StringInput
                        { ...props }
                        value={ value && value[0] ? value[0] : '' }
                        onChange={ onChangeByIndex(onChange, value, 0, 2) }
                        isServicePortal={ isServicePortal }
                    />
                    <StringInput
                        { ...props }
                        value={ value && value[1] ? value[1] : '' }
                        onChange={ onChangeByIndex(onChange, value, 1, 2) }
                        isServicePortal={ isServicePortal }
                    />
                    <StringInput
                        { ...props }
                        value={ value && value[2] ? value[2] : '' }
                        onChange={ onChangeByIndex(onChange, value, 2, 2) }
                        isServicePortal={ isServicePortal }
                    />
                </React.Fragment>
            );
        case 'reference':
            if (value && value.term) {
                props.term = value.term;
            }
            return (
                <Reference
                    { ...props }
                    key="reference"
                    value={ value }
                    onChange={ onChange }
                    special={ special }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
        case 'list':
            return (
                <List
                    { ...props }
                    isEdit
                    value={ getListValue(value) }
                    special={ special }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                />
            );
        case 'documentId':
            return (
                <DocumentId
                    { ...props }
                    value={ value }
                    onChange={ onChange }
                    isServicePortal={ isServicePortal }
                />
            );
        case 'none':
            return null;
        default:
            return (
                <StringInput
                    { ...props }
                    placeholder={ filter_titles && filter_titles.enter_value }
                    readOnly={ true }
                    isServicePortal={ isServicePortal }
                    valueOpts={ valueOpts }
                />
            );
    }
};

//обработка получения значения для листа
const getListValue = (value) => {
    if (Array.isArray(value)) {
        return value.map(v => ({
            database_value: v.database_value || v.sys_id,
            display_value: v.value || v.display_value,
            link: v.link,
            reference_state: v.reference_state || null,
        }));
    }
    if (typeof value === 'object') {
        return [
            {
                database_value: value.database_value,
                display_value: value.value || value.display_value,
                link: value.link,
                reference_state: value.reference_state || null,
            },
        ];
    }
    return value;
};

//получить предыдущее значение третьего поля (поле Значение)
const getPrevValue = (prevValue, i) => {
    if (!Array.isArray(prevValue)) {
        return '';
    }
    return prevValue[i] ? prevValue[i] : '';
};

// получение списка значений для поля Значение
const getValues = (prevValue, index, value, lastIndex) => {
    const newValues = [];
    for (let i = 0; i <= lastIndex; i++) {
        newValues[i] = i === index ? value : getPrevValue(prevValue, i);
    }
    return newValues;
};

//обработчик изменения поля Значения (index - порядковый номер поля)
const onChangeByIndex = (onChange, prevValue, index, lastIndex) => ({ value }) => {
    const values = getValues(prevValue, index, value, lastIndex);
    onChange({ value: values });
};

// Получение значения поля в зависимости от типа
export const getConditionValue = (type, value) => {
    switch (type) {
        case 'reference':
            return value && value.database_value ? value : null;
        case 'integer':
        case 'float':
        case 'decimal':
            if (_.isPlainObject(value)) {
                value.display_value = value.display_value.replace(/[^0-9.]+/, '');
                value.database_value = value.database_value.replace(/[^0-9.]+/, '');
                return value;
            }
            return value.replace(/[^0-9.]+/, '');
        case 'none':
            return '';
        default:
            return value;
    }
};

//Подготовка строки условия для передачи на backend
export const getConditionString = (filterFields, sortingFields, groupingFields) => {
    const { filter_titles } = langStore.getTranslate();
    const hasValueFields = filterFields.filter(row => !_.isEmpty(row.getField()));
    const lastBlock = hasValueFields.length > 0 ? hasValueFields[hasValueFields.length - 1].getBlock() : 0;
    const firstBlock = hasValueFields[0] && hasValueFields[0].getBlock();

    let filteringUrl = '';
    for (let i = firstBlock; i <= lastBlock; i++) {
        const filteredBlock = hasValueFields.filter(row => row.getBlock() === i);
        if (filteredBlock.length === 0) {
            continue;
        }
        const orIndexes = [];
        filteringUrl = `${ filteringUrl }${ i === firstBlock ? '(' : '^OR(' }`;
        let innerBlock = '';
        filteredBlock.map((row, index) => {
            if (row.getType() === 'OR') {
                orIndexes.push(index);
            }
        });
        filteredBlock.forEach((row, index) => {
            innerBlock = `${ innerBlock }${ setFilterInnerUrl(filteredBlock, orIndexes, row, index, innerBlock) }`;
        });
        filteringUrl = `${ filteringUrl }${ innerBlock })`;
    }

    const sortingUrls = sortingFields.map(row => {
        const isDesc = row.getDirection() && (row.getDirection().database_value === filter_titles.z_to_a || row.getDirection() === filter_titles.z_to_a);
        const desc = isDesc ? 'DESC' : '';
        return `ORDERBY${ desc }${ row.getField().dot_walking_attribute }`;
    });

    const groupingUrls = groupingFields.map(row => {
        const direction = row.getDirection();
        return `^${ direction.database_value }${ row.getField().dot_walking_attribute }`;
    });

    let resultGroupUrl = '';
    if (groupingUrls.length > 0) {
        resultGroupUrl = groupingUrls.join('');
    }

    return `${ filteringUrl === '()' ? '' : filteringUrl }${ resultGroupUrl }${ sortingUrls.length > 0 ? `^${ sortingUrls.join('^') }` : '' }`;
};

//замена круглых скобок на =OP и =CP
const replaceRoundBracketsInValue = (value) => {
    if (_.isNil(value)) {
        return `null`;
    }
    return value.replace(/[(]/g, '=OP').replace(/[)]/g, '=CP');
};

const trimValue = (value) => {
    if (value && typeof value === 'string') {
        return value.replace(/^(\s|(\r?\n|\r))+|(\s|(\r?\n|\r))+$/g, '');
    }
    return value;
};

const checkOperatorValue = (operatorValue, value) => {
    const operatorIs = [
        '=',
        '!=',
    ].includes(operatorValue);

    return value === null && operatorIs;
};

// Преобразование значения condition в строку для отправки на back
const getRowValue = (value, operatorValue, field) => {
    if (typeof value === 'string' && value.match(/^javascript:/)){
        return value;
    }
    if (Array.isArray(value)) {
        return getArrayValue(value, field);
    }
    if (operatorValue === 'IN') {
        let resultValue = value;
        if (typeof value === 'object') {
            return value.database_value === null ? null : (value.database_value || value);
        }
        if (typeof value === 'string') {
            resultValue = value.replace(/,/g, '\n').split(/\n+/);
        }
        return getArrayValue(resultValue);
    }
    if (value && value.sys_id !== undefined) {
        return value.sys_id;
    }
    if (value && value.database_value !== undefined) {
        if (checkOperatorValue(operatorValue, value.database_value)) {
            return value.database_value;
        }
        return replaceRoundBracketsInValue(value.database_value);
    }
    if (value && typeof value.document_id !== 'undefined') {
        return value.document_id;
    }
    return replaceRoundBracketsInValue(value);
};

const getObjValue = (obj) => {
    if (_.isNil(obj)) {
        return obj;
    }
    if (obj.sys_id !== undefined) {
        return obj.sys_id;
    }
    if (obj.database_value !== undefined) {
        return obj.database_value;
    }
    return obj;
};

//Преобразование array в строку
const getArrayValue = (value, field = null) => {
    let values = '';
    value.forEach(v => {
        const simpleValue = trimValue(replaceRoundBracketsInValue(getObjValue(v)));
        if (simpleValue) {
            if (field && field.column_type === 'list') {
                values += !values ? simpleValue : `,${ simpleValue }`;
            }
            else {
                values += !values ? simpleValue : `@${ simpleValue }`;
            }
        }
    });
    return values;
};

// проверяет оператор на присудствие show_element = none
const checkOperator = (operator) => {
    const noneOperators = [
        IS_EMPTY_OPERATOR,
        IS_NOT_EMPTY_OPERATOR,
        IS_VALCHANGES_OPERATOR,
        IS_ANYTHING_OPERATOR,
        IS_EMPTYSTRING_OPERATOR,
    ];
    if (typeof operator === 'object') {
        if (operator.show_element) {
            return operator.show_element !== 'none' && operator.show_element !== 'multiSelect';
        }
        else {
            return !noneOperators.includes(operator.database_value);
        }
    }
    return true;
};

// Преобразование в строку внутреннего условия condition
const setFilterInnerUrl = (filteredBlock, orIndexes, row, index, prevBlock) => {
    const fieldValue = row.getField().dot_walking_attribute || row.getField();
    let operatorValue = row.getOperator().database_value || row.getOperator();
    const rowValue = row.getValue();
    let value = getRowValue(rowValue, operatorValue, row.getField());
    const isValueNull = checkOperatorValue(operatorValue, value);
    if (
        !isValueNull &&
        (!fieldValue ||
            !operatorValue ||
            !value && checkOperator(row.getOperator()))
    ) {
        return '';
    }
    let stringRow = `${ fieldValue }${ operatorValue }${ value }`;

    if (isValueNull) {
        stringRow = `${ fieldValue }${
            operatorValue === '=' ? IS_EMPTY_OPERATOR : IS_NOT_EMPTY_OPERATOR
        }`;
    }
    const isOpenOr = index !== 0 && orIndexes.includes(index + 1) && !orIndexes.includes(index); // index не равен 0 и следующий элемент с OR, а текущий с AND
    // 1. Если есть условия с OR и (оно идет следом за текущим при этом первое среди OR или isOpenOr)
    if (orIndexes.length > 0 && (orIndexes[0] === index + 1 || isOpenOr)) {
        stringRow = `${ showBracket(filteredBlock, '(') }${ stringRow }`;
    }
    // 2. Если элемент не первый
    if (index !== 0) {
        const el = orIndexes.includes(index) ? 'OR' : '';
        stringRow = `${ prevBlock ? '^' : '' }${ el }${ stringRow }`;
    }
    const isCloseOr = filteredBlock[index + 1] && orIndexes.includes(index) && !orIndexes.includes(index + 1);
    // 3. Если есть условия с OR и (последний элемент с OR или следующий элемент без OR)
    if (orIndexes.length > 0 && (orIndexes[orIndexes.length - 1] === index || isCloseOr)) {
        stringRow = `${ stringRow }${ showBracket(filteredBlock, ')') }`;
    }
    return stringRow;
};

const showBracket = (filteredBlock, mark) => {
    if (filteredBlock.length > 2) {
        return mark;
    }
    return '';
};

// получение списка значений для choice полей
export const fetchChoices = async (columnId, tableId) => {
    if (!columnId || !tableId) {
        return [];
    }
    const params = {
        table_id: tableId,
        column_id: columnId,
    };
    const response = await fetchTableChoices(params);
    return response.isOkStatus && response.data ? response.data : [];
};

// получение списка значений для dynamic полей
export const fetchDynamics = async (columnId, tableId) => { //
    if (!columnId) {
        return [];
    }
    const params = {
        column_id: columnId,
    };

    if (tableId) {
        params.table_id = tableId;
    }

    const response = await fetchDynamicFilterOptions(params);
    const data = response.isOkStatus && response.data ? response.data : {};
    return data.items ? data.items.map(item => {
        return {
            database_value: item.sys_id,
            display_value: item.title,
        };
    }) : [];
};


// получение списка опций FT0000135
export const fetchOpts = async (operator, columnId, tableId) => {
    const params = {
        table_id: tableId,
        column_id: columnId,
        operator: operator,
    };

    const response = await fetchDynamicOpts(params);
    const data = response.isOkStatus && response.data ? response.data : {};
    return data.opts ? data.opts.map(item => {
        return {
            database_value: item.value,
            display_value: item.title,
        };
    }) : [];
};

export const getFieldType = (field) => {
    return field.column_type && [
        'date',
        'timestamp',
        'time',
        'html',
        'script',
    ].includes(field.column_type)
        ? field.column_type
        : field.condition_type;
};

export const getQuarters = () => {
    return [
        {
            database_value: '1',
            display_value: 'I',
        },
        {
            database_value: '2',
            display_value: 'II',
        },
        {
            database_value: '3',
            display_value: 'III',
        },
        {
            database_value: '4',
            display_value: 'IV',
        },
    ];
};

const getTimezoneValue = (hour) => {
    const time = convertServerTimeToISO(`${ hour }:00:00`);
    const valueTime = moment.tz(time, getUserTimezone());
    return valueTime.tz('UTC').format('H');
};

export const getHours = () => {
    let hours = [];
    for (let i = 0; i <= 23; i++) {
        const hour = i < 10 ? `0${ i }` : i;
        hours.push({
            database_value: getTimezoneValue(hour),
            display_value: hour,
        });
    }
    return hours;
};

export const calculateCondtionString = (propsCondition, changeCondition, search) => {
    const params = getUrlParams(search);
    if (!propsCondition && !params.condition && !changeCondition) {
        return '';
    }
    if (changeCondition) {
        return changeCondition;
    }
    if (params.condition) {
        return params.condition;
    }
    return propsCondition;
};
