import { observable, action } from 'mobx';
import { isMedia } from 'helpers/html';
import { savePreference, getPreference } from 'actions/preferences';
import { fetchDynamicMenuList, fetchDynamicMenuShortList, fetchFavorites, fetchMenuList } from 'actions/menu';
import _ from 'lodash';
import { CategoriesOpenType, MenuItemType } from 'types/globalState/menu';
import { MenuItem } from 'globalState/sidebarState/menuItem';
import { getItems } from 'helpers/sidebar';

class SidebarState {
    @observable _favorites: MenuItemType[] = [];
    @observable _menuItems: MenuItemType[] = [];
    @observable isOpened = false;
    @observable isPinned = false;
    preventCloseNav = false;
    @observable activeTabIndex = -1;
    @observable menuId = '';
    @observable isLoadingMenu = false;
    @observable isLoadingFavorites = false;
    @observable private _isLoadingItems = false;
    @observable private _isOpenDynamic = false;
    @observable private _dynamicMenuItems: MenuItemType[] = [];
    @observable private _dynamicTitle = '';
    @observable private _configureUrl = '';
    @observable private _activeMenuItems: CategoriesOpenType[] = [];
    @observable private _dynamicActiveMenuItems: CategoriesOpenType[] = [];

    setOpenedState(boolean) {
        this.isOpened = boolean;
    }

    fetchMenuPreferences = async () => {
        const { data, isOkStatus} = await getPreference(['menu.pin', 'menu.item', 'menu.tab']);
        if (isOkStatus) {
            this.isPinned = data['menu.pin'] === 'true';
            this.menuId = data['menu.item'];
            if (this.isPinned) {
                this.activeTabIndex = parseInt(data['menu.tab']);
                if(!isMedia('sm')){
                    this.isOpened = this.activeTabIndex !== -1;
                }
                if (this.activeTabIndex === 0) {
                    const openDynamicMenuId = window.localStorage.getItem('openDynamicMenuId') || '';
                    if (!openDynamicMenuId) {
                        this.fetchMenuList().catch(console.error);
                    } else {
                        this.setIsOpenDynamic(true);
                        this.preventCloseNav = true;
                        this.fetchDynamicMenuList(openDynamicMenuId).catch(console.error);
                    }
                } else if (this.activeTabIndex === 1) {
                    this.fetchFavoritesList().catch(console.error);
                }
            }
        }
    };

    setPinnedState = async (boolean: boolean) => {
        this.isPinned = boolean;
        await savePreference('menu.pin', boolean);
        if (boolean) {
            await savePreference('menu.tab', this.activeTabIndex);
        }
    };

    setTabIndex = async (index: number) => {
        this.activeTabIndex = index;
        if (this.isPinned) {
            await savePreference('menu.tab', this.activeTabIndex);
        }
    };

    getMenuId = (): string => {
        return this.menuId;
    };

    setMenuId = async (menuId: string) => {
        this.menuId = menuId;
        await savePreference('menu.item', menuId);
    };

    toggleOpenedState() {
        this.isOpened = !this.isOpened;
    }

    getFavorites = () => {
        return this._favorites;
    };

    @action
    fetchFavoritesList = async (getFromCache = true) => {
        this.isLoadingFavorites = true;
        const { data, isOkStatus } = await fetchFavorites(getFromCache);
        if (isOkStatus) {
            this._favorites = _.map(data, item => new MenuItem(item));
            this.prepareFavorites();
        }
        this.isLoadingFavorites = false;
    };

    getMenuItems = (): MenuItemType[] => {
        return this._menuItems;
    };

    @action
    fetchMenuList = async (getFromCache = true) => {
        this.isLoadingMenu = true;
        const { isOkStatus, data } = await fetchMenuList(getFromCache);
        if (isOkStatus) {
            this._menuItems = this.prepareItems(_.map(data.elements, item => new MenuItem(item)));
        }
        this.isLoadingMenu = false;
    };

    @action
    fetchDynamicMenuList = async (dynamicCategoryId) => {
        this.isLoadingMenu = true;
        const updateData = (data) => {
            this._dynamicMenuItems = [];
            this._dynamicTitle = data.displayName;
            this._configureUrl = data.configure_url;
            const items = [ ...getItems(data.categories, false), ...getItems(data.items, true) ];
            _.forEach(items, item => {
                this._dynamicMenuItems.push(new MenuItem(item));
            });
        };
        const { isOkStatus: isOkStatusShort, data: dataShort } = await fetchDynamicMenuShortList(dynamicCategoryId);
        if (isOkStatusShort) {
            updateData(dataShort);
            const { isOkStatus, data } = await fetchDynamicMenuList(dynamicCategoryId);
            if (isOkStatus) {
                updateData(data);
            }
        }
        this.isLoadingMenu = false;
    }

    getIsLoadingMenu = (): boolean => {
        return this.isLoadingMenu;
    };

    setIsLoadingMenu = (isLoading: boolean) => {
        this.isLoadingMenu = isLoading;
    };

    getIsLoadingFavorites = (): boolean => {
        return this.isLoadingFavorites;
    };

    setIsLoadingFavorites = (isLoading: boolean) => {
        this.isLoadingFavorites = isLoading;
    };

    getIsOpenDynamic(): boolean {
        return this._isOpenDynamic;
    }

    setIsOpenDynamic(value: boolean) {
        this._isOpenDynamic = value;
    }

    getDynamicMenuItems(): MenuItemType[] {
        return this._dynamicMenuItems;
    }

    setDynamicMenuItems(value: MenuItemType[]) {
        this._dynamicMenuItems = value;
    }

    getDynamicTitle(): string {
        return this._dynamicTitle;
    }

    setDynamicTitle(value: string) {
        this._dynamicTitle = value;
    }

    getConfigureUrl(): string {
        return this._configureUrl;
    }

    setConfigureUrl(value: string) {
        this._configureUrl = value;
    }

    getActiveMenuItems(): CategoriesOpenType[] {
        return this._activeMenuItems;
    }

    setActiveMenuItems(value: CategoriesOpenType[]) {
        this._activeMenuItems = value;
    }

    getDynamicActiveMenuItems(): CategoriesOpenType[] {
        return this._dynamicActiveMenuItems;
    }

    setDynamicActiveMenuItems(value: CategoriesOpenType[]) {
        this._dynamicActiveMenuItems = value;
    }

    clear = () => {
        this._favorites = [];
        this._menuItems = [];
    };

    @action
    prepareFavorites(): MenuItemType[] {
        const items = this._favorites;
        const preparedItems: MenuItemType[] = [];
        if (!items || items.length === 0) {
            return preparedItems;
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item && !_.isEmpty(item.getItems())) {
                this.prepareSubItems(item);
            } else {
                if (item.getUrl() === this.getPath()) {
                    item.setIsActive(true);
                }
            }
            preparedItems.push(item);
        }

        return preparedItems;
    }

    @action
    prepareItems(items: MenuItemType[]): MenuItemType[] {
        const preparedItems: MenuItemType[] = [];
        if (items.length === 0) {
            return preparedItems;
        }

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (!_.isEmpty(item.getItems())) {
                this.prepareSubItems(item);
            }
            else {
                if (item.getUrl() === this.getPath()) {
                    item.setIsActive(true);
                }
            }
            preparedItems.push(item);
        }
        return preparedItems;
    }

    @action
    prepareSubItems = (item: MenuItemType) => {
        let menuId = new URLSearchParams(window.location.search).get('menu_id');
        if (!menuId) {
            menuId = this.menuId;
        }

        for (let i = 0, subItems = item.getItems(), length = subItems.length; i < length; i++) {
            const subItem: MenuItemType = subItems[i];
            const findIndex = subItem.getUrl().search(/\?/);
            const newUrl = findIndex !== -1 ? subItem.getUrl().slice(0, findIndex) : subItem.getUrl();
            const search = findIndex !== -1 ? `&${ subItem.getUrl().slice(findIndex + 1) }` : '';
            const usp = new URLSearchParams(search);
            const resultSearchParams = usp.toString();
            subItem.setUrl(newUrl + '?' + resultSearchParams);

            if (subItem.getItems()) {
                this.prepareSubItems(subItem);
                const activeSub = subItem.getItems().filter((item) => item.getIsActive());
                if (activeSub.length > 0 || item.getIsActive()) {
                    item.setIsDefaultOpened(true);
                    item.setIsActive(true);
                } else {
                    item.setIsActive(false);
                }
            }

            if (subItem.getSysId() === menuId) {
                item.setIsDefaultOpened(true);
                subItem.setIsActive(true);
            } else {
                subItem.setIsActive(false);
            }

        }
    };

    getPath = () => {
        let path = '';

        const location = window.location;
        if (location.pathname.split('/')[1] === 'record') {
            const searchParams: URLSearchParams = new URLSearchParams(location.search);

            if (searchParams.has('parentUrl') && [_.map(searchParams, param => param)].length === 1) {
                path = location.search.substr(11);
            }
        }
        else {
            path = location.pathname + location.search;
        }
        return path;
    };
}


export default new SidebarState();
