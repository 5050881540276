import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './styles.module.scss';
import IconSearch from 'assets/img/icons/search.svg';
import langStore from 'globalState/lang';
import IconClose from 'assets/img/icons/close-x.svg';

/**
 * @method onChangeSearch
 * @method onSearch
 * @param {string} search
 * @param {string} placeholder
 */
@observer
export default class ListFilterSearchKeywords extends React.Component {
    @observable isFocused = false;

    handleValueChange = (event) => {
        const { onChangeSearch } = this.props;
        if (onChangeSearch) {
            onChangeSearch(event.target.value);
        }
    };

    handleFocus = () => {
        this.isFocused = true;
    };

    handleBlur = () => {
        this.isFocused = false;
    };

    handleKeyDown = (event) => {
        const { onSearch } = this.props;
        if (event.key === 'Enter') {
            event.preventDefault();
            if (onSearch) {
                onSearch();
            }
        }
    };

    clearSearch = () => {
        const { onChangeSearch, onSearch } = this.props;
        if (onChangeSearch) {
            onChangeSearch('');
        }
        if (onSearch) {
            onSearch();
        }
    };

    renderIcons = () => {
        const { search } = this.props;
        if (!search) {
            return (
                <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconSearch } } />
            );
        }
        return (
            <div className={ `${styles.Icon} ${styles.CloseIcon}` } dangerouslySetInnerHTML={ { __html: IconClose } } onClick={ this.clearSearch } />
        );
    };

    render() {
        const { placeholder, search } = this.props;
        const { filter_titles } = langStore.getTranslate();
        const description = placeholder ? placeholder : (filter_titles && filter_titles.keyword_search);
        return (
            <div className={ styles.Search }>
                <label className={ styles.InputWrap }>
                    <input className={ styles.Input }
                           value={ search }
                           onKeyDown={ this.handleKeyDown }
                           onChange={ this.handleValueChange }
                           onFocus={ this.handleFocus }
                           onBlur={ this.handleBlur }
                           placeholder={ description }
                    />
                    { this.renderIcons() }
                </label>
            </div>
        );
    }
}
