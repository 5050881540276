import apiRequest from 'lib/apiRequest';
import { handleResponse } from 'helpers/action';


/**
 * Запрос для получения url аттача
 * @param params
 * @returns {Promise<{data: *, response: *, isOkStatus: boolean}|{data: {}, response: *, isOkStatus: boolean}>}
 */
export const fetchAttachmentUrl = async (params) => {
    const response = await new apiRequest(`GET /attachments/get-url`).qs(params).send();
    return handleResponse(response);
};

export async function getImageLink(attachment_id) {
    const response = await new apiRequest(`GET /attachments/get-url/${attachment_id}`).send().catch(console.error);
    return handleResponse(response);
}
