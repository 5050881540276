import React, {useState, useRef} from 'react';
import styles from './styles.module.scss';
import './custom.css';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {AutoScrollPlugin} from '@lexical/react/LexicalAutoScrollPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import ImagePlugin from './plugins/ImagePlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import PopupToolbarPlugin from './plugins/ToolbarPlugin/PopupToolbarPlugin';
import {editorConfig} from "./config/editorConfig";
import {OnChangePlugin} from "./plugins/OnChangePlugin";
import {ResizePlugin} from "./plugins/ResizePlugin";
import type {LexicalEditor} from 'lexical';
import {ATTRIBUTES} from "constants/attributesForTests";
import langStore from "globalState/lang";

const Placeholder = ({readOnly}) => {
    if (readOnly) return null;

    const { rich_text } = langStore.getTranslate();

    return (
        <div className={styles.Placeholder}>
            { rich_text?.enter_your_message }
        </div>
    );
};

export default function TextEditor({readOnly, value, onChange, setRawEditor}) {
    const [isShowToolbar, setIsShowToolbar] = useState<boolean>(false);
    const [editor, setEditor] = useState<LexicalEditor | null>(null);
    const editorRef = useRef<HTMLDivElement>(null);

    const rootElement = editor?.getRootElement();
    const maxWidth = rootElement?.offsetWidth;

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <OnChangePlugin
                value={value}
                isReadOnly={readOnly}
                setEditor={setEditor}
                setValue={onChange}
                setRawEditor={setRawEditor}
            />
            <div className={`${styles.EditorContainer} ${readOnly ? styles.DisabledEditor : ''}`}>
                { <ToolbarPlugin
                    isShowToolbar={isShowToolbar}
                    setIsShowToolbar={setIsShowToolbar}
                />}
                <div
                    className={styles.EditorInner}
                    data-test={ ATTRIBUTES.editorInput }
                    ref={editorRef}
                >
                    <RichTextPlugin
                        contentEditable={<ContentEditable
                            style={{maxWidth}}
                            className={styles.EditorInput}
                        />}
                        placeholder={<Placeholder readOnly={readOnly}/>}
                    />
                    <HistoryPlugin/>
                    <ImagePlugin/>
                    <CodeHighlightPlugin/>
                    <ListPlugin/>
                    <LinkPlugin/>
                    <AutoLinkPlugin/>
                    <AutoScrollPlugin scrollRef={editorRef} />
                    <PopupToolbarPlugin
                        isShowToolbar={isShowToolbar}
                    />
                    <ListMaxIndentLevelPlugin maxDepth={7}/>
                </div>
                <ResizePlugin editorRef={editorRef}/>
            </div>
        </LexicalComposer>
    );
}
