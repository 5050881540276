import * as React from 'react';
import DragAndDropState from '../dragAndDrop/state';
import TaskBoardLanesState from 'components/taskBoard/taskBoardLanes/state';
import { updateLaneOrder, updateCardOrder, updateCardLaneOrder } from 'actions/taskBoard';
import { observer } from 'mobx-react';
import { EMPTY_CARD } from 'constants/taskBoard';

/**
 * @param {string} sysId
 * @param {string} type
 * @param {number} order
 * @param {string} categoryId
 * @param {string} className
 */
@observer
export default class DroppableWrapper extends React.Component {
    handleDrop = async () => {
        const { type, order, categoryId } = this.props;
        const draggedSysId = DragAndDropState.getSysId();
        const draggedType = DragAndDropState.getType();
        const draggedCategoryId = DragAndDropState.getCategoryId();
        const draggedOrder = DragAndDropState.getOrder();
        const firstCategoryId = DragAndDropState.getFirstLaneId();

        if (type === 'lane' && type === draggedType) {
            const response = await updateLaneOrder({
                lane_id: draggedSysId,
                order: order <= draggedOrder ? order : order + 1,
            });
            if (response.isOkStatus) {
                TaskBoardLanesState.updateLanes(response.data);
            }
        }
        if (type === 'card' && type === draggedType) {
            if ((categoryId === draggedCategoryId && !firstCategoryId) || (firstCategoryId && categoryId === firstCategoryId)) {
                const response = await updateCardOrder({
                    card_id: draggedSysId,
                    order: order <= draggedOrder ? order : order + 1,
                });
                if (response.isOkStatus) {
                    TaskBoardLanesState.updateCards(categoryId, response.data);
                }
            } else {
                const response = await updateCardLaneOrder({
                    lane_id: categoryId,
                    card_id: draggedSysId,
                    order: order,
                });
                if (response.isOkStatus) {
                    TaskBoardLanesState.updateCards(categoryId, response.data, firstCategoryId || draggedCategoryId, draggedSysId);
                }
            }
        }
        DragAndDropState.clean();
    };

    handleDragEnter = () => {
        const { type, sysId, categoryId } = this.props;
        const draggedType = DragAndDropState.getType();
        const draggedSysId = DragAndDropState.getSysId();
        // const draggedCategoryId = DragAndDropState.getCategoryId();
        const isNotEnterCard = !sysId.includes(EMPTY_CARD) && sysId !== draggedSysId && !DragAndDropState.getEnterId().includes(sysId);
        if (type === draggedType && type === 'lane') {
            DragAndDropState.setEnterId(`${type}${sysId}`);

            if (sysId !== draggedSysId) {
                TaskBoardLanesState.changeLaneOrder(sysId, draggedSysId);
            }
        } else if (type === draggedType && type === 'card' && isNotEnterCard) {
            DragAndDropState.setEnterId(`${type}${sysId}`);

            TaskBoardLanesState.addEmptyCard(categoryId, sysId);
        }
    };

    handleDragOver = (event) => {
        event.preventDefault();
    };

    handleDragLeave = (event) => {
        event.stopPropagation();
        const { type, categoryId, sysId } = this.props;
        const draggedType = DragAndDropState.getType();
        const draggedSysId = DragAndDropState.getSysId();
        if (type === draggedType && type === 'card' && sysId !== draggedSysId) {
            DragAndDropState.setEnterId('');
            TaskBoardLanesState.deleteCard(categoryId, EMPTY_CARD);
        }
    };

    render() {
        return (
            <div
                className={ this.props.className }
                onDrop={ this.handleDrop }
                onDragEnter={ this.handleDragEnter }
                onDragOver={ this.handleDragOver }
                onDragLeave={ this.handleDragLeave }
            >
                { this.props.children }
            </div>
        );
    }
}
