import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import ErrorIcon from 'assets/img/icons/alert-octagon.svg';
import InfoIcon from 'assets/img/icons/alert-circle.svg';
import WarningIcon from 'assets/img/icons/alert-triangle.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Описание: Сообщения под полями формы
 * Параметры:
 * type: {type: string} - тип сообщения
 *
 */

@observer
export default class FieldMessage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const classNames = [styles.Message];

        let icon = null;
        switch (this.props.type) {
            case 'error':
                classNames.push(styles.error);
                icon = ErrorIcon;
                break;

            case 'info':
                classNames.push(styles.info);
                icon = InfoIcon;
                break;

            case 'warning':
                classNames.push(styles.warning);
                icon = WarningIcon;
                break;

            case 'success':
                classNames.push(styles.success);
                break;

            default:
                break;
        }

        return (
            <div
                className={ classNames.join(' ') }
                data-test={ `${this.props.columnName}-${ ATTRIBUTES.fieldMessage }` }
            >
                { icon && <div className={ styles.Icon } dangerouslySetInnerHTML={ { __html: icon } } /> }
                { this.props.message }
            </div>
        );
    }
}
