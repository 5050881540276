import * as React from 'react';
import { observer } from 'mobx-react';

import PageData from 'components/portalDesigner/state/pageData';

import IsLoading  from 'components/portalDesigner/view/main/states/isLoading.jsx';
import PageMustBeChoose  from 'components/portalDesigner/view/main/states/pageMustBeChoose.jsx';
import Containers  from 'components/portalDesigner/view/main/states/containers.jsx';
import EmptyPage  from 'components/portalDesigner/view/main/states/emptyPage.jsx';
import brandingState from 'globalState/branding';

/**
 * дизайнер страниц порталов
 * @props isLoading - флаг загрузки
 */
@observer
export default class Main extends React.Component {

    constructor(props) {
        super(props);
        if (PageData.main && PageData.main.favicon) {
            brandingState.setFavicon(PageData.main.favicon);
        }
    }

    render() {
        const page = PageData.currentPageSysId;
        const containers = PageData.getContainers();

        switch (true) {
            case this.props.isLoading:
                return <IsLoading />;

            case !page:
                return <PageMustBeChoose />;

            case containers.length > 0:
                return <Containers />;

            case containers.length === 0:
                return <EmptyPage />;
        }
    }
}