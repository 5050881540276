import styles from 'layouts/main/sidebar/styles.module.scss';
import { ATTRIBUTES } from 'constants/attributesForTests';
import sidebarState from 'globalState/sidebarState';
import IconSidebar from 'assets/img/icons/sidebar.svg';
import IconStar from 'assets/img/icons/star.svg';
import * as React from 'react';
import { SidebarTabsPropsType } from 'types/globalState/menu';
import { observer } from 'mobx-react';
import langStore from 'globalState/lang';
import Hint from 'components/hint';

function SidebarTabs({ handleTabClick }: SidebarTabsPropsType) {
    const refMenu = React.createRef<HTMLDivElement>();
    const refFav = React.createRef<HTMLDivElement>();
    const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');

    return (
        <div className={ styles.Tabs }>
            <div className={ styles.TabsList } data-test={ ATTRIBUTES.sidebar }>
                <div
                    onClick={ handleTabClick(0) }
                    className={ `${ styles.TabsItem } ${ sidebarState.activeTabIndex === 0 ? styles.active : '' }` }
                    dangerouslySetInnerHTML={ { __html: IconSidebar } }
                    data-test={ ATTRIBUTES.sidebarMenuIcon }
                    ref={ refMenu }
                />
                <Hint refParent={ refMenu }>{ system_buttons_hints?.menu }</Hint>
                <div
                    onClick={ handleTabClick(1) }
                    className={ `${ styles.TabsItem } ${ sidebarState.activeTabIndex === 1 ? styles.active : '' }` }
                    dangerouslySetInnerHTML={ { __html: IconStar } }
                    data-test={ ATTRIBUTES.sidebarFavoritesIcon }
                    ref={ refFav }
                />
                <Hint refParent={ refFav }>{ system_buttons_hints?.favorites }</Hint>
            </div>
        </div>
    );
}

export default observer(SidebarTabs);