import "core-js/stable";
import "regenerator-runtime/runtime"; // Need for async/await
require('helpers/polyfills');
import * as serviceWorkerRegistration from 'assets/pwa/serviceWorkerRegistration';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observable } from 'mobx';

import 'helpers/formatDatePHP2Moment';
import 'helpers/initialRedirectCheck'; // check for redirect_to params from url



window.PORTAL_STATE = observable({}); // Global stage for special widgets

import App from './App';

// Basic styles for all
import './basics-scss/index.scss';

// Ui action scripts
import './ui-actions-scripts/index';
import { errorListener } from 'helpers/misc';


// Global errors in our code handler
window.addEventListener('error', errorListener);

ReactDOM.render(
    <React.Fragment>
        <App />
    </React.Fragment>,
document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
