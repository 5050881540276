import * as React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';
import styles from './styles.module.scss';
import GlobalPortal from 'components/globalPortal';
import userState from 'globalState/user';
import langStore from 'globalState/lang';
import searchState from 'globalState/search';
import { fetchSearchResultsData } from 'actions/search';
import { smartRedirect } from 'helpers/history';
import { getRecordLink } from 'helpers/widget';
import { isMedia } from 'helpers/html';
import { getUrlParams } from 'helpers/data';
import IconSearch from 'assets/img/icons/search.svg';
import IconClose from 'assets/img/icons/close-x.svg';
import IconDocument from 'assets/img/icons/file-text.svg';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { DEFAULT_COUNT_RESULTS, SEARCH_PARAM, LARGE_MODE } from './config';
import { Hint, SearchWidgetProps } from 'types/components/portalWidgetsComponents/search';
import { UserData } from 'types/globalState/user';
import { KeyboardKeys } from 'types/components/dynamicForms/maskInput';
import { Record } from 'types/actions/search';

/**
 * Props:
 * size: sm-компактный или lg-большой
 * title: заголовок над строкой поиска в большом варианте
 * placeholder: серый текст в строке поиска до наступления события фокус
 * tsGroupId - идентификатор группы таблиц, в которых ведется поиск
 * top - сколько первых совпадений показывать
 * onchange - функция вызываемая при нажатии на кнопку поиска
 * showTitle - показывать ли заголовок
 * searchUrl - страница результатов для перехода при нажатии на кнопку поиска
 * searchQuery - начальное значение поля поиска
 * itemPage - страница для перехода по ссылке в элементе подсказки
 * itemView - view страницы
 */

@observer
export class SearchWidget extends React.Component<SearchWidgetProps> {
    @observable term = ''; // искомое выражение
    @observable prevTerm = '';
    @observable isOpened = false; // открыта ли панель подсказок
    @observable isPopupOpened = false; // открыт ли мобильный попап поиска
    @observable hints: Hint[] = []; // массив для подсказок
    @observable isFocused = false; // input focus
    @observable isMediaSm = false; // is mobile screen
    @observable timeout: NodeJS.Timeout;
    refInput = React.createRef<HTMLInputElement>();
    refDropdown = React.createRef<HTMLDivElement>();
    refLargeDropdown = React.createRef<HTMLDivElement>();
    isSearchStarted = false; // произошло ли событие запуска поиска

    constructor(props) {
        super(props);
        const { search } = getUrlParams(props.location.search);

        this.term = props.searchQuery || search || '';
        reaction(
            () => this.isPopupOpened,
            this.freezeBodyScroll
        );
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        document.addEventListener('mousedown', this.handleClickOutside);
        this.onWindowResize();
        searchState.setSearchOnPage(1);

        if (this.props.focusOnLoad) {
            this.refInput.current?.focus();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchQuery !== prevProps.searchQuery) {
            this.term = this.props.searchQuery || '';
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        document.removeEventListener('mousedown', this.handleClickOutside);
        searchState.setSearchOnPage(-1);
    }

    onWindowResize = () => {
        this.isMediaSm = isMedia('sm');
    };

    freezeBodyScroll = () => {
        const { body } = document;

        if (!isMedia('sm')) return;

        if (this.isPopupOpened) {
            body.classList.add(styles.Freeze);
            const scrollY = window.scrollY;
            body.style.position = 'fixed';
            body.style.top = `-${ scrollY }px`;
        } else {
            body.classList.remove(styles.Freeze);
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    };

    fetchHints = async () => {
        const { top, location } = this.props;

        if (this.term.trim() === '') return;
        const portal = location.pathname.split('/')[1];
        const perPage = Number(top) || DEFAULT_COUNT_RESULTS;

        const { data } = await fetchSearchResultsData(this.term, 0, perPage, portal);
        const records = data.records;

        const results: Hint[] = this.groupHints(records);

        if (this.term.trim() && !this.isSearchStarted) { // пока делали запрос term уже пустой или пользователь уже запустил поиск
            this.toggleHints(true);
        }
        this.hints = results;
    };

    toggleHints = (state: boolean) => {
        this.isOpened = state;
    };

    togglePopup = (state: boolean) => {
        this.isPopupOpened = state;
    }

    groupHints = (records: Record[]): Hint[] => {
        const compare = (a: Record, b: Record) => {
            if (a.ts_table_title > b.ts_table_title) return 1;
            if (b.ts_table_title > a.ts_table_title) return -1;
            return 0;
        };
        const sortRecords = records.sort(compare);

        return sortRecords.reduce((acc, curr) => {
            const hintRecord = {
                title: curr.title,
                table_name: curr.table_name,
                sys_id: curr.sys_id,
            };

            const findGroup = acc.find(hint => hint.groupName === curr.ts_table_title);
            if (findGroup) {
                findGroup.records.push(hintRecord);
            } else {
                acc.push({
                    groupName: curr.ts_table_title,
                    tableName: curr.table_name,
                    records: [ hintRecord ],
                });
            }
            return acc;
        }, [] as Hint[]);
    }

    clearSearch = () => {
        this.toggleHints(false);
        this.term = '';
        this.refInput.current?.focus();
    };

    getPlaceholder = () => {
        const { placeholder, size } = this.props;
        const { sp_search, user_menu_titles } = langStore.getTranslate();

        if (placeholder) return placeholder;
        if (size === LARGE_MODE) return sp_search?.search_placeholder;
        return user_menu_titles?.search;
    }

    handleClickOutside = (evt) => {
        if (this.refDropdown?.current) {
            if (!this.refDropdown.current.contains(evt.target)) {
                this.toggleHints(false);
            }
        }

        if (this.refLargeDropdown?.current) {
            if (!this.refLargeDropdown.current.contains(evt.target)) {
                this.toggleHints(false);
            }
        }
    };

    handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.isSearchStarted = false;
        this.term = e.target.value;
        const trimmedTerm = this.term.trim();
        if (trimmedTerm && !trimmedTerm.match(/[#\\\/?{}]/)) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.fetchHints, 500);
        } else {
            this.toggleHints(false);
        }
    };

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === KeyboardKeys.Enter) {
            e.preventDefault();
            this.toggleHints(false);
            this.togglePopup(false);
            this.handleSearch();
        }
        if (e.key === KeyboardKeys.Escape) {
            e.preventDefault();
            this.toggleHints(false);
            this.togglePopup(false);
        }
    };

    handleSearch = () => {
        if (!this.term) {
            this.refInput.current?.focus();
            return;
        }

        this.isSearchStarted = true;
        const trimmedTerm = encodeURIComponent(this.term.trim());
        if (this.props.onchange) {
            const obj = {
                target: {
                    value: trimmedTerm,
                },
            };

            const f = new Function(this.props.onchange);
            f(obj);
            this.toggleHints(false);
        }
        if (this.props.searchUrl) {
            smartRedirect(`${ this.props.searchUrl }?${ SEARCH_PARAM }=${ trimmedTerm }`);
        }
    };

    renderInput = () => {
        const { size, className } = this.props;
        const isLargeMode = size === LARGE_MODE;
        const classSearch = isLargeMode ? styles.LargeInputContainer : `${ styles.SmallInputContainer } ${ this.isFocused ? styles.focus : '' } ${ className }`;

        return (
            <div className={ classSearch }>
                <input className={ isLargeMode ? styles.LargeInput : styles.SmallInput }
                       value={ this.term }
                       onKeyDown={ this.handleKeyDown }
                       onChange={ this.handleValueChange }
                       placeholder={ this.getPlaceholder() }
                       ref={ this.refInput }
                       autoFocus={ this.isMediaSm }
                       onFocus={ () => {
                           this.isFocused = true;
                       } }
                       onBlur={ () => {
                           this.isFocused = false;
                       } }
                       data-test={ ATTRIBUTES.searchLargeInput }
                />
                { !!this.term &&
                    <>
                        <div className={ `${ isLargeMode ? styles.LargeInputIcon : styles.SmallInputIcon } ${ styles.focus }` }
                             dangerouslySetInnerHTML={ { __html: IconClose } }
                             data-test={ ATTRIBUTES.searchLargeInputCloseIcon }
                             onClick={ this.clearSearch } />
                        <div className={ styles.Delimiter } />
                    </>
                }
                <div className={ `${ isLargeMode ? styles.LargeInputIcon : styles.SmallInputIcon }  ${ (this.isFocused && !!this.term) || !!this.term ? styles.focus : '' }` }
                     dangerouslySetInnerHTML={ { __html: IconSearch } }
                     data-test={ ATTRIBUTES.searchLargeInputIcon }
                     onClick={ this.handleSearch }
                />
            </div>
        );
    }

    getRenderGroups = () => {
        const { match, itemPage, itemView } = this.props;

        return this.hints.map(group => {
            const records = group.records.map((record) => {
                const link = getRecordLink(match, record.table_name || group.tableName, record.sys_id, itemPage, itemView);
                return <div
                    className={ styles.Item }
                    data-test={ ATTRIBUTES.searchGroupResultItem }
                    key={ record.sys_id }
                >
                    <Link className={ styles.ItemLink } to={ link } onClick={ () => this.toggleHints(false) }>
                        <span className={ styles.ItemIcon }
                              dangerouslySetInnerHTML={ { __html: IconDocument } } />
                        <span className={ styles.ItemTitle }
                              dangerouslySetInnerHTML={ { __html: record.title } }>{ }</span>
                    </Link>
                </div>;
            });

            return (
                <div
                    key={ group.tableName }
                    className={ styles.Group }
                    data-test={ ATTRIBUTES.searchGroupResultTitle }
                >
                    <div className={ styles.GroupTitle }>
                        { group.groupName }
                    </div>
                    { records }
                </div>
            );
        });
    }

    renderLargeMode() {
        const { className, showTitle, title } = this.props;
        const { user } = userState;
        const { sp_main_page } = langStore.getTranslate();

        let heading;
        if (showTitle) {
            const greetingDefault = sp_main_page?.welcome_user_title;
            const greeting = title ? title : greetingDefault;
            heading = greeting && ((user as UserData).first_name ? `${ (user as UserData).first_name }, ${ greeting.toLowerCase() }` : greeting);
        }

        return (
            <div className={ `${ styles.Search } ${ className || '' }` }
                 data-test={ this.props['data-test'] ? this.props['data-test'] : `search-${ ATTRIBUTES.widget }` }>
                { !!heading && <div className={ styles.Heading }>{ heading }</div> }
                <div className={ styles.SearchWrapper }>
                    <div className={ `${ styles.SearchContainer } ${ this.isFocused ? styles.focus : '' }` }>
                        { this.renderInput() }
                        { this.isOpened && (this.hints.length > 0) && (
                            <>
                                <div className={ styles.Line }>{ }</div>
                                <div
                                    className={ styles.GroupsBlock }
                                    data-test={ ATTRIBUTES.searchGroupsResult }
                                    ref={ this.refLargeDropdown }
                                >
                                    { this.getRenderGroups() }
                                </div>

                            </>
                        ) }
                    </div>
                </div>
            </div>
        );
    }

    renderBlockInput = () => {
        return (
            <div className={ styles.SmallInputContainer } onClick={ () => {
                this.togglePopup(true);
            } }
                 data-test={ this.props['data-test'] ? this.props['data-test'] : `search-${ ATTRIBUTES.widget }` }
            >
                <input className={ styles.SmallInput }
                       value={ this.term }
                       onChange={ this.handleValueChange }
                       placeholder={ this.getPlaceholder() }
                />
                <div className={ `${ styles.SmallInputIcon }` }
                     dangerouslySetInnerHTML={ { __html: IconSearch } }
                />
            </div>
        );
    }

    renderIconInput = () => {
        return (
            <div
                className={ styles.PopupShow }
                dangerouslySetInnerHTML={ { __html: IconSearch } }
                onClick={ () => {
                    this.togglePopup(true);
                } }
                data-test={ this.props['data-test'] ? this.props['data-test'] : `search-${ ATTRIBUTES.widget }` }
            />
        );
    }

    renderMobileMode() {
        const { isBlockInput } = this.props;
        const { user_menu_titles } = langStore.getTranslate();

        return (
            <>
                { isBlockInput ? this.renderBlockInput() : this.renderIconInput() }
                { this.isPopupOpened && (
                    <GlobalPortal>
                        <div className={ styles.Popup }
                             data-test={ this.props['data-test'] ? this.props['data-test'] : `searchpopup-${ ATTRIBUTES.widget }` }>
                            <div className={ styles.PopupHeader }>
                                <div className={ styles.PopupHeading }>{ user_menu_titles?.search }</div>
                                <div
                                    className={ styles.PopupClose }
                                    dangerouslySetInnerHTML={ { __html: IconClose } }
                                    onClick={ () => {
                                        this.togglePopup(false);
                                    } }
                                />
                            </div>
                            <div className={ styles.InputSmWrap }>
                                { this.renderInput() }
                                { this.getRenderGroups() }
                            </div>
                        </div>
                    </GlobalPortal>
                ) }
            </>
        );
    }

    renderOpenedResults() {
        if (this.isOpened && (Array.isArray(this.getRenderGroups()) && this.getRenderGroups().length > 0)) {
            return (
                <div className={ styles.InputSmDropDown } ref={ this.refDropdown }>
                    <div className={ styles.InputSmDropCont }>{ this.getRenderGroups() }</div>
                </div>
            );
        }
        return null;
    }

    render() {
        const { user } = userState;
        const { size, preventMobilePopup, preventHidden } = this.props;

        if (_.isEmpty(user)) return null;

        const isLargeMode = size === LARGE_MODE;
        if (isLargeMode) {
            return this.renderLargeMode();
        }

        // Прячем поиск на портале, если несколько на странице
        if (searchState.getSearchOnPage() > 1 && !preventHidden) return null;

        if (this.isMediaSm && !preventMobilePopup) {
            return this.renderMobileMode();
        }

        return (
            <div className={ styles.InputSmWrap }
                 data-test={ this.props['data-test'] ? this.props['data-test'] : `search-${ ATTRIBUTES.widget }` }>
                { this.renderInput() }
                { this.renderOpenedResults() }
            </div>
        );
    }
}

export default withRouter(SearchWidget);
