import * as React from 'react';
import { observable } from "mobx";
import { observer } from 'mobx-react';
import _ from 'lodash';

import styles from './styles.module.scss';

import {
    Delimiters,
    KeyboardKeys,
    MaskedInputModelTypes,
    MaskedInputPropsTypes,
} from "types/components/dynamicForms/maskInput";
import StringInput from 'components/dynamicForms/view/field/stringInput';
import MaskedInputModel from 'components/dynamicForms/model/field/MaskedInputModel';
import { normalizeValue } from "helpers/date";
import { ATTRIBUTES } from "constants/attributesForTests";


/**
 * Описание: компонент MaskedInput
 * Параметры:
 * model={ MaskedInputModel }
 * onPaste={ function }
 * getNewMaskValue={ function }
 * checkValidFormatValue={ function }
 * checkValidEnteredValue={ function }
 * showWrongDataMsg={ function }
 * hideAllValidateMsg={ function }
 * onChange: { function } - метод для изменения значения
 * save: { function } - метод сохранения формы
 **/


@observer
export default class MaskedInput extends React.Component<MaskedInputPropsTypes> {
    @observable showMask = false;
    @observable showMaskInput = false;
    @observable isFocusOn = false;
    @observable isSelectionOn = false;
    isLabelMouseDown = false;
    refHiddenInput: React.RefObject<HTMLInputElement> = React.createRef();
    model: MaskedInputModelTypes;

    constructor(props) {
        super(props);

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new MaskedInputModel(props);
        }
        this.model.setMask();
        this.model.setMaskValues();
    }

    componentDidMount() {
        document.addEventListener('paste', this.handlePasteValue);
        document.addEventListener('mouseup', this.onDocumentMouseUp);

        if (this.model.value) {
            this.showMaskInput = true;
        }
        if (this.model.cellEditMode) {
            this.refHiddenInput.current?.focus();
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            this.model.mergeData(this.props);
        }

        const displayValue = this.model.serializeValue();
        if (!_.isNil(this.model.value) && this.model.value.trim() !== displayValue) {

            this.model.isValidInput = this.props.checkValidFormatValue(this.model.value);

            this.model.setMask();
            this.model.setMaskValues();
            if (this.model.value) {
                this.showMaskInput = true;
            }
            else {
                this.showMaskInput = false;
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('paste', this.handlePasteValue);
        document.removeEventListener('mouseup', this.onDocumentMouseUp);
    }

    removeSelection = (nativeSelection  = false) => {
        this.isSelectionOn = false;

        // по настоящему чистим Selection только при потере фокуса поля.
        // иначе, при вводе значений, removeAllRanges() будет постоянно закрывать мобильную клавиатуру.
        if (!nativeSelection) return;
        const selection: Selection | null = window.getSelection();
        if (selection) {
            selection.removeAllRanges();
        }
    };

    addSelection = () => {
        this.isSelectionOn = true;
    };

    onChange = () => {
        if (this.model.activeTab === -1) return;

        if (this.props.onChange) {
            this.props.onChange(this.model.value);
        }
    };

    handleClickOnTabMask = (index) => () => {
        if (!this.model.readonly) {
            const activeTab = this.model.activeTab;
            const maskValue = this.model.maskValues[activeTab];

            if (activeTab !== -1 && activeTab !== index && !maskValue.isFilled) {
                const activeTabValue = maskValue.tabValue;

                if (activeTabValue) {
                    const {activeTabMask} = this.model.getMask();
                    const newValue = normalizeValue(activeTabValue, activeTabMask);

                    this.model.changeValueTabMask(newValue, '');
                    this.onChange();
                }
            }

            this.model.isNeedClearActiveTabMask = true;
            this.model.activeTab = index;
        }
    };

    onFocus = (evt) => {
        evt.preventDefault();
        if (!this.model.readonly) {
            this.isFocusOn = true;

            if (this.model.readonly) return;

            this.showMaskInput = true;
            this.showMask = true;
            if (!this.model.isValidInput) {
                this.props.showWrongDataMsg();
            }
            this.model.isValidInput = true;

            // Если фокус сброшен выставялем на 1 таб маски
            if (this.model.activeTab === -1 && this.showMask) {
                const findLastTab = this.model.maskValues.findIndex(tab => !tab.isFilled);
                this.model.activeTab = ~findLastTab ? findLastTab : this.model.maskValues.length - 1;
                this.model.isNeedClearActiveTabMask = true;
            }
        }
    }

    onDoubleClick = (evt) => {
        evt.preventDefault();
        if (!this.model.readonly) {
            const selection: Selection | null = window.getSelection();    // Save the selection.
            let range = document.createRange();

            if (this.model.ref.current?.firstChild && selection) {
                range.selectNodeContents(this.model.ref.current.firstChild);
                selection.removeAllRanges();          // Remove all ranges from the selection.
                selection.addRange(range);            // Add the new range.
                this.refHiddenInput.current?.focus();
                this.addSelection();
            }
        }
    };

    onClick = () => {
        this.removeSelection();
    };

    onMouseOver = () => {
        if (!this.model.isValidInput) {
            this.props.showWrongDataMsg();
        }
    };

    onLabelMouseDown = () => {
        this.isLabelMouseDown = true;
    };

    onDocumentMouseUp = ({ target }) => {
        if (!this.isLabelMouseDown) return;

        this.isLabelMouseDown = false;

        if (this.model.ref.current && !this.model.ref.current.contains(target)) {
            this.onBlur(this.model.value)();
        }
    };

    onBlur = (value) => () => {
        if (!this.model.readonly) {
            if (this.model.activeTab === -1 || this.isLabelMouseDown) return;

            if (!value) {
                this.showMaskInput = false;
                this.model.isValidInput = this.props.checkValidFormatValue(this.model.value);
            } else {
                const {activeTabMask} = this.model.getMask();
                const activeTabValue = this.model.maskValues[this.model.activeTab].tabValue;

                if (activeTabValue) {
                    const newValue = normalizeValue(activeTabValue, activeTabMask);
                    this.model.changeValueTabMask(newValue, '');
                }
                this.model.isValidInput = this.props.checkValidFormatValue(this.model.value);
            }

            this.onChange();

            if (this.model.isValidInput) {
                this.props.hideAllValidateMsg();
            }
        }

        this.showMask = false;
        this.model.activeTab = -1;
        this.isFocusOn = false;
        this.removeSelection(true);
        this.props.onBlur();
    };

    handlePasteValue = (evt) => {
        if (this.model.ref.current && !this.model.readonly) {
            if (this.model.ref.current.contains(document.activeElement)) {
                this.props.onPaste(evt);
            }
        }
    };

    onKeyDown = (evt) => {
        if (this.model.readonly || [
            KeyboardKeys.Tab,
            KeyboardKeys.Shift,
            KeyboardKeys.Unidentified,
        ].includes(evt.key) || evt.ctrlKey) return;

        this.showMask = true;
        this.props.hideAllValidateMsg();

        // Выход за пределы маски игнорируем ввод
        if (!this.model.maskValues[this.model.activeTab]) return;

        const { activeTabMask, tabMasks } = this.model.getMask();
        let activeTabValue = this.model.maskValues[this.model.activeTab].tabValue;

        if (this.props.save && evt.key === KeyboardKeys.Enter) {
            evt.preventDefault();
            this.props.save();
            return;
        }

        if ([
            KeyboardKeys.Backspace,
            KeyboardKeys.Delete,
        ].includes(evt.key)) {
            const selection: Selection | null = window.getSelection();
            const selectionType = selection && selection.type;

            if (selectionType === 'Range' && this.isSelectionOn) {
                this.model.value = '';
                this.model.setMaskValues();
                this.model.activeTab = 0;
            } else {
                if (!activeTabValue) {
                    this.model.activeTab--;
                    if (this.model.activeTab === -1) {
                        this.model.activeTab = this.model.maskValues.length - 1;
                    }
                    this.model.isNeedClearActiveTabMask = true;
                    return;
                }
                const value = activeTabValue.substring(0, activeTabValue.length - 1);
                const { newValue, newMask } = this.props.getNewMaskValue(value, activeTabMask, this.model.maskValues[this.model.activeTab]);
                this.model.changeValueTabMask(newValue, newMask);
            }

            this.removeSelection();
            this.model.isNeedClearActiveTabMask = false;
            this.onChange();
            return;
        }

        this.removeSelection();

        // Сдвигаемся по маске в право
        if ([
            Delimiters.Space,
            Delimiters.Dot,
            Delimiters.Comma,
            Delimiters.Dash,
            Delimiters.Slash,
            KeyboardKeys.ArrowRight,
        ].includes(evt.key)) {
            evt.preventDefault();
            if (evt.key !== KeyboardKeys.ArrowRight && !activeTabValue && this.model.isNeedClearActiveTabMask) {
                return;
            }
            if (activeTabValue) {
                const newValue = normalizeValue(activeTabValue, activeTabMask);
                this.model.changeValueTabMask(newValue, '');
            }

            if (this.model.activeTab < tabMasks.length - 1) {
                this.model.activeTab++;
            } else {
                this.model.activeTab = 0;
            }

            this.model.isNeedClearActiveTabMask = true;
            this.onChange();
            return;
        }

        // Сдвигаемся по маске в лево
        if (evt.key === KeyboardKeys.ArrowLeft) {
            if (activeTabValue) {
                const newValue = normalizeValue(activeTabValue, activeTabMask);
                this.model.changeValueTabMask(newValue, '');
            }

            this.model.activeTab--;
            if (this.model.activeTab === -1) {
                this.model.activeTab = this.model.maskValues.length - 1;
            }
            this.model.isNeedClearActiveTabMask = true;
            this.onChange();
            return;
        }

        if (evt.key === KeyboardKeys.ArrowUp) {
            evt.preventDefault();
            const increment = Number(activeTabValue) + 1;
            const newValue = normalizeValue(increment.toString(), activeTabMask, true);
            this.model.changeValueTabMask(newValue, '');

            this.model.isNeedClearActiveTabMask = false;
            this.onChange();
            return;
        }

        if (evt.key === KeyboardKeys.ArrowDown) {
            evt.preventDefault();
            const decrement = Number(activeTabValue) - 1;
            const newValue = normalizeValue(decrement.toString(), activeTabMask, true);
            this.model.changeValueTabMask(newValue, '');

            this.model.isNeedClearActiveTabMask = false;
            this.onChange();
            return;
        }

        // проверка ввода по регулярке
        if (!this.props.checkValidEnteredValue(evt.key)) {
            return;
        }

        // Если надо очищаем значение в активной части маски
        if (this.model.isNeedClearActiveTabMask) {
            this.model.changeValueTabMask('', activeTabMask);
            activeTabValue = this.model.maskValues[this.model.activeTab].tabValue;
        }

        // Если введено символов больше чем в маске, переход в следующую часть
        if (this.model.maskValues[this.model.activeTab].isFilled) {
            const newValue = normalizeValue(activeTabValue, activeTabMask);
            this.model.changeValueTabMask(newValue, '');
            this.onChange();

            if (this.model.activeTab < tabMasks.length - 1) {
                this.model.activeTab++;
            } else {
                this.model.activeTab = 0;
            }
            this.model.isNeedClearActiveTabMask = true;
            return;
        }

        const value = activeTabValue + evt.key;

        const { newValue, newMask } = this.props.getNewMaskValue(value, activeTabMask, this.model.maskValues[this.model.activeTab]);
        this.model.changeValueTabMask(newValue, newMask);

        // Если введено символов больше чем в маске, переход в следующую часть
        if (this.model.maskValues[this.model.activeTab].isFilled) {
            const newValue = normalizeValue(this.model.maskValues[this.model.activeTab].tabValue, activeTabMask);
            this.model.changeValueTabMask(newValue, '');
            this.onChange();

            if (this.model.activeTab < tabMasks.length - 1) {
                this.model.activeTab++;
            } else {
                this.model.activeTab = 0;
            }

            this.model.isNeedClearActiveTabMask = true;
            return;
        }

        this.model.isNeedClearActiveTabMask = false;
        this.onChange();
    };

    getClasses = () => {
        const { className } = this.model;

        const stylesStr = [ styles.Input ];
        if (className) {
            stylesStr.push(className);
        }
        if (this.isFocusOn) {
            stylesStr.push(styles.HasFocused);
        }
        if (this.model.isWarning && this.model.cellEditMode || !this.model.isValidInput) {
            stylesStr.push(styles.warningInput);
        }
        if (this.model.readonly) {
            stylesStr.push(styles.readonlyInput);
        }
        return stylesStr.join(' ');
    };

    renderPlaceholder() {
        if (this.model.placeholder) {
            return (
                <span className={ styles.TabMask }>
                    { this.model.placeholder }
                </span>
            );
        }
    }

    renderMaskedValue() {
        return (
            this.model.maskValues.map((elem, index) => {
                return (
                    <span key={ index }
                          onClick={ this.handleClickOnTabMask(index) }
                          data-test={ `${ ATTRIBUTES.maskedInputTab }-${ index }` }
                    >
                     <span
                         className={ this.model.activeTab === index ? styles.Active : '' }
                     >
                        { elem.tabValue }

                         { !elem.isFilled && this.showMask &&
                         <span className={ styles.TabMask }>
                                { elem.tabMask }
                            </span>
                         }
                     </span>

                    <span className={ elem.tabMask.length > 0 && this.showMask ? styles.TabMask : '' }>
                        { elem.isFilled || this.showMask ? elem.separator : '' }
                    </span>
                </span>
                );
            })
        );
    }

    render() {
        if (this.model.readonly) {
            return <StringInput value={ this.model.value } readOnly/>;
        }

        return (
            <label
                className={ this.getClasses() }
                ref={ this.model.ref }
                onMouseOver={ this.onMouseOver }
                onDoubleClick={ this.onDoubleClick }
                onClick={ this.onClick }
                onMouseDown={ this.onLabelMouseDown }
                data-test={ ATTRIBUTES.maskedInput }
            >
                <div className={ `${styles.Mask} ${this.isSelectionOn ? styles.SelectionOn : ''} ${this.model.maskClassName ? this.model.maskClassName : ''}` }>
                    {
                        this.showMaskInput ? this.renderMaskedValue()
                            : this.renderPlaceholder()
                    }
                </div>
                <input
                    type="number"
                    inputMode="numeric"
                    autoComplete="off"
                    className={ styles.HiddenInput }
                    onBlur={ this.onBlur(this.model.value) }
                    onFocus={ this.onFocus }
                    onKeyDown={ this.onKeyDown }
                    ref={ this.refHiddenInput }
                />
            </label>
        );
    }
}
