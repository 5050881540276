import * as React from 'react';
import Container from 'components/container';
import DynamicForm from 'components/dynamicForms';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import apiRequest from 'lib/apiRequest';
import ActionsPanel from 'components/actionsPanel';
import UiButtons from 'components/uiButtons';
import UiButtonsContainer from 'components/uiButtonsContainer';
import Button from 'components/button';
import { getUrlParams } from 'helpers/data';
import DragAndDrop from 'components/dragAndDrop';
import RelatedLists from 'components/relatedLists';
import PageLoader from 'components/pageLoader';
import attachmentsWidgetState from 'globalState/attachmentsWidgetState';
import { EnhancedComponent } from 'components/EnhancedComponent';
import Paperclip from 'assets/img/icons/paperclip-attach.svg';
import SmartTitle from 'components/smartTitle';
import FormMessages from 'components/formMessages';
import styles from './styles.module.scss';
import stylesTable from 'components/table/styles.module.scss';
import { globalEventEmitter } from 'lib/EventEmitter';
import Heading from 'components/heading';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';
import { isMedia } from 'helpers/html';
import UiBurger from 'components/uiBurger';
import FormsState from 'globalState/forms';
import { ATTRIBUTES } from 'constants/attributesForTests';
import langStore from "globalState/lang";
import { history, setLocationHistoryLength } from 'helpers/history';
import { fetchRecord } from 'actions/record';
import SimpleForm from 'ui-actions-scripts/simple-form';
import activityFeeds from 'globalState/activityFeeds';

import _ from 'lodash';
import SubFormModal from 'components/subForm/subFormModal';
import { generateRandomString } from 'helpers/data';

@observer
class RecordPage extends EnhancedComponent {
    @observable resultData;
    @observable errorMessage = '';
    @observable showAttachPopup = false;
    @observable attachmentsLength;
    @observable scriptInfo = {};
    @observable isActionUpdate = false;
    @observable pageName = '';
    @observable uiAction = {};
    @observable disableUiActions = false;
    @observable isMediaSm = false;
    @observable isHideAttach = false;
    lastRouteParams = '';
    recordId = '';
    tableName = '';
    dndDropFuncLink = null;
    isRelatedListsDelayed = true;
    uiActionGetTimer = null;
    @observable dynamicFormId = '';
    @observable view = '';

    constructor(props) {
        super(props);

        FormsState.clear();
        const tableName = this.getTableName();
        this.tableName = tableName;
        this.recordId = this.getSysId();

        this.fetchData().catch(this.errorFetchData);

        const sysId = this.getSysId();

        window.isNewRecord = !!sysId;
        window.recordGlobal[tableName] = this;

        setLocationHistoryLength(history);

        this.useEffect(this.onUpdate, true);
        this.useEffect(this.useImpersonateHandler);
        this.useEffect(this.onMount);
        this.useEffect(this.onUnmount);
        this.dynamicFormId = generateRandomString();
    }

    onWindowResize = () => {
        this.isMediaSm = isMedia('sm');
    };

    onMount = (mount) => {
        if (!mount) return;
        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();
    };

    onUnmount = () => {
        return () => {
            window.removeEventListener('resize', this.onWindowResize);
            window.s_form = undefined;
        };
    };

    onUpdate = (mount, update, prevProps) => {
        if (!update) return; // ignore onMount

        const tableName = this.getTableName();
        const sysId = this.getSysId();
        const parsedSearchParams = getUrlParams(location.search);
        const prevParsedSearchParams = getUrlParams(prevProps.location.search);
        const isEqualRelatedView = _.isEqual(prevParsedSearchParams.related_view, parsedSearchParams.related_view);
        this.view = parsedSearchParams.form_view;
        const isEqualView = _.isEqual(prevParsedSearchParams.form_view, parsedSearchParams.form_view);
        const needActionUpdate = !isEqualRelatedView || !isEqualView;
        if (tableName !== this.tableName && sysId !== this.recordId) {
            setLocationHistoryLength(history);
        }
        if (tableName !== this.tableName || sysId !== this.recordId || !isEqualView) {
            FormsState.clear(false);
            window.isNewRecord = !!sysId;
            window.recordGlobal[tableName] = this;
            // todo надо как-то оптимизировать запросы при быстром переключении страниц
            setTimeout(() => this.fetchData(needActionUpdate).catch(this.errorFetchData), 10);
        }
        const isAclActiveAndEmptyAttach = this.resultData.is_clip_visible === false && this.attachmentsLength === 0;
        const attachment = attachmentsWidgetState.getAttachment(this.dynamicFormId);
        this.isHideAttach = attachment?.hasWidget && attachment?.essence === this.getTableName()
            && attachment?.recordId === this.getSysId() || isAclActiveAndEmptyAttach;
    };

    // Update page data when we do impersonate or deImpersonate
    useImpersonateHandler = () => {
        const handleImpersonate = async () => {
            await this.fetchData(true).catch(this.errorFetchData);
        };

        return globalEventEmitter.subscribe([
            'impersonate',
            'deImpersonate',
        ], handleImpersonate);
    };

    handleCloseAttachPopup = () => {
        this.showAttachPopup = false;
    };

    handleShowAttachPopup = () => {
        this.showAttachPopup = true;
    };

    handleAttachmentsLengthChange = (length) => {
        this.attachmentsLength = length;
    };

    getTableName = () => {
        const isEssence = this.props.match.params.table_name === 'index' || this.props.match.params.table_name === 'new' || this.props.match.params.table_name === 'one';

        return isEssence ?
            new URLSearchParams(this.props.location.search).get('essence') :
            this.props.match.params.table_name;
    };

    getSysId = () => {
        return this.props.match.params.number || new URLSearchParams(this.props.location.search).get('sys_id');
    };

    setPageName = (pageName, isHidden = false) => {
        this.pageName = pageName;
        this.isPageNameHidden = isHidden;
    };

    fetchData = async (isActionUpdate = false) => {
        const { location } = this.props;
        const parsedSearchParams = getUrlParams(location.search);
        const routeParamsString = JSON.stringify({
            ...this.props.match.params,
            ...parsedSearchParams,
        });
        parsedSearchParams.open_first_rel_list = this.isRelatedListsDelayed ? 0 : 1;
        if (this.lastRouteParams === routeParamsString && !isActionUpdate) return false;
        this.lastRouteParams = routeParamsString;

        if (!this.props.match.params.table_name) return;
        this.tableName = this.getTableName();
        this.recordId = this.getSysId();
        this.uiAction = {};
        this.setUiActionGetTimer();
        const activityFeedState = activityFeeds.getItem(this.tableName, this.recordId);
        if (activityFeedState) {
            activityFeedState.setIsLoadingFromForm(true);
        }
        const { data, isOkStatus } = await fetchRecord(this.tableName, this.recordId, parsedSearchParams, true);
        if (isOkStatus) {
            const activityFeedState = activityFeeds.getItem(this.tableName, this.recordId);
            if (activityFeedState) {
                activityFeedState.setActivityTypesFromFields(data.sections);
                activityFeedState.setIsLoadingFromForm(false);
            }
            window.s_form = new SimpleForm(this.tableName, this.recordId, this.view, data.form_title, this.dynamicFormId);
            this.resultData = data;
            window.s_form.formId = data.form_id;
            this.attachmentsLength = this.resultData.attachments.length;
            this.isActionUpdate = isActionUpdate;
            this.setPageName(this.resultData.page_name || '', this.resultData.is_page_name_hidden);
        }
    };

    fetchUiActions = async () => {
        const tableName = this.getTableName();
        const recordId = this.getSysId();
        const parsedSearchParams = getUrlParams(location.search);
        const request = ((this.recordId) ?
            ('GET /ui-action/record-actions/' + tableName + '/' + recordId) :
            ('GET /ui-action/record-actions/' + tableName));
        const response = await new apiRequest(request).qs(parsedSearchParams).send();
        this.uiAction = response.getData();
    };

    updateUiActionsAfterSaveForm = () => {
        this.setUiActionGetTimer();
    };

    setUiActionGetTimer = () => {
        if (this.uiActionGetTimer !== null) {
            clearTimeout(this.uiActionGetTimer);
            this.uiActionGetTimer = null;
        }
        this.uiActionGetTimer = setTimeout(() => this.fetchUiActions().catch(this.errorFetchData), 500);
    };

    updateRelatedListAfterSaveForm = (activeElementId) => {
        if (this.resultData.related_lists && this.resultData.related_lists.active_element_id) {
            this.resultData.related_lists.active_element_id = activeElementId;
            this.isActionUpdate = true;
        }
    };

    resetIsActionUpdate = () => {
        this.isActionUpdate = false;
    };

    disableUiActionOnSaveForm = (condition) => {
        this.disableUiActions = condition;
    };

    errorFetchData = (e) => {
        this.errorMessage = e.message;
    };

    setScriptInfo(result = null, info = null) {
        this.scriptInfo.result = result || '';
        this.scriptInfo.info = info || '';
    }

    renderScriptInfo = () => {
        if (!this.scriptInfo.result && !this.scriptInfo.info) {
            return null;
        }
        return (
            <>
                <div className={ styles.RecordField } data-test={ ATTRIBUTES.scriptResult }>
                    <Heading size={ 'h3' }> { langStore.getTranslateKey('result') } </Heading>
                    <pre>{ this.scriptInfo.result }</pre>
                </div>
                <div className={ styles.RecordField } data-test={ ATTRIBUTES.scriptInfo }>
                    <Heading size={ 'h3' }> { langStore.getTranslateKey('info') } </Heading>
                    <pre>{ this.scriptInfo.info }</pre>
                </div>
            </>
        );
    };

    renderRelatedLists = () => {
        const data = this.resultData.related_lists;
        if (!data.related_elements
            || !data.related_elements.length
            || (!this.isRelatedListsDelayed
                && (!data.active_related_grid_data
                    || (data.active_related_grid_data.length === 0)))) {
            return null;
        }
        const tableName = this.getTableName();
        const recordId = this.getSysId();
        if (!recordId) {
            return null;
        }
        const activeElementId = data.active_element_id;
        const elements = data.related_elements.map((element) => {
            return {
                name: element.title,
                sysId: element.sys_id,
                data: element.sys_id === activeElementId ? data.active_related_grid_data : null,
                relatedColumnId: element.related_column_id,
                relatedTableId: element.related_table_id,
                related_column_name: element.related_column_name,
                related_list_id: element.related_list_id,
                related_table_name: element.related_table_name,
            };
        });
        return (
            <RelatedLists
                activeElementsId={ activeElementId }
                title={ this.resultData.related_lists_title }
                elements={ elements }
                essence={ tableName }
                recordId={ recordId }
                location={ this.props.location }
                tableName={ this.props.match.params.table_name }
                activeData={ data.active_related_grid_data }
                record={ this }
                isActionUpdate={ this.isActionUpdate }
                isRelatedListsDelayed={ this.isRelatedListsDelayed }
                resetIsActionUpdate={ this.resetIsActionUpdate }
                fetchUiActions={ this.fetchUiActions }
                disableUiActions={ this.disableUiActions }
            />
        );
    };

    handleDrop = (e) => {
        if (e.dataTransfer.files.length) {
            this.showAttachPopup = true;
            this.dndDropFuncLink(e);
        }
    };

    getFormLabels = (form_labels) => {
        return form_labels.map((item) => {
            const classList = [
                stylesTable.stateBadge,
                styles.FormLabels,
            ];

            if (item.color) {
                classList.push(stylesTable[item.color]);
            }
            else {
                classList.push(stylesTable.default);
            }
            return <div className={ classList.join(' ') }>{ item.name }</div>;
        });
    };

    render() {
        if (this.errorMessage) return <div>{ this.errorMessage }</div>;
        if (!this.resultData || this.isDataOutdated()) return <PageLoader />;

        const { related_links_title, system_buttons_hints } = langStore.getTranslate();
        const tableName = this.getTableName();
        const recordId = this.getSysId();

        const data = this.resultData;
        const fields = data?.sections[0]?.elements;
        const { table_id } = data;

        // Hide attach button if we have attach widget on page
        const isHideAttachBtn = this.isHideAttach;
        const attachBtn = isHideAttachBtn ? null : <Button buttonType='attach' hint={ system_buttons_hints?.attachments } attachmentsLength={ this.attachmentsLength } svg={ Paperclip } onClick={ this.handleShowAttachPopup } />;
        const attachment = attachmentsWidgetState.getAttachment(this.dynamicFormId);

        return (
            <Container className={ styles.NoPaddingTop } onDrop={ this.handleDrop }>
                <FormMessages />
                <ActionsPanel
                    center={
                        !this.isMediaSm && <UiButtonsContainer data={ this.uiAction ? this.uiAction.form_button : null }
                                                               recordId={ recordId }
                                                               tableName={ tableName }
                                                               actionsContextMenu={ this.uiAction ? this.uiAction.button_context_menu : null }
                                                               disableUiActions={ this.disableUiActions }
                        /> }
                    contextMenu={ this.uiAction ? this.uiAction.form_context_menu : null }
                    disableUiActions={ this.disableUiActions }
                    headerButton={
                        !this.isMediaSm && <UiButtons data={ this.uiAction ? this.uiAction.form_header_button : null }
                                                      recordId={ recordId }
                                                      tableName={ tableName }
                                                      actionsContextMenu={ this.uiAction ? this.uiAction.button_context_menu : null }
                                                      disableUiActions={ this.disableUiActions }
                        /> }
                    right={
                        <>
                            { attachBtn }
                            { this.isMediaSm && <UiBurger
                                data={ [
                                    ...(this.uiAction && this.uiAction.form_header_button ? this.uiAction.form_header_button : []),
                                    ...(this.uiAction && this.uiAction.form_button ? this.uiAction.form_button : []),
                                ] }
                                tableName={ tableName }
                                recordId={ recordId }
                                isMobile
                                menuTitle={ data.entity_name }
                            /> }
                        </>

                    }
                    recordId={ recordId }
                    tableName={ tableName }
                    pageName={ data.entity_name }
                />
                <Heading className={ `${ styles.Heading } ${ this.isPageNameHidden ? styles.Hidden : '' }` } size={ 'h2' } data-test={ ATTRIBUTES.headingTitle }><SmartTitle>{ this.pageName }</SmartTitle></Heading>
                { Array.isArray(data.form_labels) &&
                data.form_labels.length !== 0 &&
                this.getFormLabels(data.form_labels) }
                <DynamicForm
                    sections={ data.sections }
                    key="firstSection"
                    fields={ fields }
                    name={ data.sections[0].name }
                    sysId={ this.recordId }
                    tableName={ this.tableName }
                    tableId={ table_id }
                    clientScripts={ data.client_scripts }
                    contextMenu={ this.uiAction ? this.uiAction.form_field_label_context_menu : null }
                    form_id={ data.form_id }
                    setPageName={ this.setPageName }
                    updateUiActionsAfterSaveForm={ this.updateUiActionsAfterSaveForm }
                    updateRelatedListAfterSaveForm={ this.updateRelatedListAfterSaveForm }
                    disableUiActionOnSaveForm={ this.disableUiActionOnSaveForm }
                    id={ this.dynamicFormId }
                    formView={ this.view }
                />
                { !!this.uiAction && this.uiAction.form_bottom_button && this.uiAction.form_bottom_button.length > 0 &&
                <div className={ `${ styles.RecordField } ${ styles.WideButtonsSm }` }>
                    <UiButtons
                        recordId={ recordId }
                        tableName={ tableName }
                        data={ this.uiAction.form_bottom_button }
                        actionsContextMenu={ this.uiAction.button_context_menu }
                        disableUiActions={ this.disableUiActions }
                    />
                </div>
                }
                { !!this.uiAction && this.uiAction.form_link && this.uiAction.form_link.length > 0 &&
                <div className={ styles.RecordField }>
                    { related_links_title && <Heading size={ 'h3' }> { related_links_title } </Heading> }
                    <UiButtons data={ this.uiAction.form_link }
                               recordId={ recordId }
                               tableName={ tableName }
                               actionsContextMenu={ this.uiAction.button_context_menu }
                               isRelatedLinks={ true }
                               disableUiActions={ this.disableUiActions }
                    />
                </div>
                }
                {
                    data.related_lists && this.renderRelatedLists()
                }
                { !attachment?.hasWidget && // Hide attach popup if we have attach widget on page
                <DragAndDrop
                    onClose={ this.handleCloseAttachPopup }
                    onAttachmentsLengthChange={ this.handleAttachmentsLengthChange }
                    show={ this.showAttachPopup }
                    essence={ tableName }
                    recordId={ recordId }
                    attachments={ data.attachments }
                    onDropFunc={ (func) => {
                        this.dndDropFuncLink = func;
                    } }
                    isAclActive={ data.is_clip_visible === false }
                />
                }
                { this.renderScriptInfo() }
                <SubFormModal />
            </Container>
        );
    }

    isDataOutdated() {
        return this.recordId !== this.getSysId() ||
            this.tableName !== this.getTableName();
    }

}


export default ErrorWrapperHoc(RecordPage);
