import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {$isCodeHighlightNode} from '@lexical/code';
import {$isLinkNode} from '@lexical/link';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils';
import {$isHeadingNode} from '@lexical/rich-text';
import {
    $isListNode,
    ListNode,
} from '@lexical/list';
import {
    $getSelection,
    $isRangeSelection,
    $isTextNode,
    COMMAND_PRIORITY_LOW,
    LexicalEditor,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';
import styles from './styles.module.scss';
import {ToolbarItems} from './ToolbarItems';
import {getSelectedNode, setPopupPosition} from "./helpers";

function useTextFormatFloatingToolbar({
                                          editor,
                                          isShowToolbar,
                                      }: { editor: LexicalEditor; isShowToolbar: boolean }): JSX.Element | null {
    const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);
    const isReadOnly = !editor.isEditable();

    const [blockType, setBlockType] = useState('paragraph');
    const [isText, setIsText] = useState(false);

    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isLink, setIsLink] = useState(false);

    const updatePopup = useCallback(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection();
            const nativeSelection = window.getSelection();
            const rootElement = editor.getRootElement();

            if (
                nativeSelection !== null &&
                (!$isRangeSelection(selection) ||
                    rootElement === null ||
                    !rootElement.contains(nativeSelection.anchorNode))
            ) {
                setIsText(false);
                return;
            }

            if (!$isRangeSelection(selection)) {
                return;
            }

            const node = getSelectedNode(selection);

            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));

            const anchorNode = selection.anchor.getNode();
            const element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    setBlockType(type);
                }
            }

            // Update links
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            if (
                !$isCodeHighlightNode(selection.anchor.getNode()) &&
                selection.getTextContent() !== ''
            ) {
                setIsText($isTextNode(node));
            } else {
                setIsText(false);
            }
        });
    }, [editor]);

    const updateTextFormatFloatingToolbar = useCallback(() => {
        const selection = $getSelection();

        const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
        const nativeSelection = window.getSelection();

        if (popupCharStylesEditorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            nativeSelection !== null &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;

            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild as HTMLElement;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }

            setPopupPosition(popupCharStylesEditorElem, rect, rootElement);
        }
    }, [editor]);

    useEffect(() => {
        const onScroll = () => {
            const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
            const rootElement = editor.getRootElement();

            if (popupCharStylesEditorElem === null || rootElement === null) {
                return;
            }

            setPopupPosition(popupCharStylesEditorElem, null, rootElement);
        };
        document.addEventListener('scroll', onScroll, true);
        return () => {
            document.removeEventListener('scroll', onScroll, true);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('selectionchange', updatePopup);
        return () => {
            document.removeEventListener('selectionchange', updatePopup);
        };
    }, [updatePopup]);

    useEffect(() => {
        return editor.registerUpdateListener(() => {
            updatePopup();
        });
    }, [editor, updatePopup]);

    useEffect(() => {
        const onResize = () => {
            editor.getEditorState().read(() => {
                updateTextFormatFloatingToolbar();
            });
        };
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [editor, updateTextFormatFloatingToolbar]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateTextFormatFloatingToolbar();
        });
        return mergeRegister(
            editor.registerUpdateListener(({editorState}) => {
                editorState.read(() => {
                    updateTextFormatFloatingToolbar();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateTextFormatFloatingToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateTextFormatFloatingToolbar]);

    if (!isText || isLink || isShowToolbar || isReadOnly) {
        return null;
    }

    return (
        <>
            {createPortal(
                <div
                    ref={popupCharStylesEditorRef}
                    className={styles.PopupToolbar}
                >
                    <ToolbarItems
                        isShowMainItems={true}
                        containItems={99}
                        blockType={blockType}
                        isBold={isBold}
                        isItalic={isItalic}
                        isUnderline={isUnderline}
                        isStrikethrough={isStrikethrough}
                        isLink={isLink}
                        isShowLinkItem
                    />
                </div>,
                document.body,
            )}
        </>
    );
}

export default function PopupToolbarPlugin({isShowToolbar}): JSX.Element | null {
    const [editor] = useLexicalComposerContext();
    return useTextFormatFloatingToolbar({editor, isShowToolbar});
}
