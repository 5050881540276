import styles from 'layouts/main/sidebar/menu/styles.module.scss';
import IconCircleRight from 'assets/img/icons/circle-right.svg';
import ItemIcon from 'layouts/main/sidebar/menu/ItemIcon';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { CommonLinkPropsType } from 'types/globalState/menu';
import Label from 'layouts/main/sidebar/label';
import { beforeUnload } from 'helpers/form';
import { isMedia } from 'helpers/html';
import sidebarState from 'globalState/sidebarState';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { ATTRIBUTES } from 'constants/attributesForTests';

function MenuLink({ item, handleDynamicLinkClick }: CommonLinkPropsType) {
    const onLinkClick = (subItem): React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement> => (evt: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
        if (subItem.getDynamic()) {
            evt.preventDefault();
            handleDynamicLinkClick(subItem)(evt);
            return;
        }
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
        if (isMedia('sm') || !sidebarState.isPinned) {
            sidebarState.setOpenedState(false);
            if (!isMedia('sm')) {
                sidebarState.setTabIndex(-1).catch(console.error);
            }
        }
        if (subItem) {
            sidebarState.setMenuId(subItem.getSysId());
            window.localStorage.setItem('activeDynamicMenuId', '');
            subItem.setIsActive(true);
            _.forEach(subItem.getItems(), sItem => sItem.setIsActive(false));
            sidebarState.prepareItems(sidebarState.getIsOpenDynamic() ? sidebarState.getDynamicMenuItems() : sidebarState.getMenuItems());
        }
    };

    const renderLinkContainer = (item) => {
        return (
            <div
                className={ styles.linkContainer }
                data-test={ item.getIsCategory() ? ATTRIBUTES.sidebarMenuLinkCategory : ATTRIBUTES.sidebarMenuLinkItem }
            >
                <div
                    className={ styles.arrowContainer }
                    data-test={ ATTRIBUTES.sidebarArrowContainer }
                >
                    { getLinkIcon(item) }
                </div>
                <div className={ styles.labelContainer }>
                    <Label
                        hasIcon={ item.getIsCategory() }
                        data-test={ ATTRIBUTES.sidebarMenuLinkLabel }
                    >
                        { item.getLabel() }
                    </Label>
                    { itemIcon }
                </div>
            </div>
        );
    };

    let itemIcon: JSX.Element | null = null;
    const getLinkIcon = (item): JSX.Element | null => {
        if (item.getDynamic()) {
            return (
                <span
                    dangerouslySetInnerHTML={ { __html: IconCircleRight } }
                    className={ styles.icon }
                    data-test={ ATTRIBUTES.sidebarDynamicIcon }
                />
            );
        }
        return (
            <span
                className={ styles.dot }
                data-test={ ATTRIBUTES.sidebarDotIcon }
            />
        );
    };
    itemIcon = (
        <ItemIcon
            item={ item }
            className={ `${ styles.linkIcon } ${ item.getIsCategory() ? styles.categoryLinkIcon : '' }` }
        />
    );
    const matchAbsoluteUrl = item.getUrl() ? item.getUrl().match(/http[s]?:\/\//) : false;
    if (matchAbsoluteUrl) {
        return (
            <a
                onClick={ onLinkClick(item) }
                href={ item.getUrl() }
                className={ styles.link }
                key={ `Item${ item.getSysId() }` }
                data-test={ ATTRIBUTES.sidebarMenuLink }
            >
                { renderLinkContainer(item) }
            </a>
        );
    }
    return (
        <Link
            onClick={ onLinkClick(item) }
            to={ item.getUrl() || '#' }
            className={ styles.link }
            key={ `Item${ item.getSysId() }` }
            data-test={ ATTRIBUTES.sidebarMenuLink }
        >
            { renderLinkContainer(item) }
        </Link>
    );
}

export default observer(MenuLink);