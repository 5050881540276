import TablesState from 'globalState/tables';
import InfoMessagesState from 'globalState/infoMessages';
import { deleteRecord } from 'actions/record';

export default class SimpleList {
    query = '';

    getQuery = () => {
        return this.query;
    };

    /**
     * Получить массив имён таблиц
     *  @returns {Array<string>}
     * */
    getTablesName = () => {
        return TablesState.getTablesName();
    };


    /**
     *  Получить массив отмеченных строк в таблице
     *  @param {string} tableName
     *  @returns {Array<string>}
     * */
    getCheckedRow = (tableName) => {
        const table = TablesState.getTable(tableName);
        return table.getCheckedRow();
    };

    /**
     * Удалить список строк
     * @param {string} tableName
     * @param {Array<string>} sysIds
     * @returns {Promise<*>}
     */
    deleteRows = async (tableName, sysIds) => {
        await deleteRecord(tableName, sysIds);
    };

    /**
     * Обновить данные на листе
     * @param {string} tableName
     */
    refresh = (tableName) => {
        window.listGlobal[tableName].fetchUiActions();
        window.listGlobal[tableName].fetchData(false);
    };

    /**
     * Вывести информационное сообщение
     * @param {string} message
     * @param {number} [duration]
     */
    addInfoMessage = (message, duration) => {
        InfoMessagesState.pushInfo(message, duration);
    };

    /**
     * Вывести сообщение об ошибке
     * @param {string} message
     */
    addErrorMessage = (message) => {
        InfoMessagesState.pushError(message);
    };

    /**
     * Вывести сообщение о предупреждении
     * @param {string} message
     * @param {number} [duration]
     */
    addWarningMessage = (message, duration) => {
        InfoMessagesState.pushWarn(message, duration);
    };

    /**
     * Вывести сообщение об успехе
     * @param {string} message
     * @param {number} [duration]
     */
    addSuccessMessage = (message, duration) => {
        InfoMessagesState.pushSuccess(message, duration);
    };

    //Removes all informational and error messages from the top of the form.
    clearMessages() {
        InfoMessagesState.removeAll();
    }
}
