import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { IS_UPDATE_CHART } from 'globalState/report';
import { changeYValue, formatNumberValue, getDurationValue, numberFormatter, onClick } from 'helpers/report/report';

const HighCharts = require('highcharts');
// Load module after Highcharts is loaded
require('highcharts/highcharts-more')(HighCharts);
require('highcharts/modules/exporting')(HighCharts);
require('highcharts/modules/export-data')(HighCharts);
require('highcharts/modules/heatmap')(HighCharts);
require('highcharts/modules/solid-gauge')(HighCharts);
require('highcharts-border-radius')(HighCharts);

require('./highchart.css');

const HEATMAP_CELL_PADDING = 48;

function formatterLabel(name, color, value, isDurationAverage) {
    let label = '(not set)';
    if (typeof name === 'number') {
        name = name.toString();
    }
    if (name && typeof name === 'string' || name === '') {
        label = name && name.length < 500 || name === '' ? name : name.slice(0, 500) + '...';
    }
    let resultValue = value;
    if (isDurationAverage) {
        resultValue = getDurationValue(value, true);
    }
    return '<span style="color:' + color + '">\u25CF</span> ' + label + ' = ' + resultValue + '<br/>';
}

let id = 1;

@observer
export default class ChartComponent extends React.Component {
    chart;
    chartOptions;
    reportState;

    constructor(props) {
        super(props);
        this.chartId = id++;
        this.reportState = props.reportState;
    }

    componentDidMount() {
        this.drawChart();
    }

    componentDidUpdate(prevProps) {
        const prev = prevProps.data;
        const next = this.props.data;
        if (prev.chart_type !== next.chart_type || !_.isEqual(prevProps.styleOptions, this.props.styleOptions)) {
            this.drawChart();
        }
        if (!_.isEqual(prev.chart_data, next.chart_data)
            || this.props.isUpdateChart
            || !_.isEqual(prevProps.styleOptions, this.props.styleOptions)
        ) {
            if (this.props.isUpdateChart) {
                this.reportState.stateFlags = this.reportState.stateFlags & ~IS_UPDATE_CHART;
            }
            this.chart.update(this.props.styleOptions);
        }
        if (this.props.isDurationAverage && !prevProps.isDurationAverage) {
            this.drawChart(true);
        }
    }

    componentWillUnmount() {
        this.chart.destroy();
    }

    drawChart(isUpdateOptions = false) {
        if (!this.chartOptions || !_.isEqual(this.props.styleOptions, this.chartOptions) || isUpdateOptions) {
            this.chartOptions = this.getChartOptions();
        }
        this.chart = HighCharts.chart(`highcharts${ this.chartId }`, this.chartOptions);
        this.props.onChartReady(this.chart);
    }

    assignedStyleOption(data) {
        const { styleOptions } = this.props;
        data = _.merge(data, styleOptions);
        data.title = {
            text: '',
        };
    }

    justifyColumns() {
        const categories = this.xAxis[0].categories,
            offsetX = 7.5;
        let sum = 0,
            number = 0;
        for (let i = 0; i < categories.length; i++) {
            sum = 0;
            _.each(this.series, function (p) {
                if (p.visible) {
                    _.each(p.data, function (ob) {
                        if (ob.category === categories[i]) {
                            sum++;
                        }
                    });
                }
            });
            number = 1;
            _.each(this.series, (p) => {
                if (p.visible) {
                    _.each(p.data, (ob) => {
                        if (ob.category === categories[i]) {
                            // центр ячейки - ob.plotX
                            // позиция элемента - ob.barX
                            let value = ob.plotX - sum * (ob.pointWidth + 2) / 2 - ob.barX;
                            if (sum > 1 && number > 1) {
                                value = value + (number - 1) * (ob.pointWidth + 2);
                            }
                            ob.graphic.element.style.transform = `translateX(${ value }px)`;
                            // tooltip position
                            ob.tooltipPos[0] = value + ob.barX + ob.pointWidth / 2;
                            // datalabels position
                            if (ob.dataLabel) {
                                const offsetLabel = ob.pointWidth > offsetX ? (ob.pointWidth - ob.dataLabel.width + 15) / 2 : 0;
                                ob.dataLabel.element.style.transform = `translate(${ ob.barX + value - offsetX + offsetLabel }px, ${ ob.dataLabel.y }px)`;
                            }
                            number++;
                        }
                    });
                }
            });
            this.reflow();
        }
    }

    redrawConnectors() {
        const renderer = this.renderer;

        this.series[0].points.forEach((point, i) => {
            const marker = renderer.createElement('marker').add(renderer.defs).attr({
                markerWidth: 10,
                markerHeight: 10,
                refX: 5,
                refY: 5,
                orient: 'auto',
                id: `connector-marker-${ i }`,
            });

            renderer.circle(5, 5, 4).add(marker).attr({
                fill: '#fbfcfd',
                stroke: '#a9acbe',
                'stroke-width': 2,
            });

            if (point.connector) {
                point.connector.attr({
                    'marker-end': `url(#connector-marker-${ i })`,
                });
            }
        });
    }

    fitContainerSize(chartId) {
        return function () {
            let contextLength = 0;
            this.series[0].data.forEach((point) => contextLength = Math.max(contextLength, point.dataLabel.width));

            // Вычисляем размеры ячейки с учётом отступов
            const cellSize = contextLength + HEATMAP_CELL_PADDING;
            const colAmount = this.xAxis[0].categories.length;
            const rowAmount = this.yAxis[0].categories.length;
            // Размеры с учётом отступов, легенды, заголовков
            const newWidth = this.chartWidth - this.clipBox.width + cellSize * colAmount;
            const newHeight = this.chartHeight - this.clipBox.height + cellSize * rowAmount;

            const chartContainer = document.getElementById(`highcharts${ chartId }`);
            chartContainer.style.width = newWidth + 'px';
            chartContainer.style.height = newHeight + 'px';
            chartContainer.style.margin = 'auto';
            this.reflow();
        };
    }

    fitContainerGaugeSize(chartId) {
        return function () {
            const newHeight = this.chartHeight + 24;
            const chartContainer = document.getElementById(`highcharts${ chartId }`);
            const tickPositions = this.yAxis[0].tickPositions;
            this.yAxis[0].ticks[tickPositions[2]].label.translate(0, 10);
            chartContainer.style.height = newHeight + 'px';
            this.reflow();
        };
    }


    getChartOptions = () => {
        const {
            data,
            isDurationAverage,
        } = this.props;
        const reportState = this.reportState;
        const chartData = data.chart_data || [];
        let chartOptions;
        if (data.chart_type === 'pie') {
            chartOptions = {
                chart: {
                    plotShadow: false,
                    type: 'pie',
                    size: '360px',
                    events: {
                        load: this.redrawConnectors,
                        redraw: this.redrawConnectors,
                    },
                },
                tooltip: {
                    borderColor: '#17181d',
                    borderWidth: 0,
                    backgroundColor: '#FFF',
                    shadow: {
                        color: '#17181d',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    formatter: function () {
                        return formatterLabel(this.point.name, this.color, this.point.y, isDurationAverage);
                    },
                },
                legend: {
                    symbolHeight: 20,
                    symbolRadius: 4,
                    itemStyle: {
                        fontSize: '12px',
                        fontWeight: 'normal',
                        transform: 'translate(7px, -4px)',
                    },
                    labelFormatter: function () {
                        return `${ this.name } = ${ changeYValue(this.y, false, reportState) } (${ formatNumberValue(this.percentage, 0) }%)`;
                    },
                },
                series: [
                    {
                        animation: false,
                        colorByPoint: true,
                        data: chartData,
                        cursor: 'pointer',
                        events: {
                            click: onClick,
                        },
                        minSize: 242,
                        dataLabels: {
                            formatter: function () {
                                const value = numberFormatter(this.series, this.point, reportState);
                                return `${ this.point.name } = ${ value }`;
                            },
                        },
                    },
                ],
                plotOptions: {
                    pie: {
                        showInLegend: true,
                        // используем связку { borderWidth: 1, borderColor: null } для скрытия пустого пространства между секторами
                        // https://api.highcharts.com/highcharts/plotOptions.pie.borderWidth
                        borderWidth: 1,
                        borderColor: null,
                        dataLabels: {
                            softConnector: false,
                            connectorColor: '#a9acbe',
                            color: '#334d6e',
                            style: {
                                textShadow: false,
                                textOutline: false,
                                fontSize: '12px',
                                fontWeight: 'normal',
                            },
                        },
                        states: {
                            hover: {
                                enabled: true,
                            },
                        },
                    },
                },
            };
        }
        else if (data.chart_type === 'line') {
            let series = chartData.data;
            series.map(data => {
                    data.events = { click: onClick };
                    data.cursor = 'pointer';
                    data.animation = false;
                    data.dataLabels = {
                        formatter: function () {
                            return numberFormatter(this.series, this.point, reportState);
                        },
                    };
                },
            );
            chartOptions = {
                xAxis: {
                    categories: chartData.categories,
                    title: {
                        text: 'Date',
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        formatter: function () {
                            if (isDurationAverage) {
                                return getDurationValue(this.value);
                            }
                            return this.value;
                        },
                    },
                },
                series: series,
                plotOptions: {
                    series: {
                        connectNulls: true,
                        lineWidth: 2,
                        states: {
                            hover: {
                                lineWidth: 4,
                            },
                        },
                        animation: false,
                    },
                },
                tooltip: {
                    borderColor: '#17181d',
                    borderWidth: 0,
                    backgroundColor: '#FFF',
                    shadow: {
                        color: '#17181d',
                        offsetX: 0,
                        offsetY: 0,
                    },
                    formatter: function () {
                        return formatterLabel(this.point.series.name, this.color, this.point.y, isDurationAverage);
                    },
                },
            };
        }
        else if (data.chart_type === 'gauge') {
            // к числу, потому что, если с бека придет число, нам все уронят
            const value = Number(chartData[0].y);

            chartOptions = {
                title: null,
                chart: {
                    events: {
                        load: this.fitContainerGaugeSize(this.chartId),
                        update: this.fitContainerGaugeSize(this.chartId),
                    },
                },
                pane: {
                    startAngle: -160,
                    endAngle: 160,
                    center: [
                        '50%',
                        '50%',
                    ],
                    size: '372px',
                    background: {
                        borderColor: 'none',
                        backgroundColor: '#dfe1e5',
                        innerRadius: '93%',
                        outerRadius: '100%',
                        shape: 'arc',
                    },
                },
                tooltip: {
                    distance: 30,
                    formatter: function () {
                        return formatterLabel(this.name, this.point.color, this.point.y, isDurationAverage);
                    },
                },

                plotOptions: {
                    solidgauge: {
                        innerRadius: '93%',
                        dataLabels: {
                            y: -31,
                            borderWidth: 0,
                            useHTML: true,
                        },
                    },
                },

                series: [
                    {
                        data: [{
                            y: value,
                            url: chartData[0].url,
                            dataLabels: {
                                enabled: true,
                                style: {
                                    color: reportState?.styleOptions?.yAxis?.maxColor,
                                    fontSize: '42px',
                                    lineHeight: 1,
                                    fontFamily: 'Montserrat',
                                },
                                formatter: function () {
                                    return numberFormatter(this.series, this.point, reportState);
                                },
                            },
                        }],
                        animation: false,
                        cursor: 'pointer',
                        events: {
                            click: onClick,
                        },
                    },
                ],
                yAxis: {
                    title: {
                        enabled: false,
                    },
                    labels: {
                        formatter: function () {
                            if (isDurationAverage) {
                                return getDurationValue(this.value);
                            }
                            return _.round(this.value, 2);
                        },
                    },
                },
            };
        }
        else if (data.chart_type === 'heatmap') {
            chartOptions = {
                chart: {
                    type: 'heatmap',
                    plotBorderWidth: 0,
                    events: {
                        render: this.fitContainerSize(this.chartId),
                    },
                },
                xAxis: {
                    opposite: true,
                    categories: chartData.rowCategories,
                    gridLineColor: '#fbfcfd',
                    lineWidth: 0,
                    labels: {
                        autoRotationLimit: 0,
                        overflow: 'allow',
                        style: {
                            textOverflow: 'ellipsis',
                            height: '82px',
                            padding: '8px',
                            fontFamily: 'OpenSans',
                            fontSize: '14px',
                            color: '#334d6e',
                        },
                    },
                },
                yAxis: {
                    categories: chartData.colCategories,
                    gridLineColor: '#fbfcfd',
                    title: null,
                    labels: {
                        reserveSpace: true,
                        align: 'left',
                        style: {
                            textOverflow: 'ellipsis',
                            width: '132px',
                            padding: '8px',
                            fontFamily: 'OpenSans',
                            fontSize: '14px',
                            color: '#334d6e',
                        },
                    },
                },
                colorAxis: {
                    min: 0,
                    labels: {
                        reserveSpace: true,
                        style: {
                            fontFamily: 'OpenSans',
                            fontSize: '12px',
                            color: '#334d6e',
                        },
                    },
                },
                tooltip: {
                    formatter: function () {
                        return (this.point.count ? this.point.count : 0) + ' records';
                    },
                },
                legend: {
                    itemMarginTop: 10,
                },
                series: [
                    {
                        borderColor: '#fbfcfd',
                        borderWidth: 2,
                        animation: false,
                        data: chartData.data,
                        dataLabels: {
                            enabled: true,
                            style: {
                                color: '#6e728f',
                                fontFamily: 'OpenSans',
                                fontSize: '14px',
                                textOutline: '0px',
                                fontWeight: 'normal',
                            },
                            formatter: function () {
                                return numberFormatter(this.series, {
                                    ...this.point,
                                    y: this.point.value,
                                }, reportState);
                            },
                        },
                        cursor: 'pointer',
                        events: {
                            click: onClick,
                        },
                    },
                ],
            };
        }
        else {
            if (Array.isArray(chartData)) {
                const categories = _.map(chartData, line => line.name);
                chartOptions = {
                    chart: {
                        type: 'column',
                    },
                    xAxis: {
                        title: null,
                        categories: categories,
                    },
                    yAxis: {
                        maxPadding: 0.15,
                        labels: {
                            formatter: function () {
                                if (isDurationAverage) {
                                    return getDurationValue(this.value);
                                }
                                return this.value;
                            },
                        },
                    },
                    plotOptions: {
                        series: {
                            grouping: false,
                        },
                    },
                    series: [
                        {
                            states: {
                                hover: {
                                    opacity: 0.8,
                                },
                                inactive: {
                                    opacity: 1,
                                },
                            },
                            colorByPoint: true,
                            animation: false,
                            data: chartData,
                            cursor: 'pointer',
                            events: {
                                click: onClick,
                            },
                            dataLabels: {
                                formatter: function () {
                                    return numberFormatter(this.series, this.point, reportState);
                                },
                            },
                        },
                    ],
                    tooltip: {
                        headerFormat: '',
                        formatter: function () {
                            return formatterLabel(this.key, this.point.color, this.point.y, isDurationAverage);
                        },
                        borderColor: '#17181d',
                        borderWidth: 0,
                        backgroundColor: '#FFF',
                        shadow: {
                            color: '#17181d',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    },
                };
            }
            else {
                const _categories = _.clone(chartData.categories);
                const series = chartData.data;
                series.map(data => {
                        data.events = { click: onClick };
                        data.cursor = 'pointer';
                        data.animation = false;
                        data.dataLabels = {
                            formatter: function () {
                                return numberFormatter(this.series, this.point, reportState);
                            },
                        };
                    },
                );
                chartOptions = {
                    chart: {
                        type: 'column',
                        events: {
                            load: this.props.isStacked ? this.justifyColumns : undefined,
                            redraw: this.props.isStacked ? this.justifyColumns : undefined,
                        },
                    },
                    xAxis: {
                        categories: _categories,
                    },
                    yAxis: {
                        title: {
                            text: null,
                        },
                        labels: {
                            formatter: function () {
                                if (isDurationAverage) {
                                    return getDurationValue(this.value);
                                }
                                return this.value;
                            },
                        },
                    },
                    tooltip: {
                        headerFormat: '<br/>',
                        formatter: function () {
                            return formatterLabel(this.point.stackTotal ? `${ this.series.name } = ${ changeYValue(this.point.y, true, reportState) } (${ changeYValue(this.point.stackTotal, true, reportState) })` : this.series.name, this.point.color, this.point.y, isDurationAverage);
                        },
                    },
                    series: series,
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: false,
                            },
                            showInLegend: false,
                        },
                    },
                };
            }
        }

        chartOptions.credits = {
            enabled: false,
        };
        // Убираем бургер-меню
        chartOptions.exporting = {
            buttons: [],
        };

        this.assignedStyleOption(chartOptions);

        if (!chartOptions.chart) {
            chartOptions.chart = {};
        }
        chartOptions.chart.backgroundColor = 'transparent';

        return chartOptions;
    };

    render() {
        return (
            <div id={ `highcharts${ this.chartId }` } />
        );
    }
}
