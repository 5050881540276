import { action, observable } from 'mobx';
import { ContainerWidgetType } from 'types/pages/servicePortal/index';
import { PortalWidgetType } from 'types/pages/servicePortal/container/index';

export default class PortalWidget implements PortalWidgetType {
    @observable private _clientScript: string;
    @observable private _css: string;
    @observable private _sysId: string;
    @observable private _template: string;

    constructor(data: ContainerWidgetType) {
        this._clientScript = data.client_script;
        this._css = data.css;
        this._sysId = data.sys_id;
        this._template = data.template;
    }

    getClientScript(): string {
        return this._clientScript;
    }

    @action
    setClientScript(value: string) {
        this._clientScript = value;
    }

    getCss(): string {
        return this._css;
    }

    @action
    setCss(value: string) {
        this._css = value;
    }

    getSysId(): string {
        return this._sysId;
    }

    @action
    setSysId(value: string) {
        this._sysId = value;
    }

    getTemplate(): string {
        return this._template;
    }

    @action
    setTemplate(value: string) {
        this._template = value;
    }

    @action
    updateData = (template: string, clientScript: string, css: string) => {
        if (this._template !== template) {
            this._template = template;
        }
        if (this._clientScript !== clientScript) {
            this._clientScript = clientScript;
        }
        if (this._css !== css) {
            this._css = css;
        }
    };
}
