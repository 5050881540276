import { action, observable } from 'mobx';
import { TemplateRowState } from 'globalState/template/templateRow';
import * as _ from 'lodash';

class Template {
    @observable _templateFields = [
        new TemplateRowState({
            id: '',
            field: {
                dot_walking_attribute: '',
                referenced_table_name: '',
                referenced_table_id: '',
                column_id: '',
            },
            value: '',
        }),
    ];
    @observable _keyTemplateIndex = 0;
    @observable _result = {};

    getTemplateFields = () => {
        return this._templateFields;
    };

    getKeyTemplateIndex = () => {
        return this._keyTemplateIndex;
    };

    getActiveColumnIds = () => {
        const columns = [];
        this._templateFields.forEach(field => {
            if (field.getField().column_id) {
                columns.push(field.getField().column_id);
            }
        });
        return columns;
    };

    /**
     * Установка значения для template и преобразование в вид строк со значениями
     * @param value: (type: object) - значение
     * @param data: {type: array} - список из /dot-walk
     * @param tableId: {type: string} - id связанной таблицы
     */
    @action
    setResult = (value, data, tableId) => {
        this.clearData();
        this._result = value;
        if (value && typeof value === 'object' && data.length > 0) {
            this._templateFields = [];
            const keys = Object.keys(value);
            keys.forEach(key => {
                const findObj = data.find(attr => attr.column_id === key);
                if (findObj) {
                    this._keyTemplateIndex++;
                    const insertObj = new TemplateRowState({
                        id: `template${ this._keyTemplateIndex }`,
                        field: findObj,
                        value: this.getSysValue(value[key]),
                    }, tableId);
                    this._templateFields = [
                        ...this._templateFields,
                        insertObj,
                    ];
                }
            });
            this._keyTemplateIndex++;
            this._templateFields = [
                ...this._templateFields,
                new TemplateRowState({
                    id: `template${ this._keyTemplateIndex }`,
                    field: {
                        dot_walking_attribute: '',
                        referenced_table_name: '',
                        referenced_table_id: '',
                        column_id: '',
                    },
                    value: '',
                }, tableId),
            ];
        }
    };

    /**
     * Преобразование данных пришедших с бека в нужный вид
     * @param value: (type: object) - значение
     * @param data: {type: array} - список из /dot-walk
     * @param tableId: {type: string} - id связанной таблицы
     */
    @action
    parseValue = (value, data, tableId) => {
        if (value && typeof value === 'object' && data.length > 0) {
            this.clearData();
            _.forEach(value, (item, key) => {
                this._result[key] = _.isNil(item && item.database_value) ? item : item.database_value;
                if (!item) return;
                const findObj = data.find(attr => attr.column_id === key);
                if (findObj) {
                    this._keyTemplateIndex++;
                    const insertObj = new TemplateRowState({
                        id: `template${ this._keyTemplateIndex }`,
                        field: findObj,
                        value: this.getValue(item, findObj),
                        special: item.special,
                    }, tableId);
                    this._templateFields = [
                        ...this._templateFields,
                        insertObj,
                    ];
                }
            });
            this._keyTemplateIndex++;
            this._templateFields = [
                ...this._templateFields,
                new TemplateRowState({
                    id: `template${ this._keyTemplateIndex }`,
                    field: {
                        dot_walking_attribute: '',
                        referenced_table_name: '',
                        referenced_table_id: '',
                        column_id: '',
                    },
                    value: '',
                    special: {},
                }, tableId),
            ];
            return this._result;
        }
        return value;
    };

    getSysValue = (obj) => {
        const listValue = obj.split(',');
        if (listValue.length === 1) {
            return obj;
        }
        return listValue;
    };

    getValue = (obj, field) => {
        const type = obj.type || field.column_type;
        switch (type) {
            case 'choice':
                return {
                    database_value: obj.database_value,
                    display_value: obj.display_value,
                };
            case 'reference':
                return {
                    database_value: obj.database_value,
                    display_value: obj.display_value,
                };
            case 'list':
                const valueIsEmpty = _.isNil(obj.database_value) && !obj.display_value;
                if (valueIsEmpty) {
                    return [];
                } else {
                    const sysIds = obj.database_value ? obj.database_value.split(',') : [];
                    const displayValues = obj.display_value.split(',');
                    return displayValues.map((value, index) => {
                        return {
                            sys_id: sysIds[index] ? sysIds[index] : '',
                            value: value,
                        };
                    });
                }
            case 'id':
                return obj.special;
            default:
                return obj.database_value;
        }
    };

    @action
    addTemplateRow = () => {
        this._keyTemplateIndex++;
        const insertObj = new TemplateRowState({
            id: `template${ this._keyTemplateIndex }`,
            field: {
                dot_walking_attribute: '',
                referenced_table_name: '',
                referenced_table_id: '',
                column_id: '',
            },
            value: '',
        });
        this._templateFields = [
            ...this._templateFields,
            insertObj,
        ];
    };

    @action
    removeTemplateRow = (id) => {
        const findRow = this._templateFields.find(field => field.getId() === id);
        if (findRow && findRow.getField()) {
            delete this._result[findRow.getField().column_id];
        }
        this._templateFields = this._templateFields.filter(field => field.getId() !== id);
    };

    @action
    changeTemplateResult = (id, value) => {
        this._templateFields.forEach((row, index) => {
            if (row.getId() === id) {
                const nValue = this.getValueDocumentId(value);
                const resultValue = Array.isArray(value) ? value.map(v => v.sys_id || v.database_value).join(',') : nValue;
                this._result = {
                    ...this._result,
                    [row.getField().column_id]: resultValue || null,
                };
                if (this._templateFields.length - 1 === index) {
                    this.addTemplateRow();
                }
            }
        });
    };

    @action
    removeResult = (id) => {
        const findRow = this._templateFields.find(field => field.getId() === id);
        if (findRow && findRow.getField()) {
            delete this._result[findRow.getField().column_id];
        }
    }

    getValueDocumentId = (value) => {
        if (!value) {
            return value;
        }
        else if (_.isPlainObject(value)) {
            return value.sys_id || value.database_value || value.document_id;
        }
        else {
            return value;
        }

    }

    getResult = () => {
        return this._result;
    };

    @action
    clearData = () => {
        this._templateFields = [];
        this._keyTemplateIndex = 0;
        this._result = {};
    };
}


export const templateStateClass = Template;

export default new Template();
