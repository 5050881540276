import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Dropdown from 'components/dropdown';
import styles from './styles.module.scss';
import StringInput from 'components/dynamicForms/view/field/stringInput';
import Button from 'components/button';
import IconArrow from 'assets/img/select-arrow.svg';
import { helperRedirect } from 'helpers/history';
import { isEqual } from 'helpers/data';
import langStore from 'globalState/lang';
import { ATTRIBUTES } from 'constants/attributesForTests';


/***
 * Описание: сохранение/выбор фильтра
 * Праметры:
 * value: {required: false, type: string} - введённое значение
 * readOnly: {required: false, type: boolean} - readOnly
 * options: {required: true, type: array} - массив доступных значений
 * onChange: {required: true, type: function} - метод вызываемый при изменении значения инпута
 * onSave: {required: true, type: function} - метод вызываемый при сохранении фильтра
 * isMobile - мобильная версия
 */

@observer
export default class ChoiceSaveComponent extends React.Component {
    @observable isOpened = false;
    @observable hoverId = '';
    refDropdown = React.createRef();
    refInput = React.createRef();
    refButton = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.addEventListener('scroll', () => this.isOpened = false);
        document.addEventListener('click', this.onDocumentClick);

        if (this.props.value === '') {
            const selected = this.props.options.filter((option) => option.is_selected)[0];
            if (typeof selected !== 'undefined') {
                this.setValue(selected.name);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.isOpened = false);
        document.removeEventListener('click', this.onDocumentClick);
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.options, prevProps.options)) {
            const selected = this.props.options.filter((option) => option.is_selected)[0];

            if (typeof selected !== 'undefined') {
                this.setValue(selected.name);
            }
            else {
                this.setValue('');
            }
        }
    }

    renderMenu() {
        const items = this.props.options.map((option, index) => {
            const hoverId = index + '.' + option.name;
            return (
                <div
                    className={ `${ styles.menuItem } ${ hoverId === this.hoverId ? styles.hover : '' }` }
                    key={ hoverId }
                    onClick={ () => this.onItemClick(option) }
                    onMouseEnter={ () => this.onItemHover(hoverId) }
                >
                    { option.name }
                </div>
            );
        });

        return <div className={styles.menu}>{items}</div>;
    }

    onItemHover = (hoverId) => {
        this.hoverId = hoverId;
    };

    onItemClick = (option) => {
        helperRedirect(option.url);
        this.isOpened = false;
        this.setValue(option.name);
    };

    onDocumentClick = (e) => {
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const buttonEl = this.refButton.current;
        if (!dropdownEl || !buttonEl) return false;

        if (!dropdownEl.contains(e.target) && !buttonEl.contains(e.target)) {
            this.isOpened = false;
        }
    };

    setValue(name) {
        const { filter_titles = {} } = langStore.getTranslate();
        this.props.onChange(name !== filter_titles.none_filter_name ? name : '');
    }

    onChange = (data) => {
        this.props.onChange(data.value);
    };

    render() {
        const { filter_titles = {} } = langStore.getTranslate();
        return (
            <>
                <div
                    className={ `${styles.Choice} ${this.props.isMobile ? styles.MobileChoice : ''}` }
                    ref={ this.refInput }
                    data-test={ ATTRIBUTES.conditionSavedFilters }
                >
                    <div className={ styles.container }>
                        <StringInput
                            isServicePortal={ this.props.isServicePortal }
                            className={ styles.input }
                            onChange={ this.onChange }
                            value={ this.props.value }
                            readOnly={ this.props.readOnly }
                            placeholder={ filter_titles.enter_name }
                        />
                        <Button
                            className={ styles.button }
                            buttonType={ 'icon-border' }
                            onClick={ () => {
                                this.isOpened = !this.isOpened;
                            } }
                            ref={ this.refButton }
                        >
                            <div className={ styles.icon } dangerouslySetInnerHTML={ { __html: IconArrow } } />
                        </Button>
                    </div>

                    { this.isOpened && !this.props.readOnly &&
                    <Dropdown refParent={ this.refInput } ref={ this.refDropdown }>
                        { this.renderMenu() }
                    </Dropdown>
                    }
                </div>

                <Button
                    onClick={ this.props.onSave }
                    disabled={ !this.props.value || this.props.readOnly }
                    className={ this.props.isMobile ? styles.MobileSave : '' }
                    data-test={ ATTRIBUTES.conditionSaveNewFilterButton }
                >
                    { filter_titles.button_save }
                </Button>
            </>
        );
    }
}
