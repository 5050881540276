import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import IconList from 'assets/img/icons/text-format-list.svg';
import IconX from 'assets/img/icons/close-x.svg';
import IconEdit from 'assets/img/icons/edit-2.svg';
import sidebarState from 'globalState/sidebarState';
import IconArrow from 'assets/img/sidebar-chevron-right.svg';
import { isMedia } from 'helpers/html';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Label from '../label';
import { beforeUnload } from 'helpers/form';
import { deleteFavorite } from 'actions/menu';
import { MenuItemType } from 'types/globalState/menu';
import _ from 'lodash';
import langStore from 'globalState/lang';
import { isEmptyMenu } from 'helpers/sidebar';
import { observer } from 'mobx-react/index';

interface FavoriteComponentProps {
    filterValue?: string;
    items: MenuItemType[];
    fetchMenuList: (getFromCache: boolean) => void;
}

class FavoriteComponent extends React.Component<FavoriteComponentProps> {
    removeFromFavorites = (item: MenuItemType) => async () => {
        await deleteFavorite({
            sys_id: item.getSysId(),
            label: item.getLabel(),
            url: item.getUrl(),
        });

        sidebarState.fetchMenuList(false).catch(console.error);
        sidebarState.fetchFavoritesList(false).catch(console.error);
    };

    handleClick = (evt) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            return;
        }
        const parent = evt.target.closest('.' + styles.item);
        parent.classList.toggle(styles.active);
    }

    onLinkClick = (evt) => {
        const unload = beforeUnload({});
        if (unload && !confirm(unload)) {
            evt.preventDefault();
            return;
        }
        if (isMedia('sm') || !sidebarState.isPinned) {
            sidebarState.setOpenedState(false);
            if(!isMedia('sm')){
                sidebarState.setTabIndex(-1).catch(console.error);
            }
        }
        sidebarState.prepareFavorites();
    };

    renderMenuItems(items: MenuItemType[], index = 0, isSubMenu = false) {
        return _.map(items, (item) => {
            if (!_.isEmpty(item.getItems())) {
                if (!item.getIsShow()) {
                    return null;
                }
                const classSubItem = isSubMenu ? styles.SubMenu : '';
                const classItem = item.getIsDefaultOpened() ? [
                    styles.active,
                    styles.item,
                    classSubItem,
                ].join(' ') : `${ styles.item } ${ classSubItem }`;
                return (
                    <div
                        key={ `Item${ item.getSysId() }${ index }` }
                        className={ classItem }
                    >
                        <div className={ `${ styles.linkWrap } ${ styles.parent }` } data-test={ ATTRIBUTES.favoriteItem }>
                            <div
                                className={ styles.link }
                                onClick={ this.handleClick }
                                data-test={ ATTRIBUTES.favoriteItemLink }
                            >
                                <div
                                    className={ styles.arrow }
                                    dangerouslySetInnerHTML={ { __html: IconArrow } }
                                />
                                <div className={ styles.linkContainer }>
                                    <Label>{ item.getLabel() }</Label>
                                </div>
                            </div>
                            {
                                item.getEditUrl() && (
                                    <Link
                                        onClick={ this.onLinkClick }
                                        to={ item.getEditUrl() }
                                        className={ styles.linkBtn }
                                        dangerouslySetInnerHTML={ { __html: IconEdit } }
                                    />
                                )
                            }
                            {
                                !isSubMenu ?
                                    <span
                                        className={ styles.linkBtn }
                                        onClick={ this.removeFromFavorites(item) }
                                        dangerouslySetInnerHTML={ { __html: IconX } }
                                        data-test={ ATTRIBUTES.favoriteItemDelete }
                                    />
                                    : ''
                            }
                        </div>

                        <div className={ styles.submenu }>
                            {
                                _.map(item.getItems(), (subItem: MenuItemType, subIndex) => {
                                    if (!_.isEmpty(subItem.getItems())) {
                                        return this.renderMenuItems([subItem], subIndex, true);
                                    }
                                    if ((_.isEmpty(subItem.getItems()) && subItem.getUrl().startsWith('#')) || !subItem.getIsShow()) {
                                        return null;
                                    }
                                    const itemIcon = subItem.getIcon() ? <span className={ styles.linkIcon } dangerouslySetInnerHTML={ { __html: IconList } } /> : '';
                                    let itemClass: string | string[] = [styles.item];
                                    if (subItem.getIsActive()) {
                                        itemClass.push(styles.active);
                                    }
                                    itemClass = itemClass.join(' ');
                                    return (
                                        <div
                                            className={ itemClass } key={ subItem.getSysId() }
                                            data-test={ ATTRIBUTES.favoriteSubItem }
                                        >
                                            <div className={ styles.linkWrap }>
                                                <Link
                                                    onClick={ this.onLinkClick }
                                                    to={ subItem.getUrl() }
                                                    className={ styles.link }
                                                    data-test={ ATTRIBUTES.favoriteSubItemLink }
                                                >
                                                    { itemIcon }
                                                    <div className={ styles.linkContainer }>
                                                        <Label>{ subItem.getLabel() }</Label>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                );
            }
            else {
                if ((isSubMenu && _.isEmpty(item.getItems())) || !item.getIsShow()) {
                    return null;
                }
                // возможно когда то будет приходить разная иконка, а пока хардкод
                const itemIcon = item.getIcon() ? <span className={ styles.linkIcon } dangerouslySetInnerHTML={ { __html: IconList } } /> : '';
                return (
                    <div
                        key={ item.getSysId() }
                        className={ item.getIsActive() ? [
                            styles.active,
                            styles.item,
                        ].join(' ') : styles.item }
                    >
                        <div className={ `${ styles.linkWrap } ` } data-test={ ATTRIBUTES.favoriteItem }>
                            <Link
                                onClick={ this.onLinkClick }
                                to={ item.getUrl() }
                                className={ ` ${ styles.link } ${ styles.itemSingle } ` }
                                data-test={ ATTRIBUTES.favoriteItemLink }
                            >
                                { itemIcon }
                                <div className={ styles.linkContainer }>
                                    <Label>{ item.getLabel() }</Label>
                                </div>
                            </Link>
                            {
                                item.getEditUrl() && (
                                    <Link
                                        onClick={ this.onLinkClick }
                                        to={ item.getEditUrl() }
                                        className={ styles.linkBtn }
                                        dangerouslySetInnerHTML={ { __html: IconEdit } }
                                    />
                                )
                            }
                            <span
                                className={ styles.linkBtn }
                                onClick={ this.removeFromFavorites(item) }
                                dangerouslySetInnerHTML={ { __html: IconX } }
                                data-test={ ATTRIBUTES.favoriteItemDelete }
                            />
                        </div>
                    </div>
                );
            }
        });
    }

    render() {
        const { items, filterValue } = this.props;
        const { navigation_search: { no_items_found } = {} } = langStore.getTranslate();
        return (
            <div className={ styles.menu }>
                { (isEmptyMenu(items) || items.length === 0) && filterValue
                    ? <div className={ styles.noResults }>{ no_items_found }</div>
                    : this.renderMenuItems(items) }
            </div>
        );
    }
}

export default observer(FavoriteComponent);