import { action, observable } from 'mobx';
import SimpleUser from 'ui-actions-scripts/simple-user';
import langStore from 'globalState/lang/index';
import cache from 'globalState/cache';
import FormsState from 'globalState/forms/index';
import { getUserAccessToken } from 'lib/apiRequest';
import { fetchAuthLogin, fetchAuthLoginSideDoor, fetchSignUp, fetchUser, fetchSsoLogout, fetchDropAccessToken } from 'actions/auth';
import { fetchPublicProperties } from 'actions/preferences';
import {AccessError, AuthLoginData, LoginData, SignupData, UserData, UserType} from 'types/globalState/user';
import {helperRedirect} from "helpers/history";

class User implements UserType {
    @observable initialFetching = true;
    @observable isFetching = true;
    @observable authorized = false;
    @observable user: UserData | {} = {};
    @observable accessError: AccessError = null;
    accessToken: null | string = null;
    ssoEnabled = false;

    constructor() {
        this.fetchMe().catch(console.error);
    }

    @action
    async fetchMe(): Promise<void> {
        this.accessToken = getUserAccessToken ? getUserAccessToken() : '';

        try {
            if (this.accessToken) {
                this.isFetching = true;
                const { data } = await fetchUser();
                this.user = data;
                await this.getPublicProperties();
                this.ssoEnabled = (this.user as UserData).sso_enabled;
                langStore.setLanguage((this.user as UserData).language);
                this.authorized = true;
            }
        }
        catch (e) {
            this.user = {};
            this.authorized = false;

            throw e;
        }
        finally {
            this.initialFetching = false;
            this.isFetching = false;
            (window as any).s_user = new SimpleUser();
        }
    }

    async getPublicProperties() {
        const { isOkStatus, data } = await fetchPublicProperties();
        if (isOkStatus) {
            (this.user as UserData).version = data.version;
        }
    }

    clearUserData() {
        window.localStorage.removeItem('accessToken');
        cache.deleteAllByPrefix();
    }

    @action async logout(dropToken = true) {
        if (this.ssoEnabled) {
            await fetchSsoLogout();
            this.clearUserData();
        } else {
            FormsState.clear();
            if (dropToken) {
                const { data: { logoutUrl } } = await fetchDropAccessToken();
                if (logoutUrl) {
                    queueMicrotask(() => helperRedirect(logoutUrl));
                }
            }
            this.authorized = false;
            this.user = {};
            this.clearUserData();
            window.localStorage.removeItem('redirect');
            queueMicrotask(() => window.location.reload());
        }
    }

    @action setAccessError(codeError: number) {
        this.accessError = codeError;
    }

    getAccessError(): AccessError {
        return this.accessError;
    }

    @action
    async login(data: LoginData, isSideDoor: boolean | undefined = false): Promise<AuthLoginData | never>  {
        const loginMethod = isSideDoor ? fetchAuthLoginSideDoor : fetchAuthLogin;
        const { data: respData } = await loginMethod({ language: data.language }, data);
        const isSetToken = await this.setAccessToken(respData.auth_key);
        if (!isSetToken) {
            throw new Error(`Unexpected server authorization error`);
        }
        return respData;
    }

    @action
    async signup(data: SignupData): Promise<AuthLoginData | never> {
        const { data: respData } = await fetchSignUp({ language: data.language }, data);
        const isSetToken = await this.setAccessToken(respData.auth_key);
        // Something wrong here
        if (!isSetToken) {
            throw new Error(`Unexpected server registration error`);
        }
        return respData;
    }

    @action
    async setAccessToken(token: string): Promise<boolean> {
        if (token) {
            window.localStorage.setItem('accessToken', token);
            cache.deleteAllByPrefix();
            await this.fetchMe();
            return true;
        }
        return false;
    }
}

export default new User();
