import * as React from 'react';
import Container from 'components/container';

import LayoutEditor from 'components/layoutEditor';
import Button from 'components/button';
import apiRequest from 'lib/apiRequest';
import langStore from 'globalState/lang';


import styles from './styles.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { goBack } from 'helpers/history';
import SmartTitle from 'components/smartTitle';
import PageLoader from 'components/pageLoader';
import Heading from 'components/heading';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';


/**
 *  Описание: Страница с редактированием записей связных списков
 *  Параметры:
 *  record_id: {required: true,  type: string} - рекорд, для которого выбираются записи связного списка
 *  related_list_element_id: {required: true,  type: string} - связный список с записями
 */
@observer
class RelatedListEditLayout extends React.Component {
    @observable data;
    @observable errorMessage = '';
    @observable recordId;
    @observable relatedElementId;
    @observable isSavingData = false;
    lastRouteParams = '';
    selected = [];


    constructor(props) {
        super(props);
        const { record_id, related_list_element_id } = props.match.params;
        this.recordId = record_id;
        this.relatedElementId = related_list_element_id;
        if (this.recordId && this.relatedElementId) {
            this.fetchData().catch(this.errorFetchData);
        }
    }

    componentDidUpdate() {
        const { record_id, related_list_element_id } = this.props.match;
        if (record_id !== this.recordId || related_list_element_id !== this.relatedElementId) {
            this.fetchData().catch(this.errorFetchData);
        }
    }

    fetchData = async () => {
        const { match } = this.props;

        const routeParams = JSON.stringify({
            ...match.params,
        });
        if (routeParams.localeCompare(this.lastRouteParams) === 0) return false;
        this.lastRouteParams = routeParams;

        const params = {};
        params.related_list_element_id = this.relatedElementId;

        try {
            const response = await new apiRequest('GET /edit-related-list-member/' + this.recordId).qs(params).send();
            this.data = response.getData();
        }
        catch (e) {
            throw e;
        }

    };

    errorFetchData = (e) => {
        this.isSavingData = false;
        this.errorMessage = e.message;
    };

    updateSelected = (selected) => {
        this.selected = selected;
    };

    saveSelected = async () => {
        this.isSavingData = true;
        const params = {};
        params.related_list_element_id = this.relatedElementId;

        const data = {
            'selected_ids': this.selected,
        };

        const result = await new apiRequest('POST /edit-related-list-member/' + this.recordId).qs(params).sendJSON(data);
        if (result && result.status === 'OK') {
            goBack('/record' + this.data.previous_page_link);
        }
    };

    render() {
        const { related_list_edit } = langStore.getTranslate();
        if (this.errorMessage) return <div>{ this.errorMessage }</div>;
        if (!this.data) return <PageLoader />;

        return (
            <Container>
                <div className={ styles.all }>
                    <div className={ styles.actionsTop }>
                        <div className={ styles.title }>
                            <Heading size={'h2'} noMargin><SmartTitle>{ related_list_edit && related_list_edit.related_list_edit_configure }</SmartTitle></Heading>
                        </div>
                        <Button className={ styles.button } data-loader={ this.isSavingData } onClick={ this.saveSelected } buttonType='primary'>{ related_list_edit && related_list_edit.save_button }</Button>
                        <Button className={ styles.button } onClick={ () => {
                            goBack('/record' + this.data.previous_page_link);
                        } }>{ related_list_edit && related_list_edit.cancel_button }</Button>
                    </div>
                    <LayoutEditor
                        onChange={ this.updateSelected }
                        selectedItems={ this.data.selected }
                        lang={ related_list_edit ? related_list_edit : null }
                        availableItems={ this.data.collection }
                        location={ this.props.location }
                    />
                </div>
            </Container>
        );
    }

}


export default ErrorWrapperHoc(RelatedListEditLayout);
