import _ from 'lodash';
import reportState from 'globalState/report';
import { createConditionStr, getDateGroupName, getDisplayNameString, getParamSection, getParamValue, getSysColumnNameById, isDurationAverage, limitPrecision, STEPS } from 'helpers/report/report';
import moment from 'moment';

/**
 * Подготовка данных для gauge
 * @param data
 * @param currentReportState
 * @returns {[*]}
 */
export const prepareDataForGauge = (data, currentReportState = reportState) => {
    // к числу, потому что, если с бека придет число, нам все уронят
    const value = [ data && data[0] ? data[0].value : 0 ];
    return [{
            y: Number(value),
            url: getUrlByFieldValues(currentReportState),
        }];
};

/**
 * Обрезание под формат для gauge && digit
 * @param value
 */
export const prepareCutValue = (value) => {
    // если это дробное число
    const indexPoint =  (value.indexOf(",") !== -1 && value.indexOf(",")) || value.indexOf(".");
    const valueLength = indexPoint !== -1 ? indexPoint : value.length;
    const wholeNumberLength = value.length;
    if (wholeNumberLength > 4) {
        if (-1 < indexPoint && valueLength < 4 && wholeNumberLength > 5 ) {
            return value.slice(0, 5) + '...';
        } else {
            if (valueLength > 3 && valueLength < 7) {
                return Math.round(parseInt(value) / 1e3) + "k";
            } else if (valueLength < 10) {
                return Math.round(parseInt(value) / 1e6) + "m";
            } else if (valueLength < 13) {
                return Math.round(parseInt(value) / 1e9) + "b";
            } else if (valueLength >= 13) {
                return Math.round(parseInt(value) / 1e12) + "t";
            }
        }
    }
    return value;
};

/**
 * Подготовка данных для heatmap
 * @param data - данные
 * @param config - конфигурация
 * @param currentReportState - текущий стейт для репорта
 * @returns {{rowCategories: string[], data: [], colCategories: string[]}}
 */
export const prepareDataForHeatmap = (data, config, currentReportState) => {
    if (!Array.isArray(data)) return false;
    const rowSysName = getSysColumnNameById(getParamValue(config, 'column_id', true), currentReportState);
    const columnSysName = getSysColumnNameById(getParamValue(config, 'row_id', true), currentReportState);

    const rowCategories = [];
    const colCategories = [];
    const chartData = [];
    data.forEach(row => {
        rowCategories.push({
            name: row.display_name,
            bdName: row.database_name,
        });
        row.items.forEach(column => {
            if (!_.some(colCategories, [
                'bdName',
                column.database_name,
            ])) {
                colCategories.push({
                    name: column.display_name,
                    bdName: column.database_name,
                });
            }
            chartData.push({
                x: _.findIndex(rowCategories, [
                    'bdName',
                    row.database_name,
                ]),
                y: _.findIndex(colCategories, [
                    'bdName',
                    column.database_name,
                ]),
                value: column.value === null ? 0 : column.value,
                url: getUrlByFieldValues(
                    currentReportState,
                    [
                        {
                            name: rowSysName,
                            value: row.database_name,
                        },
                        {
                            name: columnSysName,
                            value: column.database_name,
                        },
                    ]),
                count: column.count,
            });
        });
    });
    return {
        data: chartData,
        rowCategories: rowCategories.map(row => getDisplayNameString(row.name)),
        colCategories: colCategories.map(col => getDisplayNameString(col.name)),
    };
};

/**
 * Подготовка данных для Bar
 * @param data - данные
 * @param isStacked - ключ, указывающий на stacked
 * @param groupBySysName - sys name колонки group by
 * @param sliceBySysName - sys name колонки slice by
 * @param currentReportState - текущий стейт репорта
 * @returns {*}
 */
export const prepareDataForBar = (data, isStacked, groupBySysName, sliceBySysName, currentReportState) => {
    if (!Array.isArray(data)) return false;
    let x = 0;
    return _.reduce(data, (result, value) => {
        result.categories.push(getDisplayNameString(value.display_name));
        value.items && value.items.forEach((el) => {
            // Не отображаем пустые значения
            if (el.value === null) {
                return;
            }

            const stackedData = {
                x: x,
                y: el.value,
                url: getUrlByFieldValues(
                    currentReportState,
                    [
                        {
                            name: groupBySysName,
                            value: value.database_name,
                        },
                        {
                            name: sliceBySysName,
                            value: el.database_name,
                        },
                    ]),
            };
            let founedEl = result.data.find((item) => _.isEqual(item.dbName, el.database_name));
            if (founedEl) {
                founedEl.data.push(stackedData);
            }
            else {
                const name = getDisplayNameString(el.display_name);
                let item = {
                    name,
                    dbName: el.database_name,
                    data: [ stackedData ],
                };
                if (isStacked) item.stack = name;
                result.data.push(item);
            }
        });
        x++;
        return result;
    }, {
        data: [],
        categories: [],
    });
};

/**
 * Функция, которая формирует ссылку для перехода на определить list-layout при клике на элемент чарта.
 * @param styleOptions
 * @param currentReportState - текущий стейт у репортов
 * @returns {string}
 */
const getDrilldownView = (styleOptions, currentReportState) => {
    if (window.s_form && window.s_form.getDisplayValue) {
        const view = window.s_form.getDisplayValue('drilldown_list_layout_id');
        if (view) {
            return `&view=${ view }`;
        }
    }
    else if (styleOptions && styleOptions.drilldown_list_layout_id) {
        return `&view=${ styleOptions.drilldown_list_layout_id }`;
    }
    else if (currentReportState.styleOptions && currentReportState.styleOptions.drilldown_list_layout_id) {
        return `&view=${ currentReportState.styleOptions.drilldown_list_layout_id }`;
    }
    return '';
};

/**
 * @param currentReportState - текущий стейт репорта
 * @param fieldValues - массив объектов { name, value }, где
 *          name - название поля,
 *          value - значение поля для выбранного элемента в отчёте
 * @returns {*} - url для перехода на список записей
 */
export const getUrlByFieldValues = (currentReportState, fieldValues = []) => {
    if (fieldValues.some(field => field.value === undefined)) {
        return null;
    }

    const trendByColumnType = currentReportState.additionalData?.trend_by_column_type_name;
    const params = fieldValues.map(field => createConditionStr(field.name, field.value, trendByColumnType));

    // Добавляем условия из condition builder'а
    const conditionStr = removeConditionSortAndGroup(getCondition(currentReportState));
    if (conditionStr && conditionStr.length > 0) {
        params.push(`(${ conditionStr })`);
    }

    const paramStr = encodeURIComponent(params.join('^'));
    return `/list/${ (currentReportState.getTableName()) }/?condition=${ paramStr }` + getDrilldownView(currentReportState.reportStyles, currentReportState);
};


/**
 * Получения поля condition из данных рекорда
 * @param currentReportState - текущий стект у репорта
 * @returns {*}
 */
const getCondition = (currentReportState) => {
    const section = currentReportState.report ?
        currentReportState.report.record_data :
        currentReportState.reportData ? currentReportState.reportData.chart.record_data : null;
    if (!section) {
        return null;
    }
    const dataSection = getParamSection(
        currentReportState.report ?
            currentReportState.report.record_data :
            currentReportState.reportData.chart.record_data,
        'Data');
    return getParamValue(dataSection, 'condition', true);
};

/**
 * Функция преобразует данные с groupBy для временных графиков к highcharts формату
 * @param data - маассив с исходными данными
 * @param isTimestamp - являются ли даты в формате timestamp или числа
 * @param currentReportState - текущий стект у репорта
 * @param groupBySysName - sysName поля, по которому производится группировка
 * @param trendBySysName - sysName поля, по которому строится trend
 * @param per - поле в форме, отвечающее за период (year, month etc.)
 * @param isCombine - флаг, комбинировать ли?
 * @param includeNull - флаг, нужно ли отображать данные с незаданной датой
 */
function prepareDataWithTimeWithGroupBy(data, isTimestamp, currentReportState, groupBySysName, trendBySysName, per, isCombine, includeNull) {
    return _.reduce(data, (result, group) => {
        group.items.forEach((el) => {
            if (!includeNull && el.display_name === null) {     // Не отображаем данные с незаданной датой
                return;
            }
            // Получаем числовое представление даты
            const date = getDateAsNumber(el.display_name, isTimestamp);
            if (!result.categories.includes(date)) {    // Добавляем дату к массиву категорий
                result.categories.push(date);
            }

            const newPoint = {
                y: el.value,
                name: date,
                url: getUrlByFieldValues(
                    currentReportState,
                    [
                        {
                            name: groupBySysName,
                            value: group.database_name,
                        },
                        {
                            name: trendBySysName,
                            value: {
                                per,
                                isCombine,
                                value: date,
                            },
                        },
                    ]),
            };

            // Ищем лайн по имени
            let foundedLine = result.data.find((item) => item.dbName === group.database_name);
            if (foundedLine) { // Если есть, добавляем в него ещё одну точку
                foundedLine.data.push(newPoint);
            }
            else {    // Если нет, создаём новый
                result.data.push({
                    name: getDisplayNameString(group.display_name),
                    dbName: group.database_name,
                    data: [ newPoint ],
                });
            }
        });
        return result;
    }, {
        data: [],
        categories: [],
    });
}

/**
 * Функция преобразует дату к числовому форматы
 * @param date - дата
 * @param isTimestamp - является ли дата в формате timestamp или числа
 * @returns {*}
 */
function getDateAsNumber(date, isTimestamp) {
    return !date ?
        null :
        isTimestamp ?
            moment(date).valueOf() :
            Number(date);
}

/**
 * Функция преобразует данные с одним измерением для временных графиков к highcharts формату
 * @param data - маассив с исходными данными
 * @param isTimestamp - являются ли даты в формате timestamp или числа
 * @param currentReportState - текущий стект у репорта
 * @param trendBySysName - sysName поля, по которому строится trend
 * @param per - поле в форме, отвечающее за период (year, month etc.)
 * @param isCombine - флаг, комбинировать ли?
 * @param includeNull - флаг, нужно ли отображать данные с незаданной датой
 * @param tableName - имя таблицы, для которой строится отчёт
 */
function prepareSingleDataWithTime(data, isTimestamp, currentReportState, trendBySysName, per, isCombine, includeNull, tableName) {
    return _.reduce(data, (result, item) => {
            if (!result.data[0]) {      // В этом случае только один лайн
                result.data[0] = {
                    name: tableName,
                    data: [],
                };
            }
            if (includeNull || item.display_name !== null) {     // Не отображаем данные с незаданной датой
                // Получаем числовое представление даты
                const date = getDateAsNumber(item.display_name, isTimestamp);
                if (!result.categories.includes(date)) {    // Добавляем дату к массиву категорий
                    result.categories.push(date);
                }
                // Добавляем к графику новую точку
                result.data[0].data.push({
                    y: item.value,
                    name: date,
                    url:
                        getUrlByFieldValues(
                            currentReportState,
                            [
                                {
                                    name: trendBySysName,
                                    value: {
                                        per,
                                        isCombine,
                                        value: item.database_name,
                                    },
                                },
                            ]),
                });
            }
            return result;
        }, {
            data: [],
            categories: [],
        });
}

/**
 * Подготовка данных для временных отчётов
 * @param data - изначальные данные
 * @param config - конфигурация отчета (в МО поле configure)
 * @param tableName - название таблицы
 * @param includeNull - нужно ли отображать данные для незаданной даты
 * @param currentReportState - текущий стект у репорта
 * @returns {{data: *, categories: *[]}} - если данные некоректны, вернет false
 */
export const prepareDataWithTime = (data, config, tableName, includeNull, currentReportState) => {
    if (!Array.isArray(data)) return false;
    const per = getParamValue(config, 'per', true).toLowerCase();
    const isCombine = getParamValue(config, 'combine_periods') === 1;
    const isTimestamp = !(isCombine || per === 'year');

    const groupBy = getParamValue(config, 'group_by', true);
    let groupBySysName;
    if (groupBy && groupBy.length > 0) {
        groupBySysName = getSysColumnNameById(groupBy, currentReportState);
    }

    const trendBy = getParamValue(config, 'trend_by', true);
    const trendBySysName = getSysColumnNameById(trendBy, currentReportState);

    const formattedData = groupBy && groupBy.length > 0 ?
        prepareDataWithTimeWithGroupBy(data, isTimestamp, currentReportState, groupBySysName, trendBySysName, per, isCombine, includeNull, ) :
        prepareSingleDataWithTime(data, isTimestamp, currentReportState, trendBySysName, per, isCombine, includeNull, tableName, );

    // Сортируем данные по дате
    return sortLineDataByGroups(formattedData, per, isCombine);
};

/**
 * преобразование к формату для highchart
 * @param data - объект с исходными данными
 * @param fieldSysName - sysName поля, покторому производится группировка
 * @param currentReportState - текущий стейт репорта
 * @returns {*}
 */
export const formatItem = (data, fieldSysName, currentReportState) => {
    if (!Array.isArray(data)) return false;
    return _.clone(data).map((item) => {
        const limitedPrecision = limitPrecision();
        let yValue = limitedPrecision !== null ? +item.value.toFixed(+limitedPrecision): item.value;
        return {
            name: getDisplayNameString(item.display_name),
            y: yValue,
            url: getUrlByFieldValues(currentReportState, [
                {
                    name: fieldSysName,
                    value: item.database_name,
                },
            ]),
        };
    });
};

/**
 * Сортировка данных для Line
 * @param result - данные, которые сортируем
 * @param per - поле в форме, отвечающее за период (year, month etc.)
 * @param isCombine - флаг, комбинировать ли?
 * @returns {{data: *, categories: []}}
 */
const sortLineDataByGroups = (result, per, isCombine) => {
    const categories = [];
    // Сортируем категории по дате
    let sortedCategories = _.sortBy(result.categories);
    if (sortedCategories.includes(null)) {
        sortedCategories = [ null, ..._.filter(sortedCategories, cat => cat !== null) ];
    }
    const sortedData = result.data.map((item) => { // Перебираем все лайны
        item.data = _.sortBy(  // Сортируем данные по x
            _.map(item.data,
                value => {
                    // Форматируем даты в соответствии с типом группировки и формируем список категорий
                    const index = _.indexOf(sortedCategories, value.name);  // вычисляем координату x для точки
                    const dateGroup = getDateGroupName(value.name, per, isCombine);
                    categories[index] = dateGroup;  // Добавляем пользовательское представление даты к массиву категорий
                    value.x = index;
                    value.name = dateGroup;
                    return value;
                },
            ), 'x');
        return item;
    });
    return {
        categories,
        data: sortedData,
    };
};

/**
 * Заполнение данными, которые приходят с других мест (запроса, родительских компонентов)
 * @param data
 * @param currentReportState - текущий стейт, для виджетов, виджет создается индивидуально для каждого компонента
 * @returns {{record_data}|*}
 */
export const parseReport = (data, currentReportState) => {
    currentReportState.additionalData = data.additional_data;
    currentReportState.aggregationColumn = data.additional_data.aggregation_column_type_name;
    const dataSection = data.record_data.find((section) => section.service_name === 'Data');

    const name = dataSection.elements.find((field) => field.sys_column_name === 'name');
    data.report_name = name.value;

    const table = dataSection.elements.find((field) => field.sys_column_name === 'table_id');
    data.table_name = table.value;

    data.report_type = data.report_type.toLowerCase();

    if (data && data.record_data) {
        const config = Object.values(data.record_data).find((section) => section.service_name === STEPS[2]);
        if (config) {
            const groupBy = config.elements.find((element) => element.sys_column_name === 'group_by');
            currentReportState.additionalGroup = groupBy ? _.clone(groupBy.value) : [];
            const aggregationColumn = config.elements.find((element) => element.sys_column_name === 'aggregation_column');
            if (aggregationColumn?.value?.database_value) {
                isDurationAverage(currentReportState);
            }
        }
    }
    return data;
};

/**
 * Удаляет сортировку и группировку со строки кондишина
 * @param {string} condition
 * @returns {string}
 */
export const removeConditionSortAndGroup = (condition) => {
    let tempCondition = condition;
    if (tempCondition && (tempCondition.includes('^ORDERBY') || tempCondition.includes('^GROUPBY'))) {
        tempCondition = tempCondition?.replace(/(?=\^ORDERBY)(.+)(?=\^|)/g, '').replace(/(?=\^GROUPBY)(.+)(?=\^|)/g, '');
    }
    return tempCondition;
};
