import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import apiRequest from 'lib/apiRequest';

import FieldWrapper from 'components/dynamicForms/view/fieldWrapper';
import Button from 'components/button';
import RecordPopupButton from 'components/recordPopup/infoButton';

import Reference from 'components/dynamicForms/view/field/reference';

import styles from 'components/dynamicForms/view/field/documentId/styles.module.scss';
import Search from 'assets/img/icons/search.svg';
import langStore from 'globalState/lang';
import sysDBTables from 'globalState/sysDBTables';
import Dropdown from 'components/dropdown';
import DocumentIdModel from 'components/dynamicForms/model/field/DocumentIdModel';
import _ from 'lodash';
import { getTestNameField } from 'helpers/data';
import { ATTRIBUTES } from 'constants/attributesForTests';
import IconClose from 'assets/img/icons/close-circle.svg';
import { getLinkDisplayValue, isChanged } from 'helpers/form';
import IconEye from 'assets/img/icons/eye-off.svg';
import { LINK_TITLE_UNAVAILABLE, LINK_NOT_FOUND, LINK_UNAVAILABLE } from 'constants/strings';

/**
 * Описание: компонент DocumentId
 * Параметры:
 * onChange: {required, type: function} - метод для изменения
 * value: {type: string}
 */
@observer
export default class DocumentId extends React.Component {
    @observable model;
    @observable isOpened = false;
    @observable disabledButtonSave = true;
    @observable isTableEdit = false;
    refInput = React.createRef();
    refDropdown = React.createRef();

    constructor(props) {
        super(props);
        this.fetchData().catch(this.errorFetchData);

        if (props.model) {
            this.model = props.model;
        } else {
            this.model = new DocumentIdModel(props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props, prevProps)) {
            if (this.props.model) {
                this.model = this.props.model;
            } else {
                this.model.mergeData(this.props);
            }
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.doCloseWindow);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.doCloseWindow);
    }

    fetchData = async () => {
       await sysDBTables.fetchSysDbTable();
       this.disabledButtonSave = !(sysDBTables.getList().length);
    };

    @action
    handleClick = () => {
        if (this.model.readonly) return;
        this.isOpened = !this.isOpened;
    };

    @action
    doCloseWindow = () => {
        this.isOpened = false;
    };

    @action
    changeTable = ({ value }) => {
        if(!value) {
            this.model.mergeData({
                value: {
                    ...this.model.value,
                    table_title: null,
                    table_name: null,
                    table_id: null,
                    referenceValue: null,
                    term: null,
                    record_title: null,
                    record_id: null,
                    document_id: null,
                },
            });
            this.model.changed = isChanged(this.model.defaultValue, this.model.value);
            const { onChange } = this.props;
            if (onChange) {
                onChange(this.model);
            }

            return;
        }

        const table = sysDBTables.getById(value.database_value);
        if(!table) return;
        this.model.mergeData({
            value: {
                ...this.model.value,
                table_title: table.title,
                table_name: table.name,
                table_id: table.sys_id,
                referenceValue: null,
                term: null,
                record_title: null,
                record_id: null,
            },
        });
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.model);
        }
    };

    @action
    save = async () => {
        const { table_id, referenceValue } = this.model.value;
        if (!table_id || !referenceValue) {
            return;
        }
        const response = await new apiRequest('GET /field/document-id').qs({
            table_id,
            record_id: referenceValue.database_value,
        }).send();
        if (response.status === 'OK') {
            const value = response.getData();
            this.model.mergeData({ value });
            if (this.props.onChange){
                this.props.onChange(this.model);
            }
            this.doCloseWindow();
        }
    };

    referenceChange = ({ term, value }) => {
        this.model.mergeData({
            term: term,
            value: {
                ...this.model.value,
                document_id: null,
                referenceValue: value,
                record_title: '',
                record_id: null,
            },
        });
        this.model.changed = isChanged(this.model.defaultValue, this.model.value);
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.model);
        }
    };

    onFieldClear = () => {
        this.changeTable({value:''});
    };

    errorFetchData = (message) => {
        console.error(message);
    };

    onTableEditChange = (isEdit) => {
        this.isTableEdit = isEdit;
    };

    render() {
        const { value } = this.model;
        const tablesLength = sysDBTables.getList().length > 0;
        let table_id = null;
        let table_name = null;
        let table_title = null;

        if (tablesLength && value) {
            table_id = value.table_id;
            table_name = value.table_name;
            table_title = value.table_title;
        }

        const tableValue = {
            display_value: table_title,
            database_value: table_id,
        };

        const refValue = (value && value.referenceValue) || {
            display_value: value && value?.record_title,
            database_value: value && value?.record_id,
            reference_state: value && value?.reference_state || null,
        };

        const { document_id_titles } = langStore.getTranslate();
        let classes = [ styles.DocumentIdInput ];

        if (this.model.readonly) {
            classes.push(styles.readOnly);
        }
        classes = classes.join(' ');
        const fieldName = getTestNameField(this.model);
        const dataTest = (fieldName !== 'unknown') ? `${ fieldName }-${ ATTRIBUTES.fieldDocumentId }` : `${ ATTRIBUTES.fieldDocumentId }`;
        const isUnavailable = value?.reference_state === LINK_UNAVAILABLE || value?.reference_state === LINK_TITLE_UNAVAILABLE;
        const isDisabled = value?.reference_state === LINK_UNAVAILABLE || value?.reference_state === LINK_NOT_FOUND;
        const badgeEye = <div className={ styles.BadgeEye } data-test={ ATTRIBUTES.badgeEye } dangerouslySetInnerHTML={ { __html: IconEye } } />;

        let displayValue = '';
        if ([LINK_NOT_FOUND, LINK_UNAVAILABLE, LINK_TITLE_UNAVAILABLE].includes(value?.reference_state)){
            displayValue = getLinkDisplayValue(value);
        } else {
            displayValue = value?.record_title;
        }

        const popUpRecordButtonTitle = <>
            { isUnavailable && badgeEye }
            { displayValue }
        </>;
        return (
            <FieldWrapper model={ this.model }>
                <div
                    className={ styles.DocumentId }
                    data-test={ this.props['data-test'] ? this.props['data-test'] : dataTest }
                    data-test-visible={ this.model.isVisible }
                    data-test-mandatory={ this.model.isMandatory }
                    data-test-readonly={ this.model.readonly }
                    data-test-field-type={ this.model.sys_column_name ? this.model.column_type : undefined }
                    data-test-field-name={ this.model.sys_column_name }
                >
                    <div className={ classes } ref={ this.refInput }>
                        {
                            this.model.readonly && !(table_name && value && value.record_id) ?
                                <div className={ styles.FieldReadonly }/> :
                                <div className={ styles.Field }>
                                    <div className={ styles.FieldOverlay } onClick={ this.handleClick }/>
                                    {
                                        table_name && value && value.record_id
                                        && (
                                            <>
                                                <div className={ styles.BadgeWrap }>
                                                    <RecordPopupButton
                                                        fieldType='record_field'
                                                        className={ `${ styles.Badge } ${isDisabled ? styles.disabled : ''}` }
                                                        essence={ table_name }
                                                        sys_id={ value.record_id }
                                                        isDisabled={ isDisabled }
                                                    >
                                                        { popUpRecordButtonTitle }
                                                    </RecordPopupButton>
                                                </div>
                                                { !this.model.readonly && (
                                                    <div
                                                        onClick={ this.onFieldClear }
                                                        className={ styles.FieldClear }
                                                        dangerouslySetInnerHTML={ { __html: IconClose } }
                                                    />
                                                ) }
                                            </>
                                        )
                                    }
                                </div>

                        }

                        { !this.model.readonly && (
                            <Button
                                buttonType={ 'icon-border' }
                                svg={ Search }
                                className={ `${ styles.button }` }
                                onClick={ this.handleClick }
                            />
                        )}
                    </div>
                    { tablesLength && this.isOpened &&
                    (
                        <>
                            <Dropdown
                                refParent={ this.refInput }
                                ref={ this.refDropdown }
                            >
                                <div className={ styles.DocumentIdOverlay } onClick={ this.doCloseWindow } />
                                <div className={ styles.DocumentIdDropdown }>
                                    <div className={ styles.DocumentIdWindow }>
                                        <div className={ styles.DocumentIdBody }>
                                            <Reference value={ tableValue }
                                                       label={ `${ document_id_titles && document_id_titles.table_name }` }
                                                       special={ {
                                                           table_name: sysDBTables.TABLE_NAME,
                                                       } }
                                                       setFocus={ !(tableValue.display_value && tableValue.database_value) }
                                                       onEditFlagChange={ this.onTableEditChange }
                                                       onChange={ this.changeTable } />
                                            <Reference value={ refValue }
                                                       term={ this.model.term }
                                                       readOnly={ !(tableValue.display_value && tableValue.database_value) }
                                                       label={ `${ document_id_titles && document_id_titles.document }` }
                                                       special={ {
                                                           table_id,
                                                           table_name,
                                                       } }
                                                       ref={ this.model.ref }
                                                       setFocus={ !this.isTableEdit && (tableValue.display_value && tableValue.database_value) && !(refValue.database_value)}
                                                       onChange={ this.referenceChange } />
                                            <div className={ styles.DocumentIdButtons }>
                                                <Button buttonType={ 'primary' }
                                                        onClick={ this.save }
                                                        disabled={ !refValue || !refValue.database_value || !value.referenceValue }>
                                                    { document_id_titles && document_id_titles.button_apply }
                                                </Button>
                                                <Button onClick={ this.doCloseWindow }>
                                                    { document_id_titles && document_id_titles.button_close }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dropdown>
                        </>
                    )
                    }
                </div>
            </FieldWrapper>

        );
    }
}
