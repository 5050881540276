import { WidgetElementType, WidgetDataElementPropsType, WidgetDataElementType } from 'types/components/widgets/index';
import { observable } from 'mobx';
import _ from 'lodash';
import { simpleHashFromObject } from 'helpers/misc';
import { generateRandomString } from 'helpers/data';

class ElementWidget implements WidgetElementType {
    @observable private _props: WidgetDataElementPropsType | undefined;
    @observable private _type: string | undefined;
    @observable private _children: WidgetElementType[];
    @observable private _data: string | undefined;
    @observable private _key: string;

    constructor(props: WidgetDataElementType, getFieldValue: (fieldKey: string) => any) {
        this._props = _.reduce(props?.props, function(result, value, key) {
            result[key] = value;
            return result;
        }, {});
        this._type = props.type;
        this._children = !_.isEmpty(props.props?.children) && typeof props.props?.children !== 'string'
            ? _.map(_.filter(props?.props?.children, child => !_.isEmpty(child)), child => {
                if (typeof child === 'string') {
                    return new ElementWidget({ data: child, type: 'text' }, getFieldValue);
                }
                return new ElementWidget(child, getFieldValue);
            })
            : [];
        this._data = props.data ? _.trim(props.data, ' \n\t') : (typeof props?.props?.children === 'string' ? _.trim(props?.props?.children || '', ' \n\t') : '');
        this._key = props.key || (props.props ? simpleHashFromObject(props.props).toString() : generateRandomString());
    }

    getProps(): WidgetDataElementPropsType | undefined {
        return this._props;
    }

    setProps(value: WidgetDataElementPropsType | undefined) {
        this._props = value;
    }

    getType(): string | undefined {
        return this._type;
    }

    setType(value: string | undefined) {
        this._type = value;
    }

    getChildren(): WidgetElementType[] {
        return this._children;
    }

    setChildren(value: WidgetElementType[]) {
        this._children = value;
    }

    getData(): string | undefined {
        return this._data;
    }

    setData(value: string | undefined) {
        this._data = value;
    }

    getKey(): string {
        return this._key;
    }

    setKey(value: string) {
        this._key = value;
    }
}

export default ElementWidget;
