import { action, observable } from 'mobx';

class SortingRow {
    @observable _id = '';
    @observable _field = '';
    @observable _direction = 'ascending';
    @observable _displayValue = {};
    @observable _disabled = false;

    constructor(data) {
        if (data) {
            this._id = data.id;
            this._field = data.field;
            this._direction = data.direction;
            this._displayValue = data.displayValue || {};
            this._disabled = data.disabled || false;
        }
    }

    getId() {
        return this._id;
    }

    @action
    setId(value) {
        this._id = value;
    }

    getField() {
        return this._field;
    }

    @action
    setField(value) {
        this._field = value;
    }

    getDirection() {
        return this._direction;
    }

    @action
    setDirection(value) {
        this._direction = value;
    }

    getDisplayValue() {
        return this._displayValue;
    }

    @action
    setDisplayValue(value) {
        this._displayValue = value;
    }

    getDisabled() {
        return this._disabled;
    }

    @action
    setDisabled(disabled) {
        this._disabled = disabled;
    }
}

export const SortingRowState = SortingRow;

export default new SortingRow();
