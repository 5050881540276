import * as React from 'react';
import { observer } from 'mobx-react';
import UiButtons from 'components/uiButtons';
import WidgetFormState from './state';
import { withRouter } from 'react-router-dom';
import { ATTRIBUTES } from 'constants/attributesForTests';
import FormsState from 'globalState/forms';
import DynamicForm from 'components/dynamicForms';
import { beforeUnload } from 'helpers/form';
import Heading from 'components/heading';
import widgetsDataState from 'globalState/widgets';

/**
 * @props tableName системное название таблицы
 * @props sysId id записи
 * @props view представление формы, необязательный параметр
 */
@withRouter
@observer
export default class WidgetForm extends React.Component {
    /**
     * @type WidgetFormState
     */
    state;

    constructor(props) {
        super(props);

        this.state = new WidgetFormState(props);
    }

    componentDidMount() {
        window.addEventListener('beforeunload', beforeUnload);
    }

    componentDidUpdate(prevProps) {
        const { tableName, sysId, view, titleHide, uiActions, userScripts, widgetId, formName } = this.props;
        if (
            prevProps.tableName !== tableName
            || prevProps.sysId !== sysId
            || prevProps.view !== view
            || prevProps.titleHide !== titleHide
            || prevProps.uiActions !== uiActions
            || prevProps.userScripts !== userScripts
            || prevProps.widgetId !== widgetId
            || prevProps.formName !== formName
        ) {
            this.state.updateData({ ...this.props, isUpdate: true }).catch(console.error);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
        widgetsDataState.removeForm(this.state.dynamicFormId);
        window.removeEventListener('beforeunload', beforeUnload);
    }

    renderHeader = () => {
        const { titleHide } = this.props;
        if (!titleHide) {
            return null;
        }
        return (
            <Heading size={'h2'}>{ this.state.getTitle() }</Heading>
        );
    };

    renderSections = () => {
        const { widgetId, formName } = this.props;
        const { tableName, sysId, view, recordData } = this.state;
        const { sections, client_scripts: clientScripts, form_title } = recordData;
        return (
            <DynamicForm
                isServicePortal
                sections={ sections }
                fields={ sections[0].elements }
                name={ sections[0].name }
                sysId={ sysId }
                tableName={ tableName }
                clientScripts={ this.state.isUserScripts && clientScripts }
                key={ `${ tableName }-${ sysId }-${ view }` }
                formView={ form_title }
                widgetId={ widgetId }
                formName={ formName }
                id={ this.state.dynamicFormId }
            />);
    };

    render() {
        if (!this.state) {
            return null;
        }

        const { save, className, widgetId } = this.props;
        const { tableName, sysId, actionsData, recordData } = this.state;

        if (!recordData) {
            return null;
        }

        const disabled = !!_.find(FormsState.getFields(this.state.dynamicFormId), field => field.isMandatory && field.checkEmptyValue());
        const buttons = actionsData && actionsData.form_bottom_button ? actionsData.form_bottom_button : [];
        const script = `${ widgetId ? `s_widgets.getFormById('${ this.state.dynamicFormId }')` : 's_form' }.save().then(__resolveServerResponse).catch(__rejectServerResponse);`;
        const saveButtons = save ? [{
                client: true,
                style: 'primary',
                script: script,
                name: save,
                disabled: disabled,
                wait_server_response: true,
                page_key: 'form_button__save',
            }] : [];
        const actionData = buttons.concat(saveButtons);

        return (
            <div className={ className || '' } data-test={ this.props['data-test'] ? this.props['data-test'] : `form-${ ATTRIBUTES.widget }` }>
                { this.renderHeader() }
                { this.renderSections() }
                {
                    actionData.length !== 0 && <UiButtons
                        recordId={ sysId }
                        tableName={ tableName }
                        data={ actionData }
                    />
                }
            </div>
        );
    }
}
