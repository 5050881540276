import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import Button from 'components/button';
import { executeScripts } from 'helpers/uiActionsHelper';
import IconMenu from 'assets/img/icons/burger-menu.svg';
import IconCheck from 'assets/img/icons/check.svg';
import IconX from'assets/img/icons/close-x.svg';
import IconChevron from 'assets/img/icons/chevron-right.svg';
import IconChevronL from 'assets/img/icons/chevron-left.svg';
import styles from './styles.module.scss';
import Check from 'assets/img/checkbox-check.svg';
import Dropdown from 'components/dropdown';
import { ATTRIBUTES } from 'constants/attributesForTests';
import { isMedia } from 'helpers/html';
import ModalWrapper from 'components/modalWrapper';
import _ from 'lodash';
import langStore from 'globalState/lang';

@observer
export default class BurgerMenu extends React.Component {
    @observable isOpened = false;
    @observable mobSubMenu = [];
    @observable menuData = [];
    @observable currentMenu = [];
    refButton = React.createRef();
    refDropdown = React.createRef();

    constructor(props) {
        super(props);
        this.menuData = this.props.data;
        this.currentMenu = this.menuData;
        reaction(
            () => this.isOpened,
            (isOpened) => {
                if (!isOpened) {
                    this.removeAllActive(this.menuData);
                    this.currentMenu = [];
                }
            }
        );
    }

    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (!_.isEqual(prevProps.data, data)) {
            this.menuData = data;
            this.currentMenu = this.menuData;
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleDocumentClick);
        document.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleDocumentClick);
        document.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleDocumentClick = (e) => {
        if(isMedia('sm')) return;
        const dropdownEl = this.refDropdown ? this.refDropdown.current : null;
        const buttonEl = this.refButton.current;
        if (!dropdownEl || !buttonEl) return false;

        if (!dropdownEl.contains(e.target) && !buttonEl.contains(e.target)) {
            this.isOpened = false;
        }
    };

    handleScroll = () => {
        if(isMedia('sm')) return;
        this.isOpened = false;
    };

    handleClickMenu = () => {
        if (this.props.data) {
            this.isOpened = !this.isOpened;
        }
    };

    handleClickExecScripts = (item, closeAfter = true) => () => {
        if (item.wait_server_response && this.props.disableUiActions) {
            return;
        }
        if (!item.sub_menu && item.parent_action === 'list_filters' && this.props.onClickButton) {
            this.props.onClickButton(item);
        }

        executeScripts(item, this.props.tableName, this.props.recordId);

        if(closeAfter){
            this.isOpened = false;
            this.mobSubMenu = [];
        }
    };

    handleKeyDown = (e) => {
        if (!this.isOpened || this.menuData.length === 0) return;
        const { key } = e;

        if (key === 'ArrowUp' || key === 'ArrowDown') {
            e.preventDefault();
            if(this.currentMenu.length === 0) {
                this.currentMenu = this.menuData;
            }

            const currentMenuLength = this.currentMenu.length;
            let activeIndex = null;

            for ( let i = 0; currentMenuLength > i; i++ ) {
                if (this.currentMenu[i].isActive) {
                    activeIndex = i;
                    break;
                }
            }

            if (activeIndex !== null) {
                let newIndex = key === 'ArrowUp' ? activeIndex - 1 : activeIndex + 1;
                if (newIndex < 0) {
                    newIndex = currentMenuLength - 1;
                }
                else if (newIndex >= currentMenuLength) {
                    newIndex = 0;
                }
                this.currentMenu[activeIndex].isActive = false;
                this.currentMenu[newIndex].isActive = true;
            }
            else {
                let newIndex = key === 'ArrowUp' ? currentMenuLength - 1 : 0;
                this.currentMenu[newIndex].isActive = true;
            }

        }

        if (key === 'ArrowRight') {
            e.preventDefault();
            const currentMenuLength = this.currentMenu.length;

            for ( let i = 0; currentMenuLength > i; i++ ) {
                if (this.currentMenu[i].isActive && this.currentMenu[i].sub_menu) {
                    this.currentMenu = this.currentMenu[i].sub_menu;
                    this.currentMenu[0].isActive = true;
                    break;
                }
            }
        }

        if (key === 'ArrowLeft') {
            e.preventDefault();
            const parentMenu = this.findParentMenu(this.menuData);
            if (parentMenu) {                
                this.removeAllActive(this.currentMenu);
                this.currentMenu = parentMenu;
            }
        }

        if (key === 'Enter') {
            e.preventDefault();
            const activeItem = _.find(this.currentMenu, item => item.isActive);
            if (activeItem) {
                this.handleClickExecScripts(activeItem)();
            }
            else {
                this.isOpened = false;
            }
        }

        if (key === 'Escape') {
            e.preventDefault();
            this.isOpened = false;
        }
    };

    findParentMenu = (menu) => {
        const activeItem = _.find(menu, item => item.isActive);
        if (activeItem) {
            const subMenu = activeItem.sub_menu;
            if (subMenu) {
                if( _.isEqual(subMenu, this.currentMenu)) {
                    return menu;
                }
                else {
                    return this.findParentMenu(subMenu);
                }
            }
        }
    }

    removeAllActive = (menu) => {
        const activeItem = _.find(menu, item => item.isActive);

        if (activeItem) {
            const subMenu = activeItem.sub_menu;
            activeItem.isActive = false;
            if (subMenu) {
                this.removeAllActive(subMenu);
            }
        }
    };

    onItemMouseEnter = (item, currentMenu) => () => {
        this.removeAllActive(currentMenu);
        item.isActive = true;
        this.currentMenu = currentMenu;
    };

    onItemMouseLeave = (item) => () => {
        item.isActive = false;
        this.removeAllActive(this.currentMenu);
        this.currentMenu = [];
    };

    renderMenu(data, isSubMenu = false) {
        const items = data.map((item, index) => {
            const stylesItemArray = [];
            stylesItemArray.push(styles.item);
            if (item.wait_server_response && this.props.disableUiActions) {
                stylesItemArray.push(styles.disabled);
            }
            else if (item.isActive) {
                stylesItemArray.push(styles.active);
            }
            return (
                <div
                    key={ index }
                    className={ stylesItemArray.join(' ') }
                    onClick={ this.handleClickExecScripts(item) }
                    data-test={ isSubMenu ? ATTRIBUTES.burgerSubMenuItem : ATTRIBUTES.burgerMenuItem }
                    onMouseEnter={ this.onItemMouseEnter(item, data) }
                    onMouseLeave={ this.onItemMouseLeave(item) }
                >
                    <span className={ styles.text }>{ item.name }</span>
                    { item.is_selected === true &&
                    <span className={ styles.selected }><div className={ styles.Check } dangerouslySetInnerHTML={ { __html: Check } } /></span>
                    }
                    { item.sub_menu && item.sub_menu.length > 0 &&
                    <React.Fragment>
                        <span className={ styles.arrow }  dangerouslySetInnerHTML={ { __html: IconChevron }} />
                        { this.renderMenu(item.sub_menu, true) }
                    </React.Fragment>
                    }
                </div>
            );
        });

        let menu;
        if (isSubMenu) {
            menu = <div className={ styles.menu }>{ items }</div>;
        }
        else {
            menu = (
                <Dropdown refParent={ this.refButton } ref={ this.refDropdown } disableMinWidth>
                    <div className={ styles.menu }>
                        { items }
                    </div>
                </Dropdown>
            );
        }

        return menu;
    }

    renderMobileMenu = (data) => {
        const isSubMenu = this.mobSubMenu.length > 0;
        const currentMenu = isSubMenu ? this.mobSubMenu[this.mobSubMenu.length-1].items : data;
        const currentTitle = isSubMenu > 0 ? this.mobSubMenu[this.mobSubMenu.length-1].title : this.props.pageName;

        const items = currentMenu.map((item, index) => {
            let classNames = [];
            const isSubMenu = !!(item.sub_menu && item.sub_menu.length > 0);
            const onItemClick = ()=> {
                if(isSubMenu){
                    this.mobSubMenu.push({
                        items: item.sub_menu,
                        title: item.name,
                    });
                }
                this.handleClickExecScripts(item, !isSubMenu)();
            };

            classNames.push(styles.MobileItem);
            if (item.wait_server_response && this.props.disableUiActions) {
                classNames.push(styles.disabled);
            }
            classNames = classNames.join(' ');

            return (
                <div
                    key={ index }
                    className={ classNames }
                    onClick={ onItemClick }
                    data-test={ isSubMenu ? ATTRIBUTES.burgerSubMenuItem : ATTRIBUTES.burgerMenuItem }
                >
                    <div className={ styles.MobileText }>{ item.name }</div>
                    { item.is_selected === true &&
                        <div className={ styles.MobileCheck } dangerouslySetInnerHTML={ { __html: IconCheck } } />
                    }
                    { isSubMenu &&
                        <div
                            className={ styles.MobileButtonChevron }
                            dangerouslySetInnerHTML={ { __html: IconChevron } }
                        />
                    }
                </div>
            );
        });

        return (
            <ModalWrapper>
                <div className={ styles.MobilePopup }>
                    <div className={ styles.MobileHeader }>
                        { isSubMenu && (
                            <div
                                className={ styles.MobileButtonChevron }
                                dangerouslySetInnerHTML={{__html: IconChevronL }}
                                onClick={()=>{
                                    this.mobSubMenu.pop();
                                }}
                            />
                        )}
                        <div
                            className={ styles.MobileTitle }
                            onClick={()=>{
                                if(isSubMenu){
                                    this.mobSubMenu.pop();
                                }
                            }}
                        >{ currentTitle }</div>
                        <Button
                            className={ styles.MobileHeaderButton }
                            buttonType={'icon'}
                            svg={ IconX }
                            onClick={()=>{
                                this.isOpened = false;
                                this.mobSubMenu = [];
                            }}
                        />
                    </div>
                    <div className={ styles.MobileMenu}>
                        { items }
                    </div>
                </div>
            </ModalWrapper>
        );
    };

    render() {
        if (this.props.isDisable) {
            return (
                <div className={ styles.wrap }>
                    <Button buttonType='icon' className={ styles.disabled } svg={ IconMenu } />
                </div>);
        }

        let menu = null;
        if(this.props.data){
            if(isMedia('sm')){
                menu = this.renderMobileMenu(this.props.data);
            }
            else {
                menu = this.renderMenu(this.menuData);
            }
        }
        const system_buttons_hints = langStore.getTranslateKey('system_buttons_hints');

        return (
            <div className={ styles.wrap }>
                <Button
                    hint={ system_buttons_hints?.additional_actions }
                    buttonType='icon'
                    onClick={ this.handleClickMenu }
                    svg={ IconMenu }
                    ref={ this.refButton }
                    data-test={ this.props.isRelatedLists ? ATTRIBUTES.buttonRelatedListsBurgerMenu : ATTRIBUTES.buttonBurgerMenu }
                />
                { this.isOpened === true && menu }
            </div>
        );
    }
}
