import * as React from 'react';
import { observer } from 'mobx-react';
import { observable, reaction } from 'mobx';
import { isEqual } from 'helpers/data';

import styles from './styles.module.scss';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import IconChevronLeft from 'assets/img/icons/chevron-left.svg';
import IconChevronRight from 'assets/img/icons/chevron-right.svg';

import langStore from 'globalState/lang';
import { fetchDotWalkList } from 'actions/conditions';
import { ATTRIBUTES } from 'constants/attributesForTests';
import Dropdown from 'components/dropdown';

/**
 * Описание: компонент ConditionField
 * Параметры:
 * isActive - компонент активен, т.е. в нём среди других таких же компонентов на странице идёт выбор значения из списка
 * data - список выбора
 * value - значение поля
 * onChange - обработчик для изменения текущего значения поля
 * setActive - установить активным (см. isActive)
 * readOnly - доступен только для чтения
 * className - класс
 */


@observer
export default class ConditionField extends React.Component {
    @observable childrenData = [];
    @observable stringValue = '';
    @observable filteringData = [];
    @observable isActive;
    @observable isShowPopup = false;
    @observable hoveredItem = null;
    tableId = '';
    refCondition = React.createRef();
    refStringCondition = React.createRef();
    refLastUl = React.createRef();
    refDropdown = React.createRef();
    timeout = null;
    isArrowNextClick = false;
    isAnimateRun = false;
    @observable clickPath = [];
    maxLevelFieldList = 1; // максимальный уровень вложенности для полей типа List
    maxLevel = 10; // максимальный уровень вложенности

    constructor(props) {
        super(props);
        if (props.data) {
            this.stringValue = '';
            this.filteringData = props.data;
            this.setValue(props.data, props.value);

            reaction(() => this.stringValue, (data) => {
                this.filteringData = this.props.data.filter(li => li && li.column_title.toLowerCase().includes(data.toLowerCase()));

                let currentList = this.filteringData;
                if (this.childrenData.length > 0) {
                    currentList = this.childrenData[this.childrenData.length - 1].filter(li => li && li.column_title.toLowerCase().includes(this.stringValue.toLowerCase()));
                }
                this.hoveredItem = currentList.length > 0 ? currentList[0] : null;
            });
        }
        this.isActive = this.props.isActive;
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onDocumentKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onDocumentKeyDown);
    }

    componentDidUpdate(prevProps) {
        const { data, value } = this.props;
        if (data && !isEqual(data, prevProps.data)) {
            this.stringValue = '';
            this.filteringData = data;
            this.setValue(data, value);
        }
        if (prevProps.isActive !== this.props.isActive) {
            this.isActive = this.props.isActive;
        }
        this.setFocus();
    }


    checkListLevel = () => {
        let result = true;
        const listLevel = this.clickPath.findIndex((elem) => elem.condition_type === 'list');
        const newLevel = this.clickPath.length;
        if (listLevel !== -1 && newLevel-listLevel >= this.maxLevelFieldList){
            result = false;
        }
        return result;
    };

    onDocumentKeyDown = (e) => {
        const { key } = e;
        const { data, value } = this.props;


        if (!data || !this.isActive || value === null || this.isAnimateRun) {
            return;
        }

        if (key === 'ArrowDown' || key === 'ArrowUp') {
            let currentList = this.filteringData;

            if (this.childrenData.length > 0) {
                currentList = this.childrenData[this.childrenData.length - 1].filter(li => li && li.column_title.toLowerCase().includes(this.stringValue.toLowerCase()));
            }

            if (currentList.length > 0) {
                if (!this.hoveredItem) {
                    this.hoveredItem = currentList[0];
                    return;
                }

                for (let i = 0, length = currentList.length; i < length; i++) {
                    if (currentList[i].column_id === this.hoveredItem.column_id) {
                        if (key === 'ArrowDown') {
                            this.hoveredItem = i + 1 < length ? currentList[i + 1] : currentList[0];
                        }
                        else if (key === 'ArrowUp') {
                            this.hoveredItem = i > 0 ? currentList[i - 1] : currentList[length - 1];
                        }
                        this.updateListScrollTop();
                        break;
                    }
                }
            }
        }

        if (key === 'Enter') {
            if (this.hoveredItem) {
                this.handleClickLi(this.hoveredItem)();
            }
        }

        if (key === 'ArrowRight') {
            if (this.hoveredItem) {
                if (this.childrenData && this.childrenData.length > 0) {
                    if (this.clickPath.length-1 < this.maxLevel && this.hoveredItem.referenced_table_id && this.hoveredItem.condition_type !== 'choice' && this.checkListLevel()) {
                        this.handleClickArrow(this.hoveredItem, this.clickPath.length-1)();
                    }
                }
                else {
                    if (this.hoveredItem.referenced_table_id && this.hoveredItem.condition_type !== 'choice') {
                        this.handleClickArrow(this.hoveredItem, 0)();
                    }
                }
            }
        }

        if (key === 'ArrowLeft') {
            if (this.clickPath.length > 0) {
                this.goToPrevList();
            }
        }

        if (key === 'Escape') {
            this.close();
        }
    };

    updateListScrollTop = () => {
        const list = this.refLastUl ? this.refLastUl.current ? this.refLastUl.current : null : null;
        if (!list) return;

        const hovered = list.getElementsByClassName(styles.Hovered);
        if (!hovered) return;

        const rectHover = hovered[0].getBoundingClientRect();
        const rectList = list.getBoundingClientRect();

        if (rectHover.top + rectHover.height > rectList.top + rectList.height) {
            list.scrollTo(0, list.scrollTop + rectHover.top + rectHover.height - (rectList.top + rectList.height));
        }
        else if (rectHover.top < rectList.top) {
            list.scrollTo(0, list.scrollTop - (rectList.top - rectHover.top));
        }
    };

    searchValue = async (data, value) => {
        const findLi = data.find(li => li.dot_walking_attribute === value.dot_walking_attribute);
        if (findLi) {
            this.props.onChange(findLi, this.tableId);
            return;
        }
        const values = value.dot_walking_attribute.split('.');
        if (values.length > 1) {
            this.searchSelectedValue(values, data);
        }
    };

    searchSelectedValue = async (values, data) => {
        let compValue = '';
        for (const index in values) {
            if (values.hasOwnProperty(index)) {
                compValue = compValue ? `${ compValue }.${ values[index] }` : values[index];
                if (Number(index) === 0 && values.length > 1) {
                    const findLi = data.find(li => li.dot_walking_attribute === values[index]);
                    await this.fetchData(findLi, index);
                }
                if (index > 0 && values.length - 1 > index && this.childrenData.length > 0 && this.childrenData[index - 1].length > 0) {
                    const findLi = this.childrenData[index - 1].find(li => li.dot_walking_attribute === compValue);
                    await this.fetchData(findLi, index);
                }
            }
        }
    };

    fetchData = async (li, index) => {
        this.isAnimateRun = true;
        const { value } = this.props;
        const params = {
            referenced_table_id: li.referenced_table_id,
            dot_walking_attribute: li.dot_walking_attribute,
        };
        const response = await fetchDotWalkList(params);
        const data = response.isOkStatus && response.data ? response.data : {};
        this.tableId = data.table_id;
        const items = data.items || data;
        const findLi = items.find(li => li.dot_walking_attribute === value.dot_walking_attribute);
        if (findLi) {
            this.props.onChange(findLi, data.table_id);
        }
        const tempData = this.childrenData.filter((data, i) => index > i);
        this.childrenData = [
            ...tempData,
            items,
        ];

        if (this.isActive) {
            this.clickPath.push({
                column_title: li.column_title,
                condition_type: li.condition_type,
            });
        }

        if (data) {
            this.isAnimateRun = false;
            this.stringValue = '';
            this.hoveredItem = null;
        }
    };

    setValue = (data, value) => {
        if (value) {
            this.searchValue(data, value);
        }
    };

    handleChangeStringValue = (e) => {
        this.stringValue = e.target.value;
    };

    handleClickLi = (li) => () => {
        this.props.onChange(li, this.tableId);
        this.handleToggleShow();
    };

    handleMouseEnterLi = (li) => () => {
        this.hoveredItem = li;
    };

    handleClickArrow = (li, index) => (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.isAnimateRun) return;
        this.isArrowNextClick = true;
        this.fetchData(li, index);
    };

    handleToggleShow = () => {
        const { value, data } = this.props;
        if (!this.isActive) {
            this.props.setActive(true);
            if (value) {
                const values = value.dot_walking_attribute.split('.');
                if (values.length > 1) {
                    this.searchSelectedValue(values, data);
                }
            }
            document.addEventListener('mousedown', this.onOuterClick);
        }
        else {
            this.close();
        }
    };

    setFocus = () => {
        this.refStringCondition.current && this.refStringCondition.current.focus();
    };

    isDotWalkEquals = (value, row) => {
        return value.dot_walking_attribute === row.dot_walking_attribute;
    };

    isDotWalkIncludes = (value, row) => {
        return value && value.dot_walking_attribute.includes(`${ row.dot_walking_attribute }.`);
    };

    onOuterClick = (e) => {
        const conditionEl = this.refCondition.current;
        const dropdownEl = this.refDropdown.current;

        if (!conditionEl || !dropdownEl) return false;

        if (!conditionEl.contains(e.target) && !dropdownEl.contains(e.target)) {
            this.close();
            e.preventDefault();
            e.stopPropagation();
        }
    };

    close = () => {
        this.childrenData = [];
        this.clickPath = [];
        this.isArrowNextClick = false;
        this.stringValue = '';
        this.hoveredItem = null;
        this.filteringData = this.props.data;
        this.props.setActive(false);
        document.removeEventListener('mousedown', this.onOuterClick);
    };

    hidePopup = () => {
        clearTimeout(this.timeout);
        this.isShowPopup = false;
    };

    showPopup = () => {
        if (this.childrenData.length === 0) {
            return;
        }
        this.timeout = setTimeout(() => {
            this.isShowPopup = true;
        }, 300);
    };

    cutText = (text, cutLength) => {
        if (typeof text === 'string') {
            let textCut = text.slice(0, cutLength);
            if (text.length > textCut.length) {
                textCut = textCut.trim() + '...';
            }
            return textCut;
        }
        else {
            return text;
        }
    };

    renderChildren = () => {
        const { value } = this.props;
        if (!this.childrenData || this.childrenData.length === 0) {
            return null;
        }

        return this.childrenData.map((jsonUl, index) => {
            const filteringUl = index === this.childrenData.length - 1 && this.stringValue
                ? jsonUl.filter(li => li && li.column_title.toLowerCase().includes(this.stringValue.toLowerCase()))
                : jsonUl;
            const li = filteringUl.map((jsonLi, liIndex) => {
                const className = (this.isDotWalkEquals(value, jsonLi) || this.isDotWalkIncludes(value, jsonLi)) ? styles.Selected : '';
                const arrow = this.clickPath.length-1 < this.maxLevel && jsonLi.referenced_table_id && jsonLi.condition_type !== 'choice' && this.checkListLevel() ? (
                    <span className={ styles.ConstSelectBlockArrow } dangerouslySetInnerHTML={{__html: IconChevronRight}} onClick={ this.handleClickArrow(jsonLi, index + 1) } />
                ) : null;
                return (
                    <li
                        key={ `${ jsonLi.dot_walking_attribute }${ liIndex }` }
                        className={ `${ className } ${ this.hoveredItem && jsonLi.column_id === this.hoveredItem.column_id ? styles.Hovered : '' }` }
                        onClick={ this.handleClickLi(jsonLi) }
                        onMouseEnter={ this.handleMouseEnterLi(jsonLi) }
                    >
                        { jsonLi.column_title } { arrow }
                    </li>
                );
            });

            const ref = index === this.clickPath.length - 1 ? this.refLastUl : null;

            return (
                <ul className={ `${ styles.CondSelectBlockList } ${ styles.slideIn }` } ref={ ref } key={ `ul${ index }` }>
                    { li }
                </ul>
            );
        });
    };

    renderModalField = () => {
        const { data, value, isMobile } = this.props;
        if (!data || !this.isActive || value === null) {
            return null;
        }
        this.setFocus();
        const li = this.filteringData.map((jsonLi, liIndex) => {
            const className = (this.isDotWalkEquals(value, jsonLi) || this.isDotWalkIncludes(value, jsonLi)) ? styles.Selected : '';
            const arrow = jsonLi.referenced_table_id && jsonLi.condition_type !== 'choice' ? (
                <span className={ styles.ConstSelectBlockArrow } dangerouslySetInnerHTML={{__html: IconChevronRight}} onClick={ this.handleClickArrow(jsonLi, 0) } />
            ) : null;
            return (
                <li
                    key={ `${ jsonLi.dot_walking_attribute }${ liIndex }` }
                    className={ `${ className } ${ this.hoveredItem && jsonLi.column_id === this.hoveredItem.column_id ? styles.Hovered : '' }` }
                    onClick={ this.handleClickLi(jsonLi) }
                    onMouseEnter={ this.handleMouseEnterLi(jsonLi) }
                    data-test={ `${ jsonLi.dot_walking_attribute }-${ ATTRIBUTES.conditionFieldLi }` }
                >
                    { jsonLi.column_title } { arrow }
                </li>
            );
        });

        const ref = this.clickPath.length === 0 ? this.refLastUl : null;

        return (
            <Dropdown disableMinWidth={ !isMobile } refParent={ this.refCondition } ref={ this.refDropdown }>
                <div className={ styles.CondSelectBlock }>
                    <div className={ styles.CondSelectBlockHead }>
                        <div className={ styles.CondSelectBlockFilter }>
                            { this.clickPath.length > 0 && <Button
                                buttonType={ 'icon-border' }
                                svg={ IconChevronLeft }
                                className={ styles.button }
                                onClick={ this.goToPrevList }
                                data-test={ ATTRIBUTES.conditionFieldBackButton }
                            /> }
                            <input
                                type="text"
                                className={ styles.input }
                                value={ this.stringValue }
                                onChange={ this.handleChangeStringValue }
                                ref={ this.refStringCondition }
                                data-test={ ATTRIBUTES.conditionFieldSearchInput }
                            />
                        </div>
                        { this.clickPath.length > 0 && <div title={ this.clickPath.map(elem => elem.column_title).join(' / ') } className={ styles.CondSelectBlockPath }>
                            <div className={ styles.container }>
                                <div className={ styles.content }>
                                    { this.clickPath.map(elem => elem.column_title).join(' / ') }
                                </div>
                            </div>
                        </div> }
                    </div>
                    <div className={ styles.CondSelectBlockBody }>
                        <ul
                            ref={ ref }
                            className={ styles.CondSelectBlockList }
                            data-test={ this.props['data-test'] && this.props['data-test'].includes('sort') ? ATTRIBUTES.conditionFieldSortingOptions : ATTRIBUTES.conditionFieldFilteringOptions }
                        >
                            { li }
                        </ul>
                        <div className={ styles.CondSelectBlockChildren }>
                            { this.renderChildren() }
                        </div>
                    </div>
                </div>
            </Dropdown>
        );
    };


    goToPrevList = () => {
        if (this.isAnimateRun) return;

        this.clickPath.pop();
        this.childrenData.pop();
        this.isArrowNextClick = false;
        this.isAnimateRun = false;
        this.stringValue = '';
        this.hoveredItem = null;
    };


    findFullTitle = (attributes) => {
        const { data } = this.props;
        const firstAttr = data.find(attr => attr.dot_walking_attribute === attributes[0]);
        let value = firstAttr ? firstAttr.column_title : '';
        let rAttribute = attributes[0];
        this.childrenData.forEach((child, index) => {
            rAttribute += '.' + attributes[index + 1];
            const findAttr = child.find(attr => attr.dot_walking_attribute === rAttribute);
            value += findAttr ? ' -> ' + findAttr.column_title : '';
        });
        return value;
    };

    render() {
        const { value, className } = this.props;
        const { filter_titles } = langStore.getTranslate();
        const attributes = value !== null && value.dot_walking_attribute ? value.dot_walking_attribute.split('.') : [];
        const dots = attributes.length > 1 ? '... ' : '';

        return (
            <div
                ref={ this.refCondition }
                className={ `${ styles.ConditionField } ${ className ? className : '' }` }
                data-test={ this.props['data-test'] }
            >
                <button
                    className={ `${ styles.CondSelectButton } ${ !value ? styles.placeholder : '' }` }
                    type="button"
                    onClick={ this.handleToggleShow }
                    disabled={ this.props.readOnly }
                    onMouseLeave={ this.hidePopup }
                    onMouseEnter={ this.showPopup }
                >
                    { value !== null && value !== '' ? `${ dots }${ value.column_title }` : (filter_titles && filter_titles.choose_field) }
                </button>
                { this.renderModalField() }
                { this.isShowPopup && <Tooltip
                    onClose={ this.hidePopup }
                    parent={ this.refCondition.current }
                >
                    { this.cutText(this.findFullTitle(attributes), 500) }
                </Tooltip> }
            </div>
        );
    }
}
