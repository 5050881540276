import {observer} from "mobx-react";
import * as React from "react";
import {observable} from "mobx";
import langStore from "globalState/lang";
import styles from "../styles.module.scss";
import IconArrow from "assets/img/sidebar-chevron-right.svg";
import {Link} from "react-router-dom";

@observer
export default class Dropdown extends React.Component {
    @observable isShow = true;

    constructor(props) {
        super(props);
    }

    dropdownToggle = () => {
        this.isShow = !this.isShow;
    };

    getRecordUrl(record, table) {
        if (table.table_name && record.record_id) return `/record/${table.table_name}/${record.record_id}`;
        return '';
    }

    render() {
        const { item } = this.props;
        const { table, record, field, value } = langStore.getTranslate();
        return (
            <div>
                <div className={this.isShow ? `${styles.active} ${styles.title}` : styles.title} onClick={this.dropdownToggle}>
                    { table } "{item.table_title}" ({item.table_name}) - ({item.records.length})
                    <div className={styles.arrow} dangerouslySetInnerHTML={{__html: IconArrow}}/>
                </div>
                <div style={{display: (this.isShow ? 'block' : 'none')}}>
                    {item.records.map((result, index) => {
                        return (
                            <div key={index} className={styles.record}>
                                <Link to={this.getRecordUrl(result, item)}
                                      className={styles.link}>{ record }: {result.display_value}</Link>
                                <div className={styles.description}>
                                    { field }, "{ result.column_title }" || { value } : {result.column_value }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
