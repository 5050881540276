import { fetchRecord, fetchRecordUiActions } from 'actions/record';
import { observable } from 'mobx';
import { getAllFields } from 'helpers/misc';
import SimpleForm from 'ui-actions-scripts/simple-form';
import activityFeeds from 'globalState/activityFeeds';
import widgetsDataState from 'globalState/widgets';
import { generateRandomString } from 'helpers/data';

export default class WidgetFormState {
    @observable actionsData;
    @observable recordData;
    @observable isSForm = false;

    table;
    sysId;
    view;
    titleHide = false;
    isUserScripts = true;
    timeout = null;
    dynamicFormId = '';

    constructor(data) {
        this.updateData(data).catch(console.error);
    }

    async updateData({
                         tableName,
                         sysId,
                         view = null,
                         uiActions: isUIActions,
                         userScripts: isUserScripts = true,
                         titleHide,
                         isServicePortal,
                         widgetId,
                         formName,
                         isUpdate,
                     }) {
        if (!tableName) {
            return;
        }
        clearTimeout(this.timeout);
        const URLParams = new URLSearchParams(location.search);
        /**
         * @todo исправить это в формах
         */
        window.isNewRecord = !!sysId;
        this.dynamicFormId = generateRandomString();
        this.tableName = tableName || URLParams.get('table');
        const getSysId = () => {
            if (sysId) {
                return sysId;
            }
            if (URLParams.get('sysid')) {
                return sysId;
            }
            if (sysId !== undefined) {
                return sysId;
            }
            return undefined;
        };
        this.sysId = getSysId();
        this.view = view || URLParams.get('view');
        this.isUserScripts = isUserScripts;
        this.titleHide = titleHide;
        const newSimpleForm = new SimpleForm(this.tableName, this.sysId, view, formName, this.dynamicFormId);
        if (widgetId) {
            widgetsDataState.addForm(this.dynamicFormId, newSimpleForm);
        }
        if (!window.s_form) {
            window.s_form = newSimpleForm;
            this.isSForm = true;
        }
        if (this.tableName === window.s_form.table && (this.isSForm || (window.s_form && (isUpdate || this.sysId)))) {
            window.s_form.sysId = this.sysId;
        }
        // отложенный запуск, так как в виджете sysId может не сразу прийти, а у пользователя может не быть прав на создание
        this.timeout = setTimeout(async () => {
            const { recordData, actionsData } = await this.fetch({
                table: this.tableName,
                sysId: this.sysId,
                view: this.view,
                isServicePortal,
                isUIActions,
            });

            if (window.s_form) {
                window.s_form.formView = recordData.form_title;
                window.s_form.formId = recordData.form_id;
            }
            if (widgetId) {
                const form = widgetsDataState.getFormById(this.dynamicFormId);
                if (form) {
                    form.formView = recordData.form_title;
                    form.formId = recordData.form_id;
                }
                if (recordData.client_scripts) {
                    recordData.client_scripts = _.map(recordData.client_scripts, script =>{
                        return { ...script, script: _.replace(script.script, /s_form/g, `s_widgets.getFormById('${ this.dynamicFormId }')`) };
                    });
                }
            }
            this.recordData = recordData;
            this.actionsData = actionsData;
        }, this.sysId === undefined ? 100 : 500);
    }

    async fetch({ table, sysId, view = null, isServicePortal, isUIActions = true }) {
        let params = {
            open_first_rel_list: 0,
            is_service_portal: isServicePortal ? 1 : 0,
            is_widget: 1,
        };
        if (view) {
            params['form_view'] = view;
        }

        const activityFeedState = activityFeeds.getItem(table, sysId);
        if (activityFeedState) {
            activityFeedState.setIsLoadingFromForm(true);
        }
        const [record, action] = await Promise.all([
            fetchRecord(table, sysId, params),
            isUIActions && fetchRecordUiActions(table, sysId, params),
        ]);
        if (activityFeedState) {
            activityFeedState.setActivityTypesFromFields(record.data.sections);
            activityFeedState.setIsLoadingFromForm(false);
        }
        return {
            recordData: record.data,
            actionsData: action.data,
        };
    }

    getTitle() {
        if (!this.recordData) {
            return null;
        }
        const { sections, entity_name: tableName } = this.recordData;
        const titleField = getAllFields(sections).find((field) => field.display_by_ref);
        return titleField ? titleField.value : tableName;
    }
}
