import * as React from 'react';
import { observable, reaction } from 'mobx';

import { fetchLayout, moveItem } from 'actions/portalDesigner';
import { deleteRecord } from 'actions/record';

let id = 1;

/**
 * базовый класс для всех моделей дизайнера страниц
 */
export default class BasePortalDesignerModel {
    /**
     * таблица соответствующая модели
     * @type {string}
     */
    TABLE_NAME;

    /**
     * тип блока
     * @type {string}
     */
    TYPE;

    /**
     * id блока
     * sysId, если данные пришли с сервера
     * инкримент id, если блок создан локально
     * @type {number}
     */
    id;

    /**
     * индекс сортировки, определяет порядок блоков на странице
     * @type {number}
     */
    @observable order;

    /**
     * список css классов блока, приходит с сервера
     * @type {string}
     */
    cssClassNames;

    /**
     * цвет заливки контейнера, приходит с сервера
     * @type {string}
     */
    backgroundColor;

    /**
     * фоновое изображение контейнера (url), приходит с сервера
     * @type {string}
     */
    backgroundImage;

    /**
     * ссылка на коллекцию которой принадлежит модель
     * @type {BasePortalDesignerCollection}
     */
    collection;


    /**
     * ссылка на DOM элемент соотвествуюищй модели
     * @type {{current}}
     */
    node = React.createRef();

    /**
     * блоки с этим флагом используются для превью при перетаскивание
     * @type {boolean}
     */
    isTemp = false;

    /**
     * флаг, включает лоадер в блоке
     * @type {boolean}
     */
    @observable isLoaded = false;

    constructor(params) {
        this.id = id++;
        this.collection = params.collection;
        this.parent = params.parent;

        /**
         * пересортировать если был изменен order
         */
        reaction(
            () => this.order,
            () => {
                this.collection.items = this.collection.sortByOrder(this.collection.items);
            },
        );
    }

    /**
     * наполнение модели на основе данных с сервера
     * @param params {*}
     */
    setData(params) {
        const sysId = params.widget_instance_id || params.sys_id;
        if (sysId) {
            this.id = this.sysId = sysId;
        }

        this.cssClassNames = params.css_class_names || null;
        if (!_.isNil(params.isTemp)) {
            this.isTemp = !!params.isTemp;
        }
        if (!_.isNil(params.isLoaded)) {
            this.isLoaded = !!params.isLoaded;
        }

        if(!_.isNil(params.background_color)) {
            this.backgroundColor = params.background_color;
        }

        if(!_.isNil(params.background_image)) {
            this.backgroundImage = params.background_image;
        }

        const order = params.sys_order || params.order;
        if (!_.isNil(order)) {
            this.order = order;
        }
    }

    /**
     * сохранение элемента и его модели
     *
     * @param params  параметры запроса
     * @returns {Promise<*>}
     */
    async fetchSave(params) {
        this.setData({
            isLoaded: true,
            isTemp: false,
        });
        const isNewElement = params.is_new;
        delete params.is_new;

        const nextModel = this.getNextModel();
        if (nextModel) {
            params.next_record_id = nextModel.sysId;
        }
        let response = {};

        if (!isNewElement) {
            response = await moveItem(params);
        } else {
            response = await fetchLayout(params);
        }
        const { data } = response;

        this.setData({
            sys_id: data.added_record_id,
            isLoaded: false,
        });

        this.collection.updateItems(data.structure);

        return data;
    }

    /**
     * запрос на удаление элемента
     *
     * @returns {Promise<*>}
     */
    async fetchRemove() {
        this.isLoaded = true;

        const { data } = await deleteRecord(this.TABLE_NAME, [ this.sysId ]);
        this.remove();
        this.isLoaded = false;
        return data;
    }

    /**
     * удаление модели из коллекции
     */
    remove() {
        const index = this.getModelsIndex();
        if (index > -1) {
            this.collection.items.splice(index, 1);
        }
    }

    /**
     * возвращает индекс модели в массиве коллекции
     *
     * @returns {number}
     */
    getModelsIndex() {
        return this.collection.items.indexOf(this);
    }

    /**
     * возвращает следующею модель из коллекции
     *
     * @returns {*}
     */
    getNextModel() {
        const index = this.getModelsIndex();
        return this.collection.getItems(index + 1);
    }

    /**
     * добавляет в коллекцию новую модель после текущей
     *
     * @param params {*}
     */
    appendBefore(params = {}) {
        const order = this.order - .5;
        return this.collection.insert({
            order,
            ...params,
        });
    }

    /**
     * добавляет в коллекцию новую модель перед текущей
     *
     * @param params
     */
    appendAfter(params = {}) {
        const order = this.order + .5;
        return this.collection.insert({
            order,
            ...params,
        });
    }
}
