import * as React from 'react';
import { Link } from 'react-router-dom';

import { showField } from 'helpers/condition';
import { getUrlParams } from 'helpers/data';
import Button from 'components/button';
import Cell from 'components/table/cell';

import styles from 'components/table/styles.module.scss';
import Cogwheel from 'assets/img/icons/settings.svg';
import Search from 'assets/img/icons/search.svg';
import langStore from 'globalState/lang';
import { observer } from 'mobx-react';
import globalTableState from 'globalState/table';
import { ATTRIBUTES } from 'constants/attributesForTests';

@observer
export default class Thead extends React.Component {
    constructor(props) {
        super(props);
    }

    getOptions = (field) => {
        const { bool } = langStore.getTranslate();
        if (field.type === 'boolean') {
            return [
                {
                    database_value: '',
                    display_value: '   ',
                },
                {
                    database_value: '1',
                    display_value: bool && bool.yes,
                },
                {
                    database_value: '0',
                    display_value: bool && bool.no,
                },
            ];
        }
        return field.special && field.special.values ? field.special.values : [];
    };

    isHideValue = (row) => {
        if (!row) {
            return true;
        }
        return !!(row && (row.getOperator() === '!=' || row.getOperator().database_value === '!='));
    };

    isShowTableFilterField = (value) => {
        return [
            'LIKE',
            '=',
        ].includes(value);
    };

    findCell = (field, key, findValue, filterRow, option, maxLengthFind) => {
        const options = this.getOptions(field);
        const className = styles.TableInput;
        const value = this.isHideValue(filterRow) ? '' : findValue;
        const onChange = this.props.table.handleValueChange(filterRow ? filterRow.getId() : '', field.attribute, option.database_value,
            option.show_element, options);
        const props = {
            className: className,
            hasEmptyOption: true,
            readOnly: filterRow ? filterRow.getDisabled() : false,
            column_type: field.type,
        };

        if (option.show_element === 'text') {
            props.showCleanBtn = true;
        }

        return (
            <th key={ key } type="subhead">
                <div className={ styles.TableInputWrap }>
                    {
                        showField(option.show_element, value, onChange, onChange, options, field.special ? field.special : {},
                            props, true, maxLengthFind)
                    }
                </div>
            </th>
        );
    };

    getDatabaseValue = (field) => {
        if (field.type === 'choice' && field.special.length && field.special.values && Array.isArray(field.special.values)) {
            const findValue = field.special.values.find(opt => opt.display_value === field.label);
            return findValue ? findValue.database_value : '';
        }
        return '';
    };

    headRow = () => {
        const columnsKey = this.props.table.props.columns.map((field) => field.db_column_id).join('.');
        let relatedListParams = '';
        let conditionParams = '';
        const { recordId, recordTableName, formId } = this.props;
        if (recordId && recordTableName) {
            relatedListParams = `&table_name=${ recordTableName }&sys_id=${ recordId }`;
        }
        if (formId) {
            relatedListParams += `&form_id=${ formId }`;
        }
        const { condition } = getUrlParams(location.search);
        if (condition) {
            conditionParams = `&condition=${ encodeURIComponent(condition) }`;
        }

        return <>
            <tr key={ 'names' + columnsKey } ref={ this.props.reference }>

                {
                    this.props.isBlankMode || (this.props.isWindow && !this.props.usedByList) || this.props.isMobile ? null : <th className={ styles.thSettings }>
                        <div>
                            { !this.props.usedByList ?
                                (
                                    <Link
                                        key="link"
                                        to={
                                            `/list-layout-personal/${ this.props.table.props.essence }` +
                                            `?view=${ (this.props.table.props.view) ? this.props.table.props.view : 'Default' }` +
                                            `${ (relatedListParams) ? relatedListParams : '' }` +
                                            `${ (conditionParams) ? conditionParams : '' }`
                                        }
                                        className={ styles.RelativeLink }
                                        data-test={ ATTRIBUTES.tableSettingsIcon }
                                    >
                                        <Button buttonType='icon' svg={ Cogwheel }/>
                                    </Link>
                                )
                                :
                                (
                                    <div className={ styles.ColSpacer } />
                                )
                            }
                        </div>
                    </th>
                }
                { !this.props.isMobile && <th className={ styles.thInfo }>
                    {
                        (this.props.isBlankMode || globalTableState.getRowsLength() === 0) ? null : <div>
                            <Button
                                buttonType='icon'
                                onClick={ () => {
                                    if (this.props.onSearchClick) {
                                        this.props.onSearchClick();
                                    }
                                } }
                                data-test={ ATTRIBUTES.tableSearchIcon }
                                svg={ Search }
                            />
                        </div>
                    }
                </th>}
                {
                    this.props.table.props.columns.map((field, key) => {
                        return (
                            <Cell
                                key={ key }
                                data={ field.label }
                                database_value={ this.getDatabaseValue(field) }
                                field={ field.attribute }
                                table={ this.props.table }
                                db_column_id={ field.db_column_id }
                                origin_column_id={ field.origin_column_id }
                                type="head"
                                sortingFields={ this.props.sortingFields }
                                onChangeSorting={ this.props.table.handleSortingChange }
                                head_column_name={ field.db_column_name }
                                fieldType={ field.type }
                                isFixedHead={ this.props.isFixedHead }
                                data-test={ ATTRIBUTES.tableHeaderCell }
                            />
                        );
                    })
                }
            </tr>
            {
                this.props.isBlankMode || this.props.isMobile ? null : <tr key={ 'search' + columnsKey }
                                                    className={ `${ styles.RowNoHover } ${ !this.props.isSearchRowVisible ? styles.HiddenRow : '' }` }>
                    { (this.props.isWindow && !this.props.usedByList) ? null : <th className={ styles.thSettings }>&nbsp;</th> }
                    <th className={ styles.thInfo }>&nbsp;</th>
                    {
                        this.props.table.props.columns.map((field, key) => {
                            const filterRows = this.props.filterFields.filter(row => row.getField().dot_walking_attribute === field.attribute &&
                                this.isShowTableFilterField(row.getOperator().database_value));
                            const filterRow = filterRows.length > 1
                                ? (filterRows.find(row => row.getOperator().database_value === 'LIKE') || filterRows[0])
                                : (filterRows.length === 1 ? filterRows[0] : undefined);
                            const findValue = filterRow && filterRow.getValue()
                                ? filterRow.getValue()
                                : undefined;
                            const maxLengthFind = this.props.maxLength;
                            const findConditionOpt = langStore.getConstant(field.type);
                            let option = findConditionOpt // ищем оператор для текстового поиска сначала
                                && findConditionOpt.options.find((elem) => elem.database_value === 'LIKE');
                            if (!option) {
                                option = findConditionOpt // ищем оператор для select
                                    && findConditionOpt.options.find((elem) => elem.database_value === '=');
                            }
                            if (option) {
                                return this.findCell(field, key, findValue, filterRow, option, maxLengthFind);
                            }
                            // нет подходящего оператора - выводим пустую ячейку
                            return <th key={ key } type="subhead" />;
                        })
                    }
                </tr>
            }

        </>;
    };

    render() {
        const { className } = this.props;

        return <thead className={ className } data-test={ this.props['data-test'] }>
        { this.headRow() }
        </thead>;
    }
}
