import * as React from 'react';
import { observer } from 'mobx-react';

import DragAndDropState from './state';

/**
 * Компонент dragAndDrop в taskBoard
 * @param {string} type
 * @param {string} sysId
 * @param {string} categoryId
 * @param {number} order
 */
@observer
export default class DragAndDrop extends React.Component {
    constructor(props) {
        super(props);
    }

    handleDragStart = (event) => {
        event.stopPropagation();
        if (DragAndDropState.getDragged() && DragAndDropState.getType() === 'card') {
            return;
        }
        const { dataTransfer } = event;
        const { type, sysId, categoryId, order } = this.props;
        DragAndDropState.setType(type);
        DragAndDropState.setSysId(sysId);
        DragAndDropState.setCategoryId(categoryId);
        DragAndDropState.setFirstLaneId(categoryId);
        DragAndDropState.setDragged(true);
        DragAndDropState.setOrder(order);

        dataTransfer.effectAllowed = 'move';
    };

    handleDragEnd = () => {
        DragAndDropState.clean();
    };

    handleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    render() {
        const { type, sysId } = this.props;
        return (
            <div
                draggable="true"
                onDragStart={ this.handleDragStart }
                onDragEnd={ this.handleDragEnd }
                className={ this.props.className }
                onClick={ this.handleClick }
                id={ `${type}${sysId}` }
                data-test={ this.props['data-test'] || '' }
            >
                { this.props.children }
            </div>
        );
    }
}
