import { TableGroupType } from 'types/components/table/groups/index';
import { observable } from 'mobx';

class TableGroupState implements TableGroupType {
    @observable private _title = '';
    @observable private _count = 0;
    @observable private _condition = '';
    @observable private _rows: any[] = [];
    @observable private _isShow = false;
    @observable private _page = 1;
    @observable private _perPage = 20;

    constructor({
                    title = '' ,
                    count = 0,
                    condition = '',
                    rows = [],
                    isShow = false,
                    page = 1,
                    perPage = 20,
    } = {}) {
        this._title = title || '';
        this._count = count || 0;
        this._condition = condition || '';
        this._rows = rows || [];
        this._isShow = isShow || false;
        this._page = page || 1;
        this._perPage = perPage || 20;
    }

    getTitle(): string {
        return this._title;
    }

    setTitle(value: string) {
        this._title = value;
    }

    getCount(): number {
        return this._count;
    }

    setCount(value: number) {
        this._count = value;
    }

    getCondition(): string {
        return this._condition;
    }

    setCondition(value: string) {
        this._condition = value;
    }

    getRows(): any[] {
        return this._rows;
    }

    setRows(rows: any[]) {
        this._rows = rows;
    }


    getIsShow(): boolean {
        return this._isShow;
    }

    setIsShow(value: boolean) {
        this._isShow = value;
    }

    getPage(): number {
        return this._page;
    }

    setPage(value: number) {
        this._page = value;
    }

    getPerPage(): number {
        return this._perPage;
    }

    setPerPage(value: number) {
        this._perPage = value;
    }
}

export const tableGroupStateClass = TableGroupState;

export default new TableGroupState();
