import * as React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { getFormatValue, getParseText } from 'helpers/search';
import IconDocument from 'assets/img/icons/file-text.svg';
import { SearchResultCardProps } from 'types/components/portalWidgetsComponents/searchResult/searchResultCard';
import { ATTRIBUTES } from 'constants/attributesForTests';

/**
 * Props:
 * name - название найденного элемента
 * description - описание элемента
 * number - идентификатор найденного элемента
 * link - ссылка для перехода на элемент
 */

@observer
export default class SearchResultsCard extends React.Component<SearchResultCardProps> {
    constructor(props) {
        super(props);
    }

    renderLink(link: string, display_name: string) {
        const title = display_name.replace(/<srch_plh>|<\/srch_plh>/g, '');
        const replacer = (match, text) => {
            return `<span class='${
                styles.matching
            }'>${ text }</span>`;
        };

        return (
            <Link to={ link }
                  title={ title }
                  className={ styles.Link }
                  dangerouslySetInnerHTML={ { __html: getParseText(display_name, replacer) } }
                  data-test={ ATTRIBUTES.searchRecordLink }
            />
        );
    }

    renderDescription() {
        const { displayedColumns } = this.props;
        return displayedColumns.map((col) => {
            let value = col.value;
            if (value.includes("&lt") || value.includes("srch_plh")) {
                const replacer = (match, p1) => {
                    return `<span class='${
                        styles.matching
                    }' data-test='${ ATTRIBUTES.searchDynamicMatchValue }'>${ p1 }</span>`;
                };
                value = getParseText(col.value, replacer);
            }
            value = `<span class='${ styles.ItemValue }' data-test='${ ATTRIBUTES.searchDynamicFieldValue }'>${ value }</span>`;

            return (
                <span
                    key={ col.title }
                    className={ styles.DescriptionItem }
                    dangerouslySetInnerHTML={ { __html: col.title + ": " + value } }
                    data-test={ ATTRIBUTES.searchDynamicField }
                />
            );
        });
    }

    recordSubInfo() {
        const { subInfoColumns } = this.props;
        if (!subInfoColumns.length) return null;

        const subColumns = subInfoColumns.map(col => {
            const value = `<span class='${ styles.ItemValue } data-test='${ATTRIBUTES.searchStaticFieldValue}'>${ getFormatValue(col.column_type, col.value) }</span>`;
            return (
                <span
                    key={ col.title }
                    className={ [ styles.DescriptionItem, styles.SubItems ].join(' ') }
                    dangerouslySetInnerHTML={ { __html: `${ col.title }: ${ value }` } }
                    data-test={ ATTRIBUTES.searchStaticField }
                >
                </span>
            );
        });

        return (
            <div className={ styles.Footer }>
                { subColumns }
            </div>
        );
    }

    render() {
        const { link, title } = this.props;

        return (
            <div
                className={ styles.Card }
                data-test={ ATTRIBUTES.searchRecord }
            >
                <div className={ styles.Header }>
                    <span className={ styles.Icon } dangerouslySetInnerHTML={ { __html: IconDocument } } />
                    { this.renderLink(link, title) }
                </div>
                { this.renderDescription() }
                { this.recordSubInfo() }
            </div>
        );
    }
}
