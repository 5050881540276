import moment from 'moment';
import { getTimeZoneDate } from 'helpers/getUserTimeZone';

const DATE_TIME_FORMAT = 'D MMMM YYYY, HH:mm';
const TIME_FORMAT = ' HH:mm';

export function formatDate(date) {
    const typeDate = getTimeZoneDate(date);
    if (moment().diff(date, 'days') >= 1) {
        return typeDate.format(DATE_TIME_FORMAT);
    }
    return typeDate.calendar().split(' ')[0] + typeDate.format(TIME_FORMAT);

}
