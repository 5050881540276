import * as React from 'react';
import Container from 'components/container';

import LayoutEditor from 'components/layoutEditor';
import Button from 'components/button';
import apiRequest from 'lib/apiRequest';

import styles from './styles.module.scss';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { helperRedirect } from 'helpers/history';
import { getUrlParams } from 'helpers/data';
import SmartTitle from 'components/smartTitle';
import langStore from 'globalState/lang';
import Heading from 'components/heading';
import ErrorWrapperHoc from 'helpers/hoc/errorWrapperHOC';


/***
 * Описание: компонент для отображения и выбора значений из произвольных списков
 * Параметры:
 */

@observer
class HeapSelectEdit extends React.Component {
    @observable data;
    @observable errorMessage = '';
    lastRouteParams = '';
    selected = [];
    scriptId = '';
    redirectPath = '';

    constructor(props) {
        super(props);

        const parsedSearchParams = getUrlParams(this.props.location.search);
        this.scriptId = parsedSearchParams.script_id || '';
        this.redirectPath = parsedSearchParams.redirect_path || '';
        this.lastRouteParams = this.props.location.pathname + this.props.location.search;
        this.fetchData().catch(this.errorfetchData);
    }

    componentDidUpdate() {
        const routeParams = this.props.location.pathname + this.props.location.search;
        if (this.lastRouteParams !== routeParams) {
            this.lastRouteParams = routeParams;
            this.fetchData().catch(this.errorFetchData);
        }
    }

    fetchData = async () => {
        const parsedSearchParams = getUrlParams(this.props.location.search);
        delete parsedSearchParams.redirect_path;
        const response = await new apiRequest('GET ' + this.props.location.pathname).qs(parsedSearchParams).send();
        this.data = response.getData();
    };

    errorFetchData = (e) => {
        this.errorMessage = e.message;
    };

    updateSelected = (selected) => {
        this.selected = selected;
    };

    saveSelected = async () => {
        const parsedSearchParams = getUrlParams(this.props.location.search);

        const data = {
            'script_id': this.scriptId,
            'chosen_ids': this.selected,
        };

        if (parsedSearchParams.parent_id) {
            data.parent_id = parsedSearchParams.parent_id;
        }
        if (parsedSearchParams.parent_table) {
            data.parent_table = parsedSearchParams.parent_table;
        }

        const result = await new apiRequest(`PUT ${ window.location.pathname }`).sendJSON(data);
        if (result) {
            helperRedirect(this.redirectPath);
        }
    };

    handleClose = () => {
        helperRedirect(this.redirectPath);
    };


    render() {
        if (this.errorMessage) return <div>{ this.errorMessage }</div>;
        if (!this.data) return null;

        const { heap_select_titles } = langStore.getTranslate();
        return (
            <Container>
                <div className={ styles.all }>
                    <div className={ styles.actionsTop }>
                        <div className={ styles.title }>
                            <Heading size={ 'h2' }
                                     noMargin><SmartTitle>{ heap_select_titles && heap_select_titles.title }</SmartTitle></Heading>
                        </div>
                        <Button className={ styles.button } onClick={ this.saveSelected }
                                buttonType='primary'>{ heap_select_titles && heap_select_titles.save_button }</Button>
                        <Button
                            onClick={ this.handleClose }>{ heap_select_titles && heap_select_titles.cancel_button }</Button>
                    </div>
                    <LayoutEditor onChange={ this.updateSelected }
                                  lang={ heap_select_titles ? heap_select_titles : null }
                                  selectedItems={ this.data.selected_items }
                                  availableItems={ this.data.available_items }
                                  location={ this.props.location }
                    />
                </div>
            </Container>
        );
    }

}


export default ErrorWrapperHoc(HeapSelectEdit);
