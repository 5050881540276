import * as React from 'react';
import { observer } from 'mobx-react';

import Container from 'components/portalDesigner/components/container';
import PageData from 'components/portalDesigner/state/pageData';

import styles from 'components/portalDesigner/styles.module.scss';
import { containerSort } from 'helpers/portal';

/**
 * компонент выводящий все контейнеры текущей страницы
 */
@observer
export default class PortalPageDesignerContainers extends React.Component {
    render() {
        const containers = _.clone(PageData.getContainers());

        return <div className={ styles.View }>
            {
                containerSort(containers).map((model, index) => <Container { ...model }
                                                            model={ model }
                                                            index={ index }
                                                            key={ model.id }
                                                            containerRef={ model.node } />,
                )
            }
        </div>;
    }
}