/**
 * Описание: Компонент для выбора правила фильтрации workflow в списке
 * Props:
 * options - список правил фильтрации
 * setValue(id) - метод для передачи выбранного значения
 * value - значение выбора
 * */
import * as React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styles from './styles.module.scss';
import IconChevronDown from 'assets/img/icons/chevron-down.svg';
import IconChevronUp from 'assets/img/icons/chevron-up.svg';

@observer
export default class WorkflowChoice extends React.Component {
    @observable isOpened = false;
    refContainer = React.createRef();

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    itemSelect = (id) => {
        this.isOpened = false;
        this.props.setValue(id);
    };

    handleClickOutside = (e) => {
        const { current: el } = this.refContainer;

        if (!el) return false;

        if (!el.contains(e.target)) {
            this.isOpened = false;
        }
    };

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    render(){
        const option = this.props.options.find((elem) => (elem.id === this.props.value));
        let list='';
        if (this.isOpened) {
            const options = this.props.options.map((elem) => <div className={ styles.Item } key={ elem.id }
                                                                  onClick={() => this.itemSelect(elem.id)}>{ elem.display_value }</div>);
            list = <div className={styles.List} >{options}</div>;
        }
        return (
            <div className={ styles.Control } ref={ this.refContainer }>
                <div className={ styles.Choice }
                     onClick={ () => { this.isOpened = !this.isOpened } }>
                    { option && option.display_value }
                </div>
                { this.isOpened && <div className={ styles.IconArrow }
                     dangerouslySetInnerHTML={ { __html: IconChevronUp } }
                     onClick={ () => { this.isOpened = !this.isOpened } } />
                }
                { !this.isOpened && <div className={ styles.IconArrow }
                     dangerouslySetInnerHTML={ { __html: IconChevronDown } }
                     onClick={ () => { this.isOpened = !this.isOpened } } /> }
                { list }
            </div>
        );
    }
}